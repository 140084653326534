<div class="page-content">
    <div class="page-header border-0">
        <h1 class="page-title text-primary-d2 text-160">
            Adherents
            <small class="page-info text-secondary-d3">
                <i class="fa fa-angle-double-right text-80"></i>
                Registre des adherents
            </small>
        </h1>

    </div>


    <div class="px-2">

        <div class="mt-3 mt-lg-4">
            <div class="card bcard pt-1 pt-lg-2">
                <div class="card-header brc-primary-l3">
                    <h5 class="card-title pl-1 text-120">
                        En cours
                    </h5>

                    <div class="card-toolbar align-self-center no-border">
                        <button type="button" class="btn border-2 btn-outline-default btn-sm">
                        </button>
                    </div>

                    <div class="card-toolbar align-self-center">
                        <a href="#" data-action="toggle" class="card-toolbar-btn text-grey text-110">
                            <i class="fa fa-chevron-up"></i>
                        </a>
                    </div>
                </div>


                <div class="card-body p-0 border-0">
                    <table id="simple-table" class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                        <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                        <tr>
                            <th class="text-center pr-0">
                                <label class="py-0">
                                    <input type="checkbox" class="align-bottom mb-n1 border-2 text-dark-m3">
                                </label>
                            </th>

                            <th>
                                N° CNI
                            </th>

                            <th>
                                NOM COMPLET
                            </th>


                            <th class="d-none d-sm-table-cell">
                                DATE DELIVRANCE
                            </th>

                            <th class="d-none d-sm-table-cell">
                                DATE DELIVRANCE
                            </th>

                            <th class="d-none d-sm-table-cell">
                                DATE ADHESION
                            </th>



                            <th></th>
                        </tr>
                        </thead>

                        <tbody class="mt-1">
                        <tr class="bgc-h-yellow-l4 d-style" *ngFor="let citizen of citizens">
                            <td class="text-center pr-0 pos-rel">
                                <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                    <!-- border shown on hover -->
                                </div>
                                <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                    <!-- border shown when row is selected -->
                                </div>

                                <label>
                                    <input type="checkbox" class="align-middle">
                                </label>
                            </td>

                            <td>
                                <a href="#" class="text-blue-d1 text-600 text-95">{{citizen.cardNumber}}</a>
                            </td>

                            <td>
                                <a href="#" class="text-blue-d1 text-600 text-95">
                                    <img  src="{{citizen?.img}}" class="radius-round border-2 p-2px brc-default-m1 mr-2 w-5 h-5">
                                    {{citizen.prenom}} &nbsp; {{citizen.nom}}
                                </a>
                            </td>



                            <td class="d-none d-sm-table-cell text-grey-d1">
                                {{citizen.issued}}
                            </td>

                            <td class="d-none d-sm-table-cell text-grey-d1">
                                {{citizen.expiry}}
                            </td>

                            <td class="d-none d-sm-table-cell text-grey text-95">
                                <img src="assets/images/flags/{{citizen.consulat.pays.code}}.png" height="24" /> {{citizen.pays.libelle}}

                            </td>



                            <td class="text-center pr-0">
                                <div>
                                    <a (click)="viewDocument(citizen)" class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed" title="Show Details">
                                  <span class="d-none d-md-inline mr-1">
											Consulter
										</span>
                                        <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                    </a>
                                </div>
                            </td>

                        </tr>


                        </tbody>
                    </table>

                </div><!-- /.card-body -->
            </div><!-- /.card -->
        </div>

    </div>


</div>

<app-view-card #viewDoc></app-view-card>
