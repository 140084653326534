import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FrmaffecteComponent } from 'src/app/collectivite/detailstad/frmaffecte/frmaffecte.component';
import { FrmcollectivitecollaborateurComponent } from 'src/app/collectivite/frmcollectivitecollaborateur/frmcollectivitecollaborateur.component';
import { ActeurService } from 'src/app/services/acteur.service';
import { BudgetService } from 'src/app/services/budget.service ';
import { FoyerService } from 'src/app/services/foyer.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { StructureService } from 'src/app/services/structure.service';
import { VilleService } from 'src/app/services/ville.service';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { SecurityService } from 'src/app/services/security.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-lstaffectetad',
  templateUrl: './lstaffectetad.component.html',
  styleUrls: ['./lstaffectetad.component.css']
})


export class LstaffectetadComponent implements OnInit {
  @ViewChild ('frmcollaborateurtad') frmcollaborateurtad: FrmcollectivitecollaborateurComponent;
  @ViewChild('frmaffecte') frmaffecte: FrmaffecteComponent;
  constructor(private http: HttpClient,
    private structureService: StructureService, private villeService: VilleService
,         private foyerService: FoyerService, private acteurService: ActeurService
,         private budgetService: BudgetService,private authenticationService: AuthenticationService,
private paginationService:PaginationService, private securiteService: SecurityService,private spinner: NgxSpinnerService) { }
  datapensionnaires: any = [];
  datacollaborateurs: any = [];
  databudgets: any = [];
  pensionnaire: any = null;
  collaborateur: any = null;
  budget: any = null;
  parent;
  colSelected;
  typeacteur: any = '';
  tadSelected: any;
  data: any = [];
  tads: any = [];
  user: User = new User();
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10,20, 30, 40];
  currentIndex:any;

  prenom: any = null;
  nom: any = null;
  email: any = null;
  adresse: any = null;
  datenaissance: any = null;
  telephone: any = null;

  canCreate = false;
  canEdit = false;
  canDelete = false;
  canDetails = false;
  idTad =0;

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.showDoits();
    this.filter(null,this.user);
    this.showStructure();
    //this.showActeurStructure();
  }
  showStructure(): void {
    this.structureService.getStructuresByType(TypeStructureModel.TAD).subscribe(data => {
      this.tads = data;

    });

  }
  showDoits(){
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_AFFECTE_TAD").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreate = true;
      }  else {
        this.canCreate = false;
      }
   });
   this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_AFFECTE_TAD").subscribe(data => {
    console.log(this.data)
    if (!!data) {
      this.canDelete = true;
    }  else {
      this.canDelete = false;
    }
 });
 
  }

  rechercheAvance() {
    this.spinner.show();
    this.prenom = this.prenom ? this.prenom: null;
    this.nom = this.nom ? this.nom: null;
    this.email = this.email ? this.email: null;
    this.adresse = this.adresse ? this.adresse: null;
    this.telephone = this.telephone ? this.telephone: null;
    if(this.idTad == null) 
      this.idTad = 0;
   // alert(this.user.structure)
    this.acteurService.getRecherche(this.prenom,this.nom,this.email,this.adresse,
      this.telephone,TypeActeurModel.AFFECTER,this.idTad,TypeStructureModel.TAD).subscribe(data => {
      this.data = data;
      this.spinner.hide();
   });
  
  }

  deleteActeur(id: any): void {
    if (confirm('Voulez vraiment supprimer cet élément ? ' )) {
       this.acteurService.delete(id).subscribe(data => {
        this.filter(this,this.tadSelected);
       });
     } 
 }
 openAffectermActeur(typeacteur:any): void  {
  this.frmaffecte.typeacteur=typeacteur;  
  if(this.user.structure!=0){
      this.structureService.get(this.user.structure).subscribe(data => {
        this.frmaffecte.structure = data;
      });
      this.frmaffecte.selecttad=this.user.structure 
    } 
  this.frmaffecte.show(this); 
  this.frmaffecte.show2() ;
}

  editActeur(id: any): void {
   // alert("ok");
    this.frmcollaborateurtad.editPersonne(this,id);
  }
  showActeurStructure(): void {
    this.acteurService.getActeurRattacheesAll(TypeActeurModel.AFFECTER,this.user.structure,TypeStructureModel.TAD).subscribe(data => {
      this.data = data;
    //  this.pensionnaire = null;
    });
  } 
  filter(parent,tad:any): void  {
  // alert()
    this.showActeurStructure();
  }

  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.data = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }

  tabSize(event){
    this.page = event;
  //  this.showData();
  }  

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
  //  this.showData();
  }  
  reset1(){
    this.prenom = null;
   }
   reset2(){
    this.nom = null;
   }
   reset3(){
    this.email = null;
   }
   reset4(){
    this.adresse = null;
   }
   reset5(){
    this.telephone = null;
   }

   reset6(){
    this.idTad = 0;
   }
}
