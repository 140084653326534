import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActeurService } from 'src/app/services/acteur.service';
import { ReservationService } from 'src/app/services/reservation.service';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
declare var $: any;
@Component({
  selector: 'app-frmchauffeurreservation',
  templateUrl: './frmchauffeurreservation.component.html',
  styleUrls: ['./frmchauffeurreservation.component.css']
})
export class FrmchauffeurreservationComponent implements OnInit {

  prestataires: any = [];
  motifs: any = [];
  tads: any = [];
  passagers: any = [];
  reservation: any;
  contacts: any = [];
  chauffeurs: any = [];

  templates: any = [];
  cbxTemplate: any;
  transporteur: any;
  adresseDepart: any = '';
  adresseArrivee: any = '';
  distance: number;
  estimatedDuration: number;
  motifTransport: any;
  noteChauffeur: any;
  reservationDate: any;
  templatePrix: any;
  tad: any = null;
  col: any = null;
  user: User;
  erroradressearrive = false;
  erroradressedepart = false;
  test = true;
  error = false;
  statut = 1;
  isdupplicate = false;
  chauffeur:any;
  selectedchauffeur:any;
  parent: any;
  id: any;
  selectchauffeur: any;


  constructor(private http: HttpClient, private acteurService: ActeurService,
    private authenticationService: AuthenticationService, private reservationService: ReservationService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    //stransAdresse('adresse');
  }
  showChauffeur(): void {
    if(!!this.transporteur){
      this.acteurService.getActeurRattachee(TypeActeurModel.CHAUFFEUR, this.transporteur.id).subscribe(data => {
        this.chauffeurs = data;

      });
    }


     }
     changeChauffeur(){
     // alert(this.selectedchauffeur);
      this.acteurService.get(this.selectedchauffeur).subscribe(data => {
        this.chauffeur = data;
        this.save();

      });

    }
  show(parent,id:any) {
    this.selectedchauffeur = null;
    this.parent = parent;
  //  alert(id);
    this.reservationService.get(id).subscribe(data => {
      this.reservation = data;

      this.tad = this.reservation.tad.id;
      this.adresseDepart = this.reservation.adresseDepart;
      this.adresseArrivee = this.reservation.adresseArrivee;
      this.motifTransport = this.reservation.motifTransport.id;
      this.distance = this.reservation.distance;
      this.reservationDate = this.reservation.reservationDate;
      this.noteChauffeur = this.reservation.noteChauffeur;
      this.estimatedDuration = this.reservation.estimatedDuration;
      this.transporteur =  this.reservation.transporteur;
     // alert(this.transporteur.id);
      this.templatePrix =  this.reservation.templatePrix;
      if(!!this.reservation.chauffeur){
        this.chauffeur = this.reservation.chauffeur;
        this.selectedchauffeur = this.reservation.chauffeur.id;
      }
      this.showChauffeur();

    });


    $('#modalAjoutChauffeur').modal('show');
  }
  fermer(){
    $('#modalAjoutChauffeur').modal('hide');

  }


  save(): void {
 /*   $('#origin-input')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#destination-input')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#reservationDate')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#distance')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#estimatedDuration')[0].dispatchEvent(new Event('input', { bubbles: true }));


    const headers = {};
    const body = {

      id: this.reservation.id, adresseDepart: this.adresseDepart, adresseArrivee: this.adresseArrivee, actif: true,
      distance: this.distance, estimatedDuration: this.estimatedDuration,
      motifTransport: { id: this.motifTransport }, noteChauffeur: this.noteChauffeur,
      reservationDate: this.reservationDate,
      statut: { id: this.statut }, transporteur: this.transporteur, templatePrix: this.templatePrix, chauffeur: this.chauffeur,
      tad: { id: this.tad }, createdBy: { id: this.user.id }
    };*/
    this.reservationService.ajouterChauffeur(this.reservation.id,this.chauffeur.id).subscribe(data => {
        this.reservation = data;
      //  console.log(data);
      });
    //  this.fermer()
    }

}
