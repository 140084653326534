<div class="page-content">
    <div class="page-header border-0">
        <h1 class="page-title text-primary-d2 text-160">
            Souscription
            <small class="page-info text-secondary-d3">
                <i class="fa fa-angle-double-right text-80"></i>
                Details
            </small>
        </h1>

    </div>


    <div class="px-2">



        <div class="row mt-5 pt-lg-3">

            <div class="col-lg-8" *ngIf="!!dossier">

                <div class="card bcard border-1 brc-secondary-l2">
                    <ul class="nav nav-tabs nav-tabs-simple nav-tabs-scroll is-scrollable nav-tabs-static border-b-1 brc-default-l2 pt-2px" role="tablist">
                        <li class="nav-item mx-3">
                            <a class="btn btn-light-lightgrey bgc-white btn-brc-tp btn-h-light-dark btn-a-outline-green btn-a-text-dark py-25 px-15 border-none border-b-4 active" id="home18-tab-btn" data-toggle="tab" href="#home18" role="tab" aria-controls="home18" aria-selected="true">
                                Informations personnelles
                            </a>
                        </li>



                        <li class="nav-item">
                            <a class="btn btn-light-lightgrey bgc-white btn-brc-tp btn-h-light-dark btn-a-outline-green btn-a-text-dark py-25 px-15 border-none border-b-4" id="carte-tab-btn" data-toggle="tab" href="#carte" role="tab" aria-controls="more18" aria-selected="false">
                                Details de demande
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="btn btn-light-lightgrey bgc-white btn-brc-tp btn-h-light-dark btn-a-outline-green btn-a-text-dark py-25 px-15 border-none border-b-4" id="tab-payments" data-toggle="tab" href="#payments" role="tab" aria-controls="more18" aria-selected="false">
                                Paiements effectués
                            </a>
                        </li>


                    </ul>

                    <div class="tab-content tab-sliding py-2 px-0 mx-md-0">
                        <div class="tab-pane show text-95 px-3 active" id="home18" role="tabpanel" aria-labelledby="home18-tab-btn">


                            <div class="row mt-5">
                                <div class="col-12 px-4 mb-3">



                                    <hr class="w-100 mx-auto mb-0 brc-default-l2">

                                    <div class="bgc-white radius-1">
                                        <table class="table table table-striped-default table-borderless">
                                            <tbody>

                                            <tr>
                                                <td>
                                                    <i class="far fa-id-card text-success"></i>
                                                </td>

                                                <td class="text-95 text-600 text-secondary-d2">
                                                    NIN
                                                </td>

                                                <td class="text-dark-m3">
                                                    {{dossier.demandeur.nin}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i class="far fa-calendar text-success"></i>
                                                </td>

                                                <td class="text-95 text-600 text-secondary-d2">
                                                    Délivré le
                                                </td>

                                                <td class="text-dark-m3">
                                                    {{dossier.demandeur.dateNaissance}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i class="far fa-user text-success"></i>
                                                </td>

                                                <td class="text-95 text-600 text-secondary-d2">
                                                    Nom complet
                                                </td>

                                                <td class="text-dark-m3">
                                                    {{dossier.demandeur.prenom}} {{dossier.demandeur.nom}}
                                                </td>
                                            </tr>



                                            <tr>
                                                <td>
                                                    <i class="far fa-envelope text-blue"></i>
                                                </td>

                                                <td class="text-95 text-600 text-secondary-d2">
                                                    Email
                                                </td>

                                                <td class="text-blue-d1 text-wrap">
                                                    {{dossier.demandeur.email}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <i class="fa fa-phone text-purple"></i>
                                                </td>

                                                <td class="text-95 text-600 text-secondary-d2">
                                                    Téléphone
                                                </td>

                                                <td class="text-dark-m3">
                                                    {{dossier.demandeur.phone}}
                                                </td>
                                            </tr>




                                            </tbody></table>
                                    </div>

                                </div>

                            </div><!-- /.row -->



                        </div>



                        <div class="tab-pane text-95 px-3" id="carte" role="tabpanel" aria-labelledby="more18-tab-btn">


                            <div class="row mt-5">
                                <div class="col-12 px-4 mb-3">



                                    <hr class="w-100 mx-auto mb-0 brc-default-l2">

                                    <div class="bgc-white radius-1">
                                        <table class="table table table-striped-default table-borderless">
                                            <tbody>

                                            <tr>
                                                <td>
                                                    <i class="fa fa-money-bill text-success"></i>
                                                </td>

                                                <td class="text-95 text-600 text-secondary-d2">
                                                    Capital
                                                </td>

                                                <td class="text-dark-m3">
                                                    {{dossier.capital}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i class="far fa-calendar text-success"></i>
                                                </td>

                                                <td class="text-95 text-600 text-secondary-d2">
                                                    Mode de paiement
                                                </td>

                                                <td class="text-dark-m3">
                                                    {{dossier.mode}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <i class="far fa-money-bill-alt text-success"></i>
                                                </td>

                                                <td class="text-95 text-600 text-secondary-d2">
                                                    Prime
                                                </td>

                                                <td class="text-dark-m3">
                                                    {{dossier.prime}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <i class="far fa-money-bill-alt text-success"></i>
                                                </td>

                                                <td class="text-95 text-600 text-secondary-d2">
                                                    Exedent
                                                </td>

                                                <td class="text-dark-m3">
                                                    {{dossier.exedent}}
                                                </td>
                                            </tr>








                                            </tbody></table>
                                    </div>

                                </div>

                            </div><!-- /.row -->





                        </div>


                        <div class="tab-pane text-95 px-3" id="payments" role="tabpanel" aria-labelledby="more18-tab-btn">


                            <div class="row mt-5">
                                <div class="col-12 px-4 mb-3">



                                    <hr class="w-100 mx-auto mb-0 brc-default-l2">

                                    <div class="bgc-white radius-1">

                                        <table class="table table-bordered">
                                            <thead>
                                            <td >Date</td>
                                            <td class="text-right">Montant</td>
                                            <td>Methode</td>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let pay of payments">

                                                <td>{{pay.payDate}}</td>
                                                <td class="text-right">{{pay.amount}}</td>
                                                <td>Mobile Money</td>

                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div><!-- /.row -->





                        </div>
                    </div>
                </div>

            </div>




        </div>



    </div>

</div>

<app-view-doc #viewDoc></app-view-doc>


<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>

