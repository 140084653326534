<div class="page-content">
    <div class="page-header border-0">
        <h1 class="page-title text-primary-d2 text-160">
            Organisations
            <small class="page-info text-secondary-d3">
                <i class="fa fa-angle-double-right text-80"></i>
                Structures
            </small>
        </h1>
    </div>

    <div class="px-2">
        <div class="row">
            <div class="col-12 col-md-4 mt-3 mt-md-0">
                <div class="card bcard h-auto border-0">
                    <div class="card-header bgc-default-d3">
                        <h3 class="card-title text-130 text-white">
                            Structures
                            <div class="mb-2 mb-sm-0">
                                <div class="dropdown" class="btn btn-info dropdown-toggle" *ngIf="this.canShow">
                                    <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-bars mr-1"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
                                        <a class="dropdown-item" href="#" *ngIf="this.canCreate"
                                            (click)="openFormCollectivite()">Ajouter</a>
                                        <a class="dropdown-item" href="#" *ngIf="this.canUpdate"
                                            (click)="edit()">Modifier</a>
                                        <a class="dropdown-item" href="#" *ngIf="this.canDelete"
                                            (click)="delete()">Supprimer</a>
                                    </div>
                                </div>
                            </div>
                        </h3>
                    </div>
                    <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
                        <div class="jqtree tree-dotted" id="id-jqtree-files"></div>
                        <input type="hidden" id="orgId" name="orgId" [(ngModel)]="orgId" (input)="getOrg()">
                    </div>
                </div>
            </div>
            <div class="col-md-8 mt-3 mt-md-0">
                <app-detailscollectivite #detailCollectivite
                    [className]="(!!this.selectedOrg && this.selectedOrg.typestructure=='COLLECTIVITE')?'d-block':'d-none'">
                </app-detailscollectivite>
                <app-detailstad #detailsTad
                    [className]="(!!this.selectedOrg && this.selectedOrg.typestructure=='TAD')?'d-block':'d-none'">
                </app-detailstad>
            </div>
        </div>
    </div>
</div>

<app-frmcollectivites #frmcollectivitesComponent></app-frmcollectivites>