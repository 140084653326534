<div aria-hidden="true" class="modal fade modal-ds" id="modalDetailsTemplate" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Template de prix
        </h5>

        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
        </div>

      <div class="modal-body">

        <div>

          <div>

            <div class="mt-1 mb-2">
              <span class="badge badge-info ml-n1">Identification</span>
            </div>

            <div>
              <table class="table table table-striped-default table-borderless">
                <tbody>
                <tr>


                  <td class="text-95 text-600 text-secondary-d2">
                    Libellé
                  </td>

                  <td class="text-dark-m3">
                    {{template?.libelle}}
                  </td>
                </tr>

                <tr>


                  <td class="text-95 text-600 text-secondary-d2">
                    Prestataire
                  </td>

                  <td class="text-blue-d1 text-wrap">
                    {{template?.prestataire?.nom}}
                  </td>
                </tr>

                <tr>
                  <td class="text-95 text-600 text-secondary-d2">
                    Structure
                  </td>

                  <td class="text-dark-m3">
                    {{template?.collectivite?.nom}}
                  </td>
                </tr>

                <tr>

                  <td class="text-95 text-600 text-secondary-d2">
                    TAD
                  </td>

                  <td class="text-dark-m3">
                    {{template?.tad?.nom}}
                  </td>
                </tr>

                <tr>


                  <td class="text-95 text-600 text-secondary-d2">
                    Période
                  </td>

                  <td class="text-dark-m3">
                    Du <span class="text-blue">{{template?.datedebut}}</span> au <span
                    class="text-danger">{{template?.datefin}}</span>
                  </td>
                </tr>

                <tr>


                </tbody>
              </table>
            </div>


          </div>

          <div>

            <div class="mt-4 mb-2">
              <span class="badge badge-success ml-n1">Timing</span>
            </div>

            <div class="mt-1 pl-1 pos-rel">
              <div class="position-tl h-90 border-l-2 brc-secondary-l1 ml-2 ml-lg-25 mt-2"></div>

              <div class="row pos-rel">
                <div class="position-tl mt-1 ml-25 w-2 h-2 bgc-white radius-round border-3 brc-success-m1"></div>

                <div class="col-4 ml-4 col-lg-4 ml-lg-0 text-90  text-dark-m3  text-left ">Heure début jour</div>
                <div class="col-12 ml-4 col-lg-8 ml-lg-n4 pb-2 border-b-1 brc-grey-l4">

                    <span class="text-grey-m2">
                                               {{template?.heuredebutjour}}
                                            </span>

                </div>
              </div>
              <div class="row pos-rel my-3">
                <div class="position-tl mt-1 ml-25 w-2 h-2 bgc-white radius-round border-3 brc-blue-m1"></div>

                <div class="col-4 ml-4 col-lg-4 ml-lg-0 text-90  text-dark-m3 text-left">Heure début nuit</div>
                <div class="col-12 ml-4 col-lg-8 ml-lg-n4 pb-2 border-b-1 brc-grey-l4">


                    <span class=" text-grey-m2">
                                                 {{template?.heuredebutnuit}}
                                            </span>


                </div>
              </div>
              <div class="row pos-rel my-3">
                <div class="position-tl mt-1 ml-25 w-2 h-2 bgc-white radius-round border-3 brc-purple-m1"></div>

                <div class="col-4 ml-4 col-lg-4 ml-lg-0 text-90 text-dark-m3 text-left">Week-end</div>
                <div class="col-12 ml-4 col-lg-8 ml-lg-n4 pb-2 border-b-1 brc-grey-l4">
                     <span *ngIf="template?.flagsamedi"
                           class="d-inline-block radius-round bgc-default-l2 text-dark-tp3 text-90 px-25 py-3px mx-2px my-2px">
                                Samedi
                            </span>

                  <span *ngIf="template?.flagdimanche"
                        class="d-inline-block radius-round bgc-default-l2 text-dark-tp3 text-90 px-25 py-3px mx-2px my-2px">
                                Dimanche
                            </span>
                </div>
              </div>

            </div>


          </div>

          <div>

            <div class="mt-4 mb-2">
              <span class="badge badge-warning ml-n1">Tarification</span>
            </div>

            <div class="row">

              <div class="dh-zoom-1 col-6 col-sm-3 px-0 my-2 my-md-0">
                <div class="mx-1 bcard overflow-hidden">
                  <ul class="list-unstyled text-center m-0 text-dark-m3">
                    <li class="mb-2px bgc-success text-white py-2">
                            <span class="text-105 text-600 brc-white-tp3">
                                    Tarif jour
                                </span>

                    </li>

                    <li class="py-2">{{template?.tarifjour | number: '1.0-2':'fr-FR'}} €</li>


                  </ul>
                </div>
              </div>


              <div class="dh-zoom-1 col-6 col-sm-3 px-0 my-2 my-md-0">
                <div class="mx-1 bcard overflow-hidden">
                  <ul class="list-unstyled text-center m-0 text-dark-m3">
                    <li class="mb-2px bgc-blue text-white py-2">
                            <span class="text-105 text-600  brc-white-tp3">
                                    Tarif nuit
                                </span>

                    </li>

                    <li class="py-2">{{template?.tarifnuit | number: '1.0-2':'fr-FR'}} €</li>


                  </ul>
                </div>
              </div>


              <div class="dh-zoom-1 col-6 col-sm-3 px-0 my-2 my-md-0">
                <div class="mx-1 bcard overflow-hidden">
                  <ul class="list-unstyled text-center m-0 text-dark-m3">
                    <li class="mb-2px bgc-warning-d1 text-white py-2">
                            <span class="text-105 text-600 brc-white-tp3">
                                    Tarif week-end
                                </span>

                    </li>

                    <li class="py-2">{{template?.tarifweekend | number: '1.0-2':'fr-FR'}} €</li>


                  </ul>
                </div>
              </div>


              <div class="dh-zoom-1 col-6 col-sm-3 px-0 my-2 my-md-0">
                <div class="mx-1 bcard overflow-hidden">
                  <ul class="list-unstyled text-center m-0 text-dark-m3">
                    <li class="mb-2px bgc-purple text-white py-2">
                            <span class="text-105 text-600  brc-white-tp3">
                                    Tarif jour férié
                                </span>

                    </li>

                    <li class="py-2">{{template?.tarifferie | number: '1.0-2':'fr-FR' }} €</li>


                  </ul>
                </div>
              </div>

            </div>


          </div>


        </div>


      </div>

      <div class="modal-footer">
          <button class="btn btn-secondary" data-dismiss="modal" type="button">
            Fermer
          </button>


      </div>
      </div>
    </div>
  </div>
