<div class="modal fade modal-ds " id="modalTraitement" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >
          Traitement réservation
        </h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="">

          <div class="card-body px-0 py-2">



            <form class="mt-lg-3">
              <div class="form-group row">

                <div class="col-sm-8 ml-auto mr-auto col-form-label pr-0">
                  <div class="alert alert-collapse bgc-white text-dark-tp3 border-1 brc-secondary-l2 shadow-sm radius-0 py-3 d-flex align-items-start">
                    <div class="position-tl w-102 m-n1px border-t-4 brc-primary"></div>
                    <div class="bgc-primary px-4 py-25 radius-1px mr-4 shadow-sm">
                      <i class="fa fa-info text-180 text-white"></i>
                    </div>

                    <div class="text-dark-tp3">
                      <h3 class="text-blue-d1 text-130">{{(!!reservation)?reservation.statut.libelle: ''}}</h3>
                      <div class="my-2">
                        <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                        <span class="text-600 text-90">
                                    N°:
                                </span>
                        #{{(!!reservation)?reservation.id:''}}
                      </div>

                      <div class="my-2">
                        <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                        <span class="text-600 text-90">
                                    Date creation:
                                </span>
                        {{(!!reservation)?(reservation.createdDate | date : 'dd/MM/yyyy'):''}}
                      </div>
                    </div>


                  </div>

                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 text-center col-form-label pr-0">
                  <label class="mb-0">
                    Commentaire
                  </label>
                </div>

                <div class="col-sm-8 mr-auto ml-auto">
                  <textarea rows="5" class="form-control" [(ngModel)]="commentaire" name="commentaire"  placeholder="" style="overflow: hidden; overflow-wrap: break-word; resize: horizontal; height: 100px;"></textarea>
                </div>
              </div>


            </form>

          </div>
        </div>








      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Annuler
        </button>
        <button type="button" class="btn btn-danger" (click)="reject()">
          Rejeté
        </button>
        <button type="button" (click)="validate()" class="btn btn-success">
          Valider
        </button>

      </div>
    </div>
  </div>
</div>
