import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgxSpinnerService} from 'ngx-spinner';


registerLocaleData(localeFr);


FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);


import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HomeComponent } from './components/site/home/home.component';
import { LstreservationsComponent } from './reservation/lstreservations/lstreservations.component';
import { FrmreservationComponent } from './reservation/frmreservation/frmreservation.component';
import { LstorgansisationsComponent } from './organisation/lstorgansisations/lstorgansisations.component';
import { LstprestatairesComponent } from './prestataire/lstprestataires/lstprestataires.component';
import { LoginComponent } from './login/login/login.component';
import { TypetemplateComponent } from './referentiel/typetemplate/typetemplate.component';
import { StatutComponent } from './referentiel/statut/statut.component';
import { MotifComponent } from './referentiel/motif/motif.component';
import { HttpClientModule } from '@angular/common/http';
import { FrmpassagerComponent } from './reservation/frmpassager/frmpassager.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { TypetemplateService } from './services/typetemplate.service';
import { StatutService } from './services/statut.service';
import { MotifService } from './services/motif.service';
import { LstfoyersComponent } from './foyer/lstfoyers/lstfoyers.component';
import { LstacteursComponent } from './acteur/lstacteurs/lstacteurs.component';
import { LstcollaborateursComponent } from './collaborateur/lstcollaborateurs/lstcollaborateurs.component';
import { LsttemplatePrixComponent } from './templateprix/lsttemplateprix/lsttemplateprix.component';
import { FonctionComponent } from './referentiel/fonction/fonction.component';
import { VilleComponent } from './referentiel/ville/ville.component';
import { FrmtypetemplateComponent } from './referentiel/typetemplate/frmtypetemplate/frmtypetemplate.component';
import { FrmcontactpassagerComponent } from './reservation/frmcontactpassager/frmcontactpassager.component';
import { FrmtemplateprixComponent } from './templateprix/frmtemplateprix/frmtemplateprix.component';
import { LstcollectivitesComponent } from './collectivite/lstcollectivites/lstcollectivites.component';
import { LsttadsComponent } from './tad/lsttads/lsttads.component';
import { LsttemplateprixComponent } from './prestataire/lsttemplateprix/lsttemplateprix.component';
import { LstchauffeursComponent } from './prestataire/lstchauffeurs/lstchauffeurs.component';
import { FrmchauffeursComponent } from './prestataire/frmchauffeurs/frmchauffeurs.component';
import { FrmfoyersComponent } from './foyer/frmfoyers/frmfoyers.component';
import { LstpensionnairesComponent } from './foyer/lstpensionnaires/lstpensionnaires.component';
import { FrmpensionnairesComponent } from './foyer/frmpensionnaires/frmpensionnaires.component';
import { FrmprestatairesComponent } from './prestataire/frmprestataires/frmprestataires.component';
import { DetailsprestatairesComponent } from './prestataire/detailsprestataires/detailsprestataires.component';
import { DetailsfoyersComponent } from './foyer/detailsfoyers/detailsfoyers.component';
import { FrmcollectivitesComponent } from './collectivite/frmcollectivites/frmcollectivites.component';
import { FrmtadComponent } from './collectivite/frmtad/frmtad.component';
import { FrmcontactsComponent } from './collectivite/frmcontacts/frmcontacts.component';
import { LsttadComponent } from './collectivite/lsttad/lsttad.component';
import { LstcontactsComponent } from './collectivite/lstcontacts/lstcontacts.component';

import { FrmcollaborateursprestatairesComponent } from './prestataire/frmcollaborateursprestataires/frmcollaborateursprestataires.component';
import { LstcollaborateurscollectiviteComponent } from './collectivite/lstcollaborateurscollectivite/lstcollaborateurscollectivite.component';
import { LsttemplateprixcollectiviteComponent } from './collectivite/lsttemplateprixcollectivite/lsttemplateprixcollectivite.component';
import { LstmotifscollectiviteComponent } from './collectivite/lstmotifscollectivite/lstmotifscollectivite.component';
import { LstcollaborateursfoyersComponent } from './foyer/lstcollaborateursfoyers/lstcollaborateursfoyers.component';
import { FrmcollaborateursfoyersComponent } from './foyer/frmcollaborateursfoyers/frmcollaborateursfoyers.component';
import { SecurityComponent } from './security/security/security.component';
import { DetailscollectiviteComponent } from './collectivite/detailscollectivite/detailscollectivite.component';
import { DetailstadComponent } from './collectivite/detailstad/detailstad.component';
import { UsersComponent } from './security/users/users.component';
import { FrmuserComponent } from './security/users/frmuser/frmuser.component';
import { FrmresatemplateComponent } from './reservation/frmresatemplate/frmresatemplate.component';
import { FrmcollaborateursComponent } from './collectivite/frmcollaborateurs/frmcollaborateurs.component';
import { FrmmotifsComponent } from './collectivite/frmmotifs/frmmotifs.component';
import { LstbudgetComponent } from './fonction/lstbudget/lstbudget.component';
import { FrmbudgetComponent } from './fonction/frmbudget/frmbudget.component';
import { LstactiviteComponent } from './fonction/lstactivite/lstactivite.component';
import { MenuComponent } from './menu/menu.component';
import { FrmcollectivitecollaborateurComponent } from './collectivite/frmcollectivitecollaborateur/frmcollectivitecollaborateur.component';
import { FrmcollectivitetadComponent } from './collectivite/frmcollectivitetad/frmcollectivitetad.component';
import { FrmcollectiviteprestataireComponent } from './collectivite/frmcollectiviteprestataire/frmcollectiviteprestataire.component';
import { FrmmotifcollectiviteComponent } from './collectivite/detailscollectivite/frmmotifcollectivite/frmmotifcollectivite.component';
import { DetailsreservationComponent } from './reservation/detailsreservation/detailsreservation.component';
import { Tool } from "./shared/tool";
import { TraiterComponent } from './reservation/traiter/traiter.component';
import { LstsimulationComponent } from './fonction/lstsimulation/lstsimulation.component';
import { LststatistiqueComponent } from './fonction/lststatistique/lststatistique.component';
import { CarteComponent } from './reservation/carte/carte.component';
import { CalendrierComponent } from './reservation/calendrier/calendrier.component';
import { PassagersComponent } from './reservation/passagers/passagers.component';
import { LstpenssionnairetadComponent } from './tad/lstpenssionnairetad/lstpenssionnairetad.component';

import { LstcollaborateurtadComponent } from './tad/lstcollaborateurtad/lstcollaborateurtad.component';
import { LstbudgettadComponent } from './tad/lstbudgettad/lstbudgettad.component';
import { LstaffectetadComponent } from './tad/lstaffectetad/lstaffectetad.component';
import { LstprestatairescollectvitesComponent } from './collectivite/lstprestatairescollectvites/lstprestatairescollectvites.component';
import { LstfoyercollectvitesComponent } from './collectivite/lstfoyercollectvites/lstfoyercollectvites.component';
import { FrmaffecteComponent } from "./collectivite/detailstad/frmaffecte/frmaffecte.component";
import { NoteComponent } from './reservation/note/note.component';
import { ModalreservationretailsComponent } from './reservation/detailsreservation/modalreservationretails/modalreservationretails.component';
import { FrmenfantComponent } from './collectivite/detailstad/frmenfant/frmenfant.component';
import { FrmtransfertenfantComponent } from './foyer/frmtransfertenfant/frmtransfertenfant.component';
import { FrmtadprestataireComponent } from './collectivite/detailstad/frmtadprestataire/frmtadprestataire.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TemplatesSimulationComponent } from './fonction/templates-simulation/templates-simulation.component';
import { FrmTemplateSimulationComponent } from './fonction/frm-template-simulation/frm-template-simulation.component';
import {BonComponent} from './reservation/bon/bon.component';
import {FrminformationchauffeurComponent} from './reservation/frminformationchauffeur/frminformationchauffeur.component';
import {FrmchauffeurreservationComponent} from './reservation/frmchauffeurreservation/frmchauffeurreservation.component';
import {NgxNotificationMsgModule} from 'ngx-notification-msg';
import {LstprestatairetadComponent} from './tad/lstprestatairetad/lstprestatairetad.component';
import {FrmptadprestataireComponent} from './tad/frmptadprestataire/frmptadprestataire.component';
import {DuplicateComponent} from './reservation/duplicate/duplicate.component';
import {DetailstemplateprixComponent} from './templateprix/detailstemplateprix/detailstemplateprix.component';
import {FeriesComponent} from './referentiel/feries/feries.component';
import {ConfigurationComponent} from './referentiel/configuration/configuration.component';
import {FrmCircuitComponent} from './reservation/frm-circuit/frm-circuit.component';
import {UploadFilesComponent} from './uploads/upload-files/upload-files.component';
import {SortablejsModule} from 'ngx-sortablejs';
import {HistoriqueSimulationComponent} from './fonction/historique-simulation/historique-simulation.component';
import {DetailsHistoriqueComponent} from './fonction/details-historique/details-historique.component';
import {AlertsComponent} from './alerts/alerts/alerts.component';
import {TemplatesComponent} from './alerts/templates/templates.component';
import {FrmTemplateAlertComponent} from './alerts/frm-template-alert/frm-template-alert.component';
import { FrmcollectivitefoyerComponent } from './collectivite/frmcollectivitefoyer/frmcollectivitefoyer.component';
import { PfdViewerComponent } from './reservation/note/pfd-viewer/pfd-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FormFerieComponent } from './referentiel/feries/form-ferie/form-ferie.component';
import { ContacttadsComponent } from './tad/contacttads/contacttads.component';
import { LstdossiersComponent } from './dossiers/lstdossiers/lstdossiers.component';
import { LstprevalsComponent } from './dossiers/lstprevals/lstprevals.component';
import { LstaccordsComponent } from './dossiers/lstaccords/lstaccords.component';
import {ViewDocComponent} from "./view-doc/view-doc.component";
import { RegistreComponent } from './registre/registre.component';
import { ViewCardComponent } from './view-card/view-card.component';
import { DossierComponent } from './dossier/dossier.component';
//import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
//import {SortablejsModule} from "ngx-sortablejs";

//import { FrmuseradminComponent } from './security/users/frmuseradmin/frmuseradmin.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LstreservationsComponent,
    FrmreservationComponent,
    LstorgansisationsComponent,
    LstprestatairesComponent,
    LstfoyersComponent,
    LstacteursComponent,
    LstcollaborateursComponent,
    LsttemplatePrixComponent,
    LoginComponent,
    StatutComponent,
    MotifComponent,
    TypetemplateComponent,
    FrmpassagerComponent,
    FonctionComponent,
    VilleComponent,
    LstacteursComponent,
    FrmtypetemplateComponent,
    FrmcontactpassagerComponent,
    SecurityComponent,
    FrmtemplateprixComponent,
    LstcollectivitesComponent,
    LsttadsComponent,
    LsttemplateprixComponent,
    LstchauffeursComponent,
    FrmchauffeursComponent,
    FrmfoyersComponent,
    LstpensionnairesComponent,
    FrmpensionnairesComponent,
    FrmprestatairesComponent,
    DetailsprestatairesComponent,
    DetailsfoyersComponent,
    FrmcollectivitesComponent,
    FrmtadComponent,
    FrmcontactsComponent,
    LsttadComponent,
    LstcontactsComponent,

    FrmcollaborateursprestatairesComponent,
    LstcollaborateurscollectiviteComponent,
    LsttemplateprixcollectiviteComponent,
    LstmotifscollectiviteComponent,
    LstcollaborateursfoyersComponent,
    FrmcollaborateursfoyersComponent,
    DetailscollectiviteComponent,
    DetailstadComponent,
    UsersComponent,
    FrmuserComponent,
    FrmresatemplateComponent,
    FrmmotifsComponent,
    FrmcollaborateursComponent,
    FrmuserComponent,
    FrmcollaborateursfoyersComponent,
    LstbudgetComponent,
    FrmbudgetComponent,
    FrmcollectivitecollaborateurComponent,
    FrmcollectivitetadComponent,
    FrmcollectiviteprestataireComponent,
    FrmcollectivitefoyerComponent,
    FrmmotifcollectiviteComponent,
    LstactiviteComponent,
    MenuComponent,
    LstsimulationComponent,
    LststatistiqueComponent,
    DetailsreservationComponent,
    TraiterComponent,
    CarteComponent,
    CalendrierComponent,
    PassagersComponent,
    LstpenssionnairetadComponent,
    LstcollaborateurtadComponent,
    LstbudgettadComponent,
    LstaffectetadComponent,
    LstprestatairescollectvitesComponent,
    LstfoyercollectvitesComponent,
    FrmaffecteComponent,
    CalendrierComponent,
    CarteComponent,
    ModalreservationretailsComponent,
    FrmenfantComponent,
    NoteComponent,
    FrmtransfertenfantComponent,
    TemplatesSimulationComponent,
    FrmTemplateSimulationComponent,

    FrmtadprestataireComponent,

    BonComponent,

    FrminformationchauffeurComponent,

    FrmchauffeurreservationComponent,
    LstprestatairetadComponent,
    FrmptadprestataireComponent,
    DuplicateComponent,
    DetailstemplateprixComponent,
    ConfigurationComponent,
    FeriesComponent,
    FrmCircuitComponent,
    UploadFilesComponent,
    HistoriqueSimulationComponent,
    DetailsHistoriqueComponent,
    AlertsComponent,
    TemplatesComponent,
    FrmTemplateAlertComponent,
    PfdViewerComponent,
    FormFerieComponent,
    ContacttadsComponent,
    LstdossiersComponent,
    LstprevalsComponent,
    LstaccordsComponent,
    ViewDocComponent,
    RegistreComponent,
    ViewCardComponent,
    DossierComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FullCalendarModule,
    NgxPaginationModule,
    NgxNotificationMsgModule,
    NgxSpinnerModule,
    NgxExtendedPdfViewerModule,
    SortablejsModule.forRoot({animation: 150}),

  ],
  providers: [Tool, NgxSpinnerService],
  bootstrap: [AppComponent]
})
export class AppModule {
     /*public static API_ENDPOINT = 'http://localhost:8080/api/';
    public static APP_SERVER = 'http://localhost:4200/';
     public static API_ENDPOINT_V2 = 'http://localhost:8080/';
  public static APP_HOME = 'http://localhost:4200/accueil';*/


  public static API_ENDPOINT = 'https://vps84300.serveur-vps.net:8080/api/';
  public static API_ENDPOINT_V2 = 'https://vps84300.serveur-vps.net:8080/';
  public static APP_SERVER = 'https://askia-bo.ms-media.pro/';
  public static APP_HOME = 'https://askia-bo.ms-media.pro/accueil/';





}
