<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Référentiel
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Statut
      </small>
    </h1>

  </div>


  <div class="px-2">


    <div class="card dcard">
      <div class="card-body px-1 px-md-3">

        <form autocomplete="off">
          <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
              </div>

            <div class="mb-2 mb-sm-0">

            </div>
          </div>

          <table
            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
              <tr>
                <th class="text-center pr-0">

                </th>
               
                <th>
                  Code
                </th>
                <th >

                </th>
                
                <th>
                  Libellé
                </th>
              </tr>
            </thead>

            <tbody class="mt-1">
              <tr *ngFor="let stat of data| paginate : {
                itemsPerPage: tableSize,
                currentPage: page,
                totalItems: count}; let i = index" [class.active]="i == currentIndex" class="bgc-h-yellow-l4 d-style">
                <td class="text-center pr-0 pos-rel">
                  <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                    <!-- border shown on hover -->
                  </div>
                  <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                    <!-- border shown when row is selected -->
                  </div>


                </td>

                <td>
                  <span class="text-dark-d1 text-600 text-95">{{stat.code}}</span>
                </td>
                <td>
                  
                </td>
                <td>
                  <a class="text-blue-d1 text-600 text-95">{{stat.libelle}}</a>
                </td>

               
              </tr>
            </tbody>
          </table>

          <!-- table footer -->
          <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
            <div class="text-nowrap align-self-center align-self-sm-start">
                Afficher 1 - {{ tableSize }} of {{data.length}}
                <select (change)="tableData($event)" class="custom-select">
                    <option *ngFor="let size of tableSizesArr" [value]="size">
                        Afficher {{ size }}
                    </option>
                </select>
            </div>
            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                    (pageChange)="tabSize($event)">
                </pagination-controls>
            </div>
            <div>

            </div>
        </div>
        </form>

      </div><!-- /.card-body -->
    </div>



  </div>

</div>


<div class="modal fade modal-ds " id="modalForm" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Statut
        </h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="">
          <div class="">

            <form class="mt-lg-3">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Code
                  </label>
                </div>

                <div class="col-sm-9">
                  <input type="text" [(ngModel)]="code" name="code" class="form-control col-sm-8 col-md-3">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Libellé
                  </label>
                </div>

                <div class="col-sm-9">
                  <input type="text" [(ngModel)]="libelle" name="libelle" class="form-control col-sm-8 col-md-6">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Description
                  </label>
                </div>

                <div class="col-sm-9">
                  <textarea class="form-control" [(ngModel)]="description" name="description" placeholder=""
                    style="overflow: hidden; overflow-wrap: break-word; resize: horizontal; height: 62px;"></textarea>
                </div>
              </div>

            </form>

          </div><!-- /.card-body -->
        </div>


      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Fermer
        </button>
        <button type="button" class="btn btn-primary">
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
