import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActeurService } from 'src/app/services/acteur.service';
import { BudgetService } from 'src/app/services/budget.service ';
import { FoyerService } from 'src/app/services/foyer.service';
import { StructureService } from 'src/app/services/structure.service';
import { VilleService } from 'src/app/services/ville.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
declare function stransAdresse(id: any);
declare var $: any;
declare function stransAdresseNative(id: any);
@Component({
  selector: 'app-frmtad',
  templateUrl: './frmtad.component.html',
  styleUrls: ['./frmtad.component.css']
})
export class FrmtadComponent implements OnInit {

  data: any = [];
  tad: any = null;

  collectivites: any = [];
  lblnomtad;
  siret: any = '';
  nom: any = '';
  adresse: any = '';
  responsable: any = '';
  email: any = '';
  collectivite: any = '';
  codepostal: any = '';
  telephone: any = '';
  telephonePortable: any = '';
  typestructure: any = '';
  ville: any = '';
  parent;
  codePostal: any = '';
  structure: any = '';
  selectedcol: any = '';
  information: any ='';
  errorNom = false;
  errorEmail = false;
  errorTel = false;
  test = true;
  infoErrorNom = "Le nom est obligatoire";
  infoErrorTel = "";
  infoErrorTelFixe = "";
  errorTelFixe =false;
  errorSelectcol = false;
  infoErrorEmail ='L\'email est obligatoire';

  @ViewChild('adresseField') adresseField;
  constructor(private http: HttpClient,
    private structureService: StructureService, private villeService: VilleService
    , private foyerService: FoyerService, private acteurService: ActeurService
    , private budgetService: BudgetService) { }

  ngOnInit(): void {
    stransAdresse('adresse');
    this.showCollectivite();
  }

  showCollectivite(): void {
    this.structureService.getStructuresByType(TypeStructureModel.COLLECTIVITE).subscribe(data => {
      this.collectivites = data;

    });

  }

  show(parent) {
    this.parent = parent;


//stransAdresseNative(this.adresseField.nativeElement);


    if (this.selectedcol != '') {
      //  alert(this.selectedcollectivite)
      // this.selectstructure=this.structure.id;
      $("#strus").attr("disabled", true);
    }else{
      $("#strus").attr("disabled", false);
    }
    
    this.showCollectivite();
    $('#modalTad').modal('show');
  }

  save(): void {
  //  this.errorNom = true;
  //  this.errorTel = true;
    if (this.valideInput()) {
      $('#adresse')[0].dispatchEvent(new Event('input', { bubbles: true }));
      $('#ville')[0].dispatchEvent(new Event('input', { bubbles: true }));
      $('#codePostal')[0].dispatchEvent(new Event('input', { bubbles: true }));
      const headers = {};
      const body = {
        nom: this.nom,
        adresse: this.adresse, telephone: this.telephone, telephonePortable: this.telephonePortable,
        responsable: this.responsable, ville: this.ville, codePostal: this.codePostal, information: this.information,
        collectivite: { id: this.selectedcol },
        email: this.email, typestructure: "TAD", actif: true
      };
      console.log(this.structure);
      if (!this.structure) {
        this.structureService.saveTad(headers, body).subscribe(data => {
          $('#modalTad').modal('hide');
          this.parent.show();


        });
      } else {
        this.structureService.update(headers, body, this.structure.id).subscribe(data => {
          $('#modalTad').modal('hide');
          this.parent.show();

        });
      }
      this.resetForm();
    }
  }

  resetForm() {
    this.structure = null;
    this.siret = '';
    this.nom = '';
    this.adresse = '';
    this.responsable = '';
    this.email = '';
    this.telephone = '';
    this.telephonePortable = '';
    this.ville = '';
    this.codePostal = '';
    this.selectedcol = '';
    this.errorNom = false;
    this.errorEmail = false;
    this.errorTel = false;
    this.errorSelectcol = false;
    this.information = null;
    this.errorTelFixe = false;
    this.infoErrorTelFixe = null;
    
    $('#modalTad').modal('hide');
  }
  edit(parent, id: any): void {
    $("#strus").attr("disabled", true);
    // this.showCollectivite();
    this.parent = parent;
    this.structureService.get(id).subscribe(data => {
      this.structure = data;
      //  this.siret = this.structure.siret;

      this.nom = this.structure.nom;
      this.telephone = this.structure.telephone;
      this.telephonePortable = this.structure.telephonePortable;
      this.adresse = this.structure.adresse;
      this.responsable = this.structure.responsable;
      this.information = this.structure.information;
      this.email = this.structure.email;
      this.ville = this.structure.ville;
      this.codePostal = this.structure.codePostal;
      this.typestructure = this.structure.typestructure;
      this.selectedcol = this.structure.collectivite.id;
      $('#modalTad').modal('show');
      // alert(this.structure.collectivite.id)
    });

  }

  valideEmail() {
    var validRegex = "^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$";
    if (!!this.email && this.email.trim().length != 0) {
      if (this.email.match(validRegex)) {
        this.errorEmail = false;
      } else {
        this.errorEmail = true;
        this.infoErrorEmail="L'email est invalide";
      }
    } else {
      this.errorEmail = true;
      // this.infoErrorEmail="L'email est obligatoire";
    }
    return this.errorEmail;
  }
  valideTel() {
    var reg = /^(06|07)[0-9]{8}/gi;
    if(!!this.telephonePortable){
      this.telephonePortable = this.telephonePortable.replaceAll(' ', '');
      this.telephonePortable = this.telephonePortable.replaceAll('-', '');
      if (this.telephonePortable.length == 0) {
        this.errorTel = false;
        // this.infoErrorTel = 'champ obligatoir';
      } else
        if (this.telephonePortable.length != 0) {
          if (this.telephonePortable.length == 10) {
            if (!this.telephonePortable.match(reg)) {
              this.errorTel = true;
              this.infoErrorTel = 'Le numero doit imperativement commencer par 06 ou 07.';
            } else {
              this.errorTel = false;
            }
          } else {
            // if(this.telephone.length < 10)
            this.infoErrorTel = 'Vous avez saisi ' + this.telephonePortable.length + '.La longueur requise est de 10 chiffres.';
            this.errorTel = true;
            // this.infoError ="Vous avez saisie "
          }
        } else {
          this.errorTel = false;
        }
    }else{
      this.errorTel = true;
      this.infoErrorTel = 'Le téléphone portable est obligatoire';
    }

    return this.errorTel;
  }
  valideTelFixe() {

    var reg = /^(01|02|03|04|05|08|09)[0-9]{8}/gi;
    if(!!this.telephone){
      this.telephone = this.telephone.replaceAll(' ', '');
      this.telephone = this.telephone.replaceAll('-', '');
      if (this.telephone.length == 0) {
        this.errorTel = false;
        // this.infoErrorTel = 'champ obligatoir';
      } else
        if (this.telephone.length != 0) {
          if (this.telephone.length == 10) {
            if (!this.telephone.match(reg)) {
              alert();
              this.errorTelFixe = true;
              this.infoErrorTelFixe = 'Le numero doit imperativement commencer par 01 ou 02 ou 03 ou 04 ou 05 ou 08 ou 09.';
            } else {
              this.errorTelFixe = false;
            }
          } else {
            // if(this.telephone.length < 10)
            this.infoErrorTelFixe = 'Vous avez saisi ' + this.telephone.length + '.La longueur requise est de 10 chiffres.';
            this.errorTelFixe = true;
            // this.infoError ="Vous avez saisie "
          }
        } else {
          this.errorTelFixe = false;
        }
    }else{
      this.errorTelFixe = true;
      this.infoErrorTelFixe = 'Le téléphone fixe est obligatoire'
    }

    return this.errorTelFixe;
  }


  valideInput() {
    if (this.selectedcol == '' || !this.selectedcol) {
      this.errorSelectcol = true;
      this.test = true;
    } else {
      this.errorSelectcol = false;
    }
    if (!this.nom || this.nom.trim().length == 0) {this.errorTelFixe = false;
      this.errorNom = true;
      this.test = false;
    } else {
      this.errorNom = false;
      this.test = true;
    }

    if (this.valideEmail() == true) {
      this.test = false;
    }
    if (this.valideTel() == true) {
      this.test = false;
    }
    if (this.valideTelFixe() == true) {
      this.test = false;
    }
    return this.test;
  }
}
