import {Component, OnInit} from '@angular/core';
import {FrmreservationComponent} from '../frmreservation/frmreservation.component';
import {HttpClient} from '@angular/common/http';
import {ContactpassagerService} from '../../services/contactpassager.service';
import {ActeurService} from '../../services/acteur.service';
import {TypeActeurModel} from '../../shared/models/type-acteur-model.model';
import {TypeStructureModel} from '../../shared/models/type-structure-model.model';
import {StructureService} from "../../services/structure.service";
import {NgxSpinnerService} from "ngx-spinner";

declare var $: any;
declare function showPoperError(message: any);


@Component({
  selector: 'app-frmcontactpassager',
  templateUrl: './frmcontactpassager.component.html',
  styleUrls: ['./frmcontactpassager.component.css']
})
export class FrmcontactpassagerComponent implements OnInit {
  telephone2: any;
  telephone1: any;
  email: any;
  adresse: any;
  nom: any;
  prenom: any;

  frmreservationComponent: FrmreservationComponent;
  passager: any;
  personne: any;
  type: any;
  contacts: any = [];
  isAll = false;
  contactPsgId = 0;
  tad: any;
  search: any;
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;

  constructor(private http: HttpClient, private structureService: StructureService, private contactpassagerService: ContactpassagerService, private acteurService: ActeurService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
  }

  getContacts() {
    let seachString = this.search;
    if (!this.search || this.search === '') {
      seachString = 'null';
    }
    // tslint:disable-next-line:max-line-length
    this.acteurService.getActeurRattacheeByStructAnParentSearch(TypeActeurModel.CONTACT, this.tad?.collectivite?.id, seachString).subscribe(data => {
      this.contacts = data;
    });
  }

  show(frmreservationComponent: FrmreservationComponent, passager: any,  tad: any, type: any) {

    this.frmreservationComponent = frmreservationComponent;
    this.passager = passager;
    //alert(passager);
    this.type = type;
    console.log(tad);

    this.passager.contacts.forEach((ctcPsg: any) => {
      if (ctcPsg.type === type) {
        this.contactPsgId = ctcPsg.id;
        //   alert(this.contactPsgId);
      }
    });

    this.structureService.get(tad.id).subscribe(data => {
      this.tad = data;
      this.getContacts();

    });


    $('#modalFormContactPassager').modal('show');


  }


  saveContact() {

   if(this.personne){
    if (this.isAll) {
      this.saveAll();
    } else {
      this.spinner.show();
      const headers = {};
      const body = {id: null, actif: 1, passager: this.passager, personne: this.personne, type: this.type};

      if (this.contactPsgId > 0) {
        body.id = this.contactPsgId;
      }


      this.contactpassagerService.save(headers, body).subscribe(data => {
        this.frmreservationComponent.refreshPassengers();

        this.spinner.hide();
        $('#modalFormContactPassager').modal('hide');

      });


    }
    this.reset();
   }else{
    // alert("veillez selectionner d'abord un contact");
     showPoperError('Veuillez sélectionner un contact' );
   }


  }


  ctcChange(ctc: any) {
    this.personne = ctc.personne;
  }
reset(){
  this.isAll = false;
  this.personne = null;
  this.search = null;
}

  setIsAll(e) {
    if (e.target.checked) {
      this.isAll = true;
    } else {
      this.isAll = false;
    }

  }

  saveAll() {
    this.spinner.show();
    this.frmreservationComponent.passagers.forEach((psg: any) => {
      console.log('Passenger contact' + psg.contacts);
      let saved = false;
      if (psg.contacts.length > 0) {
        psg.contacts.forEach((ctc: any) => {
          if (!!ctc && ctc.type === this.type) {
            saved = true;
            console.log('Passenger ' + psg.id + ' Contact ' + ctc.id)

            const headers = {};
            const body = {id: ctc.id, actif: 1, passager: psg, personne: this.personne, type: this.type};
            this.contactpassagerService.save(headers, body).subscribe(data => {
              this.spinner.hide();
              this.frmreservationComponent.refreshPassengers();
            });

          }
        });
      }
      if (!saved) {
        const headers = {};
        const body = {id: null, actif: 1, passager: psg, personne: this.personne, type: this.type};

        /*if (this.contactPsgId > 0) {
          body.id = this.contactPsgId;
        }*/


        this.contactpassagerService.save(headers, body).subscribe(data => {
          this.spinner.hide();
          this.frmreservationComponent.refreshPassengers();

        });
      }

    });

    $('#modalFormContactPassager').modal('hide');
  }

  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.contacts = this.contacts;
    // this.showData();
  }
}
