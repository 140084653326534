<div class="radius-">
    <ul class="nav nav-tabs nav-tabs-faded bgc-info-m3 pt-2 pl-2 radius-t-1" role="tablist">
        <li class="nav-item mr-3px" *ngIf="this.canConsulterTAD">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0 active"
                data-toggle="tab" href="#home2" role="tab" aria-controls="home2" aria-selected="true">
                Unités
            </a>
        </li>

        <li class="nav-item mr-3px" *ngIf="this.canConsulterFoyer">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                data-toggle="tab" href="#profile2" role="tab" aria-controls="profile2" aria-selected="false">
                Lieu d'hébergement
            </a>
        </li>

        <li class="nav-item mr-3px" *ngIf="this.canConsulterPrestataire">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                data-toggle="tab" href="#prestataires" role="tab" aria-controls="prestataires" aria-selected="false">
                Prestataires
            </a>
        </li>

        <li class="nav-item mr-3px" *ngIf="this.canConsulterCollaborateur">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                data-toggle="tab" href="#collaborateur" role="tab" aria-controls="collaborateur" aria-selected="false">
                Collaborateurs
            </a>
        </li>
        <li class="nav-item mr-3px" *ngIf="this.canConsulterContact">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
                Contacts
            </a>
        </li>
        <li class="nav-item mr-3px" *ngIf="this.canConsulterMotifs">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                data-toggle="tab" href="#motif" role="tab" aria-controls="motif" aria-selected="false">
                Motifs
            </a>
        </li>


    </ul>

    <div class="tab-content bgc-white p-35 border-1 border-t-0 brc-default-l2 radius-b-1">
        <div *ngIf="this.canConsulterTAD" class="tab-pane fade text-95 active show" id="home2" role="tabpanel"
        style="overflow-y: scroll; height:400px;">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button type="button" *ngIf="this.canCreateTAD" (click)="openFormTad()"
                                    data-toggle="modal" data-target="#modalTAD"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>

                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card w-100 mt-1 mb-1">
                            <div class="card-body p-0">
                                <div class="accordion">
                                    <div class="card border-0 mt-1px bgc-primary-l5">
                                        <div class="card-header border-0 bgc-transparent mb-0">
                                            <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                                <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                    href="#rechercheAvanceeDetailColTAD" data-toggle="collapse"
                                                    aria-expanded="false" aria-controls="rechercheAvanceeDetailColTAD">
                                                    Recherche avancée

                                                    <span
                                                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                        <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                    </span>
                                                    <span
                                                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                        <i
                                                            class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                    </span>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="rechercheAvanceeDetailColTAD" class="collapse"
                                            aria-labelledby="headingTwo4" data-parent="#rechercheAvanceeDetailColTAD"
                                            style="" (keyup.enter)="RechercheAvanceTAD()">
                                            <div
                                                class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                                <div class="form-group row">


                                                    <div class="col-md-4">

                                                        <div class="form-group row">
                                                            <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Nom
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-8 pl-2">
                                                                <input type="text" [(ngModel)]="nomtad" name="nomtad"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetNomTad()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-6 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Responsable
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-6 pl-2">
                                                                <input type="text" [(ngModel)]="responsabletad"
                                                                    name="responsabletad" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetResponsableTad()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-md-2">

                                                        <div class="form-group row">

                                                            <div class="mb-2 mb-sm-0">
                                                                <button type="button" (click)="RechercheAvanceTAD()"
                                                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                    <i class="fa fa-search mr-1"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>
                                    <th>
                                        Nom
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Responsable
                                    </th>
                                    <th class="d-none d-sm-table-cell">
                                        Téléphone fixe
                                    </th>
                                    <th class="d-none d-sm-table-cell">
                                        Téléphone portable
                                    </th>

                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="mt-1">
                                <ng-container *ngFor="let structure of data| paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count}; let i = index" [class.active]="i == currentIndex">
                                    <tr class="bgc-h-yellow-l4 d-style">

                                        <td class="text-blue-d1 text-600 text-95">
                                            {{structure.nom}}
                                        </td>

                                        <td class="text-blue-d1 text-600 text-95">
                                            {{structure.responsable}}
                                        </td>

                                        <td>
                                            <span class="text-dark-d1 text-600 text-95">{{structure.telephone}}</span>
                                        </td>
                                        <td>
                                            <span class="text-dark-d1 text-600 text-95">{{structure.telephonePortable}}</span>
                                        </td>

                                        <td class="text-center pr-0">
                                            <div>
                                                <a data-toggle="collapse"
                                                    [attr.data-target]="'#detail-presta-'+structure.id"
                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                    title="Show Details" aria-expanded="false">
                                                    <span class="d-none d-md-inline mr-1">
                                                        Details
                                                    </span>
                                                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                </a>
                                            </div>
                                        </td>

                                        <td>
                                            <!-- action buttons -->
                                            <div class="d-none d-lg-flex">
                                                <a href="#" *ngIf="this.canUpdateTAD" (click)="editTad(structure.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                                                    <i class="fa fa-pencil-alt"></i>
                                                </a>

                                                <a href="#" *ngIf="this.canDeleteTAD" (click)="delete(structure.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>

                                            </div>

                                            <!-- show a dropdown in mobile -->
                                            <div
                                                class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                <a href="#"
                                                    class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    <i class="fa fa-cog"></i>
                                                </a>

                                                <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                    <div class="dropdown-inner">
                                                        <div
                                                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                                                            Prestataire 1
                                                        </div>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i> Edit
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                            Delete
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                            Autre
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>


                                    <!-- detail row -->
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse" id="{{'detail-presta-'+structure.id}}">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div
                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                            <div class="bgc-white radius-1">
                                                                <table
                                                                    class="table table table-striped-default table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-info text-success"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Information complémentaire
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{structure.information}}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i
                                                                                    class="far fa-envelope text-blue"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Email
                                                                            </td>

                                                                            <td class="text-blue-d1 text-wrap">
                                                                                {{structure.email}}
                                                                            </td>
                                                                        </tr>

                                                        
                                                                        
                                                                        <tr>
                                                                            <td>
                                                                                <i
                                                                                    class="fa fa-map-marker text-orange-d1"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Adresse
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{structure.adresse}}
                                                                            </td>
                                                                        </tr>


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </ng-container>



                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{data.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>
        </div>

        <div *ngIf="this.canConsulterFoyer" class="tab-pane fade text-95" id="profile2" role="tabpanel"
        style="overflow-y: scroll; height:400px;">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button type="button" *ngIf="this.canCreateFoyer"
                                    (click)="openFormCollectStructureFoyer('FOYER','Lieu d´hébergement')" data-toggle="modal"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>
                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card w-100 mt-1 mb-1">
                            <div class="card-body p-0">
                                <div class="accordion">
                                    <div class="card border-0 mt-1px bgc-primary-l5">
                                        <div class="card-header border-0 bgc-transparent mb-0">
                                            <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                                <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                    href="#rechercheAvanceefoyerDetailcollectivite"
                                                    data-toggle="collapse" aria-expanded="false"
                                                    aria-controls="rechercheAvanceefoyerDetailcollectivite">
                                                    Recherche avancée

                                                    <span
                                                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                        <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                    </span>
                                                    <span
                                                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                        <i
                                                            class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                    </span>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="rechercheAvanceefoyerDetailcollectivite" class="collapse"
                                            aria-labelledby="headingTwo4"
                                            data-parent="#rechercheAvanceefoyerDetailcollectivite" style=""
                                            (keyup.enter)="RechercheAvanceCollFoyer()">
                                            <div
                                                class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                                <div class="form-group row">
                                                    <!--      <div class="col-md-4">
    
                                                        <div class="form-group row">
                                                          <div class="col-sm-4 col-form-label text-sm-right pr-2 " >
                                                            <label class="mb-0">
                                                              Structure
                                                            </label>
                                                          </div>
                          
                                                          <div class="col-sm-8 pl-2">
                                                            <select  class="form-control" [(ngModel)]="idcollectivite" name="idcollectivite" >
                                                                <option value="">&nbsp;</option>
                                                                <option *ngFor="let col of collectivites;" value="{{col.id}}">{{col.nom}}</option>
                                                            </select>
                                                          </div>
                                                        </div>
                          
                                                      </div>-->
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Siren
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="siretfoyer"
                                                                    name="siretfoyer" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetSiretFoyer()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Nom
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="nomfoyer"
                                                                    name="nomfoyer" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                                    (click)='resetNomFoyer()'>
                                                                    Effacer
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                                <div class="form-group row">
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Email
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="emailfoyer"
                                                                    name="emailfoyer" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                                    (click)='resetEmailFoyer()'>
                                                                    Effacer
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Responsable
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="responsablefoyer"
                                                                    name="responsablefoyer" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                                    (click)='resetResponsableFoyer()'>
                                                                    Effacer
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Adresse
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="adressefoyer"
                                                                    name="adressefoyer" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                                    (click)='resetAdresseFoyer()'>
                                                                    Effacer
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Téléphone
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="telephonefoyer"
                                                                    name="telephonefoyer" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetTelephoneFoyer()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    

                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    
                                                                </label>
                                                            </div>

                                                            <div class="col-md-1">

                                                                <div class="form-group row">
            
                                                                    <div class="mb-2 mb-sm-0">
                                                                        <button type="button"
                                                                            (click)="RechercheAvanceCollFoyer()"
                                                                            class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                            <i class="fa fa-search mr-1"></i>
            
                                                                        </button>
                                                                    </div>
                                                                </div>
            
                                                            </div>
                                                        </div>

                                                    </div>
                                                   
                                                    

                                                </div>
                                                

                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>
                                    <th>
                                        SIREN
                                    </th>

                                    <th>
                                        Nom
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Email
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Adresse
                                    </th>

                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="mt-1">
                                <ng-container *ngFor="let structure of datafoyers| paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count}; let i = index" [class.active]="i == currentIndex">
                                    <tr class="bgc-h-yellow-l4 d-style">


                                        <td class="text-blue-d1 text-600 text-95">
                                            {{structure.structure.siret}}
                                        </td>
                                        <td class="text-blue-d1 text-600 text-95">
                                            {{structure.structure.nom}}
                                        </td>

                                        <td class="text-blue-d1 text-600 text-95">
                                            {{structure.structure.email}}
                                        </td>

                                        <td class="text-blue-d1 text-600 text-95">
                                            {{structure.structure.adresse}}
                                        </td>

                                        <td class="text-center pr-0">
                                            <div>
                                                <a data-toggle="collapse"
                                                    [attr.data-target]="'#detail-presta-'+structure.structure.id"
                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                    title="Show Details" aria-expanded="false">
                                                    <span class="d-none d-md-inline mr-1">
                                                        Details
                                                    </span>
                                                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                </a>
                                            </div>
                                        </td>

                                        <td>
                                            <!-- action buttons -->
                                            <div class="d-none d-lg-flex">

                                                <a href="#" *ngIf="this.canDeleteFoyer"
                                                    (click)="deleteCollctiviteStructure(structure.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>

                                            </div>

                                            <!-- show a dropdown in mobile -->
                                            <div
                                                class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                <a href="#"
                                                    class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    <i class="fa fa-cog"></i>
                                                </a>

                                                <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                    <div class="dropdown-inner">
                                                        <div
                                                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                                                            Prestataire 1
                                                        </div>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i> Edit
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                            Delete
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                            Autre
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>

                                    <!-- detail row -->
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse"
                                                id="{{'detail-presta-'+structure.structure.id}}">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div
                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                            <div class="bgc-white radius-1">
                                                                <table
                                                                    class="table table table-striped-default table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="far fa-user text-success"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Responsable
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{structure.structure.responsable}}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i
                                                                                    class="far fa-envelope text-blue"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Email
                                                                            </td>

                                                                            <td class="text-blue-d1 text-wrap">
                                                                                {{structure.structure.email}}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-phone text-purple"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Téléphone fixe
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{structure.structure.telephone}}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i
                                                                                    class="fa fa-map-marker text-orange-d1"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Adresse
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{structure.structure.adresse}}
                                                                            </td>
                                                                        </tr>


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </ng-container>
                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{datafoyers.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>

        </div>

        <div *ngIf="this.canConsulterPrestataire" class="tab-pane fade text-95" id="prestataires" role="tabpanel"
        style="overflow-y: scroll; height:400px;">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button type="button" *ngIf="this.canCreatePrestataire"
                                    (click)="openFormCollectStructure('PRESTATAIRE','Nouveau Prestataire')" data-toggle="modal"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>

                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card w-100 mt-1 mb-1">
                            <div class="card-body p-0">
                                <div class="accordion">
                                    <div class="card border-0 mt-1px bgc-primary-l5">
                                        <div class="card-header border-0 bgc-transparent mb-0">
                                            <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                                <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                    href="#rechercheAvanceePrestataireDetailcollectivite"
                                                    data-toggle="collapse" aria-expanded="false"
                                                    aria-controls="rechercheAvanceePrestataireDetailcollectivite">
                                                    Recherche avancée

                                                    <span
                                                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                        <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                    </span>
                                                    <span
                                                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                        <i
                                                            class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                    </span>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="rechercheAvanceePrestataireDetailcollectivite" class="collapse"
                                            aria-labelledby="headingTwo4"
                                            data-parent="#rechercheAvanceePrestataireDetailcollectivite" style=""
                                            (keyup.enter)="RechercheAvanceCollPrestaire()">
                                            <div
                                                class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                                <div class="form-group row">
                                                    <!--                       <div class="col-md-4">
    
                                                        <div class="form-group row">
                                                          <div class="col-sm-4 col-form-label text-sm-right pr-2 " >
                                                            <label class="mb-0">
                                                              Structure
                                                            </label>
                                                          </div>
                          
                                                          <div class="col-sm-8 pl-2">
                                                            <select  class="form-control" [(ngModel)]="idcollectivite" name="idcollectivite" id="collect" >
                                                                <option value="">&nbsp;</option>
                                                                <option *ngFor="let col of datacollectivites;" value="{{col.id}}">{{col.nom}}</option>
                                                            </select>
                                                          </div> 
                                                        </div>
                          
                                                      </div>-->
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Siren
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-5 pl-2">
                                                                <input type="text" [(ngModel)]="siretprestataire"
                                                                    name="siretprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetSiret()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Nom
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="nomprestataire"
                                                                    name="nomprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetNom()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                                <!-- <div class="form-group row">
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Email
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="emailprestataire"
                                                                    name="emailprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetEmail()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Responsable
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="responsableprestataire"
                                                                    name="responsableprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetResponsable()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Adresse
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="adresseprestataire"
                                                                    name="adresseprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetAdresse()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Téléphone
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="telephoneprestataire"
                                                                    name="telephoneprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetTelephonefixe()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    

                                                </div> -->
                                                <div class="form-group row">
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    
                                                                </label>
                                                            </div>

                                                            <div class="col-md-4">

                                                                <div class="form-group row">
        
        
                                                                    <div class="mb-2 mb-sm-0">
                                                                        <button type="button"
                                                                            (click)="RechercheAvanceCollPrestaire()"
                                                                            class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                            <i class="fa fa-search mr-1"></i>
        
                                                                        </button>
                                                                    </div>
                                                                </div>
        
                                                            </div>
                                                        </div>

                                                    </div>
                                                   

                                                </div>


                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>

                                    <th>
                                        SIREN
                                    </th>

                                    <th>
                                        Nom
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Responsable
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Adresse
                                    </th>
                                    

                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="mt-1">
                                <ng-container *ngFor="let structure of dataprestataires
                                | paginate : {
                                        itemsPerPage: tableSize,
                                        currentPage: page,
                                        totalItems: count}; let i = index" [class.active]="i == currentIndex">
                                    <tr class="bgc-h-yellow-l4 d-style">

                                        <td>
                                            <span
                                                class="text-dark-d1 text-600 text-95">{{structure.structure.siret}}</span>
                                        </td>
                                        <td class="text-blue-d1 text-600 text-95">
                                            {{structure.structure.nom}}
                                        </td>

                                        <td class="text-blue-d1 text-600 text-95">
                                            {{structure.responsable}}
                                        </td>

                                        <td class="text-blue-d1 text-600 text-95">
                                            {{structure.structure.adresse}}
                                        </td>
                                        <td class="text-center pr-0">
                                            <div>
                                                <a data-toggle="collapse"
                                                    [attr.data-target]="'#detail-presta-'+structure.id"
                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                    title="Show Details" aria-expanded="false">
                                                    <span class="d-none d-md-inline mr-1">
                                                        Details
                                                    </span>
                                                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                </a>
                                            </div>
                                        </td>

                                        <td>
                                            <!-- action buttons -->
                                            <div class="d-none d-lg-flex">
                                                <a href="#" *ngIf="this.canUpdatePrestataire" (click)="editPres(structure.id)"
                                                class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                                                <i class="fa fa-pencil-alt"></i>
                                            </a>

                                                <a href="#" *ngIf="this.canDeletePrestataire"
                                                    (click)="deleteCollctiviteStructure(structure.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>

                                            </div>

                                            <!-- show a dropdown in mobile -->
                                            <div
                                                class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                <a href="#"
                                                    class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    <i class="fa fa-cog"></i>
                                                </a>

                                                <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                    <div class="dropdown-inner">
                                                        <div
                                                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                                                            Prestataire 1
                                                        </div>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i> Edit
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                            Delete
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                            Autre
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>


                                    <!-- detail row -->
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse"
                                                id="{{'detail-presta-'+structure.id}}">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div
                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                            <div class="bgc-white radius-1">
                                                                <table
                                                                    class="table table table-striped-default table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-calendar text-success"></i>
                                                                            </td>
    
                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                Date de Création
                                                                            </td>
    
                                                                            <td class="text-dark-m3">
                                                                                {{structure.structure.datecreation |
                                                                                date:'dd-MM-yyyy'}}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i
                                                                                    class="far fa-envelope text-blue"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Email
                                                                            </td>

                                                                            <td class="text-blue-d1 text-wrap">
                                                                                {{structure.email}}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-phone text-purple"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Téléphone fixe
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{structure.telephone}}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i
                                                                                    class="fa fa-map-marker text-orange-d1"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Adresse
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{structure.structure.adresse}}
                                                                            </td>
                                                                        </tr> 


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </ng-container>
                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{dataprestataires.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>
        </div>

        <div *ngIf="this.canConsulterCollaborateur" class="tab-pane fade text-95" id="collaborateur" role="tabpanel"
        style="overflow-y: scroll; height:400px;">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button type="button" *ngIf="this.canCreateCollaborateur"
                                    (click)="openFormActeur('COLLABORATEUR','COLLECTIVITE')" data-toggle="modal"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>

                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card w-100 mt-1 mb-1">
                            <div class="card-body p-0">
                                <div class="accordion">
                                    <div class="card border-0 mt-1px bgc-primary-l5">
                                        <div class="card-header border-0 bgc-transparent mb-0">
                                            <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                                <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                    href="#rechercheAvanceeCollaborateurCol" data-toggle="collapse"
                                                    aria-expanded="false"
                                                    aria-controls="rechercheAvanceeCollaborateurCol">
                                                    Recherche avancée

                                                    <span
                                                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                        <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                    </span>
                                                    <span
                                                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                        <i
                                                            class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                    </span>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="rechercheAvanceeCollaborateurCol" class="collapse"
                                            aria-labelledby="headingTwo4"
                                            data-parent="#rechercheAvanceeCollaborateurCol" style=""
                                            (keyup.enter)="rechercheAvanceCollaborateur()">
                                            <div
                                                class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                                <div class="form-group row">

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Prénom/Nom
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input [(ngModel)]="prenomcollaborateur"
                                                                    name="prenomcollaborateur" type="text"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetPrenomCol()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>
<!-- 
                                                    <div class="col-md-4">

                                                        <div class="form-group row">
                                                            <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Nom
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-8 pl-2">
                                                                <input [(ngModel)]="nomcollaborateur"
                                                                    name="nomcollaborateur" type="text"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetNomCol()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div> -->

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Email
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input [(ngModel)]="emailcollaborateur"
                                                                    name="emailcollaborateur" type="text"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetEmailCol()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>


                                                <div class="form-group row">

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Adresse
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input [(ngModel)]="adressecollaborateur"
                                                                    name="adressecollaborateur" type="text"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetAdresseCol()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Téléphone
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input [(ngModel)]="telephonecollaborateur"
                                                                    name="telephonecollaborateur" type="text"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetTelephoneCol()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                   

                                                </div>
                                                <div class="form-group row">

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    
                                                                </label>
                                                            </div>

                                                            <div class="mb-2 mb-sm-0">
                                                                <button (click)="rechercheAvanceCollaborateur()"
                                                                    type="button"
                                                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                    <i class="fa fa-search mr-1"></i>

                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                   
                                                   

                                                </div>
                                            


                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>

                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>
                                    <th class="text-center pr-0">

                                    </th>
                                    <th>
                                        Nom
                                    </th>

                                    <th>
                                        Prénom
                                    </th>

                                    <th>
                                        Email
                                    </th>
                                    <th>
                                        Fonction
                                    </th>



                                    <th class="d-none d-sm-table-cell">
                                        Téléphone Fixe
                                    </th>
                                    <th class="d-none d-sm-table-cell">
                                        Téléphone Portable
                                    </th>
                                    <th class="d-none d-sm-table-cell">

                                    </th>

                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="mt-1">
                                <ng-container *ngFor="let col of collaborateurs| paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count};  let i = index;" [class.active]="i == currentIndex">
                                    <tr class="bgc-h-yellow-l4 d-style">
                                        <td class="text-center pr-0 pos-rel">
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                                <!-- border shown on hover -->
                                            </div>
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                                <!-- border shown when row is selected -->
                                            </div>
                                        </td>

                                        <td>
                                            <a class="text-blue-d1 text-600 text-95">
                                                {{(!!col.personne) ? col.personne.nom:''}}</a>
                                        </td>

                                        <td class="text-600 text-orange-d2">
                                            {{(!!col.personne) ? col.personne.prenom:''}}
                                        </td>

                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{ (!!col.personne) ? col.personne.email:''}}
                                        </td>
                                        <td class="text-dark-m3">
                                            {{(!!col.fonction) ? col.fonction.libelle:''}}
                                        </td>


                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{(!!col.personne) ? col.personne.telephone1:''}}
                                        </td>
                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{(!!col.personne) ? col.personne.telephone2:''}}
                                        </td>
                                        <td class="text-center pr-0">
                                            <div>
                                                <a href="#" data-toggle="collapse"
                                                    [attr.data-target]="'#detail-col-'+col.id"
                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                    title="Show Details" aria-expanded="false">
                                                    <span class="d-none d-md-inline mr-1">
                                                        Details
                                                    </span>
                                                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <!-- action buttons -->
                                            <div class="d-none d-lg-flex">
                                                <a href="#" *ngIf="this.canUpdateCollaborateur"
                                                    (click)="editActeur(col.id,'COLLABORATEUR')"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                                                    <i class="fa fa-pencil-alt"></i>
                                                </a>

                                                <a href="#" *ngIf="this.canDeleteCollaborateur"
                                                    (click)="deleteActeur(col.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>

                                            </div>

                                            <!-- show a dropdown in mobile -->
                                            <div
                                                class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                <a href="#"
                                                    class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    <i class="fa fa-cog"></i>
                                                </a>

                                                <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                    <div class="dropdown-inner">
                                                        <div
                                                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">

                                                        </div>
                                                        <a href="#" (click)="edit(col.id)" class="dropdown-item">
                                                            <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                                            Modifier
                                                        </a>
                                                        <a href="#" (click)="delete(col.id)" class="dropdown-item">
                                                            <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                            Supprimer
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                            Autre
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse" id="{{'detail-col-'+col.id}}">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div
                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                            <div class="bgc-white radius-1">
                                                                <table class="table table table-striped-default table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-info text-success"></i>
                                                                            </td>

                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                Information complémentaire
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{(!!col.personne) ? col.personne.information:''}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-map-marker text-success"></i>
                                                                            </td>

                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                adresse
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{(!!col.personne) ? col.personne.adresse:''}}
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{collaborateurs.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>
        </div>

        <div *ngIf="this.canConsulterContact" class="tab-pane fade text-95" id="contact" role="tabpanel"
        style="overflow-y: scroll; height:400px;">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button type="button" *ngIf="this.canCreateContact"
                                    (click)="openFormActeur('CONTACT','COLLECTIVITE')" data-toggle="modal"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>

                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card w-100 mt-1 mb-1">
                            <div class="card-body p-0">
                                <div class="accordion">
                                    <div class="card border-0 mt-1px bgc-primary-l5">
                                        <div class="card-header border-0 bgc-transparent mb-0">
                                            <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                                <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                    href="#rechercheAvanceeContact" data-toggle="collapse"
                                                    aria-expanded="false" aria-controls="rechercheAvanceeContact">
                                                    Recherche avancée

                                                    <span
                                                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                        <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                    </span>
                                                    <span
                                                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                        <i
                                                            class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                    </span>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="rechercheAvanceeContact" class="collapse" aria-labelledby="headingTwo4"
                                            data-parent="#rechercheAvanceeContact" style=""
                                            (keyup.enter)="rechercheAvanceContact()">
                                            <div
                                                class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                                <div class="form-group row">

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Prénom/Nom
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input [(ngModel)]="prenomcontact" name="prenomcontact"
                                                                    type="text" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetPrenomContact()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <!-- <div class="col-md-4">

                                                        <div class="form-group row">
                                                            <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Nom
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-8 pl-2">
                                                                <input [(ngModel)]="nomcontact" name="nomcontact"
                                                                    type="text" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetNomContact()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div> -->

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Email
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input [(ngModel)]="emailcontact" name="emailcontact"
                                                                    type="text" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetEmailContact()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>


                                                <div class="form-group row">

                                                    <!--     <div class="col-md-4">

                                                        <div class="form-group row">
                                                            <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Adresse
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-8 pl-2">
                                                                <input [(ngModel)]="adressecontact"
                                                                    name="adressecontact" type="text"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetAdresseContact()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div> --> 

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Téléphone
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input [(ngModel)]="telephonecontact"
                                                                    name="telephonecontact" type="text"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetTelephoneContact()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-md-4">

                                                        <div class="form-group row">


                                                            <div class="mb-2 mb-sm-0">
                                                                <button (click)="rechercheAvanceContact()" type="button"
                                                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                    <i class="fa fa-search mr-1"></i>

                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>

                                    <th>
                                        Nom
                                    </th>

                                    <th>
                                        Prénom
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Téléphone fixe
                                    </th>
                                    <th class="d-none d-sm-table-cell">
                                        Téléphone portable
                                    </th>


                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="mt-1">
                                <ng-container *ngFor="let col of contacts| paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count}; let i = index" [class.active]="i == currentIndex">
                                    <tr class="bgc-h-yellow-l4 d-style">

                                        <td class="text-dark-d1 text-600 text-95">
                                            {{col.personne.nom}}
                                        </td>

                                        <td class="text-drak-d1 text-600 text-95">
                                            <span> {{col.personne.prenom}}</span>
                                        </td>

                                        <td class="text-dark-d1 text-600 text-95">
                                            {{col.personne.telephone1}}
                                        </td>

                                        <td class="text-dark text-600 text-95">
                                            {{col.personne.telephone2}}
                                        </td>

                                        <td class="text-center pr-0">
                                            <div>
                                                <a href="#" data-toggle="collapse"
                                                    [attr.data-target]="'#detail-col-'+col.id"
                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                    title="Show Details" aria-expanded="false">
                                                    <span class="d-none d-md-inline mr-1">
                                                        Details
                                                    </span>
                                                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <!-- action buttons -->
                                            <div class="d-none d-lg-flex">
                                                <a href="#" *ngIf="this.canUpdateContact"
                                                    (click)="editActeur(col.id,'CONTACT')"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                                                    <i class="fa fa-pencil-alt"></i>
                                                </a>

                                                <a href="#" *ngIf="this.canDeleteContact" (click)="deleteActeur(col.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>

                                            </div>

                                            <!-- show a dropdown in mobile -->
                                            <div
                                                class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                <a href="#"
                                                    class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    <i class="fa fa-cog"></i>
                                                </a>

                                                <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                    <div class="dropdown-inner">
                                                        <div
                                                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">

                                                        </div>
                                                        <a href="#" (click)="edit(col.id)" class="dropdown-item">
                                                            <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                                            Modifier
                                                        </a>
                                                        <a href="#" (click)="delete(col.id)" class="dropdown-item">
                                                            <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                            Supprimer
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                            Autre
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse" id="{{'detail-col-'+col.id}}" style="">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div
                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                            <div class="bgc-white radius-1">
                                                                <table
                                                                    class="table table table-striped-default table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-info text-success"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Information complémentaire
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{col.personne.information}}
                                                                               
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i
                                                                                    class="far fa-envelope text-blue"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Email
                                                                            </td>

                                                                            <td class="text-blue-d1 text-wrap">
                                                                                {{col.personne.email}}
                                                                            </td>
                                                                        </tr>

                                                                        

                                                                      


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </ng-container>
                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{contacts.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>
        </div>

        <div *ngIf="this.canConsulterMotifs" class="tab-pane fade text-95" id="motif" role="tabpanel"
        style="overflow-y: scroll; height:400px;">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button type="button" *ngIf="this.canCreateMotifs" (click)="openFormMotif()"
                                    data-toggle="modal"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>

                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card w-100 mt-1 mb-1">
                            <div class="card-body p-0">
                                <div class="accordion">
                                    <div class="card border-0 mt-1px bgc-primary-l5">
                                        <div class="card-header border-0 bgc-transparent mb-0">
                                            <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                                <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                    href="#rechercheAvanceemotifcollectivite" data-toggle="collapse"
                                                    aria-expanded="false"
                                                    aria-controls="rechercheAvanceemotifcollectivite">
                                                    Recherche avancée

                                                    <span
                                                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                        <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                    </span>
                                                    <span
                                                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                        <i
                                                            class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                    </span>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="rechercheAvanceemotifcollectivite" class="collapse"
                                            aria-labelledby="headingTwo4"
                                            data-parent="#rechercheAvanceemotifcollectivite" style=""
                                            (keyup.enter)="RechercheAvanceCollMotif()">
                                            <div
                                                class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                                <div class="form-group row">
                                                    <!--              <div class="col-md-4">
    
                                                        <div class="form-group row">
                                                          <div class="col-sm-4 col-form-label text-sm-right pr-2 " >
                                                            <label class="mb-0">
                                                              Structure
                                                            </label>
                                                          </div>
                          
                                                          <div class="col-sm-8 pl-2">
                                                            <select  class="form-control" [(ngModel)]="idcollectivitemotif" name="idcollectivitemotif" >
                                                                <option value="">&nbsp;</option>
                                                                <option *ngFor="let col of collectivites;" value="{{col.id}}">{{col.nom}}</option>
                                                            </select>
                                                          </div>
                                                        </div>
                          
                                                      </div>-->
                                                    <div class="col-md-8">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Libellé
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="libellemotofs"
                                                                    name="libellemotofs" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetPrenomMotif()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-md-4">

                                                        <div class="form-group row">


                                                            <div class="mb-2 mb-sm-0">
                                                                <button type="button"
                                                                    (click)="RechercheAvanceCollMotif()"
                                                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                    <i class="fa fa-search mr-1"></i>

                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>

                                    <th>
                                        Code
                                    </th>

                                    <th>
                                    </th>

                                    <th>

                                    </th>
                                    <th>
                                        Libellé
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="mt-1">

                                <ng-container *ngFor="let motif of motifs
                                | paginate : {
                                        itemsPerPage: tableSize,
                                        currentPage: page,
                                        totalItems: count}; let i = index" [class.active]="i == currentIndex">
                                    <tr class="bgc-h-yellow-l4 d-style">
                                        <td>
                                            <span class="text-dark-d1 text-600 text-95">{{motif.motif.code}}</span>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                            <span class="text-dark-d1 text-600 text-95">{{motif.motif.libelle}}</span>
                                        </td>
                                        <td class="text-center pr-0">
                                            <div>
                                                <a href="#" data-toggle="collapse"
                                                    [attr.data-target]="'#detail-motif-'+motif.id"
                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                    title="Show Details" aria-expanded="false">
                                                    <span class="d-none d-md-inline mr-1">
                                                        Details
                                                    </span>
                                                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <!-- action buttons -->
                                            <div class="d-none d-lg-flex">

                                                <a href="#" *ngIf="this.canDeleteMotifs" (click)="deleteMotif(motif.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>
                                            </div>

                                            <!-- show a dropdown in mobile -->

                                        </td>
                                    </tr>
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse" id="{{'detail-motif-'+motif.id}}"
                                                style="">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div
                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                            <div class="bgc-white radius-1">
                                                                <table
                                                                    class="table table table-striped-default table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="far fa-user text-success"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Code
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{motif.motif.code}}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i
                                                                                    class="far fa-envelope text-blue"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Libellé
                                                                            </td>

                                                                            <td class="text-blue-d1 text-wrap">
                                                                                {{motif.motif.libelle}}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-phone text-purple"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Description
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{motif.motif.description}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{motifs.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>
        </div>


    </div>
</div>
<app-frmcollectivitetad #frmcollectivitetad></app-frmcollectivitetad>
<app-frmmotifcollectivite #frmmotifsComponent></app-frmmotifcollectivite>
<app-frmcollectivitecollaborateur #frmcollaborateur></app-frmcollectivitecollaborateur>
<app-frmcollectiviteprestataire #frmcollectivitetprestataire></app-frmcollectiviteprestataire>
<app-frmcollectivitefoyer #frmcollectivitetfoyer></app-frmcollectivitefoyer>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
