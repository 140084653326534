import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FrmcollectivitecollaborateurComponent } from 'src/app/collectivite/frmcollectivitecollaborateur/frmcollectivitecollaborateur.component';
import { ActeurService } from 'src/app/services/acteur.service';
import { FoyerService } from 'src/app/services/foyer.service';
import { StructureService } from 'src/app/services/structure.service';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { FrmcollaborateursfoyersComponent } from '../frmcollaborateursfoyers/frmcollaborateursfoyers.component';
import { FrmpensionnairesComponent } from '../frmpensionnaires/frmpensionnaires.component';
import { FrmenfantComponent } from 'src/app/collectivite/detailstad/frmenfant/frmenfant.component';
import { FrmtransfertenfantComponent } from '../frmtransfertenfant/frmtransfertenfant.component';
import { PaginationService } from 'src/app/services/pagination.service';
import { SecurityService } from 'src/app/services/security.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
import Swal from 'sweetalert2';
@Component({
  selector: 'app-detailsfoyers',
  templateUrl: './detailsfoyers.component.html',
  styleUrls: ['./detailsfoyers.component.css']
})
export class DetailsfoyersComponent implements OnInit {

  @ViewChild('frmfoyersComponent') frmfoyersComponent: FrmcollaborateursfoyersComponent;
  @ViewChild('frmpensionnairesComponent') frmpensionnairesComponent: FrmpensionnairesComponent;
  @ViewChild('frmcollaborateur') frmcollaborateur: FrmcollectivitecollaborateurComponent;
  @ViewChild('frmenfants') frmenfants: FrmenfantComponent;
  @ViewChild('frmtransfertenfants') frmtransfertenfants: FrmtransfertenfantComponent;
  constructor(private http: HttpClient, private foyerService: FoyerService
    , private acteurService: ActeurService, private structureService: StructureService,
    private paginationService: PaginationService, private securiteService: SecurityService, 
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.getAge(this.datenaissanceEnfant);

  }

  // Partie pensionnaire

  datapensionnaires: any = [];
  datacollaborateurs: any = [];
  pensionnaire: any = null;
  collaborateur: any = null;
  parent;
  typeacteur: any = '';
  selectedOrg: any;
  orgId: any;
  colSelected: any;
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30]; 
  currentIndex: any;
  page1 = 1;
  count1 = 0;
  tableSize1 = 10;
  tableSizesArr1 = [10, 20, 30];
  currentIndex1: any;

  idFoyer;

  user: User = new User();

  prenomEnfant: any = null;
  nomEnfant: any = null;
  emailEnfant: any = null;
  adresseEnfant: any = null;
  datenaissanceEnfant: any = null;
  telephoneEnfant: any = null;

  prenomCollaborateur: any = null;
  nomCollaborateur: any = null;
  emailCollaborateur: any = null;
  adresseCollaborateur: any = null;
  datenaissanceCollaborateur: any = null;
  telephoneCollaborateur: any = null;

  canCreateEnfant = false;
  canCreateCollaborateur = false;
  canEditCollaborateur = false;
  canDeleteCollaborateur = false;
  canTransfertEnfant = false;
  canDeleteEnfant = false;
  canShowEnfant = false;
  canShowCollaborateur = false;
  lstcollaborateurscollectivite = false;

  details(parent, id: any): void {
    this.parent = parent;
    this.idFoyer = id.id;
    this.showPensionnaire(id.id);
    this.showCollaborateur(id.id);
    this.frmcollaborateur.structure = id;
    this.frmenfants.structure = id;
    this.frmtransfertenfants.structure = id;
    this.frmcollaborateur.selectstructure = id.id;
    this.frmenfants.selectstructure = id.id;
    this.showDoits();

  }
  resetPrenom(){
    this.prenomEnfant = null;
  }
  resetNom(){
    this.nomEnfant = null;
  }
  resetAdresse(){
    this.adresseEnfant = null;
  }
  resetEmail(){
    this.emailEnfant = null;
  }
  resetTelephone(){
    this.telephoneEnfant = null;
  }
  resetPrenomCol(){
    this.prenomCollaborateur = null;
  }
  resetNomCol(){
    this.nomCollaborateur = null;
  }
  resetAdresseCol(){
    this.adresseCollaborateur = null;
  }
  resetEmailCol(){
    this.emailCollaborateur = null;
  }
  resetTelephoneCol(){
    this.telephoneCollaborateur = null;
  }
  rechercheAvanceEnfant() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.prenomEnfant = this.prenomEnfant ? this.prenomEnfant : null;
    this.nomEnfant = this.nomEnfant ? this.nomEnfant : null;
    this.emailEnfant = this.emailEnfant ? this.emailEnfant : null;
    this.adresseEnfant = this.adresseEnfant ? this.adresseEnfant : null;
    this.telephoneEnfant = this.telephoneEnfant ? this.telephoneEnfant : null;
    // alert(this.user.structure)
    this.acteurService.getRecherche(this.prenomEnfant, this.nomEnfant, this.emailEnfant, this.adresseEnfant,
      this.telephoneEnfant, TypeActeurModel.PENSIONNAIRE, this.idFoyer, TypeStructureModel.FOYER).subscribe(data => {
        this.datapensionnaires = data;
        this.spinner.hide();

      });
      this.tabSize(event);

  }

  rechercheAvanceCollaborateur() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.prenomCollaborateur = this.prenomCollaborateur ? this.prenomCollaborateur : null;
    this.nomCollaborateur = this.nomCollaborateur ? this.nomCollaborateur : null;
    this.emailCollaborateur = this.emailCollaborateur ? this.emailCollaborateur : null;
    this.adresseCollaborateur = this.adresseCollaborateur ? this.adresseCollaborateur : null;
    this.telephoneCollaborateur = this.telephoneCollaborateur ? this.telephoneCollaborateur : null;
    // alert(this.user.structure)
    this.acteurService.getRecherche(this.prenomCollaborateur, this.nomCollaborateur, this.emailCollaborateur, this.adresseCollaborateur,
      this.telephoneCollaborateur, TypeActeurModel.COLLABORATEUR, this.idFoyer, TypeStructureModel.FOYER).subscribe(data => {
        this.datacollaborateurs = data;
        this.spinner.hide();
      });
      this.tabSize(event);

  }

  showDoits() {

    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_COLLABORATEUR_FOYER").subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canShowCollaborateur = true;
      } else {
        this.canShowCollaborateur = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_COLLABORATEUR_ADD_FOYER").subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canCreateCollaborateur = true;
      } else {
        this.canCreateCollaborateur = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_COLLABORATEUR_DEL_FOYER").subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canDeleteCollaborateur = true;
      } else {
        this.canDeleteCollaborateur = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_EDIT_COLLABORATEUR_FOYER").subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canEditCollaborateur = true;
      } else {
        this.canEditCollaborateur = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_ENFANT_FOYER").subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canShowEnfant = true;
      } else {
        this.canShowEnfant = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_ENFANT_ADD_FOYER").subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canCreateEnfant = true;
      } else {
        this.canCreateEnfant = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_ENFANT_DEL_FOYER").subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canDeleteEnfant = true;
      } else {
        this.canDeleteEnfant = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_ENFANT_EDIT_FOYER").subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canTransfertEnfant = true;
      } else {
        this.canTransfertEnfant = false;
      }
    });
  }
  show(parent) {
    this.parent = parent;
    $('#modalDetailsFoyer').modal('show');

  }
  filter(parent, foyer: any) {
    //alert(foyer);
    this.details(parent, foyer);
  }

  transferer(enfant: any): void {
    this.frmtransfertenfants.enfant = enfant;
    this.frmtransfertenfants.personne = enfant.personne;
    this.frmtransfertenfants.typestructure = 'FOYER';
    this.frmtransfertenfants.lblstructure = 'Lieu hebergement';
    this.frmtransfertenfants.lblnom = 'Transfert enfant';
    this.frmtransfertenfants.show(this);
  }
  openFormEnfant(typeacteur: any, typestructure: any): void {
    // this.frmenfants.selectstructure= this.tadSelected.id;
    this.frmenfants.typestructure = typestructure;
    this.frmenfants.lblnom = 'Enfant';
    this.frmenfants.lblstructure = 'Foyer'
    this.frmenfants.typeacteur = typeacteur;
    this.frmenfants.show(this);
  }
  openFormActeur(typeacteur: any, typestructure: any): void {
    this.lstcollaborateurscollectivite = false;
    this.frmcollaborateur.typestructure = typestructure;
    this.frmcollaborateur.lblstructure = 'Foyer';
    //  this.frmcollaborateur.lblnom = 'Collaborateur';
    if (typeacteur == 'COLLABORATEUR') {
      this.frmcollaborateur.lblnom = 'Nouveau Collaborateur';
      this.lstcollaborateurscollectivite = true;
    } else if (typeacteur == 'PENSIONNAIRE') {
      this.frmcollaborateur.lblnom = 'Nouveau Enfant';
    }
    this.frmcollaborateur.typeacteur = typeacteur;
    this.frmcollaborateur.show(this);


  }

  editCol(id: any) {
    this.frmcollaborateur.editPersonne(this, id);
    this.frmcollaborateur.lblnom = 'Modification Collaborateur';
  }

  deleteCol(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.acteurService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.details(this, this.frmcollaborateur.structure);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.acteurService.delete(id).subscribe(data => {
    //     this.details(this, this.frmcollaborateur.structure);
    //   });
    // }
  }

  editPen(id: any) {
    this.frmcollaborateur.editPersonne(this, id);
    this.frmcollaborateur.lblnom = 'Modification Collaborateur';
  }

  deletePen(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.acteurService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.details(this, this.frmcollaborateur.structure);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.acteurService.delete(id).subscribe(data => {
    //     this.details(this, this.frmcollaborateur.structure);
    //   });
    // }
  }
  show1(): void {
    this.acteurService.getData().subscribe(data => {
      this.datapensionnaires = data;
      this.pensionnaire = null;
    });

  }

  showPensionnaire(id: any): void {
    // this.pensionnaire = pens;
    this.acteurService.getActeurRattachee(TypeActeurModel.PENSIONNAIRE, id).subscribe(data => {
      this.datapensionnaires = data;
      this.pensionnaire = null;
    });
  }

  showCollaborateur(id: any): void {
    this.acteurService.getActeurRattachee(TypeActeurModel.COLLABORATEUR, id).subscribe(data => {
      this.datacollaborateurs = data;
      this.collaborateur = null;
    });

  }

  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.datapensionnaires = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }

  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }

  tabSize1(event) {
    this.page1 = event;
    // this.showData();
  }

  tableData1(event): void {
    this.tableSize1 = event.target.value;
    this.page1 = 1;
    // this.showData();
  }

  getAge(dateNaissance) {

    var timeDiff = Math.abs(Date.now() - new Date(dateNaissance).getTime());
    var age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

    return age;
  }
  
}
