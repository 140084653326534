<div class="modal fade modal-ds" data-backdrop="static" id="modalAffecte" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Affectation
                </h5>

                <button type="button" class="close" (click)="fermer()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                        Unité <label style="color:red">
                            *
                        </label>
                    </label>
                </div>

                <div class="col-sm-9">
                    <select (change)="openSelect()" [(ngModel)]="selecttad" name="selecttad" class="form-control"
                        id="tadservice">
                        <option value="">&nbsp;</option>
                        <option *ngFor="let col of tads;" value="{{col.id}}">{{col.nom}}</option>
                    </select>
                </div>
            </div>
            <table
                class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                    <tr>
                        <th class="text-center pr-0">
                            #
                        </th>

                        <th>
                            Nom
                        </th>

                        <th>
                            Prénom
                        </th>

                        <th class="d-none d-sm-table-cell">
                            Adresse
                        </th>

                        <th class="d-none d-sm-table-cell">

                        </th>

                        <th></th>
                    </tr>
                </thead>

                <tbody class="mt-1">
                    <tr *ngFor="let acteur of data;" class="bgc-h-yellow-l4 d-style">
                        <td class="text-center pr-0 pos-rel">
                            <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                <!-- border shown on hover -->
                            </div>
                            <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                <!-- border shown when row is selected -->
                            </div>

                            <label>
                                <input type="checkbox" [value]="acteur.personne.id" (change)="oncpChange($event)"
                                    class="align-middle">
                            </label>
                        </td>

                        <td>
                            <a  class="text-blue-d1 text-600 text-95">{{acteur.personne.nom}}</a>
                        </td>

                        <td class="text-600 text-orange-d2">
                            {{acteur.personne.prenom}}
                        </td>

                        <td class="d-none d-sm-table-cell text-grey-d1">
                            {{acteur.personne.adresse}}
                        </td>

                    </tr>

                </tbody>
            </table>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="fermer()">
                    Fermer
                </button>
                <button type="button" class="btn btn-primary" (click)="save()">
                    Enregistrer
                </button>

            </div>
        </div>
    </div>
</div>