import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActeurService } from 'src/app/services/acteur.service';
import { StructureService } from 'src/app/services/structure.service';
import { PersonneService } from 'src/app/services/personne.service ';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { formatDate } from '@angular/common';
import { User } from 'src/app/shared/session/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FonctionService } from 'src/app/services/fonction.service';

declare var $: any;
declare function stransAdresse(id: any);
declare function stransAdresseNative(id: any);
declare function showPoperError(message: any);

@Component({
  selector: 'app-frmcollectivitecollaborateur',
  templateUrl: './frmcollectivitecollaborateur.component.html',
  styleUrls: ['./frmcollectivitecollaborateur.component.css']
})
export class FrmcollectivitecollaborateurComponent implements OnInit {

  data: any = [];
  prestataires: any = [];
  collaborateur: any = null;

  fonctions: any = [];

  code: any = '';
  prenom: any = '';
  nom: any = '';
  email: any = '';
  adresse: any = '';
  datenaissance: any = '';
  telephone1: any = '';
  telephone2: any = '';
  information: any = '';
  fonction: any = null;
  typeacteur: any = '';
  personne: any = '';
  structure: any;
  collectivites: any = [];
  ville: any = '';
  parent;
  codePostal: any = '';
  error = false;
  errorprenom = false;
  errorEmail = false;
  errorTel = false;
  errorTelFixe = false;
  errorDate = false;
  test = true;
  infoError = "Champs invalide";
  infoErrorTelFixe = "Champs invalide";
  dateNow = new Date().getFullYear();
  infoDate = 'champs invalide';
  typestructure: TypeStructureModel;
  lblstructure;
  lblnom;
  selectstructure: any = null;
  user: User = new User();
  today: any;
  dd: any;
  mm: any;
  yyyy: any;
  date: any;
  showAction = true;
  showInputFonction = false;
  showInputdateNaissance = true;
  showInputTelephoneFixe = true;
  showInputAdresse = true;

  @ViewChild('acteurModal') acteurModal;
  @ViewChild('adresseField') adresseField;


  constructor(private http: HttpClient, private acteurService: ActeurService,
    private structureService: StructureService, private authenticationService: AuthenticationService,
    private personneService: PersonneService, private fonctionService: FonctionService) { }


  ngOnInit(): void {
    this.user = this.authenticationService.getUser();

    this.valideDateNaiss();

  }

  showStructure(): void {
    this.structureService.getStructuresByType(this.typestructure).subscribe(data => {
      this.collectivites = data;

    });

  }

  showFonction(): void {
    this.fonctionService.getData().subscribe(data => {
      this.fonctions = data;

    });

  }

  show(parent) {
    this.parent = parent;
    
    if (this.selectstructure != null) {
      $(".coll").attr("disabled", true);
    }else{
      $(".coll").attr("disabled", false);
    }
    
    if (parent.lstcollaborateurscollectivite) {
      this.showInputFonction = true;
    }else{
      this.fonction = '2';
      this.showInputFonction = false;
    }
    if (!parent.dateNaissance) {
      this.showInputdateNaissance = true;
    }else{
      this.showInputdateNaissance = false;
    }
    if (!parent.telephoneFixeParent) {
      this.showInputTelephoneFixe = true;
    }else{
      this.showInputTelephoneFixe = false;
    }
    if (!parent.adresseParent) {
      this.showInputAdresse = true;
    }else{
      this.showInputAdresse = false;
    }
    this.showStructure();
    this.showFonction();
    $(this.acteurModal.nativeElement).modal('show');
    setTimeout(()=> {
      stransAdresse("adresse");
    },3000);
    
  }

  savePersonne(): void {
    this.error = true;
    this.errorprenom = true;
    if((!this.telephone1 || this.telephone1.trim().length == 0) && (!this.telephone2 || this.telephone2.trim().length == 0) && this.parent.name==true){
      showPoperError('Vous devez saisir au moins l\' un des numéros');
      this.valideInput();
    }else{
      if (this.valideInput()) {
        this.pushDataPersonne();
      }
    }
    
  }


  pushDataPersonne() {
    if(this.showInputAdresse){
      $('#adresse')[0].dispatchEvent(new Event('input', { bubbles: true }));
      $('#ville')[0].dispatchEvent(new Event('input', { bubbles: true }));
      $('#codePostal')[0].dispatchEvent(new Event('input', { bubbles: true }));
     // console.log("Adrese = ",this.adresse);
    }
    
    const headers = {};
    const body = {
      id: null, code: this.code, nom: this.nom, prenom: this.prenom,
      adresse: this.adresse, telephone1: this.telephone1, telephone2: this.telephone2,
      datenaissance: this.datenaissance, information: this.information, ville: this.ville
      , codePostal: this.codePostal, email: this.email, actif: true
    };
    console.log(this.collaborateur);
    if (!this.collaborateur) {
      this.personneService.save(headers, body).subscribe(data => {
        this.personne = data;
        this.save();
        this.parent.filter(null, this.structure);

      });
    } else {
      body.id = this.collaborateur.personne.id;
      this.personneService.update(headers, body, this.collaborateur.personne.id).subscribe(data => {
         this.personne=data;
        this.save();
        this.parent.filter(null, this.structure);
        //  $('#modalActeur').modal('hide');
      });
    }
    //  this.resetForm();
    $(this.acteurModal.nativeElement).modal('hide');
  }


  save(): void {

    const headers = {};
    const body = {
      personne: this.personne, structure: { id: this.selectstructure },
      typeacteur: this.typeacteur, fonction: { id: this.fonction }, actif: true
    };
    console.log(this.collaborateur);
    if (!this.collaborateur) {
      this.acteurService.save(headers, body).subscribe(data => {
        // this.personne=data;$('#modalActeur').modal('hide');
        $(this.acteurModal.nativeElement).modal('hide');
        this.parent.filter(null, this.structure);

      });
    } else {
      this.acteurService.update(headers, body, this.collaborateur.id).subscribe(data => {
        $(this.acteurModal.nativeElement).modal('hide');
        this.parent.filter(null, this.structure);
      });
    }
    this.resetForm();

  }
  fermer() {
    this.resetForm();
    $(this.acteurModal.nativeElement).modal('hide');

  }
  resetForm() {
    this.collaborateur = null;
    this.personne = null;
    this.fonction = null;
    this.selectstructure = null; 
    this.nom = null;
    this.prenom = null;
    this.adresse = null;
    this.email = null;
    this.telephone1 = null;
    this.telephone2 = null;
    this.datenaissance = null;
    this.information = null;
    this.ville = null;
    this.codePostal = null;
    this.error = false;
    this.errorprenom = false;
    this.errorEmail = false;
    this.errorTel = false;
    this.errorDate = false;
  }

  editPersonne(parent, id: any): void {
    // this.parent=parent;
    this.acteurService.get(id).subscribe(data => {
      this.collaborateur = data;
      if (!!this.collaborateur) {
        this.personne = this.collaborateur.personne;
        this.structure = this.collaborateur.structure;
        this.fonction = this.collaborateur.fonction;
        if (this.collaborateur.fonction)
          this.fonction = this.collaborateur.fonction.id;
        if (this.collaborateur.structure) {
          this.selectstructure = this.collaborateur.structure.id;
          this.typestructure = this.collaborateur.structure.typestructure;
        }
        if (this.collaborateur.personne)
        this.personne = this.collaborateur.personne.id;

        if (this.collaborateur.personne != null) {
          // alert( this.prenom)
          this.nom = this.collaborateur.personne.nom;
          this.prenom = this.collaborateur.personne.prenom;
          this.adresse = this.collaborateur.personne.adresse;
          this.email = this.collaborateur.personne.email;
          this.telephone1 = this.collaborateur.personne.telephone1;
          this.telephone2 = this.collaborateur.personne.telephone2;
          this.datenaissance = this.collaborateur.personne.datenaissance;
         // alert(this.datenaissance); 
          this.information = this.collaborateur.personne.information;
          this.ville = this.collaborateur.personne.ville;
          this.codePostal = this.collaborateur.personne.codePostal;

        }

      }


      this.show(parent)
      // $(this.acteurModal.nativeElement).modal('show');
      // alert();

    });
  }

  valideInput() {
    this.test = true;
    if (!this.prenom || this.prenom.trim().length == 0) {
      this.errorprenom = true;
      this.test = false;
    } else {
      this.errorprenom = false;
    }
    if (!this.nom || this.nom.trim().length == 0) {
      this.error = true;
      this.test = false;
    } else {
      this.error = false;
    }
    if (this.valideEmail() == true) {
      this.test = false;
    }
    if (this.valideTel() == true) {
      this.test = false;
    }
    if (this.valideTelFixe() == true) {
      this.test = false;
    }
    /* if (this.valideDateNaiss() == true) {
       this.test = false;
     } */
    return this.test;
  }

  valideDateNaiss() {
    // this.today = new Date();
    //document.getElementById("date").setAttribute("max", this.today);

    $('[type="date"]').prop('max', function () {
      return new Date().toJSON().split('T')[0];
    });
    if (this.datenaissance.trim() != "") {
      var age = this.dateNow - Number(this.datenaissance.substring(0, 4));
      // alert(this.dateNow < Number(this.datenaissance.substring(0, 4)))

      // if (age < 18 || age > 150) {
      // alert('échec et bammmmmmmmmmmmmmmmmmm')
      //   this.errorDate = true;
      //  this.infoDate = "La date est incorrecte.";
      //} else {
      //   this.errorDate = false;
      // }
      return this.errorDate;
    }

  }
  valideTel() {
    var reg = /^(06|07)[0-9]{8}/gi;
    if(!!this.telephone2){
      this.telephone2 = this.telephone2.replaceAll(' ', '');
      this.telephone2 = this.telephone2.replaceAll('-', '');
      if (this.telephone2.length != 0) {
        if (this.telephone2.length == 10) {
          if (!this.telephone2.match(reg)) {
            this.errorTel = true;
            this.infoError = 'Le numero saisi doit impérativement commencer par 06 ou 07.';
          } else {
            this.errorTel = false;
          }
        } else {
          this.infoError = 'Vous avez saisi ' + this.telephone2.length + '.La longueur requise est de 10 chiffres.';
          this.errorTel = true;
        }
      } else {
        // alert('No 10')
        this.errorTel = false;
      }
    }else{
      this.errorTel = false;
    }
    
    return this.errorTel;
  }
  valideTelFixe() {
    var reg = /^(01|02|03|04|05|08|09)[0-9]{8}/gi;
    if(!!this.telephone1){
      this.telephone1 = this.telephone1.replaceAll(' ', '');
      this.telephone1 = this.telephone1.replaceAll('-', '');

      if (this.telephone1.length != 0) {
        if (this.telephone1.length == 10) {
          if (!this.telephone1.match(reg)) {
            this.errorTelFixe = true;
            this.infoErrorTelFixe = 'Le numero saisi doit impérativement commencer par 01 ou 02 ou 03 ou 04 ou 05 ou 08 ou 09.';
          } else {
            this.errorTelFixe = false;
          }
        } else {
          this.infoErrorTelFixe = 'Vous avez saisi ' + this.telephone1.length + '.La longueur requise est de 10 chiffres.';
          this.errorTelFixe = true;
        }
      } else {
        // alert('No 10')
        this.errorTelFixe = false;
      }
    }else{
      this.errorTelFixe = false;
    }
    
    
    return this.errorTelFixe;
  }
  valideEmail() {
    var validRegex = "^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$";
    if (!!this.email && this.email.trim().length != 0) {
      if (this.email.match(validRegex)) {
        this.errorEmail = false;
      } else {
        this.errorEmail = true;
      }
    } else {
      this.errorEmail = false;
    }
    return this.errorEmail;
  }

}
