import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FrmcollectivitecollaborateurComponent } from 'src/app/collectivite/frmcollectivitecollaborateur/frmcollectivitecollaborateur.component';
import { FrmcollaborateursfoyersComponent } from 'src/app/foyer/frmcollaborateursfoyers/frmcollaborateursfoyers.component';
import { ActeurService } from 'src/app/services/acteur.service';
import { FonctionService } from 'src/app/services/fonction.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { PersonneService } from 'src/app/services/personne.service ';
import { StructureService } from 'src/app/services/structure.service';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { SecurityService } from 'src/app/services/security.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare  function  stransAdresse(id: any);
import Swal from 'sweetalert2';
import { FoyerService } from 'src/app/services/foyer.service';
declare var $: any;
@Component({
  selector: 'app-lstcollaborateurtad',
  templateUrl: './lstcollaborateurtad.component.html',
  styleUrls: ['./lstcollaborateurtad.component.css']
})
export class LstcollaborateurtadComponent implements OnInit {

  data: any = [];
  prestataires: any = [];
  collaborateur: any = null;

  fonctions: any = [];

  code: any='';
  prenom: any = null;
  nom: any = null;
  email: any = null;
  adresse: any = null;
  datenaissance: any = null;
  telephone: any = null;
  telephone2: any = null;
  information: any = null;
  fonction: any = null;
  telephone1;
  typeacteur: any = '';
  personne: any = '';
  structure: any = '';
  user: User = new User();
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex = -1;

  canCreate = false;
  canEdit = false;
  canDelete = false;
  canDetails = false;
  foyers: any = [];
  idfoyer = 0;
  lstcollaborateurscollectivite = true;

  @ViewChild ('frmfoyersComponent') frmfoyersComponent: FrmcollaborateursfoyersComponent;
  @ViewChild('frmcollaborateur') frmcollaborateur: FrmcollectivitecollaborateurComponent;
 
  constructor(private http: HttpClient, private acteurService: ActeurService,
    private fonctionService: FonctionService, private structureService: StructureService,
    private personneService: PersonneService , private securiteService: SecurityService,
    private authenticationService: AuthenticationService, private paginationService:PaginationService,
    private spinner: NgxSpinnerService,private foyerService: FoyerService) { }


  ngOnInit(): void {
    
    this.user = this.authenticationService.getUser();
    this.showDoits();
    this.showActeurStructure();
    this.showStructure();
    stransAdresse('adresse');
  }

  showDoits(){
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_COLLABORATEUR_TAD").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreate = true;
      }  else {
        this.canCreate = false;
      }
   });
   this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_COLLABORATEUR_TAD").subscribe(data => {
    console.log(this.data)
    if (!!data) {
      this.canDelete = true;
    }  else {
      this.canDelete = false;
    }
 });
 this.securiteService.getDroit(this.user.profilCode, "ACT_EDIT_COLLABORATEUR_TAD").subscribe(data => {
  console.log(this.data)
  if (!!data) {
    this.canEdit = true;
  }  else {
    this.canEdit = false;
  }
});
  }
  

  filter(parent:any, structure:any){
    this.showActeurStructure();
  }
 showActeurStructure(): void {
 // this.spinner.show();
  if(this.user.structure==0 || this.user.profilCode == "PF_TAD"){
   this.acteurService.getActeurRattacheesAll(TypeActeurModel.COLLABORATEUR,this.user.structure,TypeStructureModel.TAD).subscribe(data => {
    this.data = data;
   // console.log(JSON.stringify(this.data));
    this.collaborateur = null;
 //   this.spinner.hide();
 });
  }else if(this.user.profilCode == "PF_AGCOL" || this.user.profilCode == "PF_PRESTAIRE" || this.user.profilCode == "PF_GFOYER"){
    if(this.user.structure)
    this.acteurService.getActeurAllTAD(TypeActeurModel.COLLABORATEUR,this.user.structure,TypeStructureModel.TAD,this.user.id).subscribe(data => {
      this.data = data;
     // console.log(JSON.stringify(this.data));
      this.collaborateur = null;
   //   this.spinner.hide();
    });
  }

}
showStructure(): void {
  this.structureService.getTadByProfil(this.user.id).subscribe(data => {
    this.foyers = data;
  });

}
rechercheAvance() {
  this.spinner.show();
  $(".collapse").collapse('hide');
  if (this.idfoyer == null )
  this.idfoyer = 0;
  this.prenom = this.prenom ? this.prenom: null;
  this.nom = this.nom ? this.nom: null;
  this.email = this.email ? this.email: null;
  this.adresse = this.adresse ? this.adresse: null;
  this.telephone = this.telephone ? this.telephone: null; 
 // alert(this.user.structure)
  this.acteurService.getRechercheBis(this.idfoyer,this.prenom,this.nom,this.email,this.adresse,
    this.telephone,TypeActeurModel.COLLABORATEUR,this.user.structure,TypeStructureModel.TAD).subscribe(data => {
    this.data = data;
    this.structure = null;
    this.spinner.hide();
 });

}

openFormCollaborateurFoyer(typestructure:any) {
//  this.spinner.show();
  this.frmfoyersComponent.lblstructure='Unité';
  this.frmfoyersComponent.lblnom='Unité';
  this.frmfoyersComponent.typestructure=typestructure;
   this.frmfoyersComponent.show(this); 
 }

 openFormActeur(typeacteur:any,typestructure:any): void  {
  this.lstcollaborateurscollectivite = true;
 // this.spinner.show();
  if(this.user.structure!=0){
  //  alert()
    this.structureService.get(this.user.structure).subscribe(data => {
      this.frmcollaborateur.structure = data;
    });
    this.frmcollaborateur.selectstructure=this.user.structure 
  }
  this.frmcollaborateur.typestructure=typestructure;
  this.frmcollaborateur.lblstructure = 'Unité'; 
  this.frmcollaborateur.lblnom = 'Nouveau Collaborateur'; 
  this.frmcollaborateur.typeacteur=typeacteur;
  this.frmcollaborateur.show(this);
}

edit(id:any){
  this.frmcollaborateur.lblstructure = 'Unité'; 
  this.frmcollaborateur.lblnom = 'Modification Collaborateur'; 
  this.frmcollaborateur.typeacteur='COLLABORATEUR';
  this.frmcollaborateur.editPersonne(this, id);
}

  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.acteurService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussi' });
          this.showActeurStructure();
        },
          err => {
            Swal.fire({ icon: 'error', text: "La Suppresion a échoué. Car cet élément a peut être été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ' )) {
    //   this.acteurService.delete(id).subscribe(data => {
    //     this.showActeurStructure();
    //   });
    // }
  }


  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.data = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }

  

  tabSize(event){
    this.page = event;
   // this.showData();
  }  

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
   // this.showData();
  }  

  reset1(){
    this.prenom = null;
   }
   reset2(){
    this.nom = null;
   }
   reset3(){
    this.email = null;
   }
   reset4(){
    this.adresse = null;
   }
   reset5(){
    this.telephone = null;
   }

   reset6(){
    this.idfoyer = 0;
   }
}
