import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FoyerService } from '../../services/foyer.service';

declare var $: any;
import { ActeurService } from 'src/app/services/acteur.service';
import { MotifService } from 'src/app/services/motif.service';
import { StructureService } from "../../services/structure.service";
import { FrmcollectivitesComponent } from "../../collectivite/frmcollectivites/frmcollectivites.component";
import { DetailstadComponent } from '../../collectivite/detailstad/detailstad.component';
import { DetailscollectiviteComponent } from "../../collectivite/detailscollectivite/detailscollectivite.component";
import { User } from 'src/app/shared/session/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { SecurityService } from 'src/app/services/security.service';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import Swal from 'sweetalert2';

declare function loadOrgs(data);

@Component({
  selector: 'app-lstorgansisations',
  templateUrl: './lstorgansisations.component.html',
  styleUrls: ['./lstorgansisations.component.css']
})
export class LstorgansisationsComponent implements OnInit {

  datacollec: any = [{}

  ];
  data: any = [];
  dataprestatire: any = [];
  dataprestatires: any = [];
  datamotifs: any = [];
  datafoyers: any = [];
  collaborateurs: any = [];
  contacts: any = [];
  motifs: any = [];
  datafoyer: any = [];
  datacollectivte: any = [];
  datacol: any = [];
  dataville: any = [];
  datatype: any = [];
  structure: any = null;
  collaborateur: any = null;
  contact: any = null;
  motif: any = null;
  siret: any = '';
  nom: any = '';
  ville: any = '';
  adresse: any = '';
  telephone: any = '';
  email: any = '';
  typestructure: any = '';
  responsable: any = '';
  collectivite: any = '';
  fonctions: any = [];

  // MOTIF
  libelle: any = '';
  description: any = '';
  selectedOrg: any;
  orgId: any;
  collapsed = true;
  user: User = new User();
  canCreate = false;
  canShow = false;
  canUpdate = false;
  canDelete = false;
  canDetails = false;
  canCreateCollaborateur = false;
  canUpdateCollaborateur = false;
  canDeleteCollaborateur = false;
  canDetailsCollaborateur = false;
  canConsulterCollaborateur = false;

  canCreateContact = false;
  canUpdateContact = false;
  canDeleteContact = false;
  canDetailsContact = false;
  canConsulterContact = false;

  canCreateTAD = false;
  canUpdateTAD = false;
  canDeleteTAD = false;
  canDetailsTAD = false;
  canConsulterTAD = false;

  canCreateFoyer = false;
  canUpdateFoyer = false;
  canDeleteFoyer = false;
  canDetailsFoyer = false;
  canConsulterFoyer = false;
  canCreatePrestataire = false;
  canUpdatePrestataire = false;
  canDeletePrestataire = false;
  canDetailsPrestataire = false;
  canConsulterPrestataire = false;
  canCreateMotifs = false;
  canUpdateMotifs = false;
  canDeleteMotifs = false;
  canDetailsMotifs = false;
  canConsulterMotifs = false;
  prenomcontact: any = null;
  nomcontact: any = null;
  emailcontact: any = null;
  adressecontact: any = null;
  datenaissancecontact: any = null;
  telephonecontact: any = null;
  telephone2contact: any = null;
  informationcontact: any = null;
  fonctioncontact: any = null;
  prenomcollaborateur: any = null;
  nomcollaborateur: any = null;
  emailcollaborateur: any = null;
  adressecollaborateur: any = null;
  datenaissancecollaborateur: any = null;
  telephonecollaborateur: any = null;
  telephone2collaborateur: any = null;
  informationcollaborateur: any = null;
  fonctioncollaborateur: any = null;
  colselect;
  codeFeature = '';


  @ViewChild('frmcollectivitesComponent') frmcollectivitesComponent: FrmcollectivitesComponent;
  @ViewChild('detailsTad') detailsTad: DetailstadComponent;
  @ViewChild('detailCollectivite') detailCollectivite: DetailscollectiviteComponent;

  parent;
  constructor(private http: HttpClient, private authenticationService: AuthenticationService,
    private foyerService: FoyerService, private motifService: MotifService, private acteurService: ActeurService,
    private structureService: StructureService, private securiteService: SecurityService) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.getOrganisations(); this.getDroit();

  }
  getlstorganisation() {
    this.detailCollectivite.getlstorganisation(this);
  }
  getOrg() {
    this.structureService.get(this.orgId).subscribe(data => {
      this.selectedOrg = data;
      if (this.selectedOrg.typestructure === 'TAD') {
        //  alert(this.selectedOrg);
        this.canShow = false;
        this.detailsTad.codeFeatureTad = 'FEA_ORGANISATION'
        this.detailsTad.filter(this, this.selectedOrg);
      } else {
        this.canShow = true;
        this.detailCollectivite.codeFeature = 'FEA_ORGANISATION';
        this.getlstorganisation();
        this.detailCollectivite.filter(this, this.selectedOrg);
      }
    });
  }


  getOrganisations() {

    $('#id-jqtree-files').tree('destroy');
    this.structureService.getOrganisationsrattachees(this.user.structure).subscribe(data => {
      let organisations = data;
      loadOrgs(organisations);


    });
  }

  openFormCollectivite() {
    this.frmcollectivitesComponent.show(this);
    this.frmcollectivitesComponent.lblnom ='Nouvelle Structure';
  }

  delete(): void {
    if (!!this.selectedOrg) {
      // alert(this.selectedOrg)
      // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
      //   this.structureService.delete(this.selectedOrg.id).subscribe(data => {
      //     this.getOrganisations();
      //   });
      // }
      Swal.fire({
        title: 'Etes vous sûr de vouloir supprimer?',
        text: 'Vous ne pourrez pas récupérer ce fichier !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, Supprimer!',
        cancelButtonText: 'Non, Annuler'
      }).then((result) => {
        if (result.value) {
          this.structureService.delete(this.selectedOrg.id).subscribe(data => {
            Swal.fire({ icon: 'success', text: "Suppression effectuée avec succés." });
           this.getOrganisations();
          },
            err => {
              Swal.fire({ icon: 'error', text: "La Suppresion a échoué. L'élément a été utilisé ailleurs" });
            }
          );
        }
      })
    }
    else {
      Swal.fire(
        'Info!',
        'Veuillez sélectionner une collectivité.',
        // 'success'
      )
    }
  }
  edit() {
    if (!!this.selectedOrg) {
      this.frmcollectivitesComponent.edit(this, this.selectedOrg.id);
      this.frmcollectivitesComponent.lblnom ='Modification de Structure';

    } else {
      Swal.fire(
        'Info!',
        'Veuillez sélectionner une collectivité.',
        // 'success'
      )
    }
  }


  rechercheAvanceCollaborateur() {
    this.prenomcollaborateur = this.prenomcollaborateur ? this.prenomcollaborateur : null;
    this.nomcollaborateur = this.nomcollaborateur ? this.nomcollaborateur : null;
    this.emailcollaborateur = this.emailcollaborateur ? this.emailcollaborateur : null;
    this.adressecollaborateur = this.adressecollaborateur ? this.adressecollaborateur : null;
    this.telephonecollaborateur = this.telephonecollaborateur ? this.telephonecollaborateur : null;
    // alert(this.user.structure)
    this.acteurService.getRecherche(this.prenomcollaborateur, this.nomcollaborateur, this.emailcollaborateur, this.adressecollaborateur,
      this.telephonecollaborateur, TypeActeurModel.COLLABORATEUR, this.selectedOrg.id, TypeStructureModel.COLLECTIVITE).subscribe(data => {
        this.collaborateurs = data;
        this.collaborateur = null;
      });

  }

  rechercheAvanceContact() {
    this.prenomcontact = this.prenomcontact ? this.prenomcontact : null;
    this.nomcontact = this.nomcontact ? this.nomcontact : null;
    this.emailcontact = this.emailcontact ? this.emailcontact : null;
    this.adressecontact = this.adressecontact ? this.adressecontact : null;
    this.telephonecontact = this.telephonecontact ? this.telephonecontact : null;
    // alert(this.user.structure)
    this.acteurService.getRecherche(this.nomcontact, this.prenomcontact, this.emailcontact, this.adressecontact,
      this.telephonecontact, TypeActeurModel.CHAUFFEUR, this.selectedOrg.id, TypeStructureModel.PRESTATAIRE).subscribe(data => {
        this.contacts = data;
        this.contact = null;
      });

  }

  getDroit(): void {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_ORG_COLLECTIVITE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_ORG_COLLECTIVITE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_EDIT_ORG_COLLECTIVITE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canUpdate = true;
      } else {
        this.canUpdate = false;
      }
    });

  }
}


