<div class="modal fade modal-ds" data-backdrop="static" id="pdfViewer" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document" style="height: 1000px;width: 1000px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <!-- Nouvel utilisateur -->
                </h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body"> 
                <div class="card ccard">
                    <div class="card-body px-0 py-2">
                        <ngx-extended-pdf-viewer src="{{url}}" useBrowserLocale="true">
                        </ngx-extended-pdf-viewer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
