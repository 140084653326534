<div class="modal fade modal-ds" data-backdrop="static" id="modalBudget" tabindex="-1" role="dialog" aria-hidden="true" style="height: 6000px;">
    <div class="modal-dialog modal-dialog-scrollable" role="document" style="height: 8000px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{lblnom}}
                </h5>

                <button type="button" (click)="resetForm()" class="close" (click)="fermer()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="">
                    <div class="">

                        <form class="mt-lg-3" name="myForm">
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Unité <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <select [(ngModel)]="selecttad" name="selecttad" class="form-control col-sm-9 col-md-7"
                                        (change)="valideInput()" [ngClass]="{'errortad':errortad==true}" id="tad">
                                        <option value="">&nbsp;</option>
                                        <option *ngFor="let col of tads;" value="{{col.id}}">{{col.nom}}</option>
                                    </select>
                                    <span style="color:red" *ngIf="errortad==true">
                                        Champs obligatoir.
                                    </span>
                                </div>
                            </div>
                            <!--           <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Date début <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="date" [(ngModel)]="datedebut" name="datedebut"
                                        class="form-control col-sm-8 col-md-6" (change)="valideInput()"
                                        [ngClass]="{'errordd':errordd==true}">
                                    <span style="color:red" *ngIf="errordd==true">
                                        Champs obligatoir.
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Date fin <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="date" [(ngModel)]="datefin" name="datefin"
                                        class="form-control col-sm-8 col-md-6" (change)="valideInput()"
                                        [ngClass]="{'errordf':errordf==true}">
                                    <span style="color:red" *ngIf="errordf==true">
                                        {{infoError}}
                                    </span>
                                </div>
                            </div> -->
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                  <label class="mb-0 required">
                                    Date de début 
                                  </label>
                                </div>
                                <div class="col-sm-9 input-group date">
                                  <input type="text" [(ngModel)]="datedebut" name="datedebut" id="datedebut" class="form-control col-sm-8 col-md-6"
                                    (change)="valideInput()" [ngClass]="{'errordd':errordd==true}">
                                    
                                    <div class="input-group-addon input-group-append">
                                      <div class="input-group-text">
                                        <i class="fa fa-calendar"></i>
                                      </div>
                                    </div>
                                </div>
                                <span *ngIf="errordd==true" style="color:red">
                                  Champs obligatoire.
                                </span>
                
                              </div>
                
                              <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                  <label class="mb-0 required">
                                    Date de fin 
                                  </label>
                                </div>
                                <div class="col-sm-9 input-group date">
                                  <input type="text" [(ngModel)]="datefin" name="datefin" id="datefin" class="form-control col-sm-8 col-md-6"
                                    (change)="valideInput()" [ngClass]="{'errordf':errordf==true}" >
                                    <div class="input-group-addon input-group-append">
                                      <div class="input-group-text">
                                        <i class="fa fa-calendar"></i>
                                      </div>
                                    </div>
                                  
                                </div>
                                <span *ngIf="errordf==true" style="color:red">
                                  Champs obligatoire.
                                </span>
                
                              </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Budget initial <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="number" [(ngModel)]="montant" name="montant"
                                        class="form-control col-sm-9 col-md-7" (change)="valideInput()"
                                        [ngClass]="{'errorm':errorm==true}">
                                    <span style="color:red" *ngIf="errorm==true">
                                        {{infoErrorm}}
                                    </span>
                                </div>
                            </div>

                        </form>


                    </div>
                    <!-- /.card-body -->
                </div>


            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)='fermer()'>
                    Fermer
                </button>

                <button type="button" class="btn btn-primary" (click)='save()'>
                    Enregistrer
                </button>

            </div>
        </div>
    </div>
</div>