import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';
import { FoyerModel } from '../shared/models/foyer-model.model';
import { Observable } from 'rxjs';
import { BudgetModel } from '../shared/models/budget-model.model ';

@Injectable({
  providedIn: 'root'
})
export class BudgetService {

  constructor(private http: HttpClient) { }

  getData() {
     return this.http.get(AppModule.API_ENDPOINT + 'budget/list');
  }

  getByStructure(structure:FoyerModel): Observable<BudgetModel[]> {
    return this.http.get<BudgetModel[]>(AppModule.API_ENDPOINT + 'budget/list/'+structure);
 }

 getByStructureBis(personne:any): Observable<BudgetModel[]> {
  return this.http.get<BudgetModel[]>(AppModule.API_ENDPOINT + 'budget/listBudget/'+personne);
}

 getByCollectivite(structure:FoyerModel): Observable<BudgetModel[]> {
  return this.http.get<BudgetModel[]>(AppModule.API_ENDPOINT + 'budget/budgetscollect/'+structure);
}

  get(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'budget/get/' + id);
  }

  save(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'budget/create', body, { headers });
  }

  update(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + 'budget/update/' + id, body, { headers });
  }

  delete(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + 'budget/delete/' + id);
  }
  isValidePeriode(id: any,datedbut:any,datefin : any,budget:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'budget/validedate/' + id +'/' + datedbut+'/' +datefin+'/' +budget);
  }

  getRecherche(id: any,datedbut:any,datefin : any,montant:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'budget/recherche/' + id +'/' + datedbut+'/' +datefin+'/'+montant);
  }

  getRechercheCollectivite(id: any,datedbut:any,datefin : any,montant:any,collectivite:any,critdatedbut:any,critdatefin : any,critmontant:any, datedebutmax : any,datefinmax : any,montantmax:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'budget/recherchecollectivitebis/' + id +'/' + datedbut+'/' +datefin+'/'+montant+'/'+collectivite+'/' + critdatedbut+'/' +critdatefin+'/'+critmontant
    +'/' + datedebutmax+'/' +datefinmax+'/'+montantmax);
  }

  getRechercheTad(id: any, datedbut: any, datefin: any, montant: any, personne: any, critdatedbut: any, critdatefin: any, critmontant: any, datedebutmax: any, datefinmax: any, montantmax: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'budget/recherchetadbis/' + id + '/' + datedbut + '/' + datefin + '/' + montant + '/' + personne + '/' + critdatedbut + '/' + critdatefin + '/' + critmontant
      + '/' + datedebutmax + '/' + datefinmax + '/' + montantmax);
  }

  getRechercheBis(id: any, datedbut: any, datefin: any, montant: any, critdatedbut: any, critdatefin: any, critmontant: any, datedebutmax: any, datefinmax: any, montantmax: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'budget/recherchebis/' + id + '/' + datedbut + '/' + datefin + '/' + montant + '/' + critdatedbut + '/' + critdatefin + '/' + critmontant
      + '/' + datedebutmax + '/' + datefinmax + '/' + montantmax);
  }


  getBudgetRestant(tad: any, dateReservation: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'budget/restant/' + tad + '/' + dateReservation);
  }
  updateBudgetRestant(tad: any,datereservation: any,montantrestant:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'budget/updaterestant/' + tad+ '/' +datereservation+ '/' + montantrestant );
  }

  updateBudget(tad: any,datereservation: any,montantrestant:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'budget/updatebudget/' + tad+ '/' +datereservation+ '/' + montantrestant );
  }
}
