import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StructureService } from 'src/app/services/structure.service';
import { VilleService } from 'src/app/services/ville.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { FoyerService } from 'src/app/services/foyer.service';
import { MotifService } from 'src/app/services/motif.service';
import { ActeurService } from 'src/app/services/acteur.service';
import { FrmcollectivitesComponent } from "../../collectivite/frmcollectivites/frmcollectivites.component";
import { DetailscollectiviteComponent } from '../detailscollectivite/detailscollectivite.component';
import { User } from 'src/app/shared/session/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { SecurityService } from 'src/app/services/security.service';
import { NgxSpinnerService } from 'ngx-spinner';


declare function stransAdresse(id: any);
declare var $: any;import Swal from 'sweetalert2';



@Component({
  selector: 'app-lstcollectivites',
  templateUrl: './lstcollectivites.component.html',
  styleUrls: ['./lstcollectivites.component.css']
})
export class LstcollectivitesComponent implements OnInit {

  data: any = [];
  dataprestatire: any = [];
  dataprestatires: any = [];
  datamotifs: any = [];
  datafoyers: any = [];
  collaborateurs: any = [];
  contacts: any = [];
  motifs: any = [];
  datafoyer: any = [];
  datacollectivte: any = [];
  datacol: any = [];
  dataville: any = [];
  datatype: any = [];
  structure: any = null;
  collaborateur: any = null;
  contact: any = null;
  motif: any = null;

  siret = null;
  nom = null;
  ville = null;
  adresse = null;
  telephone = null;
  email = null;
  responsable = null;
  typestructure: any = '';
  collectivite: any = null;
  user: User = new User();

  //Acteur

  fonctions: any = [];

  codeacteur: any = '';
  prenom: any = '';
  nomacteur: any = '';
  emailacteur: any = '';
  adresseacteur: any = '';
  datenaissance: any = '';
  telephone1: any = '';
  telephone2: any = '';
  information: any = '';
  fonction: any = '';
  typeacteur: any = '';
  selectedOrg: any;
  orgId: any;
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canDetails = false;

  @ViewChild('detailCollectivite') detailCollectivite: DetailscollectiviteComponent;
  @ViewChild('frmcollectivitesorgComponent') frmcollectivitesorgComponent: FrmcollectivitesComponent;
  constructor(private http: HttpClient, private foyerService: FoyerService, private motifService: MotifService,
    private acteurService: ActeurService, private structureService: StructureService,
    private authenticationService: AuthenticationService, private paginationService: PaginationService, 
    private securiteService: SecurityService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.showCollectivite();
    stransAdresse('adresse');
    this.getDroit();

    // this.detailCollectivite.codeFeature1 = 'FEA_ORGANISATION'
  }

  getOrg() {
    this.structureService.get(this.orgId).subscribe(data => {
      this.selectedOrg = data;

    });
  }
  getOrganisations() {
    this.showCollectivite();
  }

  showCollectivite(): void {
    this.spinner.show();
    this.structureService.getCollectiviteByProfil(this.user.id).subscribe(data => {
      this.datacollectivte = data;
      this.structure = null;
      this.spinner.hide();
    });
 /*   if (this.user.structure == 0) {
      this.foyerService.getData(TypeStructureModel.COLLECTIVITE).subscribe(data => {
        this.datacollectivte = data;
        this.structure = null;
        this.spinner.hide();
      });
    } else if (this.user.profilCode == "PF_TAD") {
      this.structureService.getCollectivitesTad(TypeStructureModel.TAD, this.user.structure).subscribe(data => {
        this.datacollectivte = data;
        this.structure = null; 
        this.spinner.hide();
      });
    } else if (this.user.profilCode == "PF_AGCOL") {
      this.structureService.getCollectivite(this.user.structure).subscribe(data => {
        this.datacollectivte = data;
        this.structure = null;
        this.spinner.hide();
      });
    } else {
      this.structureService.getCollectivitesRattachee(TypeStructureModel.COLLECTIVITE, this.user.structure).subscribe(data => {
        this.datacollectivte = data;
        this.structure = null;
        this.spinner.hide();
      });
    }
*/

  }
  openDetailsCollectivite(col: any) {
    this.detailCollectivite.codeFeature = 'FEA_LSTCOLLECTIVITES'
    this.detailCollectivite.filter(this, col);
  }

  openFormCollectivite() {
    this.frmcollectivitesorgComponent.show(this);
    this.frmcollectivitesorgComponent.lblnom ='Nouvelle Structure';
  }

  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.structureService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.showCollectivite();
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.structureService.delete(id).subscribe(data => {
    //     this.showCollectivite();
    //   });
    // }
  }

  edit(id: any) {
    this.frmcollectivitesorgComponent.edit(this, id);
    this.frmcollectivitesorgComponent.lblnom ='Modification de Structure';
  }

  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.datacollectivte = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }

  tabSize(event) {
    this.page = event;
    //this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }

  RechercheAvance() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.nom = this.nom ? this.nom : null;
    this.siret = this.siret ? this.siret : null;
    this.email = this.email ? this.email : null;
    this.adresse = this.adresse ? this.adresse : null;
    this.telephone = this.telephone ? this.telephone : null;
    this.responsable = this.responsable ? this.responsable : null;
    this.structureService.getRechercheCollectivite(this.nom, this.siret, this.email, this.adresse,
      this.telephone, this.responsable, TypeStructureModel.COLLECTIVITE,this.user.id).subscribe(data => {
        this.datacollectivte = data;
        this.structure = null;
        this.spinner.hide();
      });
      this.tabSize(event);

  }

  getDroit(): void {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_COLLECTIVITE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_EDIT_COLLECTIVITE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canUpdate = true;
      } else {
        this.canUpdate = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_COLLECTIVITE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_DETAILS_COLLECTIVITE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDetails = true;
      } else {
        this.canDetails = false;
      }
    });

  }

  reset1(){
    this.siret = null;
   }
   reset2(){
    this.nom = null;
   }
   reset3(){
    this.email = null;
   }
   reset4(){
    this.adresse = null;
   }
   reset5(){
    this.responsable = null;
   }

   reset6(){
    this.telephone = null;
   }
}


