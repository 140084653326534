<div class="modal fade modal-ds2" data-backdrop="static" id="modalTemplatePrix" role="dialog" tabindex="-1"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{lblnom}}
        </h5>

        <button type="button" (click)="resetForm()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="">
          <div class="">

            <form class="mt-lg-3" name="myForm">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Libellé <label style="color:red">
                      *
                    </label>
                  </label>
                </div>
                <div class="col-sm-7">
                  <input type="text" [(ngModel)]="libelle" name="libelle" class="form-control"
                    (change)="valideInput()" [ngClass]="{'libelle':libelle==true}">
                  <span *ngIf="errorlibelle==true" style="color:red">
                    Champs obligatoire.
                  </span>
                </div>

              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Référence du marché 
                  </label>
                </div>
                <div class="col-sm-7">
                  <input type="text" [(ngModel)]="referencemarche" name="referencemarche" class="form-control"
                    >
                 
                </div>

              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0 required">
                    Date de début 
                  </label>
                </div>
                <div class="col-sm-7 input-group date">
                  <input type="text" [(ngModel)]="datedebut" name="datedebut" id="datedebut" class="form-control"
                    (change)="valideInput()" [ngClass]="{'error':errordatedebut==true}">
                    
                    <div class="input-group-addon input-group-append">
                      <div class="input-group-text">
                        <i class="fa fa-calendar"></i>
                      </div>
                    </div>
                </div>
                <span *ngIf="errordatedebut==true" style="color:red">
                  Champs obligatoire.
                </span>

              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0 required">
                    Date de fin 
                  </label>
                </div>
                <div class="col-sm-7 input-group date">
                  <input type="text" [(ngModel)]="datefin" name="datefin" id="datefin" class="form-control "
                    (change)="valideInput()" [ngClass]="{'error':errordatefin==true}" >
                    <div class="input-group-addon input-group-append">
                      <div class="input-group-text">
                        <i class="fa fa-calendar"></i>
                      </div>
                    </div>
                 
                </div>
                <span *ngIf="errordatefin==true" style="color:red">
                  Champs obligatoire.
                </span>

              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Structure <label style="color:red">
                      *
                    </label>
                  </label>
                </div>

                <div class="col-sm-7">
                  <select class="form-control struct" (change)="valideInput()" 
                  [ngClass]="{'error':errorcollectivite==true}" (change)="openSelect()"
                    [(ngModel)]="collectivite" name="collectivite" id="collectivite">
                    <option value="0">&nbsp;</option>

                    <option *ngFor="let coll of collectivites;" value="{{coll.id}}">{{coll.nom}}</option>

                  </select>
                  <span *ngIf="errorcollectivite==true" style="color:red">
                    Champs obligatoire.
                  </span>

                </div>

              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Unité <label style="color:red">
                      *
                    </label>
                  </label>
                </div>

                <div class="col-sm-7">
                  <select class="form-control tadservices" (change)="valideInput()" (change)="openSelectPresta()"
                   [ngClass]="{'error':errortad==true}"
                    [(ngModel)]="tad" name="tad" id="tad">
                    <option value="0">&nbsp;</option>

                    <option *ngFor="let tad of tads;" value="{{tad.id}}"  >{{tad.nom}}
                    </option>
                  </select>
                  <span *ngIf="errortad==true" style="color:red">
                    Champs obligatoire.
                  </span>

                </div>

              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Prestataire <label style="color:red">
                      *
                    </label>
                  </label>
                </div>

                <div class="col-sm-7">
                  <select class="form-control presta" (change)="valideInput()" [ngClass]="{'error':errorprestataire==true}"
                    [(ngModel)]="prestataire" name="prestataire" id="prestataire">
                    <option value="0">&nbsp;</option>
                    <option *ngFor="let prestataire of prestataires;" value="{{prestataire.id}}">{{prestataire.nom}}
                    </option>
                  </select>
                  <span *ngIf="errorprestataire==true" style="color:red">
                    Champs obligatoire.
                  </span>

                </div>

              </div>
             
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Type de template <label style="color:red">
                      *
                    </label>
                  </label>
                </div>

                <div class="col-sm-7">
                  <select class="form-control" (change)="valideInput()" [ngClass]="{'error':errortypetemplate==true}"
                    [(ngModel)]="typetemplate" name="typetemplate" id="form-field-select-1">
                    <option value="">&nbsp;</option>

                    <option *ngFor="let type of types;" value="{{type.id}}">{{type.libelle}}</option>

                  </select>
                  <span *ngIf="errortypetemplate==true" style="color:red">
                    Champs obligatoire.
                  </span>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Tarif jour (€)
                  </label>
                </div>

                <div class="col-sm-7"> 
                  <input type="number" [(ngModel)]="tarifjour" name="tarifjour" 
                  class="form-control">
                </div>
              </div>
                           
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Tarif nuit (€)
                  </label>
                </div>

                <div class="col-sm-7">
                  <input type="number" [(ngModel)]="tarifnuit" name="tarifnuit" class="form-control">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Dimanche weekend
                  </label>
                </div>

                <div class="col-sm-7">
                  <label class="ml-1 text-orange-d3 text-600">
                    <span class="text-info">Non </span>
                    <input type="checkbox" class="ace-switch ace-switch-thin text-grey-l1 bgc-warning-d2 mr-1"
                      [(ngModel)]="flagdimanche" name="flagdimanche" [checked]="flagdimanche">
                    <span> Oui</span>
                  </label>
                </div>

              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Samedi weekend
                  </label>
                </div>

                <div class="col-sm-9 input-group">
                  <label class="ml-1 text-orange-d3 text-600">
                    <span class="text-info">Non </span> <input
                      class="ace-switch ace-switch-thin text-grey-l1 bgc-warning-d2 mr-1" [(ngModel)]="flagsamedi"
                      name="flagsamedi" type="checkbox">
                    <span> Oui</span>
                  </label>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Tarif weekend (€)
                  </label>
                </div>
                <div class="col-sm-7">
                  <input type="number" [(ngModel)]="tarifweekend" name="tarifweekend" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Tarif férié (€)
                  </label>
                </div>
                <div class="col-sm-7">
                  <input type="number" [(ngModel)]="tarifferie" name="tarifferie" 
                  class="form-control">
                </div>
              </div>
              
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Tarif min (€)
                  </label>
                </div>

                <div class="col-sm-7">
                  <input type="number" [(ngModel)]="tarifmin" name="tarifmin" class="form-control">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0 ">
                    Montant prise en charge (€)
                  </label>
                </div>  

                <div class="col-sm-7">
                  <input type="number" [(ngModel)]="priseenncharge" name="priseenncharge" id="priseenncharge"
                    class="form-control">
                  
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Delai annualation (H)
                  </label>
                </div>

                <div class="col-sm-7">
                  <input type="number" [(ngModel)]="delaiannualation" name="delaiannualation" class="form-control">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Delai minimum (H)
                  </label>
                </div>

                <div class="col-sm-7">
                  <input type="number" [(ngModel)]="delaiminim" name="delaiminim" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Approche facturable
                  </label>
                </div>
                <div class="col-sm-9 input-group">
                  <label class="ml-1 text-orange-d3 text-600">
                    <span class="text-info">Non </span> <input
                      class="ace-switch ace-switch-thin text-grey-l1 bgc-warning-d2 mr-1" [(ngModel)]="flagapproche"
                      name="flagapproche" type="checkbox">
                    <span> Oui</span>
                  </label>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Retour adresse de référence facturable
                  </label>
                </div>

                <div class="col-sm-9 input-group">
                  <label class="ml-1 text-orange-d3 text-600">
                    <span class="text-info">Non </span> <input
                      class="ace-switch ace-switch-thin text-grey-l1 bgc-warning-d2 mr-1" [(ngModel)]="flagretour"
                      name="flagretour" type="checkbox">
                    <span> Oui</span>
                  </label>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Adresse de référence <label style="color:red">
                      *
                    </label>
                  </label>
                </div>
                <div class="col-sm-7">
                  <input #adresseField class="form-control" (change)="valideInput()"
                  [ngClass]="{'error':erroradresse==true}"
                   placeholder="Adresse" id="adresse" [(ngModel)]="pointref"
                    name="pointref" />
                    <span *ngIf="erroradresse==true" style="color:red">
                      Champs obligatoire.
                    </span>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Ville
                  </label>
                </div>

                <div class="col-sm-7">
                  <input readonly type="text" id="ville" [(ngModel)]="ville" name="ville" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Code Postal
                  </label>
                </div>

                <div class="col-sm-7">
                  <input readonly type="text" id="codePostal" [(ngModel)]="codePostal" name="codePostal"
                    class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0 required">
                    Heure debut jour
                  </label>
                </div>

                <div class="col-sm-4 input-group time">
                  <input [(ngModel)]="heuredebutjour"(change)="valideInput()"  class="form-control" id="heuredebutjour" name="heuredebutjour"
                    type="text" [ngClass]="{'error':errorheuredebutjour==true}"/>

                  <div class="input-group-addon input-group-append">
                    <div class="input-group-text">
                      <i class="far fa-clock"></i>
                    </div>
                  </div>
                  
                </div>
                <span *ngIf="errorheuredebutjour==true" style="color:red">
                  Champs obligatoire.
                </span>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0 required">
                    Heure debut nuit
                  </label>
                </div>
                <div class="col-sm-4 input-group time">
                  <input [(ngModel)]="heuredebutnuit" (change)="valideInput()" class="form-control" id="heuredebutnuit" name="heuredebutnuit"
                    type="text" [ngClass]="{'error':errorheuredebutjour==true}"/>
                  <div class="input-group-addon input-group-append">
                    <div class="input-group-text">
                      <i class="far fa-clock"></i>
                    </div>
                  </div>
                  
                </div>
                <span *ngIf="errorheuredebutnuit==true" style="color:red">
                  Champs obligatoire.
                </span>
              </div>
            </form>
          </div><!-- /.card-body -->
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="fermer()">
          Fermer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()">
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>
