import {Component, OnInit, ViewChild} from '@angular/core';
import {Demand} from '../shared/classes/demand';
import {AppModule} from '../app.module';
import {User} from '../shared/session/user';
import {DossiersService} from '../shared/services/dossiers.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthenticationService} from '../shared/services/authentication.service';
import Swal from 'sweetalert2';
import {ViewCardComponent} from "../view-card/view-card.component";

@Component({
  selector: 'app-registre',
  templateUrl: './registre.component.html',
  styleUrls: ['./registre.component.css']
})
export class RegistreComponent implements OnInit {

  citizens = [];
  demand: any;
  documents = [];


  appServer = AppModule.APP_SERVER;
  apiEndPoint = AppModule.API_ENDPOINT_V2;
  user: User;

  // @ts-ignore
  @ViewChild('viewDoc') viewDoc: ViewCardComponent;

  constructor(private dossiersService: DossiersService, private spinner: NgxSpinnerService, private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.getRegistre();
  }

  getRegistre() {
    this.spinner.show();
    this.dossiersService.getRegistre(this.user.id).subscribe(citizens => {
      // @ts-ignore
      this.citizens = citizens;

      this.spinner.hide();

    });
  }

  viewDocument(citizen) {
    this.viewDoc.show(citizen);
  }

}
