import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StructureService } from '../../services/structure.service';
import { VilleService } from '../../services/ville.service';
import { FoyerService } from '../../services/foyer.service';
import { ActeurService } from '../../services/acteur.service';
import { BudgetService } from '../../services/budget.service ';
import { TypeStructureModel } from '../../shared/models/type-structure-model.model';
import { FrmcollectivitecollaborateurComponent } from '../frmcollectivitecollaborateur/frmcollectivitecollaborateur.component';
import { FrmbudgetComponent } from 'src/app/fonction/frmbudget/frmbudget.component';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { FrmaffecteComponent } from './frmaffecte/frmaffecte.component';
import { User } from 'src/app/shared/session/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FrmenfantComponent } from './frmenfant/frmenfant.component';
import { FrmtransfertenfantComponent } from 'src/app/foyer/frmtransfertenfant/frmtransfertenfant.component';
import { FrmcollectiviteprestataireComponent } from '../frmcollectiviteprestataire/frmcollectiviteprestataire.component';
import { CollectiivteStructureService } from 'src/app/services/collectivitestructure.service ';
import { FrmtadprestataireComponent } from './frmtadprestataire/frmtadprestataire.component';
import { PaginationService } from 'src/app/services/pagination.service';
import { SecurityService } from 'src/app/services/security.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';


declare var $: any;

@Component({
  selector: 'app-detailstad',
  templateUrl: './detailstad.component.html',
  styleUrls: ['./detailstad.component.css']
})
export class DetailstadComponent implements OnInit {

  constructor(private http: HttpClient,
    private structureService: StructureService, private villeService: VilleService
    , private foyerService: FoyerService, private acteurService: ActeurService, private collectivitestructure: CollectiivteStructureService
    , private budgetService: BudgetService, private authenticationService: AuthenticationService,
    private paginationService: PaginationService, private securiteService: SecurityService,
    private spinner: NgxSpinnerService) { }


  data: any = [];

  @ViewChild('frmcollaborateur') frmcollaborateur: FrmcollectivitecollaborateurComponent;
  @ViewChild('frmenfants') frmenfants: FrmenfantComponent;
  @ViewChild('frmaffecte') frmaffecte: FrmaffecteComponent;
  @ViewChild('frmbudgetComponent') frmbudgetComponent: FrmbudgetComponent;
  @ViewChild('frmtransfertenfants') frmtransfertenfants: FrmtransfertenfantComponent;
  @ViewChild('frmtadprestataire') frmtadprestataire: FrmtadprestataireComponent;

  datapensionnaires: any = [];
  dataprestataires: any = [];
  datacollaborateurs: any = [];
  databudgets: any = [];
  pensionnaire: any = null;
  dataprestataire: any = null;
  collaborateur: any = null;
  budget: any = null;
  parent;
  typeacteur: any = '';
  tadSelected: any;
  user: User = new User();
  email;
  telephone;
  nom;
  siret;
  adresse;
  responsable;
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;

  montant: any = null;
  montantMax: any = null;
  critereMontant: any = '1';
  dateDebut: any = null;
  dateDebutMax: any = null;
  dateFinMax: any = null;
  critereDateDebut: any = '1';
  dateFin: any = null;
  critereDateFin: any = '1';

  prenomEnfant: any = null;
  nomEnfant: any = null;
  emailEnfant: any = null;
  adresseEnfant: any = null;
  datenaissanceEnfant: any = null;
  telephoneEnfant: any = null;

  prenomCollaborateur: any = null;
  nomCollaborateur: any = null;
  emailCollaborateur: any = null;
  adresseCollaborateur: any = null;
  datenaissanceCollaborateur: any = null;
  telephoneCollaborateur: any = null;

  prenomAffecter: any = null;
  nomAffecter: any = null;
  emailAffecter: any = null;
  adresseAffecter: any = null;
  datenaissanceAffecter: any = null;
  telephoneAffecter: any = null;

  siretprestataire = null;
  nomprestataire = null;
  villeprestataire = null;
  adresseprestataire = null;
  telephoneprestataire = null;
  emailprestataire = null;
  responsableprestataire = null;
  idTadprestataire = 0;

  canCreatePrestataire = false;
  canDeletePrestataire = false;
  canCreateEnfant = false;
  canCreateCollaborateur = false;
  canEditCollaborateur = false;
  canDeleteCollaborateur = false;
  canTransfertEnfant = false;
  canDeleteEnfant = false;
  canShowEnfant = false;
  canShowCollaborateur = false;
  canShowPrestataire = false;
  canShowBudget = false;
  canShowAffecter = false;

  canCreateBudget = false;
  canDeleteBudget = false;
  canEditBudget = false;

  canCreateAffecter = false;
  canDeleteAffecter = false;

  organisation = false;
  tad = false;
  collectivie = false;
  telephoneFixeParent = false;
  adresseParent = false;
  name = false;

  codeFeatureTad = 'ras';
  lstcollaborateurscollectivite = false;

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    // alert(this.codeFeatureTad)

  }
  getfeature(feature: any) {
    this.codeFeatureTad = feature;
  }

  showDoitsOrganisation() {
    let parent = this.codeFeatureTad.split("_")[1];
    console.log("le code action =", parent);              
    this.securiteService.getDroit1(this.user.profilCode, "ACT_SHOWPRESTATAIRE_TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canShowPrestataire = true;
      } else {
        this.canShowPrestataire = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_SHOWENFANT_TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canShowEnfant = true;
      } else {
        this.canShowEnfant = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_SHOWCOLLABORATEUR_TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canShowCollaborateur = true;
      } else {
        this.canShowCollaborateur = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_SHOWBUDGET_TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canShowBudget = true;
      } else {
        this.canShowBudget = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_SHOWAFFECTE_TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canShowAffecter = true;
      } else {
        this.canShowAffecter = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_ADD_PRESTATAIRE__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canCreatePrestataire = true;
      } else {
        this.canCreatePrestataire = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_DEL_PRESTATAIRE__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canDeletePrestataire = true;
      } else {
        this.canDeletePrestataire = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_ADD_COLLABORATEUR__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canCreateCollaborateur = true;
      } else {
        this.canCreateCollaborateur = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_DEL_COLLABORATEUR__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canDeleteCollaborateur = true;
      } else {
        this.canDeleteCollaborateur = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_EDIT_COLLABORATEUR__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canEditCollaborateur = true;
      } else {
        this.canEditCollaborateur = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_ADD_ENFANT__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canCreateEnfant = true;
      } else {
        this.canCreateEnfant = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_DEL_Enfant__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canDeleteEnfant = true;
      } else {
        this.canDeleteEnfant = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_EDIT_Enfant__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canTransfertEnfant = true;
      } else {
        this.canTransfertEnfant = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_ADD_BUDGET__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canCreateBudget = true;
      } else {
        this.canCreateBudget = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_DEL_BUDGET__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canDeleteBudget = true;
      } else {
        this.canDeleteBudget = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_EDIT_BUDGET__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canEditBudget = true;
      } else {
        this.canEditBudget = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_ADD_AFFECTE__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canCreateAffecter = true;
      } else {
        this.canCreateAffecter = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_DEL_AFFECTE__TAD_".concat(parent), this.codeFeatureTad).subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canDeleteAffecter = true;
      } else {
        this.canDeleteAffecter = false;
      }
    });


  }

  deleteActeur(id: any): void {
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.acteurService.delete(id).subscribe(data => {
    //     this.filter(this, this.tadSelected);
    //   });
    // }
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.acteurService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.filter(this, this.tadSelected);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
  }


  editActeur(id: any, typeacteur: any): void {
    // alert();
    this.lstcollaborateurscollectivite = false;
    this.frmcollaborateur.selectstructure = this.tadSelected.id;
    this.frmcollaborateur.typeacteur = typeacteur;
    this.frmcollaborateur.typestructure = TypeStructureModel.TAD;
    this.frmcollaborateur.lblstructure = 'Unité';
    if (typeacteur == 'COLLABORATEUR') {
      this.frmcollaborateur.lblnom = 'Modification collaborateur';
      this.lstcollaborateurscollectivite = true;
    } else if (typeacteur == 'CONTACT') {
      this.frmcollaborateur.lblnom = 'Modification Contact';
    } else if (typeacteur == 'PENSIONNAIRE') {
      this.frmcollaborateur.lblnom = 'Modification Enfant';
      this.telephoneFixeParent = true;
      this.adresseParent = true;
    } else if (typeacteur == 'CHAUFFEUR') {
      this.frmcollaborateur.lblnom = 'Modification Chauffeur';
    }
    

    this.frmcollaborateur.editPersonne(this, id);
  }

  deleteBudget(id: any): void {
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.budgetService.delete(id).subscribe(data => {
    //     this.filter(this, this.tadSelected);
    //   });
    // }
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.budgetService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.filter(this, this.tadSelected);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car ce budget a déjà été utilisé ailleurs" });
          }
        );
      }
    })
  }


  openFormBudget() {
    this.frmbudgetComponent.show(this);
    this.frmbudgetComponent.lblnom = 'Ajout Budget';
    this.frmbudgetComponent.isdetail = true;
  }

  editBudget(id: any) {
    this.frmbudgetComponent.edit(this, id);
    this.frmbudgetComponent.lblnom = 'Modification Budget';
    this.frmbudgetComponent.isdetail = true;
  }

  openFormActeur1(typeacteur: any, typestructure: any): void {
    this.frmenfants.selectstructure = this.tadSelected.id;
    this.frmenfants.typestructure = typestructure;
    this.frmenfants.lblnom = 'Enfant';
    this.frmenfants.lblstructure = 'TAD'
    this.frmenfants.typeacteur = typeacteur;
    this.frmenfants.show(this);
  }

  transferer(enfant: any): void {
    this.frmtransfertenfants.enfant = enfant;
    this.frmtransfertenfants.personne = enfant.personne;
    this.frmtransfertenfants.typestructure = 'TAD';
    this.frmtransfertenfants.lblstructure = 'Unité/services';
    this.frmtransfertenfants.lblnom = 'Transfert enfant';
    this.frmtransfertenfants.show(this);
  }
  openFormActeur(typeacteur: any, typestructure: any): void {
    // alert(this.tadSelected.id);
    this.lstcollaborateurscollectivite = false;
    this.frmcollaborateur.selectstructure = this.tadSelected.id;
    this.frmcollaborateur.typestructure = typestructure;
    this.frmcollaborateur.lblstructure = 'Unité';
    if (typeacteur == 'COLLABORATEUR') {
      this.frmcollaborateur.lblnom = 'Nouveau Collaborateur';
      this.lstcollaborateurscollectivite = true;
    } else if (typeacteur == 'CONTACT') {
      this.frmcollaborateur.lblnom = 'Nouveau Contact';
    } else if (typeacteur == 'PENSIONNAIRE') {
      this.frmcollaborateur.lblnom = 'Nouveau Enfant';
      this.telephoneFixeParent = true;
      this.adresseParent = true;
    } else if (typeacteur == 'CHAUFFEUR') {
      this.frmcollaborateur.lblnom = 'Nouveau Chauffeur';
    }
    this.frmcollaborateur.typeacteur = typeacteur;
    this.frmcollaborateur.show(this);

  }

  openAffectermActeur(typeacteur: any): void {
    this.frmaffecte.typeacteur = typeacteur;
    this.frmaffecte.show(this);
  }

  filter(parent, tad: any): void {
    this.spinner.show();
    this.showDoitsOrganisation();
    // this.showDoitsTad();
    this.tadSelected = tad;
    this.frmbudgetComponent.taddetails = tad;
    //   alert(this.tadSelected)

    if (this.tadSelected.id != null) {

      this.parent = parent;
      this.frmaffecte.structure = tad;
      this.frmaffecte.selecttad = tad.id;

      this.frmcollaborateur.structure = tad;
      this.frmbudgetComponent.tad = tad.id;
      this.frmbudgetComponent.selecttad = tad.id;
      this.frmtransfertenfants.structure = tad;
      this.frmtadprestataire.collectivite = tad;
      this.frmtadprestataire.parenttad = tad.collectivite.id;
      this.acteurService.getActeurRattachee(TypeActeurModel.COLLABORATEUR, this.tadSelected.id).subscribe(data => {
        this.datacollaborateurs = data;
        this.collaborateur = null;
        this.spinner.hide();
      });
      this.structureService.getStructureRattachee(TypeStructureModel.PRESTATAIRE, this.tadSelected.id).subscribe(data => {
        this.dataprestataires = data;
        this.dataprestataire = null;
        this.spinner.hide();
      });
      this.acteurService.getActeurRattachee(TypeActeurModel.PENSIONNAIRE, this.tadSelected.id).subscribe(data => {
        this.datapensionnaires = data;
        this.pensionnaire = null;
        this.spinner.hide();
      });

      this.acteurService.getActeurRattachee(TypeActeurModel.AFFECTER, this.tadSelected.id).subscribe(data => {
        this.data = data;
        this.pensionnaire = null;
      });
      //alert();
      this.budgetService.getByStructure(this.tadSelected.id).subscribe(data => {
        this.databudgets = data;
        this.budget = null;
        this.spinner.hide();
      });


    }

  }
  rechercheAvancePrestaire() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    // alert(this.siretprestataire);
    if (this.idTadprestataire == null)
      this.idTadprestataire = 0;

    this.nomprestataire = this.nomprestataire ? this.nomprestataire : null;
    this.siretprestataire = this.siretprestataire ? this.siretprestataire : null;
    this.emailprestataire = this.emailprestataire ? this.emailprestataire : null;
    this.adresseprestataire = this.adresseprestataire ? this.adresseprestataire : null;
    this.telephoneprestataire = this.telephoneprestataire ? this.telephoneprestataire : null;
    this.responsableprestataire = this.responsableprestataire ? this.responsableprestataire : null;
    this.collectivitestructure.getRechercheBis(this.idTadprestataire,TypeStructureModel.TAD, this.nomprestataire, this.siretprestataire,
      this.emailprestataire, this.adresseprestataire,
      this.telephoneprestataire, this.responsableprestataire, TypeStructureModel.PRESTATAIRE, this.tadSelected.id).subscribe(data => {
        this.dataprestataires = data;
        this.dataprestataire = null;
        this.spinner.hide();
      });

  }

  rechercheAvanceEnfant() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.prenomEnfant = this.prenomEnfant ? this.prenomEnfant : null;
    this.nomEnfant = this.nomEnfant ? this.nomEnfant : null;
    this.emailEnfant = this.emailEnfant ? this.emailEnfant : null;
    this.adresseEnfant = this.adresseEnfant ? this.adresseEnfant : null;
    this.telephoneEnfant = this.telephoneEnfant ? this.telephoneEnfant : null;
    // alert(this.user.structure)
    this.acteurService.getRecherche(this.prenomEnfant, this.nomEnfant, this.emailEnfant, this.adresseEnfant,
      this.telephoneEnfant, TypeActeurModel.PENSIONNAIRE, this.tadSelected.id, TypeStructureModel.TAD).subscribe(data => {
        this.datapensionnaires = data;
        this.spinner.hide();
      });
      this.tabSize(event);
  }

  rechercheAvanceCollaborateur() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.prenomCollaborateur = this.prenomCollaborateur ? this.prenomCollaborateur : null;
    this.nomCollaborateur = this.nomCollaborateur ? this.nomCollaborateur : null;
    this.emailCollaborateur = this.emailCollaborateur ? this.emailCollaborateur : null;
    this.adresseCollaborateur = this.adresseCollaborateur ? this.adresseCollaborateur : null;
    this.telephoneCollaborateur = this.telephoneCollaborateur ? this.telephoneCollaborateur : null;
    // alert(this.user.structure)
    this.acteurService.getRecherche(this.prenomCollaborateur, this.nomCollaborateur, this.emailCollaborateur, this.adresseCollaborateur,
      this.telephoneCollaborateur, TypeActeurModel.COLLABORATEUR, this.tadSelected.id, TypeStructureModel.TAD).subscribe(data => {
        this.datacollaborateurs = data;
        this.spinner.hide();
      });
      this.tabSize(event);
  }

  rechercheAvanceBudget() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    // alert(this.dateDebut)
    this.dateDebut = this.dateDebut ? this.dateDebut : null;
    this.dateFin = this.dateFin ? this.dateFin : null;
    this.montant = this.montant ? this.montant : null;
    //alert(this.dateFin)
    this.budgetService.getRecherche(this.tadSelected.id, this.dateDebut, this.dateFin, this.montant).subscribe(data => {
      this.databudgets = data;
      this.spinner.hide();

    });
    this.tabSize(event);

  }

  rechercheAvanceAffecter() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.prenomAffecter = this.prenomAffecter ? this.prenomAffecter : null;
    this.nomAffecter = this.nomAffecter ? this.nomAffecter : null;
    this.emailAffecter = this.emailAffecter ? this.emailAffecter : null;
    this.adresseAffecter = this.adresseAffecter ? this.adresseAffecter : null;
    this.telephoneAffecter = this.telephoneAffecter ? this.telephoneAffecter : null;
    // alert(this.user.structure)
    this.acteurService.getRecherche(this.prenomAffecter, this.nomAffecter, this.emailAffecter, this.adresseAffecter,
      this.telephoneAffecter, TypeActeurModel.AFFECTER, this.tadSelected.id, TypeStructureModel.TAD).subscribe(data => {
        this.data = data;
        this.spinner.hide();
      });
      this.tabSize(event);
  }

  deleteCollctiviteStructure(id: any): void {
    //     if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //       this.collectivitestructure.delete(id).subscribe(data => {
    //         this.filter(this, this.tadSelected);
    //       });
    // }
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.collectivitestructure.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.filter(this, this.tadSelected);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
  }
  openFormCollectStructure(typestructure: any, lblstructure: any): void {
    // alert(this.colSelected.id)
    this.frmtadprestataire.typestructure = typestructure;
    this.frmtadprestataire.lblstructure = lblstructure;
    this.frmtadprestataire.collectiviteTad = "Unité";
    this.frmtadprestataire.selectedstructure = this.tadSelected.id;
    //this.frmtadprestataire.collectivite=this.colSelected;
    this.frmtadprestataire.show(this);
  }

  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.datapensionnaires = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }
  getAge(dateNaissance) {

    var timeDiff = Math.abs(Date.now() - new Date(dateNaissance).getTime());
    var age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

    return age;
  }

  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }

  resetSiret(){
    this.siretprestataire = null;
  }

  resetAdresse(){
    this.adresseprestataire = null;
  }
  resetEmail(){
    this.emailprestataire = null;
  }
  resetNom(){
    this.nomprestataire  = null;
  }
  resetTelephonefixe(){
    this.telephoneprestataire = null;
  }
  resetResponsable(){
    this.responsableprestataire = null;
  }

  resetPrenomEnfant(){
    this.prenomEnfant = null;
  }

  resetAdresseEnfant(){
    this.adresseEnfant = null;
  }
  resetEmailEnfant(){
    this.emailEnfant = null;
  }
  resetNomEnfant(){
    this.nomEnfant  = null;
  }
  resetTelephoneEnfant(){
    this.telephoneEnfant = null;
  }

  resetPrenomCol(){
    this.prenomCollaborateur = null;
  }

  resetAdresseCol(){
    this.adresseCollaborateur = null;
  }
  resetEmailCol(){
    this.emailCollaborateur = null;
  }
  resetNomCol(){
    this.nomCollaborateur  = null;
  }
  resetTelephoneCol(){
    this.telephoneCollaborateur = null;
  }

  resetPrenomAffecte(){
    this.prenomAffecter = null;
  }

  resetAdresseAffecte(){
    this.adresseAffecter = null;
  }
  resetEmailAffecte(){
    this.emailAffecter = null;
  }
  resetNomAffecte(){
    this.nomAffecter  = null;
  }
  resetTelephoneAffecte(){
    this.telephoneAffecter = null;
  }
  resetTad(){
    this.tadSelected = 0;
   }
   resetMontant(){
    this.montant = null;
   }
   resetMontantMax(){
    this.montantMax = null;
   }
   reset1(){
    this.dateDebut = null;
   }
   reset2(){
    this.dateFin = null;
   }
   reset3(){
    this.dateDebutMax = null;
   }
   reset4(){
    this.dateFinMax = null;
   }
}
