import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FrmtadprestataireComponent } from 'src/app/collectivite/detailstad/frmtadprestataire/frmtadprestataire.component';
import { DetailsprestatairesComponent } from 'src/app/prestataire/detailsprestataires/detailsprestataires.component';
import { CollectiivteStructureService } from 'src/app/services/collectivitestructure.service ';
import { FoyerService } from 'src/app/services/foyer.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { SecurityService } from 'src/app/services/security.service';
import { StructureService } from 'src/app/services/structure.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
declare var $: any;
import Swal from 'sweetalert2';
@Component({
  selector: 'app-lstprestatairetad',
  templateUrl: './lstprestatairetad.component.html',
  styleUrls: ['./lstprestatairetad.component.css']
})
export class LstprestatairetadComponent implements OnInit {
  dataprestataire: any = [];
  dataprestataires: any = [];
  structure: any = null;
  siret = null;
  nom = null;
  ville = null;
  adresse = null;
  telephone = null;
  email = null;
  responsable = null;
  datecreation = null;
  codePostal: any = '';
  typestructure: any = '';
  collectivite: any = null;
  selectedstructure;
  user: User = new User();
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  datacollectivites: any = [];
  datacollectivte: any;
  idcollectivite = 0;
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canDetails = false;

  @ViewChild('detailprestataire') detailprestataire: DetailsprestatairesComponent;
  @ViewChild('frmtadprestataire') frmtadprestataire: FrmtadprestataireComponent;
  

  constructor(private http: HttpClient, private collectivitestructure: CollectiivteStructureService,
    private structureService: StructureService, private authenticationService: AuthenticationService,
    private paginationService: PaginationService, private securiteService: SecurityService,
    private spinner: NgxSpinnerService,private foyerService:FoyerService) { }

  ngOnInit(): void {

    this.user = this.authenticationService.getUser();
    this.filter(null, this.user);
    this.showCollectivite();
    this.getDroit();
    this.Disable();

  }
  Disable(): void {
    if (this.idcollectivite != null) {
      $(".collect").attr("disabled", true);
      this.showCollectivite();
    }
    if (this.user.structure != 0) {
      // alert()
      this.structureService.get(this.user.structure).subscribe(data => {
        this.datacollectivte = data;
      });
      this.idcollectivite = this.user.structure
    }
  }
  showCollectivite(): void {
    

    this.structureService.getTadByProfil(this.user.id).subscribe(data => {
      this.datacollectivites = data;
    });

  }
  filter(parent, col: any): void {
    this.spinner.show();
    if (this.user != null) {
      if (this.user.structure == 0) {
        this.collectivitestructure.getStructureTypeStructure(TypeStructureModel.PRESTATAIRE,TypeStructureModel.TAD).subscribe(data => {
          this.dataprestataires = data;
        //  this.dataprestataire = null;
        this.spinner.hide();
        });
      }
      else if (this.user.profilCode == "PF_AGCOL") {
        this.collectivitestructure.getStructureRattachee(TypeStructureModel.PRESTATAIRE, this.user.structure,TypeStructureModel.TAD).subscribe(data => {
          this.dataprestataires = data;
          this.spinner.hide();
        });

      } else if (this.user.profilCode == "PF_PRESTATAIRE") {
        this.collectivitestructure.getStructureRattacheeCollectivite(this.user.structure,TypeStructureModel.TAD).subscribe(data => {
          this.dataprestataires = data;
          this.spinner.hide();
        });

      } else if (this.user.profilCode == "PF_TAD") {
        this.collectivitestructure.getStructureRattachee(TypeStructureModel.PRESTATAIRE, this.user.structure,TypeStructureModel.TAD).subscribe(data => {
          this.dataprestataires = data;
          this.spinner.hide();
        });
      } else {
        this.collectivitestructure.getStructureRattacheeFoyerPrestataire(TypeStructureModel.PRESTATAIRE, this.user.structure,TypeStructureModel.TAD).subscribe(data => {
          this.dataprestataires = data;
          this.spinner.hide();
        });
      }
    }

  }
  openFormCollectStructure(typestructure: any): void {
   // this.spinner.show();
    this.frmtadprestataire.typestructure = typestructure;
    this.frmtadprestataire.lblstructure = 'Prestataire';
    this.frmtadprestataire.collectiviteTad = "Unité";
    if (this.user.structure != 0) {
      this.structureService.get(this.user.structure).subscribe(data => {
        this.frmtadprestataire.collectivite = data;
      });
      this.frmtadprestataire.selectedstructure = this.user.structure;
    }
    this.frmtadprestataire.show(this);

  }
  deleteCollctiviteStructure(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.collectivitestructure.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussi' });
          this.filter(this, this.user.structure);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La Suppresion a échoué. Car cet élément a peut être été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.collectivitestructure.delete(id).subscribe(data => {
    //     this.filter(this, this.user.structure);
    //   });
    // }
  }
  detail(id: any, prestacol: any): void {
    // alert(this.prestataire.nom); 
    this.detailprestataire.detail(this, id, prestacol);

  }

  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.dataprestataires = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }

  tabSize(event) {
    this.page = event;
    //this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }

  RechercheAvanceCollPrestaire() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    if (this.idcollectivite == null)
      this.idcollectivite = 0;

    this.nom = this.nom ? this.nom : null;
    this.siret = this.siret ? this.siret : null;
    this.email = this.email ? this.email : null;
    this.adresse = this.adresse ? this.adresse : null;
    this.telephone = this.telephone ? this.telephone : null;
    this.responsable = this.responsable ? this.responsable : null;
    this.collectivitestructure.getRechercheBis(this.idcollectivite,TypeStructureModel.TAD, this.nom, this.siret, this.email, this.adresse,
      this.telephone, this.responsable, TypeStructureModel.PRESTATAIRE, this.user.structure).subscribe(data => {
        this.dataprestataires = data;
        this.dataprestataire = null;
        this.spinner.hide();
      });

  }
  reset(){
    this.idcollectivite = 0;
   }
   reset1(){
    this.siret = null;
   }
   reset2(){
    this.nom = null;
   }
   reset3(){
    this.email = null;
   }
   reset4(){
    this.adresse = null;
   }
   reset5(){
    this.responsable = null;
   }

   reset6(){
    this.telephone = null;
   }
  getDroit(): void {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_PRESTATAIRE_TAD").subscribe(data => {
      console.log(this.dataprestataires)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_PRESTATAIRE_TAD").subscribe(data => {
      console.log(this.dataprestataires)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_DETAILS_PRESTATAIRE_TAD").subscribe(data => {
      console.log(this.dataprestataires)
      if (!!data) {
        this.canDetails = true;
      } else {
        this.canDetails = false;
      }
    });

  }
}
