import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StructureService } from 'src/app/services/structure.service';
import { ActeurService } from 'src/app/services/acteur.service';
import { TemplatePrixService } from 'src/app/services/templatePrix.service';
import { FrmtemplateprixComponent } from 'src/app/templateprix/frmtemplateprix/frmtemplateprix.component';
import { FrmcollaborateursprestatairesComponent } from '../frmcollaborateursprestataires/frmcollaborateursprestataires.component';
import { FrmcollectivitecollaborateurComponent } from 'src/app/collectivite/frmcollectivitecollaborateur/frmcollectivitecollaborateur.component';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { SecurityService } from 'src/app/services/security.service';
import { User } from 'src/app/shared/session/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
declare  function  stransAdresse(id: any);
import Swal from 'sweetalert2';
@Component({
  selector: 'app-detailsprestataires',
  templateUrl: './detailsprestataires.component.html',
  styleUrls: ['./detailsprestataires.component.css']
})
export class DetailsprestatairesComponent implements OnInit {
  collaborateurs: any = [];
  templates: any = [];
  chauffeurs: any = [];
  template: any = null;
  chauffeur: any = null;
  collaborateur: any = null;
  referencemarche = null;
  prestataire;
  structure;
  parent;
  prestacol;
  page = 1;
  col;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canDetails = false;
  canConsulterCollaborateur = false;
  canCreateChauffeur = false;
  canUpdateChauffeur = false;
  canDeleteChauffeur = false;
  canDetailsChauffeur = false;
  canConsulterChauffeur = false;
  canCreateTemplatePrix = false;
  canUpdateTemplatePrix = false;
  canDeleteTemplatePrix = false;
  canDetailsTemplatePrix = false;
  canConsulterTemplatePrix = false;
  user: User = new User();
  code: any = '';
  prenom: any = null;
  nom: any = null;
  email: any = null;
  adresse: any = null;
  datenaissance: any = null;
  telephone: any = null;
  telephone2: any = null;
  information: any = null;
  fonction: any = null;
  prenomchauffeur: any = null;
  nomchauffeur: any = null;
  emailchauffeur: any = null;
  adressechauffeur: any = null;
  datenaissancechauffeur: any = null;
  telephonechauffeur: any = null;
  telephone2chauffeur: any = null;
  informationchauffeur: any = null;
  fonctionchauffeur: any = null;

  idcollectivite = 0;
  idtad = 0;
  idprestataire = 0;
  prest: any = 0;
  pres: any;
  critereDateDebut: any = '1';
  critereDateFin: any = '1';
  criteretarifJour: any = '1';
  criteretariNuit: any = '1';
  criteretariWeekend: any = '1';
  criteretarifMin: any = '1';
  criteretariSup: any = '1';
  dateFinMax = null;
  dateDebutMax = null;
  tarifJourMax = null;
  tarifNuitMax = null;
  tarifWeekMax = null;
  tarifMinMax = null;
  tariSupMax = null;
  libelle = null;

  dataDebut = null;
  dataFin = null;
  tarifJour = null;
  tariNuit = null;
  tariWeekend = null;
  tarifMin = null;
  tariSup = null;
  delaiMin = null;
  delaiAnn = null;
  heureJour = null;
  heureNuit = null;
  tarifweekend: any = '';
  collectivitestemp: any = [];
  idtadstemp: any = [];
  idprestatairestemp: any = [];
  tadSelected: any = 0;
  lstcollaborateurscollectivite = false;

  @ViewChild('frmtemplateprix') frmtemplateprix: FrmtemplateprixComponent;
  @ViewChild('frmcollaborateur') frmcollaborateur: FrmcollectivitecollaborateurComponent;

  constructor(private http: HttpClient,
    private structureService: StructureService,
    private templateprixService: TemplatePrixService,
    private acteurService: ActeurService, private securiteService: SecurityService,
    private authenticationService: AuthenticationService,private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.col = this.user.parent;
    this.getDroit();
    stransAdresse('adresse');
  }
  showCollectivite(): void {
    this.structureService.getStructuresByType(TypeStructureModel.COLLECTIVITE).subscribe(data => {
      this.collectivitestemp = data;

    });

  }
  showTad(): void {
    this.structureService.getStructuresByType(TypeStructureModel.TAD).subscribe(data => {
      this.idtadstemp = data;

    });

  }
  
  showPrestataire(): void {
    this.structureService.getStructuresByType(TypeStructureModel.PRESTATAIRE).subscribe(data => {
      this.idprestatairestemp = data;

    });

  }
  show(parent) {
    this.parent = parent;
    $('#modalFullscreen').modal('show');
  }

  filter(parent, presta: any): void {
    this.detail(parent, presta, this.prestacol);
  }

  showTemplates(pres: any, prestacol: any): void {
    alert(pres);
    this.detail(null, pres, prestacol);
  }

  detail(parent, id: any, pestacol: any): void {
  //  this.spinner.show();
    this.prestacol = pestacol;
    if (this.prestacol == null) {
      //alert(id);
      this.showacteur(id.id);
      this.showChauffeur(id.id);
      this.showTemplateByStructure(id.id);
      this.showCollectivite();
      this.showTad();
      this.showPrestataire();
      this.structure = id;
      this.frmcollaborateur.structure = id;
      this.frmcollaborateur.selectstructure = id.id;
      this.frmtemplateprix.pres = id;
      this.frmtemplateprix.prestacol = null;
      this.prestataire = id.id;
    } else {
      this.showacteur(this.prestacol.structure.id);
      this.showChauffeur(this.prestacol.structure.id);
      this.showTemplateByStructureCollectivite(this.prestacol.structure.id, this.prestacol.collectivite.id);

      this.structure = this.prestacol.structure;
      this.frmcollaborateur.structure = this.prestacol.structure;
      this.frmcollaborateur.selectstructure = this.prestacol.structure.id;
      this.frmtemplateprix.pres = null;
      this.frmtemplateprix.prestacol = this.prestacol.structure;
      this.prestataire = this.prestacol.structure.id;
    }


  }

  openFormTemplatePrix() {
    this.frmtemplateprix.prestataire = this.prestataire;
    this.frmtemplateprix.show(this);
    this.frmtemplateprix.lblnom ='Ajout Template Prix'

  }

  editTemp(id: any) {
    this.frmtemplateprix.edit(this, id);
    this.frmtemplateprix.lblnom ='Modification Template Prix'
  }

  openFormCollaborateurprestataire() {
    this.frmcollaborateur.show(this);
    
  }
  openFormActeur(typeacteur: any): void {
    //alert(typeacteur);
    this.lstcollaborateurscollectivite = false;
    this.frmcollaborateur.typeacteur = typeacteur;
    this.frmcollaborateur.lblstructure = 'Prestataire';
    if (typeacteur == 'COLLABORATEUR') {
      this.frmcollaborateur.lblnom = 'Nouveau Collaborateur';
      this.lstcollaborateurscollectivite = true;
    } else if (typeacteur == 'CONTACT') {
      this.frmcollaborateur.lblnom = 'Nouveau Contact';
    } else if (typeacteur == 'PENSIONNAIRE') {
      this.frmcollaborateur.lblnom = 'Nouveau Pensionnaire';
    } else if (typeacteur == 'CHAUFFEUR') {
      this.frmcollaborateur.lblnom = 'Nouveau Chauffeur';
    }
    this.frmcollaborateur.show(this);
  }

  editCol(id: any, typeacteur: any) {
    this.frmcollaborateur.typeacteur = typeacteur;
    this.frmcollaborateur.lblstructure = 'Prestataire';
    if (typeacteur == 'COLLABORATEUR') {
      this.frmcollaborateur.lblnom = 'Modification Collaborateur';
    } else if (typeacteur == 'CONTACT') {
      this.frmcollaborateur.lblnom = 'Modification Contact';
    } else if (typeacteur == 'PENSIONNAIRE') {
      this.frmcollaborateur.lblnom = 'Modification Pensionnaire';
    } else if (typeacteur == 'CHAUFFEUR') {
      this.frmcollaborateur.lblnom = 'Modification Chauffeur';
    }
    this.frmcollaborateur.editPersonne(this, id);
  }

  deleteTemp(id: any,): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.templateprixService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.detail(this, this.structure, this.prestacol);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.templateprixService.delete(id).subscribe(data => {
    //     this.detail(this, this.structure, this.prestacol);
    //   });
    // }
  }
  deleteCol(id: any,): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.acteurService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.detail(this, this.structure, this.prestacol);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.acteurService.delete(id).subscribe(data => {
    //     this.detail(this, this.structure, this.prestacol);
    //   });
    // }
  }


  showacteur(id: any): void {
    //alert(id);
    this.spinner.show();
    this.acteurService.getActeurRattachee(TypeActeurModel.COLLABORATEUR, id).subscribe(data => {
      this.collaborateurs = data;
      this.collaborateur = null;
      this.spinner.hide();
    });
  }

  showChauffeur(id: any): void {
    this.spinner.show();
    this.acteurService.getActeurRattachee(TypeActeurModel.CHAUFFEUR, id).subscribe(data => {
      this.chauffeurs = data;
      this.chauffeur = null;
      this.spinner.hide();
    });
  }

  showTemplateByStructure(id: any): void {
    this.spinner.show();
    this.templateprixService.getTemplatesByStructure(id).subscribe(data => {
      this.templates = data;
      this.template = null;
      this.spinner.hide();
    });

  }

  showTemplateByStructureCollectivite(id: any, col: any): void {
    this.spinner.show();
    this.templateprixService.getTemplatesByStructureCollectivite(id, col).subscribe(data => {
      this.templates = data;
      this.template = null;
      this.spinner.hide();
    });

  }


  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }

  getDroit(): void {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLETCOLLABORATEUR_PRESTATAIRE").subscribe(data => {
      console.log(this.collaborateurs)
      if (!!data) {
        this.canConsulterCollaborateur = true;
      } else {
        this.canConsulterCollaborateur = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_ADD_PRESCOLLABORATEUR").subscribe(data => {
      console.log(this.collaborateurs)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_DEL_PRESCOLLABORATEUR").subscribe(data => {
      console.log(this.collaborateurs)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_EDIT_COLLABORATEUR_PRESTATAIRE").subscribe(data => {
      console.log(this.collaborateurs)
      if (!!data) {
        this.canUpdate = true;
      } else {
        this.canUpdate = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLETCHAUFFEUR_PRESTATAIRE").subscribe(data => {
      console.log(this.chauffeurs)
      if (!!data) {
        this.canConsulterChauffeur = true;
      } else {
        this.canConsulterChauffeur = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_ADD_CHAUUFFEUR_PRESTATAIRE").subscribe(data => {
      console.log(this.chauffeurs)
      if (!!data) {
        this.canCreateChauffeur = true;
      } else {
        this.canCreateChauffeur = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_DEL_CHAUFFEUR_PRESTATAIRE").subscribe(data => {
      console.log(this.chauffeurs)
      if (!!data) {
        this.canDeleteChauffeur = true;
      } else {
        this.canDeleteChauffeur = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_EDIT_CHAUFFEUR_PRESTATAIRE").subscribe(data => {
      console.log(this.chauffeurs)
      if (!!data) {
        this.canUpdateChauffeur = true;
      } else {
        this.canUpdateChauffeur = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLETTEMPLATE_PRESTATAIRE").subscribe(data => {
      console.log(this.templates)
      if (!!data) {
        this.canConsulterTemplatePrix = true;
      } else {
        this.canConsulterTemplatePrix = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_ADD_TEMPLATE_PRESTATAIRE").subscribe(data => {
      console.log(this.templates)
      if (!!data) {
        this.canCreateTemplatePrix = true;
      } else {
        this.canCreateTemplatePrix = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_DEL_TEMPLATE_PRESTATAIRE").subscribe(data => {
      console.log(this.templates)
      if (!!data) {
        this.canDeleteTemplatePrix = true;
      } else {
        this.canDeleteTemplatePrix = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_ONGLET_EDIT_TEMPLATE_PRESTATAIRE").subscribe(data => {
      console.log(this.templates)
      if (!!data) {
        this.canUpdateTemplatePrix = true;
      } else {
        this.canUpdateTemplatePrix = false;
      }
    });
  }
  resetPrenom(){
    this.prenom = null;
  }

  resetAdresse(){
    this.adresse = null;
  }
  resetEmail(){
    this.email = null;
  }
  resetNom(){
    this.nom  = null;
  }
  resetTelephonefixe(){
    this.telephone = null;
  }

  resetPrenomchauffeur(){
    this.prenomchauffeur = null;
  }

  resetAdressechauffeur(){
    this.adressechauffeur = null;
  }
  resetEmailchauffeur(){
    this.emailchauffeur = null;
  }
  resetNomchauffeur(){
    this.nomchauffeur  = null;
  }
  resetTelephonefixechauffeur(){
    this.telephonechauffeur = null;
  }
  rechercheAvanceCollaborateur() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.prenom = this.prenom ? this.prenom : null;
    this.nom = this.nom ? this.nom : null;
    this.email = this.email ? this.email : null;
    this.adresse = this.adresse ? this.adresse : null;
    this.telephone = this.telephone ? this.telephone : null;
    // alert(this.user.structure)
    this.acteurService.getRecherche(this.prenom, this.nom, this.email, this.adresse,
      this.telephone, TypeActeurModel.COLLABORATEUR, this.prestataire, TypeStructureModel.PRESTATAIRE).subscribe(data => {
        this.collaborateurs = data;
        this.collaborateur = null;
        this.spinner.hide();
      });
      this.tabSize(event);

  }

  rechercheAvanceChauffeur() {
    
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.prenomchauffeur = this.prenomchauffeur ? this.prenomchauffeur : null;
    this.nomchauffeur = this.nomchauffeur ? this.nomchauffeur : null;
    this.emailchauffeur = this.emailchauffeur ? this.emailchauffeur : null;
    this.adressechauffeur = this.adressechauffeur ? this.adressechauffeur : null;
    this.telephonechauffeur = this.telephonechauffeur ? this.telephonechauffeur : null;
    // alert(this.user.structure)
    this.acteurService.getRecherche(this.prenomchauffeur, this.prenomchauffeur, this.emailchauffeur, this.adressechauffeur,
      this.telephonechauffeur, TypeActeurModel.CHAUFFEUR, this.prestataire, TypeStructureModel.PRESTATAIRE).subscribe(data => {
        this.chauffeurs = data;
       // this.chauffeur = null;
        this.spinner.hide();
      });
      this.tabSize(event);

  }
  rechercheAvanceTemplate() {
    //this.onCollectivteBlur($event);
    this.spinner.show();
    $(".collapse").collapse('hide');
    //alert(this.tarifJour)
    if (this.idcollectivite == null )
      this.idcollectivite = 0;
    if (this.idtad == null)
      this.idtad = 0;
    if (this.idprestataire == null)
      this.idprestataire = 0;

    // alert(this.tadSelected)
    this.libelle = this.libelle ? this.libelle : null;
    this.referencemarche = this.referencemarche ? this.referencemarche : null;
    this.dataDebut = this.dataDebut ? this.dataDebut : null;
    this.dateDebutMax = this.dateDebutMax ? this.dateDebutMax : null;
    this.dataFin = this.dataFin ? this.dataFin : null;
    this.dateFinMax = this.dateFinMax ? this.dateFinMax : null;
    this.tarifJour = this.tarifJour ? this.tarifJour : null;
    this.tarifJourMax = this.tarifJourMax ? this.tarifJourMax : null;
    this.tariSup = this.tariSup ? this.tariSup : null;
    this.tariSupMax = this.tariSupMax ? this.tariSupMax : null;
    this.tariNuit = this.tariNuit ? this.tariNuit : null;
    this.tarifNuitMax = this.tarifNuitMax ? this.tarifNuitMax : null;
    this.tarifweekend = this.tarifweekend ? this.tarifweekend : null;
    this.tarifWeekMax = this.tarifWeekMax ? this.tarifWeekMax : null;
    if (this.user.structure == 0) {
      this.templateprixService.getRechercheBisBis(this.idcollectivite,this.idtad,this.idprestataire,this.libelle, this.dataDebut, this.critereDateDebut, this.dateDebutMax, this.dataFin,
        this.critereDateFin, this.dateFinMax, this.tarifJour, this.criteretarifJour, this.tarifJourMax, this.tariNuit, this.criteretariNuit,
        this.tarifNuitMax, this.tariSup, this.criteretariSup, this.tariSupMax, this.tariWeekend, this.criteretariWeekend, this.tarifWeekMax,this.referencemarche).subscribe(data => {
          this.templates = data;
         // console.log(data);
          this.spinner.hide();
        });

    } else if (this.user.profilCode == "PF_PRESTAIRE") {
      this.templateprixService.getRecherchePrestataire(this.idcollectivite,this.idtad,this.idprestataire,this.libelle, this.dataDebut, this.critereDateDebut, this.dateDebutMax, this.dataFin,
        this.critereDateFin, this.dateFinMax, this.tarifJour, this.criteretarifJour, this.tarifJourMax, this.tariNuit, this.criteretariNuit,
        this.tarifNuitMax, this.tariSup, this.criteretariSup, this.tariSupMax, this.tariWeekend, this.criteretariWeekend,
        this.tarifWeekMax, this.pres,this.referencemarche).subscribe(data => {
          this.templates = data;
         // console.log(data);
          this.spinner.hide();
        });
    }
    else if (this.user.profilCode == "PF_AGCOL") {
      this.templateprixService.getRechercheCollectivite(this.idcollectivite, this.idtad,this.idprestataire,this.libelle,this.dataDebut, this.critereDateDebut, this.dateDebutMax, this.dataFin,
        this.critereDateFin, this.dateFinMax, this.tarifJour, this.criteretarifJour, this.tarifJourMax, this.tariNuit, this.criteretariNuit,
        this.tarifNuitMax, this.tariSup, this.criteretariSup, this.tariSupMax, this.tariWeekend, this.criteretariWeekend,
        this.tarifWeekMax, this.pres,this.referencemarche).subscribe(data => {
          this.templates = data;
         // console.log(data);
          this.spinner.hide();
          //alert(this.data)
        });
    } else if (this.user.profilCode == "PF_TAD") {
      this.templateprixService.getRechercheCollectivite(this.idcollectivite, this.idtad,this.idprestataire,this.libelle,this.dataDebut, this.critereDateDebut, this.dateDebutMax, this.dataFin,
        this.critereDateFin, this.dateFinMax, this.tarifJour, this.criteretarifJour, this.tarifJourMax, this.tariNuit, this.criteretariNuit,
        this.tarifNuitMax, this.tariSup, this.criteretariSup, this.tariSupMax, this.tariWeekend, this.criteretariWeekend,
        this.tarifWeekMax, this.col,this.referencemarche).subscribe(data => {
          this.templates = data;
          this.spinner.hide();
         // console.log(data);
        });
    }
    else if (this.user.profilCode == "PF_GFOYER") {
      this.templateprixService.getRechercheFoyer(this.idcollectivite,this.idtad,this.idprestataire,this.libelle, this.dataDebut, this.critereDateDebut, this.dateDebutMax, this.dataFin,
        this.critereDateFin, this.dateFinMax, this.tarifJour, this.criteretarifJour, this.tarifJourMax, this.tariNuit, this.criteretariNuit,
        this.tarifNuitMax, this.tariSup, this.criteretariSup, this.tariSupMax, this.tariWeekend, this.criteretariWeekend,
        this.tarifWeekMax, this.pres,this.referencemarche).subscribe(data => {
          this.templates = data;
          this.spinner.hide();
         // console.log(data);
        });
    }
    this.tabSize(event);
  }
 
  reset1(){
    this.idcollectivite = 0;
   }
   reset2(){
    this.idtad = 0;
   }
   reset3(){
    this.dataDebut = null;
   }
   reset4(){
    this.dataFin = null;
   }
   reset5(){
    this.dateDebutMax = null;
   }
   reset6(){
    this.dateFinMax = null;
   }
   reset7(){
    this.idprestataire = 0;
   }
   reset8(){
    this.libelle = null;
   }
   reset9(){
    this.referencemarche = null; 
   }
}
