<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Réservations
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Carte
      </small>
    </h1>

  </div>


  <div class="px-2">

    <div class="row mt-3">
      <div class="col-12">
        <div class="card ccard">
          <div class="px-1 px-md-3">

            <form class="mt-1 text-dark-m1"  novalidate="novalidate">

                  <div class=" mb-2">

                    <div class="  p-0">
                      <div class="form-group row mt-2">
                        <div class="col-sm-2 col-form-label text-sm-right pr-0">
                          Date :
                        </div>

                        <div class="col-sm-3 pr-0 pr-sm-3">
                          <div class="input-group date">
                            <input [(ngModel)]="reservationDate" class="form-control" id="reservationDate"
                                   name="reservationDate"
                                   type="text"/>
                            <div class="input-group-addon input-group-append">
                              <div class="input-group-text">
                                <i class="far fa-clock"></i>
                              </div>
                            </div>

                          </div>


                        </div>


                        <div class="col-sm-3">
                          <button class="btn btn-info btn-bold px-4" (click)="getTrajets()" type="button">
                            <i class="fa fa-check mr-1"></i>
                            Afficher
                          </button>
                        </div>

                      </div>

                    </div>

                  </div>


              <div class="row">

                <div class="col-8">

                  <div style="display: none;">
                    <input
                      [(ngModel)]="adresseDepart"
                      class="controls"
                      id="origin-input"
                      name="adresseDepart" placeholder="Adresse départ" type="text"
                    />

                    <input
                      [(ngModel)]="adresseArrivee"
                      class="controls"
                      id="destination-input"
                      name="adresseArrivee" placeholder="Adresse arrivée" type="text"
                    />

                    <div id="mode-selector" style="display: none">
                      <input
                        id="changemode-walking"
                        name="type"
                        type="radio"

                      />
                      <label for="changemode-walking">Walking</label>

                      <input id="changemode-transit" name="type" type="radio"/>
                      <label for="changemode-transit">Transit</label>

                      <input checked="checked" id="changemode-driving" name="type" type="radio"/>
                      <label for="changemode-driving">Driving</label>
                    </div>
                  </div>

                  <div id="map"></div>


                </div>

                <div class="col-md-4">

                  <p *ngFor="let trajet of trajets" class=" text-dark"><span [style.background-color]="trajet.color"
                                                                             class="p-0 pl-2 pr-2 mr-2"> </span>  {{trajet.passager}}
                    , <span class="text-muted text-sm">{{trajet.depart}} - {{trajet.arrivee}}</span></p>

                </div>

              </div>


            </form>




          </div>
        </div>

      </div>

    </div>


  </div>

</div>







