import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ReservationService} from "../../services/reservation.service";
import {User} from "../../shared/session/user";
import {AuthenticationService} from "../../shared/services/authentication.service";

declare function initMapTrajets(suffix: any);

declare function createDateField();

declare function trajets(suffix: any, trajets: any);

declare var $;

@Component({
  selector: 'app-carte',
  templateUrl: './carte.component.html',
  styleUrls: ['./carte.component.css']
})
export class CarteComponent implements OnInit {
  adresseDepart: any;
  adresseArrivee: any;
  reservations: any = [];
  reservationDate: any;
  user: User;
  trajets: any = [];

  constructor(private http: HttpClient, private  reservationService: ReservationService, private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    createDateField();
    initMapTrajets('');

  }

  getTrajets() {

    this.trajets = [];
    $('#reservationDate')[0].dispatchEvent(new Event('input', {bubbles: true}));

    this.reservationService.getForCal(this.user.id, this.reservationDate).subscribe(data => {
      this.reservations = data;
      this.reservations.forEach((reservation: any) => {

        // tslint:disable-next-line:max-line-length
        this.trajets.push({
          depart: reservation.adresseDepart,
          arrivee: reservation.adresseArrivee,
          passager: reservation.passagers[0]?.acteur?.personne.prenom + ' ' + reservation.passagers[0]?.acteur?.personne.nom,
          color: this.getRandomColor()
        });
      });


      trajets('', this.trajets);
    });
  }


  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }
}
