<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Tableau de bord
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Vue d'ensemble
      </small>
    </h1>

  </div>





  <div class="px-2  ">
    <div class="row">

      <div class="col-12 col-sm-6 col-lg-3 p-0 p-md-1 mb-2 mb-lg-0">
        <div class="p-2 py-lg-3 d-flex pos-rel mx-1 justify-content-start justify-content-lg-center ccard">

          <div class="pl-1">
                      <span class="d-inline-block bgc-success-tp1 p-3 radius-round text-center border-2 brc-black-tp11">
              	<i class="fas fa-chart-line text-white text-170 w-3 mx-3px"></i>
              </span>
          </div>

          <div class="pl-25">
            <div class="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span class="text-grey-d3 text-160 mr-4">
      				450
      			</span>


            </div>



            <div class="text-nowrap">
              Souscription/ce mois
            </div>
          </div>

        </div>
      </div>


      <div class="col-12 col-sm-6 col-lg-3 p-0 p-md-1 mb-2 mb-lg-0">
        <div class="p-2 py-lg-3 d-flex pos-rel mx-1 justify-content-start justify-content-lg-center ccard">

          <div class="pl-1">
                      <span class="d-inline-block bgc-blue-tp1 p-3 radius-round text-center border-2 brc-black-tp11">
              	<i class="fa fa-check-circle text-white text-170 w-3 mx-3px"></i>
              </span>
          </div>

          <div class="pl-25">
            <div class="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span class="text-grey-d3 text-160 mr-4">
      				235
      			</span>

              <div class="text-success opacity-1 pr-1 font-bolder">
                +12%
                <i class="fas fa-level-up-alt"></i>
              </div>

            </div>



            <div class="text-nowrap">
             Validés
            </div>
          </div>

        </div>
      </div>


      <div class="col-12 col-sm-6 col-lg-3 p-0 p-md-1 mb-2 mb-lg-0">
        <div class="p-2 py-lg-3 d-flex pos-rel mx-1 justify-content-start justify-content-lg-center ccard">

          <div class="pl-1">
                      <span class="d-inline-block bgc-purple-tp1 p-3 radius-round text-center border-2 brc-black-tp11">
              	<i class="fa fa-money-bill text-white text-170 w-3 mx-3px"></i>
              </span>
          </div>

          <div class="pl-25">
            <div class="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span class="text-grey-d3 text-160 mr-4">
      				120
      			</span>


              <div class="text-danger opacity-2 pr-1 font-bolder">
                -4%
                <i class="fas fa-long-arrow-alt-down text-120"></i>
              </div>
            </div>



            <div class="text-nowrap">
              En attente de paiement
            </div>
          </div>

        </div>
      </div>


      <div class="col-12 col-sm-6 col-lg-3 p-0 p-md-1 mb-2 mb-lg-0">
        <div class="p-2 py-lg-3 d-flex pos-rel mx-1 justify-content-start justify-content-lg-center ccard">

          <div class="pl-1">
                      <span class="d-inline-block bgc-danger-tp1 p-3 radius-round text-center border-2 brc-black-tp11">
              	<i class="far fa-check-double text-white text-170 w-3 mx-3px"></i>
              </span>
          </div>

          <div class="pl-25">
            <div class="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span class="text-grey-d3 text-160 mr-4">
      				 15
      			</span>


            </div>



            <div class="text-nowrap">
              En attente de validation
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>




  <hr class="mx-n3 mt-45 mb-0 brc-secondary-l2 opacity-2" />




  <div class="row px-3">
    <div class="col-12 col-lg-6 pb-25 pt-35">
      <div class="row mx-n2 mx-lg-0 h-100">
        <div class="col-12 px-0">
          <div class="d-flex mb-4">
            <h4 class="d-inline-block flex-grow-1 mr-3 text-dark-m3 text-130">
              Délivrance des titres
              <span class="text-grey text-80"></span>

            </h4>


          </div>

          <div class="row mb-3 mx-0 px-3 pt-1 pb-15 radius-1 bgc-secondary-l3">
            <div class="col-6 px-0">
                        <span class="text-120 opacity-1">

                        </span>

              <span class="text-dark-tp4 text-160">
                            965
                        </span>

              <span class="text-dark-tp5 ml-1">
                            Souscriptions
                        </span>

              <span class="d-block d-sm-inline text-success ml-2 mt-2 text-600">
                            (+9.75% <i class="fas fa-caret-up text-120"></i>)
                        </span>
            </div>

            <div class="col-6 px-0 text-right">
                        <span class="text-120 opacity-1">

                        </span>

              <span class="text-dark-tp4 text-150">
                             450
                        </span>

              <span class="text-dark-tp5 ml-1">
                            En attente de paiement
                        </span>

              <span class="d-block d-sm-inline text-orange-d3 ml-2 mt-2 text-600 text-95">
                            (+14.26% <i class="fas fa-caret-up text-120"></i>)
                        </span>
            </div>
          </div>
        </div>

        <div class="col-12 px-0 px-md-3">
          <canvas class="mw-100 mt-3" id="revenue-expense-chart" height="130"></canvas>
        </div>
      </div><!-- /.row -->
    </div>


    <div class="col-12 col-lg-6 pt-35 pb-25 pos-rel">
      <div class="d-none d-lg-block position-tl h-100 border-r-1 brc-secondary-l3"></div><!-- the border line between two columns (in desktop) -->
      <div class="d-lg-none position-tl w-100 border-b-1 brc-secondary-l3"></div><!-- the border line between two rows (in mobile) -->

      <!-- page views -->
      <div class="w-100 border-t-2 brc-secondary-l3 mt-1"></div> <!-- the 2px line separating pageviews chart and piecharts -->

      <!-- Pie charts -->
      <div class="row mt-35 pos-rel">
        <div class="col-12 col-md-6 col-lg-7 py-25 px-lg-2 pos-rel">
          <div class="mx-2 text-dark-l1 mb-2 text-right text-110">
            <i class="fa fa-arrow-down rotate-45"></i>
            Par capital
          </div>

          <div class="d-flex flex-row-reverse">
            <div class="pos-rel ml-1 align-self-start">


              <canvas id="piechart1" class="pos-rel" style="height: 150px; width: 150px; max-height: 150px; max-width: 150px;"></canvas>
            </div>
          </div>
        </div>


        <div class="col-12 col-md-6 col-lg-5 py-25 px-lg-2 pos-rel">
          <div class="mx-2 text-dark-l1 mb-2 text-110">
            Par methode de paiement
            <i class="fa fa-arrow-down rotate-n45"></i>
          </div>

          <div class="d-flex">
            <div class="pos-rel mr-3 mr-sm-2 align-self-start">


              <canvas id="piechart2" class="pos-rel" style="height: 150px; width: 150px; max-height: 150px; max-width: 150px;"></canvas>
            </div>
          </div>
        </div>
      </div><!-- /.row -->
    </div>
  </div>




  <hr class="mx-n3 mt-0 brc-secondary-l opacity-2" />





</div>
<!-- "Dashboard 4" page script to enable its demo functionality -->
