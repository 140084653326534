import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PassagerService } from 'src/app/services/passager.service';
import { FrmreservationComponent } from '../frmreservation/frmreservation.component';
import { PassagersComponent } from '../passagers/passagers.component';

declare function createTimeField();

declare var $: any;

@Component({
  selector: 'app-frminformationchauffeur',
  templateUrl: './frminformationchauffeur.component.html',
  styleUrls: ['./frminformationchauffeur.component.css']
})
export class FrminformationchauffeurComponent implements OnInit {
  reservation: any;
  passagersComponent: PassagersComponent;
  adresseDepart: any;
  adresseArrivee: any;
  adresseDepartPassager: any;
  adresseArriveePassager: any;
  heurePriseEncharge : any;
  heureDepose : any;
  pensionnaire : any;
  passager: any;

  constructor(private http: HttpClient, private passagerService: PassagerService) { }

  ngOnInit(): void {
    createTimeField();
  }
  reset(){
    this.heurePriseEncharge = null;
    this.heureDepose = null;
  }
  fermer(){
    this.reset();
    $('#modalFormEditPassager').modal('hide');
  }

  show(passager: any,  parent: PassagersComponent) {
   // alert(passager);
    if(!!passager){
      this.passager  = passager;
      this.passagersComponent  = parent;
    //  alert(this.reservation);
      this.pensionnaire = this.passager.acteur;
      this.adresseDepart = this.passager.adresseDepart;
      this.adresseArrivee = this.passager.adresseArrivee;
      this.heurePriseEncharge = this.passager.heurePriseEncharge;
      this.heureDepose = this.passager.heureDepose;
     // console.log(passager);
    }
    $('#modalFormEditPassager').modal('show');


  }

  savePassenger() { 
    $('#heurePriseEncharge')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#heureDepose')[0].dispatchEvent(new Event('input', { bubbles: true }));
    
      const headers = {};
      const body = {actif: 1, acteur: {id : this.pensionnaire.id}, adresseDepart: this.adresseDepart, 
      adresseArrivee: this.adresseArrivee, heurePriseEncharge: this.heurePriseEncharge, 
      heureDepose: this.heureDepose };

   // alert(body.heurePriseEncharge);
      this.passagerService.updateHeure(headers, body, this.passager.id).subscribe(data => {
        this.passagersComponent.getPassagers();
         console.log(data);
        
      });
    this.fermer(); 

  }

}
