<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Réservations
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Calendrier
      </small>
    </h1>

  </div>


  <div class="px-2">

    <div class="row mt-3">
      <div class="col-12">


        <div class="card dcard">
          <div class="card-body px-1 px-md-3">

            <span class="text-info"><i class="fa fa-info-circle"></i> Cliquez sur une date pour ajouter un jour férie</span>

            <full-calendar #mycal [options]="calendarOptions"></full-calendar>


          </div>
        </div>

      </div>

    </div>


  </div>

</div>


<app-form-ferie #formFerieComponent></app-form-ferie>

<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p
  style="color: #4AA0DF"> Chargement en cours... </p></ngx-spinner>
