export class Tool {

    getAge(dateNaissance) {

    var timeDiff = Math.abs(Date.now() - new Date(dateNaissance).getTime());
    var age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

    return age;
  }

  
}
