import { Component, OnInit } from '@angular/core';
import {StatutService} from '../../services/statut.service';
import {ReservationService} from '../../services/reservation.service';
import {HttpClient} from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-traiter',
  templateUrl: './traiter.component.html',
  styleUrls: ['./traiter.component.css']
})
export class TraiterComponent implements OnInit {
  commentaire: any;
  reservation: any;
  nextStatus: any;

  constructor(private http: HttpClient, private statutService: StatutService, private reservationService: ReservationService) { }

  ngOnInit(): void {
  }

  validate() {

    const state = this.getState(this.reservation.statut.code);
    this.statutService.getByCode(state).subscribe(data => {
        this.nextStatus = data;

        const headers = { };
        const body = {id: this.reservation.id, adresseDepart: this.reservation.adresseDepart, adresseArrivee: this.reservation.adresseArrivee , actif: true,
          distance: this.reservation.distance, estimatedDuration: this.reservation.estimatedDuration,
          motifTransport:  this.reservation.motifTransport, noteChauffeur: this.reservation.noteChauffeur,
          reservationDate: this.reservation.reservationDate,
          statut: this.nextStatus, transporteur : this.reservation.templatePrix.transporteur, templatePrix: this.reservation.templatePrix  };

        this.reservationService.update(headers, body, this.reservation.id).subscribe(data => {
        this.reservation = data;
        document.location.href = '/consulsen/reservation/details/' + this.reservation.id;
      });
    });

  }

  reject() {

  }

  traiter(reservation: any) {

    this.reservation = reservation;
    $('#modalTraitement').modal('show');


  }

   getState(code: any) {
    let state = '';
    switch (code) {

      case 'EAVAL':
        state = 'EAVALTRANS';
        break;
      case 'EAVALTRANS':
        state = 'VAL';
        break;
      case 'VAL':
        state = 'TER';
        break;
      default:
        break;

    }

    return state;
  }
}
