import {Component, OnInit} from '@angular/core';
import {SecurityService} from "../../services/security.service";
//import {SecurityService} from "../../services/security.service";

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit {
  profils: any = [];
  modules: any = [];
  features: any = [];
  profile: any;
  module:  any;
  action: any;
  actions: any = [];
  feature:any;

  tabAction = {};
  tabFeature = {};

  constructor(private securityService: SecurityService) {
  }

  ngOnInit(): void {
    this.getProfils();
  }

  getProfils(): void {
   this.securityService.getProfils().subscribe(data => {
      this.profils = data;
    });

  } 

  getModules(): void {
    this.securityService.getModules().subscribe(data => {
      this.modules = data;
    });
   
  }
  getModulesBis(profile): void {
    this.securityService.getModulesByProfif(profile).subscribe(data => {
      this.modules = data;
    });
   
  }


  selectProfil(profil) {
    this.profile = profil;
    this.getModulesBis(this.profile.code);
  }

  selectModule(module) {
    this.module = module;
    this.getActions();
  }


  private getFeatures() {
    this.securityService.getFeatures(this.module.code).subscribe(data => {
      this.features = data;
    //  console.log("features",this.features);
      this.tabFeature={};
      this.features.forEach(aFeature => {
        let allchecked = true;
         aFeature.actions.forEach(actionFils => {
            if(!this.checkAction(actionFils.code))
              allchecked = false;
         });
         
         this.tabFeature[aFeature.feature.code] = allchecked;
        // console.log('objFeature ==',this.objFeature)
         
      });
    });
   
  }

  selectFeature(feat: any) {

  }

  onActionChange(e, value) {
    this.action = value;
    const headers = {};
    const body = {allow: true, action: this.action, profil: this.profile, };

    if (e.target.checked) {
        body.allow = true;
    } else {
        body.allow = false;
    }

    this.securityService.configure(headers, body).subscribe(data => {
      this.getActions();
    });

  }

  onFeatureChange(e, value) {
    this.feature = value;
 // console.log('le parent ',this.feature)
  this.feature.actions.forEach(act => {
    this.onActionChange(e,act);
    
    //act.code = true;
  });
  
   

  }

  checkAction(action: string) {
    return this.actions.includes(action);
  }

  checkfeature(feature: string) {
    return this.features.includes(feature);
  }

  private getActions() {

    this.securityService.getActions(this.module.code, this.profile.code).subscribe(data => {
      this.actions = data;
      //console.log('liste des actions', this.actions)
      this.getFeatures();
      this.tabAction = {};
      this.actions.forEach(act => {
        this.tabAction[act] = true;
      });
    });

  }
}
