<div aria-hidden="true" class="modal fade modal-ds2 " id="modalFormContactPassager" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Contact passager
        </h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="card ccard">

          <div class="card-body px-0 py-2">


            <div class="col-md-12">
              <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                <h3 class="text-125 pl-1 mb-3 mb-sm-0 text-secondary-d4">
                  Contacts
                </h3>

                <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                  <i (click)="getContacts()" class="fa fa-search position-rc ml-25 text-primary-m1"></i>
                  <input [(ngModel)]="search" class="form-control w-100 pl-45 radius-1 brc-primary-m4" name="search"
                         placeholder="Rechercher ..."
                         type="text" (keyup.enter)="getContacts()">
                </div>


              </div>

              <table
                class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                <tr>
                  <th class="text-center pr-0">

                  </th>

                  <th>
                    Nom
                  </th>


                  <!-- <th>
                    Adresse
                  </th> -->

                  <th>
                    Téléphone fixe
                  </th>

                  <th>
                    Téléphone portable
                  </th>
                  


                </tr>
                </thead>

                <tbody class="mt-1">
                <tr *ngFor="let ctc of contacts| paginate : {
                  itemsPerPage: tableSize,
                  currentPage: page,
                  totalItems: count}; let i = index" [class.active]="i == currentIndex" class="bgc-h-yellow-l4 d-style">
                  <td class="text-center pr-0 pos-rel">
                    <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                      <!-- border shown on hover -->
                    </div>
                    <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                      <!-- border shown when row is selected -->
                    </div>

                    <label>
                      <input (change)="ctcChange(ctc)" [value]="ctc.id" class="align-middle" name="cbxContact"
                             type="radio">
                    </label>
                  </td>

                  <td class="text-blue-d1 text-600 text-95">
                    {{ctc.personne.prenom}} {{ctc.personne.nom}}
                  </td>


                  <!-- <td class="text-600 text-orange-d2">
                    {{ctc.personne.adresse}}
                  </td> -->

                  <td class="text-600 text-orange-d2">
                    {{ctc.personne.telephone1}}
                  </td>

                  <td class="text-600 text-orange-d2">
                    {{ctc.personne.telephone2}}
                  </td>
                </tr>
                </tbody>
              </table>

              <!-- table footer -->
              <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                <div class="text-nowrap align-self-center align-self-sm-start">
                    Afficher 1 - {{ tableSize }} of {{contacts.length}}
                    <select (change)="tableData($event)" class="custom-select">
                    <option *ngFor="let size of tableSizesArr" [value]="size">
                        Afficher {{ size }}
                    </option>
                  </select>
                </div>
                <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                    <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next" (pageChange)="tabSize($event)">
                    </pagination-controls>
                </div>
                <div>

                </div>
            </div>

            </div>


          </div>
        </div>


        <label class="mt-25">
          <input (change)="setIsAll($event)" [checked]="isAll" class="input-lg bgc-blue" id="ckboxDestination"
                 type="checkbox">
          Utiliser ce contact pour tous les passagers
        </label>


      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Fermer
        </button>
        <button (click)="saveContact()" class="btn btn-primary" type="button">
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>
