<div class="modal fade modal-ds " id="modalEnfant" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Nouveau {{lblnom}}
                </h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>

            <div class="modal-body">

                <div class="card ccard">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                            <label class="mb-0">
                                Foyer <label style="color:red">
                                    *
                                </label>
                            </label>
                        </div>
        
                        <div class="col-sm-9">
                            <select (change)="openSelect()" [(ngModel)]="selectstructure" name="selecttad" class="form-control"
                                id="foyer">
                                <option value="">&nbsp;</option>
                                <option *ngFor="let col of foers;" value="{{col.id}}">{{col.nom}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="card-body px-0 py-2">
                        <div class="tab-content tab-sliding border-0 px-0">
                            <div class="tab-pane show text-95 px-25 active" id="enfantselc" role="tabpanel" aria-labelledby="enfantselc-tab-btn">
                                <table class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                                    <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                        <tr>
                                            <th class="text-center pr-0">
                                                <label class="py-0">
                          <input type="checkbox" class="align-bottom mb-n1 border-2 text-dark-m3">
                        </label>
                                            </th>

                                            <th>
                                                Nom
                                            </th>

                                            <th>
                                                Prénom
                                            </th>

                                            <th class="d-none d-sm-table-cell">
                                                Age
                                            </th>

                                            <th class="d-none d-sm-table-cell">
                                                Téléphne portable
                                            </th>

                                            <th class="d-none d-sm-table-cell">
                                                Tad/service
                                            </th>
                                           

                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody class="mt-1">
                                        <tr class="bgc-h-yellow-l4 d-style" *ngFor="let enf of enfants;">
                                            <td class="text-center pr-0 pos-rel">
                                                <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                                    <!-- border shown on hover -->
                                                </div>
                                                <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                                    <!-- border shown when row is selected -->
                                                </div>

                                                <label>
                          <input type="checkbox" [value]="enf.personne.id" (change)="oncpChange($event)"   class="align-middle">
                        </label> 
                                            </td>

                                            <td>
                                                <span class="text-dark-d1 text-600 text-95">{{enf.personne.nom}}</span>
                                            </td>
                                            <td>
                                                <span class="text-dark-d1 text-600 text-95">{{enf.personne.prenom}}</span>
                                            </td>

                                            <td class="text-600 text-orange-d2">
                                                <span class="text-dark-d1 text-600 text-95">{{getAge(enf.personne.datenaissance)}} ans</span>
                                            </td>
                                            <td class="text-600 text-orange-d2">
                                                <span class="text-dark-d1 text-600 text-95">{{enf.personne.telephone2}}</span>
                                            </td>

                                            <td class="text-600 text-orange-d2">
                                                <span class="text-dark-d1 text-600 text-95">{{enf.structure.nom}}</span>
                                            </td>
                                            
                                        </tr>
                                    </tbody>
                                </table>

                            </div>


                        </div>
                    </div>
                </div>



            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="fermer()">
            Fermer
          </button>
                <button type="button" class="btn btn-primary" (click)="saveEnfant()">
            Enregistrer
          </button>

            </div>
        </div>
    </div>
</div>