<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Fontions avancées
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Historique des simulations
      </small>
    </h1>

  </div>


  <div class="px-2">

    <div class="card dcard">
      <div class="card-body px-1 px-md-3">


        <table
          class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
          <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
          <tr>

            <th>
              Structure
            </th>

            <th>
              Date
            </th>

            <th>
              Heure
            </th>

            <th class="text-right">
              Nombre de lignes
            </th>

            <th class="text-right">
              Nbre de requêtes générées
            </th>


            <th>

            </th>


          </tr>

          </thead>

          <tbody class="pos-rel">

          <tr *ngFor="let log of logs" class="d-style bgc-h-default-l4">
            <td>

              <span>{{log.collectivite?.nom}}</span>

            </td>

            <td class="text-grey">
              {{log.simulationDate | date:'dd/MM/y'}}
            </td>

            <td class="text-grey">
              {{log.simulationTime}}
            </td>

            <td class="text-grey text-right">
              {{log.lignes}}
            </td>

            <td class="text-grey text-right">
              {{log.queries}}
            </td>

            <td>
              <span (click)="viewDetails(log)" class="mouse-pointer text-info" title="Voir les détails"><i
                class="fa fa-list"></i> </span>
            </td>


          </tr>

          </tbody>
        </table>

      </div>

    </div>


  </div>

</div>


<app-details-historique #detailsHistoriqueComponent></app-details-historique>

<ngx-spinner bdColor="rgba(255,255,255,0)" color="#4AA0DF" fullScreen="false" size="medium" type="timer"><p
  style="color: #4AA0DF"> Chargement en cours... </p></ngx-spinner>
