import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';
import { StatutModel } from '../shared/models/statut-model.model ';

@Injectable({
  providedIn: 'root'
})
export class StatutService {

  constructor(private http: HttpClient) {
  }

  getByStatut(statut: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'reservation/listbystatut/' + statut);
  }

  getData() {
    return this.http.get(AppModule.API_ENDPOINT + 'statut/list');
  }

  getManualStatues() {
    return this.http.get(AppModule.API_ENDPOINT + 'statut/manuals');
  }

  get(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'statut/get/' + id);
  }

  save(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'statut/create', body, {headers});
  }

  update(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + 'statut/update/' + id, body, {headers});
  }


  delete(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + 'statut/delete/' + id);
  }

  getByCode(code: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'statut/statut/' + code);
  }

  getByLibelle(libelle: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'statut/statutlibelle/' + libelle);
  }
}
