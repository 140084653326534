<div class="page-content">
    <div class="page-header border-0">
        <h1 class="page-title text-primary-d2 text-160">
            Budgets
            <small class="page-info text-secondary-d3">
                <i class="fa fa-angle-double-right text-80"></i>
                Liste des budgets
            </small>
        </h1>

    </div>


    <div class="px-2">


        <div class="card dcard">
            <div class="card-body px-1 px-md-3">

                <form autocomplete="off" (keyup.enter)="rechercheAvanceBudget()">
                    <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


                        <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                            <button *ngIf="this.canCreate" type="button" (click)="openFormBudget()"
                                class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                <i class="fa fa-plus mr-1"></i>
                                Ajouter <span class="d-sm-none d-md-inline"></span>
                            </button>
                        </div>

                        <div class="mb-2 mb-sm-0">

                        </div>
                    </div>
                    <div class="card w-100 mt-1 mb-1">
                        <div class="card-body p-0">
                            <div class="accordion">
                                <div class="card border-0 mt-1px bgc-primary-l5">
                                    <div class="card-header border-0 bgc-transparent mb-0">
                                        <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                            <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                href="#rechercheAvanceeBudgetOrganisation" data-toggle="collapse"
                                                aria-expanded="false"
                                                aria-controls="rechercheAvanceeBudgetOrganisation">
                                                Recherche avancée

                                                <span
                                                    class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                    <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                </span>
                                                <span
                                                    class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                    <i
                                                        class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                </span>
                                            </a>
                                        </h2>
                                    </div>
                                    <div id="rechercheAvanceeBudgetOrganisation" class="collapse"
                                        aria-labelledby="headingTwo4" data-parent="#rechercheAvanceeBudgetOrganisation"
                                        style="">
                                        <div
                                            class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">
                                            <div class="form-group row">


                                                <div class="col-md-5">

                                                    <div class="form-group row">
                                                        <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Unité
                                                            </label>
                                                        </div>
                                                        <div class="col-sm-8 pl-2" (keyup.enter)="rechercheAvanceBudget()">
                                                            <select #searchTad (change)="searchTad.blur(); srch.focus()" class="form-control coll" [(ngModel)]="tadSelected"
                                                                name="tadSelected">
                                                                <option value="null">&nbsp;</option>
                                                                <option *ngFor="let tad of
                                                                    tads;" value="{{tad.id}}">{{tad.nom}}</option>
                                                            </select>
                                                            <input #srch style="height: 0px; width: 0px; padding: 0px; border: none;"
                                                            type="text"/>
                                                            <button type="button" class="btn btn-sm btn-secondary"
                                                                (click)='resetTad()'>
                                                                Effacer
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>

                                            <div class="form-group row">


                                                <div class="col-md-5">

                                                    <div class="form-group row">
                                                        <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Date début
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-8 pl-2">
                                                            <select #searchDateDebut (change)="searchDateDebut.blur(); srch.focus()"  [(ngModel)]="critereDateDebut"
                                                                name="critereDateDebut" class="form-control">
                                                                <option value="1">Egal</option>
                                                                <option value="2">Inférieur</option>
                                                                <option value="3">Supérieur</option>
                                                                <option value="4">Compris entre</option>
                                                            </select>
                                                            <input type="date" [(ngModel)]="dateDebut" name="dateDebut"
                                                                class="form-control">
                                                                
                                                            <button type="button" class="btn btn-sm btn-secondary"
                                                                (click)='reset1()'>
                                                                Effacer
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-md-5">

                                                    <div class="form-group row">
                                                        <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Date fin
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-8 pl-2">
                                                            <select #searchDateFin (change)="searchDateFin.blur(); srch.focus()" [(ngModel)]="critereDateFin" name="critereDateFin"
                                                                class="form-control">
                                                                <option value="1">Egal</option>
                                                                <option value="2">Inférieur</option>
                                                                <option value="3">Supérieur</option>
                                                                <option value="4">Compris entre</option>
                                                            </select>
                                                            <input type="date" [(ngModel)]="dateFin" name="dateFin"
                                                                class="form-control">
                                                            <button type="button" class="btn btn-sm btn-secondary"
                                                                (click)='reset2()'>
                                                                Effacer
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>




                                            </div>
                                            <div class="form-group row">


                                                <div class="col-md-5">

                                                    <div class="form-group row">
                                                        <div *ngIf="this.critereDateDebut == '4'"
                                                            class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Date début max
                                                            </label>
                                                        </div>

                                                        <div *ngIf="this.critereDateDebut == '4'" class="col-sm-8 pl-2">
                                                            <input type="date" [(ngModel)]="dateDebutMax"
                                                                name="dateDebutMax" class="form-control">
                                                            <button type="button" class="btn btn-sm btn-secondary"
                                                                (click)='reset3()'>
                                                                Effacer
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-md-5">

                                                    <div class="form-group row">
                                                        <div *ngIf="this.critereDateFin == '4'"
                                                            class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Date fin max
                                                            </label>
                                                        </div>

                                                        <div *ngIf="this.critereDateFin == '4'" class="col-sm-8 pl-2">
                                                            <input type="date" [(ngModel)]="dateFinMax"
                                                                name="dateFinMax" class="form-control">
                                                            <button type="button" class="btn btn-sm btn-secondary"
                                                                (click)='reset4()'>
                                                                Effacer
                                                            </button>
                                                        </div>

                                                    </div>

                                                </div>




                                            </div>


                                            <div class="form-group row">

                                                <div class="col-md-5">

                                                    <div class="form-group row">
                                                        <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Budget initial
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-8 pl-2">
                                                            <select #searchMontant (change)="searchMontant.blur(); srch.focus()" [(ngModel)]="critereMontant" name="critereMontant"
                                                                class="form-control">
                                                                <option value="1">Egal</option>
                                                                <option value="2">Inférieur</option>
                                                                <option value="3">Supérieur</option>
                                                                <option value="4">Compris entre</option>
                                                            </select>
                                                            <input type="number" [(ngModel)]="montant" name="montant"
                                                                class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                                (click)='resetMontant()'>
                                                                Effacer
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="this.critereMontant == '4'" class="col-md-5">

                                                    <div class="form-group row">
                                                        <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Budget initial max
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-8 pl-2">
                                                            <input type="number" [(ngModel)]="montantMax"
                                                                name="montantMax" class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                                (click)='resetMontantMax()'>
                                                                Effacer
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-md-4">

                                                    <div class="form-group row">


                                                        <div class="mb-2 mb-sm-0">
                                                            <button (click)="rechercheAvanceBudget()" type="button"
                                                                class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                <i class="fa fa-search mr-1"></i>

                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                    <table
                        class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                        <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                            <tr>
                                <th class="text-center pr-0">

                                </th>

                                <th>
                                    Unité
                                </th>

                                <th>
                                    Date Début
                                </th>

                                <th>
                                    Date Fin
                                </th>

                                <th class="d-none d-sm-table-cell" style="text-align: right;">
                                    Budget initial
                                </th>

                                <th class="d-none d-sm-table-cell" style="text-align: right;">
                                    Budget restant
                                </th>
                                <th class="d-none d-sm-table-cell">

                                </th>

                                <th></th>
                            </tr>
                        </thead>

                        <tbody class="mt-1">
                            <tr *ngFor="let budget of databudgets| paginate : {
                                itemsPerPage: tableSize,
                                currentPage: page,
                                totalItems: count}; let i = index" [class.active]="i == currentIndex"
                                class="bgc-h-yellow-l4 d-style">
                                <td class="text-center pr-0 pos-rel">
                                    <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                        <!-- border shown on hover -->
                                    </div>
                                    <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                        <!-- border shown when row is selected -->
                                    </div>

                                </td>
                                <td>
                                    <a class="text-blue-d1 text-600 text-95">{{budget.tad.nom}}</a>
                                </td>

                                <td >
                                    <a class="text-600 text-orange-d2">{{budget.datedebut}}</a>
                                </td>

                                <td class="text-600 text-orange-d2">
                                    {{budget.datefin}}
                                </td>

                                <td class="d-none d-sm-table-cell text-grey-d1" style="text-align: right;">
                                    <span> 
                                    {{budget.montant | number: '1.0-2':'fr-FR'}} €
                                </span>
                                </td>

                                <td class="d-none d-sm-table-cell text-grey-d1" style="text-align: right;">
                                    {{budget.montantrestant | number: '1.0-2':'fr-FR'}} €
                                </td>
                                
                                <td>
                                    <!-- action buttons -->
                                    <div class="d-none d-lg-flex">
                                        <a href="#" *ngIf="this.canUpdate" (click)="edit(budget.id)"
                                            class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                                            <i class="fa fa-pencil-alt"></i>
                                        </a>

                                        <a href="#" *ngIf="this.canDelete" (click)="delete(budget.id)"
                                            class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                            <i class="fa fa-trash-alt"></i>
                                        </a>

                                    </div>

                                    <!-- show a dropdown in mobile -->
                                    <div class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                        <a href="#" class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                            data-toggle="dropdown">
                                            <i class="fa fa-cog"></i>
                                        </a>

                                        <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                            <div class="dropdown-inner">
                                                <div
                                                    class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                                                    Unité 1
                                                </div>
                                                <a href="#" class="dropdown-item">
                                                    <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i> Modifier
                                                </a>
                                                <a href="#" class="dropdown-item">
                                                    <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                    Supprimer
                                                </a>
                                                <a href="#" class="dropdown-item">
                                                    <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i> Autre
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </td>
                            </tr>


                        </tbody>
                    </table>

                    <!-- table footer -->
                    <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                        <div class="text-nowrap align-self-center align-self-sm-start">
                            Afficher 1 - {{ tableSize }} of {{databudgets.length}}
                            <select (change)="tableData($event)" class="custom-select">
                                <option *ngFor="let size of tableSizesArr" [value]="size">
                                    Afficher {{ size }}
                                </option>
                            </select>
                        </div>
                        <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                            <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                (pageChange)="tabSize($event)">
                            </pagination-controls>
                        </div>
                        <div>

                        </div>
                    </div>
                </form>



            </div>
            <!-- /.card-body -->
        </div>



    </div>

</div>



<app-frmbudget #frmbudgetComponent></app-frmbudget>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
