import { Component, OnInit, ViewChild } from '@angular/core';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { TypetemplateService } from 'src/app/services/typetemplate.service';
import { StructureService } from 'src/app/services/structure.service';
import { HttpClient } from '@angular/common/http';
import { TemplatePrixService } from 'src/app/services/templatePrix.service';
import { FrmtemplateprixComponent } from 'src/app/templateprix/frmtemplateprix/frmtemplateprix.component';

@Component({
  selector: 'app-lsttemplateprixcollectivite',
  templateUrl: './lsttemplateprixcollectivite.component.html',
  styleUrls: ['./lsttemplateprixcollectivite.component.css']
})
export class LsttemplateprixcollectiviteComponent implements OnInit {

  data: any = [];
  template: any = null;

  collectivites: any = [];
  types: any = [];
  prestataires: any = [];

delaiannualation: any = '';

delaiminim: any = '';



  flagapproche: any = '';



 flagretour: any = '';


  pointref: any = '';


  tarifjour: any = '';


 tarifnuit: any = '';


tarifweekend: any = '';


tarifmin: any = '';


flagsamedi: any = '';


flagdimanche: any = '';


priseenncharge: any = '';

typetemplate: any = '';


datedebut: any = '';

  datefin: any = '';
    heuredebutjour: any = '';


      heuredebutnuit: any = '';


      collectivite : any = '';
      prestataire : any = '';

      @ViewChild('frmtemplateprix') frmtemplateprix: FrmtemplateprixComponent;





  constructor(private http: HttpClient, private templateprixService: TemplatePrixService,
    private structureService: StructureService,
    private typetemplateService: TypetemplateService) { }

  ngOnInit(): void {
    this.show();
    this.showtype();
    this.showcollectivites();
    this.showprestataire();

  }

  openForm(id: any) {
   // this.frmtemplateprix.edit(id);
  }

  show(): void {
     this.templateprixService.getData().subscribe(data => {
       this.data = data;
       this.template = null;
    });

  }

  showtype(): void {
     this.typetemplateService.getData().subscribe(data => {
       this.types = data;

    });

  }

  showcollectivites(): void {
     this.structureService.getStructuresByType(TypeStructureModel.COLLECTIVITE).subscribe(data => {
       this.collectivites = data;

    });

  }

  showprestataire(): void {
    this.structureService.getStructuresByType(TypeStructureModel.PRESTATAIRE).subscribe(data => {
      this.prestataires = data;

   });

 }

 save(): void {
  const headers = { };

  const body = {  delaiannualation: this.delaiannualation, delaiminim: this.delaiminim,
    pointref: this.pointref, tarifjour: this.tarifjour,
   tarifnuit: this.tarifnuit, tarifweekend: this.tarifweekend,
   tarifmin: this.tarifmin, priseenncharge: this.priseenncharge,
    datedebut: this.datedebut, datefin: this.datefin, heuredebutjour: this.heuredebutjour,
    heuredebutnuit: this.heuredebutnuit, flagapproche: this.flagapproche ,
    flagretour: this.flagretour, flagsamedi: this.flagsamedi,
    typetemplate: {id: this.typetemplate}, collectivite: {id: this.collectivite},
    prestataire: {id: this.prestataire},
   flagdimanche: this.flagdimanche,  actif: true };
  console.log(this.template);
  if (!this.template) {
    this.templateprixService.save(headers, body).subscribe(data => {
    //this.show();
   // $('#modalTemplatePrix').modal('hide');
    });
  } else {
    this.templateprixService.update(headers, body, this.template.id).subscribe(data => {
    // this.show();
    // $('#modalTemplatePrix').modal('hide');
    });
  }

}


edit(id: any): void {
 // alert("ok");
  this.templateprixService.get(id).subscribe(data => {
    this.template = data;
    this.tarifnuit = this.template.tarifnuit;
    this.tarifjour = this.template.tarifjour;
    this.delaiannualation = this.template.delaiannualation;
    this.delaiminim = this.template.delaiminim;
    this.pointref = this.template.pointref;
    this.tarifweekend = this.template.tarifweekend;
    this.tarifmin = this.template.tarifmin;
    this.priseenncharge = this.template.priseenncharge;
    this.datedebut = this.template.datedebut;
    this.datefin = this.template.datefin;
    this.heuredebutjour = this.template.heuredebutjour;
    this.heuredebutnuit = this.template.heuredebutnuit;
    this.flagapproche = this.template.flagapproche;
    this.flagretour = this.template.flagretour;
    this.flagsamedi = this.template.flagsamedi;
    this.flagdimanche = this.template.flagdimanche;
    this.flagsamedi = this.template.flagsamedi;
    this.typetemplate = this.template.typetemplate;
    this.prestataire = this.template.prestataire;
    this.collectivite = this.template.collectivite;
   // $('#modalTemplatePrix').modal('show');
  });

}



  delete(id: any): void {
    if (confirm('Voulez vraiment supprimer cet élément ? ' )) {
      this.templateprixService.delete(id).subscribe(data => {
        this.show();
        
      });
    }
  }


}
