<div class="page-content">
    <div class="page-header border-0">
        <h1 class="page-title text-primary-d2 text-160">
            Collaborateurs
            <small class="page-info text-secondary-d3">
                <i class="fa fa-angle-double-right text-80"></i>
                Liste des collaborateurs
            </small>
        </h1>

    </div>


    <div class="px-2">


        <div class="tab-pane fade text-95 active show" id="home2" role="tabpanel">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button *ngIf="this.canCreate" type="button"
                                    (click)="openFormActeur('COLLABORATEUR','TAD')"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>

                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card w-100 mt-1 mb-1">
                            <div class="card-body p-0">
                                <div class="accordion">
                                    <div class="card border-0 mt-1px bgc-primary-l5">
                                        <div class="card-header border-0 bgc-transparent mb-0">
                                            <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                                <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                    href="#rechercheAvancee" data-toggle="collapse"
                                                    aria-expanded="false" aria-controls="rechercheAvancee">
                                                    Recherche avancée

                                                    <span
                                                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                        <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                    </span>
                                                    <span
                                                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                        <i
                                                            class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                    </span>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="rechercheAvancee" class="collapse" aria-labelledby="headingTwo4"
                                            data-parent="#rechercheAvancee" style="" (keyup.enter)="rechercheAvance()">
                                            <div
                                                class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                                <div class="form-group row">
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                          <div class="col-sm-5 col-form-label text-sm-right pr-2 " >
                                                            <label class="mb-0">
                                                              Unités
                                                            </label>
                                                          </div>
                          
                                                          <div class="col-sm-7 pl-2" (keyup.enter)="rechercheAvance()" >
                                                            <select #searchFoyer (change)="searchFoyer.blur(); srch.focus()"  class="form-control"
                                                             [(ngModel)]="idfoyer" name="idfoyer" >
                                                              <option value="0">&nbsp;</option>
                                                                <option *ngFor="let foyer of foyers;" value="{{foyer.id}}">{{foyer.nom}}</option>
                                                            </select>
                                                            <input #srch style="height: 0px; width: 0px; padding: 0px; border: none;"
                                                                      type="text"/>
                                                            <button type="button" class="btn btn-sm btn-secondary"
                                                                          (click)='reset6()'>
                                                                          Effacer
                                                                      </button>
                                                          </div>
                                                        </div>
                          
                                                      </div>
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Prénom/nom
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input [(ngModel)]="prenom" name="prenom" type="text"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                                          (click)='reset1()'>
                                                                          Effacer
                                                                      </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                 <!--                 <div class="col-md-4">

                                                        <div class="form-group row">
                                                            <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Nom
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-8 pl-2">
                                                                <input [(ngModel)]="nom" name="nom" type="text"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                                          (click)='reset2()'>
                                                                          Effacer
                                                                      </button>
                                                            </div>
                                                        </div>

                                                    </div>  -->

                                                   


                                                </div>


                                                <div class="form-group row">

                                                    <!--  <div class="col-md-4">

                                                        <div class="form-group row">
                                                            <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Adresse
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-8 pl-2">
                                                                <input [(ngModel)]="adresse" name="adresse" type="text"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                                          (click)='reset4()'>
                                                                          Effacer
                                                                      </button>
                                                            </div>
                                                        </div>

                                                    </div> -->
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Email
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input [(ngModel)]="email" name="email" type="text"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                                          (click)='reset3()'>
                                                                          Effacer
                                                                      </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Téléphone
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input [(ngModel)]="telephone" name="telephone"
                                                                    type="text" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                                          (click)='reset5()'>
                                                                          Effacer
                                                                      </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    

                                                </div>
                                                <div class="form-group row">

                                                   
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                  
                                                                </label>
                                                            </div>

                                                            <div class="mb-2 mb-sm-0">
                                                                <button (click)="rechercheAvance()" type="button"
                                                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                    <i class="fa fa-search mr-1"></i>
        
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                  
                                                   

                                                </div>


                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>
                                    <th class="text-center pr-0">

                                    </th>
                                    <th>
                                        TAD
                                    </th>
                                    <th>
                                        Nom
                                    </th>

                                    <th>
                                        Prénom
                                    </th>

                                    <th>
                                        Email
                                    </th>
                                    <th>
                                        Fonction
                                    </th>



                                    <th class="d-none d-sm-table-cell">
                                        Téléphone Fixe
                                    </th>
                                    <th class="d-none d-sm-table-cell">
                                        Téléphone Portable
                                    </th>
                                    <th class="d-none d-sm-table-cell">

                                    </th>

                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="mt-1">
                                <ng-container *ngFor="let col of data| paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count};  let i = index;" [class.active]="i == currentIndex">
                                    <tr class="bgc-h-yellow-l4 d-style">
                                        <td class="text-center pr-0 pos-rel">
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                                <!-- border shown on hover -->
                                            </div>
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                                <!-- border shown when row is selected -->
                                            </div>
                                        </td>
                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{(!!col.structure) ? col.structure.nom:''}}
                                        </td>
                                        <td>
                                            <a class="text-blue-d1 text-600 text-95">
                                                {{(!!col.personne) ? col.personne.nom:''}}</a>
                                        </td>

                                        <td class="text-600 text-orange-d2">
                                            {{(!!col.personne) ? col.personne.prenom:''}}
                                        </td>

                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{ (!!col.personne) ? col.personne.email:''}}
                                        </td>
                                        <td class="text-dark-m3">
                                            {{(!!col.fonction) ? col.fonction.libelle:''}}
                                        </td>


                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{(!!col.personne) ? col.personne.telephone1:''}}
                                        </td>
                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{(!!col.personne) ? col.personne.telephone2:''}}
                                        </td>
                                        <td class="text-center pr-0">
                                            <div>
                                                <a href="#" data-toggle="collapse"
                                                    [attr.data-target]="'#detail-col-'+col.id"
                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                    title="Show Details" aria-expanded="false">
                                                    <span class="d-none d-md-inline mr-1">
                                                        Details
                                                    </span>
                                                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <!-- action buttons -->
                                            <div class="d-none d-lg-flex">
                                                <a href="#" *ngIf="this.canEdit" (click)="edit(col.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                                                    <i class="fa fa-pencil-alt"></i>
                                                </a>

                                                <a href="#" *ngIf="this.canDelete" (click)="delete(col.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>

                                            </div>

                                            <!-- show a dropdown in mobile -->
                                            <div
                                                class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                <a href="#"
                                                    class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    <i class="fa fa-cog"></i>
                                                </a>

                                                <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                    <div class="dropdown-inner">
                                                        <div
                                                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">

                                                        </div>
                                                        <a href="#" (click)="edit(col.id)" class="dropdown-item">
                                                            <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                                            Modifier
                                                        </a>
                                                        <a href="#" (click)="delete(col.id)" class="dropdown-item">
                                                            <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                            Supprimer
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                            Autre
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse" id="{{'detail-col-'+col.id}}">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div
                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                            <div class="bgc-white radius-1">
                                                                <table class="table table table-striped-default table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-info text-success"></i>
                                                                            </td>

                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                Information complémentaire
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{(!!col.personne) ? col.personne.information:''}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-map-marker text-success"></i>
                                                                            </td>

                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                adresse
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{(!!col.personne) ? col.personne.adresse:''}}
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{data.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>
        </div>
    </div>

</div>


<app-frmcollectivitecollaborateur #frmcollaborateur></app-frmcollectivitecollaborateur>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
