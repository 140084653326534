<div class="modal fade modal-ds" #acteurModal id="modalAjoutChauffeur" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                Ajouter chauffeur
                </h5>

                <button type="button" class="close"  (click)="fermer()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="">
                    <div class="">

                        <form class="mt-lg-3 Needs-validation1" novalidate>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label
                                    text-sm-right pr-0">
                                    <label class="mb-0">
                                        chauffeur <label
                                            style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <select (change)="changeChauffeur()" class="form-control coll" [(ngModel)]="selectedchauffeur" name="selectedchauffeur">
                                        <option value="">&nbsp;</option>
                                        <option *ngFor="let chauf of
                                            chauffeurs;"
                                            value="{{chauf.id}}">{{chauf.personne.prenom +" "+chauf.personne.nom}}</option>
                                        </select>
                                </div>

                            </div>

                        </form>
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="fermer()">
                        Fermer
                    </button>

            </div>
        </div>
    </div>
</div>
