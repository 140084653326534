import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AppModule } from '../../app.module';
import { StructureService } from "../../services/structure.service";
import { VilleService } from "../../services/ville.service";
import { TypeStructureModel } from "../../shared/models/type-structure-model.model";
import { TemplatePrixService } from 'src/app/services/templatePrix.service';
import { ActeurService } from 'src/app/services/acteur.service';
import { TypetemplateService } from 'src/app/services/typetemplate.service';
import { FrmprestatairesComponent } from '../frmprestataires/frmprestataires.component';
import { DetailsprestatairesComponent } from '../detailsprestataires/detailsprestataires.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { SecurityService } from 'src/app/services/security.service';

import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
declare function stransAdresse(id: any);



@Component({
  selector: 'app-lstprestataires',
  templateUrl: './lstprestataires.component.html',
  styleUrls: ['./lstprestataires.component.css']
})
export class LstprestatairesComponent implements OnInit {

  @ViewChild('frmprestataire') frmprestataire: FrmprestatairesComponent;
  @ViewChild('detailprestataire') detailprestataire: DetailsprestatairesComponent;


  data: any = [];
  prestataire: any = null;
  collaborateurs: any = [];
  siret = null;
  nom = null;
  ville = null;
  adresse = null;
  telephone = null;
  email = null;
  datecreation = null;
  typestructure;
  responsable = null;
  collectivite;
  user: User = new User();
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canDetails = false;

  constructor(private http: HttpClient,
    private structureService: StructureService,
    private templateprixService: TemplatePrixService,
    private acteurService: ActeurService,
    private authenticationService: AuthenticationService,
    private villeService: VilleService,
    private typetemplateService: TypetemplateService,
    private securiteService: SecurityService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.show();
    this.getDroit();
  }

  openFormprestataire() {
    // alert("ok");
    this.frmprestataire.show(this);
  }

  edit(id: any) {
    this.frmprestataire.edit(this, id);
  }

  detail(id: any, prestacol: any): void {
    // alert(this.prestataire.nom);
    this.detailprestataire.detail(this, id, prestacol);

  }

  show(): void {
    this.spinner.show();
    if (this.user.structure == 0) {
      this.structureService.getStructuresByType(TypeStructureModel.PRESTATAIRE).subscribe(data => {
        this.data = data;
        this.prestataire = null;
        this.spinner.hide();
      });
    } else if (this.user.profilCode == "PF_PRESTATAIRE") {
      this.structureService.getCollectivite(this.user.structure).subscribe(data => {
        this.data = data;
        this.prestataire = null;
        this.spinner.hide();
      });
    } else if (this.user.profilCode == "PF_TAD") {
      this.structureService.getStructureRattacheetad(TypeStructureModel.PRESTATAIRE, this.user.structure).subscribe(data => {
        this.data = data;
        this.prestataire = null;
        this.spinner.hide();
      });
    } else if (this.user.profilCode == "PF_AGCOL") {
      this.structureService.getStructureRattacheetad(TypeStructureModel.PRESTATAIRE, this.user.structure).subscribe(data => {
        this.data = data;
        this.prestataire = null;
        this.spinner.hide();
      });
    } else {
      this.structureService.getFoyerPrestataire(TypeStructureModel.PRESTATAIRE, this.user.structure).subscribe(data => {
        this.data = data;
        this.prestataire = null;
        this.spinner.hide();
      });
    }


  }


  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.structureService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussi' });
          this.show();
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La Suppresion a échoué. Car cet élément a peut être été utilisé ailleurs" });
          }
        );
      }
    })
  }

  showData(): void {
    //  this.paginationService.fetchPosts()
    //.subscribe(
    //  res => {
    //    this.data = res;
    //    console.log(res);
    //  },
    //  err => {
    //    console.log(err);
    //  });
  }

  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }

  RechercheAvance() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.nom = this.nom ? this.nom : null;
    this.siret = this.siret ? this.siret : null;
    this.email = this.email ? this.email : null;
    this.adresse = this.adresse ? this.adresse : null;
    this.telephone = this.telephone ? this.telephone : null;
    this.responsable = this.responsable ? this.responsable : null;
    this.structureService.getRecherchePrestataire(this.nom, this.siret, this.email, this.adresse,
      this.telephone, this.responsable, TypeStructureModel.PRESTATAIRE, this.user.id).subscribe(data => {
        this.data = data;
        this.prestataire = null;
        this.spinner.hide();
      });
      this.tabSize(event);

  }

  getDroit(): void {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_PRESTATAIRE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_PRESTATAIRE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_EDIT_PRESTATAIRE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canUpdate = true;
      } else {
        this.canUpdate = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_DETAILS_PRESTATAIRE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDetails = true;
      } else {
        this.canDetails = false;
      }
    });

  }
  reset1(){
    this.siret = null;
   }
   reset2(){
    this.nom = null;
   }
   reset3(){
    this.email = null;
   }
   reset4(){
    this.adresse = null;
   }
   reset5(){
    this.responsable = null;
   }

   reset6(){
    this.telephone = null;
   }
}
