import { Component, OnInit } from '@angular/core';
import {SecurityService} from '../services/security.service';
import {User} from '../shared/session/user';
import {AuthenticationService} from '../shared/services/authentication.service';
declare var $: any;
declare function toggle();

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  appPages: any = [];
  user: User;


  constructor(private securityService: SecurityService, private authenticationService: AuthenticationService) {

    this.user =  this.authenticationService.getUser();
    this.getMenu();

  }

  ngOnInit(): void {
  }

  private getMenu() {
    if (!!this.user) {
      /* this.securityService.getMenu(this.user.profil.code).subscribe(data => {
        this.appPages = data;
        setTimeout(function () {
          toggle();


        }, 2000);

      });*/

      this.appPages = [
        { url: 'accueil',  icon: 'fa fa-tachometer-alt', title: 'Tableau de bord'},
        { url: 'dossiers',  icon: 'fa fa-cube', title: 'Souscriptions'},
        { url: 'accords',  icon: 'fa fa-check-double', title: 'Validations'},
        // { url: 'registre',  icon: 'fa fa-users', title: 'Adhérents'},

      ];
    }
  }



}
