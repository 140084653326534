<ul class="nav flex-column has-active-border active-on-top active-on-right">


 <!-- data-toggle="dropdown" role="button" dropdown-menu -->

  <li class="nav-item" *ngFor="let page of appPages">

    <a  [href]="(!!page.url) ? page.url : '#'"    class="nav-link {{(!!page.children)?'dropdown-toggle collapsed': ''}}" >
      <i class="nav-icon fa {{page.icon}}"></i>
      <span class="nav-text fadeable">
               	  <span>{{page.title}}</span>
                    </span>


    </a>

    <div class="hideable submenu collapse"  *ngIf="!!page.children">
      <ul class="submenu-inner">

        <li class="nav-item" *ngFor="let item of page.children" >

          <a href="{{item.url}}" class="nav-link">

                           <span class="nav-text">
                              <span>{{item.title}}</span>
                           </span>


          </a>


        </li>

      </ul>

    </div>

    <b class="sub-arrow"></b>

  </li>
<li class="nav-item" style="padding-bottom: 3rem!important;"> </li>


</ul>



