import { Component, OnInit } from '@angular/core';
import { ActeurService } from 'src/app/services/acteur.service';
import { HttpClient } from '@angular/common/http';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { StructureService } from 'src/app/services/structure.service';
declare  function  stransAdresse(id: any);
declare var $: any;

@Component({
  selector: 'app-frmaffecte',
  templateUrl: './frmaffecte.component.html',
  styleUrls: ['./frmaffecte.component.css']
})
export class FrmaffecteComponent implements OnInit {

  data: any = [];
  collaborateur: any = null;
  parent;
  selectedActeurs: any = [];
  structure;
  personne;
  typeacteur;
  typestructure;
  selecttad: any = null;
  budget: any = null;
  tads: any = [];

  constructor(private http: HttpClient, private acteurService: ActeurService, private structureService: StructureService) { }

  ngOnInit(): void {

  }
  openSelect(){
    this.show2();
  }
  show2(): void {
    //alert(this.user.structure)
    this.acteurService.getActeursnonaffecter(this.selecttad, TypeStructureModel.TAD).subscribe(data => {
      this.data = data;
      this.collaborateur = null;
    });

  }

  show(parent) {
    // alert();
    this.parent = parent;
    // alert(this.selecttad);
    if (this.selecttad != null) {
      $("#tadservice").attr("disabled", true);
      this.show2();
    }
    this.showTad();

    $('#modalAffecte').modal('show');
  }

  save() {

    console.log(this.selectedActeurs);


    for (const personne of this.selectedActeurs) {


      const headers = {};
      const body = { actif: 1, typeacteur: this.typeacteur, personne: { id: personne }, structure: { id: this.selecttad } }
      // alert(this.selectedActeurs)
      this.acteurService.save(headers, body).subscribe(data => {

      });

    }
    $('#modalAffecte').modal('hide');
    this.resetForm();
    this.parent.filter(null, this.structure);

  }
  resetForm() {
    this.selectedActeurs = [];
  }
  fermer() {
    this.resetForm();
    $('#modalAffecte').modal('hide');
  }

  oncpChange(e) {
    if (e.target.checked) {
      this.selectedActeurs.push(e.target.value);

    } else {
      let i = 0;
      this.selectedActeurs.forEach((selPens: any) => {
        if (selPens == e.target.value) {
          delete this.selectedActeurs[i];
          return;
        }
        i++;
      });
    }

  }

  showTad(): void {
    this.structureService.getStructuresByType(TypeStructureModel.TAD).subscribe(data => {
      this.tads = data;

    });

  }


}
