<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Activité
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Liste des activités
      </small>
    </h1>

  </div>


  <div class="px-2">

    <div class="row mt-3">
      <div class="col-12">
        <div class="card w-100 mt-1 mb-1">
          <div class="card-body p-0">
            <div class="accordion">
              <div class="card border-0 mt-1px bgc-primary-l5">
                <div class="card-header border-0 bgc-transparent mb-0">
                  <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                    <a aria-controls="rechercheAvancee" aria-expanded="false"
                       class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                       data-toggle="collapse" href="#rechercheAvancee">
                      Recherche avancée

                      <span
                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                            </span>
                      <span
                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                            </span>
                    </a>
                  </h2>
                </div>
                <div aria-labelledby="headingTwo4" class="collapse" data-parent="#rechercheAvancee"
                     id="rechercheAvancee" style="">
                  <div class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                    <div class="form-group row">

                      <div class="col-md-6">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Unités
                            </label>
                          </div>

                          <div (keyup.enter)="rechercheAvance()" class="col-sm-8 pl-2">
                            <select [(ngModel)]="idtad" class="form-control" name="idtad">
                              <option value="0">&nbsp;</option>
                              <option *ngFor="let tad of tads;" value="{{tad.id}}">{{tad.nom}}</option>
                            </select>
                          </div>
                        </div>

                      </div>


                      <div class="col-md-6">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Prestataire
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <select [(ngModel)]="idprestataire" class="form-control" name="idprestataire">
                              <option value="0">&nbsp;</option>
                              <option *ngFor="let presta of prestataires;"
                                      value="{{presta.id}}">{{presta.nom}}</option>
                            </select>
                          </div>
                        </div>

                      </div>


                    </div>


                    <div class="form-group row">

                      <div class="col-md-6">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Date
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <select [(ngModel)]="critereDaterer" class="form-control" name="critereDaterer"
                            >
                              <option value="1">Egal</option>
                              <option value="2">Inférieur</option>
                              <option value="3">Supérieur</option>
                              <option value="4">Compris entre</option>
                            </select>


                            <input [(ngModel)]="datecrer" class="form-control" id="datecrer" name="datecrer"
                                   type="date"/>
                            <button (click)='reset1()' class="btn btn-sm btn-secondary"
                                    type="button">
                              Effacer
                            </button>


                          </div>


                        </div>

                      </div>


                      <div class="col-md-6">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Passager
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <input [(ngModel)]="passager" class="form-control" name="passager" type="text">
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="form-group row">

                      <div class="col-md-6">

                        <div class="form-group row">
                          <div *ngIf="this.critereDaterer == '4'" class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Date Max
                            </label>
                          </div>

                          <div *ngIf="this.critereDaterer == '4'" class="col-sm-8 pl-2">
                            <input [(ngModel)]="dateMax" class="form-control" id="dateMax" name="dateMax"
                                   type="date"/>
                            <button (click)='reset2()' class="btn btn-sm btn-secondary"
                                    type="button">
                              Effacer
                            </button>
                          </div>


                        </div>

                      </div>
                      <div class="col-md-2">

                        <div class="form-group row">
                          <div class="col-sm-8 pl-2">
                            <button (click)="rechercheAvance()"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10"
                                    type="button">
                              <i class="fa fa-search mr-1"></i>

                            </button>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>

        <div class="card dcard">
          <div class="card-body px-1 px-md-3">

            <form autocomplete="off">
              <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                <h3 class="text-125 pl-1 mb-3 mb-sm-0 text-secondary-d4">

                </h3>

                <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                </div>
              </div>


              <table
                class="d-style d-none datatable w-100 table text-dark-m1 text-95 border-y-1 brc-black-tp11 collapsed dtr-table"
                id="datatable">
                <thead class="sticky-nav text-secondary-m1 text-uppercase text-85">

                <tr>
                  <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm"></th>

                  <th class="text-center pr-0">
                    N°
                  </th>
                  <th>
                    Créée par
                  </th>

                  <th>
                    Validée par
                  </th>


                  <th class="d-none d-sm-table-cell">
                    Unité
                  </th>


                  <th>
                    Passagers
                  </th>

                  <th> 
                    Adresses
                  </th>


                  <th class="d-none d-sm-table-cell">
                    Date course
                  </th>

                  <th class="d-none d-sm-table-cell">
                    Prix
                  </th>


                </tr>
                </thead>

                <tbody class="mt-1">
                <tr *ngFor="let reservation of reservations| paginate : {
                  itemsPerPage: tableSize,
                  currentPage: page,
                  totalItems: count}; let i = index" [class.active]="i == currentIndex" class="bgc-h-yellow-l4 d-style">
                  <td></td>
                  <td class="text-center pr-0 pos-rel">
                    <span>{{reservation.id}}</span>
                  </td>

                  <td class="d-none d-sm-table-cell text-grey text-95">
                    {{(!!reservation.createdBy) ? reservation.createdBy.acteur.personne.prenom + ' ' + reservation.createdBy.acteur.personne.nom : ''}}
                  </td>

                  <td class="d-none d-sm-table-cell text-grey text-95">
                    {{(!!reservation.validatedBy) ? reservation.validatedBy.acteur.personne.prenom + ' ' + reservation.createdBy.acteur.personne.nom : ''}}
                  </td>


                  <td class="d-none d-sm-table-cell text-grey-d1">
                    {{reservation.tad.nom}}
                  </td>


                  <td class="d-none d-sm-table-cell text-grey text-95 mouse-pointer">
                    <span *ngIf="reservation?.passagers?.length > 0"
                          class="text-info">{{reservation.passagers[0].acteur.personne.prenom}} {{reservation?.passagers[0].acteur.personne.nom}}</span>

                  </td>

                  <td class="d-none d-sm-table-cell text-grey text-95">
                    <ul class="list-unstyled text-dark-m3">
                      <li class="mb-3">
                        <i class="w-3 text-center fa fa-car text-info" title="Adresse de départ"></i>
                        <span>{{reservation.adresseDepart}}</span>
                      </li>

                      <li class="mb-3 text-110 text-color-orange">
                        <i class="w-3 text-center fa fa-flag text-orange" title="Adresse d'arrivée"></i>
                        <span>{{reservation.adresseArrivee}}</span>
                      </li>


                    </ul>
                  </td>


                  <td class="text-85 text-blue-d1">
                    {{reservation.reservationDate}}
                  </td>

                  <td class="text-85">
                    {{reservation.price| number: '1.0-2':'fr-FR'}} €
                  </td>


                </tr>


                </tbody>
              </table>


              <table
                class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden"
                id="simple-table">
                <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                <tr>
                  <th class="text-center pr-0">
                    N°
                  </th>
                  <th>
                    Créée par
                  </th>

                  <th width="15%">
                    Validée par
                  </th>


                  <th class="d-none d-sm-table-cell">
                    Unité
                  </th>


                  <th>
                    Passagers
                  </th>

                  <th>
                    Adresses
                  </th>

                  <th class="d-none d-sm-table-cell">
                    Prestataire
                  </th>

                  <th class="d-none d-sm-table-cell" width="15%">
                    Date course
                  </th>

                  <th class="d-none d-sm-table-cell">
                    Prix
                  </th>


                </tr>
                </thead>

                <tbody class="mt-1">
                <tr *ngFor="let reservation of reservations; let i = index;"
                    class="bgc-h-yellow-l4 d-style">
                  <td class="text-center pr-0 pos-rel">
                    <span>{{reservation.id}}</span>
                  </td>

                  <td class="d-none d-sm-table-cell text-grey text-95">
                    {{(!!reservation.createdBy) ? reservation.createdBy.acteur.personne.prenom + ' ' + reservation.createdBy.acteur.personne.nom : ''}}
                  </td>

                  <td class="d-none d-sm-table-cell text-grey text-95">
                    {{(!!reservation.validatedBy) ? reservation.validatedBy.acteur.personne.prenom + ' ' + reservation.createdBy.acteur.personne.nom : ''}}
                  </td>


                  <td class="d-none d-sm-table-cell text-grey-d1">
                    {{reservation.tad.nom}}
                  </td>


                  <td class="d-none d-sm-table-cell text-grey text-95">
                    <span *ngIf="reservation?.passagers?.length > 0"
                    >{{reservation.passagers[0].acteur.personne.prenom}} {{reservation?.passagers[0].acteur.personne.nom}}</span>

                  </td>

                  <td class="d-none d-sm-table-cell text-grey text-95">
                    <ul class="list-unstyled text-dark-m3">
                      <li class="mb-3">
                        <i class="w-3 text-center fa fa-car text-info" title="Adresse de départ"></i>
                        <span>{{reservation.adresseDepart}}</span>
                      </li>

                      <li class="mb-3 text-110 text-color-orange">
                        <i class="w-3 text-center fa fa-flag text-orange" title="Adresse d'arrivée"></i>
                        <span>{{reservation.adresseArrivee}}</span>
                      </li>


                    </ul>
                  </td>


                  <td class="d-none d-sm-table-cell text-grey-d1">
                    {{reservation.transporteur?.nom}}
                  </td>
                  <td class="text-85">
                    {{reservation.reservationDate}}
                  </td>

                  <td class="text-85">
                    {{reservation.price| number: '1.0-2':'fr-FR'}} €
                  </td>


                </tr>

                                    <!-- detail row -->
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse" id="table-detail-0">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>




                                </tbody>
                            </table>

                            <!-- table footer -->
                            <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                              <div class="text-nowrap align-self-center align-self-sm-start">
                                  Afficher 1 - {{ tableSize }} of {{reservations.length}}
                                  <select (change)="tableData($event)" class="custom-select">
                                  <option *ngFor="let size of tableSizesArr" [value]="size">
                                      Afficher {{ size }}
                                  </option>
                                </select>
                              </div>
                              <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                  <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next" (pageChange)="tabSize($event)">
                                  </pagination-controls>
                              </div>
                              <div>
                  
                              </div>
                          </div>
                        </form>

                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>



    </div>

</div>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
