<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Fontions avancées
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Simulation DQE
      </small>
    </h1>

  </div>


  <div class="px-2">

    <div class="card bcard h-auto" style="min-height: 300px;">
      <form autocomplete="off" class="border-t-3 brc-blue-m2">

        <div class="card w-100 mt-1 mb-1">
          <div class="card-body p-0">
            <div class="accordion">
              <div class="card border-0 mt-1px" style="overflow: visible;">
                <div class="card-header border-0 bgc-transparent mb-0">
                  <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                    <a aria-controls="rechercheAvancee" aria-expanded="false"
                       class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                       data-toggle="collapse" href="#rechercheAvancee">
                      Filtres

                      <span
                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                            </span>
                      <span
                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                            </span>
                    </a>
                  </h2>
                </div>
                <div aria-labelledby="headingTwo4" class="collapse show" data-parent="#rechercheAvancee"
                     id="rechercheAvancee" style="">
                  <div class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1">

                    <div class="form-group row">

                      <div class="col-md-4">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0 required">
                              Unité
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <select [(ngModel)]="tadSelected" class="form-control" name="tadSelected">
                              <option value="0"> - Sélectionner un TAD -</option>
                              <option *ngFor="let tad of tads" value="{{tad.id}}">{{tad.nom}}</option>
                            </select>
                          </div>
                        </div>

                      </div>

                      <div class="col-md-4">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0 required" >
                              Période du
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <div class="input-group date">
                              <input [(ngModel)]="startDate" class="form-control" id="startDate"
                                     name="startDate"
                                     type="text"/>
                              <div class="input-group-addon input-group-append">
                                <div class="input-group-text">
                                  <i class="far fa-clock"></i>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="col-md-4">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0 required">
                              au
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <div class="input-group date">
                              <input [(ngModel)]="endDate" class="form-control" id="endDate"
                                     name="endDate"
                                     type="text"/>
                              <div class="input-group-addon input-group-append">
                                <div class="input-group-text">
                                  <i class="far fa-clock"></i>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>


                    </div>


                    <div class="form-group row">

                      <div class="col-md-4">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0 required">
                              Template 1
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <select (change)="setTemplate1()" [(ngModel)]="template1" class="form-control"
                                    name="template1">
                              <option value="0"> - Sélectionner un template -</option>
                              <option *ngFor="let temp of templatesPrix" value="{{temp.id}}">{{temp.libelle}}</option>
                            </select>
                          </div>
                        </div>

                      </div>

                      <div class="col-md-4" >

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Template 2
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <select (change)="setTemplate2()" [(ngModel)]="template2" class="form-control"
                                    name="template2" placeholder="">
                              <option value="0"> - Sélectionner un template -</option>
                              <option *ngFor="let temp of templatesPrix" value="{{temp.id}}">{{temp.libelle}}</option>
                            </select>
                          </div>
                        </div>

                      </div>

                      <div class="col-md-2 text-right">
                        <button (click)="getReservations()"
                                class="btn btn-success px-3  text-95 radius-round border-2 brc-black-tp10"
                                type="button">
                          <i class="fa fa-check-circle mr-1"></i>
                          Simuler <span class="d-sm-none d-md-inline"></span>
                        </button>
                      </div>


                    </div>


                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>

        <div *ngIf="show" class="card-body pb-0 pt-1 text-dark-m1 border-l-3 brc-warning bgc-primary-l5 mt-5 mb-15">

          <div class="form-group row">

            <div class="col-md-12">

              <table class="table table table-striped-default table-borderless">
                <tbody>

                <tr>
                  <td class="text-95 text-600 text-secondary-d2">
                    Kilométrage totale :
                  </td>

                  <td class="text-dark-m3">
                    {{kmGlobal | number: '1.0-2': 'fr-FR' }} KM
                  </td>

                  <td class="text-95 text-600 text-secondary-d2">
                    Prix de réference total :
                  </td>

                  <td class="text-dark-m3">
                    {{prixReference | number: '1.0-2': 'fr-FR'}} €
                  </td>
                </tr>


                <tr>
                  <td class="text-95 text-600 text-secondary-d2">
                    Prix total Template 1 :
                  </td>

                  <td class="text-blue-d1 text-wrap">
                    {{prixReference1 | number: '1.0-2': 'fr-FR'}} €
                  </td>

                  <td class="text-95 text-600 text-secondary-d2" [class.hide]="!this.templatePrix2">
                    Prix total template 2 :
                  </td>

                  <td class="text-blue-d1 text-wrap" [class.hide]="!this.templatePrix2">
                    {{prixReference2 | number: '1.0-2': 'fr-FR'}} €
                  </td>
                </tr>


                </tbody>
              </table>
            </div>


          </div>




        </div>


        <table *ngIf="show"
               class="d-style w-100 table text-dark-m1 text-95 border-y-1 brc-black-tp11 collapsed dtr-table"
               id="datatable">
          <thead class="sticky-nav text-secondary-m1 text-uppercase text-85">
          <tr>
            <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm"></th>

            <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
              Enfant
            </th>

            <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
              Départ
            </th>

            <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
              Arrivée
            </th>

            <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
              KM REF
            </th>

            <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
              Prix REF
            </th>

            <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
              KM Temp 1
            </th>

            <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
              Prix Temp 1
            </th>

            <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm" [class.hide]="!this.templatePrix2">
              KM Temp 2
            </th>

            <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm" [class.hide]="!this.templatePrix2">
              Prix Temp 2
            </th>


          </tr>

          </thead>

          <tbody class="pos-rel">

          <tr *ngFor="let reservation of reservations" class="d-style bgc-h-default-l4">
            <td class="d-none"></td>
            <td>
                        <span class="text-105">
                           <span *ngIf="reservation.passagers.length > 0"
                                 class="text-info">{{reservation.passagers[0].acteur.personne.prenom}} {{reservation.passagers[0].acteur.personne.nom}}</span>

                        </span>

            </td>

            <td class="text-grey">
              {{reservation.adresseDepart}}
            </td>

            <td class="text-grey">
              {{reservation.adresseArrivee}}
            </td>

            <td class="text-grey-d1">
              {{ (!!distances[reservation.id]) ? (distances[reservation.id][0] | number: '1.0-0':'fr-FR') : '0' }} KM
            </td>

            <td>
              {{ (!!prices[reservation.id]) ? (prices[reservation.id][0] | number: '1.0-2' : 'fr-FR') : '0' }} €
            </td>

            <td class="text-grey-d1">
              {{ (!!distances[reservation.id]) ? (distances[reservation.id][1] | number: '1.0-0':'fr-FR') : '0' }} KM
            </td>

            <td>
              {{ (!!prices[reservation.id]) ? (prices[reservation.id][1] | number: '1.0-2': 'fr-FR') : '0' }} €

            </td>

            <td class="text-grey-d1" [class.hide]="!this.templatePrix2">
              {{ (!!distances[reservation.id]) ? (distances[reservation.id][2] | number: '1.0-0':'fr-FR') : '0' }} KM
            </td>

            <td [class.hide]="!this.templatePrix2">
              {{ (!!prices[reservation.id]) ? (prices[reservation.id][2] | number: '1.0-2':'fr-FR') : '0' }} €

            </td>


          </tr>

          </tbody>
        </table>


      </form>
    </div>


  </div>

</div>




<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
