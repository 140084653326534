import {Component, OnInit, ViewChild} from '@angular/core';
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular';
import frLocale from '@fullcalendar/core/locales/fr';

import dayGridPlugin from '@fullcalendar/daygrid';

import {parse} from 'date-fns';
import {ReservationService} from "../../services/reservation.service";
import {FormFerieComponent} from "./form-ferie/form-ferie.component";
import {NgxSpinnerService} from "ngx-spinner";


@Component({
  selector: 'app-feries',
  templateUrl: './feries.component.html',
  styleUrls: ['./feries.component.css']
})
export class FeriesComponent implements OnInit {

  @ViewChild('mycal') calendar: FullCalendarComponent;
  @ViewChild('formFerieComponent') formFerieComponent: FormFerieComponent;


  calendarOptions: CalendarOptions;
  events: any = [];
  feries: any = [];
  feriesDates: any = [];


  constructor(private reservationService: ReservationService, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
   this.spinner.show();
    this.reservationService.getFeries().subscribe(feries => {
      // console.log(feries[0]);


      this.feries = feries;
      this.render();
      this.spinner.hide();
    });


  }


  render() {

    this.feries.forEach((ferie: any) => {
      for (const date in ferie) {
        const lbl = ferie[date];
        console.log(date);
        console.log(lbl);
        const dateFerie = parse(date, 'yyyy-MM-dd', new Date());
        // tslint:disable-next-line:max-line-length
        const dateFormat: string = dateFerie.getFullYear() + '-' + ('0' + (dateFerie.getMonth() + 1)).slice(-2) + '-' + ('0' + dateFerie.getDate()).slice(-2);
        // alert(newDate + ' ' + reservartion.reservationDate );
        this.events.push({
          title: lbl,
          date: dateFormat,
          /*block: true,
          display: 'background',
          backgroundColor: '#e12828',*/


        });
      }
    });


    // this.events.push({title: 'Férie', date: '2021-08-15'});

    this.calendarOptions = {
      initialView: 'listWeek',
      events: this.events,
      displayEventTime: false,
      locale: 'fr',
      buttonText: {
        today: 'Aujourd\'hui',
      },
      dateClick: this.handleDateClick.bind(this),

  };

  }

  handleDateClick(info) {

    this.formFerieComponent.show(info.dateStr, this);

  }

  refresh() {
    this.calendar.getApi().removeAllEvents();

    this.feries.forEach((ferie: any) => {
      for (const date in ferie) {
        const lbl = ferie[date];
        console.log(date);
        console.log(lbl);
        const dateFerie = parse(date, 'yyyy-MM-dd', new Date());
        // tslint:disable-next-line:max-line-length
        const dateFormat: string = dateFerie.getFullYear() + '-' + ('0' + (dateFerie.getMonth() + 1)).slice(-2) + '-' + ('0' + dateFerie.getDate()).slice(-2);
        // alert(newDate + ' ' + reservartion.reservationDate );


        this.calendar.getApi().addEvent({
          title: lbl,
          date: dateFormat,
          /*block: true,
          display: 'background',
          backgroundColor: '#e12828',*/

        });
      }
    });

  }


}
