<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Sécurité
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Gestion des profils
      </small>
    </h1>

  </div>


  <div class="px-2">


    <div class="">
      <div class="card-body px-1 px-md-3">

        <div class="row security-blokcs">
          <div class="col-md-4 p-0 pr-5">

            <div class="card ccard radius-t-0 h-100">
              <div class="position-tl w-102 border-t-3 brc-primary-tp3 ml-n1px mt-n1px"></div><!-- the blue line on top -->

              <div class="card-header brc-secondary-l3 pb-3">
                <h5 class="card-title mb-2 mb-md-0 text-dark-m3">
                  Profils
                  <span class="text-sm">

                    </span>
                </h5>

                <div class="card-toolbar no-border pl-0 pl-md-2">
                  <a href="#" class="btn btn-sm btn-lighter-grey btn-bgc-white btn-h-light-orange btn-a-light-orange text-600 px-25 radius-round">

                    <i class="fa fa-arrow-right ml-2 text-90"></i>
                  </a>
                </div>
              </div>

              <div class="card-body bgc-white p-0 pb-15">
                <form autocomplete="off" id="tasks" class="mt-n1px">

                  <div *ngFor="let pf of profils" class="task-item d-flex align-items-center border-t-1 bgc-h-green-l3 brc-secondary-l3 px-2">


                    <label class="flex-grow-1 mr-3 py-3" >
                      <input (change)="selectProfil(pf)" name="profil" type="radio" >

                      <span class="align-middle">
                                {{pf.name}}
                            </span>
                    </label>



                  </div>
                </form>
              </div><!-- /.card-body -->
            </div>

          </div>



          <div class="col-md-4 p-0 pr-5">

            <div class="card ccard radius-t-0 h-100">
              <div class="position-tl w-102 border-t-3 brc-primary-tp3 ml-n1px mt-n1px"></div><!-- the blue line on top -->

              <div class="card-header brc-secondary-l3 pb-3">
                <h5 class="card-title mb-2 mb-md-0 text-dark-m3">
                  Modules
                  <span class="text-sm">

                    </span>
                </h5>

                <div class="card-toolbar no-border pl-0 pl-md-2">
                  <a href="#" class="btn btn-sm btn-lighter-grey btn-bgc-white btn-h-light-orange btn-a-light-orange text-600 px-25 radius-round">

                    <i class="fa fa-arrow-right ml-2 text-90"></i>
                  </a>
                </div>
              </div>

              <div class="card-body bgc-white p-0 pb-15">
                <form autocomplete="off" class="mt-n1px">

                  <div *ngFor="let mod of modules" class="task-item d-flex align-items-center border-t-1 bgc-h-green-l3 brc-secondary-l3 px-2">


                    <label class="flex-grow-1 mr-3 py-3" >
                      <input  (change)="selectModule(mod)" type="radio"  name="module">

                      <span class="align-middle">
                                {{mod.name}}
                            </span>
                    </label>



                  </div>
                </form>
              </div><!-- /.card-body -->
            </div>

          </div>


          <div class="col-md-4 p-0 pr-5">

            <div class="card ccard radius-t-0 h-100">
              <div class="position-tl w-102 border-t-3 brc-primary-tp3 ml-n1px mt-n1px"></div><!-- the blue line on top -->

              <div class="card-header brc-secondary-l3 pb-3">
                <h5 class="card-title mb-2 mb-md-0 text-dark-m3">
                  Fonctionnalités
                  <span class="text-sm">

                    </span>
                </h5>

                <div class="card-toolbar no-border pl-0 pl-md-2">
                  <a href="#" class="btn btn-sm btn-lighter-grey btn-bgc-white btn-h-light-orange btn-a-light-orange text-600 px-25 radius-round">

                    <i class="fa fa-arrow-right ml-2 text-90"></i>
                  </a>
                </div>
              </div>

              <div class="card-body bgc-white p-0 pb-15">
                <form autocomplete="off" class="mt-n1px">

                  <ng-container *ngFor="let feat of features">
                  <div  class="task-item d-flex align-items-center border-t-1 bgc-h-green-l3 brc-secondary-l3 px-2">

                    <label class="flex-grow-1 mr-3 py-3" >
                      <input  type="checkbox"  [checked]="tabFeature[feat.feature.code]" name="feature" (change)="onFeatureChange($event, feat)" >

                      <span class="align-middle">
                                {{feat.feature.name}}
                            </span>
                    </label>
                  </div>

                  <div *ngFor="let act of feat.actions" class="pl-45 task-item d-flex align-items-center border-t-1 bgc-h-green-l3 brc-secondary-l3 px-2">
                    <label class="flex-grow-1 mr-3 py-3" >
                      <input   type="checkbox" [checked]="tabAction[act.code]"  name="action" [value]="act.code" (change)="onActionChange($event, act)" >

                      <span class="align-middle">
                                {{act.name}}
                            </span>
                    </label>
                  </div>
                  </ng-container>
                </form>
              </div><!-- /.card-body -->
            </div>

          </div>
        </div>
      </div><!-- /.card-body -->
    </div>
  </div>

</div>
