import {Component, OnInit, ViewChild} from '@angular/core';
import {AlerteService} from '../../services/alerts.service';
import Swal from 'sweetalert2';
import {FrmTemplateAlertComponent} from '../frm-template-alert/frm-template-alert.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {SecurityService} from "../../services/security.service";

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {

  templates: any = [];

  @ViewChild('frmTemplateAlertComponent') frmTemplateAlertComponent: FrmTemplateAlertComponent;

  constructor(private alerteService: AlerteService, private spinner: NgxSpinnerService, private securityService: SecurityService) {

  }

  ngOnInit(): void {

    this.getTemplates();
  }

  getTemplates() {
    this.spinner.show();

    this.alerteService.getTemplates().subscribe(templates => {
      this.templates = templates;
      this.spinner.hide();

    });
  }

  delete(id: any): void {


    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
         this.alerteService.deleteTemplate(id).subscribe(data => {
             Swal.fire({icon: 'success', text: 'Suppression réussie'});
             this.getTemplates();
           },
           err => {
             Swal.fire({
               icon: 'error',
               text: 'La suppression a échoué, car ce motif  a déjà été utilisé pour une réservation'
             });
           }
         );
      }
    });
  }


  edit(template: any) {
    this.frmTemplateAlertComponent.show(template, this);
  }


  create() {
    this.frmTemplateAlertComponent.show(null, this);
  }


}
