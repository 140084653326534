import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';
import { TypeActeurModel } from '../shared/models/type-acteur-model.model';
import { Observable } from 'rxjs';
import { ActeurModel } from '../shared/models/acteur-model.model';

@Injectable({
  providedIn: 'root'
})
export class PensionnaireService {

  constructor(private http: HttpClient) { }
  getData(typeacteur: TypeActeurModel): Observable<ActeurModel[]> {
    return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/list/'+ typeacteur);
 }

  get(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/get/' + id);
  }

  save(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'acteur/create', body, { headers });
  }

  update(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + 'acteur/update/' + id, body, { headers });
  }


  delete(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + 'acteur/delete/' + id);
  }

  getPensionnaires() {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/list/' + TypeActeurModel.PENSIONNAIRE);
  }

  getPensionnairesBis(structure:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/list/'+'structure' +'/'+ TypeActeurModel.PENSIONNAIRE);
  }
}
