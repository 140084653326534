import {Component, OnInit} from '@angular/core';
import {AlerteService} from "../../services/alerts.service";
import {NgxSpinnerService} from "ngx-spinner";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {User} from "../../shared/session/user";

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  alerts: any = [];
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  user: User;

  constructor(private alerteService: AlerteService, private spinner: NgxSpinnerService, 
    private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();

    this.spinner.show();
    this.alerteService.getAlertsByUser(this.user.id).subscribe(alerts => {
      this.alerts = alerts;
      this.spinner.hide();
    });
  }


  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }
}
