import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PassagerService} from "../../services/passager.service";
import {Tool} from "../../shared/tool";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {ReservationService} from "../../services/reservation.service";
import {ContactpassagerService} from "../../services/contactpassager.service";
import {TraiterComponent} from "../traiter/traiter.component";
import {parse} from "date-fns";
import { PfdViewerComponent } from '../note/pfd-viewer/pfd-viewer.component';

declare function getDistance(depart: any, arrivee: any);


@Component({
  selector: 'app-detailsreservation',
  templateUrl: './detailsreservation.component.html',
  styleUrls: ['./detailsreservation.component.css']
})
export class DetailsreservationComponent implements OnInit {

  @ViewChild('frmTaiter') frmTaiter: TraiterComponent;
  @ViewChild('pdfViewerComponent') pfdViewerComponent: PfdViewerComponent;

  passagers: any = [];
  contacts: any = [];
  reservation: any;
  mainContact: any;
  progression: any = 5;
  notes: any = [];

  price: any;
  pricePec: any;
  tarif: any;
  total: any;
  tarifApplique: any;
  jourReservation: any;
  heureReservation: any;
  distsRefs: any = [];
  temp;
  distApproche = 0;
  distRetour = 0;
  feriesDates: any = [];
  tabFoyer = {};


  private routeSub: Subscription;


  constructor(private http: HttpClient, private contactpassagerService: ContactpassagerService, private reservationService: ReservationService, private passagerService: PassagerService, public tool: Tool, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.getDayFeries();
    this.routeSub = this.route.params.subscribe(params => {
      console.log(params);
      if(!!params && !!params.id) {
        this.reservationService.get(params.id).subscribe(data => {
          this.reservation = data;
          // console.log('reservation -->', this.reservation);
            this.show();
        });
      }
    });
  }

  showDetails(reservationId) {

    this.reservationService.get(reservationId).subscribe(data => {
      this.reservation = data;
      this.show();

    });

  }

  getPassagers() {
    this.passagerService.getPassagers(this.reservation.id).subscribe(data => {
      this.passagers = data;
      this.passagers.forEach(psg => {
        //   alert(psg.acteur.personne.nom);
           this.passagerService.getFoyerPassager(psg.acteur.personne.id).subscribe(data => {
         //    alert(data);
             this.tabFoyer[psg.id] = data;
           });
         });

    });
  }

  getContacts() {
    this.contactpassagerService.getContacts(this.reservation.id).subscribe(data => {
      this.contacts = data;
      if(!!data) {
        this.mainContact = data[0];
      }
    });
  }

  traiter() {
      this.frmTaiter.traiter(this.reservation);
  }

  getProgression(code: any) {
    let progression = 0;
    switch (code) {

      case 'EAVAL':
        progression = 20;
        break;
      case 'EAVALTRANS':
        progression = 50;
        break;
      case 'VAL':
        progression = 75;
        break;
      case 'TER':
        progression = 100;
        break;
      default:
        break;

    }

    return progression;
  }

  private getNotes() {

    this.reservationService.getNotesBis(this.reservation.id).subscribe(data => {
      this.notes = data;
    });

  }

  getPrice(template) {

    console.log(this.distsRefs);
    let price = 0;
    const isFerie = this.checkFerie(this.reservation.reservationDate);
    console.log('Ferie = ' + isFerie);

    console.log(template);
    let distance = this.reservation.distance;
    console.log('reservation distance ' + distance);

    // tslint:disable-next-line:max-line-length
    const dateReservation = parse(this.reservation.reservationDate, 'd-MM-yyyy HH:mm', new Date());
    const reservationTime: string = dateReservation.getHours() + ':' + dateReservation.getMinutes() + ':' + '00';

    const heuredebutjour = parse(template.heuredebutjour, 'HH:mm:ss', new Date());
    heuredebutjour.setDate(dateReservation.getDate());
    heuredebutjour.setMonth(dateReservation.getMonth());
    heuredebutjour.setFullYear(dateReservation.getFullYear());

    const heuredebutnuit = parse(template.heuredebutnuit, 'HH:mm:ss', new Date());
    heuredebutnuit.setDate(dateReservation.getDate());
    heuredebutnuit.setMonth(dateReservation.getMonth());
    heuredebutnuit.setFullYear(dateReservation.getFullYear());

    const day: number = dateReservation.getDay();

    let tarif = template.tarifjour;
    let tarifs: any = [];

    if (isFerie && !!template.tarifferie) {
      tarifs.push(template.tarifferie);
    }

    if ((day === 0 && template.flagdimanche) || (day === 6 && template.flagsamedi)) {
      tarifs.push(template.tarifweekend);
    }

    if (dateReservation >= heuredebutjour && dateReservation < heuredebutnuit) {
      tarifs.push(template.tarifjour);
    } else {
      tarifs.push(template.tarifnuit);
    }

    console.log('tarifs ' + tarifs);

    tarif = tarifs.reduce((op, item) => op = op > item ? op : item, 0);
    this.tarifApplique = tarif;

    console.log('tarif appliqué  ' + tarif);


    let additionnalDistance = 0;

    if ((template.flagapproche || template.flagretour) && !!template.pointref && template.pointref !== '') {
      const distanceRef = this.distApproche
      console.log('Distance approche ' + distanceRef);

      const distRetour = this.distRetour
      console.log('Distance retour ' + distRetour);


      if (template.flagapproche) {
        additionnalDistance = (additionnalDistance) + (distanceRef);
        console.log('add distance  ' + additionnalDistance);
      }

      if (template.flagretour) {
        additionnalDistance = (additionnalDistance) + (distRetour);
        console.log('add distance  ' + additionnalDistance);
      }

    }

    distance += additionnalDistance;
    console.log(' distance  ' + distance);


    price = ((distance * tarif) > template.tarifmin) ? (distance * tarif) : template.tarifmin;


    let pricePec = 0;
    if (!!template.priseenncharge && template.priseenncharge > 0) {
      pricePec = (template.priseenncharge * (this.passagers.length));
    }


    price = (price + pricePec);
    return price;
  }


  getTarifApplique(template) {
    let tarif = 'Tarif';
    // tslint:disable-next-line:max-line-length
    const dateReservation = parse(this.reservation.reservationDate, 'd-MM-yyyy HH:mm', new Date());
    const reservationTime: string = dateReservation.getHours() + ':' + dateReservation.getMinutes() + ':' + '00';

    const day: number = dateReservation.getDay();

    // if (template.flagapproche && !!template.pointref && template.pointref !==  '' ) {

    // }

    if ((day === 0 && template.flagdimanche) || (day === 6 && template.flagsamedi)) {
      // tslint:disable-next-line:max-line-length
      tarif = 'Tarif Weekend (KM)';
    } else if (reservationTime >= template.heuredebutjour && reservationTime < template.heuredebutnuit) {
      // tslint:disable-next-line:max-line-length
      tarif = 'Tarif Jour (KM)';
    } else {
      // tslint:disable-next-line:max-line-length
      tarif = 'Tarif Nuit (KM)';

    }

    return tarif;
  }


  getCoutTarifApplique(template) {
    let tarif = 0;
    // tslint:disable-next-line:max-line-length
    const dateReservation = parse(this.reservation.reservationDate, 'd-MM-yyyy HH:mm', new Date());
    const reservationTime: string = dateReservation.getHours() + ':' + dateReservation.getMinutes() + ':' + '00';

    const day: number = dateReservation.getDay();

    // if (template.flagapproche && !!template.pointref && template.pointref !==  '' ) {

    // }
    if ((day === 0 && template.flagdimanche) || (day === 6 && template.flagsamedi)) {
      // tslint:disable-next-line:max-line-length
      tarif = template.tarifweekend;
    } else if (reservationTime >= template.heuredebutjour && reservationTime < template.heuredebutnuit) {
      // tslint:disable-next-line:max-line-length
      tarif = template.tarifjour;
    } else {
      // tslint:disable-next-line:max-line-length
      tarif = template.tarifnuit

    }

    return tarif;
  }

  getPricePriseEnCharge(template) {
    let price = 0;


    if (!!template.priseenncharge && template.priseenncharge > 0 && this.reservation.passagers.length > 1) {
      price = (template.priseenncharge * (this.reservation.passagers.length - 1));
    }

    return price;
  }


  async getDayFeries() {
    this.feriesDates = await this.reservationService.getDateFeries().toPromise();
  }

  checkFerie(date) {

    const dateReservation = parse(date, 'd-MM-yyyy HH:mm', new Date());
    const dateFormat: string = dateReservation.getFullYear() + '-' + ('0' + (dateReservation.getMonth() + 1)).slice(-2) + '-' + ('0' + dateReservation.getDate()).slice(-2);

    if (this.feriesDates.includes(dateFormat)) {
      return true;
    } else {
      return false;
    }


  }

  showPdf(url:any){
    this.pfdViewerComponent.show(url);
  }


   show() {

     if (!!this.reservation && !!this.reservation.reservationDate) {
       this.jourReservation = this.reservation.reservationDate.split(' ')[0];
       this.heureReservation = this.reservation.reservationDate.split(' ')[1];
     }
     this.progression = this.getProgression(this.reservation.statut.code);
     this.getPassagers();
     this.getContacts();
     this.getNotes();

     if (!!this.reservation.templatePrix) {
       this.temp = this.reservation.templatePrix;


       const p = getDistance(this.reservation.adresseDepart, this.temp.pointref);
       p.then(response => {
         this.distApproche = Math.round(response.routes[0].legs[0].distance.value / 1000);
       });

       const p2 = getDistance(this.reservation.adresseArrivee, this.temp.pointref);
       p2.then(response => {
         this.distRetour = Math.round(response.routes[0].legs[0].distance.value / 1000);
       });

       setTimeout(() => {
         this.total = this.getPrice(this.temp);
       }, 2000);


     }

  }
}
