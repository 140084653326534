import {Consulat} from "../classes/consulat";

export class User {
  id: number;
  login: string;
  name: string;
  password: string;
  profil: Profil = new Profil();
  acteur: Acteur = new Acteur();
  profilCode: string;
  profilName: string;
  token: string;
  structure: number;
  personne: number;
  parent: number;
  consulat: Consulat = new Consulat();
  firstname: any;
  lastname: any;
  expiry: any;
  issued: any;

}

export class Profil {
  code: string;

}

export class Acteur {
  id: string;

}
