<div aria-hidden="true" class="modal fade modal-ds " id="modalFormTemplate" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Modèle alerte
        </h5>

        <button aria-label="Close" class="close"  (click)="hide()" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="">
          <div class="">

            <form class="mt-lg-3">

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Libellé <label style="color:red">
                    *
                  </label>
                  </label>
                </div>

                <div class="col-sm-9">
                  <input [(ngModel)]="libelle" class="form-control col-sm-8 col-md-6" name="libelle" required
                         type="text">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Type <label style="color:red">
                    *
                  </label>
                  </label>
                </div>

                <div class="col-sm-9 pr-0 pr-sm-3 col-md-4">
                  <select [(ngModel)]="type" class="form-control" name="type"
                          style=" height: 100%;width:150%">
                    <option value="">&nbsp;- Sélectionnez un type -</option>
                    <option *ngFor="let typ of types" [value]="typ.id">{{typ.libelle}}</option>

                  </select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Priorité <label style="color:red">
                    *
                  </label>
                  </label>
                </div>

                <div class="col-sm-9 pr-0 pr-sm-3 col-md-4">
                  <select [(ngModel)]="priorite" class="form-control" name="priorite"
                          style=" height: 100%;width:150%">
                    <option value="">&nbsp;- Sélectionnez une priorité -</option>
                    <option *ngFor="let priority of priorites" [value]="priority">{{priority}}</option>

                  </select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Profils <label style="color:red">
                    *
                  </label>
                  </label>
                </div>

                <div class="col-sm-9 pr-0 pr-sm-3 col-md-4">
                  <select class="chosen-select form-control" id="profils" multiple="" name="selectedProfils">
                    <option selected="selected" value=""></option>
                    <option *ngFor="let profil of profils" value="{{profil.code}}" >{{profil.name}}</option>

                  </select>
                </div>
              </div>


              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Delai
                  </label>
                </div>

                <div class="col-sm-9">
                  <input [(ngModel)]="delai" class="form-control col-sm-8 col-md-4" name="delai"    type="text">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0 required">
                    Message
                  </label>
                </div>

                <div class="col-sm-9">
                  <textarea [(ngModel)]="message" class="form-control" name="message" placeholder=""
                            style="overflow-wrap: break-word; resize: both;" rows="5"></textarea>
                  <button class="btn px-4 btn-warning my-1"
                          data-content="[RESERVATION] = Numéro de la réservation <br/> [DATERESERVATION] = Date de la réservation <br/> [TAD] = Unité <br/> [PASSAGERS]  = Liste des passagers<br/>  [PASSAGER] = Premier passager <br/>  [DEPART] = Adresse de départ <br/>
                          [ARRIVEE] = Adresse d'arrivée<br/> [CREEPAR] = Utilisateur ayant créé la réservation, <br/> [VALIDEPAR] = Utilisateur ayant validé la réservation <br/> [STATUS] = Statut de la réservation <br/> [TELEPHONEPRESTATAIRE] = Les numéros de téléphone du prestataire"
                          data-placement="bottom" id="popover-2" title="Tags disponibles">
                    <i class="fa fa-question-circle"></i> Aide
                  </button>
                </div>
              </div>


            </form>


          </div><!-- /.card-body -->
        </div>


      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" (click)="hide()" type="button">
          Fermer
        </button>
        <button (click)="save()" class="btn btn-primary" type="button">
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(255,255,255,0)" color="#4AA0DF" fullScreen="false" size="medium" type="timer"><p
  style="color: #4AA0DF"> Chargement en cours... </p></ngx-spinner>
