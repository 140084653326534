import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {AppModule} from '../../app.module';
import {FonctionService} from "../../services/fonction.service";
import { User } from 'src/app/shared/session/user';
import { SecurityService } from 'src/app/services/security.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fonction',
  templateUrl: './fonction.component.html',
  styleUrls: ['./fonction.component.css']
})
export class FonctionComponent implements OnInit {

  data: any = [];
  fonction: any = null;

  code: any = '';
  libelle: any = '';
  description: any = '';
  user: User = new User();
  pagesize = 5;
  i;
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  constructor(private http: HttpClient, private fonctionService: FonctionService,
    private securiteService : SecurityService,private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.show();
    this.getDroit();
  }

  show(): void {
    this.spinner.show();
     this.fonctionService.getData().subscribe(data => {
       this.data = data;
       this.fonction = null;
       this.spinner.hide();
    });

  }

  save(): void {
    const headers = { };
    const body = { code: this.code, libelle: this.libelle , actif: true  };
    console.log(this.fonction);
    if (!this.fonction) {
      this.fonctionService.save(headers, body).subscribe(data => {
        this.show();
        $('#modalForm').modal('hide');
      });
    } else {
      this.fonctionService.update(headers, body, this.fonction.id).subscribe(data => {
        this.show();
        $('#modalForm').modal('hide');
      });
    }
    this.resetForm();
  }

  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.fonctionService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.show();
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cette fonction a déjà été utilisés ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ' )) {
    //   this.fonctionService.delete(id).subscribe(data => {
    //     this.show();
    //   });
    // }
  }


  edit(id: any): void {
    this.fonctionService.get(id).subscribe(data => {
      this.fonction = data;
      this.code = this.fonction.code;
      this.libelle = this.fonction.libelle;
      $('#modalForm').modal('show');
    });

  }

  tabSize(event){
    this.page = event;
   // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
   // this.showData();
  }

  getDroit():void{
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_FONCTION").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreate = true;
      }  else {
        this.canCreate = false;
      }
   });
  
  this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_FONCTION").subscribe(data => {
  console.log(this.data)
  if (!!data) {
    this.canDelete = true;
  }  else {
    this.canDelete = false;
  }
  });
  
  this.securiteService.getDroit(this.user.profilCode, "ACT_EDIT_FONCTION").subscribe(data => {
  console.log(this.data)
  if (!!data) {
    this.canUpdate = true;
  }  else {
    this.canUpdate = false;
  }
  });

  
   }

   resetForm(){
   
    this.code = null;
    this.libelle = null;
    this.description = null;
    
    
  
  }
}
