import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PensionnaireService} from '../../services/pensionnaire.service';
import {TypeActeurModel} from '../../shared/models/type-acteur-model.model';
import {Observable} from 'rxjs';
import {ActeurModel} from '../../shared/models/acteur-model.model';
import {PassagerService} from '../../services/passager.service';
import {FrmreservationComponent} from '../frmreservation/frmreservation.component';
import { ActeurService } from 'src/app/services/acteur.service';
import { SecurityService } from 'src/app/services/security.service';
import { User } from 'src/app/shared/session/user';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {NgxSpinnerService} from "ngx-spinner";

declare function initMap(suffix: any);
declare function createTimeField();

declare var $: any;
declare function showPoperError(message: any);

@Component({
  selector: 'app-frmpassager',
  templateUrl: './frmpassager.component.html',
  styleUrls: ['./frmpassager.component.css']
})
export class FrmpassagerComponent implements OnInit {
  hasMap: any = false;
  pensionnaires: any = [];
  selPensionnaires = [];
  reservation: any;
  frmReservation: FrmreservationComponent;
  adresseDepart: any;
  adresseArrivee: any;
  adresseDepartPassager: any;
  adresseArriveePassager: any;
  tad: any;
  selPensionnaire: any;
  user: User = new User();
  search;
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  ckadresse:any=0;
  memeadresse = true;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private acteurService: ActeurService, private pensionnaireService: PensionnaireService, private passagerService: PassagerService,
              private spinnerService: NgxSpinnerService) {
    // this.getPensionnaires();
  }

  ngOnInit(): void {

    this.user = this.authenticationService.getUser();
    createTimeField();
    $('#rbAdresse').hide();
    $('#ckboxDestination').change(function () {
      if (this.checked) {
        $('#mainTrajet').show();
        $('#map-frmpsg').hide();
        $('#rbAdresse').hide();
      } else {
        $('#mainTrajet').hide();
        $('#map-frmpsg').show();
        $('#rbAdresse').show();
        $("#origin-input-frmpsg").attr("disabled", true);
        $("#destination-input-frmpsg").attr("disabled", false);
        this.adresseDepartPassager = this.adresseDepart;
        this.adresseArriveePassager = null;
        if (!this.hasMap) {
          initMap('-frmpsg');
        }

        this.hasMap = true;
      }

    });

    let pacContainerInitialized = false;
    $('#origin-input-frmpsg').keypress(function() {
      if (!pacContainerInitialized) {
        $('.pac-container').css('z-index', '9999');
        pacContainerInitialized = true;
      }
    });

    let pacContainerInitialized2 = false;
    $('#destination-input-frmpsg').keypress(function() {
      if (!pacContainerInitialized2) {
        $('.pac-container').css('z-index', '9999');
        pacContainerInitialized2 = true;
      }
    });

  }

  ckAdresseChange(){
        this.adresseDepartPassager = this.adresseDepart;
        this.adresseArriveePassager = null;
    $('#rbDepart').change(function () {
      if (this.checked) {
        $("#origin-input-frmpsg").attr("disabled", true);
        $("#destination-input-frmpsg").attr("disabled", false);
      } else {
        $("#origin-input-frmpsg").attr("disabled", false);
        $("#destination-input-frmpsg").attr("disabled", true);
      }

    });
  }
  ckAdresseChangeArrivee(){
        this.adresseDepartPassager = null;
        this.adresseArriveePassager = this.adresseArrivee;
    $('#rbDestination').change(function () {
      if (this.checked) {
        $("#origin-input-frmpsg").attr("disabled", false);
        $("#destination-input-frmpsg").attr("disabled", true);
      } else {
        $("#origin-input-frmpsg").attr("disabled", true);
        $("#destination-input-frmpsg").attr("disabled", false);
      }

    });
  }
  ctcChange(ctc: any) {
    this.selPensionnaire = ctc;
  }

  reset(){
    this.selPensionnaires = null;
    this.adresseDepartPassager = null;
    this.adresseArriveePassager = null;
    this.search = null;
  }
  fermer(){
    this.reset();
    $('#modalFormPassager').modal('hide');
  }

  savePassenger() {
    if (this.selPensionnaire) {
      $('#origin-input-frmpsg')[0].dispatchEvent(new Event('input', {bubbles: true}));
      $('#destination-input-frmpsg')[0].dispatchEvent(new Event('input', {bubbles: true}));


      // console.log(this.selPensionnaires);

      //  for (const pensionnaire of this.selPensionnaires) {

      //console.log(pensionnaire);
      if (!!this.adresseDepartPassager && !!this.adresseArriveePassager && this.adresseDepartPassager === this.adresseArriveePassager) {
        showPoperError('L\'adresse de départ et celle d\'arrivée doivent être différentes');
        return false;
      }

      if (!!this.adresseDepartPassager) {
        this.adresseDepart = this.adresseDepartPassager;
      }
      if (!!this.adresseArriveePassager) {
        this.adresseArrivee = this.adresseArriveePassager;
      }


      this.spinnerService.show();


      const headers = {};
      const body = {
        actif: 1, acteur: {id: this.selPensionnaire.id},
        reservation: {id: this.reservation}, adresseDepart: this.adresseDepart,
        adresseArrivee: this.adresseArrivee
      };


      this.passagerService.save(headers, body).subscribe(data => {
        if (!this.tad) {
          this.acteurService.get(data.acteur.id).subscribe(act => {
           // console.log(act);
            // @ts-ignore
            this.frmReservation.tad = act.structure.id;
            this.frmReservation.save();
            this.frmReservation.refreshPassengers();
            this.spinnerService.hide();


          });
        } else {
          this.frmReservation.refreshPassengers();
          this.spinnerService.hide();

        }

      });

   // }
    this.fermer();

    }else{
      showPoperError('Veuillez sélectionner un enfant' );
    }


  }
  changesPensionnnaires(tad:any) {
    //  this.acteurService.getPensioonnairesReservation(this.user.id).subscribe(data => {
    //    this.pensionnaires = data;
    //  console.log(this.pensionnaires);

    // });
  }

  getPensionnaires() {
    this.spinnerService.show();

    /* if(this.user.profilCode == "PF_GFOYER"){
      this.acteurService.getPensionnaireFoyerTad(this.tad, this.user.structure).subscribe(data => {
        this.pensionnaires = data;
      //  console.log(this.pensionnaires);

      });
    }  else{ */

    let seachString = this.search;
    if (!this.search || this.search === '') {
      seachString = 'null';
    }

    if (!!this.tad) {
      this.acteurService.getPensionnairesByTadBis(this.tad, seachString, this.reservation).subscribe(data => {
        this.pensionnaires = data;
        this.spinnerService.hide();


      });
    } else {
      this.acteurService.getPensionnairesByUser(this.user.id, seachString).subscribe(data => {
        this.pensionnaires = data;
        this.spinnerService.hide();
      });
    }


  }

  oncpChange(e) {

    if (e.target.checked) {
      this.selPensionnaires.push(e.target.value);
    } else {
      let i = 0;
      this.selPensionnaires.forEach((selPens: any) => {
        if (selPens == e.target.value) {
          delete this.selPensionnaires[i];
          return;
        }
        i++;
      });
    }

  }

  show(reservation: any, parent: FrmreservationComponent, adresseDepart, adresseArrivee) {
    this.reservation = reservation;
    this.frmReservation = parent;
    this.selPensionnaire = null;
    this.adresseDepart = adresseDepart;
    this.adresseDepartPassager = adresseDepart;
    this.adresseArrivee = adresseArrivee;

    // alert(this.ckadresse);

    this.getPensionnaires();


    $('#mainTrajet').show();
    $('#map-frmpsg').hide();
    $('#rbAdresse').hide();
    $('#ckboxDestination').prop('checked', true);
    $('#rbDepart').prop('checked', true);

    $('#modalFormPassager').modal('show');


  }

  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.pensionnaires = this.pensionnaires;
    // this.showData();
  }

  getAge(dateNaissance) {

    var timeDiff = Math.abs(Date.now() - new Date(dateNaissance).getTime());
    var age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

    return age;
  }
}


