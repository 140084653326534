<div class="page-content">
    <div class="page-header border-0">
        <h1 class="page-title text-primary-d2 text-160">
            Dossiers
            <small class="page-info text-secondary-d3">
                <i class="fa fa-angle-double-right text-80"></i>
                En attente de prévalidation
            </small>
        </h1>

    </div>


    <div class="px-2">



        <div class="row mt-5 pt-lg-3">
            <div class="col-lg-4">
                <div class="card ccard radius-t-0 h-100">
                    <div class="position-tl w-102 border-t-3 brc-primary-tp3 ml-n1px mt-n1px"></div><!-- the blue line on top -->

                    <div class="card-header pb-3 brc-secondary-l3">
                        <h5 class="card-title mb-2 mb-md-0 text-dark-m3">
                            Dossiers
                        </h5>

                        <div class="card-toolbar no-border pl-0 pl-md-2">
                            <a href="#" class="btn btn-sm btn-lighter-grey btn-bgc-white btn-h-light-orange btn-a-light-orange text-600 px-2 py-1 radius-round">
                                <i class="fa fa-arrow-down text-90 mx-1px"></i>
                            </a>
                        </div>
                    </div>

                    <div class="card-body pt-2 pb-1" style="height: 500px; overflow: scroll">

                        <div role="button" (click)="showDetails(dossier)" *ngFor="let dossier of dossiers" [class.bgc-info]="!!demand && dossier.id==demand.id"  [class.text-light]="!!demand && dossier.id==demand.id" class="d-flex flex-wrap align-items-center my-2 bgc-secondary-l4 bgc-h-secondary-l3 radius-1 p-25 d-style">
                      <span class="mr-25 w-4 h-4 overflow-hidden text-center border-1 brc-secondary-m2 radius-round shadow-sm d-zoom-2">
                        <img  src="{{dossier.demandeur.img}}" class="h-4 w-4">
                    </span>

                            <span  class="text-90 text-600">
                        {{dossier.demandeur.prenom}} {{dossier.demandeur.nom }}
                    </span>

                            <span class="ml-auto  text-700 text-nowrap">
                        #{{dossier.id}}
                        <span class="text-80">
                            <img src="assets/img/{{dossier.demandeur.pays.code}}.png" height="25">
                        </span>
                      </span>

                            <span class="ml-2">
                    </span>
                        </div>


                    </div>
                </div>
            </div>

            <div class="col-lg-8" *ngIf="!!demand">

                <div class="card bcard border-1 brc-secondary-l2">
                    <ul class="nav nav-tabs nav-tabs-simple nav-tabs-scroll is-scrollable nav-tabs-static border-b-1 brc-default-l2 pt-2px" role="tablist">
                        <li class="nav-item mx-3">
                            <a class="btn btn-light-lightgrey bgc-white btn-brc-tp btn-h-light-dark btn-a-outline-green btn-a-text-dark py-25 px-15 border-none border-b-4 active" id="home18-tab-btn" data-toggle="tab" href="#home18" role="tab" aria-controls="home18" aria-selected="true">
                                Infos personnels
                            </a>
                        </li>

                        <li class="nav-item mr-3">
                            <a class="btn btn-light-lightgrey bgc-white btn-brc-tp btn-h-light-dark btn-a-outline-green btn-a-text-dark py-25 px-15 border-none border-b-4" id="profile18-tab-btn" data-toggle="tab" href="#profile18" role="tab" aria-controls="profile18" aria-selected="false">
                                Documents
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="btn btn-light-lightgrey bgc-white btn-brc-tp btn-h-light-dark btn-a-outline-green btn-a-text-dark py-25 px-15 border-none border-b-4" id="more18-tab-btn" data-toggle="tab" href="#more18" role="tab" aria-controls="more18" aria-selected="false">
                                Traitement
                            </a>
                        </li>

                        <li class="nav-item ml-auto mr-3 align-self-center">
                            <button  (click)="valid(demand)" class="ml-3 mr-2 btn  btn-sm btn-green radius-1 h-auto border-2 px-25 btn-brc-tp text-600">
                                <i class="far fa-check-circle opacity-2 mr-1"></i>
                                Pré-valider
                            </button>
                        </li>
                    </ul>

                    <div class="tab-content tab-sliding py-2 px-0 mx-md-0">
                        <div class="tab-pane show text-95 px-3 active" id="home18" role="tabpanel" aria-labelledby="home18-tab-btn">
                       <div class="row">
                            <div class="col-6">

                                <div class="mt-1 mb-2">
                                    <span class="badge badge-info ml-n1">Informations personelles</span>
                                </div>

                                <div>
                                    <table class="table table table-striped-default table-borderless">
                                        <tbody>

                                        <tr>
                                            <td class="text-95 text-600 text-secondary-d2">
                                                Numero d'identification
                                            </td>

                                            <td class="text-blue-d1 text-wrap">
                                                {{demand.demandeur.nin}}
                                            </td>
                                        </tr>


                                        <tr>

                                            <td class="text-95 text-600 text-secondary-d2">
                                                Nom complet
                                            </td>

                                            <td class="text-dark-m3">
                                                {{demand.demandeur.prenom}} {{demand.demandeur.nom}}
                                            </td>
                                        </tr>

                                        <tr>


                                            <td class="text-95 text-600 text-secondary-d2">
                                                Date de naissance
                                            </td>

                                            <td class="text-dark-m3">
                                                {{demand.demandeur.dateNaissance}}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="text-95 text-600 text-secondary-d2">
                                                Lieu de naissance
                                            </td>

                                            <td class="text-blue-d1 text-wrap">
                                                {{demand.demandeur.lieuNaissance}}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="text-95 text-600 text-secondary-d2">
                                                Pays de résidence
                                            </td>

                                            <td class="text-blue-d1 text-wrap">
                                                {{demand.demandeur.pays.libelle}}
                                            </td>
                                        </tr>




                                        </tbody>
                                    </table>
                                </div>


                                <div class="mt-1 mb-2">
                                    <span class="badge badge-info ml-n1">Famille</span>
                                </div>

                                <table class="table table table-striped-default table-borderless">
                                    <tbody>

                                    <tr *ngFor="let fam of famille">
                                        <td class="text-95 text-600 text-secondary-d2">
                                            <img  height="24"  src="{{fam.membre.img}}"/>
                                        </td>

                                        <td class="text-blue-d1 text-wrap">
                                            {{fam.membre.prenom}} {{fam.membre.nom}}
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                            Né(e) le  {{fam.membre.dateNaissance}}
                                        </td>



                                        <td class="text-blue-d1 text-wrap">
                                            <span class="badge badge-secondary">{{fam.parente}}</span>
                                        </td>
                                    </tr>

                                    </tbody>

                                </table>

                            </div>

                            <div class="col-6">

                                <div class="mt-1 mb-2">
                                    <span class="badge badge-info ml-n1">Photo</span>
                                </div>

                                <div>

                                    <img [src]="this.demand.demandeur.img" height="100px"
                                         alt="Photo" >


                                </div>




                            </div>
                       </div>

                        </div>

                        <div class="tab-pane text-95 px-3" id="profile18" role="tabpanel" aria-labelledby="profile18-tab-btn">
                            <div class="col-12  cards-container mt-3 mt-sm-0" id="card-container-6">
                                <div class="card dcard p-1 bgc-secondary-l3" id="card-6">
                                    <div class="card-body p-1 p-sm-2 p-lg-3">
                                        <div class="card" id="card-7">
                                            <div class="card-header">
                                                <h5 class="card-title text-120 text-default-d3">
                                                    Documents requis
                                                </h5>

                                                <div class="card-toolbar no-border  align-self-center">
                            <span class="badge badge-primary px-25">
							          2/3
						          </span>
                                                </div>

                                                <div class="card-toolbar">


                                                    <a href="#" data-action="toggle" class="card-toolbar-btn btn btn-sm radius-1 btn-light-default btn-brc-tp mx-2px">
                                                        <i class="fa fa-angle-up w-2 mx-1px"></i>
                                                    </a>


                                                </div>
                                            </div><!-- /.card-header -->

                                            <div class="card-body p-0 collapse show" style="">
                                                <div class="p-3">


                                                    <div class="row">


                                                        <div class="col-4 text-center px-2" *ngFor="let document of documents; let i = index;">
                                                            <div class="pos-rel d-style my-3 radius-1 shadow-sm overflow-hidden bgc-default-m3">


                                                               <img src="{{document.path}}"  height="200" />



                                                                <div class="mt-hover position-tl w-100 bgc-black-tp4 p-25">
                                                                    <div class="d-flex justify-content-center action-buttons text-110">
                                                                        <a (click)="viewDocument(document)" class="show-lightbox mx-2" >
                                                                            <i class="fa fa-search-plus text-blue-l3"></i>
                                                                        </a>

                                                                    </div>
                                                                </div>

                                                                <a href="#" [class.bgc-success]="document.statut=='FOURNI'"  [class.bgc-danger]="document.statut=='NONFOURNI'" class=" position-br p-2  m-1 radius-1 text-white text-600 no-underline">
                    <span class="align-middle">
                    {{document.label}}
                   </span>
                                                                </a>
                                                            </div>




                                                        </div>


                                                    </div>


                                                </div>
                                            </div><!-- /.card-body -->
                                        </div>

                                        <div class="card mt-2" id="card-8">
                                            <div class="card-header">
                                                <h5 class="card-title text-120 text-default-d3">
                                                    Autres documents
                                                </h5>

                                                <div class="card-toolbar no-border  align-self-center">
                            <span class="badge badge-primary px-25">
							          0
						          </span>
                                                </div>

                                                <div class="card-toolbar">


                                                    <a href="#" data-action="toggle" class="card-toolbar-btn btn btn-sm radius-1 btn-light-default btn-brc-tp mx-2px">
                                                        <i class="fa fa-angle-up w-2 mx-1px"></i>
                                                    </a>


                                                </div>
                                            </div><!-- /.card-header -->

                                            <div class="card-body mt-2 p-0 collapse show" style="">
                                                <div class="p-3">


                                                    <div class="row">




                                                    </div>


                                                </div>
                                            </div><!-- /.card-body -->
                                        </div>

                                    </div>
                                </div>



                            </div>
                        </div>

                        <div class="tab-pane text-95 px-3" id="more18" role="tabpanel" aria-labelledby="more18-tab-btn">
                        </div>
                    </div>
                </div>

            </div>




        </div>



    </div>

</div>
<app-view-doc #viewDoc></app-view-doc>


<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>

