import {Component, OnInit} from '@angular/core';
import {FrmreservationComponent} from '../frmreservation/frmreservation.component';

declare var $;

@Component({
  selector: 'app-frm-circuit',
  templateUrl: './frm-circuit.component.html',
  styleUrls: ['./frm-circuit.component.css']
})
export class FrmCircuitComponent implements OnInit {
  reservation: any;
  parent: FrmreservationComponent;

  items = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  show(reservation: any, parent: FrmreservationComponent, passagers: any[], circuit: any[]) {
    this.items = [];
    this.reservation = reservation;
    this.parent = parent;

    if (!!circuit && circuit.length > 0) {
      this.items = circuit;
    } else {

      let addresses: any[] = [];

      passagers.forEach(psg => {

        if (!addresses.includes(psg.adresseDepart) && psg.adresseDepart !== this.reservation.adresseDepart) {
          addresses.push(psg.adresseDepart);
        }

        if (!addresses.includes(psg.adresseArrivee) && psg.adresseArrivee !== this.reservation.adresseArrivee) {
          addresses.push(psg.adresseArrivee);
        }

      });


      this.items.push({role: 'origin', draggable: false, text: this.reservation.adresseDepart, icon: 'fa fa-car'});

      addresses.forEach(addr => {
        this.items.push({role: 'waypoint', draggable: true, text: addr, icon: 'fa fa-arrow-down'});
      });

      this.items.push({
        role: 'destination',
        draggable: false,
        text: this.reservation.adresseArrivee,
        icon: 'fa fa-flag'
      });
      console.log(this.items);
    }


    $('#modalCircuit').modal('show');
  }

  close() {
    $('#modalCircuit').modal('hide');
  }

  save() {
    console.log(this.items);

    const adresses = JSON.stringify(this.items);

    this.parent.caculateDistanceByCircuit(this.items);

    $('#modalCircuit').modal('hide');
  }


}
