import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';
import { StatutModel } from '../shared/models/statut-model.model ';

@Injectable({
  providedIn: 'root'
})
export class TypeNoteService {

  constructor(private http: HttpClient) { }

  getByStatut(statut: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'reservation/listbytypenote/'+statut);
  }
  getData() {
     return this.http.get(AppModule.API_ENDPOINT + 'typenote/list');
  }

  get(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'typenote/get/' + id);
  }

  save(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'typenote/create', body, { headers });
  }

  update(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + 'typenote/update/' + id, body, { headers });
  }


  delete(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + 'typenote/delete/' + id);
  }

  getByCode(code: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'typenote/type/' + code);
  }

  getByLibelle(libelle: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'typenote/statutlibelle/' + libelle);
  }
}
