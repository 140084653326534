<div aria-hidden="true" class="modal fade modal-ds" id="modalCircuit" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Circuit
        </h5>

        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div>


          <div [sortablejs]="items">
            <div *ngFor="let item of items" [class.disabled]="!item.draggable"
                 [class.draggable]="item.draggable">
              <div
                class="d-flex flex-wrap align-items-center my-2 bgc-secondary-l4 bgc-h-secondary-l3 radius-1 p-25 d-style"
                role="button">
                      <span class="mr-2">
                            <i class="{{item.icon}} text-blue-d1 text-95"></i>
                    </span>

                <span class="text-default-d3 text-90 text-600">
                        {{item.text}}
                    </span>

                <!--fa fa-arrow-down -->


              </div>
            </div>
          </div>

        </div>


      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" data-dismiss="modal" type="button">
          Fermer
        </button>
        <button (click)="save()" class="btn btn-primary" type="button">
          Valider
        </button>


      </div>
    </div>
  </div>
</div>
