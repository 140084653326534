<div aria-hidden="true" class="modal fade modal-lg" id="modalDocument" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <span *ngIf="!!citizen">{{citizen.cardNumber}}</span>

                </h5>

                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>

            <div class="modal-body" style="min-height: 350px;">



                <div *ngIf="!!citizen?.path">

                    <iframe [src]="getSafeUrl(url+citizen.path)" height="500" width="100%">

                    </iframe>

                </div>





            </div>

            <div class="modal-footer">
                <button class="btn btn-secondary" data-dismiss="modal" type="button">
                    Fermer
                </button>



            </div>
        </div>
    </div>
</div>
