import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FoyerService } from 'src/app/services/foyer.service';
import { CollectiivteStructureService } from 'src/app/services/collectivitestructure.service ';
import { StructureService } from 'src/app/services/structure.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
declare function stransAdresse(id: any);
declare var $: any;

@Component({
  selector: 'app-frmtadprestataire',
  templateUrl: './frmtadprestataire.component.html',
  styleUrls: ['./frmtadprestataire.component.css']
})
export class FrmtadprestataireComponent implements OnInit {

  structures: any = [];
  selectedstructures: any = [];
  // datafoyers: any = [];
  structure: any = null;
  collectivitestructure: any = null;
  typestructure: any = '';
  lblstructure;

  siret: any = '';
  nom: any = '';
  adresse: any = '';
  responsable: any = '';
  email: any = '';
  collectivite: any = '';
  codepostal: any = '';
  telephone: any = '';
  telephoneportable: any = '';
  ville: any = '';
  parent;
  prestataire;
  codePostal: any = '';
  error = false;
  errorEmail = false;
  errorTel = false;
  test = true;
  infoError = "Champs invalide";
  selectedstructure;
  collectiviteTad;
  parenttad;
  collectivites: any = [];
  @ViewChild('structureModal') structureModal;


  constructor(private http: HttpClient, private foyerService: FoyerService,
    private collectiviteStructureService: CollectiivteStructureService,
    private structureService: StructureService) { }

  ngOnInit(): void {
    stransAdresse('adresse');
  }

  showStructure(): void {
    // alert(this.parenttad)
    this.structureService.getStructureNonRattacheeTad(this.typestructure, this.parenttad, this.selectedstructure).subscribe(data => {
      this.structures = data;

    });
  }
  openSelect() {
    this.showStructure();
  }
  showTad(): void {
    this.structureService.getStructuresByType(TypeStructureModel.TAD).subscribe(data => {
      this.collectivites = data;

    });

  }

  show(parent) {
    // alert();
  //  alert("ok");
    this.parent = parent;
    if (this.selectedstructure != null) {
      $(".struct").attr("disabled", true);
      this.showStructure();
    }
    this.showTad();
    // this.showStructure();
   
    $(this.structureModal.nativeElement).modal('show');
  }

  savePrestaFoyer() {
    if (this.nom != '')
      this.save();
    for (const structure of this.selectedstructures) {

      console.log(structure);

      const headers = {};
      const body = { telephone: this.telephone,responsable: this.responsable, email: this.email,
        actif: 1, structure: { id: structure }, collectivite: { id: this.selectedstructure } }

      this.collectiviteStructureService.save(headers, body).subscribe(data => {
        this.parent.filter(null, this.collectivite);
      });

    }
    $(this.structureModal.nativeElement).modal('hide');
    this.resetForm();
    this.parent.filter(null, this.collectivite);


  }
  fermer() {
    this.resetForm();
    $(this.structureModal.nativeElement).modal('hide');
  }

  resetForm() {
    this.selectedstructures = [];
    this.siret = '';
    this.nom = '';
    this.adresse = '';
    this.responsable = '';
    this.email = '';
    this.ville = '';
    this.codePostal = '';
  }

  oncpChange(e) {
    if (e.target.checked) {
      this.selectedstructures.push(e.target.value);
    } else {
      let i = 0;
      this.selectedstructures.forEach((selPens: any) => {
        if (selPens == e.target.value) {
          delete this.selectedstructures[i];
          return;
        }
        i++;
      });
    }

  }
  pushData() {
    $('#adresse')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#ville')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#codePostal')[0].dispatchEvent(new Event('input', { bubbles: true }));
    const headers = {};
    const body = {
      siret: this.siret, nom: this.nom,
      adresse: this.adresse, telephone: this.telephone,
      responsable: this.responsable, ville: this.ville, codePostal: this.codePostal,
      email: this.email, typestructure: this.typestructure, actif: true
    };
    console.log(this.prestataire);
    if (!this.prestataire) {
      this.structureService.save(headers, body).subscribe(data => {
        this.structure = data;
        this.savecolstruc();
        // $('#modalTad').modal('hide');
        //this.parent.filter(null, this.collectivite);
        // this.parent.parent.getOrganisations();

        this.resetForm();

      });
    } else {
      this.structureService.update(headers, body, this.prestataire.id).subscribe(data => {
        // $('#modalTad').modal('hide');
        // this.parent.filter(null,this.collectivite);
        this.resetForm();
      });
    }
  }




  save(): void {

    if (this.valideInput()) {
      this.pushData();
    }
  }

  savecolstruc(): void {
    const headers = {};
    const body = { structure: this.structure, collectivite: { id: this.selectedstructure }, actif: true };
    console.log(this.collectivitestructure);
    if (!this.collectivitestructure) {
      this.collectiviteStructureService.save(headers, body).subscribe(data => {
        //   alert("ok");
        this.parent.filter(null, this.collectivite);
        //  this.personne=data;
        //  $('#modalCollectiviteStructure').modal('hide');
        // this.parent.filter(null,this.structure);
      });
    } else {
      this.collectiviteStructureService.update(headers, body, this.collectivitestructure.id).subscribe(data => {
        //  $('#modalCollectiviteStructure').modal('hide');
        // this.parent.filter(null,this.structure);

      });
    }
  }

  valideInput() {
    if (this.nom.trim().length == 0) {
      this.error = true;
      this.test = false;
    } else {
      this.error = false;
      this.test = true;
    }
    if (this.valideEmail() == true) {
      this.test = false;
    }
    // if (this.valideTel() == true) {
    //   this.test = false;
    // }
    return this.test;
  }
  valideTel() {
    var reg = /^(06|07)[0-9]{8}/gi;
    this.telephone = this.telephone.replaceAll(' ', '');
    this.telephone = this.telephone.replaceAll('-', '');
    if (this.telephone.length != 0) {
      if (this.telephone.length == 10) {
        if (!this.telephone.match(reg)) {
          this.errorTel = true;
          this.infoError = 'Le numero doit imperativement commencer par 06 ou 07.';
        } else {
          this.errorTel = false;
        }
      } else {
        // if(this.telephone.length < 10)
        this.infoError = 'Vous avez saisi ' + this.telephone.length + '.La longueur requise est de 10 chiffres.';
        this.errorTel = true;
        this.infoError = "Vous avez saisie "
      }
    } else {
      this.errorTel = false;
    }
    return this.errorTel;
  }

  valideEmail() {
    var validRegex = "^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$";
    if (this.email.trim().length != 0) {
      if (this.email.match(validRegex)) {
        this.errorEmail = false;
      } else {
        this.errorEmail = true;
      }
    }
    return this.errorEmail;
  }
}
