import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';
import { Observable } from 'rxjs';
import { FoyerModel } from '../shared/models/foyer-model.model';
import { TypeStructureModel } from '../shared/models/type-structure-model.model';
import { VilleModel } from '../shared/models/ville-model.model';
import { CollectiviteStructureModel } from '../shared/models/collectivitestructure-model.model';
import { StructureMotifModel } from '../shared/models/structuremotif-model.model ';

@Injectable({
  providedIn: 'root'
})
export class FoyerService {

  constructor(private http: HttpClient) { }

  getData(typestructure: TypeStructureModel): Observable<FoyerModel[]> {
     return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/list/'+typestructure);
  }

  getDataMot(){
    return this.http.get<StructureMotifModel[]>(AppModule.API_ENDPOINT + 'structuremotif/list');
 }

  getData1(typestructure: TypeStructureModel): Observable<CollectiviteStructureModel[]> {
    return this.http.get<CollectiviteStructureModel[]>(AppModule.API_ENDPOINT + 'collectivtestructure/list/'+typestructure);
 }

 getByCollectivite(structure: FoyerModel): Observable<CollectiviteStructureModel[]> {
  return this.http.get<CollectiviteStructureModel[]>(AppModule.API_ENDPOINT + 'collectivtestructure/list/'+structure);
}

getTadByCollectivite(structure: any) {
  return this.http.get(AppModule.API_ENDPOINT + 'structure/liststrucutre/'+structure);
}

 getDataMotif(typestructure: TypeStructureModel): Observable<StructureMotifModel[]> {
  return this.http.get<StructureMotifModel[]>(AppModule.API_ENDPOINT + 'structuremotif/list/'+typestructure);
}
  getDataVille(): Observable<VilleModel[]> {
    return this.http.get<VilleModel[]>(AppModule.API_ENDPOINT + 'structure/finAll');
 }

  get(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/get/' + id); 
  }

  save(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'structure/create', body, { headers });
  }

  update(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + 'structure/update/' + id, body, { headers });
  }


  delete(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + 'structure/delete/' + id);
  }

    getStructureByID(id: number): Observable<FoyerModel[]> {
    return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/getStructureById/'+id);
  }

  

  getDataByTad(typestructure: TypeStructureModel, id: number): Observable<FoyerModel[]> {
    return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/list/'+typestructure +'/' + id);
  }

  getDataFoyerAdmin(typestructure:any): Observable<FoyerModel[]> {
    return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/listfoyerfa/'+typestructure);
 }
}
