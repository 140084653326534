<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Référentiel
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Fonction
      </small>
    </h1>

  </div>


  <div class="px-2">


    <div class="card dcard">
      <div class="card-body px-1 px-md-3">

        <form autocomplete="off">
          <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
              <button  *ngIf="this.canCreate" type="button" data-toggle="modal" data-target="#modalForm" class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                <i class="fa fa-plus mr-1"></i>
                Ajouter <span class="d-sm-none d-md-inline"></span>
              </button> </div>

            <div class="mb-2 mb-sm-0">
              
            </div>
          </div>

          <table  class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
            <tr>
          
              <th>
                Code
              </th>
              <th>
                Libellé
              </th>
              <th></th>
              <th></th>
            </tr>
            </thead>

            <tbody class="mt-1">
            <tr  *ngFor="let fonction of data| paginate : {
              itemsPerPage: tableSize,
              currentPage: page,
              totalItems: count}; let i = index" [class.active]="i == currentIndex" class="bgc-h-yellow-l4 d-style">
             
              <td>
                <span  class="text-dark-d1 text-600 text-95">{{fonction.code}}</span>
              </td>
              <td>
                <a href="#" class="text-blue-d1 text-600 text-95">{{fonction.libelle}}</a>
              </td>
              
              <td>
                <!-- action buttons -->
                
                <div class="d-none d-lg-flex">
                  <a href="#" *ngIf="this.canUpdate" (click)="edit(fonction.id)" class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                    <i class="fa fa-pencil-alt"></i>
                  </a>

                  <a href="#"   *ngIf="this.canDelete" (click)="delete(fonction.id)" class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                    <i class="fa fa-trash-alt"></i>
                  </a>
                  
                </div>

                <!-- show a dropdown in mobile -->
                <div class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                  <a href="#" class="btn btn-default btn-xs py-15 radius-round dropdown-toggle" data-toggle="dropdown">
                    <i class="fa fa-cog"></i>
                  </a>

                  <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                    <div class="dropdown-inner">
                      <div class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                        CK
                      </div>
                      <a href="#" class="dropdown-item">
                        <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                        Edit
                      </a>
                      <a href="#" class="dropdown-item" (click)="delete(fonction.id)">
                        <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                        Delete
                      </a>
                      <a href="#" class="dropdown-item">
                        <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                        Autre
                      </a>
                    </div>
                  </div>
                </div>

              </td>
            </tr>
            </tbody>
          </table>

          <!-- table footer -->
          <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
            <div class="text-nowrap align-self-center align-self-sm-start">
                Afficher 1 - {{ tableSize }} of {{data.length}}
                <select (change)="tableData($event)" class="custom-select">
                <option *ngFor="let size of tableSizesArr" [value]="size">
                    Afficher {{ size }}
                </option>
              </select>
            </div>
            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next" (pageChange)="tabSize($event)">
                </pagination-controls>
            </div>
            <div>

            </div>
        </div>
        </form>

      </div><!-- /.card-body -->
    </div>



  </div>

</div>


<div class="modal fade modal-ds " id="modalForm" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >
          Fonction
        </h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="">
          <div class="">

            <form class="mt-lg-3">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Code
                  </label>
                </div>

                <div class="col-sm-9">
                  <input type="text" [(ngModel)]="code" name="code" class="form-control col-sm-8 col-md-3">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Libellé
                  </label>
                </div>

                <div class="col-sm-9">
                  <input type="text" [(ngModel)]="libelle" name="libelle" class="form-control col-sm-8 col-md-6">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Description
                  </label>
                </div>

                <div class="col-sm-9">
                  <textarea class="form-control" [(ngModel)]="description"  name="description" placeholder="" style="overflow: hidden; overflow-wrap: break-word; resize: horizontal; height: 62px;"></textarea>
                </div>
              </div>

            </form>


          </div><!-- /.card-body -->
        </div>


      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Fermer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()">
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
