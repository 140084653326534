import { Component, OnInit, ViewChild } from '@angular/core';
import {FrmreservationComponent} from "../frmreservation/frmreservation.component";
import {HttpClient} from "@angular/common/http";
import {PassagerService} from "../../services/passager.service";
import {Tool} from "../../shared/tool";
import { FrminformationchauffeurComponent } from '../frminformationchauffeur/frminformationchauffeur.component';
import { SecurityService } from 'src/app/services/security.service';
import { User } from 'src/app/shared/session/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
declare var $: any;

@Component({
  selector: 'app-passagers',
  templateUrl: './passagers.component.html',
  styleUrls: ['./passagers.component.css']
})
export class PassagersComponent implements OnInit {

  passagers: any = [];
  reservation: any;
  canAddHeure =false;
  user: User = new User();

  @ViewChild('frminformationchauffeurComponent') frminformationchauffeurComponent: FrminformationchauffeurComponent;

  constructor(private http: HttpClient, private passagerService: PassagerService, private tool: Tool,
    private securiteService: SecurityService, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
  }


  show(reservation: any) {
    this.reservation  = reservation;
    this.getDroit();
    this.getPassagers();


  }
  getDroit(): void {

    this.securiteService.getDroit(this.user.profilCode, 'ACT_ADD_HEUREPASSAGER').subscribe(data => {
      // console.log(this.reservations)
      if (!!data) {

        this.canAddHeure = true;

      } else {
        this.canAddHeure = false;
        // alert(this.canCreateReservation)
      }

    });
  }
  getPassagers() {

    this.passagerService.getPassagers(this.reservation.id).subscribe(data => {
      this.passagers = data;
      $('#modalPassager').modal('show');
    });

  }

  getAge(dateN: any) {
    return this.tool.getAge(dateN);
  }

  EditPassager(passager:any){
    this.frminformationchauffeurComponent.show(passager, this);
  }


}
