import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActeurService } from 'src/app/services/acteur.service';
import { PersonneService } from 'src/app/services/personne.service ';
import { StructureService } from 'src/app/services/structure.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { timingSafeEqual } from 'crypto';
declare var $: any;
declare function stransAdresse(id: any);

@Component({
  selector: 'app-frmenfant',
  templateUrl: './frmenfant.component.html',
  styleUrls: ['./frmenfant.component.css']
})
export class FrmenfantComponent implements OnInit {
  //personne: any = null;
  enfants: any = [];
  parent;
  typestructure: TypeStructureModel;
  lblnom;
  lblstructure;
  selectstructure: any = null;
  selectedActeurs: any = [];
  structure;
  typeacteur;
  foers: any = [];
  user: User = new User();
  constructor(private http: HttpClient, private acteurService: ActeurService,
    private structureService: StructureService, private authenticationService: AuthenticationService,
    private personneService: PersonneService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    stransAdresse('adresse');
  }
  showEnfant(): void {
    this.acteurService.getEnfantNonRattacheesFoyer().subscribe(data => {
      this.enfants = data;   
    });
  }
  openSelect(){
    this.showEnfant();
  }

  getAge(dateNaissance) {

    var timeDiff = Math.abs(Date.now() - new Date(dateNaissance).getTime());
    var age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

    return age;
  }
  showStructure(): void {
    //   if(this.user.structure==0){
       this.structureService.getStructuresByType(this.typestructure).subscribe(data => {
         this.foers = data;
       
       });
    // }
        } 

  show(parent) {
    this.parent = parent;
    if (this.selectstructure != null){
      $("#foyer").attr("disabled", true);
      this.showEnfant();
    } 
    this.showStructure();
    
    $('#modalEnfant').modal('show');
  }

  oncpChange(e) {
    if (e.target.checked) {
      this.selectedActeurs.push(e.target.value);
      
    } else {
      let i = 0;
      this.selectedActeurs.forEach((selPens: any) => {
        if (selPens == e.target.value) {
          delete this.selectedActeurs[i];
          return;
        }
        i++;
      });
    }
  
  }
  
saveEnfant() {
for (const personne of this.selectedActeurs) { 
//  alert(this.selectstructure)
  console.log(personne);
  const headers = {};
  const body = {actif: 1,typeacteur: this.typeacteur, personne: {id : personne}, structure: {id: this.selectstructure}}
  this.acteurService.save(headers, body).subscribe(data => {
    
  });
} 
this.resetForm();
$('#modalEnfant').modal('hide');
this.parent.filter(null, this.structure); 

}
  fermer() {
    this.resetForm();
    $('#modalEnfant').modal('hide');

  }
  resetForm() {
    this.selectedActeurs = []; 
  }
}
