import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BudgetService } from 'src/app/services/budget.service ';
import { PaginationService } from 'src/app/services/pagination.service';
import { SecurityService } from 'src/app/services/security.service';
import { StructureService } from 'src/app/services/structure.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { FrmbudgetComponent } from '../frmbudget/frmbudget.component';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { FoyerService } from 'src/app/services/foyer.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;


@Component({
  selector: 'app-lstbudget',
  templateUrl: './lstbudget.component.html',
  styleUrls: ['./lstbudget.component.css']
})


export class LstbudgetComponent implements OnInit {
  @ViewChild ('frmbudgetComponent') frmbudgetComponent: FrmbudgetComponent;
  constructor(private http: HttpClient,
    private structureService: StructureService, private budgetService: BudgetService,
    private authenticationService: AuthenticationService,private paginationService:PaginationService
    ,private securiteService : SecurityService, private foyerService: FoyerService,private spinner: NgxSpinnerService) { }
  databudgets: any = [];
  tads: any = [];
  pensionnaire: any = null;
  collaborateur: any = null;
  budget: any = null;
  tad;
  col;
  user: User = new User();
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex:any;
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canDetails = false;

  montant: any = null;
  montantMax: any = null;
  critereMontant: any = '1';
  dateDebut: any = null;
  dateDebutMax: any = null; 
  dateFinMax: any = null; 
  critereDateDebut: any = '1';
  dateFin: any = null;
  critereDateFin: any = '1';
  tadSelected: any = 0;

  personne;
  
  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.tad = this.user.structure;
    this.col = this.user.structure;
    this.personne = this.user.personne;
    this.showBudget();
    this.showStructure();
    this.getDroit();
  }
  showBudget(): void {
    this.spinner.show();
    if(this.user.structure==0){
      this.budgetService.getData().subscribe(data => {
        this.databudgets = data;
        this.budget = null;
        this.spinner.hide();
     });
    }else if(this.user.profilCode == "PF_AGCOL"){
      this.budgetService.getByCollectivite(this.col).subscribe(data => {
        this.databudgets = data;
        this.budget = null;
        this.spinner.hide();
     });
    }else{
    this.budgetService.getByStructureBis(this.personne).subscribe(data => {
      this.databudgets = data;
      this.budget = null;
      this.spinner.hide();
   });
  }
  }

  showStructure(): void {
    this.structureService.getTadByProfil(this.user.id).subscribe(data => {
        this.tads = data;
      });
       
    }
 
     resetTad(){
      this.tadSelected = 0;
     }
     resetMontant(){
      this.montant = null;
     }
     resetMontantMax(){
      this.montantMax = null;
     }
     reset1(){
      this.dateDebut = null;
     }
     reset2(){
      this.dateFin = null;
     }
     reset3(){
      this.dateDebutMax = null;
     }
     reset4(){
      this.dateFinMax = null;
     }
  rechercheAvanceBudget() { 
    this.spinner.show();
    $(".collapse").collapse('hide');
    if(this.tadSelected == null)
    this.tadSelected = 0;
   // alert(this.tadSelected)
     this.dateDebutMax = this.dateDebutMax ? this.dateDebutMax: null;
     this.dateFinMax = this.dateFinMax ? this.dateFinMax: null;
     this.montantMax = this.montantMax ? this.montantMax: null;
     this.dateDebut = this.dateDebut ? this.dateDebut: null;
     this.dateFin = this.dateFin ? this.dateFin: null;
     this.montant = this.montant ? this.montant: null;
     if(this.user.structure==0){
      this.budgetService.getRechercheBis(this.tadSelected, this.dateDebut,this.dateFin,this.montant, this.critereDateDebut, this.critereDateFin, this.critereMontant, this.dateDebutMax,this.dateFinMax,this.montantMax).subscribe(data => {
        this.databudgets = data;
        this.spinner.hide();
     });
     
    }else if(this.user.profilCode == "PF_AGCOL"){
      this.budgetService.getRechercheCollectivite(this.tadSelected, this.dateDebut,this.dateFin,this.montant,this.user.structure, this.critereDateDebut, this.critereDateFin, this.critereMontant, this.dateDebutMax,this.dateFinMax,this.montantMax).subscribe(data => {
        this.databudgets = data;
        this.spinner.hide();
     });
    }else{
      this.budgetService.getRechercheTad(this.tadSelected, this.dateDebut,this.dateFin,this.montant,this.user.personne, this.critereDateDebut, this.critereDateFin, this.critereMontant, this.dateDebutMax,this.dateFinMax,this.montantMax).subscribe(data => {
        this.databudgets = data;
        this.spinner.hide();
     });
  }
     
   }
 
  filter(parent,presta:any): void  {
    this.showBudget();
  }

  openFormBudget() {
   // this.spinner.show();
    if(this.user.profilCode == "PF_TAD"){
      // alert()
       this.structureService.get(this.user.structure).subscribe(data => {
         this.frmbudgetComponent.tad = data;
       });
       
       this.frmbudgetComponent.selecttad=this.user.structure
     }else if(this.user.profilCode == "PF_AGCOL"){
      this.foyerService.getTadByCollectivite(this.col).subscribe(data => {
        this.frmbudgetComponent.tads = data;
        this.tad = null;
        this.spinner.hide();
      });
     }
    this.frmbudgetComponent.show(this);
  }

  delete(id: any): void {
    this.budgetService.get(id).subscribe(data => {
      this.budget = data;
     // alert(this.budget.montantconsome);
      Swal.fire({
        title: 'Voulez vraiment supprimer?',
        text: 'Vous aller definitivement supprimer ce budget!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OUI, Supprimer!',
        cancelButtonText: 'NON, Garder'
      }).then((result) => {
        if (result.value) {
          
          if(!this.budget.montantconsome || this.budget.montantconsome == 0){
            this.budgetService.delete(id).subscribe(data => {
              Swal.fire(
                'Supprimer!',
                'Le budget a été supprimé avec succes',
                'success',
      
              )
              this.filter(this, this.tadSelected);
              this.tabSize(event);
            },
            err => {
              Swal.fire({ icon: 'error', text: "La Suppresion a échoué. Car le budget a été  utilisé ailleurs" });
            });
        
          }else{
            Swal.fire({ icon: 'error', text: "La Suppresion a échoué. Car le budget a été  utilisé dans au moins une réservation" });
          }
          
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Annuler',
            'Le budget n\'a pas  été supprimé :)',
            'error'
          )
        }
      })
    });

     
  }
 

 edit(id:any){
   this.frmbudgetComponent.edit(this, id);
 }
 
 showData(): void {
  this.paginationService.fetchPosts()
    .subscribe(
      res => {
        this.databudgets = res;
        console.log(res);
      },
      err => {
        console.log(err);
      });
}

tabSize(event){
  this.page = event;
 // this.showData();
}  

tableData(event): void {
  this.tableSize = event.target.value;
  this.page = 1;
 // this.showData();
} 


getDroit():void{
  this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_BUDGET").subscribe(data => {
    console.log(this.databudgets)
    if (!!data) {
      this.canCreate = true;
    }  else {
      this.canCreate = false;
    }
 });

this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_BUDGET").subscribe(data => {
console.log(this.databudgets)
if (!!data) {
  this.canDelete = true;
}  else {
  this.canDelete = false;
}
});

this.securiteService.getDroit(this.user.profilCode, "ACT_EDIT_BUDGET").subscribe(data => {
console.log(this.databudgets)
if (!!data) {
  this.canUpdate = true;
}  else {
  this.canUpdate = false;
}
});



 }
 
}
