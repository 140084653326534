import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FrmpassagerComponent } from '../frmpassager/frmpassager.component';
import { HttpClient } from '@angular/common/http';
import { TypetemplateService } from '../../services/typetemplate.service';
import { StructureService } from '../../services/structure.service';
import { TemplatePrixService } from '../../services/templatePrix.service';
import { TypeStructureModel } from '../../shared/models/type-structure-model.model';
import { MotifService } from '../../services/motif.service';
import { ReservationService } from '../../services/reservation.service';
import { PassagerService } from '../../services/passager.service';
import { FrmcontactpassagerComponent } from '../frmcontactpassager/frmcontactpassager.component';
import { ContactpassagerService } from '../../services/contactpassager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FrmresatemplateComponent } from '../frmresatemplate/frmresatemplate.component';
import { User } from '../../shared/session/user';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { StructureMotifService } from 'src/app/services/structuremotif.service ';
import { FoyerService } from 'src/app/services/foyer.service';
import { StatutService } from 'src/app/services/statut.service';
import { Subscription } from 'rxjs';
import { ActeurService } from 'src/app/services/acteur.service';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { SecurityService } from 'src/app/services/security.service';

// import { AutocompleteDirectionHandler } from ' ../../assets/js/autocomplete.js';
declare function loadWizard();

// declare function initAutocomplete(map: any, input: any);
declare function initMap(suffix: any);

declare function setMapDirection(suffix: any, depart: any, arrivee: any);
import Swal from 'sweetalert2';

import {parse} from 'date-fns';
import {BudgetService} from '../../services/budget.service ';
import {NgxSpinnerService} from "ngx-spinner";
import {DetailstemplateprixComponent} from 'src/app/templateprix/detailstemplateprix/detailstemplateprix.component';
import {FrmCircuitComponent} from "../frm-circuit/frm-circuit.component";
import {AlerteService} from "../../services/alerts.service";

declare function showPoperError(message: any);

declare function getDistance(depart: any, arrivee: any);

declare function getDistanceByCircuit(cricuit: any);

declare var $: any;


@Component({
  selector: 'app-frmreservation',
  templateUrl: './frmreservation.component.html',
  styleUrls: ['./frmreservation.component.css']
})
export class FrmreservationComponent implements OnInit {
  private routeSub: Subscription;

  prestataires: any = [];
  motifs: any = [];
  tads: any = [];
  passagers: any = [];
  reservation: any;
  contacts: any = [];
  chauffeurs: any = [];

  templates: any = [];
  cbxTemplate: any;
  transporteur: any;
  adresseDepart: any = '';
  adresseArrivee: any = '';
  distance: number;
  estimatedDuration: number;
  motifTransport: any;
  noteChauffeur: any;
  reservationDate: any;
  templatePrix: any;
  tad: any = null;
  col: any = null;
  user: User;
  erroradressearrive = false;
  erroradressedepart = false;
  test = true;
  error = false;
  statut = 1;
  isduplicate = false;
  chauffeur: any;
  selectedchauffeur: any;
  oldReservationId: any;
  isduplicatePassagers = false;
  canAddHeure = false;
  canFinish = false;
  flagHeureReservation = 'DEPART';
  feriesDates: any = [];
  prices: any = [];
  distsRefs: any = [];
  circuit: any [];
  canHaveCircuit = false;
  bon = true;
  tabFoyer ={};
  edited = false;
  noEditableStatus = ['VAL'];
  validatedBy: any;



  // parent2 = ;

  @ViewChild('frmpassagerComponent') frmpassagerComponent: FrmpassagerComponent;
  @ViewChild('frmcontactpassagerComponent') frmcontactpassagerComponent: FrmcontactpassagerComponent;
  @ViewChild('frmresatemplateComponent') frmresatemplateComponent: FrmresatemplateComponent;
  @ViewChild('detailstemplateprix') detailstemplateprix: DetailstemplateprixComponent;
  @ViewChild('frmCricuit') frmCricuit: FrmCircuitComponent;


  constructor(private http: HttpClient, private structureService: StructureService, private templateService: TemplatePrixService, private motifService: MotifService, private reservationService: ReservationService,
              private passagerService: PassagerService,
              private structuremotif: StructureMotifService,
              private contactpassagerService: ContactpassagerService, private acteurService: ActeurService,
              private route: Router, private foyerService: FoyerService, private activiteRoute: ActivatedRoute,
              private authenticationService: AuthenticationService, private statutService: StatutService, private securiteService: SecurityService,
              private budgetService: BudgetService, private spinner: NgxSpinnerService, private alertService: AlerteService) {

    this.user = authenticationService.getUser();
    this.col = this.user.structure;
    // this.gettads();    // this.personne = this.user.personne;
    this.getMotifs();
    this.getDayFeries();
    if (this.user.profilCode == 'PF_GFOYER') {
      this.statut = 8;
    }
    if (this.user.profilCode != 'PF_PRESTAIRE') {
      $('#cha').attr('disabled', true);
    }
  }
  gettads() {
    if (this.user.structure == 0) {
      this.structureService.getStructuresByType(TypeStructureModel.TAD).subscribe(data => {
        this.tads = data;
        this.tad = null;
      });
    } else {
      if (this.user.profilCode == 'PF_AGCOL') {
        this.foyerService.getTadByCollectivite(this.col).subscribe(data => {
          this.tads = data;
          this.tad = null;
        });
      } else if (this.user.profilCode == 'PF_GFOYER') {
        this.structureService.getTadRattacheeFoyerPrestataire(TypeStructureModel.TAD, this.col).subscribe(data => {
          this.tads = data;
          this.tad = null;
        });
      } else {
        this.structureService.getTads(TypeStructureModel.TAD, this.user.personne).subscribe(data => {
          this.tads = data;
          this.tad = this.user.structure;
          this.getMotifs();
        });
      }

    }
  }

  getPrestataires(): void {
    this.structureService.getStructureRattachee(TypeStructureModel.PRESTATAIRE, this.user.structure).subscribe(data => {
      this.prestataires = data;
    });

  }

  getMotifs(): void {
    this.structuremotif.getMotifByUser(this.user.id).subscribe(data => {
      this.motifs = data;
    });

  }

  openSelect() {
    this.getMotifs();
  }



  ngOnInit(): void {
    // $('#origin-input').addClass('brc-danger-m2');


    loadWizard();

    // $('.knob').knob();

    $('#id-duree').knob({
      readOnly: true,
      fgColor: 'rgb(55, 149, 218)',
      thickness: 0.2,
      width: 80,
      height: 80,
      max: 24,
      draw() { $(this.i).val(this.cv + ' H'); }
    });


    this.routeSub = this.activiteRoute.params.subscribe(params => {

      // console.log("route",this.activiteRoute.snapshot['_routerState'].url);
      // this.isduplicate = this.activiteRoute.snapshot._routerState.url.includes('duplicate');
      // alert(this.isduplicate);
      console.log(this.isduplicate);
      if (!!params && !!params.id) {
        //  alert(this.isdupplicate);
        this.reservationService.get(params.id).subscribe(data => {
          this.reservation = data;
          this.oldReservationId = this.reservation.id;
          this.tad = this.reservation.tad.id;
          this.adresseDepart = this.reservation.adresseDepart;
          this.adresseArrivee = this.reservation.adresseArrivee;
          this.motifTransport = this.reservation.motifTransport.id;
          this.distance = this.reservation.distance;
          if (!this.isduplicate) {
            this.reservationDate = this.reservation.reservationDate;
            this.flagHeureReservation = this.reservation.flagHeureReservation;
          }
          this.noteChauffeur = this.reservation.noteChauffeur;
          this.estimatedDuration = this.reservation.estimatedDuration;
          this.transporteur = this.reservation.transporteur;
          this.templatePrix = this.reservation.templatePrix;
          if (!!this.reservation.chauffeur && !this.isduplicate) {
            this.chauffeur = this.reservation.chauffeur;
            this.selectedchauffeur = this.reservation.chauffeur.id;
          }

          this.canFinish = true;

          if(this.noEditableStatus.includes(this.reservation.statut.code)) {
            this.edited = true;
            this.validatedBy = this.reservation.validatedBy;
          }

          setMapDirection('', this.reservation.adresseDepart, this.reservation.adresseArrivee);
          // initMap('');

          if (this.isduplicate) {
            // this.reservation = null;
          }

          // $('#origin-input')[0].dispatchEvent(new Event('input', { bubbles: true }));
          // $('#destination-input')[0].dispatchEvent(new Event('input', { bubbles: true }));

          this.getMotifs();
          this.refreshPassengers();
          if (!this.isduplicate) {
            this.getTemplatesBis();
          }
          // alert(this.reservationDate);
          // console.log(this.reservation);
        });
        // console.log("Voici id de la reservation = "+params.id)
      } else {
        initMap('');

      }
    });

  }


  openFormPassager(): void {
    $('#adresse_depart_psg').text($('#origin-input').val());
    $('#adresse_arrivee_psg').text($('#destination-input').val());

    // $('#modalFormPassager').modal('show');
    this.frmpassagerComponent.tad = this.tad;
    this.frmpassagerComponent.show(this.reservation.id, this, this.adresseDepart, this.adresseArrivee);


  }

  refreshPassengers() {
    this.passagerService.getPassagers(this.reservation.id).subscribe(data => {
      this.passagers = data;
      this.canHaveCircuit = false;
      this.bon = true;

      if (this.passagers.length == 0) {
        this.tad = null;
        this.canHaveCircuit = false;
        this.save();
      } else {
        this.passagers.forEach(psg => {
          if (psg.adresseDepart !== this.reservation.adresseDepart || psg.adresseArrivee !== this.reservation.adresseArrivee) {
            this.canHaveCircuit = true;
          }
          if (psg.adresseDepart !== this.reservation.adresseDepart && psg.adresseArrivee !== this.reservation.adresseArrivee) {
            this.bon = false;
          }
       //   alert(psg.acteur.personne.nom);
          this.passagerService.getFoyerPassager(psg.acteur.personne.id).subscribe(data => {
        //    alert(data);
            this.tabFoyer[psg.id] = data;
          });
        });
      }
    });
 //   alert(this.bon);
  }
  valideInput() {
    this.test = true;
    if (this.adresseDepart.trim().length == 0) {
      this.erroradressedepart = true;
      this.test = false;
    } else {
      this.erroradressedepart = false;
    }
    if (this.adresseArrivee.trim().length == 0) {
      this.erroradressearrive = true;
      this.test = false;
    } else {
      this.erroradressearrive = false;
    }
  }
  goNext() {
    // if (this.valideInput) {
    this.save();
    // }
  }
  getStatut() {
    this.statutService.getByCode('BROUILLON').subscribe(data => {
      this.statut = data[0].id;
      console.log(this.statut);
    });
  }

  save(): void {
    this.spinner.show();
    this.dispatch();
    const headers = {};
    const body = {
      id: null, adresseDepart: this.adresseDepart, adresseArrivee: this.adresseArrivee, actif: false,
      distance: this.distance, estimatedDuration: this.estimatedDuration,
      motifTransport: {id: this.motifTransport}, noteChauffeur: this.noteChauffeur,
      reservationDate: this.reservationDate,
      statut: {id: this.statut}, transporteur: null, templatePrix: null, chauffeur: null,
      tad: null, createdBy: {id: this.user.id},
      price: 0, flagHeureReservation: this.flagHeureReservation,
    };

    if (!!this.tad) {
      body.tad = { id: this.tad };
    }

    if(!!this.validatedBy) {
      body['validatedBy'] = this.validatedBy;
    }


    if (!!this.templatePrix) {
      body.transporteur = this.templatePrix.prestataire;
      body.templatePrix = this.templatePrix;
      body.price = this.getPrice(this.templatePrix);
    }

    if (!!this.chauffeur) {
      body.chauffeur = this.chauffeur;
    }

    if (this.canFinish) {
      body.actif = true;
    } else {
      body.actif = false;
    }


    if (!this.reservation || this.isduplicate) {

      this.reservationService.save(headers, body).subscribe(data => {
        this.reservation = data;
        if (!this.isduplicatePassagers) {
          this.passagerService.duplicatePassagers(this.oldReservationId, this.reservation.id).subscribe(data => {
            this.passagers = data;
         //   console.log('Liste passagers', this.passagers);
            this.isduplicatePassagers = true;
          });

        }
        this.spinner.hide();
        console.log('nouvelle reservation', this.reservation);
      });
      this.isduplicate = false;
    } else {
      body.statut = this.reservation.statut;
      body.id = this.reservation.id;
      this.reservationService.update(headers, body, this.reservation.id).subscribe(data => {
        this.reservation = data;
        this.spinner.hide();
      });
    }

    this.getTemplatesBis();

   // this.spinner.hide();
  }


  dispatch() {
    $('#origin-input')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#destination-input')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#reservationDate')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#distance')[0].dispatchEvent(new Event('input', { bubbles: true }));
 //   $('#estimatedDuration')[0].dispatchEvent(new Event('input', { bubbles: true }));

  }


  getTemplates() {
    this.templateService.getTemplates().subscribe(data => {
      this.templates = data;
    });
  }

  getTemplatesBis() {
    if (this.user.profilCode != 'PF_GFOYER') {
      this.templateService.getTemplatesBis(this.tad, this.reservationDate).subscribe(data => {
        this.templates = data;
        this.templates.forEach(temp => {
          let distApproche = 0;
          let distRetour = 0;

          const p = getDistance(this.reservation.adresseDepart, temp.pointref);
          p.then(response => {
            distApproche = Math.round(response.routes[0].legs[0].distance.value / 1000);
            this.distsRefs['da' + temp.id] = distApproche;
          });

          const p2 = getDistance(this.reservation.adresseArrivee, temp.pointref);
          p2.then(response => {
            distRetour = Math.round(response.routes[0].legs[0].distance.value / 1000);
            this.distsRefs['dr' + temp.id] = distRetour;
          });

          setTimeout(() => {
            this.prices[temp.id] = this.getPrice(temp);
          }, 2000);

        });
      });
    }

  }


  updateDate(date: any) {
    console.log(date);
  }

  changeTranspoteur(template: any) {
    this.templatePrix = template;
    this.save();
  }

  getAge(dateNaissance) {

    let timeDiff = Math.abs(Date.now() - new Date(dateNaissance).getTime());
    let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

    return age;
  }

  addContact(passager, type) {
    this.frmcontactpassagerComponent.show(this, passager, this.reservation.tad, type);

  }

  getContacts() {
    this.contactpassagerService.getData().subscribe(data => {
      this.contacts = data;
    });
  }

  finish() {

    this.budgetService.getBudgetRestant(this.reservation.tad.id, this.reservation.reservationDate).subscribe(data => {

      const budgetRestant = Number(data);
      if (budgetRestant < this.reservation.price) {
        showPoperError('Budget insuffisant ou non défini pour '+this.reservation.tad.nom);
      } else {
        const budgetRes = budgetRestant - this.reservation.price;
      //  alert(budgetRes)
        this.budgetService.updateBudgetRestant(this.reservation.tad.id, this.reservation.reservationDate, budgetRes).subscribe(data => {
          //alert(data)
        });
        this.canFinish = true;
        this.save();
        if(this.edited) {
          this.alertService.notify(this.reservation.id).subscribe();
        }
        this.route.navigateByUrl('reservations');

      }

    });

  }

  addPrestataire() {

    this.frmresatemplateComponent.show(this.reservation.id, this);

  }

  refreshtemplates() {

  }

  EditPassager(passager: any) {
    //  this.frminformationchauffeurComponent.show(passager,this);
  }

  getDroit(): void {

    this.securiteService.getDroit(this.user.profilCode, 'ACT_ADD_HEUREPASSAGER').subscribe(data => {
      // console.log(this.reservations)
      if (!!data) {
        this.canAddHeure = true;
      } else {
        this.canAddHeure = false;
        // alert(this.canCreateReservation)
      }

    });


  }


  getPrice(template) {

    console.log(this.distsRefs);
    let price = 0;
    const isFerie = this.checkFerie(this.reservation.reservationDate);
    console.log('Ferie = ' + isFerie);

    console.log(template);
    let distance = this.reservation.distance;
    console.log('reservation distance ' + distance);

    // tslint:disable-next-line:max-line-length
    const dateReservation = parse(this.reservation.reservationDate, 'd-MM-yyyy HH:mm', new Date());
    const reservationTime: string = dateReservation.getHours() + ':' + dateReservation.getMinutes() + ':' + '00';

    const heuredebutjour = parse(template.heuredebutjour, 'HH:mm:ss', new Date());
    heuredebutjour.setDate(dateReservation.getDate());
    heuredebutjour.setMonth(dateReservation.getMonth());
    heuredebutjour.setFullYear(dateReservation.getFullYear());

    const heuredebutnuit = parse(template.heuredebutnuit, 'HH:mm:ss', new Date());
    heuredebutnuit.setDate(dateReservation.getDate());
    heuredebutnuit.setMonth(dateReservation.getMonth());
    heuredebutnuit.setFullYear(dateReservation.getFullYear());

    const day: number = dateReservation.getDay();

    let tarif = template.tarifjour;
    let tarifs: any = [];

    if (isFerie && !!template.tarifferie) {
      tarifs.push(template.tarifferie);
    }

    if ((day === 0 && template.flagdimanche) || (day === 6 && template.flagsamedi)) {
      tarifs.push(template.tarifweekend);
    }

    if (dateReservation >= heuredebutjour && dateReservation < heuredebutnuit) {
      tarifs.push(template.tarifjour);
    } else {
      tarifs.push(template.tarifnuit);
    }

    console.log('tarifs ' + tarifs);

    tarif = tarifs.reduce((op, item) => op = op > item ? op : item, 0);


    console.log('tarif appliqué  ' + tarif);


    let additionnalDistance = 0;

    if ((template.flagapproche || template.flagretour) && !!template.pointref && template.pointref !== '') {
      const distanceRef = this.distsRefs['da' + template.id];
      console.log('Distance approche ' + distanceRef);

      const distRetour = this.distsRefs['dr' + template.id];
      console.log('Distance retour ' + distRetour);


      if (template.flagapproche) {
        additionnalDistance = (additionnalDistance) + (distanceRef);
        console.log('add distance  ' + additionnalDistance);
      }

      if (template.flagretour) {
        additionnalDistance = (additionnalDistance) + (distRetour);
        console.log('add distance  ' + additionnalDistance);
      }

    }

    distance += additionnalDistance;
    console.log(' distance  ' + distance);


    price = ((distance * tarif) > template.tarifmin) ? (distance * tarif) : template.tarifmin;


    let pricePec = 0;
    if (!!template.priseenncharge && template.priseenncharge > 0) {
      pricePec = (template.priseenncharge * (this.passagers.length));
    }


    price = (price + pricePec);
    return price;
  }

  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce passager !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.passagerService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.refreshPassengers();

        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car ce passager a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.passagerService.delete(id).subscribe(data => {
    //     this.refreshPassengers();
    //   });
    // }
  }

  setFlagHeure(e) {
    if (e.target.checked) {
      this.flagHeureReservation = 'ARRIVEE';
    } else {
      this.flagHeureReservation = 'DEPART';
    }

  }

  checkContact(typeConcact, contacts: any[]) {
    let hasContact = false;
    if (contacts.length > 0) {
      contacts.forEach(contact => {
        if (contact.type === typeConcact) {
          hasContact = true;
        }
      });
    }
    return hasContact;
  }

  viewDetailsTemplate(template) {
    this.detailstemplateprix.show(template);
  }

  async getDayFeries() {
    this.feriesDates = await this.reservationService.getDateFeries().toPromise();
  }

  checkFerie(date) {

    const dateReservation = parse(date, 'd-MM-yyyy HH:mm', new Date());
    const dateFormat: string = dateReservation.getFullYear() + '-' + ('0' + (dateReservation.getMonth() + 1)).slice(-2) + '-' + ('0' + dateReservation.getDate()).slice(-2);

    if (this.feriesDates.includes(dateFormat)) {
      return true;
    } else {
      return false;
    }


  }


  createCircuit() {
    this.frmCricuit.show(this.reservation, this, this.passagers, this.circuit);
  }


  caculateDistanceByCircuit(circuit: any) {
    this.spinner.show();
    this.circuit = circuit;
    const p = getDistanceByCircuit(circuit);
    p.then(response => {
      const dist = Math.round(response.routes[0].legs[0].distance.value / 1000);
      this.distance = dist;
      this.reservation.distance = dist;


      this.templates.forEach(temp => {
        this.prices[temp.id] = this.getPrice(temp);
      });

      this.spinner.hide();

    });
  }
}

