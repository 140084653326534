<div aria-hidden="true" class="modal fade modal-lg" id="modalDocument" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <span *ngIf="!!doc">{{doc.label}}</span>

        </h5>

        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>

      <div class="modal-body">



        <div *ngIf="!!doc">

          <img src="{{doc.path}}" />

        </div>





      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" data-dismiss="modal" type="button">
          Fermer
        </button>



      </div>
    </div>
  </div>
</div>
