import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServiceService} from "./service.service";
import {AppModule} from "../app.module";

@Injectable({
  providedIn: 'root'
})
export class ContactpassagerService extends ServiceService {

  httpx: HttpClient;

  constructor(http: HttpClient) {
    super(http, 'contactpassager');
    this.httpx = http;

  }

  getContact(passagerId: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/get/contacts/' + passagerId);
  }

  getContacts(reservadtionId: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/get/reservation/' + reservadtionId);
  }

  savePersonne(headers, body) {
    return this.httpx.post<any>(AppModule.API_ENDPOINT + 'personne' + '/create', body, { headers });
  }




}
