import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {AppModule} from '../../app.module';
import {StatutService} from '../../services/statut.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;


@Component({
  selector: 'app-statut',
  templateUrl: './statut.component.html',
  styleUrls: ['./statut.component.css']
})
export class StatutComponent implements OnInit {

  data: any = [];
  statut: any = null;

  code: any = '';
  libelle: any = '';
  description: any = '';
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;

  constructor(private http: HttpClient, private statutService: StatutService,private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.show();
  }

  show(): void {
    this.spinner.show();
     this.statutService.getData().subscribe(data => {
       this.data = data;
       this.statut = null;
       this.spinner.hide();
    });

  }
  tabSize(event) {
    this.page = event;
    //this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }
/*  save(): void {
    const headers = { };
    const body = { code: this.code, libelle: this.libelle , actif: true  };
    console.log(this.statut);
    if (!this.statut) {
      this.statutService.save(headers, body).subscribe(data => {
        this.show();
        $('#modalForm').modal('hide');
      });
    } else {
      this.statutService.update(headers, body, this.statut.id).subscribe(data => {
        this.show();
        $('#modalForm').modal('hide');
      });
    }
  }

  delete(id: any): void {
    if (confirm('Voulez vraiment supprimer cet élément ? ' )) {
      this.statutService.delete(id).subscribe(data => {
        this.show();
      });
    }
  }


  edit(id: any): void {
    this.statutService.get(id).subscribe(data => {
      this.statut = data;
      this.code = this.statut.code;
      this.libelle = this.statut.libelle;
      $('#modalForm').modal('show');
    });

  } */ 


}
