import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FrmbudgetComponent } from 'src/app/fonction/frmbudget/frmbudget.component';
import { BudgetService } from 'src/app/services/budget.service ';
import { StructureService } from 'src/app/services/structure.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-lstbudgettad',
  templateUrl: './lstbudgettad.component.html',
  styleUrls: ['./lstbudgettad.component.css']
})
export class LstbudgettadComponent implements OnInit {

  @ViewChild ('frmbudgetComponent') frmbudgetComponent: FrmbudgetComponent;
  constructor(private http: HttpClient,
    private structureService: StructureService, private budgetService: BudgetService,
    private authenticationService: AuthenticationService) { }
  databudgets: any = [];
  pensionnaire: any = null;
  collaborateur: any = null;
  budget: any = null;
  tad;
  user: User = new User();
  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.tad = this.user.structure;
    this.showBudget();
  }
  showBudget(): void {
    if(this.user.structure==0){
      this.budgetService.getData().subscribe(data => {
        this.databudgets = data;
        this.budget = null;
     });
    }else{
    this.budgetService. getByStructure(this.tad).subscribe(data => {
      this.databudgets = data;
      this.budget = null;
   });
  }
  }

  filter(parent,presta:any): void  {
    this.showBudget();
  }

  openFormBudget() {
    this.frmbudgetComponent.show(this);
  }

  delete(id: any): void {
    if (confirm('Voulez vraiment supprimer cet élément ? ' )) {
       this.budgetService.delete(id).subscribe(data => {
         this.showBudget();
       });
     }

  //    Swal.fire({
  //     title: 'Voulez vraiment supprimer?',
  //     text: 'Vous aller definitivement supprimer cet utilisateur!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'OUI, Supprimer!',
  //     cancelButtonText: 'NON, Garder'
  //   }).then((result) => {
  //     if (result.value) {
  //       Swal.fire(
  //         'Supprimer!',
  //         'L\'utilisateur a ete supprimé avec succes',
  //         'success',

  //       )
  //       this.budgetService.delete(id).subscribe(data => {
  //         this.showBudget();
  //       });
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire(
  //         'Annuler',
  //         'L\'utilisateur n\'a pas  été supprimé :)',
  //         'error'
  //       )
  //     }
  //   })
   }
   

 edit(id:any){
   this.frmbudgetComponent.edit(this, id);
 }



}
