import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceService} from './service.service';
import {AppModule} from '../app.module';
import {FoyerModel} from '../shared/models/foyer-model.model';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AlerteService extends ServiceService {

  httpx: HttpClient;
  module = 'alerts/';
  eventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(http: HttpClient) {
    super(http, 'alerts');
    this.httpx = http;
  }


  getTemplates() {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/templates');
  }

  getTemplatess(id: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/template/list/' + id);
  }

  saveTemplate(headers, body) {
    return this.httpx.post<any>(AppModule.API_ENDPOINT + this.mod + '/template/create', body, {headers});
  }

  getAlerts() {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/alerts');
  }

  getTypes() {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/types');
  }

  updateTemplate(headers, body, id: any) {
    return this.httpx.put(AppModule.API_ENDPOINT + this.mod + '/template/update/' + id, body, {headers});
  }

  deleteTemplate(id: any) {
    return this.httpx.delete(AppModule.API_ENDPOINT + this.mod + '/template/delete/' + id);
  }

  getAlertsBis() {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/alertsbis');
  }

  getAlertsByUser(usrId: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/alerts/user/' + usrId );
  }

  notify(id: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/alert/notify/' + id);
  }
}

