import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReservationService} from '../../services/reservation.service';
import {User} from '../../shared/session/user';
import {AuthenticationService} from '../../shared/services/authentication.service';
import { TypeNoteService } from 'src/app/services/typenote.service';
import Swal from 'sweetalert2';
import { UploadFilesService } from 'src/app/services/upload-files.service';
import { Observable } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { PfdViewerComponent } from './pfd-viewer/pfd-viewer.component';
declare var $: any;
declare function showFieldErrorRequired(id: any);

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit {
  @ViewChild('modalNote') modalNote;

  note: any = '';
  reservation: any;
  typeNote: any = 1;
  typeNotes: any = [];
  notes: any = [];
  user: User;
  errornote;
  test  = true;
  fichierAEnvoyer: File = null;
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  fileInfos?: Observable<any>;
  fichier: any = '';
  parent: any;
  typeFichier: any;


@ViewChild('pdfViewerComponent') pfdViewerComponent: PfdViewerComponent;

  constructor(
    private http: HttpClient,
    private typenoteService: TypeNoteService,
    private reservationService: ReservationService,
    private authenticationService: AuthenticationService,
    private uploadService: UploadFilesService
    ) {
    this.user = this.authenticationService.getUser();
  }

  ngOnInit(): void {

this.showTypeNote();

  }
  envoiFichier (fichiers: FileList) {
    this.fichierAEnvoyer = fichiers.item(0);
}


  showTypeNote(): void {
    this.typenoteService.getData().subscribe(data => {
      this.typeNotes = data;
   });

 }
reset() {
  this.note = null;
  this.typeNote = 1;
  this.progressInfos=null;
  this.selectedFiles=null;
}
fermer() {
  this.reset();
  $(this.modalNote.nativeElement).modal('hide');
}
saveNote(): void {
  //this.uploadFiles();
}
  save() {
    if (this.valideInput()) {
  //  alert(this.typeNote)
    const headers = {};
    const body = {reservation: {id: this.reservation.id}, typeNote: {id: this.typeNote}, 
    note: this.note, user: this.user};
    this.uploadFiles(body);
    this.reset();
    
    this.reservationService.emitNewNote();
    
  }
  }
  deleteNote(id:any) {
    Swal.fire({
      title: 'Voulez vraiment supprimer?',
      text: 'Vous aller definitivement supprimer cet element!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OUI, Supprimer!',
      cancelButtonText: 'NON, Garder'
    }).then((result) => {
      if (result.value) {
        this.reservationService.deleteNote(id).subscribe(data => {
          // this.reset();
           this.getNotes();
         });
        Swal.fire(
          'Supprimer!',
          'element a été supprimé avec succes',
          'success',

        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Annuler',
          'element n\'a pas  été supprimé :)',
          'error'
        )
      }
    })

  }
  show(reservation) {
    this.reservation = reservation;
    $(this.modalNote.nativeElement).modal('show');

    this.getNotes();


  }

  private getNotes() {

    this.reservationService.getNotesBis(this.reservation.id).subscribe(data => {
      this.notes = data;
    });

  }
  pushData() {

  }


  valideInput() {
    this.test = true;
    if (this.note == null || this.note.trim().length == 0) {
      this.errornote = true;
     // showFieldErrorRequired('note');
      this.test = false;
    } else {
      this.errornote = false;
    }
    return this.test;
  }

  uploadFiles(body:any): void {
 //   alert(body.reservation.id);
    this.message = [];
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i],body);
        
      }
    }
    
  }

  upload(idx: number, file: File,body:any): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
  
    if (file) {
      if (file.type === "application/pdf") {
        this.typeFichier = "pdf";
      }
      if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        this.typeFichier = "word";
      }
      if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        this.typeFichier = "excel";
      }
      this.uploadService.uploads(file,body).subscribe(
         (event: any) => {
          this.getNotes();
        //   if (event.type === HttpEventType.UploadProgress) {
        //     this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        //   } else if (event instanceof HttpResponse) {
        //     const msg = 'Uploaded the file successfully: ' + file.name;
        //     this.message.push(msg);
        //     this.fileInfos = this.uploadService.getFiles();
        //   }
        // },
        // (err: any) => {
        //   this.progressInfos[idx].value = 0;
        //   const msg = 'Could not upload the file: ' + file.name;
        //   this.message.push(msg);
        //   this.fileInfos = this.uploadService.getFiles();
        });
       // this.save();
    }
  }

  selectFiles(event): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
   // this.uploadFiles(null);
  }
  showPdf(url:any){
    this.pfdViewerComponent.show(url);
  }
}
