<div aria-hidden="true" class="modal fade modal-lg2" id="modalDetailsSimulation" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Détails simulation DQE
        </h5>

        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div>


          <div class="row">

            <div class="col-6">

              <div class="mt-1 mb-2">
                <span class="badge badge-info ml-n1">Filtre</span>
              </div>

              <div>
                <table class="table table table-striped-default table-borderless">
                  <tbody>


                  <tr>

                    <td class="text-95 text-600 text-secondary-d2">
                      Tad/service
                    </td>

                    <td class="text-dark-m3">
                      {{tad?.nom}}
                    </td>
                  </tr>

                  <tr>


                    <td class="text-95 text-600 text-secondary-d2">
                      Période
                    </td>

                    <td class="text-dark-m3">
                      Du <span>{{log?.startDate}}</span> au <span>
                      {{log?.endDate}}</span>
                    </td>
                  </tr>

                  <tr>
                    <td class="text-95 text-600 text-secondary-d2">
                      Template 1
                    </td>

                    <td class="text-blue-d1 text-wrap">
                      {{log?.template1}}
                    </td>
                  </tr>

                  <tr>
                    <td class="text-95 text-600 text-secondary-d2">
                      Template 2
                    </td>

                    <td class="text-blue-d1 text-wrap">
                      {{log?.template2}}
                    </td>
                  </tr>


                  </tbody>
                </table>
              </div>


            </div>

            <div class="col-6">

              <div class="mt-1 mb-2">
                <span class="badge badge-info ml-n1">Résultat globale</span>
              </div>

              <div>

                <table class="table table table-striped-default table-borderless">
                  <tbody>

                  <tr>
                    <td class="text-95 text-600 text-secondary-d2">
                      Total KM
                    </td>

                    <td class="text-dark-m3">
                      {{log?.totalKm}} KM
                    </td>
                  </tr>

                  <tr>

                    <td class="text-95 text-600 text-secondary-d2">
                      Total prix ref
                    </td>

                    <td class="text-dark-m3">
                      {{log?.totalPrice | number: '1.0-2': 'fr-FR'}} €
                    </td>
                  </tr>

                  <tr>
                    <td class="text-95 text-600 text-secondary-d2">
                      Total prix temp 1
                    </td>

                    <td class="text-blue-d1 text-wrap">
                      {{log?.totalPriceTemp1 | number: '1.0-2': 'fr-FR'}} €
                    </td>
                  </tr>


                  <tr>
                    <td class="text-95 text-600 text-secondary-d2">
                      Total prix temp 2
                    </td>

                    <td class="text-blue-d1 text-wrap">
                      {{log?.totalPriceTemp2 | number: '1.0-2': 'fr-FR'}} €
                    </td>
                  </tr>


                  </tbody>
                </table>
              </div>


            </div>


            <div class="col-12">

              <div class="mt-1 mb-2">
                <span class="badge badge-info ml-n1">Détails</span>
              </div>

              <table
                class="d-style w-100 table text-dark-m1 text-95 border-y-1 brc-black-tp11 collapsed dtr-table"
              >
                <thead class="text-secondary-m1 text-uppercase text-85">
                <tr>

                  <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
                    Enfant
                  </th>

                  <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
                    Départ
                  </th>

                  <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
                    Arrivée
                  </th>

                  <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
                    KM REF
                  </th>

                  <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
                    Prix REF
                  </th>

                  <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
                    KM Temp 1
                  </th>

                  <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
                    Prix Temp 1
                  </th>

                  <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
                    KM Temp 2
                  </th>

                  <th class="border-0 bgc-white bgc-h-yellow-l3 shadow-sm">
                    Prix Temp 2
                  </th>


                </tr>

                </thead>

                <tbody class="pos-rel">

                <tr *ngFor="let det of details" class="d-style bgc-h-default-l4">
                  <td>
                    {{det.enfant}}

                  </td>

                  <td class="text-grey">
                    {{det.depart}}
                  </td>

                  <td class="text-grey">
                    {{det.arivee}}
                  </td>

                  <td class="text-grey-d1">
                    {{  det.kmRef | number: '1.0-0':'fr-FR' }} KM
                  </td>

                  <td>
                    {{ det.prixRef | number: '1.0-2' : 'fr-FR' }} €
                  </td>

                  <td class="text-grey-d1">
                    {{ det.kmTemp1 | number: '1.0-0':'fr-FR' }} KM
                  </td>

                  <td>
                    {{ det.prixTemp1 | number: '1.0-2': 'fr-FR' }} €

                  </td>

                  <td class="text-grey-d1">
                    {{ det.kmTemp2 | number: '1.0-0':'fr-FR' }} KM
                  </td>

                  <td>
                    {{ det.prixTemp2 | number: '1.0-2': 'fr-FR' }} €

                  </td>


                </tr>

                </tbody>
              </table>


            </div>


          </div>


        </div>


      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" data-dismiss="modal" type="button">
          Fermer
        </button>


      </div>
    </div>
  </div>
</div>
