import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BudgetService } from 'src/app/services/budget.service ';
import { StructureService } from 'src/app/services/structure.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { Tool } from 'src/app/shared/tool';
import Swal from 'sweetalert2';
declare var $: any;
declare function createDateField();
@Component({
  selector: 'app-frmbudget',
  templateUrl: './frmbudget.component.html',
  styleUrls: ['./frmbudget.component.css']
})
export class FrmbudgetComponent implements OnInit {
  lblnom;
  parent;
  montant: any = 0;
  montantrestant: any = 0;
  montantConsome: any = 0;
  datedebut: any = '';
  datefin: any = '';
  tad: any = '';
  selecttad: any = null;
  budget: any = null;
  tads: any = [];
  taddetails;

  errortad = false;
  errordd = false;
  errordf = false;
  errorm = false;
  error = true;
  valideperiode;
  idbudget = 0;
  infoError = "Champs invalide";
  infoErrorm = "";
  isdetail= false;
  constructor(private http: HttpClient,
    private structureService: StructureService, private budgetService: BudgetService) { }

  ngOnInit(): void {
    createDateField();
    this.showTad();
  }

  showTad(): void {
    this.structureService.getStructuresByType(TypeStructureModel.TAD).subscribe(data => {
      this.tads = data;

    });

  }
  show(parent) {
    this.parent = parent;
    if (this.selecttad != null)
      $("#tad").attr("disabled", true);
    $('#modalBudget').modal('show');
  }

  pushData() {
    const headers = {};
    const body = {
      montant: this.montant, montantrestant: this.montantrestant , montantconsome: this.montantConsome, datedebut: this.datedebut,
      datefin: this.datefin, tad: { id: this.selecttad }, actif: true
    };
   // console.log(this.budget);
    if (!this.budget) {
      body.montantrestant = this.montant;
      body.montantconsome = 0.0;
      this.budgetService.save(headers, body).subscribe(data => {
     //   $('#modalBudget').modal('hide');
        this.parent.filter(null, this.taddetails);
        // this.parent.showBudget();
      });
   //   this.resetForm();
    } else {
     // alert("montantAmodifier ="+ this.montant)
     // body.montantrestant = this.montant;
      body.montantrestant = (this.montant - this.montantConsome);
      if(body.montantrestant >= 0){ 
      this.budgetService.update(headers, body, this.budget.id).subscribe(data => {
      //  $('#modalBudget').modal('hide');
        this.parent.filter(null, this.taddetails);
        //    this.parent.showBudget();
      });
    //  this.resetForm();
    }else{
      Swal.fire({ icon: 'error', text: "Attention! le budget consomé est supérieur au budget initial" });
    }
    }
    
  }
  taskDate(date) {
    var d = date.split('-');
    //d[2] = d[2] + ',';
 // alert(d[2]+"-"+d[1]+"-"+d[0]);
    return (d[2]+"-"+d[1]+"-"+d[0]);
}
  save(): void {
    this.errordd = true;
    this.errordf = true;
    this.errorm = true;
    if (this.valideInput()) {
      var date1 = this.taskDate(this.datedebut);
      var date2 = this.taskDate(this.datefin);
  //    alert(new Date(this.taskDate(this.datefin)).getTime() - new Date(this.taskDate(this.datedebut)).getTime());
      if (this.controleDate(this.datedebut, this.datefin) > 0) {
          
        this.budgetService.isValidePeriode(this.selecttad, date1, date2, this.idbudget).subscribe(data => {
          this.valideperiode = data;
          if (this.valideperiode) {
            this.pushData();
            this.fermer();
          } else { 
            Swal.fire({ icon: 'error', text: "Attention! chevauchement de budget." });
            // alert("Attention! chevauchement de budget")
          }
        });

      } else if (this.controleDate(this.datedebut, this.datefin) == 0) {
        Swal.fire({ icon: 'error', text: "Attention! Date début et date fin ne peuvent pas être égales." });
        // alert("date fin ne peut etre anterieur à la date debut")
      } else {
        Swal.fire({ icon: 'error', text: "Date fin ne peut être antérieur à la date début." });
        // alert("date fin ne peut etre anterieur à la date debut")
      }
    }
  //  this.resetForm();
  }
  controleDate(datedebut, datefin) {
    //var date1 = new 
    
    var timeDiff = new Date(this.taskDate(datefin)).getTime() - new Date(this.taskDate(datedebut)).getTime();
    return timeDiff;
  }
  fermer() {
    this.resetForm();
    $('#modalBudget').modal('hide');
  }
  resetForm() {
    //  this.selecttad = null
    $("#tad").attr("disabled", false);
    this.budget = null;
    this.datedebut = null;
    this.datefin = null;
    this.montant = null;
    if(!this.isdetail)
    this.selecttad = null;
    // this.selecttad = this.budget.tad.id;
    this.errortad = false;
    this.errordd = false;
    this.errordf = false;
    this.errorm = false;
  }
  edit(parent, id: any): void {
    this.parent = parent;
    this.budgetService.get(id).subscribe(data => {
      this.budget = data;
      this.datedebut = this.budget.datedebut;
      this.datefin = this.budget.datefin;
      this.montant = this.budget.montant;
      this.montantConsome = this.budget.montantconsome;
      this.selecttad = this.budget.tad.id;
      if (!!this.budget) {
        this.idbudget = this.budget.id;
        // alert(this.idbudget)
      }

      $('#modalBudget').modal('show');
    });

  }
  valideInput() {
    $('#datedebut')[0].dispatchEvent(new Event('input', {bubbles: true}));
    $('#datefin')[0].dispatchEvent(new Event('input', {bubbles: true}));
    this.error = true;
    var strtad = document.forms['myForm'].tad.value
    // this.tad.trim().length == 0
    if (!strtad.replace(/\s+/, '').length) {
      // document.getElementById("msgt").innerHTML="Champ obligatoir";
      this.errortad = true;
      this.error = false;
    } else {
      this.errortad = false;
      // this.error = true;
    }

    if (!document.forms['myForm'].datedebut.value.replace(/\s+/, '').length) {
      // document.getElementById("msgdd").innerHTML="Champ obligatoir";
      this.errordd = true;
      this.error = false;
    } else {
      this.errordd = false;
      //  this.error = true;
    }

    if (!document.forms['myForm'].datefin.value.replace(/\s+/, '').length) {
      //   document.getElementById("msgdf").innerHTML="Champ obligatoir";
      this.errordf = true;
      this.error = false;
    } else {
      this.errordf = false;
      //  this.error = true;
    }

    if (!document.forms['myForm'].montant.value.replace(/\s+/, '').length) {

      // isNaN(document.forms['myForm'].montant.value)
      //   document.getElementById("msgm").innerHTML="Champ obligatoir";
      // document.getElementById("msgm").rese
      this.errorm = true;
      this.error = false;
      this.infoErrorm = 'Champs obligatoire.'
    } else {
      if (Number(this.montant) < 0) {
        this.errorm = true;
        this.error = false;
        this.infoErrorm = 'Le montant ne peut pas être négatif.'
      } else {
        // alert('valide')
        this.errorm = false;
      }
    }
    return this.error;
  }

}
