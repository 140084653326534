import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class FonctionService {

  constructor(private http: HttpClient) { }

  getData() {
     return this.http.get(AppModule.API_ENDPOINT + 'fonction/list');
  }

  get(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'fonction/get/' + id);
  }

  save(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'fonction/create', body, { headers });
  }

  update(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + 'fonction/update/' + id, body, { headers });
  }


  delete(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + 'fonction/delete/' + id);
  }
}
