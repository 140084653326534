import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';
import {ServiceService} from './service.service';

@Injectable({
  providedIn: 'root'
})
export class MotifService extends  ServiceService {

  constructor(http: HttpClient) {
    super(http, 'motif');
  }


}
