import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FoyerService } from 'src/app/services/foyer.service';
import { StructureMotifService } from 'src/app/services/structuremotif.service ';
import { MotifService } from 'src/app/services/motif.service';
import { StructureService } from 'src/app/services/structure.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { ActeurService } from 'src/app/services/acteur.service';
import { User } from 'src/app/shared/session/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

declare var $: any;

@Component({
  selector: 'app-frmmotifcollectivite',
  templateUrl: './frmmotifcollectivite.component.html',
  styleUrls: ['./frmmotifcollectivite.component.css']
})
export class FrmmotifcollectiviteComponent implements OnInit {

  constructor(private http: HttpClient, private foyerService: FoyerService,
    private motifService: MotifService, private structuremotifService: StructureMotifService,
    private acteurService: ActeurService,
    private structureService: StructureService, private authenticationService: AuthenticationService) { }

  collectivites: any = [];
  collectivite: any;
  motifs: any = [];
  datamotifs: any = [];
  code: any = '';
  libelle: any = '';
  description: any = '';
  motif: any = null;
  motifcollectivie: any = null;
  parent;
  selectedstructures: any = [];
  error = false;
  test = true;
  selectedstructure;
  user: User = new User();


  ngOnInit(): void {

    //  this.user = this.authenticationService.getUser();
    // this.showMotif();
  }



  showMotif(): void {
    this.structuremotifService.getMotifNonRattaches(this.selectedstructure).subscribe(data => {
      this.datamotifs = data;
    });

  }
  showCollectivite(): void {
    this.structureService.getStructuresByType(TypeStructureModel.COLLECTIVITE).subscribe(data => {
      this.collectivites = data;

    });

  }


  saveMotifColl() {
    // if (this.valideInput()) {
    console.log(this.selectedstructures);
    if (this.code != '' && this.libelle != '')
      this.saveMotif();
    // alert(this.selectedstructures);
    for (const motif of this.selectedstructures) {

      console.log(this.selectedstructures);

      const headers = {};
      //  alert(this.selectedstructure)
      const body = { actif: 1, motif: { id: motif }, collectivite: { id: this.selectedstructure } }

      this.structuremotifService.save(headers, body).subscribe(data => {

      });

    }
    $('#modalForm').modal('hide');
    this.resetForm();
    this.parent.filter(null, this.collectivite);
    // }
  }

  fermer() {
    this.resetForm();
    $('#modalForm').modal('hide');
  }

  resetForm() {
    this.selectedstructures = [];
    this.code = '';
    this.libelle = '';
    this.description = '';
    this.error = false;
  }


  oncpChange(e) {
    if (e.target.checked) {
      //alert("ok");
      this.selectedstructures.push(e.target.value);
    } else {
      let i = 0;
      this.selectedstructures.forEach((selPens: any) => {
        if (selPens == e.target.value) {
          delete this.selectedstructures[i];
          return;
        }
        i++;
      });
    }

  }



  show(parent) {
    this.parent = parent;
    if (this.selectedstructure != null) {
      $("#stru").attr("disabled", true);
      this.showMotif();
    }
    this.showCollectivite();

    $('#modalForm').modal('show');
  }

  saveMotif(): void {
    const headers = {};
    const body = { code: this.code, libelle: this.libelle, actif: true };
    console.log(this.motif);
    if (!this.motif) {
      this.motifService.save(headers, body).subscribe(data => {
        this.motif = data;
        this.save();
        //  this.showMotifCollectivite();
        //  $('#modalForm').modal('hide');

      });
    } else {
      this.motifService.update(headers, body, this.motif.id).subscribe(data => {
        // this.showMotifCollectivite();
        // $('#modalForm').modal('hide');
      });
    }
  }
  pushData() {
    const headers = {};
    const body = { motif: this.motif, collectivite: { id: this.selectedstructure }, actif: true };
    console.log(this.motifcollectivie);
    if (!this.motifcollectivie) {
      this.structuremotifService.save(headers, body).subscribe(data => {
        //    this.motif=data;
        $('#modalForm').modal('hide');
        this.parent.filter(null, this.collectivite);
      });
    } else {
      this.structuremotifService.update(headers, body, this.motif.id).subscribe(data => {
        this.parent.filter(null, this.collectivite);
        $('#modalForm').modal('hide');
      });
    }
  }
  valideInput() {
    if (this.libelle.trim().length == 0) {
      this.error = true;
      this.test = false;
    } else {
      this.error = false;
      this.test = true;
    }
    // if (this.valideEmail() == true) {
    //   this.test = false;
    // }
    // if (this.valideTel() == true) {
    //   this.test = false;
    // }
    return this.test;
  }
  save(): void {

    this.pushData();

  }
  editMotif(parent, id: any): void {
    this.parent = parent;
    this.structuremotifService.get(id).subscribe(data => {
      this.motif = data;
      this.collectivite = this.motif.collectivite.id;
      this.code = this.motif.motif.code;
      this.libelle = this.motif.motif.libelle;
      $('#modalForm').modal('show');
    });

  }

}
