import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceService} from './service.service';
import {AppModule} from '../app.module';
import {FoyerModel} from '../shared/models/foyer-model.model';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SimulationService extends ServiceService {

  httpx: HttpClient;
  module = 'history/';
  eventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(http: HttpClient) {
    super(http, 'history');
    this.httpx = http;
  }


  getDetails(id: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/details/' + id);
  }

  saveDetails(headers, body) {
    return this.httpx.post<any>(AppModule.API_ENDPOINT + this.mod + '/details/create', body, {headers});
  }


}

