<div class="modal fade modal-ds " data-backdrop="static" #structureModal id="modalCollectiviteStructure" tabindex="-1"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{lblstructure}}
                </h5>

                <button type="button" (click)="resetForm()" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="card ccard">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label
                  text-sm-right pr-0">
                            <label class="mb-0">
                                {{collectiviteTad}} <label style="color:red">
                                    *
                                </label>
                            </label>
                        </div>

                        <div class="col-sm-9">
                            <select class="form-control struct" (change)="openSelect()" [(ngModel)]="selectedstructure"
                                name="selectedstructure" id="collectivite">
                                <option value="">&nbsp;</option>
                                <option *ngFor="let col of
                          collectivites;" value="{{col.id}}">{{col.nom}}</option>
                            </select><span style="color:rgb(168, 54, 45);font-size: small;"
                                *ngIf="errorSelectColect==true">
                                Champs obligatoire.
                            </span>
                        </div>
                    </div>
                   

                    <div class="modal-body">

                        <div class="">
                            <div class="">
        
                                <form class="mt-lg-3">
                                    
                                    <div class="row">
                                      <span *ngIf="errormessage" class="col-sm-8 col-md-6 mx-auto">
                                        <i class="fa fa-times text-center" style="color:red"> {{errormessage}}</i>
                                      </span>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Siren<label style="color:red">
                                                    *
                                                </label>
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input type="number"  [(ngModel)]="siret" name="siret" (change)="valideInput()"
                                                class="form-control col-sm-8 col-md-6 siren" (input)="searchEntrepriseFromSiren()" style="float: left"
                                                placeholder="exemple: 851621649" [ngClass]="{'error':error==true}" >
                                                <span class="fa-1x input-group-append" style="float: left; position: relative; left: -45px; top: 10px "
                                                 *ngIf="loadingsiren">
                                                  <i class="fas fa-spinner fa-spin"></i>
                                                </span>
                                                <span *ngIf="error==true" style="color: rgb(168, 54, 45);font-size: small;">
                                                    Le Champs nom est obligatoire
                                                </span>
                                        </div>
                                    </div>



                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Nom
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input readonly type="text" [(ngModel)]="nom"  size="4"
                                                name="nom" class="form-control col-sm-8 col-md-6">
                                          
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                      <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                          <label class="mb-0">
                                              Date Création
                                          </label>
                                      </div>

                                      <div class="col-sm-9">
                                          <input readonly class="form-control" type="text" [ngModel]="datecreation || '' | date:'dd/MM/yyyy'"
                                              name="datecreation" class="form-control col-sm-8 col-md-6" />
                                      </div>
                                  </div>

                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Adresse
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input readonly class="form-control" placeholder="Adresse" id="adresse"
                                                [(ngModel)]="adresse" name="adresse" />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Ville
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input readonly type="text" id="ville" [(ngModel)]="ville" name="ville"
                                                class="form-control col-sm-8 col-md-6">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Code Postal
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input readonly type="text" id="codePostal" name="codePostal"
                                                [(ngModel)]="codePostal" class="form-control col-sm-8 col-md-6">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Responsable
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input type="text" [(ngModel)]="responsable" name="responsable"
                                                class="form-control col-sm-8 col-md-6">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Email
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input (change)="valideEmail()" [ngClass]="{'error':errorEmail==true}"
                                                type="email" [(ngModel)]="email" name="email"
                                                class="form-control col-sm-8 col-md-6">
                                            <span *ngIf="errorEmail==true"
                                                style="color: rgb(168, 54, 45);font-size: small;">
                                                L'email est invalide.
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Téléphone fixe
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input type="text" [(ngModel)]="telephone" name="telephone"
                                                (change)="valideTelFixe()" [ngClass]="{'error':errorTelFixe==true}" class="form-control col-sm-8
                                                    col-md-6">
                                            <span *ngIf="errorTelFixe==true" style="color: rgb(168, 54,
                                                            45);font-size: small;">
                                                {{infoErrorTelFixe}}
                                            </span>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <!-- /.card-body -->
                        </div>
                    </div>
                </div>



            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="fermer()">
                    Fermer
                </button>
                <button type="button" class="btn btn-primary" (click)="savePrestaFoyer()">
                    Enregistrer
                </button>

            </div>
        </div>
    </div>
</div>
