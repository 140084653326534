import { Component, OnInit } from '@angular/core';
import {FrmreservationComponent} from "../frmreservation/frmreservation.component";
import {HttpClient} from "@angular/common/http";
import {PensionnaireService} from "../../services/pensionnaire.service";
import {PassagerService} from "../../services/passager.service";
import {TemplatePrixService} from "../../services/templatePrix.service";
declare var $: any;

@Component({
  selector: 'app-frmresatemplate',
  templateUrl: './frmresatemplate.component.html',
  styleUrls: ['./frmresatemplate.component.css']
})
export class FrmresatemplateComponent implements OnInit {

  hasMap: any = false;
  templates: any = [];
  selTemplates = [];
  reservation: any;
  frmReservation: FrmreservationComponent;


  constructor(private http: HttpClient, private pensionnaireService: PensionnaireService, private passagerService: PassagerService, private templateService: TemplatePrixService) {
    this.getTemplates();
  }

  ngOnInit(): void {





  }

  saveTemplate() {
    console.log(this.selTemplates);

    for (const tempate of this.selTemplates) {

      console.log(tempate);

      this.templateService.get(tempate).subscribe(data => {
        this.frmReservation.templates.push(data);
      });






    }

   // this.frmReservation.refreshPassengers();

    $('#modalFormTemplatePrixResa').modal('hide');


  }


  getTemplates() {
    this.templateService.getTemplates().subscribe(data => {
      this.templates = data;
    });
  }

  oncpChange(e) {

    if (e.target.checked) {
      this.selTemplates.push(e.target.value);
    } else {
      let i = 0;
      this.selTemplates.forEach((selTemp: any) => {
        if (selTemp == e.target.value) {
          delete this.selTemplates[i];
          return;
        }
        i++;
      });
    }

  }

  show(reservation: any, parent: FrmreservationComponent) {
    this.reservation  = reservation;
    this.frmReservation  = parent;
    this.selTemplates = [];


    this.getTemplates();


    $('#modalFormTemplatePrixResa').modal('show');


  }

}
