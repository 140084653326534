import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {AppModule} from '../../app.module';
import {MotifService} from '../../services/motif.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
import Swal from 'sweetalert2';


@Component({
  selector: 'app-motif',
  templateUrl: './motif.component.html',
  styleUrls: ['./motif.component.css']
})
export class MotifComponent implements OnInit {

  data: any = [];
  motif: any = null;

  code: any = '';
  libelle: any = '';
  description: any = '';
  pagesize=6;
  i;

  constructor(private http: HttpClient, private motifService: MotifService,private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.i = 0;
    this.show();
    this.activedesactive();
  }

  show(): void {
    this.spinner.show();
     this.motifService.getDataPage(this.i,this.pagesize).subscribe(data => {
       this.data = data;
       this.motif = null;
       this.spinner.hide();
    });
  }

  nextpage(): void{
   // alert(this.data.length)
    if (this.data.length >= this.pagesize)
      this.i = this.i + 1;
    this.motifService.getDataPage(this.i, this.pagesize).subscribe(data => {
      this.data = data;
      this.motif = null;
    });

    this.activedesactive();
  }

  previouspage(): void {
    if (this.i > 0)
      this.i = this.i - 1;
    this.motifService.getDataPage(this.i, this.pagesize).subscribe(data => {
      this.data = data;
      this.motif = null;
    });

    this.activedesactive();
  }

  activedesactive(): void {
    if (this.i == 0) {
      // alert();
      $("#prec").attr("disabled", true);
      $("#suiv").attr("disabled", false);
    }else{
      $("#prec").attr("disabled", false);
    }
  }

  save(): void {
    const headers = { };
    const body = { code: this.code, libelle: this.libelle , actif: true  };
    console.log(this.motif);
    if (!this.motif) {
      this.motifService.save(headers, body).subscribe(data => {
        this.show();
        $('#modalForm').modal('hide');
      });
    } else {
      this.motifService.update(headers, body, this.motif.id).subscribe(data => {
        this.show();
        $('#modalForm').modal('hide');
      });
    }
  }

  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.motifService.delete(id).subscribe(data => {
            Swal.fire({icon: 'success', text: 'Suppression réussie'});
            this.show();
          },
          err => {
            Swal.fire({
              icon: 'error',
              text: "La suppression a échoué, car ce motif  a déjà été utilisé pour une réservation"
            });
          }
        );
      }
    });
  }


  edit(id: any): void {
    this.motifService.get(id).subscribe(data => {
      this.motif = data;
      this.code = this.motif.code;
      this.libelle = this.motif.libelle;
      $('#modalForm').modal('show');
    });

  }


}
