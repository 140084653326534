import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceService} from './service.service';
import {AppModule} from "../app.module";

@Injectable({
  providedIn: 'root'
})
export class PassagerService extends  ServiceService {
   httpx: HttpClient;
  constructor(http: HttpClient) {
    super(http, 'passager');
    this.httpx = http;
  }
  

  getPassagers(resevationId: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/get/reservation/' + resevationId);
  }

  getFoyerPassager(passagerId: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/get/foyerpassager/' + passagerId);
  }

  getTadPassager(passagerId: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/get/tadpassager/' + passagerId);
  }

  duplicatePassagers(oldResevationId: any,newResevationId: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.mod + '/dupliquer/' + oldResevationId+ '/'+newResevationId);
  }

  delete(id: any) {
    return this.httpx.delete(AppModule.API_ENDPOINT + this.mod + '/delete/' + id);
  }
}
