import {Component, OnInit, ViewChild} from '@angular/core';
import {Demand} from "../../shared/classes/demand";
import {DossiersService} from "../../shared/services/dossiers.service";
import Swal from "sweetalert2";
import {NgxSpinnerService} from "ngx-spinner";
import {AppModule} from "../../app.module";
import {User} from "../../shared/session/user";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {DomSanitizer} from "@angular/platform-browser";
import {Famille} from "../../shared/classes/famille";

@Component({
  selector: 'app-lstaccords',
  templateUrl: './lstaccords.component.html',
  styleUrls: ['./lstaccords.component.css']
})
export class LstaccordsComponent implements OnInit {

  dossiers: Demand[] = [];
  dossier: any;
  documents = [];


  appServer = AppModule.APP_SERVER;
  apiEndPoint = AppModule.API_ENDPOINT_V2;
  user: User;

  apercu: any;

  famille: Famille[] = [];



  // @ts-ignore
  @ViewChild('viewDoc') viewDoc: ViewDocComponent;

  constructor(private dossiersService: DossiersService, private spinner: NgxSpinnerService, private authService: AuthenticationService , private saniter: DomSanitizer) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();


    // this.dossiers.push(this.dossier);

    this.getDossiers();
  }

  getDossiers() {
    this.spinner.show();
    this.dossiersService.getDossiersByStatutAndUser(this.user.id, 1).subscribe(dossiers => {
      // @ts-ignore
      this.dossiers = dossiers;

      this.spinner.hide();

    });
  }

  showDetails(dossier: Demand) {

    this.dossier = dossier;

   // this.apercu = this.saniter.bypassSecurityTrustResourceUrl(this.appServer + 'uploads/' +  this.dossier.demandeur.path);



  }

  valid(demand: any) {
    this.spinner.show();

    this.dossiersService.validate(demand.id, 3).subscribe(dossier => {
      this.getDossiers();

      this.spinner.hide();
      Swal.fire({icon: 'success', text: 'Demande validée'});

      this.dossier = null;

    });

  }


  reject(demand: any) {

    Swal.fire({
      title: 'Confirmation',
      text: 'Etes vous sûr de vouloir rejeter cette souscription ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Rejeter!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        this.dossiersService.validate(demand.id, 5).subscribe(dossier => {
          this.getDossiers();

          this.spinner.hide();
          Swal.fire({ icon: 'success', text: 'Souscription rejetée' });

          this.dossier = null;

        });
      }
    });



  }

  viewDocument(doc) {
    this.viewDoc.show(doc);
  }

}
