import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {User} from "../../shared/session/user";
import {AppModule} from "../../app.module";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public credentials: User  = new User();
  public formError: string = '';


  constructor(private router: Router,
              private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }


  public onLoginSubmit(): void {
    this.formError = '';
    if (!this.credentials.login) {
      this.formError = 'Veuillez saisir votre email';
    } else  if (!this.credentials.password) {
      this.formError = 'Veuillez saisir votre mot de passe';
    } else {
      this.doLogin();
    }
  }

  private doLogin(): void {
    this.authenticationService.login(this.credentials)
      .then( () => this.goHome() )
      .catch( (message) => {
        this.formError = 'Login ou mot de passe incorrect';
      });
  }

  goHome() {
    // this.router.navigateByUrl('/accueil');
    window.location.href = AppModule.APP_HOME ;
  }

}
