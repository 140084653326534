export enum TypeActeurModel {
    COLLABORATEUR='COLLABORATEUR',
    PERSONNEL='PERSONNEL',
    PENSIONNAIRE='PENSIONNAIRE',
    CONTACT='CONTACT',
    CHAUFFEUR='CHAUFFEUR',
    AFFECTER='AFFECTER',
    ADMIN='ADMIN'
    
}
