<div class="modal fade modal-ds" id="modalFormFerie" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Ferie
        </h5>

        <button type="button" class="close"  (click)="hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="">
          <div class="">

            <form class="mt-lg-3 " novalidate>

              <div class="form-group row">
                <div class="col-4 col-form-label
                                    text-sm-right pr-0">
                  <label class="mb-0">
                    Date <label
                    style="color:red">
                    *
                  </label>
                  </label>
                </div>

                <div class="col-8">
                  <span  class="badge badge-info">{{dateFerie}}</span>
                </div>

              </div>
              <div class="form-group row">
                <div class="col-4 col-form-label
                                    text-sm-right pr-0">
                  <label class="mb-0">
                    Libellé <label
                    style="color:red">
                    *
                  </label>
                  </label>
                </div>

                <div class="col-8">
                  <input type="text" [(ngModel)]="libelle" name="libelle" class="form-control col-10">

                </div>

              </div>

            </form>
          </div>
          <!-- /.card-body -->
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()" *ngIf="!!this.ferie">
          Supprimer
        </button>
        <button type="button" class="btn btn-secondary" (click)="hide()">
          Fermer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()">
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>
