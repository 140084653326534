import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import {Tool} from '../../shared/tool';
import {parse} from "date-fns";

declare var $: any;

@Component({
  selector: 'app-bon',
  templateUrl: './bon.component.html',
  styleUrls: ['./bon.component.css']
})
export class BonComponent implements OnInit {

  @ViewChild('bon') htmlData: ElementRef;


  reservation: any;
  price: any;
  pricePec: any;
  tarif: any;
  total: any;
  tarifApplique: any;


  constructor(public tool: Tool) {
  }

  ngOnInit(): void {

  }

  show(reservation) {
    this.reservation = reservation;
    if (!!this.reservation.templatePrix) {
      this.tarif = this.getTarifApplique(this.reservation.templatePrix);
      this.price = this.getPrice(this.reservation.templatePrix);
      this.tarifApplique = this.getCoutTarifApplique(this.reservation.templatePrix);
      this.pricePec = this.getPricePriseEnCharge(this.reservation.templatePrix);
      this.total = this.price + this.pricePec;
    }


    $('#modalBc').modal('show');
  }


  public createPdf() {
    const DATA = document.getElementById('bon');

    (html2canvas as any)(DATA).then(canvas => {

      const fileWidth = 208;
      const fileHeight = canvas.height * fileWidth / canvas.width;

      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);

      PDF.save('bon-commande.pdf');
    });
  }

  print() {

  }

  getPrice(template) {
    let price = 0;
    // tslint:disable-next-line:max-line-length
    const dateReservation = parse(this.reservation.reservationDate, 'd-MM-yyyy HH:mm', new Date());
    const reservationTime: string = dateReservation.getHours() + ':' + dateReservation.getMinutes() + ':' + '00';

    const day: number = dateReservation.getDay();

    // if (template.flagapproche && !!template.pointref && template.pointref !==  '' ) {

    // }

    if ((day === 0 && template.flagdimanche) || (day === 6 && template.flagsamedi)) {
      // tslint:disable-next-line:max-line-length
      price = ((this.reservation.distance * template.tarifweekend) > template.tarifmin) ? (this.reservation.distance * template.tarifweekend) : template.tarifmin;
    } else if (reservationTime >= template.heuredebutjour && reservationTime < template.heuredebutnuit) {
      // tslint:disable-next-line:max-line-length
      price = ((this.reservation.distance * template.tarifjour) > template.tarifmin) ? (this.reservation.distance * template.tarifjour) : template.tarifmin;
    } else {
      // tslint:disable-next-line:max-line-length
      price = ((this.reservation.distance * template.tarifnuit) > template.tarifmin) ? (this.reservation.distance * template.tarifnuit) : template.tarifmin;

    }


    return price;
  }

  getTarifApplique(template) {
    let tarif = 'Tarif';
    // tslint:disable-next-line:max-line-length
    const dateReservation = parse(this.reservation.reservationDate, 'd-MM-yyyy HH:mm', new Date());
    const reservationTime: string = dateReservation.getHours() + ':' + dateReservation.getMinutes() + ':' + '00';

    const day: number = dateReservation.getDay();

    // if (template.flagapproche && !!template.pointref && template.pointref !==  '' ) {

    // }

    if ((day === 0 && template.flagdimanche) || (day === 6 && template.flagsamedi)) {
      // tslint:disable-next-line:max-line-length
      tarif = 'Tarif Weekend (KM)';
    } else if (reservationTime >= template.heuredebutjour && reservationTime < template.heuredebutnuit) {
      // tslint:disable-next-line:max-line-length
      tarif = 'Tarif Jour (KM)';
    } else {
      // tslint:disable-next-line:max-line-length
      tarif = 'Tarif Nuit (KM)';

    }

    return tarif;
  }


  getCoutTarifApplique(template) {
    let tarif = 0;
    // tslint:disable-next-line:max-line-length
    const dateReservation = parse(this.reservation.reservationDate, 'd-MM-yyyy HH:mm', new Date());
    const reservationTime: string = dateReservation.getHours() + ':' + dateReservation.getMinutes() + ':' + '00';

    const day: number = dateReservation.getDay();

    // if (template.flagapproche && !!template.pointref && template.pointref !==  '' ) {

    // }
    if ((day === 0 && template.flagdimanche) || (day === 6 && template.flagsamedi)) {
      // tslint:disable-next-line:max-line-length
      tarif = template.tarifweekend;
    } else if (reservationTime >= template.heuredebutjour && reservationTime < template.heuredebutnuit) {
      // tslint:disable-next-line:max-line-length
      tarif = template.tarifjour;
    } else {
      // tslint:disable-next-line:max-line-length
      tarif = template.tarifnuit

    }

    return tarif;
  }

  getPricePriseEnCharge(template) {
    let price = 0;


    if (!!template.priseenncharge && template.priseenncharge > 0 && this.reservation.passagers.length > 1) {
      price = (template.priseenncharge * (this.reservation.passagers.length - 1));
    }

    return price;
  }
}
