import {Component, OnInit} from '@angular/core';
import {User} from '../../shared/session/user';
import {CalendarOptions} from '@fullcalendar/angular';
import {HttpClient} from '@angular/common/http';
import {ReservationService} from '../../services/reservation.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {TemplatePrixService} from '../../services/templatePrix.service';
import {StructureService} from '../../services/structure.service';
import {TypeStructureModel} from '../../shared/models/type-structure-model.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {parse} from 'date-fns';
import {SimulationService} from '../../services/simulation.service';
declare function showPoperError(message: any);

declare var $: any;

declare function createDateField();

declare function createPastDateField();

declare function createTimeField();

declare function createSimulationTables();

declare function getDistance(depart: any, arrivee: any);


@Component({
  selector: 'app-lstsimulation',
  templateUrl: './lstsimulation.component.html',
  styleUrls: ['./lstsimulation.component.css']
})
export class LstsimulationComponent implements OnInit {

  structure: any;
  reservations = [];
  user: User;
  events: any = [];
  templatesPrix: any = [];
  tads: any = [];
  startDate: any;
  endDate: any;
  template1: any = 0;
  template2: any = 0;

  templatePrix1: any;
  templatePrix2: any;
  show = false;
  prixReference = 0;
  prixReference1 = 0;
  prixReference2 = 0;
  kmGlobal = 0;
  tadSelected = 0;
  feriesDates: any = [];
  prices: any = [];
  distances: any = [];
  prixRef: any;
  tad: any;
  logs: any[] = [];
  queries : 0;

  constructor(private http: HttpClient, private reservationService: ReservationService,
              private authenticationService: AuthenticationService, private templatePrixService: TemplatePrixService,
              private structureService: StructureService, private spinner: NgxSpinnerService, private simulationService: SimulationService) {
  }

  ngOnInit(): void {
    createPastDateField();
    this.user = this.authenticationService.getUser();
    this.structure = this.user.structure;
    this.getTads();
    this.getTemplates();
    this.getDayFeries();

    // this.getReservations();
  }

  getReservations() {
    this.reservations = [];
    this.prixReference = 0;
    this.prixReference1 = 0;
    this.prixReference2 = 0;
    this.kmGlobal = 0;
    this.queries = 0
    $('#startDate')[0].dispatchEvent(new Event('input', {bubbles: true}));
    $('#endDate')[0].dispatchEvent(new Event('input', {bubbles: true}));

    if (!this.tadSelected || !this.startDate || !this.endDate || !this.templatePrix1 ) {
      showPoperError('Vous devez renseigner tous les éléments du filtre');
      return false;
    }

    this.spinner.show();
    this.show = true;


    this.getTad(this.tadSelected);


    this.reservationService.getTerminatedByPeriod(this.tadSelected, this.startDate, this.endDate).subscribe(data => {
      // @ts-ignore
      this.reservations = data;
      if (!!this.reservations && this.reservations.length > 0) {
        this.calculateGobals();
      } else {
        this.render();
        this.spinner.hide();
      }
    });


  }


  render() {
    setTimeout(() => {
      createSimulationTables();
    }, 1000);

  }

  setTemplate1() {
    if (this.template1 !== '0') {
      this.spinner.show();
      this.templatePrixService.get(this.template1).subscribe(template => {
        this.templatePrix1 = template;
        this.spinner.hide();
      });
    } else {
      this.templatePrix1 = null;
    }
  }

  setTemplate2() {
    if (this.template2 !== '0') {
      this.spinner.show();
      this.templatePrixService.get(this.template2).subscribe(template => {
        this.templatePrix2 = template;
        this.spinner.hide();
      });
    } else {
      this.templatePrix2 = null;
    }
  }



  setPrixReference(prix) {
    if (!!prix) {
      this.prixReference = Number(this.prixReference) + Number(prix);
    }
  }

  setPrixReference1(prix) {
    if (!!prix && (Number(prix).toString() !== 'NaN')) {
      this.prixReference1 = Number(this.prixReference1) + Number(prix);
    }
  }

  setPrixReference2(prix) {
    if (!!prix && (Number(prix).toString() !== 'NaN')) {
      this.prixReference2 = Number(this.prixReference2) + Number(prix);
    }
  }


  setKmGlobal(km) {
    if (!!km) {
      this.kmGlobal = Number(this.kmGlobal) + Number(km);
    }
  }

  calculateGobals() {
    const nbr = this.reservations.length;
    let i = 1;
    this.reservations.forEach(reservation => {

      let distApproche = 0;
      let distRetour = 0;

      let distApproche1 = 0;
      let distRetour1 = 0;

      let distApproche2 = 0;
      let distRetour2 = 0;
      console.log('reservation ' + reservation.id);

      console.log('template ' + reservation.templatePrix);

      const p = getDistance(reservation.adresseDepart, reservation.templatePrix.pointref);
      p.then(response => {
        distApproche = Math.round(response.routes[0].legs[0].distance.value / 1000);
      });

      const p2 = getDistance(reservation.adresseArrivee, reservation.templatePrix.pointref);
      p2.then(response => {
        distRetour = Math.round(response.routes[0].legs[0].distance.value / 1000);
      });

      if(this.templatePrix1.flagapproche) {
        this.queries++;
        const pt11 = getDistance(reservation.adresseDepart, this.templatePrix1.pointref);
        pt11.then(response => {
          distApproche1 = Math.round(response.routes[0].legs[0].distance.value / 1000);
        });
      }

      if(this.templatePrix1.flagretour) {
        this.queries++;
        const pt12 = getDistance(reservation.adresseArrivee, this.templatePrix1.pointref);
        pt12.then(response => {
          distRetour1 = Math.round(response.routes[0].legs[0].distance.value / 1000);
        });
      }

      if(this.templatePrix2?.flagapproche) {
        this.queries++;
        const pt21 = getDistance(reservation.adresseDepart, this.templatePrix2.pointref);
        pt21.then(response => {
          distApproche2 = Math.round(response.routes[0].legs[0].distance.value / 1000);
        });
      }

      if(this.templatePrix2?.flagretour) {
        this.queries++;

        const pt22 = getDistance(reservation.adresseArrivee, this.templatePrix2.pointref);
        pt22.then(response => {
          distRetour2 = Math.round(response.routes[0].legs[0].distance.value / 1000);
        });
      }


      setTimeout(() => {

        const distance = (reservation.distance) + (distApproche) + (distRetour);
        const distance1 = (reservation.distance) + (distApproche1) + (distRetour1);
        const distance2 = (reservation.distance) + (distApproche2) + (distRetour2);


        const detailsPrice = [this.getPrice(reservation.templatePrix, reservation, distance),
          this.getPrice(this.templatePrix1, reservation, distance1), this.getPrice(this.templatePrix2, reservation, distance2)];
        this.prices[reservation.id] = detailsPrice;

        this.distances[reservation.id] = [distance, distance1, distance2];

        this.setKmGlobal(distance);

        this.setPrixReference(this.prices[reservation.id][0]);
        this.setPrixReference1(this.prices[reservation.id][1]);
        this.setPrixReference2(this.prices[reservation.id][2]);

        this.logs.push({
          enfant: reservation.passagers[0]?.acteur?.personne?.prenom + ' ' + reservation.passagers[0]?.acteur?.personne?.nom,
          depart: reservation.adresseDepart,
          arivee: reservation.adresseArrivee,
          kmRef: distance,
          prixRef: this.prices[reservation.id][0],
          kmTemp1: distance1,
          prixTemp1: this.prices[reservation.id][1],
          kmTemp2: distance2,
          prixTemp2: this.prices[reservation.id][2],
          actif: true,
          historique: {id: null}
        });


        if (i == nbr) {
          this.render();

          this.spinner.hide();

          this.log();
        }

        i++;

        console.log(this.logs);
      }, 2000);


    });


  }

  async getDayFeries() {
    this.feriesDates = await this.reservationService.getDateFeries().toPromise();
  }

  async getTad(id) {
    this.tad = await this.structureService.get(id).toPromise();
  }

  checkFerie(date) {

    const dateReservation = parse(date, 'd-MM-yyyy HH:mm', new Date());
    const dateFormat: string = dateReservation.getFullYear() + '-' + ('0' + (dateReservation.getMonth() + 1)).slice(-2) + '-' + ('0' + dateReservation.getDate()).slice(-2);

    if (this.feriesDates.includes(dateFormat)) {
      return true;
    } else {
      return false;
    }


  }

  getPrice(template, reservation, distance) {

    let price = 0;
    if(!template)
      return price;

    const isFerie = this.checkFerie(reservation.reservationDate);
    console.log('Ferie = ' + isFerie);

    console.log(template);
    console.log('reservation distance ' + distance);

    // tslint:disable-next-line:max-line-length
    const dateReservation = parse(reservation.reservationDate, 'd-MM-yyyy HH:mm', new Date());
    const reservationTime: string = dateReservation.getHours() + ':' + dateReservation.getMinutes() + ':' + '00';

    const heuredebutjour = parse(template.heuredebutjour, 'HH:mm:ss', new Date());
    heuredebutjour.setDate(dateReservation.getDate());
    heuredebutjour.setMonth(dateReservation.getMonth());
    heuredebutjour.setFullYear(dateReservation.getFullYear());

    const heuredebutnuit = parse(template.heuredebutnuit, 'HH:mm:ss', new Date());
    heuredebutnuit.setDate(dateReservation.getDate());
    heuredebutnuit.setMonth(dateReservation.getMonth());
    heuredebutnuit.setFullYear(dateReservation.getFullYear());

    const day: number = dateReservation.getDay();

    let tarif = template.tarifjour;
    const tarifs: any = [];

    if (isFerie && !!template.tarifferie) {
      tarifs.push(template.tarifferie);
    }

    if ((day === 0 && template.flagdimanche) || (day === 6 && template.flagsamedi)) {
      tarifs.push(template.tarifweekend);
    }

    if (dateReservation >= heuredebutjour && dateReservation < heuredebutnuit) {
      tarifs.push(template.tarifjour);
    } else {
      tarifs.push(template.tarifnuit);
    }

    console.log('tarifs ' + tarifs);

    tarif = tarifs.reduce((op, item) => op = op > item ? op : item, 0);


    console.log('tarif appliqué  ' + tarif);


    console.log(' distance  ' + distance);


    price = ((distance * tarif) > template.tarifmin) ? (distance * tarif) : template.tarifmin;


    let pricePec = 0;
    if (!!template.priseenncharge && template.priseenncharge > 0) {
      pricePec = (template.priseenncharge * (reservation.passagers.length));
    }


    price = (price + pricePec);
    return price;
  }

  log() {
    const headers = {};
    const body = {
      actif: true,
      simulationDate: new Date(),
      simulationTime: new Date(),
      queries: this.queries,
      tad: this.tadSelected,
      collectivite: {id: this.tad?.collectivite?.id},
      startDate: this.startDate,
      endDate: this.endDate,
      template1: this.templatePrix1.libelle,
      template2: this.templatePrix2?.libelle,
      totalKm: this.kmGlobal,
      totalPrice: this.prixReference,
      temp1Km: null,
      totalPriceTemp1: this.prixReference1,
      temp2Km: null,
      totalPriceTemp2: this.prixReference2,
      lignes: this.reservations.length
    };
    console.log(body);

    this.simulationService.save(headers, body).subscribe(hist => {
      console.log(hist);
      this.logs.forEach(log => {
        let bodyLog = {
          enfant: log.enfant,
          depart: log.depart,
          arivee: log.arivee,
          kmRef: log.kmRef,
          prixRef: log.prixRef,
          kmTemp1: log.kmTemp1,
          prixTemp1: log.prixTemp1,
          kmTemp2: log.kmTemp2,
          prixTemp2: log.prixTemp2,
          actif: true,
          historique: {id: hist.id}
        };

        this.simulationService.saveDetails(headers, bodyLog).subscribe(logged => {
          console.log("LOgged details " + logged.id);
        });

      });
    });


  }

  private getTemplates() {

    this.templatePrixService.getTemplatesSimulation(this.user.structure).subscribe(templates => {
      this.templatesPrix = templates;
    });

  }

  private getTads() {
    this.structureService.getTadByProfil(this.user.id).subscribe(tads => {
      this.tads = tads;
    });
  }
}
