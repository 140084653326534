import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { StructureService } from 'src/app/services/structure.service';
declare var $: any;

declare function stransAdresse(id: any);
@Component({
  selector: 'app-frmfoyers',
  templateUrl: './frmfoyers.component.html',
  styleUrls: ['./frmfoyers.component.css']
})
export class FrmfoyersComponent implements OnInit {
  lblnom;
  data: any = [];
  dataprestatire: any = [];
  dataprestatires: any = [];
  datamotifs: any = [];
  datafoyers: any = [];
  collaborateurs: any = [];
  contacts: any = [];
  motifs: any = [];
  datafoyer: any = [];
  datacollectivte: any = [];
  datacol: any = [];
  dataville: any = [];
  datatype: any = [];
  structure: any = null;
  collaborateur: any = null;
  contact: any = null;
  motif: any = null;
  siret: any = '';
  nom: any = '';
  ville: any = '';
  codePostal: any = '';
  adresse: any = '';
  telephone: any = '';
  email: any = '';
  typestructure: any = ['FOYER', 'FAMILLEACCUEIL'];
  /////////////////////////////////
  struct: any = '';
  /////////////////////////////////
  responsable: any = '';
  collectivite: any = '';
  parent;
  errornom = false;
  error = true;

  constructor(private http: HttpClient, private structureService: StructureService) { }

  ngOnInit(): void {

    stransAdresse('adresse');
  }

  show(parent) {
    this.parent = parent;
    $('#modalFoyer').modal('show');
  }

  valideInput() {
    this.error = true;
    if (!document.forms['myForm'].nom.value.replace(/\s+/, '').length) {
      // document.getElementById("msgdd").innerHTML="Champ obligatoir";
      this.errornom = true;
      this.error = false;
    } else {
      this.errornom = false;
      // this.error = true;
    }
    return this.error;
  }


  save(): void {
    this.errornom = true;
    //this.errorTel = true;
    if (this.valideInput()) {

      $('#adresse')[0].dispatchEvent(new Event('input', { bubbles: true }));
      $('#ville')[0].dispatchEvent(new Event('input', { bubbles: true }));
      $('#codePostal')[0].dispatchEvent(new Event('input', { bubbles: true }));
      const headers = {};
      const body = {
        siret: this.siret, nom: this.nom,
        adresse: this.adresse, telephone: this.telephone,
        responsable: this.responsable, ville: this.ville, codePostal: this.codePostal,
        email: this.email, typestructure: this.struct, actif: true
      };
      console.log(this.structure);
      if (!this.structure) {
        // alert(!this.structure)
        this.structureService.save(headers, body).subscribe(data => {
          // alert('added')
          $('#modalFoyer').modal('hide');
          this.parent.show();
        });
      } else {
        this.structureService.update(headers, body, this.structure.id).subscribe(data => {
          // alert('udated')
          $('#modalFoyer').modal('hide');
          this.parent.show();
        });
      }
      this.fermer();
    }
  }
  pushFoyer() {


  }

  edit(parent, id: any): void {
    this.parent = parent;
    this.structureService.get(id).subscribe(data => {
      this.structure = data;
      if (this.structure) {
        this.siret = this.structure.siret;
        this.nom = this.structure.nom;
        this.adresse = this.structure.adresse;
        this.responsable = this.structure.responsable;
        this.email = this.structure.email;
        this.ville = this.structure.ville;
        this.telephone = this.structure.telephone;
        this.codePostal = this.structure.codePostal;
        this.struct = this.structure.typestructure;
        //this.typestructure = this.structure.typestructure;
      }
      $('#modalFoyer').modal('show');
      // alert(this.typestructure)
    });

  }

  resetForm() {
    this.structure = null;
    this.siret = null;
    this.nom = null;
    this.adresse = null;
    this.responsable = null;
    this.email = null;
    this.telephone = null;
    this.ville = null;
    this.codePostal = null;
    // this.typestructure = null;
    this.errornom = false;
    this.error = false;
    this.typestructure = ['FOYER', 'FAMILLEACCUEIL'];
    this.struct = null;
  }

  fermer() {
    $('#modalFoyer').modal('hide');
    this.resetForm();
  }
}
