<div class="page" *ngIf="rt.url != '/login'">

  <div class="body-container">
    <nav class="navbar navbar-expand-lg navbar-fixed navbar-skyblue">
      <div class="navbar-inner">

        <div class="navbar-intro justify-content-xl-between">

          <button aria-controls="sidebar" aria-expanded="false"
                  aria-label="Toggle sidebar"
                  class="btn btn-burger burger-arrowed static collapsed ml-2 d-flex d-xl-none" data-target="#sidebar"
                  data-toggle-mobile="sidebar"
                  type="button">
            <span class="bars"></span>
          </button><!-- mobile sidebar toggler button -->

          <a class="navbar-brand text-white" routerLink="/accueil">
            <img height="60px" src="assets/images/logo2.png">

            <span style="color: #fff; font-size: 1.2rem"> ASKIA-TAKAFUL</span>
          </a><!-- .navbar-brand -->

          <button type="button" class="btn btn-burger mr-2 d-none d-xl-flex" data-toggle="sidebar"
                  data-target="#sidebar" aria-controls="sidebar" aria-expanded="true" aria-label="Toggle sidebar">
            <span class="bars"></span>
          </button><!-- sidebar toggler button -->

        </div><!-- .navbar-intro -->


        <div class="navbar-content text-light ml-5" >
          <span *ngIf="!!user?.consulat"> Consulat :
            <span class="text-700">{{user?.consulat?.nom}}</span> &nbsp;  <img src="assets/images/flags/{{user?.consulat?.pays?.code}}.png" height="35" /> </span>

        </div><!-- .navbar-content -->


        <!-- mobile #navbarMenu toggler button -->
        <button class="navbar-toggler ml-1 mr-2 px-1" type="button" data-toggle="collapse" data-target="#navbarMenu"
                aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navbar menu">
            <span class="pos-rel">
                  <img class="border-2 brc-white-tp1 radius-round" width="36" src="/assets/image/avatar/avatar6.jpg"
                       alt="Jason's Photo">
                  <span class="bgc-warning radius-round border-2 brc-white p-1 position-tr mr-1px mt-1px"></span>
            </span>
        </button>


        <div class="navbar-menu collapse navbar-collapse navbar-backdrop" id="navbarMenu">

          <div class="navbar-nav">
            <ul class="nav border-0 has-active-border">

              <li class="nav-item dropdown dropdown-mega d-none">
                <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                  <i class="fa fa-list-alt mr-2 d-lg-none"></i>
                  Mega
                  <i class="caret fa fa-angle-down d-none d-lg-block"></i>
                  <i class="caret fa fa-angle-left d-block d-lg-none"></i>
                </a>
                <div class="p-0 dropdown-menu dropdown-animated bgc-default-l4 brc-primary-m2 border-t-0 border-b-2 ace-scrollbar">
                  <div class="d-flex flex-column">

                    <div class="row mx-0">

                      <div class="col-lg-4 col-12 p-2 p-lg-3 p-xl-4 d-flex flex-column align-items-center">
                        <div class="w-100 mb-3">
                          <h5 class="col-lg-9 mx-auto text-dark-m2 px-0">
                            <i class="fa fa-clipboard-check mr-1 text-purple-m1"></i>
                            Current Tasks
                          </h5>
                        </div>

                        <div class="col-lg-9 list-group px-0 border-1 brc-default-l2 radius-1 shadow-md">
                          <a href="#" class="border-0 bgc-h-primary-l4 list-group-item list-group-item-action">
                            <i class="fab fa-facebook text-blue-m1 text-110 mr-2"></i>
                            Cras justo odio
                          </a>
                          <a href="#" class="border-0 bgc-h-primary-l4 list-group-item list-group-item-action text-primary">
                            <i class="fa fa-user text-success-m1 text-110 mr-2"></i>
                            Dapibus ac facilisis in
                          </a>
                          <a href="#" class="border-0 bgc-h-primary-l4 list-group-item list-group-item-action">
                            <i class="fa fa-clock text-purple-m1 text-110 mr-2"></i>
                            Morbi leo risus
                          </a>
                          <a href="#" class="border-0 list-group-item list-group-item-action bgc-success-l2">
                            <i class="fa fa-check text-orange-d1 text-110 mr-2"></i>
                            Porta ac consectetur
                            <span class="ml-2 badge badge-primary badge-pill badge-lg">14</span>
                          </a>
                          <a href="#" class="border-0 list-group-item list-group-item-action disabled">Vestibulum at eros</a>
                        </div>
                      </div><!-- .col:mega tasks -->



                      <div class="bgc-white col-lg-4 col-12 p-4">
                        <h5 class="text-dark-m2">
                          <i class="fas fa-bullhorn mr-1 text-primary-m1"></i>
                          Notifications
                        </h5>

                        <div class="mt-3">
                          <div class="media mt-2 px-3 pt-1 border-l-2 brc-purple-m2">
                            <div class="bgc-purple radius-1 mr-3 p-3">
                              <i class="fa fa-user text-white text-150"></i>
                            </div>
                            <div class="media-body pb-0 mb-0 text-90 text-grey-m1">
                              <div class="text-grey-d2 font-bolder">@username1</div>
                              Donec id elit non mi porta gravida at eget metus. Fusce dapibus...
                            </div>
                          </div>

                          <hr />

                          <div class="media mt-2 px-3 pt-1 border-l-2 brc-warning-m2">
                            <div class="bgc-warning radius-1 mr-3 p-3">
                              <i class="fa fa-user text-white text-150"></i>
                            </div>
                            <div class="media-body pb-0 mb-0 text-90 text-grey-m1">
                              <div class="text-grey-d2 font-bolder">@username2</div>
                              Fusce dapibus, tellus ac cursus commodo, tortor mauris...
                            </div>
                          </div>

                          <hr />

                          <div class="media mt-2 px-3 pt-1 border-l-2 brc-success-m2">
                            <div class="bgc-success radius-1 mr-3 p-3">
                              <i class="fa fa-user text-white text-150"></i>
                            </div>
                            <div class="media-body pb-0 mb-0 text-90 text-grey-m1">
                              <div class="text-grey-d2 font-bolder">@username3</div>
                              Tortor mauris condimentum nibh, fusce dapibus...
                            </div>
                          </div>
                        </div>

                      </div><!-- .col:mega notifications -->


                      <div class="col-lg-4 col-12 p-4 dropdown-clickable">
                        <h5 class="text-dark-m2">
                          <i class="fa fa-envelope mr-1 text-green-m1"></i>
                          Contact Us
                        </h5>

                        <form class="my-3">
                          <div class="form-group mb-2">
                            <input placeholder="Name" type="text" class="form-control border-l-2" />
                          </div>

                          <div class="form-group mb-2">
                            <input placeholder="Email" type="text" class="form-control border-l-2" />
                          </div>

                          <div class="form-group mb-4">
                            <textarea class="form-control brc-primary-m2 border-l-2 text-grey-d1" rows="3" placeholder="Your message..."></textarea>
                          </div>

                          <div class="text-center">
                            <button type="reset" class="btn px-3 btn-secondary btn-bold tex1t-110">
                              Reset
                            </button>

                            <button data-dismiss="dropdown" type="button" class="btn btn-outline-primary btn-bgc-white px-3 btn-bold btn-text-slide-x" style="width: 8rem;">
                              Submit<i class="btn-text-2  move-right fa fa-arrow-right text-120 align-text-bottom ml-1"></i>
                            </button>
                          </div>
                        </form>
                      </div><!-- .col:mega contact -->

                    </div><!-- .row: mega -->



                    <!-- Big Action Buttons -->
                    <div class="order-first order-lg-last ">
                      <hr class="d-none d-lg-block brc-default-l1 my-0" /><!-- border above buttons in desktop mode -->

                      <div class="row mx-0 bgc-primary-l4">
                        <div class="col-lg-8 offset-lg-2 d-flex justify-content-center py-4 d-flex">

                          <button class="mx-2px btn btn-sm btn-app btn-outline-warning btn-h-outline-warning btn-a-outline-warning radius-1 border-2">
                            <i class="fa fa-cog text-190 d-block mb-2 h-4"></i>
                            <span class="text-muted">Settings</span>
                          </button>

                          <button class="mx-2px btn btn-sm btn-app btn-outline-info btn-h-outline-info radius-1 border-2">
                            <i class="fa fa-edit text-190 d-block mb-2 h-4"></i>
                            Edit
                            <span class="position-tr text-danger-m2 text-130 mr-1">*</span>
                          </button>

                          <button class="mx-2px btn btn-sm btn-app btn-dark radius-1">
                            <i class="fa fa-lock text-150 d-block mb-2 h-4"></i>
                            Lock
                          </button>

                        </div>
                      </div><!-- .row:megamenu big buttons -->

                      <hr class="d-lg-none brc-default-l1 mt-0" /><!-- border below buttons in mobile mode -->
                    </div>


                  </div>
                </div>
              </li>

              <li class="nav-item dropdown dropdown-mega">
                <a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle pl-lg-3 pr-lg-4"
                   data-toggle="dropdown" href="#" role="button">
                  <i class="fa fa-bell text-110 icon-animated-bell mr-lg-2"></i>

                  <span class="d-inline-block d-lg-none ml-2">Notifications</span><!-- show only on mobile -->
                  <span class="badge badge-sm bgc-warning radius-round text-85 border-1 brc-white-tp3"
                        id="id-navbar-badge1">{{alerts?.length}}</span>

                  <i class="caret fa fa-angle-left d-block d-lg-none"></i>
                  <div class="dropdown-caret brc-white"></div>
                </a>
                <div class="dropdown-menu dropdown-sm dropdown-animated p-0 bg-white brc-primary-m3 border-b-2 shadow">


                  <div class=" tab-sliding p-0">
                    <div class=" pl-md-2">
                      <div data-ace-scroll='{"ignore": "mobile", "height": 300, "smooth":true}'>
                        <a (click)="readAlert(alert.id);" *ngFor="let alert of alerts | slice:0:5;"
                           class="d-flex mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                          <div>
                            <span
                              [ngClass]="{'text-danger bgc-danger-l3 brc-danger-m4': alert.template?.priorite == 'Haute', 'text-warning bgc-warning-l3 brc-warning-m4': alert.template?.priorite == 'Moyenne', 'text-info bgc-info-l3 brc-info-m4': alert.template?.priorite == 'Basse'}"

                              class="badge  border-l-2">{{alert.template?.priorite}}</span>
                            <span class="text-grey text-90"> {{alert.message}}</span>
                            <br/>
                            <span class="text-grey-m1 text-85">
                                                  <i class="far fa-clock"></i>
                              {{alert.alertDate | date: 'd/MM/yyyy'}} {{alert.alertTime}}
                                              </span>
                          </div>
                        </a>
                        <hr class="my-1px brc-grey-l3"/>

                      </div><!-- ace-scroll -->

                      <hr class="my-1px brc-secondary-l2 border-double"/>
                      <a
                        class="mb-0 py-3 border-0 list-group-item text-purple text-uppercase text-center text-85 font-bolder"
                        routerLink="alerts">
                        Voir toutes les notifications
                        <i class="ml-2 fa fa-arrow-right text-muted"></i>
                      </a>
                    </div><!-- .tab-pane : messages -->

                  </div>
                </div>
              </li>


              <li class="nav-item dropdown order-first order-lg-last dropdown-hover">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                  <span class="d-inline-block d-lg-none d-xl-inline-block">
                              <span class="text-90" id="id-user-welcome">{{user.firstname}}</span>
                    <span class="nav-user-name">{{user.lastname}}</span>
                    </span>

                  <i class="caret fa fa-angle-down d-none d-xl-block"></i>
                  <i class="caret fa fa-angle-left d-block d-lg-none"></i>
                </a>

                <div class="dropdown-menu dropdown-caret dropdown-menu-right dropdown-animated brc-primary-m3">
                  <div class="d-none d-lg-block d-xl-none">
                    <div class="dropdown-header">
                    </div>
                    <div class="dropdown-divider"></div>
                  </div>

                  <a class="dropdown-item btn btn-outline-grey btn-h-lighter-primary btn-a-lighter-primary" href="#">
                    <i class="fa fa-user text-primary-m1 text-105 mr-1"></i>
                    Mon profil
                  </a>

                  <a class="dropdown-item btn btn-outline-grey btn-h-lighter-success btn-a-lighter-success" href="#" data-toggle="modal" >
                    <i class="fa fa-cog text-success-m1 text-105 mr-1"></i>
                    Paramètres
                  </a>

                  <div class="dropdown-divider brc-primary-l2"></div>

                  <a (click)="logOut()" class="dropdown-item btn btn-outline-grey btn-h-lighter-secondary btn-a-lighter-secondary" >
                    <i class="fa fa-power-off text-warning-d1 text-105 mr-1"></i>
                    Déconnexion
                  </a>
                </div>
              </li><!-- .nav-item:last -->

            </ul><!-- .navbar-nav menu -->
          </div><!-- .navbar-nav -->

        </div><!-- .navbar-menu.navbar-collapse -->

      </div>
    </nav>
    <div class="main-container bgc-white">

      <div id="sidebar" class="sidebar sidebar-fixed expandable sidebar-color sidebar-darkblue sidebar-top sidebar-backdrop" data-swipe="true" data-dismiss="true">
        <div class="sidebar-inner">

          <div class="ace-scroll flex-grow-1" data-ace-scroll="{}">
            <app-menu></app-menu>
          </div><!-- .sidebar scroll -->
        </div>
      </div>

      <div role="main" class="main-content">

        <router-outlet></router-outlet>


        <!-- this footer is shown in device width above `sm` -->
        <footer class="footer d-none d-sm-block">
          <div class="footer-inner bgc-white-tp1">
            <div class="pt-3 border-none border-t-3 brc-grey-l1 border-double">
              <span class="text-primary-m2 font-bolder text-120">ASKIA</span>
              <span class="text-muted">&copy; 2021</span>

              <span ></span>
              <a href="#"> Version 0.0</a>


            </div>
          </div><!-- .footer-inner -->

          <!-- scroll to top button -->
          <div class="footer-tools">
            <a href="#" class="btn-scroll-up btn btn-dark px-25 py-2 text-95 mb-2 mr-2">
              <i class="fa fa-angle-double-up mx-1"></i>
            </a>
          </div>
        </footer>


        <!-- this footer is shown in mobile devices below `sm` -->
        <footer class="d-sm-none footer-sm footer-fixed">
          <div class="footer-inner">
            <div class="btn-group d-flex h-100 mx-0 bgc-primary-m1 px-2 py-15 shadow-lg">
              <button class="btn btn-outline-white btn-h-outline-white btn-a-outline-white active border-0 radius-round px-4 mx-2">
                <i class="fa fa-home text-120"></i>
                Home
              </button>

              <button class="btn btn-outline-white btn-h-outline-white btn-a-outline-white border-0 radius-round">
                <i class="fa fa-plus-circle opacity-1 text-120"></i>
              </button>

              <button data-toggle="collapse" data-target="#navbarSearch" aria-controls="navbarSearch" aria-expanded="false" aria-label="Toggle navbar search" class="btn btn-outline-white btn-h-outline-white btn-a-outline-white border-0 radius-round">
                <i class="fa fa-search opacity-1 text-120"></i>
              </button>

              <button class="btn btn-outline-white btn-h-outline-white btn-a-outline-white border-0 mr-0 radius-round">
                  <span class="pos-rel">
                      <i class="fa fa-bell opacity-1 text-120"></i>
                      <span class="badge badge-dot bgc-yellow-m1 position-tr mt-n1 mr-n2px"></span>
                  </span>
              </button>
            </div>
          </div>
        </footer>
      </div>

    </div>

  </div>
</div>

<div *ngIf="rt.url == '/login'">
<router-outlet></router-outlet>
</div>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
