<div class="modal fade modal-ms" id="modalDetailsFoyer" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel2"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="col-md-12 mt- mt-md-0">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel2">
                        Details Foyers
                    </h5>

                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="radius-">
                    <ul class="nav nav-tabs nav-tabs-faded bgc-info-m3 pt-2 pl-2 radius-t-1" role="tablist">
                        <li *ngIf="this.canShowEnfant" class="nav-item mr-3px">
                            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0 active"
                                data-toggle="tab" href="#home2" role="tab" aria-controls="home2" aria-selected="true">
                                Enfants
                            </a>
                        </li>

                        <li *ngIf="this.canShowCollaborateur" class="nav-item mr-3px">
                            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                                data-toggle="tab" href="#profile2" role="tab" aria-controls="profile2"
                                aria-selected="false">
                                Collaborateurs
                            </a>
                        </li>
                    </ul>

                    <div class="tab-content bgc-white p-35 border-1 border-t-0 brc-default-l2 radius-b-1">

                        <div *ngIf="this.canShowEnfant" class="tab-pane fade text-95 active show" id="home2"
                            role="tabpanel" style="overflow-y: scroll; height:350px;">
                            <div class="card dcard">
                                <div class="card-body px-1 px-md-3">
                                    <form autocomplete="off">
                                        <div
                                            class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                                            <h3 class="text-125 pl-1 mb-3 mb-sm-0 text-secondary-d4">
                                                Enfants
                                            </h3>

                                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                                <button *ngIf="this.canCreateEnfant" type="button"
                                                    (click)="openFormEnfant('PENSIONNAIRE','FOYER')"
                                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                    <i class="fa fa-plus mr-1"></i>
                                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                                </button>
                                            </div>

                                            <div class="mb-2 mb-sm-0">

                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="accordion">
                                                <div class="card border-0 mt-1px bgc-primary-l5">
                                                    <div class="card-header border-0 bgc-transparent mb-0">
                                                        <h2
                                                            class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                                            <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                                href="#rechercheAvanceeDetailsFoyerEnfant"
                                                                data-toggle="collapse" aria-expanded="false"
                                                                aria-controls="rechercheAvanceeDetailsFoyerEnfant">
                                                                Recherche avancée

                                                                <span
                                                                    class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                                    <i
                                                                        class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                                </span>
                                                                <span
                                                                    class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                                    <i
                                                                        class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                                </span>
                                                            </a>
                                                        </h2>
                                                    </div>
                                                    <div id="rechercheAvanceeDetailsFoyerEnfant" class="collapse"
                                                        aria-labelledby="headingTwo4" (keyup.enter)="rechercheAvanceEnfant()"
                                                        data-parent="#rechercheAvanceeDetailsFoyerEnfant" style="">
                                                        <div
                                                            class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                                            <div class="form-group row">

                                                                <div class="col-md-6">

                                                                    <div class="form-group row">
                                                                        <div
                                                                            class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                            <label class="mb-0">
                                                                                Prénom/Nom
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-sm-7 pl-2">
                                                                            <input [(ngModel)]="prenomEnfant"
                                                                                name="prenomEnfant" type="text"
                                                                                class="form-control">
                                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetPrenom()'>
                                                           Effacer
                                                       </button>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <!-- <div class="col-md-4">

                                                                    <div class="form-group row">
                                                                        <div
                                                                            class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                            <label class="mb-0">
                                                                                Nom
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-sm-8 pl-2">
                                                                            <input [(ngModel)]="nomEnfant"
                                                                                name="nomEnfant" type="text"
                                                                                class="form-control">
                                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetNom()'>
                                                           Effacer
                                                       </button>
                                                                        </div>
                                                                    </div>

                                                                </div> -->

                                                                <div class="col-md-6">

                                                                    <div class="form-group row">
                                                                        <div
                                                                            class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                            <label class="mb-0">
                                                                                Email
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-sm-7 pl-2">
                                                                            <input [(ngModel)]="emailEnfant"
                                                                                name="emailEnfant" type="text"
                                                                                class="form-control">
                                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetEmail()'>
                                                           Effacer
                                                       </button>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>


                                                            <div class="form-group row">

                                                                <div class="col-md-6">

                                                                    <div class="form-group row">
                                                                        <div
                                                                            class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                            <label class="mb-0">
                                                                                Adresse
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-sm-7 pl-2">
                                                                            <input [(ngModel)]="adresseEnfant"
                                                                                name="adresseEnfant" type="text"
                                                                                class="form-control">
                                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetAdresse()'>
                                                           Effacer
                                                       </button>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div class="col-md-6">

                                                                    <div class="form-group row">
                                                                        <div
                                                                            class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                            <label class="mb-0">
                                                                                Téléphone
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-sm-7 pl-2">
                                                                            <input [(ngModel)]="telephoneEnfant"
                                                                                name="telephoneEnfant" type="text"
                                                                                class="form-control">
                                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetTelephone()'>
                                                           Effacer
                                                       </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="col-md-4">

                                                                    <div class="form-group row">


                                                                        <div class="mb-2 mb-sm-0">
                                                                            <button (click)="rechercheAvanceEnfant()"
                                                                                type="button"
                                                                                class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                                <i class="fa fa-search mr-1"></i>

                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>
                                        <table
                                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                                <tr>


                                                    <th>
                                                        Nom
                                                    </th>

                                                    <th>
                                                        Prénom
                                                    </th>

                                                    <th class="d-none d-sm-table-cell">
                                                        Age
                                                    </th>
                                                    <th class="d-none d-sm-table-cell">
                                                        Téléphone Portable
                                                    </th>

                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody class="mt-1">
                                                <ng-container *ngFor="let acteur of datapensionnaires| paginate : {
                                                    itemsPerPage: tableSize,
                                                    currentPage: page,
                                                    totalItems: count}; let i = index"
                                                    [class.active]="i == currentIndex">
                                                    <tr class="bgc-h-yellow-l4 d-style">


                                                        <td>
                                                            <a href="#"
                                                                class="text-blue-d1 text-600 text-95">{{acteur.personne.nom}}</a>
                                                        </td>

                                                        <td class="text-600 text-orange-d2">
                                                            {{acteur.personne.prenom}}
                                                        </td>

                                                        <td >
                                                            <span *ngIf="!!acteur.personne.datenaissance">
                                                                {{getAge(acteur.personne.datenaissance)}} ans
                                                            </span>  
                                                           
                                                        </td>
                                                        <td class="text-600 text-orange-d2">
                                                            {{acteur.personne.telephone2}}
                                                        </td>

                                                        <td class="text-center pr-0">
                                                            <div>
                                                                <a href="#" data-toggle="collapse"
                                                                    [attr.data-target]="'#detail-acteur-'+acteur.id"
                                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                                    title="Show Details" aria-expanded="false">
                                                                    <span class="d-none d-md-inline mr-1">
                                                                        Details
                                                                    </span>
                                                                    <i
                                                                        class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- action buttons -->
                                                            <div class="d-none d-lg-flex">


                                                                <a href="#" *ngIf="this.canDeleteEnfant"
                                                                    (click)="deletePen(acteur.id)"
                                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                                    <i class="fa fa-trash-alt"></i>
                                                                </a>
                                                                <a href="#" *ngIf="this.canTransfertEnfant"
                                                                    (click)="transferer(acteur)"
                                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-warning btn-a-lighter-warning">
                                                                    <i class="fa fa-share mx-1"></i>
                                                                </a>
                                                            </div>

                                                            <!-- show a dropdown in mobile -->
                                                            <div
                                                                class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                                <a href="#"
                                                                    class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                                    data-toggle="dropdown">
                                                                    <i class="fa fa-cog"></i>
                                                                </a>

                                                                <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                                    <div class="dropdown-inner">
                                                                        <div
                                                                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                                                                            Unité 1
                                                                        </div>
                                                                        <a href="#" class="dropdown-item">
                                                                            <i
                                                                                class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                                                            Modifier
                                                                        </a>
                                                                        <a href="#" class="dropdown-item">
                                                                            <i
                                                                                class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                                            Supprimer
                                                                        </a>
                                                                        <a href="#" class="dropdown-item">
                                                                            <i
                                                                                class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                                            Autre
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                    <tr class="border-0 detail-row bgc-white">
                                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                                            <div class="table-detail collapse"
                                                                id="{{'detail-acteur-'+acteur.id}}">
                                                                <div class="row">
                                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                                        <div
                                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                                            <div class="bgc-white radius-1">
                                                                                <table
                                                                                    class="table table table-striped-default table-borderless">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <i
                                                                                                    class="far fa-envelope text-blue"></i>
                                                                                            </td>

                                                                                            <td
                                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                                Email
                                                                                            </td>

                                                                                            <td
                                                                                                class="text-blue-d1 text-wrap">
                                                                                                {{acteur.personne.email}}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <i
                                                                                                    class="fa fa-eye text-blue"></i>
                                                                                            </td>

                                                                                            <td
                                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                                Note
                                                                                            </td>

                                                                                            <td
                                                                                                class="text-blue-d1 text-wrap">
                                                                                                {{acteur.personne.information}}
                                                                                            </td>
                                                                                        </tr>


                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>

                                        <!-- table footer -->
                                        <div
                                            class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                                            <div class="text-nowrap align-self-center align-self-sm-start">
                                                Afficher 1 - {{ tableSize }} of {{datapensionnaires.length}}
                                                <select (change)="tableData($event)" class="custom-select">
                                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                                        Afficher {{ size }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                                <pagination-controls responsive="true" previousLabel="Prev"
                                                    nextLabel="Next" (pageChange)="tabSize($event)">
                                                </pagination-controls>
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                    </form>

                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>

                        <div *ngIf="this.canShowCollaborateur" class="tab-pane fade text-95" id="profile2"
                            role="tabpanel" style="overflow-y: scroll; height:350px;">
                            <div class="card dcard">
                                <div class="card-body px-1 px-md-3">

                                    <form autocomplete="off">
                                        <div
                                            class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                                            <h3 class="text-125 pl-1 mb-3 mb-sm-0 text-secondary-d4">
                                                Collaborateurs
                                            </h3>

                                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                                <button *ngIf="this.canCreateCollaborateur" type="button"
                                                    (click)="openFormActeur('COLLABORATEUR','FOYER')"
                                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                    <i class="fa fa-plus mr-1"></i>
                                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                                </button>
                                            </div>

                                            <div class="mb-2 mb-sm-0">

                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="accordion">
                                                <div class="card border-0 mt-1px bgc-primary-l5">
                                                    <div class="card-header border-0 bgc-transparent mb-0">
                                                        <h2
                                                            class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                                            <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                                href="#rechercheAvanceeDetailsFoyerCollaborateur"
                                                                data-toggle="collapse" aria-expanded="false" (keyup.enter)="rechercheAvanceCollaborateur()"
                                                                aria-controls="rechercheAvanceeDetailsFoyerEnfant">
                                                                Recherche avancée

                                                                <span
                                                                    class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                                    <i
                                                                        class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                                </span>
                                                                <span
                                                                    class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                                    <i
                                                                        class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                                </span>
                                                            </a>
                                                        </h2>
                                                    </div>
                                                    <div id="rechercheAvanceeDetailsFoyerCollaborateur" class="collapse"
                                                        aria-labelledby="headingTwo4" (keyup.enter)="rechercheAvanceCollaborateur()" 
                                                        data-parent="#rechercheAvanceeDetailsFoyerCollaborateur"
                                                        style="">
                                                        <div
                                                            class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                                            <div class="form-group row">

                                                                <div class="col-md-6">

                                                                    <div class="form-group row">
                                                                        <div
                                                                            class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                            <label class="mb-0">
                                                                                Prénom/Nom
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-sm-7 pl-2">
                                                                            <input [(ngModel)]="prenomCollaborateur"
                                                                                name="prenomCollaborateur" type="text"
                                                                                class="form-control">
                                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetPrenomCol()'>
                                                           Effacer
                                                       </button>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <!-- <div class="col-md-4">

                                                                    <div class="form-group row">
                                                                        <div
                                                                            class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                            <label class="mb-0">
                                                                                Nom
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-sm-8 pl-2">
                                                                            <input [(ngModel)]="nomCollaborateur"
                                                                                name="nomCollaborateur" type="text"
                                                                                class="form-control">
                                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetNomCol()'>
                                                           Effacer
                                                       </button>
                                                                        </div>
                                                                    </div>

                                                                </div> -->

                                                                <div class="col-md-6">

                                                                    <div class="form-group row">
                                                                        <div
                                                                            class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                            <label class="mb-0">
                                                                                Email
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-sm-7 pl-2">
                                                                            <input [(ngModel)]="emailCollaborateur"
                                                                                name="emailCollaborateur" type="text"
                                                                                class="form-control">
                                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetEmailCol()'>
                                                           Effacer
                                                       </button>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>


                                                            <div class="form-group row">

                                                                <div class="col-md-6">

                                                                    <div class="form-group row">
                                                                        <div
                                                                            class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                            <label class="mb-0">
                                                                                Adresse
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-sm-7 pl-2">
                                                                            <input [(ngModel)]="adresseCollaborateur"
                                                                                name="adresseCollaborateur" type="text"
                                                                                class="form-control">
                                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetAdresseCol()'>
                                                           Effacer
                                                       </button>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div class="col-md-6">

                                                                    <div class="form-group row">
                                                                        <div
                                                                            class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                            <label class="mb-0">
                                                                                Téléphone
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-sm-7 pl-2">
                                                                            <input [(ngModel)]="telephoneCollaborateur"
                                                                                name="telephoneCollaborateur"
                                                                                type="text" class="form-control">
                                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetTelephoneCol()'>
                                                           Effacer
                                                       </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="col-md-4">

                                                                    <div class="form-group row">


                                                                        <div class="mb-2 mb-sm-0">
                                                                            <button
                                                                                (click)="rechercheAvanceCollaborateur()"
                                                                                type="button"
                                                                                class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                                <i class="fa fa-search mr-1"></i>

                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>
                                        <table
                                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                                <tr>
                                                    <th class="text-center pr-0">

                                                    </th>

                                                    <th>
                                                        Nom
                                                    </th>

                                                    <th>
                                                        Prénom
                                                    </th>

                                                    <th class="d-none d-sm-table-cell">
                                                        Email
                                                    </th>
                                                    <th class="d-none d-sm-table-cell">
                                                        Fonction
                                                    </th>
                                                    <th class="d-none d-sm-table-cell">
                                                        Téléphone fixe
                                                    </th>
                                                    <th class="d-none d-sm-table-cell">
                                                        Téléphone Portable
                                                    </th>
                                                    <th class="d-none d-sm-table-cell">

                                                    </th>

                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody class="mt-1">
                                                <ng-container *ngFor="let acteur of datacollaborateurs| paginate : {
                                                    itemsPerPage: tableSize1,
                                                    currentPage: page1,
                                                    totalItems: count1}; let i = index"
                                                    [class.active]="i == currentIndex1">
                                                    <tr class="bgc-h-yellow-l4 d-style">
                                                        <td class="text-center pr-0 pos-rel">
                                                            <div
                                                                class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                                                <!-- border shown on hover -->
                                                            </div>
                                                            <div
                                                                class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                                                <!-- border shown when row is selected -->
                                                            </div>


                                                        </td>

                                                        <td>
                                                            <a
                                                                class="text-blue-d1 text-600 text-95">{{acteur.personne.nom}}</a>
                                                        </td>

                                                        <td class="text-600 text-orange-d2">
                                                            {{acteur.personne.prenom}}
                                                        </td>

                                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                                            {{acteur.personne.email}}
                                                        </td>
                                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                                            {{acteur?.fonction?.libelle}}
                                                        </td>
                                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                                            {{acteur.personne?.telephone2}}
                                                        </td>
                                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                                            {{acteur.personne?.telephone1}}
                                                        </td>



                                                        <td class="text-center pr-0">
                                                            <div>
                                                                <a href="#" data-toggle="collapse"
                                                                    [attr.data-target]="'#detail-acteur-'+acteur.id"
                                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                                    title="Show Details" aria-expanded="false">
                                                                    <span class="d-none d-md-inline mr-1">
                                                                        Details
                                                                    </span>
                                                                    <i
                                                                        class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- action buttons -->
                                                            <div class="d-none d-lg-flex">
                                                                <a href="#" *ngIf="this.canEditCollaborateur"
                                                                    (click)="editCol(acteur.id)"
                                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                                                                    <i class="fa fa-pencil-alt"></i>
                                                                </a>

                                                                <a href="#" *ngIf="this.canDeleteCollaborateur"
                                                                    (click)="deleteCol(acteur.id)"
                                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                                    <i class="fa fa-trash-alt"></i>
                                                                </a>

                                                            </div>

                                                            <!-- show a dropdown in mobile -->
                                                            <div
                                                                class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                                <a href="#"
                                                                    class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                                    data-toggle="dropdown">
                                                                    <i class="fa fa-cog"></i>
                                                                </a>

                                                                <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                                    <div class="dropdown-inner">
                                                                        <div
                                                                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                                                                            Unité 1
                                                                        </div>
                                                                        <a href="#" class="dropdown-item">
                                                                            <i
                                                                                class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                                                            Modifier
                                                                        </a>
                                                                        <a href="#" class="dropdown-item">
                                                                            <i
                                                                                class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                                            Supprimer
                                                                        </a>
                                                                        <a href="#" class="dropdown-item">
                                                                            <i
                                                                                class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                                            Autre
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                    <tr class="border-0 detail-row bgc-white">
                                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                                            <div class="table-detail collapse"
                                                                id="{{'detail-acteur-'+acteur.id}}">
                                                                <div class="row">
                                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                                        <div
                                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                                            <div class="bgc-white radius-1">
                                                                                <table class="table table table-striped-default table-borderless">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <i class="far fa-info text-success"></i>
                                                                                            </td>
                
                                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                                Information complémentaire
                                                                                            </td>
                
                                                                                            <td class="text-dark-m3">
                                                                                                {{(!!acteur.personne) ? acteur.personne.information:''}}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <i class="fa fa-map-marker text-success"></i>
                                                                                            </td>
                
                                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                                adresse
                                                                                            </td>
                
                                                                                            <td class="text-dark-m3">
                                                                                                {{(!!acteur.personne) ? acteur.personne.adresse:''}}
                                                                                            </td>
                                                                                        </tr>
                
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>

                                        <!-- table footer -->
                                        <div
                                            class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                                            <div class="text-nowrap align-self-center align-self-sm-start">
                                                Afficher 1 - {{ tableSize }} of {{datacollaborateurs.length}}
                                                <select (change)="tableData1($event)" class="custom-select">
                                                    <option *ngFor="let size of tableSizesArr1" [value]="size">
                                                        Afficher {{ size }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                                <pagination-controls responsive="true" previousLabel="Prev"
                                                    nextLabel="Next" (pageChange)="tabSize1($event)">
                                                </pagination-controls>
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                    </form>

                                </div>
                                <!-- /.card-body -->
                            </div>

                        </div>

                        <div class="tab-pane fade text-95" id="prestataires" role="tabpanel">
                            <div class="card dcard">
                                <div class="card-body px-1 px-md-3">

                                    <form autocomplete="off">
                                        <div
                                            class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


                                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                                <i class="fa fa-search position-lc ml-25 text-primary-m1"></i>
                                                <input type="text"
                                                    class="form-control w-100 pl-45 radius-1 brc-primary-m4"
                                                    placeholder="Recherche ...">
                                            </div>

                                            <div class="mb-2 mb-sm-0">
                                                <button type="button" data-toggle="modal"
                                                    data-target="#modalPrestataire"
                                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                    <i class="fa fa-plus mr-1"></i>
                                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                                </button>
                                            </div>
                                        </div>

                                        <table
                                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                                <tr>
                                                    <th class="text-center pr-0">
                                                        <label class="py-0">
                                                            <input type="checkbox"
                                                                class="align-bottom mb-n1 border-2 text-dark-m3">
                                                        </label>
                                                    </th>

                                                    <th>
                                                        Siret
                                                    </th>
                                                    <th>
                                                        Nom
                                                    </th>
                                                    <th class="d-none d-sm-table-cell">
                                                        Statut
                                                    </th>

                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody class="mt-1">
                                                <tr class="bgc-h-yellow-l4 d-style">
                                                    <td class="text-center pr-0 pos-rel">
                                                        <div
                                                            class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                                            <!-- border shown on hover -->
                                                        </div>
                                                        <div
                                                            class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                                            <!-- border shown when row is selected -->
                                                        </div>

                                                        <label>
                                                            <input type="checkbox" class="align-middle">
                                                        </label>
                                                    </td>

                                                    <td>
                                                        <span class="text-dark-d1 text-600 text-95">123456789</span>
                                                    </td>
                                                    <td>
                                                        <a href="#" class="text-blue-d1 text-600 text-95">Prestataire
                                                            1</a>
                                                    </td>



                                                    <td class="d-none d-sm-table-cell">
                                                        <input type="checkbox" class="ace-switch" checked>
                                                    </td>


                                                    <td class="text-center pr-0">
                                                        <div>
                                                            <a href="#" data-toggle="collapse"
                                                                data-target="#table-detail-0"
                                                                class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                                title="Show Details" aria-expanded="false">
                                                                <span class="d-none d-md-inline mr-1">
                                                                    Details
                                                                </span>
                                                                <i
                                                                    class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                            </a>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <!-- action buttons -->
                                                        <div class="d-none d-lg-flex">
                                                            <a href="#"
                                                                class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                                                                <i class="fa fa-pencil-alt"></i>
                                                            </a>

                                                            <a href="#"
                                                                class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                                <i class="fa fa-trash-alt"></i>
                                                            </a>
                                                            <a href="#"
                                                                class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-warning btn-a-lighter-warning">
                                                                <i class="fa fa-ellipsis-v mx-1"></i>
                                                            </a>
                                                        </div>

                                                        <!-- show a dropdown in mobile -->
                                                        <div
                                                            class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                            <a href="#"
                                                                class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                                data-toggle="dropdown">
                                                                <i class="fa fa-cog"></i>
                                                            </a>

                                                            <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                                <div class="dropdown-inner">
                                                                    <div
                                                                        class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                                                                        Prestataire 1
                                                                    </div>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i
                                                                            class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                                                        Edit
                                                                    </a>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i
                                                                            class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                                        Delete
                                                                    </a>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i
                                                                            class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                                        Autre
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>

                                                <!-- detail row -->
                                                <tr class="border-0 detail-row bgc-white">
                                                    <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                                        <div class="table-detail collapse" id="table-detail-0">
                                                            <div class="row">
                                                                <div class="col-12 col-md-10 offset-md-1 p-4">
                                                                    <div
                                                                        class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                                        <div class="bgc-white radius-1">
                                                                            <table
                                                                                class="table table table-striped-default table-borderless">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                class="far fa-user text-success"></i>
                                                                                        </td>

                                                                                        <td
                                                                                            class="text-95 text-600 text-secondary-d2">
                                                                                            Responsable
                                                                                        </td>

                                                                                        <td class="text-dark-m3">
                                                                                            Martin Dubois
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                class="far fa-envelope text-blue"></i>
                                                                                        </td>

                                                                                        <td
                                                                                            class="text-95 text-600 text-secondary-d2">
                                                                                            Email
                                                                                        </td>

                                                                                        <td
                                                                                            class="text-blue-d1 text-wrap">
                                                                                            frtrans@frtrans.fr
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                class="fa fa-phone text-purple"></i>
                                                                                        </td>

                                                                                        <td
                                                                                            class="text-95 text-600 text-secondary-d2">
                                                                                            Téléphone fixe
                                                                                        </td>

                                                                                        <td class="text-dark-m3">
                                                                                            01 39 07 78 78
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                class="fa fa-map-marker text-orange-d1"></i>
                                                                                        </td>

                                                                                        <td
                                                                                            class="text-95 text-600 text-secondary-d2">
                                                                                            Adresse
                                                                                        </td>

                                                                                        <td class="text-dark-m3">
                                                                                            41 quai Pierre Scize, 69009
                                                                                            Lyon
                                                                                        </td>
                                                                                    </tr>


                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>

                                        <!-- table footer -->
                                        <div
                                            class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                                            <div class="text-nowrap align-self-center align-self-sm-start">
                                                <span class="d-inline-block text-grey-d2">
                                                    Afficher 1 - 10 of 152
                                                </span>

                                                <select
                                                    class="ml-3 ace-select no-border angle-down brc-h-blue-m3 w-auto pr-45 text-secondary-d3">
                                                    <option value="10">Afficher 10</option>
                                                    <option value="20">Afficher 20</option>
                                                    <option value="50">Afficher 50</option>
                                                </select>
                                            </div>

                                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                                <a href="#"
                                                    class="btn btn-lighter-default btn-bgc-white btn-a-secondary radius-l-1 px-3 border-2">
                                                    <i class="fa fa-caret-left mr-1"></i> Prec
                                                </a>
                                                <a href="#"
                                                    class="btn btn-lighter-default btn-bgc-white btn-a-secondary radius-r-1 px-3 border-2 ml-n2px">
                                                    Suiv
                                                    <i class="fa fa-caret-right ml-1"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                Fermer
                            </button>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<app-frmcollectivitecollaborateur #frmcollaborateur></app-frmcollectivitecollaborateur>
<app-frmenfant #frmenfants></app-frmenfant>
<app-frmtransfertenfant #frmtransfertenfants></app-frmtransfertenfant>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
