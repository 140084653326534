import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-view-doc',
  templateUrl: './view-doc.component.html',
  styleUrls: ['./view-doc.component.css']
})
export class ViewDocComponent implements OnInit {

  doc: any;


  constructor() {

  }

  ngOnInit(): void {
  }


  show(doc: any) {
    this.doc = doc;
    $('#modalDocument').modal('show');

  }

}
