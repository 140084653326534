import {HttpClient} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {PassagersComponent} from 'src/app/reservation/passagers/passagers.component';
import {ReservationService} from 'src/app/services/reservation.service';
import {StatutService} from 'src/app/services/statut.service';
import {StatutModel} from 'src/app/shared/models/statut-model.model ';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {User} from 'src/app/shared/session/user';
import {CalendarOptions} from '@fullcalendar/angular';
import {ModalreservationretailsComponent} from '../../reservation/detailsreservation/modalreservationretails/modalreservationretails.component';
import {StructureService} from '../../services/structure.service';
import {TypeStructureModel} from '../../shared/models/type-structure-model.model';
import {parse} from 'date-fns';

declare var $: any;

declare function createExcelExport();


@Component({
  selector: 'app-lstactivite',
  templateUrl: './lstactivite.component.html',
  styleUrls: ['./lstactivite.component.css']
})
export class LstactiviteComponent implements OnInit {


  structure: any;
  reservations: any = [];
  user: User;
  calendarOptions: CalendarOptions;
  events: any = [];

  statut: any;
  tads: any = [];
  idtad = 0;
  prestataires: any = [];
  idprestataire = 0;
  statuts: any = [];
  idstatut = 7;
  passager = null;
  datecrer = null;
  dateMax = null;
  critereDaterer: any = '1';
  creerpar = null;
  critereDateDebut = null;
  dateDebutMax = null;
  statues: any = [];
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;

  @ViewChild('details') details: ModalreservationretailsComponent;
  export: any;

  constructor(private http: HttpClient, private structureService: StructureService, private statutService: StatutService, private reservationService: ReservationService, private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.structure = this.user.structure;
    this.showTAD();
    this.showPrestataire();
    this.statutService.getManualStatues().subscribe(data => {
      this.statues = data;
    });
    this.rechercheAvance();
  }

  showTAD(): void {
    this.structureService.getStructuresByType(TypeStructureModel.TAD).subscribe(data => {
      this.tads = data;

    });

  }

  showPrestataire(): void {
    this.structureService.getStructuresByType(TypeStructureModel.PRESTATAIRE).subscribe(data => {
      this.prestataires = data;

    });

  }


  render() {
    if(!!this.export) {
      this.export.clear().destroy();
    }
    setTimeout(() => {
      this.export =  createExcelExport();
    }, 1000);

  }

  handleDateClick(event) {

    this.details.show(event.event.id);

  }


  rechercheAvance() {
    $('#datecrer')[0].dispatchEvent(new Event('input', {bubbles: true}));
    if (this.critereDaterer == '4') {
      $('#dateMax')[0].dispatchEvent(new Event('input', {bubbles: true}));
    }
    if (this.idtad == null) {
      this.idtad = 0;
    }
    if (this.idprestataire == null) {
      this.idprestataire = 0;
    }
    // alert(this.datecrer);
    if (this.idstatut == null) {
      this.idstatut = 0;
    }

    this.passager = this.passager ? this.passager : null;
    this.creerpar = this.creerpar ? this.creerpar : null;
    this.datecrer = this.datecrer ? this.datecrer : null;
    this.dateMax = this.dateMax ? this.dateMax : null;
    this.reservationService.getRecherche(this.idtad, this.creerpar, this.idprestataire, this.critereDaterer, this.datecrer, this.dateMax, this.idstatut, this.passager, this.user.id).subscribe(data => {
      this.reservations = data;
      this.render();
      // console.log(this.reservations);
    });


  }

  reset1() {
    this.datecrer = null;
  }

  reset2() {
    this.dateMax = null;
  }
  tabSize(event){
    this.page = event;
   // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
   // this.showData();
  }

}
