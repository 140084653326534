import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActeurService } from 'src/app/services/acteur.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { StructureService } from 'src/app/services/structure.service';
import { User } from 'src/app/shared/session/user';
declare var $: any;

@Component({
  selector: 'app-frmtransfertenfant',
  templateUrl: './frmtransfertenfant.component.html',
  styleUrls: ['./frmtransfertenfant.component.css']
})
export class FrmtransfertenfantComponent implements OnInit {
  structures: any = [];
  structure;
  structureorigine;
  typestructure;
  parent;
  selectstructure;
  lblnom;
  lblstructure;
  personne;
  enfant;
  nouveauenfant;
  user: User = new User();

  constructor(private http: HttpClient, private acteurService: ActeurService,
    private structureService: StructureService, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    //stransAdresse('adresse');
  }
  showStructure(): void {
    this.structureService.getStructuresByType(this.typestructure).subscribe(data => {
      this.structures = data;

    });

  }

  show(parent) {
    this.parent = parent;
    this.showStructure();
    this.structureorigine = this.enfant.structure.nom;
    $('#modalTransfer').modal('show');
  }
  fermer() {
    this.reset();
    $('#modalTransfer').modal('hide');

  }
reset(){
  this.selectstructure = null;
}
  save(): void {
    const headers = {};
    const body = { personne: this.personne, structure: { id: this.selectstructure }, typeacteur: 'PENSIONNAIRE', actif: true };
    console.log(this.nouveauenfant);
    this.update();
    if (!this.nouveauenfant) {
      this.acteurService.save(headers, body).subscribe(data => {
        $('#modalTransfer').modal('hide');
        this.parent.filter(null, this.structure);

      });
    } else {
      this.acteurService.update(headers, body, this.nouveauenfant.id).subscribe(data => {
        $('#modalTransfer').modal('hide');
        this.parent.filter(null, this.structure);


      });
    }
    this.reset();
  }
  update(): void {
    const headers = {};

    const body = { actif: false };
    console.log(this.enfant);
    this.acteurService.updateActif(headers, body, this.enfant.id).subscribe(data => {
    });
  }

}
