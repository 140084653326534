<div class="body-container">

  <div class="main-container container bgc-transparent">

    <div class="main-content minh-100 justify-content-center">
      <div class="p-2 p-md-4">
        <div class="row" id="row-1" >
          <div class="col-12 col-xl-10 offset-xl-1 bgc-white shadow radius-1 overflow-hidden" >

            <div class="row" id="row-2" >

            <!--  <div class="col-lg-5 d-none d-lg-flex border-r-1 brc-default-l3 px-0" id="id-col-intro">
                the left side section is carousel in this demo, to show some example variations 

                <div class="carousel slide minw-100 h-100 pointer-event" id="loginBgCarousel">
                  <ol class="carousel-indicators">
                    <li class="active" data-slide-to="0" data-target="#loginBgCarousel"></li>

                  </ol>

                  <div class="carousel-inner minw-100 h-100">


                    <div class="carousel-item minw-100 h-100 active">
                      <div class="d-flex flex-column align-items-center justify-content-start"
                           style="background-image: url('assets/images/bg.jpeg');">
                        <div class="bgc-black-tp4 radius-1 p-3 w-90 text-center my-3 h-100">
                          <a class="mt-5 mb-2">

                          </a>

                          <h2 class="text-primary-d1">
                            Dem <span class="text-80 text-light">Dikk</span>
                          </h2>

                          <div style="margin-top: 50px">

                            <p class="pt-15 text-light">Système d'information intégré pour la réservation et le suivi du
                              transport des pensionnaires des foyers </p>

                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div> -->


              <div class="col-12 col-lg-7 py-lg-5 bgc-white px-0 " id="id-col-main" 
              style="text-align: center;">


                <div class="tab-content tab-sliding border-0 p-0" data-swipe="" 
                 >

                  <div class="tab-pane active show mh-100 px-3 px-lg-0 pb-3" id="id-tab-login" 
                  >


                    <!-- show this in mobile device -->
                    <div class="text-secondary-m1 my-4 text-center">
                       <img  height="170" src="assets/images/logor.png" />
                    </div>


                    <form autocomplete="off" class="form-row mt-4" (keyup.enter)="onLoginSubmit()">
                      <div class="form-group col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                          <input [(ngModel)]="credentials.login" class="form-control form-control-lg pr-4 shadow-none"
                                 id="id-login-username" name="login"
                                 placeholder="Email" type="text">
                          <i class="fa fa-envelope text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 ml-n3" for="id-login-username">
                            Adresse email
                          </label>
                        </div>
                      </div>


                      <div
                        class="form-group col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-2 mt-md-1">
                        <div class="d-flex align-items-center input-floating-label text-blue brc-blue-m2">
                          <input class="form-control form-control-lg pr-4 shadow-none" name="password" [(ngModel)]="credentials.password" id="id-login-password"
                                 placeholder="Mot de passe" type="password">
                          <i class="fa fa-key text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 ml-n3" for="id-login-password">
                            Mot de passe
                          </label>
                        </div>
                      </div>


                      <div class="form-group col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

                        <div *ngIf="!!formError" class="alert d-flex bgc-red-l3 brc-success-m4 border-0 p-0" role="alert">
                          <div class="bgc-red px-1 py-1 text-center radius-l-1">
                            <span class="fa-2x text-white">
  
                            </span>
                              </div>
                          <span class="ml-3 align-self-center text-dark-tp3 text-110">
                           {{formError}}
                          </span>
                        </div>


                        <button (click)="onLoginSubmit()" class="btn btn-primary btn-block px-4 btn-bold mt-2 mb-4"
                                type="button">
                          Se connecter
                        </button>

                        <div class="text-center">
                          <img  height="12" src="assets/images/logo.jpg"> ASKIA   © 2021
                        </div>
                      </div>
                    </form>


                    <div class="form-row" style="min-height:200px;">


                    </div>
                  </div>


                </div><!-- .tab-content -->
              </div>

            </div><!-- /.row -->

          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="d-lg-none my-3 text-white-tp1 text-center">
          <i class="fa fa-leaf text-success-l3 mr-1 text-110"></i> Dem Dik © 2021
        </div>
      </div>
    </div>

  </div>

</div>
