import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';
import { TypeStructureModel } from '../shared/models/type-structure-model.model';
import { Observable } from 'rxjs';
import { TemplatePrixModel } from '../shared/models/templateprix-model.model';
import { FoyerModel } from '../shared/models/foyer-model.model';

@Injectable({
  providedIn: 'root'
})
export class TemplatePrixService {

  constructor(private http: HttpClient) { }

  getData() {
     return this.http.get(AppModule.API_ENDPOINT + 'templateprix/list');
  }

  get(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/get/' + id);
  }
  getTemplatesByTypeStructure(typestructure: TypeStructureModel): Observable<TemplatePrixModel[]> {
    return this.http.get<TemplatePrixModel[]>(AppModule.API_ENDPOINT + 'templateprix/listbytypestructure/'+typestructure);
  }

  getTemplatesByStructure(structure: FoyerModel): Observable<TemplatePrixModel[]> {
    return this.http.get<TemplatePrixModel[]>(AppModule.API_ENDPOINT + 'templateprix/listbyprestataire/' + structure);
  }
  getTemplatesByCollectivite(structure: FoyerModel): Observable<TemplatePrixModel[]> {
    return this.http.get<TemplatePrixModel[]>(AppModule.API_ENDPOINT + 'templateprix/listbycollectivite/' + structure);
  }
  getTemplatesByFoyer(structure: FoyerModel): Observable<TemplatePrixModel[]> {
    return this.http.get<TemplatePrixModel[]>(AppModule.API_ENDPOINT + 'templateprix/templatesbyfoyers/' + structure);
  }
  getTemplatesByStructureCollectivite(structure: FoyerModel,collectovote: FoyerModel): Observable<TemplatePrixModel[]> {
    return this.http.get<TemplatePrixModel[]>(AppModule.API_ENDPOINT + 'templateprix/list/' + structure + '/' + collectovote);
  }

  save(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'templateprix/create', body, { headers });
  }

  update(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + 'templateprix/update/' + id, body, { headers });
  }


  delete(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + 'templateprix/delete/' + id);
  }

  getTemplate(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/template/' + id);
  }

  getTemplates() {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/reservation');
  }

  getTemplatesBis(structure:any,datereservation:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/reservation/'+structure+'/'+datereservation);
  }
  getvalidePerode(prestataire: FoyerModel,collectovote: FoyerModel,datedebut:any,datefin:any,id:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/valideperiode/'+prestataire+'/'+collectovote+'/'+datedebut+'/'+datefin+'/'+id); 
  }

  getTemplatesSimulation(collectiviteId) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/simulations/' + collectiviteId);
  }

  getRecherche(id: any,datedbut:any,datefin : any,tarifjour:any,tarifnuit:any,priseenncharge:any,tarifmin:any,tarifweekend:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/recherchetemplate/' + id +'/' + datedbut+'/' +datefin+'/'+tarifjour+'/'+tarifnuit+'/'+priseenncharge+'/'+tarifmin+'/'+tarifweekend);
  }
  getRechercheBis(id: any,datedbut:any,criteredatedbut:any,datefin : any,criteredatefin : any,tarifjour:any,criteretarifjour:any,tarifnuit:any,criteretarifnuit:any,priseenncharge:any,criterepriseenncharge:any,tarifweekend:any,criteretarifweekend:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/recherchetemplateBis/' + id +'/' + datedbut+'/'+criteredatedbut+'/' +datefin+'/' +criteredatefin+ '/'+tarifjour+'/' +criteretarifjour+ '/'+tarifnuit+'/'+criteretarifnuit+'/'+priseenncharge+'/'+criterepriseenncharge+'/'+tarifweekend+'/'+criteretarifweekend);
  }

  getRechercheBisBis(id: any,idtad: any,idprestataire: any,libelle: any,datedbut:any,criteredatedbut:any,datedebutmax : any,datefin : any,criteredatefin : any, datefinmax: any,
    tarifjour:any,criteretarifjour:any,tarifjourmax:any,tarifnuit:any,criteretarifnuit:any,tarifnuitmax:any,
    priseenncharge:any,criterepriseenncharge:any,priseennchargemax:any,tarifweekend:any,criteretarifweekend:any,tarifweekendmax:any,referencemarche:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/recherchetemplateBisBis/' + id +'/'+idtad +'/'+idprestataire+'/' +libelle+'/'+ datedbut+'/'+criteredatedbut+'/'+datedebutmax+'/' +datefin+'/' +criteredatefin+ '/'+datefinmax+'/'+tarifjour+'/' +criteretarifjour+ '/'+tarifjourmax+'/'+tarifnuit+'/'+criteretarifnuit+'/'+tarifnuitmax+'/' +priseenncharge+'/'+criterepriseenncharge+'/'+priseennchargemax+'/'+tarifweekend+'/'+criteretarifweekend+'/'+tarifweekendmax+'/'+referencemarche);
  }

  getRecherchePrestataire(id: any,idtad:any,idprestataire: any,libelle: any,datedbut:any,criteredatedbut:any,datedebutmax : any,datefin : any,criteredatefin : any, datefinmax: any,
    tarifjour:any,criteretarifjour:any,tarifjourmax:any,tarifnuit:any,criteretarifnuit:any,tarifnuitmax:any,
    priseenncharge:any,criterepriseenncharge:any,priseennchargemax:any,tarifweekend:any,criteretarifweekend:any,tarifweekendmax:any,
    structure:FoyerModel,referencemarche:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/recherchetemplateprestataire/' + id +'/'+idtad+'/' +idprestataire+'/'+libelle+'/' + datedbut+'/'+criteredatedbut+'/'+datedebutmax+'/' +datefin+'/' +criteredatefin+ '/'+datefinmax+'/'+tarifjour+'/' +criteretarifjour+ '/'+tarifjourmax+'/'+tarifnuit+'/'+criteretarifnuit+'/'+tarifnuitmax+'/' +priseenncharge+'/'+criterepriseenncharge+'/'+priseennchargemax+'/'+tarifweekend+'/'+criteretarifweekend+'/'+tarifweekendmax+'/'+structure+'/'+referencemarche);
  }

  getRechercheFoyer(id: any,idtad:any,idprestataire: any,libelle: any,datedbut:any,criteredatedbut:any,datedebutmax : any,datefin : any,criteredatefin : any, datefinmax: any,
    tarifjour:any,criteretarifjour:any,tarifjourmax:any,tarifnuit:any,criteretarifnuit:any,tarifnuitmax:any,
    priseenncharge:any,criterepriseenncharge:any,priseennchargemax:any,tarifweekend:any,criteretarifweekend:any,tarifweekendmax:any,foyer: FoyerModel,referencemarche:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/recherchetemplatefoyer/' + id +'/'+idtad+'/'+idprestataire+'/' +libelle+'/' + datedbut+'/'+criteredatedbut+'/'+datedebutmax+'/' +datefin+'/' +criteredatefin+ '/'+datefinmax+'/'+tarifjour+'/' +criteretarifjour+ '/'+tarifjourmax+'/'+tarifnuit+'/'+criteretarifnuit+'/'+tarifnuitmax+'/' +priseenncharge+'/'+criterepriseenncharge+'/'+priseennchargemax+'/'+tarifweekend+'/'+criteretarifweekend+'/'+tarifweekendmax+'/'+foyer+'/'+referencemarche);
  }

  getRechercheCollectivite(id: any,idtad:any,idprestataire: any,libelle: any,datedbut:any,criteredatedbut:any,datedebutmax : any,datefin : any,criteredatefin : any, datefinmax: any,
    tarifjour:any,criteretarifjour:any,tarifjourmax:any,tarifnuit:any,criteretarifnuit:any,tarifnuitmax:any,
    priseenncharge:any,criterepriseenncharge:any,priseennchargemax:any,tarifweekend:any,criteretarifweekend:any,tarifweekendmax:any,foyer: FoyerModel,referencemarche:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/recherchetemplatecollectiivite/' + id +'/'+idtad+'/'+idprestataire+'/' +libelle+'/'+ datedbut+'/'+criteredatedbut+'/'+datedebutmax+'/' +datefin+'/' +criteredatefin+ '/'+datefinmax+'/'+tarifjour+'/' +criteretarifjour+ '/'+tarifjourmax+'/'+tarifnuit+'/'+criteretarifnuit+'/'+tarifnuitmax+'/' +priseenncharge+'/'+criterepriseenncharge+'/'+priseennchargemax+'/'+tarifweekend+'/'+criteretarifweekend+'/'+tarifweekendmax+'/'+foyer+'/'+referencemarche);
  }

  getvalidePerodes(tad:FoyerModel,prestataire: FoyerModel,collectovote: FoyerModel,datedebut:any,datefin:any,id:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'templateprix/valideperiodes/'+tad+'/'+prestataire+'/'+collectovote+'/'+datedebut+'/'+datefin+'/'+id); 
  }
}
