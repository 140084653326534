import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../../shared/session/user';
import {FrmtemplateprixComponent} from '../../templateprix/frmtemplateprix/frmtemplateprix.component';
import {HttpClient} from '@angular/common/http';
import {TemplatePrixService} from '../../services/templatePrix.service';
import {StructureService} from '../../services/structure.service';
import {TypetemplateService} from '../../services/typetemplate.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {FrmTemplateSimulationComponent} from "../frm-template-simulation/frm-template-simulation.component";
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-templates-simulation',
  templateUrl: './templates-simulation.component.html',
  styleUrls: ['./templates-simulation.component.css']
})
export class TemplatesSimulationComponent implements OnInit {

  data: any = [];
  template: any = null;

  collectivites: any = [];
  types: any = [];
  prestataires: any = [];

  delaiannualation: any = '';

  delaiminim: any = '';


  flagapproche: any = '';


  flagretour: any = '';


  pointref: any = '';


  tarifjour: any = '';


  tarifnuit: any = '';


  tarifweekend: any = '';


  tarifmin: any = '';


  flagsamedi: any = '';


  flagdimanche: any = '';


  priseenncharge: any = '';

  typetemplate: any = '';


  datedebut: any = '';

  datefin: any = '';
  heuredebutjour: any = '';


  heuredebutnuit: any = '';


  collectivite: any = '';
  prestataire: any = '';
  pres: any;
  user: User = new User();
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;

  @ViewChild('frmtemplateprix') frmtemplateprix: FrmTemplateSimulationComponent;

  constructor(private http: HttpClient, private templateprixService: TemplatePrixService,
              private structureService: StructureService,
              private typetemplateService: TypetemplateService,
              private authenticationService: AuthenticationService, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.pres = this.user.structure;
    this.show();
    this.showtype();


  }

  edit(id: any) {
    this.frmtemplateprix.edit(this, id);
  }

  openFormTemplatePrix() {
    this.frmtemplateprix.show(this);
  }

  showTemplates(prestataire: any, prestacol: any): void {
    this.show();
  }

  show(): void {
    this.spinner.show();

    this.templateprixService.getTemplatesSimulation(this.user.structure).subscribe(data => {
      this.data = data;
      this.template = null;
      this.spinner.hide();
    });


  }

  showtype(): void {
    this.typetemplateService.getData().subscribe(data => {
      this.types = data;

    });

  }


  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer cet élément !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.templateprixService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.show();
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.templateprixService.delete(id).subscribe(data => {
    //     this.show();
    //   });
    // }
  }
  tabSize(event){
    this.page = event;
   // this.showData();
  }

  tableData(event): void { 
    this.tableSize = event.target.value;
    this.page = 1;
   // this.showData();
  }

}
