<div aria-hidden="true" class="modal fade modal-ds2" data-backdrop="static" id="modalTemplatePrix" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Nouveau Template de Prix
        </h5>

        <button (click)="resetForm()" aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="">
          <div class="">

            <form class="mt-lg-3" name="myForm">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Libellé <label style="color:red">
                    *
                  </label>
                  </label>
                </div>
                <div class="col-sm-7">
                  <input (change)="valideInput()" [(ngModel)]="libelle" [ngClass]="{'libelle':libelle==true}"
                         class="form-control"
                         name="libelle" type="text">
                  <span *ngIf="errorlibelle==true" style="color:red">
                    Champs obligatoire.
                  </span>
                </div>

              </div>
              <div class="form-group row d-none">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0 required">
                    Date de début
                  </label>
                </div>
                <div class="col-sm-7 input-group date">
                  <input (change)="valideInput()" [(ngModel)]="datedebut" [ngClass]="{'error':errordatedebut==true}"
                         class="form-control" id="datedebut"
                         name="datedebut" type="text">

                  <div class="input-group-addon input-group-append">
                    <div class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </div>
                  </div>
                </div>
                <span *ngIf="errordatedebut==true" style="color:red">
                  Champs obligatoire.
                </span>

              </div>

              <div class="form-group row d-none">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0 required">
                    Date de fin
                  </label>
                </div>
                <div class="col-sm-7 input-group date">
                  <input (change)="valideInput()" [(ngModel)]="datefin" [ngClass]="{'error':errordatefin==true}"
                         class="form-control " id="datefin"
                         name="datefin" type="text">
                  <div class="input-group-addon input-group-append">
                    <div class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </div>
                  </div>

                </div>
                <span *ngIf="errordatefin==true" style="color:red">
                  Champs obligatoire.
                </span>

              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Structure <label style="color:red">
                    *
                  </label>
                  </label>
                </div>

                <div class="col-sm-7">
                  <select (change)="valideInput()" (change)="openSelect()"
                          [(ngModel)]="collectivite" [ngClass]="{'error':errorcollectivite==true}"
                          class="form-control struct" id="collectivite" name="collectivite">
                    <option value="0">&nbsp;</option>

                    <option *ngFor="let coll of collectivites;" value="{{coll.id}}">{{coll.nom}}</option>

                  </select>
                  <span *ngIf="errorcollectivite==true" style="color:red">
                    Champs obligatoire.
                  </span>

                </div>

              </div>
              <div class="form-group row d-none">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Tad <label style="color:red">
                    *
                  </label>
                  </label>
                </div>

                <div class="col-sm-7">
                  <select (change)="valideInput()" (change)="openSelectPresta()" [(ngModel)]="tad"
                          [ngClass]="{'error':errortad==true}"
                          class="form-control tadservices" id="tad" name="tad">
                    <option value="0">&nbsp;</option>

                    <option *ngFor="let tad of tads;" value="{{tad.id}}">{{tad.nom}}
                    </option>
                  </select>
                  <span *ngIf="errortad==true" style="color:red">
                    Champs obligatoire.
                  </span>

                </div>

              </div>
              <div class="form-group row d-none">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Prestataire <label style="color:red">
                    *
                  </label>
                  </label>
                </div>

                <div class="col-sm-7">
                  <select (change)="valideInput()" [(ngModel)]="prestataire"
                          [ngClass]="{'error':errorprestataire==true}"
                          class="form-control presta" id="prestataire" name="prestataire">
                    <option value="0">&nbsp;</option>
                    <option *ngFor="let prestataire of prestataires;" value="{{prestataire.id}}">{{prestataire.nom}}
                    </option>
                  </select>
                  <span *ngIf="errorprestataire==true" style="color:red">
                    Champs obligatoire.
                  </span>

                </div>

              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Type de template <label style="color:red">
                    *
                  </label>
                  </label>
                </div>

                <div class="col-sm-7">
                  <select (change)="valideInput()" [(ngModel)]="typetemplate"
                          [ngClass]="{'error':errortypetemplate==true}"
                          class="form-control" id="form-field-select-1" name="typetemplate">
                    <option value="">&nbsp;</option>

                    <option *ngFor="let type of types;" value="{{type.id}}">{{type.libelle}}</option>

                  </select>
                  <span *ngIf="errortypetemplate==true" style="color:red">
                    Champs obligatoire.
                  </span>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Tarif jour (€)
                  </label>
                </div>

                <div class="col-sm-7">
                  <input [(ngModel)]="tarifjour" class="form-control" name="tarifjour"
                         type="number">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Tarif nuit (€)
                  </label>
                </div>

                <div class="col-sm-7">
                  <input [(ngModel)]="tarifnuit" class="form-control" name="tarifnuit" type="number">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Dimanche weekend
                  </label>
                </div>

                <div class="col-sm-7">
                  <label class="ml-1 text-orange-d3 text-600">
                    <span class="text-info">Non </span>
                    <input [(ngModel)]="flagdimanche" [checked]="flagdimanche"
                           class="ace-switch ace-switch-thin text-grey-l1 bgc-warning-d2 mr-1" name="flagdimanche"
                           type="checkbox">
                    <span> Oui</span>
                  </label>
                </div>

              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Samedi weekend
                  </label>
                </div>

                <div class="col-sm-9 input-group">
                  <label class="ml-1 text-orange-d3 text-600">
                    <span class="text-info">Non </span> <input
                    [(ngModel)]="flagsamedi" class="ace-switch ace-switch-thin text-grey-l1 bgc-warning-d2 mr-1"
                    name="flagsamedi" type="checkbox">
                    <span> Oui</span>
                  </label>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Tarif weekend (€)
                  </label>
                </div>
                <div class="col-sm-7">
                  <input [(ngModel)]="tarifweekend" class="form-control" name="tarifweekend" type="number">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Tarif férié (€)
                  </label>
                </div>
                <div class="col-sm-7">
                  <input [(ngModel)]="tarifferie" class="form-control" name="tarifferie"
                         type="number">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Tarif min (€)
                  </label>
                </div>

                <div class="col-sm-7">
                  <input [(ngModel)]="tarifmin" class="form-control" name="tarifmin" type="number">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0 ">
                    Montant prise en charge (€)
                  </label>
                </div>

                <div class="col-sm-7">
                  <input [(ngModel)]="priseenncharge" class="form-control" id="priseenncharge" name="priseenncharge"
                         type="number">

                </div>
              </div>

              <div class="form-group row d-none">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Delai annualation (H)
                  </label>
                </div>

                <div class="col-sm-7">
                  <input [(ngModel)]="delaiannualation" class="form-control" name="delaiannualation" type="number">
                </div>
              </div>

              <div class="form-group row d-none">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Delai minimum (H)
                  </label>
                </div>

                <div class="col-sm-7">
                  <input [(ngModel)]="delaiminim" class="form-control" name="delaiminim" type="number">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Approche facturable
                  </label>
                </div>
                <div class="col-sm-9 input-group">
                  <label class="ml-1 text-orange-d3 text-600">
                    <span class="text-info">Non </span> <input
                    [(ngModel)]="flagapproche" class="ace-switch ace-switch-thin text-grey-l1 bgc-warning-d2 mr-1"
                    name="flagapproche" type="checkbox">
                    <span> Oui</span>
                  </label>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Retour adresse de référence facturable
                  </label>
                </div>

                <div class="col-sm-9 input-group">
                  <label class="ml-1 text-orange-d3 text-600">
                    <span class="text-info">Non </span> <input
                    [(ngModel)]="flagretour" class="ace-switch ace-switch-thin text-grey-l1 bgc-warning-d2 mr-1"
                    name="flagretour" type="checkbox">
                    <span> Oui</span>
                  </label>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Adresse de référence <label style="color:red">
                    *
                  </label>
                  </label>
                </div>
                <div class="col-sm-7">
                  <input #adresseField (change)="valideInput()" [(ngModel)]="pointref"
                         [ngClass]="{'error':erroradresse==true}"
                         class="form-control" id="adresse" name="pointref"
                         placeholder="Adresse"/>
                  <span *ngIf="erroradresse==true" style="color:red">
                      Champs obligatoire.
                    </span>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Ville
                  </label>
                </div>

                <div class="col-sm-7">
                  <input [(ngModel)]="ville" class="form-control" id="ville" name="ville" readonly type="text">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Code Postal
                  </label>
                </div>

                <div class="col-sm-7">
                  <input [(ngModel)]="codePostal" class="form-control" id="codePostal" name="codePostal" readonly
                         type="text">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0 required">
                    Heure debut jour
                  </label>
                </div>

                <div class="col-sm-4 input-group time">
                  <input (change)="valideInput()" [(ngModel)]="heuredebutjour"
                         [ngClass]="{'error':errorheuredebutjour==true}" class="form-control" id="heuredebutjour"
                         name="heuredebutjour" type="text"/>

                  <div class="input-group-addon input-group-append">
                    <div class="input-group-text">
                      <i class="far fa-clock"></i>
                    </div>
                  </div>

                </div>
                <span *ngIf="errorheuredebutjour==true" style="color:red">
                  Champs obligatoire.
                </span>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0 required">
                    Heure debut nuit
                  </label>
                </div>
                <div class="col-sm-4 input-group time">
                  <input (change)="valideInput()" [(ngModel)]="heuredebutnuit"
                         [ngClass]="{'error':errorheuredebutjour==true}" class="form-control" id="heuredebutnuit"
                         name="heuredebutnuit" type="text"/>
                  <div class="input-group-addon input-group-append">
                    <div class="input-group-text">
                      <i class="far fa-clock"></i>
                    </div>
                  </div>

                </div>
                <span *ngIf="errorheuredebutnuit==true" style="color:red">
                  Champs obligatoire.
                </span>
              </div>
            </form>
          </div><!-- /.card-body -->
        </div>
      </div>

      <div class="modal-footer">
        <button (click)="fermer()" class="btn btn-secondary" type="button">
          Fermer
        </button>
        <button (click)="save()" class="btn btn-primary" type="button">
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>
