import {Component, OnInit, ViewChild} from '@angular/core';
import {FrmreservationComponent} from "../frmreservation/frmreservation.component";
import {PassagerService} from "../../services/passager.service";
import {CalendarOptions, FullCalendarComponent} from "@fullcalendar/angular";
import interactionPlugin from '@fullcalendar/interaction';
import {ReservationService} from "../../services/reservation.service";
import {parse} from 'date-fns';
import dayGridPlugin from '@fullcalendar/daygrid';
import frLocale from '@fullcalendar/core/locales/fr';
import {AuthenticationService} from "../../shared/services/authentication.service";
import {User} from "../../shared/session/user";
import {NgxSpinnerService} from "ngx-spinner";
import {LstreservationsComponent} from "../lstreservations/lstreservations.component";


declare var $;

declare function showPoperError(message: any);


@Component({
  selector: 'app-duplicate',
  templateUrl: './duplicate.component.html',
  styleUrls: ['./duplicate.component.css']
})
export class DuplicateComponent implements OnInit {

  reservation: any;
  passagers: any = [];
  passager: any;
  calendarOptions: CalendarOptions;
  dayDuplicates: string[] = [];
  daysScheduled: string[] = [];
  reservations;
  user: User = new User();
  lstReservationComponent: LstreservationsComponent;

  @ViewChild('mycal') calendar: FullCalendarComponent;


  constructor(private passagerService: PassagerService, private reservationService: ReservationService, private authenticationService: AuthenticationService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();

    this.calendarOptions = {
      initialView: 'dayGridMonth',
      displayEventTime: false,
      locale: 'fr',
      buttonText: {
        today: 'Aujourd\'hui',
      },
      height: 300,
    };

  }

  getPassagers() {
    this.passagerService.getPassagers(this.reservation.id).subscribe(data => {
      this.passagers = data;
      if (!!data) {
        this.passager = this.passagers[0];
      }

    });
  }

  getReservations() {
    this.spinner.show();
    this.reservationService.getSimilars(this.reservation.id).subscribe(data => {
      this.reservations = data;
      this.spinner.hide();
      this.render();
    });
  }

  show(reservation: any, lstreservationsComponent: LstreservationsComponent) {

    this.lstReservationComponent = lstreservationsComponent;
    this.dayDuplicates = [];
    this.daysScheduled = [];
    this.reservation = reservation;
    this.getPassagers();
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      displayEventTime: false,
      locale: 'fr',
      buttonText: {
        today: 'Aujourd\'hui',
      },
      selectable: false,
      editable: true,
      plugins: [interactionPlugin],
      dateClick: this.handleDateClick.bind(this),
      eventColor: '#DCDCDC'

    };

    $('#modalFormDuplicate').modal('show');

    $('#modalFormDuplicate').on('shown.bs.modal', function () {
      $('#btnRefresh').click();
    });

    // this.daysScheduled.push('2021-07-05');

    this.getReservations();

    /*this.calendar.getApi().addEvent({
      title : ' ',
      date: '2021-07-05',
      block: true,
      display: 'background'

    });*/

    /*setTimeout(function() {
      this.calendar.getApi().render();

    }, 1000);*/


  }

  handleDateClick(info) {
    if (this.daysScheduled.includes(info.dateStr)) {

    } else if (!!this.dayDuplicates && this.dayDuplicates.includes(info.dateStr)) {
      info.dayEl.style.backgroundColor = '#FFF';
      this.dayDuplicates = this.dayDuplicates.filter(e => e !== info.dateStr);
    } else {
      info.dayEl.style.backgroundColor = '#D9FFCC';
      this.dayDuplicates.push(info.dateStr);
    }
    this.renderDuplicates(this.dayDuplicates);
  }

  close() {

    this.dayDuplicates.splice(0, this.dayDuplicates.length);
    this.calendar.getApi().destroy();
    $('#modalFormDuplicate').modal('hide');

  }

  refresh() {
    this.calendar.getApi().render();
  }

  save() {
    if (this.dayDuplicates.length === 0) {
      showPoperError('Vous devez sélectionner au moins une date');
      return false;
    }

    this.spinner.show();


    this.reservationService.duplicate(this.reservation.id, this.user.id, this.dayDuplicates).subscribe(res => {

      this.spinner.hide();
      this.close();
      this.lstReservationComponent.showReservation();
    });
    console.log(this.dayDuplicates);
  }


  render() {

    this.calendar.getApi().removeAllEvents();
    this.daysScheduled.splice(0, this.daysScheduled.length);

    this.reservations.forEach((reserv: any) => {
      const dateReservation = parse(reserv.reservationDate, 'd-MM-yyyy HH:mm', new Date());
      const dateFormat: string = dateReservation.getFullYear()+ '-' + ('0' + (dateReservation.getMonth() + 1)).slice(-2) + '-' + ('0' + dateReservation.getDate()).slice(-2);

      this.daysScheduled.push(dateFormat);

      this.calendar.getApi().addEvent({
        title: ' ',
        date: dateFormat,
        block: true,
        display: 'background'
      });

    });
    // this.events.push({ title: 'Paris - Marseille (TAD 1)', date: '2021-07-05' })


  }

  renderDuplicates(dayDuplicates: any []) {

    this.render();
    dayDuplicates.forEach((newDate: any) => {
      if (!this.daysScheduled.includes(newDate)) {
          this.calendar.getApi().addEvent({
          title: '',
          date: newDate,
          block: true,
          display: '',
          backgroundColor: 'transparent',
          borderColor: '#EBF8FB',
          className: 'coche'
        });
      }
    });
  }
}
