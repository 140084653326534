<div class="modal fade modal-ds " data-backdrop="static"  id="modalCollectivite" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{lblnom}}
                </h5>

                <button type="button" class="close" (click)="resetForm()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="">
                    <div class="">

                        <form class="mt-lg-3 needs-validation" novalidate>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Siren <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="number"  [(ngModel)]="siret" name="siret"  (change)="valideInput()"
                                        class="form-control col-sm-8 col-md-6 siren" (input)="searchEntrepriseFromSiren()" style="float: left"
                                        placeholder="exemple: 851621649" [ngClass]="{'error':error==true}" >
                                        <span class="fa-1x input-group-append" style="float: left; position: relative; left: -45px; top: 10px "
                                         *ngIf="loadingsiren">
                                          <i class="fas fa-spinner fa-spin"></i>
                                         </span>
                                        <span *ngIf="error==true" style="color: rgb(168, 54, 45);font-size: small;">
                                            Le Champs nom est obligatoire
                                        </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Nom 
                                    </label>
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" readonly
                                        [(ngModel)]="nom" size="4" name="nom" class="form-control col-sm-8 col-md-6"
                                       >
                                    
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Date Création
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input readonly class="form-control" type="text" [ngModel]="datecreation || '' | date:'dd/MM/yyyy'"
                                        name="datecreation" class="form-control col-sm-8 col-md-6" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Adresse
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input  readonly class="form-control"   placeholder="Adresse" id="adresse" [(ngModel)]="adresse"
                                        name="adresse" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Ville
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input readonly type="text" id="ville" [(ngModel)]="ville" name="ville"
                                        class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Code Postal
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input readonly type="text" id="codePostal" [(ngModel)]="codePostal"
                                        name="codePostal" class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Responsable
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" [(ngModel)]="responsable" name="responsable"
                                        class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Email
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input (change)="valideEmail()" [ngClass]="{'error':errorEmail==true}" type="email"
                                        [(ngModel)]="email" name="email" class="form-control col-sm-8 col-md-6">
                                    <span *ngIf="errorEmail==true" style="color: rgb(168, 54, 45);font-size: small;">
                                        L'email est invalide.
                                    </span>
                                </div>

                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Téléphone fixe
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" [(ngModel)]="telephone" name="telephone"
                                        (change)="valideTelFixe()" [ngClass]="{'error':errorTelFixe==true}" class="form-control col-sm-8
                                            col-md-6">
                                    <span *ngIf="errorTelFixe==true" style="color: rgb(168, 54,
                                                    45);font-size: small;">
                                        {{infoErrorTelFixe}}
                                    </span>
                                </div>
                            </div>

                        </form>


                    </div>
                    <!-- /.card-body -->
                </div>


            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="fermer()">
                    Fermer
                </button>
                <button type="button" class="btn btn-primary" (click)='save()'>
                    Enregistrer
                </button>

            </div>
        </div>
    </div>
</div>