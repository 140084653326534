import { Inject, Injectable } from '@angular/core';
import {BROWSER_STORAGE} from '../session/storage';
import {User} from '../session/user';
import {AuthResponse} from '../session/authresponse';
import {Loc8rDataService} from './loc8r-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(@Inject(BROWSER_STORAGE) private storage: Storage, private loc8rDataService: Loc8rDataService) { }

  public getToken(): string {
    return this.storage.getItem('loc8r-token');
  }

  public saveToken(user: User): void {
    this.storage.setItem('loc8r-token', user.token);
    this.saveUser(user);
  }

  public getUser(): User {
    let data = localStorage.getItem('user');
    console.log(data);
    var user: User = null;
    if (!!data) {
       const session = JSON.parse(data);
       const now = new Date();
       console.log(now.getTime() + ' ' + session.expiry);
      if (now.getTime() > session.expiry) {
        localStorage.removeItem('user');
        return null;
      }
      user = session.user;

    }

    return user;

  }

  public saveUser(user: User): void {
    const now = new Date();
    const session = {
      user: user,
      expiry: (now.getTime() + 1800000),
    }
    this.storage.setItem('user', JSON.stringify(session));
  }



  public login(user: User): Promise<any> {
    return this.loc8rDataService.login(user)
      .then((usr: User) => this.saveToken(usr));
  }

  public register(user: User): Promise<any> {
    return this.loc8rDataService.register(user)
      .then();
  }

  public logout(): void {
    this.storage.removeItem('loc8r-token');
    this.storage.removeItem('user');
  }

  public isLoggedIn(): boolean {
    const user: User = this.getUser();
    if (user != null) {
      return true;
    } else {
      return false;
    }
  }

}

