import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FoyerService } from '../../services/foyer.service';
import { MotifService } from '../../services/motif.service';
import { ActeurService } from '../../services/acteur.service';
import { TypeStructureModel } from '../../shared/models/type-structure-model.model';
import { TypeActeurModel } from '../../shared/models/type-acteur-model.model';
import { FrmcollaborateursprestatairesComponent } from 'src/app/prestataire/frmcollaborateursprestataires/frmcollaborateursprestataires.component';
import { FrmtadComponent } from '../frmtad/frmtad.component';
import { StructureService } from 'src/app/services/structure.service';
import { FrmcollectivitetadComponent } from '../frmcollectivitetad/frmcollectivitetad.component';
import { CollectiivteStructureService } from 'src/app/services/collectivitestructure.service ';
import { FrmcollectiviteprestataireComponent } from '../frmcollectiviteprestataire/frmcollectiviteprestataire.component';
import { FrmcollectivitecollaborateurComponent } from '../frmcollectivitecollaborateur/frmcollectivitecollaborateur.component';
import { StructureMotifService } from 'src/app/services/structuremotif.service ';
import { FrmmotifsComponent } from '../frmmotifs/frmmotifs.component';
import { FrmmotifcollectiviteComponent } from './frmmotifcollectivite/frmmotifcollectivite.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { SecurityService } from 'src/app/services/security.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
declare  function  stransAdresse(id: any);
import Swal from 'sweetalert2';
import { FrmcollectivitefoyerComponent } from '../frmcollectivitefoyer/frmcollectivitefoyer.component';
@Component({
  selector: 'app-detailscollectivite',
  templateUrl: './detailscollectivite.component.html',
  styleUrls: ['./detailscollectivite.component.css']
})
export class DetailscollectiviteComponent implements OnInit {
  constructor(private http: HttpClient, private foyerService: FoyerService, private motifService: MotifService,
    private acteurService: ActeurService, private structureService: StructureService
    , private collectivitestructure: CollectiivteStructureService,
    private structuremotif: StructureMotifService, private authenticationService: AuthenticationService,
    private securiteService: SecurityService, private structuremotifService: StructureMotifService,
    private spinner: NgxSpinnerService) { }
  collectivites: any = [];
  collectivite: any;
  data: any = [];
  dataprestataire: any = [];
  dataprestataires: any = [];
  datamotifs: any = [];
  datafoyers: any = [];
  collaborateurs: any = [];
  contacts: any = [];
  motifs: any = [];
  datafoyer: any = [];
  datacollectivte: any = [];
  datacol: any = [];
  dataville: any = [];
  datatype: any = [];
  structure: any = null;
  collaborateur: any = null;
  contact: any = null;
  motif: any = null
  colSelected: any;
  parent: any;
  parent1: any;
  siret: any = '';
  nom: any = '';
  ville: any = '';
  codePostal: any = '';
  adresse: any = '';
  telephone: any = '';
  email: any = '';
  typestructure: any = '';
  responsable: any = '';
  user: User = new User();
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  canCreateCollaborateur = false;
  canUpdateCollaborateur = false;
  canDeleteCollaborateur = false;
  canDetailsCollaborateur = false;
  canConsulterCollaborateur = false;

  canCreateContact = false;
  canUpdateContact = false;
  canDeleteContact = false;
  canDetailsContact = false;
  canConsulterContact = false;

  canCreateTAD = false;
  canUpdateTAD = false;
  canDeleteTAD = false;
  canDetailsTAD = false;
  canConsulterTAD = false;

  canCreateFoyer = false;
  canUpdateFoyer = false;
  canDeleteFoyer = false;
  canDetailsFoyer = false;
  canConsulterFoyer = false;

  canCreatePrestataire = false;
  canUpdatePrestataire = false;
  canDeletePrestataire = false;
  canDetailsPrestataire = false;
  canConsulterPrestataire = false;

  canCreateMotifs = false;
  canUpdateMotifs = false;
  canDeleteMotifs = false;
  canDetailsMotifs = false;
  canConsulterMotifs = false;

  prenomcontact: any = null;
  nomcontact: any = null;
  emailcontact: any = null;
  adressecontact: any = null;
  datenaissancecontact: any = null;
  telephonecontact: any = null;
  telephone2contact: any = null;
  informationcontact: any = null;
  fonctioncontact: any = null;

  prenomcollaborateur: any = null;
  nomcollaborateur: any = null;
  emailcollaborateur: any = null;
  adressecollaborateur: any = null;
  datenaissancecollaborateur: any = null;
  telephonecollaborateur: any = null;
  telephone2collaborateur: any = null;
  informationcollaborateur: any = null;
  fonctioncollaborateur: any = null;

  colselect;
  sirettad = null;
  nomtad = null;
  villetad = null;
  adressetad = null;
  telephonetad = null;
  emailtad = null;
  responsabletad = null;

  siretfoyer = null;
  nomfoyer = null;
  villefoyer = null;
  adressefoyer = null;
  telephonefoyer = null;
  emailfoyer = null;
  responsablefoyer = null;
  idcollectivitefoyer = 0;

  siretprestataire = null;
  nomprestataire = null;
  villeprestataire = null;
  adresseprestataire = null;
  telephoneprestataire = null;
  emailprestataire = null;
  responsableprestataire = null;
  idcollectiviteprestataire = 0;

  idcollectivitemotif = 0;
  collectivte = null;
  libellemotofs = null;
  codeFeature = '';
  typesfoyer= ['FOYER', 'FAMILLEACCUEIL'];
  lstcollaborateurscollectivite = false;
  dateNaissance = false;
  name = false;
  adresseParent = false;


  @ViewChild('frmcollectivitetad') frmcollectivitetad: FrmcollectivitetadComponent;
  @ViewChild('frmmotifsComponent') frmmotifsComponent: FrmmotifcollectiviteComponent;
  @ViewChild('frmcollaborateur') frmcollaborateur: FrmcollectivitecollaborateurComponent;
  @ViewChild('frmcollectivitetprestataire') frmcollectivitetprestataire: FrmcollectiviteprestataireComponent;
  @ViewChild('frmcollectivitetfoyer') frmcollectivitetfoyer: FrmcollectivitefoyerComponent;


  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    //   this.getDroit();
    this.showCollectivite();
    stransAdresse('adresse');
  }

  getlstorganisation(pare) {
    // alert(pare);
    this.parent1 = pare;
  }

  filter(parent, col: any): void {

    this.spinner.show();
    // this.parent1.getOrganisations();
    if (parent != null)
      this.parent = parent;
    this.colSelected = col;
    this.frmcollaborateur.structure = col;
    this.frmcollectivitetprestataire.collectivite = col;
    this.frmmotifsComponent.collectivite = col;
    this.frmcollectivitetad.getselected(this.colSelected);
    this.getDroit();
    if (this.colSelected.id != null) {

      this.structureService.getStructureRattachee(TypeStructureModel.PRESTATAIRE, this.colSelected.id).subscribe(data => {
        this.dataprestataires = data;
        this.dataprestataire = null;
        this.spinner.hide();
      });

      this.structureService.getStructureRattacheeFA(this.typesfoyer, this.colSelected.id).subscribe(data => {
        this.datafoyers = data;
        this.datafoyer = null;
        this.spinner.hide();
      });

      this.foyerService.getTadByCollectivite(this.colSelected.id).subscribe(data => {
        this.data = data;
        this.structure = null;
        this.spinner.hide();
      });

      this.acteurService.getActeurRattachee(TypeActeurModel.COLLABORATEUR, this.colSelected.id).subscribe(data => {
        this.collaborateurs = data;
        this.collaborateur = null;
        this.spinner.hide();
      });

      this.acteurService.getActeurRattachee(TypeActeurModel.CONTACT, this.colSelected.id).subscribe(data => {
        this.contacts = data;
        this.contact = null;
        this.spinner.hide();
      });

      this.structuremotif.getByCollectivite(this.colSelected.id).subscribe(data => {
        this.motifs = data;
        this.motif = null;
        this.spinner.hide();
      });
    }

  }

  openFormMotif() {
    // alert('ok');
    this.frmmotifsComponent.selectedstructure = this.colSelected.id;
    this.frmmotifsComponent.show(this);
  }

  edit(id: any) {
    this.frmmotifsComponent.editMotif(this, id);
  }
  deleteMotif(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.structuremotif.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.filter(this, this.colSelected);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.structuremotif.delete(id).subscribe(data => {
    //     this.filter(this, this.colSelected);
    //   });
    // }
  }

  openFormTad(): void {

    if (!!this.colSelected) {
      this.frmcollectivitetad.lblnom = 'Ajout Unité';
      this.frmcollectivitetad.show(this);
    } else {
      alert('Veuillez sélectionner une structure');
    }
  }
 // $(".struct").attr("disabled", true);

  openFormCollectStructure(typestructure: any, lblstructure: any): void {
    this.frmcollectivitetprestataire.typestructure = typestructure;
    this.frmcollectivitetprestataire.lblstructure = lblstructure;
    this.frmcollectivitetprestataire.collectiviteTad = "Structure";
    this.frmcollectivitetprestataire.selectedstructure = this.colSelected.id;
    //this.frmcollectivitetprestataire.collectivite=this.colSelected;
    this.frmcollectivitetprestataire.show(this);
  }
  
  editPres(id: any) {
    this.frmcollectivitetprestataire.lblstructure = 'Modification Prestataire';
    this.frmcollectivitetprestataire.collectiviteTad = "Structure";
    this.frmcollectivitetprestataire.selectedstructure = this.colSelected.id;
    this.frmcollectivitetprestataire.editPrestaire(this, id);
  }

  openFormCollectStructureFoyer(typestructure: any,lblstructure: any): void {
   this.frmcollectivitetfoyer.typestructures = typestructure;
    this.frmcollectivitetfoyer.lblstructure = lblstructure;
    this.frmcollectivitetfoyer.collectiviteTad = "Structure";
    this.frmcollectivitetfoyer.selectedstructure = this.colSelected.id;
    //this.frmcollectivitetprestataire.collectivite=this.colSelected;
    this.frmcollectivitetfoyer.show(this);
  }
  openFormActeur(typeacteur: any, typestructure: any): void {
    this.lstcollaborateurscollectivite = false;
    this.adresseParent = false;
   // alert(this.lstcollaborateurscollectivite);
    this.frmcollaborateur.selectstructure = this.colSelected.id;
    this.frmcollaborateur.typestructure = typestructure;
    this.frmcollaborateur.lblstructure = 'Structure';
    if (typeacteur == 'COLLABORATEUR') {
      this.frmcollaborateur.lblnom = 'Nouveau Collaborateur';
      this.lstcollaborateurscollectivite = true;
     // alert(this.lstcollaborateurscollectivite);
    } else if (typeacteur == 'CONTACT') {
      this.frmcollaborateur.lblnom = 'Nouveau Contact';
      this.lstcollaborateurscollectivite = false;
      this.adresseParent = true;
      this.dateNaissance = true;
      this.name = true;
    } else if (typeacteur == 'PENSIONNAIRE') {
      this.frmcollaborateur.lblnom = 'Nouveau Enfant';
      this.lstcollaborateurscollectivite = false;
    } else if (typeacteur == 'CHAUFFEUR') {
      this.frmcollaborateur.lblnom = 'Nouveau Chauffeur';
      this.lstcollaborateurscollectivite = false;
    }
    this.frmcollaborateur.typeacteur = typeacteur;
    this.frmcollaborateur.show(this);
  }
  //openFormActeur(typeacteur:any): void  {
  // this.frmcollaborateur.typeacteur=typeacteur;
  //  this.frmcollaborateur.show(this);
  //}
  deleteCollctiviteStructure(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.collectivitestructure.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.filter(this, this.colSelected);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.collectivitestructure.delete(id).subscribe(data => {
    //     this.filter(this, this.colSelected);
    //   });
    // }
  }

  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.structureService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.filter(this, this.colSelected);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // alert(id);
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.structureService.delete(id).subscribe(data => {
    //     this.filter(this, this.colSelected);
    //   });
    // }
  }

  deleteActeur(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.acteurService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.filter(this, this.colSelected);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.acteurService.delete(id).subscribe(data => {
    //     this.filter(this, this.colSelected);
    //   });
    // }
  }
  editActeur(id: any, typeacteur: any): void {
    //  alert(this.colSelected.id)
    this.lstcollaborateurscollectivite = false;
    this.frmcollaborateur.selectstructure = this.colSelected.id;
    this.frmcollaborateur.typestructure = TypeStructureModel.COLLECTIVITE;
    this.frmcollaborateur.lblstructure = 'Structure';
    this.frmcollaborateur.typeacteur = typeacteur;
    if (typeacteur == 'COLLABORATEUR') {
      this.frmcollaborateur.lblnom = 'Modification Collaborateur';
      this.lstcollaborateurscollectivite = true;
    } else if (typeacteur == 'CONTACT') {
      this.frmcollaborateur.lblnom = 'Modification Contact';
    } else if (typeacteur == 'PENSIONNAIRE') {
      this.frmcollaborateur.lblnom = 'Modification Enfant';
    } else if (typeacteur == 'CHAUFFEUR') {
      this.frmcollaborateur.lblnom = 'Modification Chauffeur';
    }
    this.frmcollaborateur.editPersonne(this, id);
  }

  editTad(id: any): void {
    this.frmcollectivitetad.edit(this, id);
    this.frmcollectivitetad.lblnom = 'Modification Unité';
  }


  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }
  showCollectivite(): void {
    this.structureService.getStructuresByType(TypeStructureModel.COLLECTIVITE).subscribe(data => {
      this.collectivites = data;

    });

  }

  getfeature(feature: any) {
    this.codeFeature = feature;
  }
  //ACT_SHOWBUDGET_TAD_ORAGANISATION ACT_DEL_BUDGET__TAD_ORAGANISATION
  getDroit(): void {
    let parent = this.codeFeature.split("_")[1];
    console.log("le code action =", parent);
    this.securiteService.getDroit1(this.user.profilCode, "ACT_SHOWCOLABORATEUR_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.collaborateurs)
      if (!!data) {
        this.canConsulterCollaborateur = true;
      } else {
        this.canConsulterCollaborateur = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_ADD_COLABORATEUR_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.collaborateurs)
      if (!!data) {
        this.canCreateCollaborateur = true;
      } else {
        this.canCreateCollaborateur = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_DEL_COLABORATEUR_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.collaborateurs)
      if (!!data) {
        this.canDeleteCollaborateur = true;
      } else {
        this.canDeleteCollaborateur = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_EDIT_COLABORATEUR_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.collaborateurs)
      if (!!data) {
        this.canUpdateCollaborateur = true;
      } else {
        this.canUpdateCollaborateur = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_SHOWCONTACT_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.contacts)
      if (!!data) {
        this.canConsulterContact = true;
      } else {
        this.canConsulterContact = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_ADD_CONTACT_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.contacts)
      if (!!data) {
        this.canCreateContact = true;
      } else {
        this.canCreateContact = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_DEL_CONTACT_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.contacts)
      if (!!data) {
        this.canDeleteContact = true;
      } else {
        this.canDeleteContact = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_EDIT_CONTACT_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.contacts)
      if (!!data) {
        this.canUpdateContact = true;
      } else {
        this.canUpdateContact = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_SHOWTAD_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canConsulterTAD = true;
      } else {
        this.canConsulterTAD = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_ADD_TAD_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreateTAD = true;
      } else {
        this.canCreateTAD = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_DEL_TAD_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDeleteTAD = true;
      } else {
        this.canDeleteTAD = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_EDIT_TAD_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canUpdateTAD = true;
      } else {
        this.canUpdateTAD = false;
      }
    });
    //alert(parent)
    this.securiteService.getDroit1(this.user.profilCode, "ACT_SHOWFOYER_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canConsulterFoyer = true;
      } else {
        this.canConsulterFoyer = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_ADD_FOYER_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreateFoyer = true;
      } else {
        this.canCreateFoyer = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_DEL_FOYER_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDeleteFoyer = true;
      } else {
        this.canDeleteFoyer = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_EDIT_FOYER_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canUpdateFoyer = true;
      } else {
        this.canUpdateFoyer = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_SHOWPRESTATAIRE_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canConsulterPrestataire = true;
      } else {
        this.canConsulterPrestataire = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_ADD_PRESTATAIRE_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreatePrestataire = true;
      } else {
        this.canCreatePrestataire = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_DEL_PRESTATAIRE_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDeletePrestataire = true;
      } else {
        this.canDeletePrestataire = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_EDIT_PRESTATAIRE_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canUpdatePrestataire = true;
      } else {
        this.canUpdatePrestataire = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_SHOWMOTIF_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canConsulterMotifs = true;
      } else {
        this.canConsulterMotifs = false;
      }
    });

    this.securiteService.getDroit1(this.user.profilCode, "ACT_ADD_MOTIF_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreateMotifs = true;
      } else {
        this.canCreateMotifs = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_DEL_MOTIF_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDeleteMotifs = true;
      } else {
        this.canDeleteMotifs = false;
      }
    });
    this.securiteService.getDroit1(this.user.profilCode, "ACT_EDIT_MOTIF_".concat(parent), this.codeFeature).subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canUpdateMotifs = true;
      } else {
        this.canUpdateMotifs = false;
      }
    });
  }
  RechercheAvanceTAD() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.nomtad = this.nomtad ? this.nomtad : null;
    this.sirettad = this.sirettad ? this.sirettad : null;
    this.emailtad = this.emailtad ? this.emailtad : null;
    this.adressetad = this.adressetad ? this.adressetad : null;
    this.telephonetad = this.telephonetad ? this.telephonetad : null;
    this.responsabletad = this.responsabletad ? this.responsabletad : null;
    this.structureService.getRecherche(this.nomtad, this.sirettad, this.emailtad, this.adressetad, this.telephonetad,
      this.responsabletad, TypeStructureModel.TAD).subscribe(data => {
        this.data = data;
        this.structure = null;
        this.spinner.hide();
      });
      this.tabSize(event);

  }

  rechercheAvanceCollaborateur() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.prenomcollaborateur = this.prenomcollaborateur ? this.prenomcollaborateur : null;
    this.nomcollaborateur = this.nomcollaborateur ? this.nomcollaborateur : null;
    this.emailcollaborateur = this.emailcollaborateur ? this.emailcollaborateur : null;
    this.adressecollaborateur = this.adressecollaborateur ? this.adressecollaborateur : null;
    this.telephonecollaborateur = this.telephonecollaborateur ? this.telephonecollaborateur : null;
    // alert(this.user.structure)
    this.acteurService.getRecherche(this.prenomcollaborateur, this.nomcollaborateur, this.emailcollaborateur, this.adressecollaborateur,
      this.telephonecollaborateur, TypeActeurModel.COLLABORATEUR, this.colSelected.id, TypeStructureModel.COLLECTIVITE).subscribe(data => {
        this.collaborateurs = data;
        this.collaborateur = null;
        this.spinner.hide();
      });
      this.tabSize(event);

  }

  rechercheAvanceContact() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.prenomcontact = this.prenomcontact ? this.prenomcontact : null;
    this.nomcontact = this.nomcontact ? this.nomcontact : null;
    this.emailcontact = this.emailcontact ? this.emailcontact : null;
    this.adressecontact = this.adressecontact ? this.adressecontact : null;
    this.telephonecontact = this.telephonecontact ? this.telephonecontact : null;
    // alert(this.user.structure)
    this.acteurService.getRecherche(this.prenomcontact, this.nomcontact, this.emailcontact, this.adressecontact,
      this.telephonecontact, TypeActeurModel.CONTACT, this.colSelected.id, TypeStructureModel.COLLECTIVITE).subscribe(data => {
        this.contacts = data;
        this.contact = null;
        this.spinner.hide();
      });
      this.tabSize(event);

  }

  RechercheAvanceCollFoyer() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    if (this.idcollectivitefoyer == null)
      this.idcollectivitefoyer = 0;
    this.nomfoyer = this.nomfoyer ? this.nomfoyer : null;
    this.siretfoyer = this.siretfoyer ? this.siretfoyer : null;
    this.emailfoyer = this.emailfoyer ? this.emailfoyer : null;
    this.adressefoyer = this.adressefoyer ? this.adressefoyer : null;
    this.telephonefoyer = this.telephonefoyer ? this.telephonefoyer : null;
    this.responsablefoyer = this.responsable ? this.responsable : null;
    this.collectivitestructure.getRechercheBis(this.idcollectivitefoyer,TypeStructureModel.COLLECTIVITE, this.nomfoyer, this.siretfoyer, 
      this.emailfoyer,
      this.adressefoyer,
      this.telephonefoyer, this.responsablefoyer, TypeStructureModel.FOYER, this.colSelected.id).subscribe(data => {
        this.datafoyers = data;
        this.datafoyer = null;
        this.spinner.hide();
      });
      this.tabSize(event);

  }

  RechercheAvanceCollPrestaire() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    if (this.idcollectiviteprestataire == null)
      this.idcollectiviteprestataire = 0;

    this.nomprestataire = this.nomprestataire ? this.nomprestataire : null;
    this.siretprestataire = this.siretprestataire ? this.siretprestataire : null;
    this.emailprestataire = this.emailprestataire ? this.emailprestataire : null;
    this.adresseprestataire = this.adresseprestataire ? this.adresseprestataire : null;
    this.telephoneprestataire = this.telephoneprestataire ? this.telephoneprestataire : null;
    this.responsableprestataire = this.responsableprestataire ? this.responsableprestataire : null;
    this.collectivitestructure.getRechercheBis(this.idcollectiviteprestataire,TypeStructureModel.COLLECTIVITE, this.nomprestataire, this.siretprestataire,
      this.emailprestataire, this.adresseprestataire,
      this.telephoneprestataire, this.responsableprestataire, TypeStructureModel.PRESTATAIRE, this.colSelected.id).subscribe(data => {
        this.dataprestataires = data;
        this.dataprestataire = null;
        this.spinner.hide();
      });
      this.tabSize(event);

  }


  RechercheAvanceCollMotif() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    //  this.collectivte = this.collectivte ? this.collectivte: null;
    if (this.idcollectivitemotif == null)
      this.idcollectivitemotif = 0;
    this.libellemotofs = this.libellemotofs ? this.libellemotofs : null;

    this.structuremotifService.getRecherche(this.idcollectivitemotif, this.libellemotofs, this.colSelected.id).subscribe(data => {
      this.motifs = data;
      this.motif = null;
      this.spinner.hide();
    });
    this.tabSize(event);

  }

  resetSiret(){
    this.siretprestataire = null;
  }

  resetAdresse(){
    this.adresseprestataire = null;
  }
  resetEmail(){
    this.emailprestataire = null;
  }
  resetNom(){
    this.nomprestataire  = null;
  }
  resetTelephonefixe(){
    this.telephoneprestataire = null;
  }
  resetResponsable(){
    this.responsableprestataire = null;
  }

  resetSiretFoyer(){
    this.siretfoyer = null;
  }

  resetAdresseFoyer(){
    this.adressefoyer = null;
  }
  resetEmailFoyer(){
    this.emailfoyer = null;
  }
  resetNomFoyer(){
    this.nomfoyer  = null;
  }
  resetTelephoneFoyer(){
    this.telephonefoyer = null;
  }
  resetResponsableFoyer(){
    this.responsablefoyer = null;
  }
  resetSiretTad(){
    this.sirettad = null;
  }

  resetAdresseTad(){
    this.adressetad = null;
  }
  resetEmailTad(){
    this.emailtad = null;
  }
  resetNomTad(){
    this.nomtad  = null;
  }
  resetTelephoneTad(){
    this.telephonetad = null;
  }
  resetResponsableTad(){
    this.responsabletad = null;
  }

  resetPrenomContact(){
    this.prenomcontact = null;
  }

  resetAdresseContact(){
    this.adressecontact = null;
  }
  resetEmailContact(){
    this.emailcontact = null;
  }
  resetNomContact(){
    this.nomcontact  = null;
  }
  resetTelephoneContact(){
    this.telephonecontact = null;
  }

  resetPrenomCol(){
    this.prenomcollaborateur = null;
  }

  resetAdresseCol(){
    this.adressecollaborateur = null;
  }
  resetEmailCol(){
    this.emailcollaborateur = null;
  }
  resetNomCol(){
    this.nomcollaborateur  = null;
  }
  resetTelephoneCol(){
    this.telephonecollaborateur = null;
  }

  resetPrenomMotif(){
    this.libellemotofs = null;
  }
 

}
