import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './components/site/home/home.component';
import {LstreservationsComponent} from './reservation/lstreservations/lstreservations.component';
import {FrmreservationComponent} from './reservation/frmreservation/frmreservation.component';
import {LstorgansisationsComponent} from './organisation/lstorgansisations/lstorgansisations.component';
import {LstprestatairesComponent} from './prestataire/lstprestataires/lstprestataires.component';
import {LoginComponent} from './login/login/login.component';
import {TypetemplateComponent} from './referentiel/typetemplate/typetemplate.component';
import {StatutComponent} from './referentiel/statut/statut.component';
import {MotifComponent} from './referentiel/motif/motif.component';
import {SecurityComponent} from './security/security/security.component';
import { LstfoyersComponent } from './foyer/lstfoyers/lstfoyers.component';
import { LstacteursComponent } from './acteur/lstacteurs/lstacteurs.component';
import { FonctionComponent } from './referentiel/fonction/fonction.component';
import { VilleComponent } from './referentiel/ville/ville.component';
import { LstcollaborateursComponent } from './collaborateur/lstcollaborateurs/lstcollaborateurs.component';
import { LsttemplatePrixComponent } from './templateprix/lsttemplateprix/lsttemplateprix.component';
import { LstcollectivitesComponent } from './collectivite/lstcollectivites/lstcollectivites.component';

import { LstchauffeursComponent } from './prestataire/lstchauffeurs/lstchauffeurs.component';
import { LsttadComponent } from './collectivite/lsttad/lsttad.component';

import { LstcollaborateurscollectiviteComponent } from './collectivite/lstcollaborateurscollectivite/lstcollaborateurscollectivite.component';
import { LstcontactsComponent } from './collectivite/lstcontacts/lstcontacts.component';
import { LsttemplateprixcollectiviteComponent } from './collectivite/lsttemplateprixcollectivite/lsttemplateprixcollectivite.component';
import { LstmotifscollectiviteComponent } from './collectivite/lstmotifscollectivite/lstmotifscollectivite.component';
import { LstcollaborateursfoyersComponent } from './foyer/lstcollaborateursfoyers/lstcollaborateursfoyers.component';
import { LstbudgetComponent } from './fonction/lstbudget/lstbudget.component';
import { LstactiviteComponent } from './fonction/lstactivite/lstactivite.component';
import {UsersComponent} from './security/users/users.component';
import {DetailsreservationComponent} from './reservation/detailsreservation/detailsreservation.component';
import {LstsimulationComponent} from './fonction/lstsimulation/lstsimulation.component';
import {LststatistiqueComponent} from './fonction/lststatistique/lststatistique.component';
import {LstprestatairescollectvitesComponent} from './collectivite/lstprestatairescollectvites/lstprestatairescollectvites.component';
import {LstfoyercollectvitesComponent} from './collectivite/lstfoyercollectvites/lstfoyercollectvites.component';
import {CarteComponent} from './reservation/carte/carte.component';
import {LstcollaborateurtadComponent} from './tad/lstcollaborateurtad/lstcollaborateurtad.component';
import {CalendrierComponent} from './reservation/calendrier/calendrier.component';
import {LstpenssionnairetadComponent} from './tad/lstpenssionnairetad/lstpenssionnairetad.component';
import {LstbudgettadComponent} from './tad/lstbudgettad/lstbudgettad.component';
import {LstaffectetadComponent} from './tad/lstaffectetad/lstaffectetad.component';
import {TemplatesSimulationComponent} from './fonction/templates-simulation/templates-simulation.component';
import {LstprestatairetadComponent} from './tad/lstprestatairetad/lstprestatairetad.component';
import {ConfigurationComponent} from './referentiel/configuration/configuration.component';
import {FeriesComponent} from './referentiel/feries/feries.component';
import {HistoriqueSimulationComponent} from './fonction/historique-simulation/historique-simulation.component';
import {AlertsComponent} from './alerts/alerts/alerts.component';
import {TemplatesComponent} from './alerts/templates/templates.component';
import { ContacttadsComponent } from './tad/contacttads/contacttads.component';
import { LsttadsComponent } from './tad/lsttads/lsttads.component';
import {LstdossiersComponent} from './dossiers/lstdossiers/lstdossiers.component';
import {LstprevalsComponent} from './dossiers/lstprevals/lstprevals.component';
import {LstaccordsComponent} from './dossiers/lstaccords/lstaccords.component';
import {RegistreComponent} from "./registre/registre.component";
import {DossierComponent} from "./dossier/dossier.component";

// import { ConfigurationComponent } from './referentiel/configuration/configuration.component';


const routes: Routes = [
  {path: 'accueil', component: HomeComponent},
  {path: 'reservations', component: LstreservationsComponent},
  {path: 'frm-reservation', component: FrmreservationComponent},
  {path: 'frm-reservation/:id', component: FrmreservationComponent},
  {path: 'frm-reservation/duplicate/:id', component: FrmreservationComponent},
  {path: 'organisations', component: LstorgansisationsComponent},
  {path: 'prestataires', component: LstfoyersComponent},
  {path: 'prestataire', component: LstprestatairesComponent},
  {path: 'login', component: LoginComponent},

  {path: 'typetemplate', component: TypetemplateComponent},
  {path: 'statut', component: StatutComponent},
  {path: 'motif', component: MotifComponent},
  {path: 'foyers', component: LstfoyersComponent},
  {path: 'enfantsfoyer', component: LstacteursComponent},
  {path: 'collaborateurs', component: LstcollaborateursComponent},
  {path: 'fonction', component: FonctionComponent},
  {path: 'ville', component: VilleComponent},
  {path: 'templateprix', component: LsttemplatePrixComponent},
 { path: 'chauffeurs', component: LstchauffeursComponent},

 { path: 'collectivite', component: LstcollectivitesComponent},
  { path: 'tads', component: LsttadsComponent},

 { path: 'tad', component: LsttadComponent},
 { path: 'collaborateurcollectivite', component: LstcollaborateurscollectiviteComponent},
 { path: 'contactcollectivite', component: LstcontactsComponent},
 { path: 'templateprixcollectivite', component: LsttemplateprixcollectiviteComponent},
 { path: 'motifscollectivite', component: LstmotifscollectiviteComponent},
 { path: 'collaborateursfoyers', component: LstcollaborateursfoyersComponent},
  {path: 'templateprix', component: LsttemplatePrixComponent},
  {path: 'security', component: SecurityComponent},
  {path: 'budget', component: LstbudgetComponent},
  {path: 'activite', component: LstactiviteComponent},
  {path: 'simulation', component: LstsimulationComponent},
  {path: 'statistique', component: LststatistiqueComponent},
  {path: 'users', component: UsersComponent},
  {path: 'prestatairescollectivite', component: LstprestatairescollectvitesComponent},
  {path: 'foyerscollectivite', component: LstfoyercollectvitesComponent},
  {path: 'reservation/details/:id', component: DetailsreservationComponent},
  {path: 'collaborateurstads', component: LstcollaborateurtadComponent},
  {path: 'reservations/:statut', component: LstreservationsComponent},
  {path: 'reservation/carte', component: CarteComponent},
  {path: 'enfantstad', component: LstpenssionnairetadComponent},
  {path: 'reservation/calendrier', component: CalendrierComponent},
  {path: 'budgettads', component: LstbudgettadComponent},
  {path: 'affecationtads', component: LstaffectetadComponent},
  {path: 'templates-simulation', component: TemplatesSimulationComponent},
  {path: 'config', component: ConfigurationComponent},
  {path: 'prestatairetad', component: LstprestatairetadComponent},
  {path: 'feries', component: FeriesComponent},
  {path: 'logs-simulation', component: HistoriqueSimulationComponent},
  {path: 'alerts', component: AlertsComponent},
  {path: 'alerts/templates', component: TemplatesComponent},
  { path: 'contacttad', component: ContacttadsComponent},


  {path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'dossiers', component: LstdossiersComponent},
  { path: 'prevalidations', component: LstprevalsComponent},
  { path: 'accords', component: LstaccordsComponent},
  { path: 'registre', component: RegistreComponent},
  {path: 'dossier/:numero', component: DossierComponent},






];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
