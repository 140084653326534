import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StructureService } from 'src/app/services/structure.service';

import { NgxNotificationDirection, NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg'
import Swal from 'sweetalert2';


declare var $: any;
declare function stransAdresse(id: any);

@Component({
  selector: 'app-frmprestataires',
  templateUrl: './frmprestataires.component.html',
  styleUrls: ['./frmprestataires.component.css']
})
export class FrmprestatairesComponent implements OnInit {


  prestataire: any = null;
  siret: any = '';
  nom: any = '';
  adresse: any = '';
  responsable: any = '';
  email: any = '';
  collectivite: any = '';
  codepostal: any = '';
  telephone: any = '';
  datecreation: any = '';
  telephoneportable: any = '';
  typestructure: any = '';
  ville: any = '';
  parent;
  codePostal: any = '';
  errornom = false;
  errorsiret = false;
  // erroremail = false;
  errorEmail = false;
  error = true;
  infoError = "";
  loadingsiren = false;
  errormessage = '';

  constructor(private http: HttpClient,
              private structureService: StructureService,
              private readonly ngxNotificationMsgService: NgxNotificationMsgService
  ) { }

  ngOnInit(): void {
    stransAdresse('adresse');
  }

  show(parent) {
  //  alert()
  if (this.siret != null) {
    $(".siretpresta").attr("disabled", true);

  }
    this.parent = parent;
    $('#modalPrestataire').modal('show');
  }
  valideEmail() {
    var validRegex = "^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$";
    if (this.email.trim().length == 0) {
      this.errorEmail = true;
      this.infoError = 'champ obligatoire';
    }
    else if (this.email.trim().length != 0) {
      if (!this.email.match(validRegex)) {
        this.errorEmail = true;
        this.infoError = 'Format email invalide';
      } else {
        this.errorEmail = false;
      }
    } else {
      this.errorEmail = false;
    }
    return this.errorEmail;
  }
  valideInput() {
    this.error = true;
    if (this.nom?.trim().length === 0) {
      // document.getElementById("msgdd").innerHTML="Champ obligatoir";
      this.errornom = true;
      this.error = false;
    } else {
      this.errornom = false;
      // this.error = true;
    }
    if (this.siret?.toString().length === 0 || this.siret?.toString().length < 9) {
      // document.getElementById("msgdd").innerHTML="Champ obligatoir";
      this.errorsiret = true;
      this.error = false;
    } else {
      this.errorsiret = false;
      // this.error = true;
    }
    if (this.valideEmail() === true) {
      this.error = false;
    }

    return this.error;
  }
  pushPrestataire() {
    $('#adresse')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#ville')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#codePostal')[0].dispatchEvent(new Event('input', { bubbles: true }));
    const headers = {};
    const body = {
      siret: this.siret, nom: this.nom,
      adresse: this.adresse, telephone: this.telephone,
      responsable: this.responsable, ville: this.ville, codePostal: this.codePostal,
      email: this.email, typestructure: "PRESTATAIRE", datecreation: this.datecreation, actif: true
    };
   // console.log(this.prestataire);
    if (!this.prestataire) {
      this.structureService.save(headers, body).subscribe(data => {
        $('#modalPrestataire').modal('hide');
        this.parent.show();
        Swal.fire({ icon: 'success', text: 'Ajout réussi' });
        // this.parent.getOrganisations();
      }, err => {
        $('#modalPrestataire').modal('hide');
        this.parent.show();
        console.log(err);
        const errormessage = 409 === err.status ? 'existe déjà !' : '' ;
        Swal.fire({icon: 'error', text: 'Erreur lors de l\'ajout du prestataire: ' + errormessage});
      });
    } else {
      this.structureService.update(headers, body, this.prestataire.id).subscribe(data => {
        $('#modalPrestataire').modal('hide');
        this.parent.show();
        Swal.fire({ icon: 'success', text: 'Modification effectuée' });



      });
    }
    this.resetForm();
  }
  save(): void {
    if (this.valideInput()) {
      this.pushPrestataire();

    }

  }

  edit(parent, id: any): void {
    //  alert(this.prestataire.datecreation)
    // this.parent = parent;
    this.structureService.get(id).subscribe(data => {
      this.prestataire = data;
      if (!!this.prestataire) {
        this.siret = this.prestataire.siret;
        this.nom = this.prestataire.nom;
        this.datecreation = this.prestataire.datecreation;
        this.adresse = this.prestataire.adresse;
        this.responsable = this.prestataire.responsable;
        this.email = this.prestataire.email;
        this.ville = this.prestataire.ville;
        this.codePostal = this.prestataire.codePostal;
        if (this.datecreation != null)
          this.datecreation = this.prestataire.datecreation;
        this.typestructure = this.prestataire.typestructure;
        this.telephone = this.prestataire.telephone;
      }
      this.show(parent)
      // $('#modalPrestataire').modal('show');
    });

  }

  resetForm() {
    this.siret = '';
    this.nom = '';
    this.adresse = '';
    this.ville = '';
    this.codePostal = '';
    this.email = '';
    this.telephone = '';
    this.telephoneportable = '';
    this.responsable = ''
    this.errornom = false;
    this.errorsiret = false;
    // erroremail = false;
    this.errorEmail = false;
    this.datecreation = '';
    this.errormessage = '';
  }

  searchEntrepriseFromSiren() {
     const siren = this.siret;
     this.resetForm();
     this.siret = siren;
     if (this.siret.toString().length < 9) {
        return ;
     }

     this.loadingsiren = true;
     this.structureService.searchFromSiren(this.siret).subscribe(data => {
      this.loadingsiren = false;
      if (data) {
        this.siret = data?.siren;
        this.nom = data?.nom;
        this.datecreation = new Date(+ data?.dateCreation.substr(0, 4), + data?.dateCreation.substr(4, 2) - 1
      , + data?.dateCreation.substr(6, 2));
        console.log('dateCreation --> ', this.datecreation);
        this.adresse = data?.adresse;
        this.ville = data?.ville;
        this.codePostal = data?.codePostal;
      } else {
        this.errormessage = 'Siren non valide';
       }

     }, err => {this.loadingsiren = false; });
  }

}
