import { Injectable } from '@angular/core';
import {AppModule} from "../../app.module";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DossiersService {

  module = AppModule.API_ENDPOINT_V2 + 'demand/';
  constructor(private http: HttpClient) {

  }



  getDossiers() {
    return this.http.get(this.module + 'list');
  }

  validate(dossier: any, statut: any) {
    return this.http.get(this.module + 'valid/' + dossier + '/' + statut);
  }

  getDossiersByStatut(id: any) {
    return this.http.get(this.module + 'dossiers/statut/' + id);
  }

  getDossiersByStatutAndUser(userId: any, statutId: any) {
    return this.http.get(this.module + '/dossiers/statut/user/' + statutId + '/' + userId);
  }

  getDossiersByUser(userId: any) {
    return this.http.get(this.module + '/dossiers/sysuser/'  + userId);
  }

  getRegistre(userId: any) {
    return this.http.get(this.module + '/registre/'  + userId);
  }

  getDocuments(demandId: any) {
    return this.http.get(this.module + 'documents/' + demandId);
  }


  getMembers(chefId: any) {
    return this.http.get(this.module + 'famille/members/' + chefId);
  }

  getDossier(id: any) {
    return this.http.get(this.module + 'get/' +  id );
  }

  getPaiements(id: any) {
    return this.http.get(this.module + 'payments/' +  id );
  }

}
