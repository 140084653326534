import { Component, OnInit, ViewChild } from '@angular/core';
import { FrmcollectivitesComponent } from "../../collectivite/frmcollectivites/frmcollectivites.component";
import { HttpClient } from "@angular/common/http";
import { FoyerService } from "../../services/foyer.service";
import { MotifService } from "../../services/motif.service";
import { ActeurService } from "../../services/acteur.service";
import { StructureService } from "../../services/structure.service";
import { FrmuserComponent } from "./frmuser/frmuser.component";
import { SecurityService } from "../../services/security.service";
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
//import { FrmuseradminComponent } from './frmuseradmin/frmuseradmin.component';
declare function loadOrgs0(data);
declare function loadOrgs(data);
declare function loadOrgs1(data);
declare function loadOrgs2(data);
declare function loadOrgs3(data);
declare function loadOrgs4(data);
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  data: any = [];
  selectedOrg: any=0;
  selectedOrgadmin: any=0;
  orgId: any;
  users: any = [];
  user: User = new User();
  prenom: any = null;
  nom: any = null;
  login: any = null;
  profil: any = null;
  datenaissance: any = null;
  telephone: any = null;
  telephone2: any = null;
  telephone1: any = null;
  information: any = null;
  fonction: any = null;
  actif: any = '';
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  codeProfil:any ='';

  @ViewChild('frmuserComponent') frmuserComponent: FrmuserComponent;
  //@ViewChild('frmuseradminComponent') frmuseradminComponent: FrmuseradminComponent;


  constructor(private http: HttpClient, private foyerService: FoyerService,
    private motifService: MotifService, private acteurService: ActeurService,
    private structureService: StructureService, private securityService: SecurityService,
    private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {

    this.user = this.authenticationService.getUser();
    this.getOrganisations();
    this.getPrestataires();
    this.getFoyer();
    this.getChauffeurs();
    this.getContacts();
    this.codeProfil='PF_AGCOL';
    this.getUsers();
    this.getOrganisationsAdmin();
      
 
  }

  getOrg() {
    this.structureService.get(this.orgId).subscribe(data => {
      this.selectedOrg = data;
      this.selectedOrgadmin = data;
      if(this.codeProfil == 'PF_AGCOL' || this.codeProfil == 'PF_TAD'){
        if(this.selectedOrg.typestructure == TypeStructureModel.COLLECTIVITE){
          this.frmuserComponent.codeProfil = 'PF_AGCOL';
          this.codeProfil = 'PF_AGCOL';
          this.frmuserComponent.typeacteur = TypeActeurModel.COLLABORATEUR;
        //  this.changerProfil('PF_AGCOL');
        }else if(this.selectedOrg.typestructure == TypeStructureModel.TAD){
          this.frmuserComponent.codeProfil = 'PF_TAD';
          this.codeProfil = 'PF_TAD';
          this.frmuserComponent.typeacteur = TypeActeurModel.COLLABORATEUR;
        //  this.changerProfil('PF_TAD');
        }
        
      }else if(this.codeProfil == 'PF_ADMIN' ){
        if(this.selectedOrg.typestructure == TypeStructureModel.ADMIN){
        //  this.frmuseradminComponent.codeProfil = 'PF_ADMIN';
          this.codeProfil = 'PF_ADMIN';
        }
      }
      this.getUsers();
    });
  }
  changerProfil(codeProfil:any){
    if(codeProfil == 'PF_ADMIN'){
      this.codeProfil = codeProfil;
    //  this.frmuseradminComponent.codeProfil = 'PF_ADMIN';
     // this.frmuserComponent.typeacteur = TypeActeurModel.COLLABORATEUR;
      this.getOrganisationsAdmin;
      this.codeProfil = codeProfil;
    }
    else if(codeProfil == 'PF_AGCOL'){
      this.codeProfil = codeProfil;
     // this.frmuserComponent.codeProfil = 'PF_AGCOL';
      this.frmuserComponent.typeacteur = TypeActeurModel.COLLABORATEUR;
      this.getOrganisations;
    }else if(codeProfil == 'PF_PRESTAIRE'){
      this.frmuserComponent.typeacteur = TypeActeurModel.COLLABORATEUR;
      this.frmuserComponent.codeProfil = 'PF_PRESTAIRE';
      this.codeProfil = codeProfil;
      this.getPrestataires();
    }else if(codeProfil == 'PF_GFOYER'){
      this.frmuserComponent.typeacteur = TypeActeurModel.COLLABORATEUR;
      this.frmuserComponent.codeProfil = 'PF_GFOYER';
      this.codeProfil = codeProfil;
      this.getFoyer();
    }else if(codeProfil == 'PF_CONTACT'){
      this.frmuserComponent.typeacteur = TypeActeurModel.CONTACT;
      this.frmuserComponent.codeProfil = 'PF_CONTACT';
      this.getContacts();
      this.codeProfil = codeProfil;
    }else if(codeProfil == 'PF_CHAUFFEUR'){
      this.frmuserComponent.typeacteur = TypeActeurModel.CHAUFFEUR;
      this.frmuserComponent.codeProfil = 'PF_CHAUFFEUR';
      this.codeProfil = codeProfil;
      this.getChauffeurs();
    }
    this.selectedOrg = 0;
    this.getUsers();
  }
  getOrganisationsAdmin() {

    this.structureService.getOrganisationsAdmin().subscribe(data => {
      let organisations = data;
      loadOrgs0(organisations);
      
    });

  }
  getOrganisations() {

    this.structureService.getOrganisations().subscribe(data => {
      let organisations = data;
      loadOrgs(organisations);
      
    });

  }

  getPrestataires() {
    this.structureService.getPrestataires().subscribe(data => {
      let organisations = data;
      loadOrgs1(organisations);

    });

  }
  getChauffeurs() {
    this.structureService.getPrestataires().subscribe(data => {
      let organisations = data;
      loadOrgs4(organisations);

    });

  }

  getFoyer() {
    this.structureService.getFoyers().subscribe(data => {
      let organisations = data;
      loadOrgs2(organisations);

    });

  }
  getContacts() {
    this.structureService.getContacts().subscribe(data => {
      let organisations = data;
      loadOrgs3(organisations);

    });

  }

  getUsers() {
    this.securityService.getUsersProfil(this.selectedOrg.id,this.codeProfil).subscribe(data => {
      this.users = data;
    });
  }

  openForm() {
    // this.frmcollectivitesComponent.show(this);
  }

  createUser() {
    if (!!this.selectedOrg && this.selectedOrg.typestructure == TypeStructureModel.ADMIN){
   //   this.frmuseradminComponent.show(this, this.selectedOrg);
    }
    else{
       if(!!this.selectedOrg)
       this.frmuserComponent.show(this, this.selectedOrg);
      else
      alert('Veuillez selctionner une structure d\'abord');
     
    }
      
 
  }

  edit(user: any) {
    this.frmuserComponent.edit(this, user);
     
  }

  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer cet utilisateur !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.securityService.deleteUser(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.getUsers();
          
            this.tabSize(event);
 
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet utilisateur a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    //  alert(id);
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.securityService.deleteUser(id).subscribe(data => {
    //     this.getUsers();
    //   });
    // }
  }

  rechercheAvance() {
    this.prenom = this.prenom ? this.prenom : null;
    this.nom = this.nom ? this.nom : null;
    this.login = this.login ? this.login : null;
    this.profil = this.profil ? this.profil : null;
    // alert(this.user.structure)
    this.securityService.getRechercheUserBIS(this.prenom, this.nom, this.login, this.profil, this.selectedOrg.id).subscribe(data => {
      this.users = data;
      this.user = null;

    });

  }


  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }

  actifDesactifUser(user: any) {
    this.updateUser(user); 
     
  }
  updateUser(user: any) {
    const headers = {};

    const body = { actif: !user.actif };
    // console.log(this.enfant);
    this.securityService.updateUser(headers, body, user.id).subscribe(data => {
    //  alert(data); 
    });
  }
  editUser(user: any) {
    const headers = {};

    const body = { actif: !user.actif };
    // console.log(this.enfant);
    this.securityService.updateUser(headers, body, user.id).subscribe(data => {
    });
  }

}
