<div class="modal fade modal-ds" data-backdrop="static" id="modalFoyer" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{lblnom}}
                </h5>

                <button type="button" class="close" (click)="fermer()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="">
                    <div class="">

                        <form class="mt-lg-3 " name="myForm">
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Type Lieu d'hébergement <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-7">
                                    <select class="form-control" [(ngModel)]="struct" name="struct"
                                        id="form-field-select-1">
                                        <option>&nbsp;</option>

                                        <option *ngFor="let typefoyer of typestructure" [ngValue]="typefoyer">
                                            {{ typefoyer }}
                                        </option>

                                    </select>
                                </div>

                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Siren
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" [(ngModel)]="siret" name="siret"
                                        class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Nom <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" [(ngModel)]="nom" name="nom"
                                        class="form-control col-sm-8 col-md-6" (change)="valideInput()"
                                        [ngClass]="{'errornom':errornom==true}">
                                    <span *ngIf="errornom==true" style="color:red">
                                        Champs obligatoire.
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Adresse
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input class="form-control" placeholder="Adresse" id="adresse" [(ngModel)]="adresse"
                                        name="adresse" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Ville
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input readonly type="text" id="ville" [(ngModel)]="ville" name="ville"
                                        class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Code Postal
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input readonly type="text" id="codePostal" [(ngModel)]="codePostal"
                                        name="codePostal" class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Responsable
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" [(ngModel)]="responsable" name="responsable"
                                        class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Email
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" [(ngModel)]="email" name="email"
                                        class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Téléphone fixe
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" [(ngModel)]="telephone" name="telephone"
                                        class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>

                        </form>


                    </div>
                    <!-- /.card-body -->
                </div>


            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="fermer()">
                    Fermer
                </button>
                <button type="button" class="btn btn-primary" (click)='save()'>
                    Enregistrer
                </button>

            </div>
        </div>
    </div>
</div>