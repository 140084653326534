import { Component, OnInit, ViewChild } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {AppModule} from '../../app.module';
import {FoyerService} from "../../services/foyer.service";
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { ActeurService } from 'src/app/services/acteur.service';
import { FrmfoyersComponent } from '../frmfoyers/frmfoyers.component';
import { StructureService } from 'src/app/services/structure.service';
import { DetailsfoyersComponent } from '../detailsfoyers/detailsfoyers.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { FrmenfantComponent } from 'src/app/collectivite/detailstad/frmenfant/frmenfant.component';
import { MotifService } from 'src/app/services/motif.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { SecurityService } from 'src/app/services/security.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
declare  function  stransAdresse(id: any);
import Swal from 'sweetalert2';


@Component({
  selector: 'app-lstfoyers',
  templateUrl: './lstfoyers.component.html',
  styleUrls: ['./lstfoyers.component.css']
})
export class LstfoyersComponent implements OnInit {

  data: any = [];
  datacol: any = [];
  dataville: any = [];
  datatype: any = [];
  structure: any = null;

  siret = null;
  nom = null;
  ville = null;
  adresse = null;
  telephone = null;
  email = null;
  typestructure = TypeStructureModel.FOYER;
  responsable = null;
  collectivite = null;

  user: User = new User();
  pagesize = 5;
  i;
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canDetails = false;
  parent:any;
  typesfoyer= ['FOYER', 'FAMILLEACCUEIL'];

  @ViewChild ('frmfoyersComponent') frmfoyersComponent: FrmfoyersComponent;
  @ViewChild ('detailsfoyersComponent') detailsfoyersComponent: DetailsfoyersComponent;

  constructor(private http: HttpClient, private foyerService: FoyerService,private authenticationService: AuthenticationService
    , private acteurService: ActeurService,private structureService: StructureService,
    private motifService: MotifService,private paginationService:PaginationService,
    private securiteService : SecurityService,private spinner: NgxSpinnerService) { }


  ngOnInit(): void {

    this.user = this.authenticationService.getUser();
    
    this.show();
    this.parent = this.user.structure;
  
  this.getDroit();
  }


  show(): void {
    this.spinner.show();
    if(this.user.structure==0){
      this.foyerService.getDataFoyerAdmin(this.typesfoyer).subscribe(data => {
        this.data = data;
        this.structure = null;
        this.spinner.hide();
     });
    }else if(this.user.profilCode == "PF_GFOYER"){
      this.structureService.getCollectivite(this.user.structure).subscribe(data => {
        this.data = data;
        this.structure = null;
        this.spinner.hide();
     });
    }else if(this.user.profilCode == "PF_TAD"){
      this.structureService.getStructureRattacheetadFA(this.typesfoyer, this.user.parent).subscribe(data => {
        this.data = data;
        this.structure = null;
        this.spinner.hide();
      }); 
    }else if(this.user.profilCode == "PF_AGCOL"){
      this.structureService.getStructureRattacheetadFA(this.typesfoyer,this.user.structure).subscribe(data => {
        this.data = data;
        this.spinner.hide();
     });
    }else{
      this.structureService.getFoyerPrestataireFA(this.typesfoyer,this.user.structure).subscribe(data => {
        this.data = data;
        this.spinner.hide();
     });
    }
  }

  openFormFoyer() {
    this.frmfoyersComponent.show(this);
    this.frmfoyersComponent.lblnom = 'Ajout lieu d\'hebergement'
  }

  openDetails(id: any): void {
    this.detailsfoyersComponent.details(this,id);
  }

  RechercheAvance() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    this.nom = this.nom ? this.nom: null;
    this.siret = this.siret ? this.siret: null;
    this.email = this.email ? this.email: null;
    this.adresse = this.adresse ? this.adresse: null;
    this.telephone = this.telephone ? this.telephone: null;
    this.responsable = this.responsable ? this.responsable: null; 
    this.structureService.getRechercheBIS(this.nom,this.siret,this.email,this.adresse,
      this.telephone,this.responsable,TypeStructureModel.FOYER,this.user.id).subscribe(data => {
      this.data = data;
      this.structure = null;
      this.spinner.hide();
   });
   this.tabSize(event);
  }
  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.foyerService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.show();
          
            this.tabSize(event);
           // this.page = this.page -1;
          
         // this.page = this.tableSize -1;
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car ce lieu d\'hebergement a déjà été utilisé ailleurs" });
          }
        );
      }
    })
  //  alert(id);
    //  if (confirm('Voulez vraiment supprimer cet élément ? ' )) {
    //     this.foyerService.delete(id).subscribe(data => {
    //       this.show();
    //     });
    //  }
    }

  edit(id:any){
    this.frmfoyersComponent.edit(this, id);
    this.frmfoyersComponent.lblnom = 'Modification lieu d\'hebergement'
  }

  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.data = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }

  tabSize(event){
    this.page = event;
   // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
   // this.showData();
  }


  getDroit():void{
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_FOYER").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreate = true;
      }  else {
        this.canCreate = false;
      }
   });
  
  this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_FOYER").subscribe(data => {
  console.log(this.data)
  if (!!data) {
    this.canDelete = true;
  }  else {
    this.canDelete = false;
  }
  });
  
  this.securiteService.getDroit(this.user.profilCode, "ACT_EDIT_FOYER").subscribe(data => {
  console.log(this.data)
  if (!!data) {
    this.canUpdate = true;
  }  else {
    this.canUpdate = false;
  }
  });

  this.securiteService.getDroit(this.user.profilCode, "ACT_DETAILS_FOYER").subscribe(data => {
    console.log(this.data)
    if (!!data) {
      this.canDetails = true;
    }  else {
      this.canDetails = false;
    }
    });
  
   }

   reset1(){
    this.siret = null;
   }
   reset2(){
    this.nom = null;
   }
   reset3(){
    this.email = null;
   }
   reset4(){
    this.adresse = null;
   }
   reset5(){
    this.responsable = null;
   }

   reset6(){
    this.telephone = null;
   }
}
