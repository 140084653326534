<div class="modal fade modal-ds " id="modalForm" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >
          Type de template
        </h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="">
          <div class="">

            <form class="mt-lg-3">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Code
                  </label>
                </div>

                <div class="col-sm-9">
                  <input type="text" [(ngModel)]="code"  name="code" class="form-control col-sm-8 col-md-3">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Libellé
                  </label>
                </div>

                <div class="col-sm-9">
                  <input type="text" [(ngModel)]="libelle" name="libelle" class="form-control col-sm-8 col-md-6">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Description
                  </label>
                </div>

                <div class="col-sm-9">
                  <textarea class="form-control" [(ngModel)]="description"  name="description" placeholder="" style="overflow: hidden; overflow-wrap: break-word; resize: horizontal; height: 62px;"></textarea>
                </div>
              </div>


            </form>


          </div><!-- /.card-body -->
        </div>


      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Fermer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()">
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
