import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FrmcollectivitecollaborateurComponent } from 'src/app/collectivite/frmcollectivitecollaborateur/frmcollectivitecollaborateur.component';
import { FrmcontactsComponent } from 'src/app/collectivite/frmcontacts/frmcontacts.component';
import { ActeurService } from 'src/app/services/acteur.service';
import { FonctionService } from 'src/app/services/fonction.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { PersonneService } from 'src/app/services/personne.service ';
import { SecurityService } from 'src/app/services/security.service';
import { StructureService } from 'src/app/services/structure.service';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
declare function stransAdresse(id: any);
declare var $: any;
import Swal from 'sweetalert2';
@Component({
  selector: 'app-contacttads',
  templateUrl: './contacttads.component.html',
  styleUrls: ['./contacttads.component.css']
})
export class ContacttadsComponent implements OnInit {

  data: any = [];
  collaborateur: any = null;
  collectivites: any = [];
  user: User = new User();
  //acteur: Acteur = new Acteur();
  collectivite: any = null;
  typeacteur: any = '';
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  dateNaissance = true;
  name = true;

  prenom: any = null;
  nom: any = null;
  email: any = null;
  adresse: any = null;
  datenaissance: any = null;
  telephone: any = null;
  telephone2: any = null;
  information: any = null;
  fonction: any = null;

  canCreate = false;
  canEdit = false;
  canDelete = false;
  canDetails = false;
  foyers: any = [];
  idfoyer = 0;
  adresseParent = true;


  @ViewChild('frmcontactComponent') frmcontactComponent: FrmcontactsComponent;
  @ViewChild('frmcollaborateur') frmcollaborateur: FrmcollectivitecollaborateurComponent;
  constructor(private http: HttpClient, private structureService: StructureService, private acteurService: ActeurService,
    private fonctionService: FonctionService, private personneService: PersonneService,
    private authenticationService: AuthenticationService, private securiteService: SecurityService,
     private paginationService: PaginationService,private spinner: NgxSpinnerService) { }


  ngOnInit(): void {

    this.user = this.authenticationService.getUser();

    this.showStructure();
    this.showDoits();
    this.show2();

    stransAdresse('adresse');
  }
  showDoits() {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADDTAD_CONTACTCOLLEC").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_DELTAD_CONTACTCOLLEC").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_EDITTAD_CONTACT_COLLECTIVITE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canEdit = true;
      } else {
        this.canEdit = false;
      }
    });
  }

  filter(parent: any, structure: any) {
    this.show2();
  }
  show2(): void {
    this.spinner.show();
    // alert(this.collectivite)
    this.acteurService.getActeurRattacheesAll(TypeActeurModel.CONTACT, this.user.structure, TypeStructureModel.COLLECTIVITE).subscribe(data => {
      this.data = data;
      this.collaborateur = null;
      this.spinner.hide();
    });

  }

  rechercheAvance() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    if (this.idfoyer == null )
    this.idfoyer = 0;
    this.prenom = this.prenom ? this.prenom : null;
    this.nom = this.nom ? this.nom : null;
    this.email = this.email ? this.email : null;
    this.adresse = this.adresse ? this.adresse : null;
    this.telephone = this.telephone ? this.telephone : null;
    // alert(this.user.structure)
    this.acteurService.getRechercheBis(this.idfoyer,this.prenom, this.nom, this.email, this.adresse,
      this.telephone, TypeActeurModel.CONTACT, this.user.structure, TypeStructureModel.COLLECTIVITE).subscribe(data => {
        this.data = data;
        this.spinner.hide();
      });
      this.tabSize(event);

  }
  openFormActeur(typeacteur: any, typestructure: any): void {
    // if (this.user.structure != 0) {
    //   //alert()
    //   this.structureService.get(this.user.structure).subscribe(data => {
    //     this.frmcollaborateur.structure = data;
    //   });
    //   this.frmcollaborateur.selectstructure = this.user.structure
    // }
    if (this.user.profilCode == "PF_TAD") {
      this.structureService.getCollectiviteByProfil(this.user.id).subscribe(data => {
        this.frmcollaborateur.structure = data; 
      });
     
    }
    this.frmcollaborateur.typestructure = typestructure;
    this.frmcollaborateur.lblstructure = 'Structure';
    this.frmcollaborateur.lblnom = 'Contact';
    this.frmcollaborateur.typeacteur = typeacteur;
    this.frmcollaborateur.show(this);
  }
  openFormContact() {
    // alert('ok');
    this.frmcontactComponent.show(this);
  }

  edit(id: any) {
    this.frmcollaborateur.lblstructure = 'Structure';
    this.frmcollaborateur.lblnom = 'Modification Contact';
    this.frmcollaborateur.typeacteur = 'CONTACT';
    this.frmcollaborateur.editPersonne(this, id);
  }

  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.acteurService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.show2();
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car ce contact a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.acteurService.delete(id).subscribe(data => {
    //     this.show2();
    //   });
    // }
  }

  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.data = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }

  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }
  showStructure(): void {
    this.structureService.getCollectiviteByProfil(this.user.id).subscribe(data => {
      this.foyers = data;
    });
  }
  reset1(){
    this.prenom = null;
   }
   reset2(){
    this.nom = null;
   }
   reset3(){
    this.email = null; 
   }
   reset4(){
    this.adresse = null;
   }
   reset5(){
    this.telephone = null;
   }

   reset6(){
    this.idfoyer = 0;
   }

}
