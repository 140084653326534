<div class="modal fade modal-ds" data-backdrop="static" #acteurModal id="modalTransfer" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{lblnom}}
                </h5>

                <button type="button" class="close" (click)="fermer()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="">
                    <div class="">

                        <form class="mt-lg-3 Needs-validation1" novalidate>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label
                                    text-sm-right pr-0">
                                    <label class="mb-0">
                                        {{lblstructure}} origine :
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <label class="mb-0">
                                        {{structureorigine}} 
                                    </label>
                                </div>

                            </div>
                            
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label
                                    text-sm-right pr-0">
                                    <label class="mb-0">
                                        {{lblstructure}} <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <select class="form-control" [(ngModel)]="selectstructure" name="selectstructure">
                                        <option value="">&nbsp;</option>
                                        <option *ngFor="let structure of
                                            structures;" value="{{structure.id}}">{{structure.nom}}</option>
                                    </select>
                                </div>

                            </div>
                            

                        </form>
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="fermer()">
                    Fermer
                </button>
                <button type="button" class="btn btn-primary" (click)="save()">
                    Enregistrer
                </button>

            </div>
        </div>
    </div>
</div>