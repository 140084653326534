import {Component, OnInit, ViewChild, ViewEncapsulation, EventEmitter} from '@angular/core';
import {TypetemplateComponent} from '../../referentiel/typetemplate/typetemplate.component';
import {HttpClient} from '@angular/common/http';
import {ReservationService} from '../../services/reservation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {StatutService} from '../../services/statut.service';
import {PassagersComponent} from '../passagers/passagers.component';
import {NoteComponent} from '../note/note.component';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {User} from 'src/app/shared/session/user';
import {SecurityService} from 'src/app/services/security.service';
import {id} from 'date-fns/locale';
import {TypeStructureModel} from 'src/app/shared/models/type-structure-model.model';
import {StructureService} from 'src/app/services/structure.service';
import {BonComponent} from '../bon/bon.component';
import {FrmreservationComponent} from '../frmreservation/frmreservation.component';
import {FrmchauffeurreservationComponent} from '../frmchauffeurreservation/frmchauffeurreservation.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {parse} from "date-fns";
import {DuplicateComponent} from "../duplicate/duplicate.component";
import { BudgetService } from 'src/app/services/budget.service ';
import Swal from 'sweetalert2';
declare function createExcelExport();

declare function loadWizard();

declare function showPoperSuccess(message: any);

declare function showPoperError(message: any);


declare var $: any;

@Component({
  selector: 'app-lstreservations',
  templateUrl: './lstreservations.component.html',
  styleUrls: ['./lstreservations.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class LstreservationsComponent implements OnInit {

  reservations: any = [];
  showAction = true;
  reservation;
  user: User = new User();
  title = 'Liste des réservations';
  private routeSub: Subscription;
  statues: any = [];
  structure: any;
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;

  @ViewChild('passagersComponent') passagersComponent: PassagersComponent;
  @ViewChild('chauffeurComponent') chauffeurComponent: FrmchauffeurreservationComponent;
  @ViewChild('notesComponent') notesComponent: NoteComponent;
  @ViewChild('bonComponent') bonComponent: BonComponent;
  @ViewChild('frmDuplicate') frmDuplicate: DuplicateComponent;

  statut: any;
  canCreateReservation = false;
  tads: any = [];
  idtad = 0;
  prestataires: any = [];
  idprestataire = 0;
  statuts: any = [];
  idstatut = 0;
  passager = null;
  datecrer = null;
  dateMax = null;
  critereDaterer: any = '1';
  creerpar = null;
  critereDateDebut = null;
  dateDebutMax = null;
  canGenerBon = false;
  canEditReservation = false;
  isEnattente = false;
  canDupliqueReservation;
  canShowDetailsReservation = false;
  canExportReservation = false;
  canAddNoteToReservation = false;
  canAddChauffeurReservation = true;
  canDelete = false;
  noEditableStatut = ['RJT', 'EXP', 'ANNULE', 'TER', 'ANL', 'BRL', 'BRD'];
  editableStatut = ['EAVAL', 'BRL', 'BRD', 'VAL'];


  @ViewChild('frmreservationsComponent') frmreservationsComponent: FrmreservationComponent;

  constructor(private http: HttpClient, private reservationService: ReservationService, private securiteService: SecurityService
    , private structureService: StructureService, private route: ActivatedRoute, private statutService: StatutService, private authenticationService: AuthenticationService,
    private budgetService: BudgetService, private spinner: NgxSpinnerService, private router: Router) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    loadWizard();
    this.showTAD();
    this.showPrestataire();
    // this.showStatut();

    this.structure = this.user.structure;
    this.getDroit();
    this.statutService.getManualStatues().subscribe(data => {
      this.statues = data;
    });

    this.showReservation();
    this.reservationService.getEventEmitter().subscribe(() => {
      this.showReservation();
    });
  }

  showReservation() {
    this.spinner.show();
    this.routeSub = this.route.params.subscribe(params => {
      console.log(params);
      if (!!params && !!params.statut) {
        this.isEnattente = true;
        this.title = 'En attente de validation';
        this.showAction = false;
        this.idstatut = params.statut;
        $('#statutselect').attr('disabled', true);
        this.rechercheAvance();

      } else {
        if (this.structure === 0) {
          this.reservationService.getDataBis().subscribe(data => {
            this.reservations = data;
            this.render();
            this.spinner.hide();

            // console.log(this.reservations);
          });
        } else if (this.user.profilCode == 'PF_PRESTAIRE') {
         // alert(this.user.parent)
          this.reservationService.getByPrestataireProfil(this.structure).subscribe(data => {
            this.reservations = data;
            this.render();
            this.spinner.hide();

            //   console.log(this.reservations);
          });

        } else if (this.user.profilCode == 'PF_GFOYER') {
          this.reservationService.getByFoyerProfil(this.structure).subscribe(data => {
            this.reservations = data;
            this.render();
            this.spinner.hide();

            //  console.log(this.reservations);
          });

        } else if (this.user.profilCode == 'PF_AGCOL') {
          this.reservationService.getByCollectiviteProfil(this.structure).subscribe(data => {
            this.reservations = data;
            this.render();
            this.spinner.hide();

            // console.log(this.reservations);
          });

        } else if (this.user.profilCode == 'PF_TAD') {
          this.reservationService.getByStructureBis1(this.user.personne).subscribe(data => {
            this.render();
            this.reservations = data;
            this.spinner.hide();

          });
        }else if (this.user.profilCode == 'PF_CHAUFFEUR') {
          this.reservationService.getByChauffeur(this.user.id).subscribe(data => {
            this.render();
            this.reservations = data;
        //    console.log(data);
            this.spinner.hide();

          });
        }else if (this.user.profilCode == 'PF_CONTACT') {
          this.reservationService.getByContact(this.user.id).subscribe(data => {
            this.render();
            this.reservations = data;
            console.log(data);
            this.spinner.hide();

          });
        }
      }
    });
  }

  rechercheAvance() {
    this.spinner.show();

    $('.datatable').DataTable().destroy();


    $('#datecrer')[0].dispatchEvent(new Event('input', {bubbles: true}));
    if (this.critereDaterer == '4') {
      $('#dateMax')[0].dispatchEvent(new Event('input', {bubbles: true}));
    }
    if (this.idtad == null) {
      this.idtad = 0;
    }
    if (this.idprestataire == null) {
      this.idprestataire = 0;
    }
    // alert(this.datecrer);
    if (this.idstatut == null) {
      this.idstatut = 0;
    }
    if(this.creerpar === '.' || this.creerpar === ';' || this.creerpar === '/' || this.creerpar === "\'")
    this.creerpar = null;

    if(this.passager === '.' || this.passager === ';' || this.passager === '/' || this.passager === "'\'")
    this.passager = null;

    this.passager = this.passager ? this.passager : null;
    this.creerpar = this.creerpar ? this.creerpar : null;
    this.datecrer = this.datecrer ? this.datecrer : null;
    this.dateMax = this.dateMax ? this.dateMax : null;
    this.reservationService.getRecherche(this.idtad, this.creerpar, this.idprestataire, this.critereDaterer, this.datecrer, this.dateMax, this.idstatut, this.passager, this.user.id).subscribe(data => {
      this.reservations = data;
      this.render();
      this.spinner.hide();

      // console.log(this.reservations);
    });
    this.tabSize(event);

  }

  reset1(){
    this.datecrer = null;
   }
   reset2(){
    this.dateMax = null;
   }
   resetTad(){
    this.idtad= 0;
   }
   resetPrestataire(){
    this.idprestataire = 0;
   }
   resetStatut(){
    this.idstatut = 0;
   }
   resetCreePar(){
    this.creerpar = null;
   }
   resetPassager(){
    this.passager = null;
   }
  viewPassagers(reservation) {
    this.passagersComponent.show(reservation);
  }


  showStatutSelect(reservation: any) {
    this.statut = reservation.statut.id;
    if( this.user.profilCode == 'PF_GFOYER' || this.user.profilCode == 'PF_CHAUFFEUR' || this.user.profilCode == 'PF_CONTACT'){
      showPoperError('Vous ne pouvez pas modifier le statut de cette réservation');

    } else {
      if ( !this.noEditableStatut.includes(reservation.statut.code)) {
        if(reservation.statut.code == 'VAL') {
             if(!this.checkDelaiAnnulation(reservation) && this.user.profilCode !== 'PF_PRESTAIRE') {
               showPoperError('Impossible d\' annuler une réservation qui a dépassé le delai d\'annulation');
             } else {
                this.editStatus(reservation);
               $("#statut" + reservation.id + " option").attr("disabled","disabled");
               $("#statut" + reservation.id + " option[value='9']").removeAttr("disabled");

             }
        } else {
          this.editStatus(reservation);
        }
      }  else {
        showPoperError('Impossible de modifier le statut de cette réservation');
      }
    }


  }

  editStatus(reservation: any) {
    $('#statut' + reservation.id).show();
    $('#statut' + reservation.id).focus();
    $('#lblstatut' + reservation.id).hide();
  }

  onRechercheBlur(){
    this.rechercheAvance();
  }

  onStatutBlur(reservation) {
    $('#statut' + reservation.id).hide();
    $('#lblstatut' + reservation.id).show();
  }

  updateStatut(reservation: any) {
    if (this.statut === '9' && !this.checkDelaiAnnulation(reservation)) {
      showPoperError('Vous ne pouvez plus annuler cette réservation');
      document.getElementById('statut' + reservation.id).style.display = 'none';
      document.getElementById('lblstatut' + reservation.id).style.display = 'block';
      return false;
    }




    this.statutService.get(this.statut).subscribe(data => {
      const stat = data;

      const headers = {};
      // @ts-ignore
      const body = {id: stat.id, code: stat.code, libelle: stat.libelle, actif: stat.actif};
      this.reservationService.updateStatut(headers, body, reservation.id, this.user.id).subscribe(data => {
        document.getElementById('statut' + reservation.id).style.display = 'none';
        document.getElementById('lblstatut' + reservation.id).style.display = 'block';
        this.ngOnInit();
      });
    });

  }


  viewNotes(reservation) {
    this.notesComponent.show(reservation);
  }

  getDroit(): void {

    this.securiteService.getDroit(this.user.profilCode, 'ACT_ADD_CHAUFFEUR').subscribe(data => {
      // console.log(this.reservations)
      if (!!data) {

        this.canAddChauffeurReservation = true;

      } else {
        this.canAddChauffeurReservation = false;
        // alert(this.canCreateReservation)
      }

    });
    this.securiteService.getDroit(this.user.profilCode, 'ACT_ADD').subscribe(data => {
      // console.log(this.reservations)
      if (!!data) {
        this.canCreateReservation = true;
      } else {
        this.canCreateReservation = false;
        // alert(this.canCreateReservation)
      }

    });
    this.securiteService.getDroit(this.user.profilCode, 'ACT_BON').subscribe(data => {
      // console.log(this.reservations)
      if (!!data) {
        this.canGenerBon = true;
      } else {
        this.canGenerBon = false;
        // alert(this.canCreateReservation)
      }

    });
    this.securiteService.getDroit(this.user.profilCode, 'ACT_EDIT').subscribe(data => {
      // console.log(this.reservations)
      if (!!data) {
        if (!this.isEnattente) {
        this.canEditReservation = true;}
      } else {
        this.canEditReservation = false;
        // alert(this.canCreateReservation)
      }

    });
    this.securiteService.getDroit(this.user.profilCode, 'ACT_DUPPLIQUER').subscribe(data => {
//  console.log(this.reservations)
      if (!!data) {
        if (!this.isEnattente) {
        this.canDupliqueReservation = true;}
      } else {
        this.canDupliqueReservation = false;
        // alert(this.canCreateReservation)
      }

    });
    this.securiteService.getDroit(this.user.profilCode, 'ACT_DETAILS').subscribe(data => {
      // console.log(this.reservations)
      if (!!data) {
        this.canShowDetailsReservation = true;
      } else {
        this.canShowDetailsReservation = false;
        // alert(this.canCreateReservation)
      }

    });
    this.securiteService.getDroit(this.user.profilCode, 'ACT_EXPORT').subscribe(data => {
      // console.log(this.reservations)
      if (!!data) {
        this.canExportReservation = true;
      } else {
        this.canExportReservation = false;
        // alert(this.canCreateReservation)
      }

    });

    this.securiteService.getDroit(this.user.profilCode, 'ACT_NOTE').subscribe(data => {
      // console.log(this.reservations)
      if (!!data) {
        this.canAddNoteToReservation = true;
      } else {
        this.canAddNoteToReservation = false;
        // alert(this.canCreateReservation)
      }

    });

    this.securiteService.getDroit(this.user.profilCode, 'ACT_DEL').subscribe(data => {
      if (!!data) {
        if (!this.isEnattente) {
        this.canDelete = true;}
      } else {
        this.canDelete = false;
      }

    });


  }

  showTAD(): void {
   // this.structureService.getStructuresByType(TypeStructureModel.TAD).subscribe(data => {
   //   this.tads = data;

   // });

    this.structureService.getTadByProfil(this.user.id).subscribe(data => {
      this.tads = data;

    });

  }

  showPrestataire(): void {
    this.structureService.getPrestatairesByProfil(this.user.id).subscribe(data => {
      this.prestataires = data;

    });

  }

  showStatut(): void {
    this.statutService.getByLibelle(TypeStructureModel.COLLECTIVITE).subscribe(data => {
      this.statuts = data;

    });

  }


  render() {
    setTimeout(() => {
      createExcelExport();
    }, 1000);

  }

  showBon(reservation: any) {

    this.bonComponent.show(reservation);

  }

  addChauffeur(reservation: any) {
    this.chauffeurComponent.show(this, reservation);
  }

  duppliquer(reservation: any) {
    // this.frmreservationsComponent.isdupplicate = true;

    /*  if (confirm('Voulez vraiment duppliquer cette réservation ? ' )) {
      this.reservationService.duppliquer(reservation.id,this.user.id).subscribe(data => {
     //   console.log(data);
     this.showReservation();
      });
    } */
  }

  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.reservations = this.reservations;
    // this.showData();
  }

  delete(reservation: any) {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer cette réservation ?',
      text: 'Vous ne pourrez pas récupérer cette réservation !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.reservationService.remove(reservation.id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.showReservation();

            this.tabSize(event);
           // this.page = this.page -1;

         // this.page = this.tableSize -1;
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cette réservation a déjà été utilisé ailleurs" });
          }
        );
      }
    })

    // if (confirm('Voulez vraiment supprimer cette réservation ? ')) {
    //   this.reservationService.remove(reservation.id).subscribe(data => {
    //     showPoperSuccess('Réservation supprimée')
    //     if(reservation.statut.code == 'EAVAL' || reservation.statut.code == 'VAL'){
    //       this.budgetService.updateBudget(reservation.tad.id, reservation.reservationDate, reservation.price).subscribe(data => {
    //       });
    //     }
    //     this.showReservation();
    //   });
    // }

  }

  checkDelaiAnnulation(reservation) {
    let delaiAnnulation = 0;
    if (!!reservation.templatePrix && !!reservation.templatePrix.delaiannualation) {
      delaiAnnulation = -reservation.templatePrix.delaiannualation;
    }
    let dateReservation = parse(reservation.reservationDate, 'd-MM-yyyy HH:mm', new Date());
    let currentDate = new Date();

    let diff = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours()) - Date.UTC(dateReservation.getFullYear(), dateReservation.getMonth(), dateReservation.getDate(), dateReservation.getHours())) / (1000 * 60 * 60));
    if (delaiAnnulation !== 0) {
      if (diff < delaiAnnulation || this.user.profilCode === 'PF_PRESTAIRE') {
        return true;
      }
    } else {
      return true;
    }

    return false;

    // return  diff + ' ' + delaiAnnulation;
  }

  duplicate(reservation: any) {

    this.frmDuplicate.show(reservation, this);

  }

  edit(reservation: any) {

    if(!this.checkDelaiAnnulation(reservation)) {
      showPoperError('Impossible de modifier une réservation qui a dépassé le delai d\'annulation');
      return;
    }


    this.router.navigateByUrl('/frm-reservation/' + reservation.id);

  }
}
