<div class="radius-">
    <ul class="nav nav-tabs nav-tabs-faded bgc-info-m3 pt-2 pl-2 radius-t-1" role="tablist">
        <li *ngIf="this.canShowPrestataire" class="nav-item mr-3px">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0 active"
                data-toggle="tab" href="#prestataire" role="tab" aria-controls="prestataire" aria-selected="true">
                Prestataires
            </a>
        </li>
        <li *ngIf="this.canShowEnfant" class="nav-item mr-3px">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0 "
                data-toggle="tab" href="#enfant" role="tab" aria-controls="enfant" aria-selected="false">
                Enfants
            </a>
        </li>

        <li *ngIf="this.canShowCollaborateur" class="nav-item mr-3px">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                data-toggle="tab" href="#collaborateurtad" role="tab" aria-controls="collaborateurtad"
                aria-selected="false">
                Collaborateurs
            </a>
        </li>
        <li *ngIf="this.canShowBudget" class="nav-item mr-3px">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                data-toggle="tab" href="#budget" role="tab" aria-controls="budget" aria-selected="false">
                Budgets
            </a>
        </li>
        <li *ngIf="this.canShowAffecter" class="nav-item mr-3px">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                data-toggle="tab" href="#collaborateurtad-affecte" role="tab" aria-controls="collaborateurtad-affecte"
                aria-selected="false">
                Collaborateurs affectés
            </a>
        </li>
    </ul>

    <div class="tab-content bgc-white p-35 border-1 border-t-0 brc-default-l2 radius-b-1">

        <div *ngIf="this.canShowPrestataire" class="tab-pane fade text-95 active show" id="prestataire" role="tabpanel"
            style="overflow-y: scroll; height:400px;">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button *ngIf="this.canCreatePrestataire" type="button"
                                    (click)="openFormCollectStructure('PRESTATAIRE','Prestataire')" data-toggle="modal"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>

                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card w-100 mt-1 mb-1">
                            <div class="card-body p-0">
                                <div class="accordion">
                                    <div class="card border-0 mt-1px bgc-primary-l5">
                                        <div class="card-header border-0 bgc-transparent mb-0">
                                            <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                                <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                    href="#rechercheAvancee" data-toggle="collapse"
                                                    aria-expanded="false" aria-controls="rechercheAvancee">
                                                    Recherche avancée

                                                    <span
                                                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                        <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                    </span>
                                                    <span
                                                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                        <i
                                                            class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                    </span>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="rechercheAvancee" class="collapse" aria-labelledby="headingTwo4"
                                            data-parent="#rechercheAvancee" style=""
                                            (keyup.enter)="rechercheAvancePrestaire()">
                                            <div
                                                class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                                <div class="form-group row">

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Siren
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="siretprestataire"
                                                                    name="siretprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetSiret()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Nom
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="nomprestataire"
                                                                    name="nomprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetNom()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                   


                                                </div>

                                                <div class="form-group row">
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Email
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="emailprestataire"
                                                                    name="emailprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetEmail()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Adresse
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="adresseprestataire"
                                                                    name="adresseprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetAdresse()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Responsable
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="responsableprestataire"
                                                                    name="responsableprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetResponsable()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Téléphone
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-7 pl-2">
                                                                <input type="text" [(ngModel)]="telephoneprestataire"
                                                                    name="telephoneprestataire" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetTelephonefixe()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    

                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-md-6">

                                                        <div class="form-group row">
                                                            <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                   
                                                                </label>
                                                            </div>

                                                            <div class="mb-2 mb-sm-0">
                                                                <button (click)="rechercheAvancePrestaire()"
                                                                    type="button"
                                                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                    <i class="fa fa-search mr-1"></i>

                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                  

                                                </div>


                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>
                                    <th class="text-center pr-0">

                                    </th>

                                    <th>
                                        SIREN
                                    </th>

                                    <th>
                                        Nom
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Responsable
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Adresse
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="mt-1">


                                <ng-container *ngFor="let structure of dataprestataires| paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count}; let i = index" [class.active]="i == currentIndex">
                                    <tr class="bgc-h-yellow-l4 d-style">
                                        <td class="text-center pr-0 pos-rel">
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                                <!-- border shown on hover -->
                                            </div>
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                                <!-- border shown when row is selected -->
                                            </div>


                                        </td>

                                        <td>
                                            <span
                                                class="text-dark-d1 text-600 text-95">{{structure.structure.siret}}</span>
                                        </td>
                                        <td>
                                            <a class="text-blue-d1 text-600 text-95">{{structure.structure.nom}}</a>
                                        </td>

                                        <td>
                                            <a
                                                class="text-blue-d1 text-600 text-95">{{structure.structure.responsable}}</a>
                                        </td>

                                        <td>
                                            <a class="text-blue-d1 text-600 text-95">{{structure.structure.adresse}}</a>
                                        </td>
                                        <td class="text-center pr-0">
                                            <div>
                                                <a data-toggle="collapse"
                                                    [attr.data-target]="'#detail-presta-'+structure.structure.id"
                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                    title="Show Details" aria-expanded="false">
                                                    <span class="d-none d-md-inline mr-1">
                                                        Details
                                                    </span>
                                                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                </a>
                                            </div>
                                        </td>

                                        <td>
                                            <!-- action buttons -->
                                            <div class="d-none d-lg-flex">

                                                <a href="#" *ngIf="this.canDeletePrestataire"
                                                    (click)="deleteCollctiviteStructure(structure.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>

                                            </div>

                                            <!-- show a dropdown in mobile -->


                                        </td>
                                    </tr>

                                    <!-- detail row -->
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse"
                                                id="{{'detail-presta-'+structure.structure.id}}">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div
                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                            <div class="bgc-white radius-1">
                                                                <table
                                                                    class="table table table-striped-default table-borderless">
                                                                    <tbody>
                                                                       
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-calendar text-success"></i>
                                                                            </td>
    
                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                Date de Création
                                                                            </td>
    
                                                                            <td class="text-dark-m3">
                                                                                {{structure.structure.datecreation |
                                                                                date:'dd-MM-yyyy'}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <i
                                                                                    class="far fa-envelope text-blue"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Email
                                                                            </td>

                                                                            <td class="text-blue-d1 text-wrap">
                                                                                {{structure.email}}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-phone text-purple"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Téléphone fixe
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{structure.telephone}}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <i
                                                                                    class="fa fa-map-marker text-orange-d1"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Adresse
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{structure.structure.adresse}}
                                                                            </td>
                                                                        </tr>


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </ng-container>

                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{dataprestataires.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>
        </div>
        <div *ngIf="this.canShowEnfant" class="tab-pane fade text-95" id="enfant" role="tabpanel"
            style="overflow-y: scroll; height:400px;">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                            <h3 class="text-125 pl-1 mb-3 mb-sm-0 text-secondary-d4">
                                Enfants
                            </h3>

                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button *ngIf="this.canCreateEnfant" type="button"
                                    (click)="openFormActeur('PENSIONNAIRE','TAD')" data-toggle="modal"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>

                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="accordion">
                                <div class="card border-0 mt-1px bgc-primary-l5">
                                    <div class="card-header border-0 bgc-transparent mb-0">
                                        <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                            <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                href="#rechercheAvanceeDetailsTadEnfant" data-toggle="collapse"
                                                aria-expanded="false" aria-controls="rechercheAvanceeDetailsTadEnfant">
                                                Recherche avancée

                                                <span
                                                    class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                    <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                </span>
                                                <span
                                                    class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                    <i
                                                        class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                </span>
                                            </a>
                                        </h2>
                                    </div>
                                    <div id="rechercheAvanceeDetailsTadEnfant" class="collapse"
                                        aria-labelledby="headingTwo4" data-parent="#rechercheAvancee" style=""
                                        (keyup.enter)="rechercheAvanceEnfant()">
                                        <div
                                            class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                            <div class="form-group row">

                                                <div class="col-md-6">

                                                    <div class="form-group row">
                                                        <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Prénom/nom
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-7 pl-2">
                                                            <input [(ngModel)]="prenomEnfant" name="prenomEnfant"
                                                                type="text" class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetPrenomEnfant()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>

                                          <!--      <div class="col-md-4">

                                                    <div class="form-group row">
                                                        <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Nom
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-8 pl-2">
                                                            <input [(ngModel)]="nomEnfant" name="nomEnfant" type="text"
                                                                class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetNomEnfant()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div> -->

                                                <div class="col-md-6">

                                                    <div class="form-group row">
                                                        <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Email
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-7 pl-2">
                                                            <input [(ngModel)]="emailEnfant" name="emailEnfant"
                                                                type="text" class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetEmailEnfant()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>


                                            <div class="form-group row">

                                                <!--     <div class="col-md-4">

                                                    <div class="form-group row">
                                                        <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Adresse
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-8 pl-2">
                                                            <input [(ngModel)]="adresseEnfant" name="adresseEnfant"
                                                                type="text" class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetAdresseEnfant()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>  -->

                                                <div class="col-md-6">

                                                    <div class="form-group row">
                                                        <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Téléphone 
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-7 pl-2">
                                                            <input [(ngModel)]="telephoneEnfant" name="telephoneEnfant"
                                                                type="text" class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetTelephoneEnfant()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-md-4">

                                                    <div class="form-group row">


                                                        <div class="mb-2 mb-sm-0">
                                                            <button (click)="rechercheAvanceEnfant()" type="button"
                                                                class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                <i class="fa fa-search mr-1"></i>

                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>
                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>
                                    <th class="text-center pr-0">

                                    </th>

                                    <th>
                                        Nom
                                    </th>

                                    <th>
                                        Prénom
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Age
                                    </th>
                                    <th class="d-none d-sm-table-cell">
                                        Téléphone Portable
                                    </th>

                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="mt-1">

                                <ng-container *ngFor="let col of datapensionnaires| paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count}; let i = index" [class.active]="i == currentIndex">
                                    <tr class="bgc-h-yellow-l4 d-style">
                                        <td class="text-center pr-0 pos-rel">
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                                <!-- border shown on hover -->
                                            </div>
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                                <!-- border shown when row is selected -->
                                            </div>
                                        </td>

                                        <td>
                                            <a class="text-blue-d1 text-600 text-95">{{col.personne.nom}}</a>
                                        </td>

                                        <td class="text-600 text-orange-d2">
                                            {{col.personne.prenom}}
                                        </td>
                                       

                                        <td>
                                            <span *ngIf="!!col.personne.datenaissance">
                                                {{getAge(col.personne.datenaissance)}} ans
                                            </span> 
                                        </td>
                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{col.personne.telephone2}}
                                        </td>


                                        <td class="text-center pr-0">
                                            <div>
                                                <a href="#" data-toggle="collapse"
                                                    [attr.data-target]="'#detail-col-'+col.id"
                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                    title="Show Details" aria-expanded="false">
                                                    <span class="d-none d-md-inline mr-1">
                                                        Details
                                                    </span>
                                                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                </a>
                                            </div>
                                        </td>



                                        <td>
                                            <!-- action buttons -->
                                            <div class="d-none d-lg-flex">


                                                <a href="#" *ngIf="this.canDeleteEnfant" (click)="deleteActeur(col.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>
                                                <a href="#" *ngIf="this.canTransfertEnfant" (click)="transferer(col)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-warning btn-a-lighter-warning">
                                                    <i class="fa fa-share mx-1"></i>
                                                </a>
                                            </div>

                                            <!-- show a dropdown in mobile -->
                                            <div
                                                class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                <a href="#"
                                                    class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    <i class="fa fa-cog"></i>
                                                </a>

                                                <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                    <div class="dropdown-inner">
                                                        <div
                                                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">

                                                        </div>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                                            Modifier
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                            Supprimer
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                            Autre
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse" id="{{'detail-col-'+col.id}}" style="">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div
                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                            <div class="bgc-white radius-1">
                                                                <table
                                                                    class="table table table-striped-default table-borderless">
                                                                    <tbody>


                                                                        <tr>
                                                                            <td>
                                                                                <i
                                                                                    class="far fa-envelope text-blue"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Email
                                                                            </td>

                                                                            <td class="text-blue-d1 text-wrap">
                                                                                {{col.personne.email}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-info text-blue"></i>
                                                                            </td>

                                                                            <td
                                                                                class="text-95 text-600 text-secondary-d2">
                                                                                Information complémentaire
                                                                            </td>

                                                                            <td class="text-blue-d1 text-wrap">
                                                                                {{col.personne.information}}
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{datapensionnaires.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>
        </div>

        <div *ngIf="this.canShowCollaborateur" class="tab-pane fade text-95" id="collaborateurtad" role="tabpanel"
            style="overflow-y: scroll; height:400px;">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                            <h3 class="text-125 pl-1 mb-3 mb-sm-0 text-secondary-d4">
                                Collaborateurs
                            </h3>

                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button *ngIf="this.canCreateCollaborateur" type="button"
                                    (click)="openFormActeur('COLLABORATEUR','TAD')" data-toggle="modal"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>

                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="accordion">
                                <div class="card border-0 mt-1px bgc-primary-l5">
                                    <div class="card-header border-0 bgc-transparent mb-0">
                                        <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                            <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                href="#rechercheAvanceeDetailsFoyerCollaborateur" data-toggle="collapse"
                                                aria-expanded="false"
                                                aria-controls="rechercheAvanceeDetailsFoyerEnfant">
                                                Recherche avancée

                                                <span
                                                    class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                    <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                </span>
                                                <span
                                                    class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                    <i
                                                        class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                </span>
                                            </a>
                                        </h2>
                                    </div>
                                    <div id="rechercheAvanceeDetailsFoyerCollaborateur" class="collapse"
                                        aria-labelledby="headingTwo4"
                                        data-parent="#rechercheAvanceeDetailsFoyerCollaborateur" style=""
                                        (keyup.enter)="rechercheAvanceCollaborateur()">
                                        <div
                                            class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                            <div class="form-group row">

                                                <div class="col-md-6">

                                                    <div class="form-group row">
                                                        <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Prénom/nom
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-7 pl-2">
                                                            <input [(ngModel)]="prenomCollaborateur"
                                                                name="prenomCollaborateur" type="text"
                                                                class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetPrenomCol()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <!--        <div class="col-md-4">

                                                    <div class="form-group row">
                                                        <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Nom
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-8 pl-2">
                                                            <input [(ngModel)]="nomCollaborateur"
                                                                name="nomCollaborateur" type="text"
                                                                class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetNomCol()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div> -->

                                                <div class="col-md-6">

                                                    <div class="form-group row">
                                                        <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Email
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-7 pl-2">
                                                            <input [(ngModel)]="emailCollaborateur"
                                                                name="emailCollaborateur" type="text"
                                                                class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetEmailCol()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>


                                            <div class="form-group row">

                                                <div class="col-md-6">

                                                    <div class="form-group row">
                                                        <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Adresse
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-7 pl-2">
                                                            <input [(ngModel)]="adresseCollaborateur"
                                                                name="adresseCollaborateur" type="text"
                                                                class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetAdresseCol()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-md-6">

                                                    <div class="form-group row">
                                                        <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Téléphone
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-7 pl-2">
                                                            <input [(ngModel)]="telephoneCollaborateur"
                                                                name="telephoneCollaborateur" type="text"
                                                                class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetTelephoneCol()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-md-4">

                                                    <div class="form-group row">


                                                        <div class="mb-2 mb-sm-0">
                                                            <button (click)="rechercheAvanceCollaborateur()"
                                                                type="button"
                                                                class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                <i class="fa fa-search mr-1"></i>

                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>
                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>
                                    <th class="text-center pr-0">

                                    </th>

                                    <th>
                                        Nom
                                    </th>

                                    <th>
                                        Prénom
                                    </th>

                                    <th>
                                        Email
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Fonction
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Téléphone fixe
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Téléphone portable
                                    </th>

                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="mt-1">

                                <ng-container *ngFor="let col of datacollaborateurs| paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count}; let i = index" [class.active]="i == currentIndex">
                                    <tr class="bgc-h-yellow-l4 d-style">
                                        <td class="text-center pr-0 pos-rel">
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                                <!-- border shown on hover -->
                                            </div>
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                                <!-- border shown when row is selected -->
                                            </div>


                                        </td>

                                        <td class="text-600 text-orange-d2">
                                            {{col?.personne?.nom}}
                                        </td>

                                        <td class="text-600 text-orange-d2">
                                            {{col?.personne?.prenom}}
                                        </td>

                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{col?.personne?.email}}
                                        </td>
                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{col?.fonction?.libelle}} 
                                        </td>
                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{col?.personne?.telephone2}}
                                        </td>
                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{col?.personne?.telephone1}}
                                        </td>


                                        <td class="text-center pr-0">
                                            <div>
                                                <a href="#" data-toggle="collapse"
                                                    [attr.data-target]="'#detail-col-'+col.id"
                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                    title="Show Details" aria-expanded="false">
                                                    <span class="d-none d-md-inline mr-1">
                                                        Details
                                                    </span>
                                                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                </a>
                                            </div>
                                        </td>



                                        <td>
                                            <!-- action buttons -->
                                            <div class="d-none d-lg-flex">
                                                <a href="#" *ngIf="this.canEditCollaborateur"
                                                    (click)="editActeur(col.id,'COLLABORATEUR')"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                                                    <i class="fa fa-pencil-alt"></i>
                                                </a>

                                                <a href="#" *ngIf="this.canDeleteCollaborateur"
                                                    (click)="deleteActeur(col.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>

                                            </div>

                                            <!-- show a dropdown in mobile -->
                                            <div
                                                class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                <a href="#"
                                                    class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    <i class="fa fa-cog"></i>
                                                </a>

                                                <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                    <div class="dropdown-inner">
                                                        <div
                                                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">

                                                        </div>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                                            Modifier
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                            Supprimer
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                            Autre
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse" id="{{'detail-col-'+col.id}}" style="">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div
                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                            <div class="bgc-white radius-1">
                                                                <table class="table table table-striped-default table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-info text-success"></i>
                                                                            </td>

                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                Information complémentaire
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{(!!col.personne) ? col.personne.information:''}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-map-marker text-success"></i>
                                                                            </td>

                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                adresse
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{(!!col.personne) ? col.personne.adresse:''}}
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </ng-container>

                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{datacollaborateurs.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>

        </div>

        <div *ngIf="this.canShowBudget" class="tab-pane fade text-95" id="budget" role="tabpanel"
            style="overflow-y: scroll; height:400px;">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                            <h3 class="text-125 pl-1 mb-3 mb-sm-0 text-secondary-d4">
                                Budgets
                            </h3>

                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button *ngIf="this.canCreateBudget" type="button" (click)="openFormBudget()"
                                    data-toggle="modal"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Ajouter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>

                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card w-100 mt-1 mb-1">
                            <div class="card-body p-0">
                                <div class="accordion">
                                    <div class="card border-0 mt-1px bgc-primary-l5">
                                        <div class="card-header border-0 bgc-transparent mb-0">
                                            <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                                <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                    href="#rechercheAvanceeBudgetOrganisation" data-toggle="collapse"
                                                    aria-expanded="false"
                                                    aria-controls="rechercheAvanceeBudgetOrganisation">
                                                    Recherche avancée

                                                    <span
                                                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                        <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                    </span>
                                                    <span
                                                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                        <i
                                                            class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                    </span>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="rechercheAvanceeBudgetOrganisation" class="collapse"
                                            aria-labelledby="headingTwo4"
                                            data-parent="#rechercheAvanceeBudgetOrganisation" style=""
                                            (keyup.enter)="rechercheAvanceBudget()">
                                            <div
                                                class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                                <div class="form-group row">


                                                    <div class="col-md-5">

                                                        <div class="form-group row">
                                                            <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Date début
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-8 pl-2">
                                                                <select [(ngModel)]="critereDateDebut"
                                                                    name="critereDateDebut" class="form-control">
                                                                    <option value="1">Egal</option>
                                                                    <option value="2">Inférieur</option>
                                                                    <option value="3">Supérieur</option>
                                                                    <option value="4">Compris entre</option>
                                                                </select>
                                                                <input type="date" [(ngModel)]="dateDebut"
                                                                    name="dateDebut" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='reset1()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-md-5">

                                                        <div class="form-group row">
                                                            <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Date fin
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-8 pl-2">
                                                                <select [(ngModel)]="critereDateFin"
                                                                    name="critereDateFin" class="form-control">
                                                                    <option value="1">Egal</option>
                                                                    <option value="2">Inférieur</option>
                                                                    <option value="3">Supérieur</option>
                                                                    <option value="4">Compris entre</option>
                                                                </select>
                                                                <input type="date" [(ngModel)]="dateFin" name="dateFin"
                                                                    class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='reset2()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>




                                                </div>
                                                <div class="form-group row">


                                                    <div class="col-md-5">

                                                        <div class="form-group row">
                                                            <div *ngIf="this.critereDateDebut == '4'"
                                                                class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Date début max
                                                                </label>
                                                            </div>

                                                            <div *ngIf="this.critereDateDebut == '4'"
                                                                class="col-sm-8 pl-2">
                                                                <input type="date" [(ngModel)]="dateDebutMax"
                                                                    name="dateDebutMax" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='reset3()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-md-5">

                                                        <div class="form-group row">
                                                            <div *ngIf="this.critereDateFin == '4'"
                                                                class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Date fin max
                                                                </label>
                                                            </div>

                                                            <div *ngIf="this.critereDateFin == '4'"
                                                                class="col-sm-8 pl-2">
                                                                <input type="date" [(ngModel)]="dateFinMax"
                                                                    name="dateFinMax" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='reset4()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>




                                                </div>


                                                <div class="form-group row">

                                                    <div class="col-md-5">

                                                        <div class="form-group row">
                                                            <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    <th class="d-none d-sm-table-cell">
                                                                        Budget initial
                                                                    </th>
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-8 pl-2">
                                                                <select [(ngModel)]="critereMontant"
                                                                    name="critereMontant" class="form-control">
                                                                    <option value="1">Egal</option>
                                                                    <option value="2">Inférieur</option>
                                                                    <option value="3">Supérieur</option>
                                                                    <option value="4">Compris entre</option>
                                                                </select>
                                                                <input type="number" [(ngModel)]="montant"
                                                                    name="montant" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetMontant()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="this.critereMontant == '4'" class="col-md-5">

                                                        <div class="form-group row">
                                                            <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                                <label class="mb-0">
                                                                    Budget initial max
                                                                </label>
                                                            </div>

                                                            <div class="col-sm-8 pl-2">
                                                                <input type="number" [(ngModel)]="montantMax"
                                                                    name="montantMax" class="form-control">
                                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetMontantMax()'>
                                                           Effacer
                                                       </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-md-4">

                                                        <div class="form-group row">


                                                            <div class="mb-2 mb-sm-0">
                                                                <button (click)="rechercheAvanceBudget()" type="button"
                                                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                    <i class="fa fa-search mr-1"></i>

                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>
                                    <th class="text-center pr-0">

                                    </th>

                                    <th>
                                        Date Début
                                    </th>

                                    <th>
                                        Date Fin
                                    </th>

                                    <th class="d-none d-sm-table-cell" style="text-align: right;">
                                        Budget initial
                                    </th>

                                    <th class="d-none d-sm-table-cell" style="text-align: right;">
                                        Budget restant
                                    </th>

                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="mt-1">
                                <tr *ngFor="let budget of databudgets| paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count}; let i = index" [class.active]="i == currentIndex"
                                    class="bgc-h-yellow-l4 d-style">
                                    <td class="text-center pr-0 pos-rel">
                                        <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                            <!-- border shown on hover -->
                                        </div>
                                        <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                            <!-- border shown when row is selected -->
                                        </div>


                                    </td>

                                    <td>
                                        <a class="text-blue-d1 text-600 text-95">{{budget.datedebut 
                                            }}</a>
                                    </td>

                                    <td class="text-600 text-orange-d2">
                                        {{budget.datefin }}
                                    </td>

                                    <td class="d-none d-sm-table-cell text-grey-d1" style="text-align: right;">
                                        {{budget.montant | number: '1.0-2':'fr-FR'}} €
                                    </td>
                                    <td class="d-none d-sm-table-cell text-grey-d1" style="text-align: right;">
                                        {{budget.montantrestant | number: '1.0-2':'fr-FR'}} €
                                    </td>

                                    <td>
                                        <!-- action buttons -->
                                        <div class="d-none d-lg-flex">
                                            <a href="#" *ngIf="this.canEditBudget" (click)="editBudget(budget.id)"
                                                class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                                                <i class="fa fa-pencil-alt"></i>
                                            </a>

                                            <a href="#" *ngIf="this.canDeleteBudget" (click)="deleteBudget(budget.id)"
                                                class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                <i class="fa fa-trash-alt"></i>
                                            </a>

                                        </div>

                                        <!-- show a dropdown in mobile -->
                                        <div class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                            <a href="#"
                                                class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                data-toggle="dropdown">
                                                <i class="fa fa-cog"></i>
                                            </a>

                                            <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                <div class="dropdown-inner">
                                                    <div
                                                        class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                                                        Unité 1
                                                    </div>
                                                    <a href="#" class="dropdown-item">
                                                        <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i> Modifier
                                                    </a>
                                                    <a href="#" class="dropdown-item">
                                                        <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                        Supprimer
                                                    </a>
                                                    <a href="#" class="dropdown-item">
                                                        <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i> Autre
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                </tr>


                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{databudgets.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>

        </div>
        <div *ngIf="this.canShowAffecter" class="tab-pane fade text-95" id="collaborateurtad-affecte" role="tabpanel"
            style="overflow-y: scroll; height:400px;">
            <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                    <form autocomplete="off">
                        <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                            <h3 class="text-125 pl-1 mb-3 mb-sm-0 text-secondary-d4">
                                Collaborateurs Affectés
                            </h3>

                            <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                                <button *ngIf="this.canCreateAffecter" type="button"
                                    (click)="openAffectermActeur('AFFECTER')" data-toggle="modal"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                    <i class="fa fa-plus mr-1"></i>
                                    Affecter <span class="d-sm-none d-md-inline"></span>
                                </button>
                            </div>

                            <div class="mb-2 mb-sm-0">

                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="accordion">
                                <div class="card border-0 mt-1px bgc-primary-l5">
                                    <div class="card-header border-0 bgc-transparent mb-0">
                                        <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                            <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                                                href="#rechercheAvanceeDetailsTadAffecter" data-toggle="collapse"
                                                aria-expanded="false"
                                                aria-controls="rechercheAvanceeDetailsTadAffecter">
                                                Recherche avancée

                                                <span
                                                    class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                                    <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                                                </span>
                                                <span
                                                    class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                                    <i
                                                        class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                                                </span>
                                            </a>
                                        </h2>
                                    </div>
                                    <div id="rechercheAvanceeDetailsTadAffecter" class="collapse"
                                        aria-labelledby="headingTwo4" data-parent="#rechercheAvancee" style=""
                                        (keyup.enter)="rechercheAvanceAffecter()">
                                        <div
                                            class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                            <div class="form-group row">

                                                <div class="col-md-6">

                                                    <div class="form-group row">
                                                        <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Prénom/nom
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-7 pl-2">
                                                            <input [(ngModel)]="prenomAffecter" name="prenomAffecter"
                                                                type="text" class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetPrenomAffecte()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <!--         <div class="col-md-4">

                                                    <div class="form-group row">
                                                        <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Nom
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-8 pl-2">
                                                            <input [(ngModel)]="nomAffecter" name="nomAffecter"
                                                                type="text" class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetNomAffecte()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div> -->

                                                <div class="col-md-6">

                                                    <div class="form-group row">
                                                        <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Email
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-7 pl-2">
                                                            <input [(ngModel)]="emailAffecter" name="emailAffecter"
                                                                type="text" class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetEmailAffecte()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>


                                            <div class="form-group row">

                                                <div class="col-md-6">

                                                    <div class="form-group row">
                                                        <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Adresse
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-7 pl-2">
                                                            <input [(ngModel)]="adresseAffecter" name="adresseAffecter"
                                                                type="text" class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetAdresseAffecte()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-md-6">

                                                    <div class="form-group row">
                                                        <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                                            <label class="mb-0">
                                                                Téléphone
                                                            </label>
                                                        </div>

                                                        <div class="col-sm-7 pl-2">
                                                            <input [(ngModel)]="telephoneAffecter"
                                                                name="telephoneAffecter" type="text"
                                                                class="form-control">
                                                                <button type="button" class="btn btn-sm btn-secondary"
                                                           (click)='resetTelephoneAffecte()'>
                                                           Effacer
                                                       </button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-md-4">

                                                    <div class="form-group row">


                                                        <div class="mb-2 mb-sm-0">
                                                            <button (click)="rechercheAvanceAffecter()" type="button"
                                                                class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                                <i class="fa fa-search mr-1"></i>

                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>
                        <table
                            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                <tr>
                                    <th class="text-center pr-0">

                                    </th>

                                    <th>
                                        Nom
                                    </th>

                                    <th>
                                        Prénom
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Email
                                    </th>
                                    <th class="d-none d-sm-table-cell">
                                        Fonction
                                    </th>
                                    <th class="d-none d-sm-table-cell">
                                        Téléphone fixe
                                    </th>

                                    <th class="d-none d-sm-table-cell">
                                        Téléphone portable
                                    </th>

                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="mt-1">

                                <ng-container *ngFor="let col of data| paginate : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count}; let i = index" [class.active]="i == currentIndex">
                                    <tr class="bgc-h-yellow-l4 d-style">
                                        <td class="text-center pr-0 pos-rel">
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                                <!-- border shown on hover -->
                                            </div>
                                            <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                                <!-- border shown when row is selected -->
                                            </div>


                                        </td>

                                        <td>
                                            <a class="text-blue-d1 text-600 text-95">{{col.personne.nom}}</a>
                                        </td>

                                        <td class="text-600 text-orange-d2">
                                            {{col.personne.prenom}}
                                        </td>

                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{col?.personne?.email}}
                                        </td>
                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{col?.personne?.fonction?.libelle}}
                                        </td>
                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{col?.personne?.telephone2}}
                                        </td>
                                        <td class="d-none d-sm-table-cell text-grey-d1">
                                            {{col?.personne?.telephone1}}
                                        </td>


                                        <td class="text-center pr-0">
                                            <div>
                                                <a href="#" data-toggle="collapse"
                                                    [attr.data-target]="'#detail-col-'+col.id"
                                                    class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                                    title="Show Details" aria-expanded="false">
                                                    <span class="d-none d-md-inline mr-1">
                                                        Details
                                                    </span>
                                                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                                </a>
                                            </div>
                                        </td>

                                        <td>
                                            <!-- action buttons -->
                                            <div class="d-none d-lg-flex">


                                                <a href="#" *ngIf="this.canDeleteAffecter"
                                                    (click)="deleteActeur(col.id)"
                                                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>

                                            </div>

                                            <!-- show a dropdown in mobile -->
                                            <div
                                                class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                                <a href="#"
                                                    class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    <i class="fa fa-cog"></i>
                                                </a>

                                                <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                                    <div class="dropdown-inner">
                                                        <div
                                                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">

                                                        </div>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                                            Modifier
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                            Supprimer
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                            Autre
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr class="border-0 detail-row bgc-white">
                                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                                            <div class="table-detail collapse" id="{{'detail-col-'+col.id}}" style="">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 offset-md-1 p-4">
                                                        <div
                                                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                                            <div class="bgc-white radius-1">
                                                                <table class="table table table-striped-default table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-info text-success"></i>
                                                                            </td>

                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                Information complémentaire
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{(!!col.personne) ? col.personne.information:''}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <i class="fa fa-map-marker text-success"></i>
                                                                            </td>

                                                                            <td class="text-95 text-600 text-secondary-d2">
                                                                                adresse
                                                                            </td>

                                                                            <td class="text-dark-m3">
                                                                                {{(!!col.personne) ? col.personne.adresse:''}}
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </ng-container>

                            </tbody>
                        </table>

                        <!-- table footer -->
                        <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                            <div class="text-nowrap align-self-center align-self-sm-start">
                                Afficher 1 - {{ tableSize }} of {{data.length}}
                                <select (change)="tableData($event)" class="custom-select">
                                    <option *ngFor="let size of tableSizesArr" [value]="size">
                                        Afficher {{ size }}
                                    </option>
                                </select>
                            </div>
                            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="tabSize($event)">
                                </pagination-controls>
                            </div>
                            <div>

                            </div>
                        </div>
                    </form>

                </div>
                <!-- /.card-body -->
            </div>

        </div>


    </div>
</div>
<app-frmcollectivitecollaborateur #frmcollaborateur></app-frmcollectivitecollaborateur>
<app-frmaffecte #frmaffecte></app-frmaffecte>
<app-frmbudget #frmbudgetComponent></app-frmbudget>
<app-frmtransfertenfant #frmtransfertenfants></app-frmtransfertenfant>
<app-frmtadprestataire #frmtadprestataire></app-frmtadprestataire>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
