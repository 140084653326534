import { Component, OnInit } from '@angular/core';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
declare var $ :any;
@Component({
  selector: 'app-pfd-viewer',
  templateUrl: './pfd-viewer.component.html',
  styleUrls: ['./pfd-viewer.component.css']
})
export class PfdViewerComponent implements OnInit {
url:any;
  constructor() { 
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  ngOnInit(): void {
  }

  show(url:any){
    this.url = url;
    $('#pdfViewer').modal('show');
  }
}
