import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FonctionService } from 'src/app/services/fonction.service';
import { ActeurService } from 'src/app/services/acteur.service';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { StructureService } from 'src/app/services/structure.service';
import { PersonneService } from 'src/app/services/personne.service ';
import { FrmchauffeursComponent } from '../frmchauffeurs/frmchauffeurs.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { FrmcollectivitecollaborateurComponent } from 'src/app/collectivite/frmcollectivitecollaborateur/frmcollectivitecollaborateur.component';
import { PaginationService } from 'src/app/services/pagination.service';
import { SecurityService } from 'src/app/services/security.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare function stransAdresse(id: any);
import Swal from 'sweetalert2';

declare var $: any;



@Component({
  selector: 'app-lstchauffeurs',
  templateUrl: './lstchauffeurs.component.html',
  styleUrls: ['./lstchauffeurs.component.css']
})
export class LstchauffeursComponent implements OnInit {

  data: any = [];
  prestataires: any = [];
  collaborateur: any = null;
  prestataire: any = null;

  fonctions: any = [];

  code: any = '';
  prenom: any = null;
  nom: any = null;
  email: any = null;
  adresse: any = null;
  datenaissance: any = null;
  telephone: any = null;
  telephone2: any = null;
  information: any = null;
  fonction: any = null;
  telephone1;
  typeacteur: any = '';
  personne: any = '';
  structure: any = '';
  user: User = new User();
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;

  canCreate = false;
  canEdit = false;
  canDelete = false;
  canDetails = false;
  prestatairessechr: any = [];
  prestatairesechr = 0;

  @ViewChild('frmchauffeur') frmchauffeur: FrmchauffeursComponent;
  @ViewChild('frmcollaborateur') frmcollaborateur: FrmcollectivitecollaborateurComponent;
  constructor(private http: HttpClient, private acteurService: ActeurService,
    private fonctionService: FonctionService, private structureService: StructureService,
    private personneService: PersonneService, private authenticationService: AuthenticationService,
    private paginationService: PaginationService, private securiteService: SecurityService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.showDoits();
    this.show2();
    stransAdresse('adresse');
    this.showStructure();
  }
  showDoits() {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_PRESCHAUFFEUR").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_PRESCHAUFFEUR").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_EDIT_CHAUFFEUR_PRESTATAIRE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canEdit = true;
      } else {
        this.canEdit = false;
      }
    });
  }
  filter(parent: any, structure: any) {
    this.show2();
  }
  show2(): void {
    this.spinner.show();
    this.acteurService.getActeurRattacheesAll(TypeActeurModel.CHAUFFEUR, this.user.structure, TypeStructureModel.PRESTATAIRE).subscribe(data => {
      this.data = data;
      this.collaborateur = null;
      this.spinner.hide();
    });

  }
  showStructure(): void {
    this.structureService.getPrestatairesByProfil(this.user.id).subscribe(data => {
      this.prestatairessechr = data;

    });

  }
  rechercheAvance() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    if (this.prestatairesechr == null )
      this.prestatairesechr = 0;
    this.prenom = this.prenom ? this.prenom : null;
    this.nom = this.nom ? this.nom : null;
    this.email = this.email ? this.email : null;
    this.adresse = this.adresse ? this.adresse : null;
    this.telephone = this.telephone ? this.telephone : null;
    // alert(this.user.structure)
    this.acteurService.getRechercheBis(this.prestatairesechr,this.prenom, this.nom, this.email, this.adresse,
      this.telephone, TypeActeurModel.CHAUFFEUR, this.user.structure, TypeStructureModel.PRESTATAIRE).subscribe(data => {
        this.data = data;
        this.structure = null;
        this.spinner.hide();
      });
      this.tabSize(event);

  }

  openFormChauffeur() {
    // alert("ok");
    this.frmchauffeur.show(this);
  }

  openFormActeur(typeacteur: any, typestructure: any): void {
    if (this.user.structure != 0) {
      //  alert()
      this.structureService.get(this.user.structure).subscribe(data => {
        this.frmcollaborateur.structure = data;
      });
      this.frmcollaborateur.selectstructure = this.user.structure
    }
    this.frmcollaborateur.typestructure = typestructure;
    this.frmcollaborateur.lblstructure = 'Prestataire';
    this.frmcollaborateur.lblnom = 'Nouveau Chauffeur';
    this.frmcollaborateur.typeacteur = typeacteur;
    this.frmcollaborateur.show(this);
  }

  edit(id: any) {
    this.frmcollaborateur.lblstructure = 'Prestataire';
    this.frmcollaborateur.lblnom = 'Modification Chauffeur';
    this.frmcollaborateur.typeacteur = 'CHAUFFEUR';
    this.frmcollaborateur.editPersonne(this, id);
  }

  delete(id: any): void {
    
      Swal.fire({
        title: 'Etes vous sûr de vouloir supprimer?',
        text: 'Vous ne pourrez pas récupérer ce fichier !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, Supprimer!',
        cancelButtonText: 'Non, Annuler'
      }).then((result) => {
        if (result.value) {
          this.acteurService.delete(id).subscribe(data => {
            Swal.fire({ icon: 'success', text: 'Suppression réussie' });
            this.show2();
            this.tabSize(event);
          },
            err => {
              Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet chauffeur a déjà été utilisé ailleurs" });
            }
          );
        }
      })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.acteurService.delete(id).subscribe(data => {
    //     this.show2();
    //   });
    // }
  }


  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.data = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }

  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }



  reset1(){
    this.prenom = null;
   }
   reset2(){
    this.nom = null;
   }
   reset3(){
    this.email = null;
   }
   reset4(){
    this.adresse = null;
   }
   reset5(){
    this.telephone = null;
   }
   reset6(){
    this.prestatairesechr = 0;
   }
}
