import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from './shared/services/authentication.service';
import {User} from './shared/session/user';
import {StructureService} from './services/structure.service';
import {CollectiviteStructureModel} from './shared/models/collectivitestructure-model.model';
import {FoyerModel} from './shared/models/foyer-model.model';
import {AlerteService} from "./services/alerts.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit {
  title = 'consulsen';
  user: User = new User();
  structure: any;
  struct: FoyerModel;
  alerts: any = [];
  canStruc = false;

  constructor(public rt: Router, private authenticationService: AuthenticationService, private structureService: StructureService, private alerteService: AlerteService) {

    if (!this.authenticationService.isLoggedIn()) {
      rt.navigateByUrl('/login');
    } else {
      this.user = authenticationService.getUser();



    }
  }

  ngOnInit(): void {


  }

  logOut(): void {
    this.authenticationService.logout();
    this.rt.navigateByUrl('/login');
  }

  readAlert(id: any) {


    this.rt.navigateByUrl('/alerts');

  }

  getAlertes() {
    this.showAlerts();
    setInterval(() => {
      this.showAlerts();
    }, 5000);

  }

  showAlerts() {
    this.alerteService.getAlertsByUser(this.user.id).subscribe(alerts => {
      this.alerts = alerts;
    });

  }
}
