import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { parse } from 'node:path';
import {NgxSpinnerService} from "ngx-spinner";
import interactionPlugin from '@fullcalendar/interaction';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { HttpClient } from '@angular/common/http';
import { ModalreservationretailsComponent } from 'src/app/reservation/detailsreservation/modalreservationretails/modalreservationretails.component';
import { StructureService } from 'src/app/services/structure.service';
import { StatutService } from 'src/app/services/statut.service';
import { ReservationService } from 'src/app/services/reservation.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
declare var $;


declare function showPoperError(message: any);

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {
 
  @ViewChild('mycal') calendar: FullCalendarComponent;


  structure: any;
  reservations: any = [];
  user: User;
  calendarOptions: CalendarOptions;
  events: any = [];

  statut: any;
  tads: any = [];
  idtad = 0;
  prestataires: any = [];
  idprestataire = 0;
  statuts: any = [];
  idstatut = 0;
  passager = null;
  datecrer = null;
  dateMax = null;
  critereDaterer: any = '1';
  creerpar = null;
  critereDateDebut = null;
  dateDebutMax = null;
  statues: any = [];

  @ViewChild('details') details: ModalreservationretailsComponent;

  constructor(private http: HttpClient, private structureService: StructureService, private statutService: StatutService, private reservationService: ReservationService, private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.structure = this.user.structure;
    this.showTAD();
    this.showPrestataire();
    this.statutService.getManualStatues().subscribe(data => {
      this.statues = data;
    });
    this.getReservations();
  }

  showTAD(): void {
    this.structureService.getStructuresByType(TypeStructureModel.TAD).subscribe(data => {
      this.tads = data;

    });

  }

  showPrestataire(): void {
    this.structureService.getStructuresByType(TypeStructureModel.PRESTATAIRE).subscribe(data => {
      this.prestataires = data;

    });

  }


  getReservations() {
    if (this.structure === 0) {
      this.reservationService.getDataBis().subscribe(data => {
        this.reservations = data;
        this.render();
        // console.log(this.reservations);
      });
    } else if (this.user.profilCode == 'PF_PRESTAIRE') {
      // alert(this.user.parent)
      this.reservationService.getByPrestataireProfil(this.structure).subscribe(data => {
        this.reservations = data;
        this.render();
        //   console.log(this.reservations);
      });

    } else if (this.user.profilCode == 'PF_GFOYER') {
      this.reservationService.getByFoyerProfil(this.structure).subscribe(data => {
        this.reservations = data;
        this.render();
        //  console.log(this.reservations);
      });

    } else if (this.user.profilCode == 'PF_AGCOL') {
      this.reservationService.getByCollectiviteProfil(this.structure).subscribe(data => {
        this.reservations = data;
        this.render();
        // console.log(this.reservations);
      });

    } else if (this.user.profilCode == 'PF_TAD'){
      this.reservationService.getByStructureBis1(this.user.personne).subscribe(data => {
        this.reservations = data;
        this.render();

      });
    }

  }

  render() { 

    this.reservations.forEach((reservartion: any) => {
    //  const dateReservation = parse(reservartion.reservationDate, 'dd-MM-yyyy HH:mm', new Date());
     // const dateFormat: string = dateReservation.getFullYear() + '-' + dateReservation.getMonth() + '-' + dateReservation.getDate();
      // alert(newDate + ' ' + reservartion.reservationDate );
      this.events.push({
        id: reservartion.id,
        title: reservartion.passagers[0]?.acteur?.personne?.prenom + ' ' + reservartion.passagers[0]?.acteur?.personne?.nom,
     //   date: dateReservation,
        color: '#50cc51'
      });
    });
    // this.events.push({ title: 'Paris - Marseille (TAD 1)', date: '2021-07-05' })

    this.calendarOptions = {
      initialView: 'dayGridMonth',
      eventClick: this.handleDateClick.bind(this),
      events: this.events,
      displayEventTime: false,
      locale: 'fr',
      buttonText: {
        today: 'Aujourd\'hui',
      }

      /* eventClick(arg) {
           alert(arg.event.id);
      },*/
    };


  }

  handleDateClick(event) {

    this.details.show(event.event.id);

  }


  rechercheAvance() {
    $('#datecrer')[0].dispatchEvent(new Event('input', {bubbles: true}));
    if (this.critereDaterer == '4') {
      $('#dateMax')[0].dispatchEvent(new Event('input', {bubbles: true}));
    }
    if (this.idtad == null) {
      this.idtad = 0;
    }
    if (this.idprestataire == null) {
      this.idprestataire = 0;
    }
    // alert(this.datecrer);
    if (this.idstatut == null) {
      this.idstatut = 0;
    }

    this.passager = this.passager ? this.passager : null;
    this.creerpar = this.creerpar ? this.creerpar : null;
    this.datecrer = this.datecrer ? this.datecrer : null;
    this.dateMax = this.dateMax ? this.dateMax : null;
    this.reservationService.getRecherche(this.idtad, this.creerpar, this.idprestataire, this.critereDaterer, this.datecrer, this.dateMax, this.idstatut, this.passager, this.user.id).subscribe(data => {
      this.reservations = data;
      this.renderUpdate();
      // console.log(this.reservations);
    });


  }

  reset1() {
    this.datecrer = null;
  }

  reset2() {
    this.dateMax = null;
  }


  renderUpdate() {

    this.calendar.getApi().removeAllEvents();


    this.reservations.forEach((reservartion: any) => {
     // const dateReservation = parse(reservartion.reservationDate, 'dd-MM-yyyy HH:mm', new Date());
     // const dateFormat: string = dateReservation.getFullYear() + '-' + dateReservation.getMonth() + '-' + dateReservation.getDate();
      // alert(newDate + ' ' + reservartion.reservationDate );
      // this.events.push();

      this.calendar.getApi().addEvent({
        id: reservartion.id,
        title: reservartion.passagers[0]?.acteur?.personne?.prenom + ' ' + reservartion.passagers[0]?.acteur?.personne?.nom,
      //  date: dateReservation,
        color: '#50cc51'
      });

    });
    // this.events.push({ title: 'Paris - Marseille (TAD 1)', date: '2021-07-05' })


  }

}
