import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';
import { ActeurModel } from '../shared/models/acteur-model.model';
import { Observable } from 'rxjs';
import { TypeActeurModel } from '../shared/models/type-acteur-model.model';
import { TypeStructureModel } from '../shared/models/type-structure-model.model';
import { FoyerModel } from '../shared/models/foyer-model.model';
import { ActeurStructureModel } from '../shared/models/acteurstructure-model';
import { CollectiviteStructureModel } from '../shared/models/collectivitestructure-model.model';

@Injectable({
  providedIn: 'root'
})
export class CollectiivteStructureService {

  constructor(private http: HttpClient) { } 

getData(){
    return this.http.get(AppModule.API_ENDPOINT + 'collectivtestructure/list');
 }


getActeursByTypeStructure(typestructure: TypeStructureModel): Observable<CollectiviteStructureModel[]> {
  return this.http.get<CollectiviteStructureModel[]>(AppModule.API_ENDPOINT + 'collectivtestructure/listbytypestructure/'+typestructure);
}

getStructureByTypeStructure(typestructure: TypeStructureModel): Observable<CollectiviteStructureModel[]> {
  return this.http.get<CollectiviteStructureModel[]>(AppModule.API_ENDPOINT + 'collectivtestructure/list/'+typestructure);
}


  get(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'collectivtestructure/get/' + id);
  }

  save(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'collectivtestructure/create', body, { headers });
  }

  update(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + 'collectivtestructure/update/' + id, body, { headers });
  }


  delete(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + 'collectivtestructure/delete/' + id);
  }

  getRecherche(collectivite: any,nom: any, siret: any, email: any, adresse: any, telephone: any, responsable: any, typestructure: TypeStructureModel,structure:any): Observable<CollectiviteStructureModel[]> {
    return this.http.get<CollectiviteStructureModel[]>(AppModule.API_ENDPOINT + 'collectivtestructure/recherchecollectivitestructure/' + collectivite + '/'+ nom + '/' + siret + '/' + email + '/' + adresse + '/' + telephone + '/' + responsable + '/' + typestructure+'/'+structure);
  }
 
  getCollectiviteByType(typestructure: TypeStructureModel): Observable<CollectiviteStructureModel[]> {
    return this.http.get<CollectiviteStructureModel[]>(AppModule.API_ENDPOINT + 'structure/listcollectivte/'+ typestructure);
  }

  getStructureByTypeStructureFA(typestructure: any): Observable<CollectiviteStructureModel[]> {
    return this.http.get<CollectiviteStructureModel[]>(AppModule.API_ENDPOINT + 'collectivtestructure/listFA/'+typestructure);
  }

  getStructureTypeStructure(typestructure: TypeStructureModel,typetad: TypeStructureModel): Observable<CollectiviteStructureModel[]> {
    return this.http.get<CollectiviteStructureModel[]>(AppModule.API_ENDPOINT + 'collectivtestructure/listprestatairetad/'+typestructure+'/'+typetad);
  }

  getStructureRattachee(type: any, parent: any,typetad: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'collectivtestructure/rattacheesbis/' + type + '/' + parent+'/'+typetad);
  }

  getStructureRattacheeCollectivite(parent: any,typetad: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'collectivtestructure/rattacheescollectivites/' + parent+'/'+typetad);
  }

  getStructureRattacheeFoyerPrestataire(type: any, parent: any,typetad: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'collectivtestructure/rattacheesfoyerprestataires/' + type + '/' + parent+'/'+typetad);
  }

  getRechercheBis(collectivite: any,typeparent: TypeStructureModel,nom: any, siret: any, email: any, adresse: any, telephone: any, responsable: any, typestructure: TypeStructureModel,structure:any): Observable<CollectiviteStructureModel[]> {
    return this.http.get<CollectiviteStructureModel[]>(AppModule.API_ENDPOINT + 'collectivtestructure/recherchecollectivitestructure/' + collectivite +'/'+typeparent+ '/'+ nom + '/' + siret + '/' + email + '/' + adresse + '/' + telephone + '/' + responsable + '/' + typestructure+'/'+structure);
  }
}
