import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FoyerService } from 'src/app/services/foyer.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { CollectiivteStructureService } from 'src/app/services/collectivitestructure.service ';
import { StructureService } from 'src/app/services/structure.service';
import { error } from 'node:console';

declare function stransAdresse(id: any);
declare var $: any;

@Component({
  selector: 'app-frmcollectiviteprestataire',
  templateUrl: './frmcollectiviteprestataire.component.html',
  styleUrls: ['./frmcollectiviteprestataire.component.css']
})
export class FrmcollectiviteprestataireComponent implements OnInit {

  structures: any = [];
  selectedstructures: any = [];
  // datafoyers: any = [];
  structure: any = null;
  collectivitestructure: any = null;
  typestructure: any;
  lblstructure;

  siret: any = '';
  nom: any = '';
  adresse: any = '';
  responsable: any = '';
  email: any = '';
  collectivite: any = '';
  codepostal: any = '';
  telephone: any = '';
  telephoneportable: any = '';
  ville: any = '';
  parent;
  prestataire;
  codePostal: any = '';
  error = false;
  errorEmail = false;
  errorTel = false;
  errorSiret = false;
  errorSelectColect = false;
  test = true;
  foyer: any;
  infoError = "Champs invalide";
  infoErrorsiret = "Champs invalide";
  selectedstructure;
  collectiviteTad;
  collectivites: any = [];
  typestructures = ['FOYER', 'FAMILLEACCUEIL'];
  loadingsiren = false;
  datecreation: any;
  errormessage = '';
  errorTelFixe = false;
  errorDate = false;
  infoErrorTelFixe = "Champs invalide";
  @ViewChild('structureModal') structureModal;

  @ViewChild('adresseFieldFP') adresseFieldFP;

  constructor(private http: HttpClient, private foyerService: FoyerService,
    private collectiviteStructureService: CollectiivteStructureService,
    private structureService: StructureService) { }

  ngOnInit(): void {
    stransAdresse('adresse');
    // this.typestructure = [TypeStructureModel.FOYER,TypeStructureModel.FAMILLEACCUEIL];
  }

  showStructure(): void {
    if (this.typestructure == "PRESTATAIRE") {
      this.structureService.getStructureNonRattachee(this.typestructure, this.selectedstructure).subscribe(data => {
        this.structures = data;
      },
        error => {
          this.structures = [];
        }
      );
    } else if (this.typestructure == "FOYER") {

      this.structureService.getStructureNonRattacheeFA(this.typestructures, this.selectedstructure).subscribe(data => {
        this.structures = data;

      },
        error => {
          this.structures = [];
        }
      );
    }
  }
  openSelect() {
    this.valideStrucSeleted();
    this.showStructure();
  }
  showCollectivite(): void {
    this.structureService.getStructuresByType(TypeStructureModel.COLLECTIVITE).subscribe(data => {
      this.collectivites = data;
    });


  }

  show(parent) {
    this.parent = parent;
    if(this.collectivitestructure != null){
      $(".siren").attr("disabled", true);
      
    }else{
      $(".siren").attr("disabled", false);
    }

    if (this.selectedstructure != null) {
      $(".struct").attr("disabled", true);
      this.showStructure();
    }else{
      $(".struct").attr("disabled", false);
    }
    this.showCollectivite();
    // this.showStructure();
    $(this.structureModal.nativeElement).modal('show');
  }

  savePrestaFoyer() {
    if (!this.valideStrucSeleted()) {
      if (this.nom != '')
        this.save();
      console.log(this.selectedstructures);
      for (const structure of this.selectedstructures) {
        console.log(structure);
        const headers = {};
        const body = { actif: 1, structure: { id: structure }, collectivite: { id: this.selectedstructure } }
        this.collectiviteStructureService.save(headers, body).subscribe(data => {
          this.parent.filter(null, this.collectivite);
          this.fermer();
        });
      }
    }
  }


  fermer() {
    this.resetForm();
    $(this.structureModal.nativeElement).modal('hide');
  }


  resetForm() {
    this.selectedstructures = [];
    this.selectedstructure = null;
    this.structures = [];
    $(".struct").attr("disabled", false);
    this.siret = '';
    this.nom = '';
    this.adresse = '';
    this.responsable = '';
    this.email = '';
    this.ville = '';
    this.codePostal = '';
    this.telephone = '';
    this.datecreation = '';
    this.errormessage = '';
  }

  oncpChange(e) {
    if (e.target.checked) {
      this.selectedstructures.push(e.target.value);
    } else {
      let i = 0;
      this.selectedstructures.forEach((selPens: any) => {
        if (selPens == e.target.value) {
          delete this.selectedstructures[i];
          return;
        }
        i++;
      });
    }

  }
  pushDatas() {

    $('#adresse')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#ville')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#codePostal')[0].dispatchEvent(new Event('input', { bubbles: true }));
    const headers = {};
    const body = {
      siret: this.siret, nom: this.nom,
      adresse: this.adresse, telephone: this.telephone,
      responsable: this.responsable, ville: this.ville, codePostal: this.codePostal,
      email: this.email, typestructure: this.typestructure,datecreation:this.datecreation, actif: true
    };
    console.log(this.prestataire);
    if (!this.prestataire) {
      this.structureService.save(headers, body).subscribe(data => {
        this.structure = data;
        this.savecolstruc();
        this.fermer();
      }, err => {
        console.log(err);
        this.errormessage = 409 === err.status ? 'Le prestataire existe déjà !' : '' ;
        // Swal.fire({icon: 'error', text: 'Erreur lors de l\'ajout du prestataire: ' + errormessage});
      });
    } else {
      this.structureService.update(headers, body, this.prestataire.id).subscribe(data => {
        this.fermer();
      });
    }
  }

  pushData() {

    $('#adresse')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#ville')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#codePostal')[0].dispatchEvent(new Event('input', { bubbles: true }));
    const headers = {};
    const body = {
      siret: this.siret, nom: this.nom,
      adresse: this.adresse,telephone: this.telephone,responsable: this.responsable, email: this.email,
      ville: this.ville, codePostal: this.codePostal,
      typestructure: this.typestructure,datecreation:this.datecreation, actif: true
    };
    console.log(this.prestataire);
    if (!this.prestataire) {
      this.structureService.save(headers, body).subscribe(data => {
        this.structure = data;
        this.savecolstruc();
        this.fermer();
      }, err => {
        // console.log(err);
        // this.errormessage = 409 === err.status ? 'Le prestataire existe déjà !' : '' ;
        // Swal.fire({icon: 'error', text: 'Erreur lors de l\'ajout du prestataire: ' + errormessage});
       // alert(this.siret);
        this.structureService.getBySiretStructure(this.siret,this.selectedstructure).subscribe(data =>{
          console.log(data);
          if (!data || !!this.collectivitestructure) {
            this.structureService.getBySiret(this.siret).subscribe(data => {
              this.structure = data;
              this.savecolstruc(); 
              this.fermer();
            });
          }else{
            this.errormessage = 409 === err.status ? 'Le prestataire existe déjà pour cette collectivité !' : '' ;
          }
        });
        
      });
    } else {
      this.structureService.update(headers, body, this.prestataire.id).subscribe(data => {
        this.fermer();
      });
    }
  }

  save(): void {

    if (this.valideInput()) {
      this.pushData();
    }
  }
  savecolstruc(): void {
    const headers = {};
    const body = {telephone: this.telephone,responsable: this.responsable, email: this.email,
       structure: this.structure, collectivite: { id: this.selectedstructure }, actif: true };
    console.log(this.collectivitestructure);
    if (!this.collectivitestructure) {
      this.collectiviteStructureService.save(headers, body).subscribe(data => {
        //   alert("ok");
        this.parent.filter(null, this.collectivite);
        //  this.personne=data;
        //  $('#modalCollectiviteStructure').modal('hide');
        // this.parent.filter(null,this.structure);
      });
    } else {
      this.collectiviteStructureService.update(headers, body, this.collectivitestructure.id).subscribe(data => {
        //  $('#modalCollectiviteStructure').modal('hide');
        // this.parent.filter(null,this.structure);
        this.parent.filter(null, this.collectivite);

      });
    }
  }

  valideInput() {
    // if (this.siret.trim().length == 0) {
    //   this.error = true;
    //   this.test = false;
    // } else {
    //   this.error = false;
    //   this.test = true;
    // }
    if (this.valideEmail() == true) {
      this.test = false;
    }
    if (this.valideTelFixe() == true) {
      this.test = false;
    }
    return this.test;
  }
  valideTel() {
    var reg = /^(06|07)[0-9]{8}/gi;
    this.telephone = this.telephone.replaceAll(' ', '');
    this.telephone = this.telephone.replaceAll('-', '');
    if (this.telephone.length != 0) {
      if (this.telephone.length == 10) {
        if (!this.telephone.match(reg)) {
          this.errorTel = true;
          this.infoError = 'Le numero doit imperativement commencer par 06 ou 07.';
        } else {
          this.errorTel = false;
        }
      } else {
        // if(this.telephone.length < 10)
        this.infoError = 'Vous avez saisi ' + this.telephone.length + '.La longueur requise est de 10 chiffres.';
        this.errorTel = true;
        this.infoError = "Vous avez saisie "
      }
    } else {
      this.errorTel = false;
    }
    return this.errorTel;
  }
  valideTelFixe() {
    var reg = /^(01|02|03|04|05|08|09)[0-9]{8}/gi;
    if(!!this.telephone){
      this.telephone = this.telephone.replaceAll(' ', '');
      this.telephone = this.telephone.replaceAll('-', '');

      if (this.telephone.length != 0) {
        if (this.telephone.length == 10) {
          if (!this.telephone.match(reg)) {
            this.errorTelFixe = true;
            this.infoErrorTelFixe = 'Le numero saisi doit impérativement commencer par 01 ou 02 ou 03 ou 04 ou 05 ou 08 ou 09.';
          } else {
            this.errorTelFixe = false;
          }
        } else {
          this.infoErrorTelFixe = 'Vous avez saisi ' + this.telephone.length + '.La longueur requise est de 10 chiffres.';
          this.errorTelFixe = true;
        }
      } else {
        // alert('No 10')
        this.errorTelFixe = false;
      }
    }else{
      this.errorTelFixe = false;
    }
    
    
    return this.errorTelFixe;
  }
  valideEmail() {
    var validRegex = "^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$";
    if (this.email.trim().length != 0) {
      if (this.email.match(validRegex)) {
        this.errorEmail = false;
      } else {
        this.errorEmail = true;
      }
    }
    return this.errorEmail;
  }
  valideStrucSeleted() {
    this.errorSelectColect = false;
    if (!this.selectedstructure) {
      this.errorSelectColect = true;
    }
    return this.errorSelectColect;
  }


  searchEntrepriseFromSiren() {

    this.nom = '';
    this.adresse = '';
    this.responsable = '';
    this.email = '';
    this.ville = '';
    this.codePostal = '';
    this.telephone = '';
    this.datecreation = '';
    this.errormessage = '';
    if (this.siret.toString().length < 9) {
      return ;
    }

    this.loadingsiren = true;
    this.structureService.searchFromSiren(this.siret).subscribe(data => {
     this.loadingsiren = false;
     if (data) {
      this.siret = data?.siren;
      this.nom = data?.nom;
      this.datecreation = new Date(+ data?.dateCreation.substr(0, 4), + data?.dateCreation.substr(4, 2) - 1
     , + data?.dateCreation.substr(6, 2));
      console.log('dateCreation --> ', this.datecreation);
      this.adresse = data?.adresse;
      this.ville = data?.ville;
      this.codePostal = data?.codePostal;
     } else {
      this.errormessage = 'Siren non valide';
     }


    }, err => {
      this.loadingsiren = false;
    });
 }


 valideSiret() {
  var reg = /^(06|07)[0-9]{8}/gi;
  if(!!this.siret){
    this.siret = this.siret.replaceAll(' ', '');
    this.siret = this.siret.replaceAll('-', '');
    if (this.siret.length != 0) {
      if (this.siret.length == 9) {
        
          this.errorSiret = true;
        //  this.infoErrorsiret = 'Le numero saisi doit impérativement commencer par 06 ou 07.';
        // else {
        //   this.errorSiret = false;
        // }
      } else {
        this.infoErrorsiret = 'Vous avez saisi ' + this.siret.length + '.La longueur requise est de 9 chiffres.';
        this.errorSiret = true;
      }
    } else {
      // alert('No 10')
      this.errorSiret = false;
    }
  }else{
    this.errorSiret = false;
  }
  
  return this.errorSiret;
}

editPrestaire(parent, id: any): void {
  // this.parent=parent;
  this.collectiviteStructureService.get(id).subscribe(data => {
    this.collectivitestructure = data;
    if (!!this.collectivitestructure) {
      this.structure = this.collectivitestructure.structure;
     
      if (this.collectivitestructure.structure) {
        this.selectedstructure = this.collectivitestructure.collectivite.id;
        
      }

      if (this.collectivitestructure.structure != null) {
        this.typestructure = this.collectivitestructure.structure.typestructure;
        this.nom = this.collectivitestructure.structure.nom;
        this.siret = this.collectivitestructure.structure.siret;
        this.datecreation = this.collectivitestructure.structure.datecreation;
        this.ville = this.collectivitestructure.structure.ville;
        this.codePostal = this.collectivitestructure.structure.codePostal;
        this.responsable = this.collectivitestructure.responsable;
        this.email = this.collectivitestructure.email;
        this.telephone = this.collectivitestructure.telephone;
       

      }

    }


    this.show(parent);

  });
}
}

