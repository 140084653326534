<div class="modal fade modal-ds" data-backdrop="static" #acteurModal id="modalActeur" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{lblnom}}
                </h5>

                <button type="button" (click)="resetForm()" class="close" (click)="fermer()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="">
                    <div class="">

                        <form class="mt-lg-3 Needs-validation1" novalidate>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label
                                    text-sm-right pr-0">
                                    <label class="mb-0">
                                        {{lblstructure}} <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <select class="form-control coll" [(ngModel)]="selectstructure"
                                        name="selectstructure" id="coll">
                                        <option value="">&nbsp;</option>
                                        <option *ngFor="let prestataire of
                                            collectivites;" value="{{prestataire.id}}">{{prestataire.nom}}</option>
                                    </select>
                                </div>

                            </div>
                            <div class="form-group row" *ngIf="showInputFonction==true">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Fonction <label style="color:red">

                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <select [(ngModel)]="fonction" name="fonction" class="form-control">
                                        <option value="">&nbsp;</option>
                                        <option *ngFor="let fonct of fonctions;" value="{{fonct.id}}">{{fonct.libelle}}
                                        </option>
                                    </select>

                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label
                                        text-sm-right pr-0">
                                    <label class="mb-0">
                                        Prénom <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" (change)="valideInput()" [ngClass]="{'error':errorprenom==true}"
                                        [(ngModel)]="prenom" size="4" name="prenom" class="form-control
                                            col-sm-8 col-md-6" required>
                                    <span *ngIf="errorprenom==true" style="color: rgb(168, 54,
                                            45);font-size: small;">
                                        Champs obligatoire.
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label
                                        text-sm-right pr-0">
                                    <label class="mb-0">
                                        Nom <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" (change)="valideInput()" [ngClass]="{'error':error==true}"
                                        [(ngModel)]="nom" size="4" name="nom" class="form-control
                                            col-sm-8 col-md-6" required>
                                    <span *ngIf="error==true" style="color:
                                            rgb(168, 54, 45);font-size: small;">
                                        Champs obligatoire.
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="showInputTelephoneFixe==true">
                                <div class="col-sm-3 col-form-label
                                        text-sm-right pr-0">
                                    <label class="mb-0">
                                        Téléphone fixe
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" [(ngModel)]="telephone1" name="telephone1"
                                        (change)="valideTelFixe()" [ngClass]="{'error':errorTelFixe==true}" class="form-control col-sm-8
                                            col-md-6">
                                    <span *ngIf="errorTelFixe==true" style="color: rgb(168, 54,
                                                    45);font-size: small;">
                                        {{infoErrorTelFixe}}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label
                                        text-sm-right pr-0">
                                    <label class="mb-0">
                                        Téléphone portable
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" [(ngModel)]="telephone2" name="telephone2" (change)="valideTel()"
                                        [ngClass]="{'error':errorTel==true}" class="form-control col-sm-8
                                            col-md-6">
                                    <span *ngIf="errorTel==true" style="color: rgb(168, 54,
                                            45);font-size: small;">
                                        {{infoError}}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row" *ngIf="showInputAdresse==true">
                                <div class="col-sm-3 col-form-label
                                        text-sm-right pr-0">
                                    <label class="mb-0">
                                        Adresse
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input #adresseField class="form-control" placeholder="Adresse" id="adresse"
                                        [(ngModel)]="adresse" name="adresse" autocomplete="on"/>
                                </div>
                            </div> 

                            <div class="form-group row" *ngIf="showInputAdresse==true">
                                <div class="col-sm-3 col-form-label
                                        text-sm-right pr-0">
                                    <label class="mb-0">
                                        Ville
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input readonly type="text" id="ville" [(ngModel)]="ville" name="ville" class="form-control col-sm-8
                                            col-md-6">
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="showInputAdresse==true">
                                <div class="col-sm-3 col-form-label
                                        text-sm-right pr-0">
                                    <label class="mb-0">
                                        Code Postal
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input readonly type="text" id="codePostal" name="codePostal"
                                        [(ngModel)]="codePostal" class="form-control col-sm-8
                                            col-md-6">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label
                                        text-sm-right pr-0">
                                    <label class="mb-0">
                                        Information complémentaire
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <textarea class="form-control" [(ngModel)]="information" name="information"
                                        placeholder="" style="overflow: hidden;
                                            overflow-wrap: break-word; resize:
                                            horizontal; height: 62px;"></textarea>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label
                                        text-sm-right pr-0">
                                    <label class="mb-0">
                                        Email
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input (change)="valideEmail()" [ngClass]="{'error':errorEmail==true}" type="email"
                                        [(ngModel)]="email" name="email" class="form-control
                                            col-sm-8 col-md-6">
                                    <span *ngIf="errorEmail==true" style="color: rgb(168, 54,
                                            45);font-size: small;">
                                        L'email est invalide.
                                    </span>
                                </div>
                            </div>


                            <div class="form-group row" *ngIf="showInputdateNaissance==true">
                                <div class="col-sm-3 col-form-label
                                        text-sm-right pr-0">
                                    <label class="mb-0">
                                        Date naissance
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="Date" (change)="valideDateNaiss()" [(ngModel)]="datenaissance"
                                        name="datenaissance" class="form-control col-sm-8
                                            col-md-6">
                                    <!-- <span *ngIf="errorDate==true" style="color: rgb(168, 54,
                                            45);font-size: small;">
                                            {{infoDate}}
                                        </span>-->
                                </div>
                            </div>

                           
                        </form>
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="fermer()">
                    Fermer
                </button>
                <button type="button" class="btn btn-primary" (click)="savePersonne()">
                    Enregistrer
                </button>

            </div>
        </div>
    </div>
</div>