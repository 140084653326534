import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AppModule } from '../../app.module';
import { TemplatePrixService } from "../../services/templatePrix.service";
import { StructureService } from "../../services/structure.service";
import { TypetemplateService } from "../../services/typetemplate.service";
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { FrmtemplateprixComponent } from '../frmtemplateprix/frmtemplateprix.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { SecurityService } from 'src/app/services/security.service';

declare var $: any;
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FoyerService } from 'src/app/services/foyer.service';

@Component({
  selector: 'app-lsttemplateprix',
  templateUrl: './lsttemplateprix.component.html',
  styleUrls: ['./lsttemplateprix.component.css']
})
export class LsttemplatePrixComponent implements OnInit {

  data: any = [];
  template: any = null;

  collectivites: any = [];

  types: any = [];
  prestataires: any = [];

  delaiannualation: any = '';

  delaiminim: any = '';



  flagapproche: any = '';



  flagretour: any = '';


  pointref: any = '';


  tarifjour: any = '';


  tarifnuit: any = '';


  tarifweekend: any = '';


  tarifmin: any = '';


  flagsamedi: any = '';


  flagdimanche: any = '';
  priseenncharge: any = '';
  typetemplate: any = '';
  datedebut: any = '';
  datefin: any = '';
  heuredebutjour: any = '';
  heuredebutnuit: any = '';
  collectivite: any = '';
  prestataire: any = '';
  pres: any;
  user: User = new User();
  col;
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  canCreate = false;
  canEdit = false;
  canDelete = false;
  canDetails = false;
  dataDebut = null;
  dataFin = null;
  tarifJour = null;
  tariNuit = null;
  tariWeekend = null;
  tarifMin = null;
  tariSup = null;
  delaiMin = null;
  delaiAnn = null;
  heureJour = null;
  heureNuit = null;
  idcollectivite = 0;
  idtad = 0;
  idprestataire = 0;
  prest: any = 0;
  critereDateDebut: any = '1';
  critereDateFin: any = '1';
  criteretarifJour: any = '1';
  criteretariNuit: any = '1';
  criteretariWeekend: any = '1';
  criteretarifMin: any = '1';
  criteretariSup: any = '1';
  dateFinMax = null;
  dateDebutMax = null;
  tarifJourMax = null;
  tarifNuitMax = null;
  tarifWeekMax = null;
  tarifMinMax = null;
  tariSupMax = null;
  libelle = null;
  referencemarche = null;
  collectivitestemp: any = [];
  idtadstemp: any = [];
  idprestatairestemp: any = [];
  tadSelected: any = 0;

  @ViewChild('frmtemplateprix') frmtemplateprix: FrmtemplateprixComponent;

  constructor(private http: HttpClient, private templateprixService: TemplatePrixService,
    private structureService: StructureService, private securiteService: SecurityService,
    private typetemplateService: TypetemplateService, private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,private foyerService: FoyerService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.pres = this.user.structure;
    this.prest = this.user.structure;
    this.col = this.user.parent;
    this.showDoits();
    this.show();
    this.showtype();
    this.showCollectivite();
    this.showTad();
    this.showPrestataire();

  }

  filter(parent: any, structure: any) {
    this.show();
  }
  showCollectivite(): void {
 
    this.structureService.getCollectiviteByProfil(this.user.id).subscribe(data => {
      this.collectivitestemp = data;

    });
 
  }

  showTad(): void {
  
    this.structureService.getTadByProfil(this.user.id).subscribe(data => {
      this.idtadstemp = data;

    });
  
  

  }
  
  showPrestataire(): void {
  
    this.structureService.getPrestatairesByProfil(this.user.id).subscribe(data => {
      this.idprestatairestemp = data;

    });
  
  }

  showDoits() {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_PRESTEMPLATE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_PRESTEMPLATE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_EDIT_TEMPLATEPRIX_PRESTATAIRE").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canEdit = true;
      } else {
        this.canEdit = false;
      }
    });
  }

  edit(id: any) {
    this.frmtemplateprix.edit(this, id);
    this.frmtemplateprix.lblnom = 'Modification Template prix'
  }

  openFormTemplatePrix() {
    if (this.user.structure != 0) {
      this.frmtemplateprix.prestataire = this.user.structure;
    }
    this.frmtemplateprix.show(this);
    this.frmtemplateprix.lblnom = 'Ajout Template prix'
  }
  showTemplates(prestataire: any, prestacol: any): void {
    this.show();
  }
  show(): void {
    this.spinner.show();
    if (this.user.structure == 0) {
      this.templateprixService.getData().subscribe(data => {
        this.data = data;
        this.template = null;
        this.spinner.hide();
      });
    } else if (this.user.profilCode == "PF_PRESTAIRE") {
      this.templateprixService.getTemplatesByStructure(this.pres).subscribe(data => {
        this.data = data;
        this.template = null;
        this.spinner.hide();
      });
    } else if (this.user.profilCode == "PF_AGCOL") {
      this.templateprixService.getTemplatesByCollectivite(this.pres).subscribe(data => {
        this.data = data;
        this.template = null;
        this.spinner.hide();
      });
    } else if (this.user.profilCode == "PF_TAD") {
      this.templateprixService.getTemplatesByCollectivite(this.col).subscribe(data => {
        this.data = data;
        this.template = null;
        this.spinner.hide();
      });
    } else if (this.user.profilCode == "PF_GFOYER") {
      this.templateprixService.getTemplatesByFoyer(this.pres).subscribe(data => {
        this.data = data;
        this.template = null;
        this.spinner.hide();
      });
    }



  }

  showtype(): void {
    this.typetemplateService.getData().subscribe(data => {
      this.types = data;

    });

  }

  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.templateprixService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.show();
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car ce template prix a déjà été utilisé pour une réservation" });
          }
        );
      }
    })
    this.tabSize(event);

 //   if (confirm('Voulez vraiment supprimer cet élément ? ')) {
 //     this.templateprixService.delete(id).subscribe(data => {
 //       this.show();
 //     });
 //   }
  }
  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.data = this.data;
    // this.showData();
  }

  rechercheAvanceTemplateRAS() {
    if (this.idcollectivite == null)
      this.idcollectivite = 0;
    // alert(this.tadSelected)
    this.dataDebut = this.dataDebut ? this.dataDebut : null;
    this.dataFin = this.dataFin ? this.dataFin : null;
    this.tarifJour = this.tarifJour ? this.tarifJour : null;
    this.tariSup = this.tariSup ? this.tariSup : null;
    this.tariNuit = this.tariNuit ? this.tariNuit : null;
    this.tarifMin = this.tarifMin ? this.tarifMin : null;
    this.tarifweekend = this.tarifweekend ? this.tarifweekend : null;
    if (this.user.structure == 0) {
      this.templateprixService.getRecherche(this.idcollectivite, this.dataDebut, this.dataFin, this.tarifJour
        , this.tariNuit, this.tariSup, null, this.tariWeekend).subscribe(data => {
          this.data = data;
          console.log(data);
        });
    }
  }

  rechercheAvanceTemplateBisBis() {
    if (this.idcollectivite == null)
      this.idcollectivite = 0;
    // alert(this.tadSelected)
    this.dataDebut = this.dataDebut ? this.dataDebut : null;
    this.dataFin = this.dataFin ? this.dataFin : null;
    this.tarifJour = this.tarifJour ? this.tarifJour : null;
    this.tariSup = this.tariSup ? this.tariSup : null;
    this.tariNuit = this.tariNuit ? this.tariNuit : null;
    this.tarifweekend = this.tarifweekend ? this.tarifweekend : null;
    if (this.user.structure == 0) {
      this.templateprixService.getRechercheBis(this.idcollectivite, this.dataDebut, this.critereDateDebut, this.dataFin,
        this.critereDateFin, this.tarifJour, this.criteretarifJour, this.tariNuit, this.criteretariNuit,
        this.tariSup, this.criteretariSup, this.tariWeekend, this.criteretariWeekend).subscribe(data => {
          this.data = data;
          console.log(data);
        });
    }
  }

  rechercheAvanceTemplate() {
    //this.onCollectivteBlur($event);
    this.spinner.show();
    $(".collapse").collapse('hide');
    //alert(this.tarifJour)
    if (this.idcollectivite == null )
      this.idcollectivite = 0;
    if (this.idtad == null)
      this.idtad = 0;
    if (this.idprestataire == null)
      this.idprestataire = 0;

    // alert(this.tadSelected)
    this.libelle = this.libelle ? this.libelle : null;
    this.referencemarche = this.referencemarche ? this.referencemarche : null;
    this.dataDebut = this.dataDebut ? this.dataDebut : null;
    this.dateDebutMax = this.dateDebutMax ? this.dateDebutMax : null;
    this.dataFin = this.dataFin ? this.dataFin : null;
    this.dateFinMax = this.dateFinMax ? this.dateFinMax : null;
    this.tarifJour = this.tarifJour ? this.tarifJour : null;
    this.tarifJourMax = this.tarifJourMax ? this.tarifJourMax : null;
    this.tariSup = this.tariSup ? this.tariSup : null;
    this.tariSupMax = this.tariSupMax ? this.tariSupMax : null;
    this.tariNuit = this.tariNuit ? this.tariNuit : null;
    this.tarifNuitMax = this.tarifNuitMax ? this.tarifNuitMax : null;
    this.tarifweekend = this.tarifweekend ? this.tarifweekend : null;
    this.tarifWeekMax = this.tarifWeekMax ? this.tarifWeekMax : null;
    if (this.user.structure == 0) {
      this.templateprixService.getRechercheBisBis(this.idcollectivite,this.idtad,this.idprestataire,this.libelle, this.dataDebut, this.critereDateDebut, this.dateDebutMax, this.dataFin,
        this.critereDateFin, this.dateFinMax, this.tarifJour, this.criteretarifJour, this.tarifJourMax, this.tariNuit, this.criteretariNuit,
        this.tarifNuitMax, this.tariSup, this.criteretariSup, this.tariSupMax, this.tariWeekend, this.criteretariWeekend, this.tarifWeekMax, this.referencemarche).subscribe(data => {
          this.data = data;
         // console.log(data);
          this.spinner.hide();
        });
        this.tabSize(event);
    } else if (this.user.profilCode == "PF_PRESTAIRE") {
      this.templateprixService.getRecherchePrestataire(this.idcollectivite,this.idtad,this.idprestataire,this.libelle, this.dataDebut, this.critereDateDebut, this.dateDebutMax, this.dataFin,
        this.critereDateFin, this.dateFinMax, this.tarifJour, this.criteretarifJour, this.tarifJourMax, this.tariNuit, this.criteretariNuit,
        this.tarifNuitMax, this.tariSup, this.criteretariSup, this.tariSupMax, this.tariWeekend, this.criteretariWeekend,
        this.tarifWeekMax, this.pres, this.referencemarche).subscribe(data => {
          this.data = data;
         // console.log(data);
          this.spinner.hide();
        });
        this.tabSize(event);
    }
    else if (this.user.profilCode == "PF_AGCOL") {
      this.templateprixService.getRechercheCollectivite(this.idcollectivite, this.idtad,this.idprestataire,this.libelle,this.dataDebut, this.critereDateDebut, this.dateDebutMax, this.dataFin,
        this.critereDateFin, this.dateFinMax, this.tarifJour, this.criteretarifJour, this.tarifJourMax, this.tariNuit, this.criteretariNuit,
        this.tarifNuitMax, this.tariSup, this.criteretariSup, this.tariSupMax, this.tariWeekend, this.criteretariWeekend,
        this.tarifWeekMax, this.pres, this.referencemarche).subscribe(data => {
          this.data = data;
         // console.log(data);
          this.spinner.hide();
          //alert(this.data)
        });
        this.tabSize(event);
    } else if (this.user.profilCode == "PF_TAD") {
      this.templateprixService.getRechercheCollectivite(this.idcollectivite, this.idtad,this.idprestataire,this.libelle,this.dataDebut, this.critereDateDebut, this.dateDebutMax, this.dataFin,
        this.critereDateFin, this.dateFinMax, this.tarifJour, this.criteretarifJour, this.tarifJourMax, this.tariNuit, this.criteretariNuit,
        this.tarifNuitMax, this.tariSup, this.criteretariSup, this.tariSupMax, this.tariWeekend, this.criteretariWeekend,
        this.tarifWeekMax, this.col, this.referencemarche).subscribe(data => {
          this.data = data;
          this.spinner.hide();
         // console.log(data);
        });
        this.tabSize(event);
    }
    else if (this.user.profilCode == "PF_GFOYER") {
      this.templateprixService.getRechercheFoyer(this.idcollectivite,this.idtad,this.idprestataire,this.libelle, this.dataDebut, this.critereDateDebut, this.dateDebutMax, this.dataFin,
        this.critereDateFin, this.dateFinMax, this.tarifJour, this.criteretarifJour, this.tarifJourMax, this.tariNuit, this.criteretariNuit,
        this.tarifNuitMax, this.tariSup, this.criteretariSup, this.tariSupMax, this.tariWeekend, this.criteretariWeekend,
        this.tarifWeekMax, this.pres, this.referencemarche).subscribe(data => {
          this.data = data;
          this.spinner.hide();
         // console.log(data);
        });
        this.tabSize(event);
    }
  }
 
  reset1(){
    this.idcollectivite = 0;
   }
   reset2(){
    this.idtad = 0;
   }
   reset3(){
    this.dataDebut = null;
   }
   reset4(){
    this.dataFin = null;
   }
   reset5(){
    this.dateDebutMax = null;
   }
   reset6(){
    this.dateFinMax = null;
   }
   reset7(){
    this.idprestataire = 0;
   }
   reset8(){
    this.libelle = null; 
   }
   reset9(){
    this.referencemarche = null; 
   }
}
