<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Réservations
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Calendrier
      </small>
    </h1>

  </div>


  <div class="px-2">

    <div class="row mt-3">
      <div class="col-12">
        <div class="card w-100 mt-1 mb-1">
          <div class="card-body p-0">
            <div class="accordion">
              <div class="card border-0 mt-1px bgc-primary-l5">
                <div class="card-header border-0 bgc-transparent mb-0">
                  <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                    <a aria-controls="rechercheAvancee" aria-expanded="false"
                       class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                       data-toggle="collapse" href="#rechercheAvancee">
                      Recherche avancée 

                      <span
                        class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                            </span>
                      <span
                        class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                            </span>
                    </a>
                  </h2>
                </div>
                <div aria-labelledby="headingTwo4" class="collapse" data-parent="#rechercheAvancee"
                     id="rechercheAvancee" style="">
                  <div class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                    <div class="form-group row">

                      <div class="col-md-4">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Unités
                            </label>
                          </div>

                          <div (keyup.enter)="rechercheAvance()" class="col-sm-8 pl-2">
                            <select [(ngModel)]="idtad" class="form-control" name="idtad">
                              <option value="0">&nbsp;</option>
                              <option *ngFor="let tad of tads;" value="{{tad.id}}">{{tad.nom}}</option>
                            </select>
                          </div>
                        </div>

                      </div>

                      <div class="col-md-4">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Créée par
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <input [(ngModel)]="creerpar" class="form-control" name="creerpar" type="text">
                          </div>
                        </div>

                      </div>

                      <div class="col-md-4">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Prestataire
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <select [(ngModel)]="idprestataire" class="form-control" name="idprestataire">
                              <option value="0">&nbsp;</option>
                              <option *ngFor="let presta of prestataires;"
                                      value="{{presta.id}}">{{presta.nom}}</option>
                            </select>
                          </div>
                        </div>

                      </div>


                    </div>


                    <div class="form-group row">

                      <div class="col-md-4">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Date
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <select [(ngModel)]="critereDaterer" class="form-control" name="critereDaterer"
                            >
                              <option value="1">Egal</option>
                              <option value="2">Inférieur</option>
                              <option value="3">Supérieur</option>
                              <option value="4">Compris entre</option>
                            </select>


                            <input [(ngModel)]="datecrer" class="form-control" id="datecrer" name="datecrer"
                                   type="date"/>
                            <button (click)='reset1()' class="btn btn-sm btn-secondary"
                                    type="button">
                              Effacer
                            </button>


                          </div>


                        </div>

                      </div>

                      <div class="col-md-4">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Statut
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <select [(ngModel)]="idstatut" class="form-control" id="statutselect" name="idstatut">
                              <option value="0">&nbsp;</option>
                              <option *ngFor="let statut of statues"
                                      [value]="statut.id">{{statut.libelle}}</option>
                            </select>
                          </div>
                        </div>

                      </div>

                      <div class="col-md-4">

                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Passager
                            </label>
                          </div>

                          <div class="col-sm-8 pl-2">
                            <input [(ngModel)]="passager" class="form-control" name="passager" type="text">
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="form-group row">

                      <div class="col-md-4">

                        <div class="form-group row">
                          <div *ngIf="this.critereDaterer == '4'" class="col-sm-4 col-form-label text-sm-right pr-2 ">
                            <label class="mb-0">
                              Date Max
                            </label>
                          </div>

                          <div *ngIf="this.critereDaterer == '4'" class="col-sm-8 pl-2">
                            <input [(ngModel)]="dateMax" class="form-control" id="dateMax" name="dateMax"
                                   type="date"/>
                            <button (click)='reset2()' class="btn btn-sm btn-secondary"
                                    type="button">
                              Effacer
                            </button>
                          </div>


                        </div>

                      </div>
                      <div class="col-md-2">

                        <div class="form-group row">
                          <div class="col-sm-8 pl-2">
                            <button (click)="rechercheAvance()"
                                    class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10"
                                    type="button">
                              <i class="fa fa-search mr-1"></i>

                            </button>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>

        <div class="card dcard">
          <div class="card-body px-1 px-md-3">

            <full-calendar #mycal [options]="calendarOptions"></full-calendar>


          </div>
        </div>

      </div>

    </div>


  </div>

</div>


<app-modalreservationretails #details></app-modalreservationretails>
