import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';


export class ServiceService {
  mod = '';
  constructor(private http: HttpClient, module: any) {
    this.mod = module;
  }

  getData() {
    return this.http.get(AppModule.API_ENDPOINT + this.mod + '/list');
  }

  getDataPage(num:any,total:any) {
    return this.http.get(AppModule.API_ENDPOINT + this.mod + '/list/' + num + '/' + total);
  }

  get(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + this.mod + '/get/' + id);
  }

  save(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + this.mod + '/create', body, { headers });
  }

  update(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + this.mod + '/update/' + id, body, {headers});
  }

  updateHeure(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + this.mod + '/updateheure/' + id, body, {headers});
  }


  delete(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + this.mod + '/delete/' + id);
  }

  deleteNote(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + this.mod + '/deleteNote/' + id);
  }

  getNotes(reservationId) {
    return this.http.get(AppModule.API_ENDPOINT + this.mod + '/notes/' + reservationId);
  }

  saveNote(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + this.mod + '/note/create', body, {headers});
  }

  deleteUser(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + this.mod + '/deleteuser/' + id);
  }

  getRechercheUser(prenom: any, nom: any, email: any, profil: any) {
    return this.http.get(AppModule.API_ENDPOINT + this.mod + '/rechercheuser/' + prenom + '/' + nom + '/' + email + '/' + profil);
  }

  getRechercheUserBIS(prenom: any, nom: any, email: any, profil: any,id:any) {
    return this.http.get(AppModule.API_ENDPOINT + this.mod + '/rechercheuser/' + prenom + '/' + nom + '/' + email + '/' + profil+'/'+id);
  }

  getNotesBis(reservationId) {
    return this.http.get(AppModule.API_ENDPOINT + this.mod + '/notesbis/' + reservationId);
  }

  getDataBis() {
    return this.http.get(AppModule.API_ENDPOINT + this.mod + '/listbis');
  }
}
