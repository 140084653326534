import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TypetemplateService} from '../../../services/typetemplate.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-frmtypetemplate',
  templateUrl: './frmtypetemplate.component.html',
  styleUrls: ['./frmtypetemplate.component.css']
})
export class FrmtypetemplateComponent implements OnInit {

  code: any ;
  libelle: any ;
  description: any ;
  typeTemplate: any ;

  constructor(private http: HttpClient, private typetemplateService: TypetemplateService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  edit(id: any): void {
    this.typetemplateService.get(id).subscribe(data => {
      this.typeTemplate = data;
      this.code = this.typeTemplate.code;
      this.libelle = this.typeTemplate.libelle;
      $('#modalForm').modal('show');
    });

  }
  show(): void {
    $('#modalForm').modal('show');
  }

  save(): void {
    const headers = { };
    const body = { code: this.code, libelle: this.libelle , actif: true  };
    console.log(this.typeTemplate);
    if (!this.typeTemplate) {
      this.typetemplateService.save(headers, body).subscribe(data => {
       // this.show();
        $('#modalForm').modal('hide');
      });
    } else {
      this.typetemplateService.update(headers, body, this.typeTemplate.id).subscribe(data => {
      //  this.show();
        $('#modalForm').modal('hide');
      });
    }
  }


}
