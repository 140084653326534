<div class="modal fade modal-ds" data-backdrop="static" id="modalTad" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{lblnomtad}}
                </h5>

                <button type="button" (click)="resetForm()" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="">
                    <div class="">

                        <form class="mt-lg-3">
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Structure <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <select (change)="valideInput()" [ngClass]="{'error':errorSelectcol==true}"
                                        [(ngModel)]="selectedcol" name="selectedcollectivite" class="form-control"
                                        id="strus">
                                        <option value="">&nbsp;</option>
                                        <option *ngFor="let col of collectivites;" value="{{col.id}}">{{col.nom}}
                                        </option>
                                    </select>
                                    <span *ngIf="errorSelectcol==true"
                                        style="color: rgb(168, 54, 45);font-size: small;">
                                        veillez selectionner une collectivitÃ©
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Nom <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" (change)="valideInput()" [ngClass]="{'error':errorNom==true}"
                                        [(ngModel)]="nom" name="nom" class="form-control col-sm-8 col-md-6">
                                    <span *ngIf="errorNom==true" style="color: rgb(168, 54, 45);font-size: small;">
                                        {{infoErrorNom}}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Adresse
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input class="form-control" placeholder="Adresse" id="adresse"
                                        [(ngModel)]="adresse" name="adresse" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Ville
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input readonly type="text" id="ville" [(ngModel)]="ville" name="ville"
                                        class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Code Postal
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input readonly type="text" id="codePostal" name="codePostal"
                                        [(ngModel)]="codePostal" class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Email<label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input (change)="valideEmail()" [ngClass]="{'error':errorEmail==true}" type="email"
                                        [(ngModel)]="email" name="email" class="form-control col-sm-8 col-md-6">
                                    <span *ngIf="errorEmail==true" style="color: rgb(168, 54, 45);font-size: small;">
                                        {{infoErrorEmail}}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Téléphone fixe <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" (change)="valideTelFixe()" [ngClass]="{'error':errorTelFixe==true}"
                                        [(ngModel)]="telephone" name="telephone" class="form-control col-sm-8 col-md-6">
                                    <span *ngIf="errorTelFixe==true" style="color: rgb(168, 54, 45);font-size: small;">
                                        {{infoErrorTelFixe}}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Téléphone portable <label style="color:red">
                                            *
                                        </label>
                                    </label>
                                </div> 

                                <div class="col-sm-9">
                                    <input type="text" (change)="valideTel()" [ngClass]="{'error':errorTel==true}"
                                        [(ngModel)]="telephonePortable" name="telephonePortable" class="form-control col-sm-8 col-md-6">
                                      <span *ngIf="errorTel==true" style="color: rgb(168, 54, 45);font-size: small;">
                                        {{infoErrorTel}}
                                    </span> 
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                    <label class="mb-0">
                                        Responsable
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <input type="text" [(ngModel)]="responsable" name="responsable"
                                        class="form-control col-sm-8 col-md-6">
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-3 col-form-label
                                        text-sm-right pr-0">
                                    <label class="mb-0">
                                        Information complémentaire
                                    </label>
                                </div>

                                <div class="col-sm-9">
                                    <textarea class="form-control" [(ngModel)]="information" name="information"
                                        placeholder="" style="overflow: hidden;
                                            overflow-wrap: break-word; resize:
                                            horizontal; height: 62px;"></textarea>
                                </div>
                            </div>


                        </form>


                    </div>
                    <!-- /.card-body -->
                </div>


            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="resetForm()" data-dismiss="modal">
                    Fermer
                </button>
                <button type="button" class="btn btn-primary" (click)="save()">
                    Enregistrer
                </button>

            </div>
        </div>
    </div>
</div>