import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FoyerService } from 'src/app/services/foyer.service';
import { MotifService } from 'src/app/services/motif.service';
import { ActeurService } from 'src/app/services/acteur.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { StructureService } from 'src/app/services/structure.service';
import { StructureMotifService } from 'src/app/services/structuremotif.service ';
import { FrmmotifsComponent } from '../frmmotifs/frmmotifs.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { FrmmotifcollectiviteComponent } from '../detailscollectivite/frmmotifcollectivite/frmmotifcollectivite.component';
import { PaginationService } from 'src/app/services/pagination.service';
import { SecurityService } from 'src/app/services/security.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

import Swal from 'sweetalert2';
@Component({
  selector: 'app-lstmotifscollectivite',
  templateUrl: './lstmotifscollectivite.component.html',
  styleUrls: ['./lstmotifscollectivite.component.css']
})
export class LstmotifscollectiviteComponent implements OnInit {

  //@ViewChild ('frmmotifsComponent') frmmotifsComponent: FrmmotifsComponent ;
  @ViewChild('frmmotifsComponent') frmmotifsComponent: FrmmotifcollectiviteComponent;
  constructor(private http: HttpClient, private foyerService: FoyerService,
    private motifService: MotifService, private structuremotifService: StructureMotifService, private acteurService: ActeurService,
    private structureService: StructureService,
    private authenticationService: AuthenticationService, private paginationService: PaginationService
    , private securiteService: SecurityService,private spinner: NgxSpinnerService
  ) { }

  collectivites: any = [];
  collectivite: any;
  motifs: any = [];
  code: any = '';
  libelle: any = '';
  description: any = '';
  motif: any = null;
  motifcollectivie: any = null;
  user: User = new User();
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canDetails = false;
  idcollectivite = 0;
  //  collectivites: any = [];
  collectivte = null;
  libellemotofs = null;

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.showMotifCollectivite();
    this.showCollectivite();
    this.getDroit();
  }

  filter(parent: any, structure: any) {
    this.showMotifCollectivite();
  }
  openFormMotif() {
    // alert('ok'); 
    if (this.user.structure != 0) {
      this.structureService.get(this.user.structure).subscribe(data => {
        this.frmmotifsComponent.collectivite = data;
      });
      this.frmmotifsComponent.selectedstructure = this.user.structure;
    }
    this.frmmotifsComponent.show(this);
  }

  edit(id: any) {
    this.frmmotifsComponent.editMotif(this, id);
  }

  showMotifCollectivite(): void {
    this.spinner.show();
    if (this.user.structure == 0) {
      this.foyerService.getDataMot().subscribe(data => {
        this.motifs = data;
        this.spinner.hide();
      });
    } else if (this.user.profilCode == "PF_GFOYER" || this.user.profilCode == "PF_PRESTAIRE") {
      //alert()
      this.structuremotifService.getMotifRattachesFoyerPrestataire(this.user.structure).subscribe(data => {
        this.motifs = data;
        this.spinner.hide();
      });
    } else if (this.user.profilCode == "PF_TAD") {
      this.structuremotifService.getByCollectivite(this.user.parent).subscribe(data => {
        this.motifs = data;
        this.spinner.hide();
      });
    } else {
      this.structuremotifService.getByCollectivite(this.user.structure).subscribe(data => {
        this.motifs = data;
        this.spinner.hide();
      });
    }


  }

  deleteMotif(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.structuremotifService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.showMotifCollectivite();
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet élément a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.structuremotifService.delete(id).subscribe(data => {
    //     this.showMotifCollectivite();
    //   });
    // }
  }

  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.motifs = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }

  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }

  getDroit(): void {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_MOTIFCOLLEC").subscribe(data => {
      console.log(this.motifs)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_MOTIFCOLLEC").subscribe(data => {
      console.log(this.motifs)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });

  }
  showCollectivite(): void {
    this.structureService.getCollectiviteByProfil(this.user.id).subscribe(data => {
      this.collectivites = data;
    });

  }
  RechercheAvanceCollMotif() {
    this.spinner.show();
    $(".collapse").collapse('hide');

    if (this.idcollectivite == null)
      this.idcollectivite = 0;
    this.libellemotofs = this.libellemotofs ? this.libellemotofs : null;

    this.structuremotifService.getRecherche(this.idcollectivite, this.libellemotofs, this.user.structure).subscribe(data => {
      this.motifs = data;
      this.spinner.hide();
    });
    this.tabSize(event);

  }
  				
  reset(){
    this.idcollectivite = 0;
   }

   reset1(){
    this.libelle = null;
   }
}
