import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';
import {TypeStructureModel} from '../shared/models/type-structure-model.model';
import { EntrepriseModel } from '../shared/models/entreprise-model';
import {Observable} from "rxjs";
import {FoyerModel} from "../shared/models/foyer-model.model";

@Injectable({
  providedIn: 'root'
})
export class StructureService {

  constructor(private http: HttpClient) { }

  getStructuresByType(typestructure: TypeStructureModel): Observable<FoyerModel[]> {
    return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/list/'+ typestructure);
  }



  getData() {
     return this.http.get(AppModule.API_ENDPOINT + 'structure/list');
  }

  get(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/get/' + id);
  }
  getCollectivite(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/getStructureById/' + id);
  }
  getTadByProfil(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/tadbyprofil/' + id);
  }
  getPrestatairesByProfil(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/prestatairebyprofil/' + id);
  }
  getFoyerByProfil(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/foyerbyprofil/' + id);
  }

  getCollectiviteByProfil(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/collectovotebyprofil/' + id);
  }

  save(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'structure/create', body, { headers });
  }


  update(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + 'structure/update/' + id, body, { headers });
  }


  delete(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + 'structure/delete/' + id);
  }
  getOrganisationsAdmin() {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/admin');
  }
  getOrganisations() {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/organisation');
  }
  getPrestataires() {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/prestataire');
  }

  getFoyers() {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/foyer');
  }
  getContacts() {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/contact');
  }
  getOrganisationStructure() {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/organisationStructure');
  }
  getOrganisationsrattachees(parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/organisation'+ '/' + parent);
  }

  getStructureRattacheeCollectivite(parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/rattacheescollectivite/' + parent);
  }
  getStructureRattachee(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/rattachees/' + type + '/' + parent);
  }
  getStructureNonRattachee(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/nonrattachees/' + type + '/' + parent);
  }

  getCollectivitesRattachee(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/collectivitesrattachees/' + type + '/' + parent);
  }

  getCollectivitesTad(type: any, tad: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/collectivitestad/' + type + '/' + tad);
  }

  getTads(type: any, personne: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/tadsrattachees/' + type + '/' + personne);
  }

  getRecherche(nom: any, siret: any, email: any, adresse: any, telephone: any, responsable: any, typestructure: TypeStructureModel): Observable<FoyerModel[]> {
    return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/recherchestructure/' + nom + '/' + siret + '/' + email + '/' + adresse + '/' + telephone + '/' + responsable + '/' + typestructure);
  }


  getStructureRattacheetad(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/rattacheesTad/' + type + '/' + parent);
  }

  getStructureRattacheeFoyerPrestataire(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/rattacheesfoyerprestataire/' + type + '/' + parent);
  }

  getTadRattacheeFoyerPrestataire(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/tadRattacheesfoyerprestataire/' + type + '/' + parent);
  }

  getFoyerPrestataire(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/foyerprestataire/' + type + '/' + parent);
  }

  getTadByCollectivite(structureId): Observable<FoyerModel[]> {
    return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/liststrucutre/' + structureId);
  }

  getStructureNonRattacheeTad(type: any, parent: any, tad: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/nonrattacheesTad/' + type + '/' + parent + '/' + tad);
  }

  getStructureRattacheetadFA(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/rattacheesTadFA/' + type + '/' + parent);
  }

  getFoyerPrestataireFA(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/foyerprestataireFA/' + type + '/' + parent);
  }

  getStructureRattacheeFA(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/rattacheesFA/' + type + '/' + parent);
  }

  getStructureRattacheeFoyerPrestataireFA(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/rattacheesfoyerprestataireFA/' + type + '/' + parent);
  }

  getStructureNonRattacheeFA(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/nonrattacheesFA/' + type + '/' + parent);
  }
  getRechercheBIS(nom: any, siret: any, email: any, adresse: any, telephone: any, responsable: any, typestructure: TypeStructureModel,id:any): Observable<FoyerModel[]> {
    return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/recherchestructurebis/' + nom + '/' + siret + '/' + email + '/' + adresse + '/' + telephone + '/' + responsable + '/' + typestructure+'/'+id);
  }

  getRechercheTad(nom: any, siret: any, email: any, adresse: any, telephone: any, responsable: any, typestructure: TypeStructureModel,id:any): Observable<FoyerModel[]> {
    return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/recherchestructuretad/' + nom + '/' + siret + '/' + email + '/' + adresse + '/' + telephone + '/' + responsable + '/' + typestructure+'/'+id);
  }

  getRecherchePrestataire(nom: any, siret: any, email: any, adresse: any, telephone: any, responsable: any, typestructure: TypeStructureModel,id:any): Observable<FoyerModel[]> {
    return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/recherchestructureprestataire/' + nom + '/' + siret + '/' + email + '/' + adresse + '/' + telephone + '/' + responsable + '/' + typestructure+'/'+id);
  }

  getRechercheCollectivite(nom: any, siret: any, email: any, adresse: any, telephone: any, responsable: any, typestructure: TypeStructureModel,id:any): Observable<FoyerModel[]> {
    return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/recherchestructurecollectivite/' + nom + '/' + siret + '/' + email + '/' + adresse + '/' + telephone + '/' + responsable + '/' + typestructure+'/'+id);
  }

  getStructureRattacheeBis(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/rattacheespresta/' + type + '/' + parent);
  }

  getRechercheTadBIS(idcollectivite:any,nom: any, siret: any, email: any, adresse: any, telephone: any, responsable: any, typestructure: TypeStructureModel,id:any): Observable<FoyerModel[]> {
    return this.http.get<FoyerModel[]>(AppModule.API_ENDPOINT + 'structure/recherchestructuretadbis/'+ idcollectivite + '/' + nom + '/' + siret + '/' + email + '/' + adresse + '/' + telephone + '/' + responsable + '/' + typestructure+'/'+id);
  }

  searchFromSiren(siren: any) {
    return this.http.get<EntrepriseModel>(AppModule.API_ENDPOINT + 'prestataire/siren/' + siren);
  }

  getBySiret(siret: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'structure/listbysiret/'+siret);
  }

  getBySiretStructure(siret: any,structure: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'collectivtestructure/listbysiret/'+siret+'/'+structure);
  }
 
  saveStruc(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'structure/createstruc', body, { headers });
  }
  saveTad(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'structure/createtad', body, { headers });
  }
}
