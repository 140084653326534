<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Réservations
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Nouvelle réservation
      </small>
    </h1>

    <button class="btn btn-grey px-3  text-75 radius-round border-2" routerLink="/reservations" type="button">
      <i class="fa fa-hand-point-left mr-1"></i>
      Revenir <span class="d-sm-none d-md-inline"></span> à la liste des réservations
    </button>

  </div>


  <div class="px-2">


    <div class="mx-n3 mx-sm-auto sw-main sw-theme-circles" id="smartwizard-1">
      <ul class="mx-auto nav nav-tabs step-anchor">
        <li class="wizard-progressbar nav-item"></li><!-- the progress line connecting wizard steps -->

        <li class="nav-item active">
          <a class="nav-link" href="#step-1">
                            <span class="step-title">
                                1
                            </span>

            <span class="step-title-done">
                                <i class="fa fa-check text-success"></i>
                            </span>
          </a>

          <span class="step-description">
                            Trajet
                        </span>
        </li>


        <li class="nav-item">
          <a class="nav-link" href="#step-2">
                            <span class="step-title">
                                2
                            </span>

            <span class="step-title-done">
                                <i class="fa fa-check text-success"></i>
                            </span>
          </a>

          <span class="step-description">
                            Détails
                        </span>
        </li>


        <li class="nav-item">
          <a class="nav-link" href="#step-3">
                            <span class="step-title">
                                3
                            </span>

            <span class="step-title-done">
                                <i class="fa fa-check text-success"></i>
                            </span>
          </a>

          <span class="step-description">
                            Passagers
                        </span>
        </li>


        <li class="nav-item">
          <a class="nav-link" href="#step-4">
                            <span class="step-title">
                                4
                            </span>

            <span class="step-title-done">
                                <i class="fa fa-check text-success"></i>
                            </span>
          </a>

          <span *ngIf="this.user?.profilCode != 'PF_GFOYER'" class="step-description">
                            Transporteur
                        </span>
          <span *ngIf="this.user?.profilCode == 'PF_GFOYER'" class="step-description">
                            Terminer
                        </span>
        </li>
      </ul>


      <div class="px-2 py-2 mb-4 sw-container tab-content" style="min-height: 0px;">
        <div class="tab-pane step-content" id="step-1" style="display: block;">
          <div class="form-group row mt-2 hide">
            <div class="col-sm-3 col-form-label text-sm-right pr-0">
              Tad/service
            </div>

            <div class="col-sm-9 pr-0 pr-sm-3 col-md-4">
              <select (change)="openSelect()" [(ngModel)]="tad" class="form-control" name="tad">
                <option *ngFor="let tad of tads" [value]="tad.id">{{tad.nom}}</option>

              </select>
            </div>
          </div>

          <form class="mt-4 text-dark-m1" id="validation-form" novalidate="novalidate">


            <div style="display: none;">
              <div class="col-sm-9">
                <input (change)="valideInput()" [(ngModel)]="adresseDepart"
                       [ngClass]="{'error':erroradressedepart==true}"
                       class="controls"
                       id="origin-input"
                       name="adresseDepart" placeholder="Adresse départ" required type="text"
                />

                <span *ngIf="erroradressedepart==true" style="color: rgb(168, 54,
              45);font-size: small;">
              Veuillez saisir l'adresse de départ.
          </span>

              </div>

              <input
                [(ngModel)]="adresseArrivee"
                class="controls"
                id="destination-input"
                name="adresseArrivee" placeholder="Adresse arrivée" type="text"
              />

              <div id="mode-selector" style="display: none">
                <input
                  id="changemode-walking"
                  name="type"
                  type="radio"

                />
                <label for="changemode-walking">Walking</label>

                <input id="changemode-transit" name="type" type="radio"/>
                <label for="changemode-transit">Transit</label>

                <input checked="checked" id="changemode-driving" name="type" type="radio"/>
                <label for="changemode-driving">Driving</label>
              </div>
            </div>

            <div id="map"></div>


          </form>
        </div>


        <div class="tab-pane step-content" id="step-2">

          <div class="form-group row mt-2">
            <div class="col-sm-3 col-form-label text-sm-right pr-0">
              Distance:
            </div>

            <div class="col-sm-9 pr-0 pr-sm-3 col-md-5 h-50">

              <input id="id-distance" name="slider-distance" type="text" value=""/>
              <span id="label-distance" style="display: none"></span>

              <input [(ngModel)]="distance" id="distance" name="distance" style="visibility: hidden;" type="number"/>

            </div>
          </div>

          <!-- <div class="form-group row mt-2 ">
            <div class="col-sm-3 col-form-label text-sm-right pr-0 hide">
              Durée estimée :
            </div>

            <div class="col-sm-9 pr-0 pr-sm-3 col-md-3 text-center pos-rel hide">

              <input id="id-duree" type="text"/>
              <span id="label-duree"></span>

            </div>

            <input [(ngModel)]="estimatedDuration" id="estimatedDuration" name="estimatedDuration"
                   style="visibility: hidden;"
                   type="number"/>


          </div>-->

          <div class="form-group row mt-2">
            <div class="col-sm-3 col-form-label text-sm-right pr-0">
              <span class="required">Date et heure</span>
            </div>

            <div class="col-sm-9 pr-0 pr-sm-3 col-md-6">

              <div class="input-group date">
                <input [(ngModel)]="reservationDate" class="form-control" id="reservationDate" name="reservationDate"
                       type="text" />
                <div class="input-group-addon input-group-append">
                  <div class="input-group-text">
                    <i class="far fa-clock"></i>
                  </div>
                </div>
                <label class="ml-1 text-orange-d3 text-600">
                  <span class="text-info">Départ </span> <input  [checked]="flagHeureReservation == 'ARRIVEE'"
                  (change)="setFlagHeure($event)" class="ace-switch ace-switch-thin text-grey-l1 bgc-warning-d2 mr-1"
                  type="checkbox">
                  <span> Arrivée</span>
                </label>
              </div>


            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-sm-3 col-form-label text-sm-right pr-0">
              <span class="required">Motif transport</span>
            </div>

            <div class="col-sm-9 pr-0 pr-sm-3 col-md-4">
              <select [(ngModel)]="motifTransport" class="form-control" id="motifTransport" name="motifTransport"
              style=" height: 100%;width:150%">
                <option value="">&nbsp;- Sélectionnez un motif -</option>
                <option *ngFor="let motif of motifs" [value]="motif.id">{{motif.libelle}}</option>

              </select>
            </div>
          </div>

          <div class="form-group row mt-2 note">
            <div class="col-sm-3 col-form-label text-sm-right pr-0">
              Note pour le chauffeur
            </div>

            <div class="col-sm-9 pr-0 pr-sm-3 col-md-4">
              <textarea [(ngModel)]="noteChauffeur" class="form-control" name="noteChauffeur"
                        placeholder="Des précautions particulières à respecter pour le transport du(des) passager(s)? Notez ici toutes les informations utiles au chauffeur pour lui permettre la bonne exécution de la course"
                        style="overflow: hidden; overflow-wrap: break-word; resize: horizontal; height: 150%;width:150%"></textarea>

            </div>
          </div>

        </div>


        <div class="text-center tab-pane step-content" id="step-3">


          <div class="row mb-3 px-2 px-sm-0 my-4">

            <div class="col-md-6">
              <h3 class="font-light text-left text-primary">
                <span class="required">Liste des passagers</span>
              </h3>
            </div>


            <div class="mb-2 mb-sm-0 col-md-6">
              <button (click)="openFormPassager()"
                      class="btn btn-blue px-3 d-inline-block  text-95 radius-round border-2 brc-black-tp10"
                      type="button">
                <i class="fa fa-plus mr-1"></i>
                Ajouter <span class="d-sm-none d-md-inline"></span>
              </button>
              <button (click)="createCircuit()" [class.disabled]="!canHaveCircuit"
                      class="btn btn-orange ml-2 px-3   text-95 radius-round border-2 brc-black-tp10"
                      type="button">
                <i class="fa fa-road mr-1"></i>
                Tracer le circuit <span class="d-sm-none d-md-inline"></span>
              </button> 
              <input id="psgTotal" type="hidden" value="{{passagers.length}}"/>
              <input id="psgCorrect" type="hidden" value="{{bon}}"/>
            </div>
          </div>

          <table
            class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
            <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
            <tr>
              <th class="text-center pr-0">

              </th>

              <th>
                N°
              </th>

              <th>
                Prénom
              </th>

              <th class="d-none d-sm-table-cell">
                Nom
              </th>

              <th class="d-none d-sm-table-cell">
                Age
              </th>
              <th class="d-none d-sm-table-cell">
                TAD
              </th>
              <th>Foyer</th>

              <th class="d-none d-sm-table-cell">
                Contact Départ
              </th>

              <th class="d-none d-sm-table-cell">
                Contact Arrivée
              </th>
              
              <th></th>

            </tr>
            </thead>

            <tbody class="mt-1">
            <ng-container *ngFor="let passager of passagers; let i = index;">
              <tr class="bgc-h-yellow-l4 d-style">
                <td class="text-center pr-0 pos-rel">
                  <span [class.is-valid-adresseDepart]="passager.adresseDepart === adresseDepart"></span>
                  <span [class.is-valid-adresseArrivee]="passager.adresseArrivee === adresseArrivee"></span>
                  <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                    <!-- border shown on hover -->
                  </div>
                  <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                    <!-- border shown when row is selected -->
                  </div>


                </td>

                <td>
                  <a class="text-blue-d1 text-600 text-95" href="#">{{i + 1}}</a>
                </td>

                <td class="text-600 text-info-d2">
                  {{passager.acteur.personne.prenom}}
                </td>

                <td class="d-none d-sm-table-cell text-info-d2">
                  {{passager.acteur.personne.nom}}
                </td>

                <td class="d-none d-sm-table-cell text-grey text-95">
                  {{getAge(passager.acteur.personne.datenaissance)}}
                </td>

                <td class="d-none d-sm-table-cell text-orange-d2 text-95">
                  {{passager.acteur.structure.nom}}
                </td>
                <td>
                  <!--   --> <input  disabled type="text" [value]="tabFoyer[passager.id]?.nom" > 
                <!--     <span>{{tabFoyer[passager.id]?.nom}}</span> -->
                  
                </td>

                <td class="d-none d-sm-table-cell text-grey text-95 mouse-pointer">
                  <span *ngIf="passager?.contacts?.length > 0 ">
                    <ng-container *ngFor="let contact of passager.contacts">
                    <span (click)="addContact(passager, 'DEPART')" *ngIf="contact.type == 'DEPART'"
                          class="text-grey"> {{contact.personne.prenom}} {{contact.personne.nom}} </span>
                    </ng-container>
                </span>

                  <span (click)="addContact(passager, 'DEPART')" *ngIf="!checkContact('DEPART', passager.contacts)"
                        class="text-danger require-psg"><i class="fa fa-user"></i> ... </span>

                </td>

                <td class="d-none d-sm-table-cell text-grey text-95 mouse-pointer">
                  <span *ngIf="passager?.contacts?.length > 0 ">
                    <ng-container *ngFor="let contact of passager.contacts">
                    <span (click)="addContact(passager, 'ARRIVEE')" *ngIf="contact.type == 'ARRIVEE'"
                          class="text-grey"> {{contact.personne.prenom}} {{contact.personne.nom}} </span>
                    </ng-container>
                </span>

                  <span (click)="addContact(passager, 'ARRIVEE')" *ngIf="!checkContact('ARRIVEE', passager.contacts)"
                        class="text-danger require-psg"><i class="fa fa-user"></i> ... </span>

                </td>
              

                <td class="text-center pr-0">
                  <div>
                    <a class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                       [attr.data-target]="'#details-passager-'+passager.id" data-toggle="collapse" href="#"
                       title="Details">
                                  <span class="d-none d-md-inline mr-1">
											Details
										</span>
                      <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                    </a>
                    <a href="#"  (click)="delete(passager.id)"
                    class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                    <i class="fa fa-trash-alt"></i>
                </a>
                  </div>
                </td>


              </tr>


              <tr class="border-0 detail-row bgc-white">
                <td class="p-0 border-none brc-secondary-l2" colspan="9">
                  <div [id]="'details-passager-'+passager.id" class="table-detail collapse">
                    <div class="row">
                      <div class="col-12 row col-md-12  p-4">

                        <div class="col-md-6">
                          <div class="card ccard radius-t-0 h-100">
                            <div class="position-tl w-102 border-t-3 brc-primary-tp3 ml-n1px mt-n1px"></div>
                            <!-- the blue line on top -->

                            <div class="card-header pb-3 brc-secondary-l3">
                              <h5 class="card-title mb-2 mb-md-0 text-dark-m3">
                                Départ
                              </h5>


                            </div>


                            <div class="card-body pt-2 pb-1">

                              <table class="table table table-striped-default table-borderless">
                                <tbody>
                                <tr>
                                  <td>
                                    <i class="fa fa-map-marker-alt text-warning"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Adresse
                                  </td>

                                  <td class="text-dark-m3">
                                    {{passager.adresseDepart}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i class="fa fa-clock text-warning"></i>
                                  </td>
                                  <td class="text-95 text-600 text-secondary-d2">
                                    Heure de prise en charge
                                  </td>

                                  <td class="text-dark-m3">
                                    {{passager.heurePriseEncharge}}
                                  </td>

                                </tr>

                                <ng-container *ngIf="passager?.contacts?.length > 0 ">

                                  <ng-container *ngFor="let contact of passager?.contacts">


                                    <ng-container *ngIf="contact.type == 'DEPART'">

                                      <tr>
                                        <td>
                                          <i class="far fa-user text-success"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Contact
                                        </td>

                                        <td class="text-dark-m3">
                                          {{contact.personne.prenom + ' ' + contact.personne.nom}}
                                        </td>
                                      </tr>


                                      <tr>
                                        <td>
                                          <i class="fa fa-phone text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Téléphone 1
                                        </td>

                                        <td class="text-dark-m3">
                                          {{contact.personne.telephone1}}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <i class="fa fa-mobile text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Téléphone 2
                                        </td>

                                        <td class="text-dark-m3">
                                          {{contact.personne.telephone2}}
                                        </td>
                                      </tr>


                                    </ng-container>


                                  </ng-container>


                                </ng-container>


                                </tbody>
                              </table>

                            </div>
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div class="card ccard radius-t-0 h-100">
                            <div class="position-tl w-102 border-t-3 brc-warning-d1 ml-n1px mt-n1px"></div>
                            <!-- the blue line on top -->

                            <div class="card-header pb-3 brc-secondary-l3">
                              <h5 class="card-title mb-2 mb-md-0 text-dark-m3">
                                Arrivée
                              </h5>


                            </div>

                            <div class="card-body pt-2 pb-1">

                              <table class="table table table-striped-default table-borderless">
                                <tbody>

                                <tr>
                                  <td>
                                    <i class="fa fa-map-marker-alt text-info"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Adresse
                                  </td>

                                  <td class="text-dark-m3">
                                    {{passager.adresseArrivee}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i class="fa fa-clock text-warning"></i>
                                  </td>
                                  <td class="text-95 text-600 text-secondary-d2">
                                    Heure de depose
                                  </td>

                                  <td class="text-dark-m3">
                                    {{passager.heureDepose}}
                                  </td>

                                </tr>


                                <ng-container *ngIf="passager?.contacts?.length > 0 ">

                                  <ng-container *ngFor="let contact of passager.contacts">


                                    <ng-container *ngIf="contact.type == 'ARRIVEE'">

                                      <tr>
                                        <td>
                                          <i class="far fa-user text-success"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Contact
                                        </td>

                                        <td class="text-dark-m3">
                                          {{contact.personne.prenom + ' ' + contact.personne.nom}}
                                        </td>
                                      </tr>


                                      <tr>
                                        <td>
                                          <i class="fa fa-phone text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Téléphone 1
                                        </td>

                                        <td class="text-dark-m3">
                                          {{contact.personne.telephone1}}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <i class="fa fa-mobile text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Téléphone 2
                                        </td>

                                        <td class="text-dark-m3">
                                          {{contact.personne.telephone2}}
                                        </td>
                                      </tr>


                                    </ng-container>


                                  </ng-container>


                                </ng-container>


                                </tbody>
                              </table>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </td>
              </tr>


            </ng-container>

            </tbody>
          </table>


        </div>


        <div class="text-center tab-pane step-content" id="step-4">


          <div class="mb-2 mb-sm-0">


          </div>

          <div *ngIf="this.user?.profilCode != 'PF_GFOYER'" class="row text-95">
            <div class="col-12 col-lg-12">
              <div class="row">
                <table class="table brc-black-tp11">
                  <thead class="border-0">
                  <tr class="border-0 bgc-dark-l5 text-dark-tp5">
                    <th class="border-0 pl-4">
                      Prestataire
                    </th>
                    <th class="border-0">
                      Template Prix
                    </th>
                    <th class="border-0">
                      Prix Estimé HT
                    </th>
                    <th class="border-0">
                      Etat
                    </th>
                  </tr>
                  </thead>

                  <tbody>
                  <ng-container *ngIf="!!templates && templates.length > 0">

                    <tr *ngFor="let template of templates;" class="bgc-h-secondary-l4">
                      <td class="text-dark-tp3 opacity-1 text-95 text-600 pl-4">
                        {{(!!template.prestataire) ? template.prestataire.nom : ''}}
                      </td>
                      <td>
                        <span (click)="viewDetailsTemplate(template)"
                              class="text-blue-d1 opacity-1 text-95 text-600 pl-4 mouse-pointer">
                           {{template.libelle }}
                          </span>
                      </td>

                      <td>
                        <span class="text-success-m1 font-bolder text-95">
                        {{this.prices[template.id] | number: '1.0-2':'fr-FR'}} €
                          <!--{{getPrice(template) | number: '1.0-2':'fr-FR'}} €-->
        						    </span>
                      </td>

                      <td>
                      <span (click)="changeTranspoteur(template)"
                            *ngIf="!this.templatePrix || (!!this.templatePrix && template.id != this.templatePrix.id)"
                            class="badge text-75 border-l-3 brc-black-tp8 bgc-info-d2 mouse-pointer text-white">Sélectionner</span>
                        <span *ngIf="!!this.templatePrix && template.id == this.templatePrix.id"
                              class="badge text-75 border-l-3 brc-black-tp8 bgc-success text-white template-selected">Sélectionné</span>
                      </td>
                    </tr>

                  </ng-container>

                  <ng-container *ngIf="!templates ||  (!!templates && templates.length == 0)">
                    <tr>
                      <td colspan="3">
                        <div class="alert d-flex bgc-red-l3 brc-success-m4 border-0 p-0" role="alert">
                          <div class="bgc-red px-3 py-1 text-center radius-l-1">
                            <span class="fa-2x text-white">              </span>
                          </div>

                          <span class="ml-3 align-self-center text-dark-tp3 text-110">
                                        Aucun template de prix disponible pour cette réservation !
                           </span>
                        </div>

                      </td>
                    </tr>
                  </ng-container>


                  </tbody>
                </table>


              </div><!-- .row -->
            </div><!-- .col -->
          </div>

          <div *ngIf="this.user?.profilCode == 'PF_GFOYER'" class="row temp-notrequired">

            <div class="m-auto  alert d-flex bgc-success-l3 brc-success-m4 border-0 p-0" role="alert">
              <div class="bgc-green px-3 py-1 text-center radius-l-1">
                <span class="fa-2x text-white">              </span>
              </div>

              <span class="ml-3 align-self-center text-dark-tp3 text-110">
                                        Cliquez sur Terminer pour enregistrer la réservation !
                           </span>
            </div>

          </div>

        </div>
      </div>
    </div>


  </div>


</div>

<button (click)="goNext()" class="d-none" id="btnNext"></button>
<button (click)="finish()" class="d-none" id="btnFin"></button>


<app-frmpassager #frmpassagerComponent></app-frmpassager>
<app-frmcontactpassager #frmcontactpassagerComponent></app-frmcontactpassager>
<app-detailstemplateprix #detailstemplateprix></app-detailstemplateprix>
<app-frm-circuit #frmCricuit></app-frm-circuit>

<!-- <app-frminformationchauffeur #frminformationchauffeurComponent></app-frminformationchauffeur> -->


<!--app-frmresatemplate #frmresatemplateComponent></app-frmresatemplate-->

<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p
  style="color: #4AA0DF"> Chargement en cours... </p></ngx-spinner>

