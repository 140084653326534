import { Component, OnInit } from '@angular/core';
import {ReservationService} from "../../../services/reservation.service";
import {Router} from "@angular/router";
import {FeriesComponent} from "../feries.component";
import {NgxSpinnerService} from "ngx-spinner";

declare var $: any;
@Component({
  selector: 'app-form-ferie',
  templateUrl: './form-ferie.component.html',
  styleUrls: ['./form-ferie.component.css']
})
export class FormFerieComponent implements OnInit {
  libelle: any;
  dateFerie: any;
  parent: FeriesComponent;
  ferie: any;

  constructor(private reservationService: ReservationService, private router: Router, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
  }

  show(dateFerie: any, parent: any) {
    this.ferie = null;
    this.dateFerie = dateFerie;
    this.parent = parent;
    if(!!this.dateFerie) {
      this.reservationService.getFerie(dateFerie).subscribe(ferie => {
          if(!!ferie) {
            this.ferie = ferie;
            this.dateFerie = this.ferie.ferieDate;
            this.libelle = this.ferie.libelle;
          }
      } );
    }
    $('#modalFormFerie').modal('show');
  }

  hide() {
     this.libelle = null;
     this.dateFerie = null;
    $('#modalFormFerie').modal('hide');


  }

  save() {

    this.spinner.show();

    const body = { libelle:  this.libelle, ferieDate: this.dateFerie }

    if(!!this.ferie) {
      body['id'] = this.ferie.id;
    }

    this.reservationService.addFerie(body).subscribe(ferie => {
        if(!!ferie) {
          this.hide();
          this.spinner.hide();
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['feries']);
          });
        }
    });

  }

  delete() {

    this.spinner.show();
    this.reservationService.deleteFerie(this.ferie.id).subscribe(value => {
      this.hide();
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['feries']);
      });
    });


  }
}
