<div #modalNote aria-hidden="true" class="modal fade modal-ds" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Note
        </h5>

        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="">

          <div class="card-body px-0 py-2">

            <form class="mt-lg-3">

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                   Type de note / incident<label style="color:red">
                    *
                </label>
                  </label>
                </div>

                <div class="col-sm-9">
                  <select [(ngModel)]="typeNote" class="form-control" name="typeNote">
                    <option *ngFor="let incident of typeNotes;" value="{{incident.id}}">{{incident.libelle}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Note <label style="color:red">
                      *
                  </label>
                  </label>
                </div>

                <div class="col-sm-9">
                  <textarea [(ngModel)]="note" class="form-control" name="note" placeholder="" rows="4"
                  style="overflow: hidden; overflow-wrap: break-word; resize: horizontal;"
                  (change)="valideInput()" [ngClass]="{'error':errornote==true}" required></textarea>
                  <span *ngIf="errornote==true" style="color: rgb(168, 54,
                  45);font-size: small;">
              Champs obligatoire.
          </span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Fichier
                  </label>
                </div>
    <div class="col-sm-9">
      <label class="btn btn-default p-0">
        <input multiple (change)="selectFiles($event)" accept=".pdf,.docx,.doc,.xlsx" type="file"/>
      </label>
    </div>
  
    
  </div>
  <div *ngFor="let progressInfo of progressInfos" class="mb-2">
    <span>{{ progressInfo.fileName }}</span>
    <div class="progress">
      <div
        class="progress-bar progress-bar-info progress-bar-striped"
        role="progressbar"
        attr.aria-valuenow="{{ progressInfo.value }}"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{ width: progressInfo.value + '%' }">
        {{ progressInfo.value }}%
      </div>
    </div>
  </div>


            </form>

          </div>
        </div>


        <div class="px-3 text-grey-m1 text-95">
          <table id="simple-table"
          class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
     <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
     <tr>
       <th class="text-center pr-0">
        Créée par
       </th>


       <th class="d-none d-sm-table-cell">
         Note
       </th>

       <th class="d-none d-sm-table-cell">
         Type de note
       </th>

       <th>
        Date/Heure
       </th>

       <th>
         Fichier
       </th>
       <th>
      </th>
     </tr>
     </thead>

     <tbody class="mt-1">
     <tr *ngFor="let nt of notes " class="bgc-h-yellow-l4 d-style">
       <td class="text-85 text-blue-d1">
        {{nt.user.acteur.personne.prenom + ' ' + nt.user.acteur.personne.nom}}
       </td>

       <td class="d-none d-sm-table-cell text-grey-d1">
        {{nt.note}}
       </td>

       <td class="d-none d-sm-table-cell text-grey-d1">
         {{nt.typeNote?.libelle}}
       </td>

       <td class="d-none d-sm-table-cell text-grey text-95">
         {{nt.modifiedDate | date:'dd-MM-yyyy HH:mm'}}
       </td>
       <td *ngIf="nt.url?.endsWith('.xlsx')" class="d-none d-sm-table-cell text-grey-d1">
        <!-- {{nt.name}} -->
        <a href="{{nt.url}}"><i class="fa fa-file-excel" style="color: rgb(21, 146, 9);" title="Visualiser"></i></a>
      </td>
      <td *ngIf="nt.url?.endsWith('.xls')" class="d-none d-sm-table-cell text-grey-d1">
        <!-- {{nt.name}} -->
        <a href="{{nt.url}}"><i class="fa fa-file-excel" style="color: rgb(21, 146, 9);" title="Visualiser"></i></a>
      </td>
      <td *ngIf="nt.url?.endsWith('.docx')" class="d-none d-sm-table-cell text-grey-d1">
        <!-- {{nt.name}} -->
        <a href="{{nt.url}}"><i class="fa fa-file-word" style="color: rgb(25, 72, 228);" title="Visualiser"></i></a>
      </td>
      <td *ngIf="nt.url?.endsWith('.doc')" class="d-none d-sm-table-cell text-grey-d1">
        <!-- {{nt.name}} -->
        <a href="{{nt.url}}"><i class="fa fa-file-word" style="color: rgb(25, 72, 228);" title="Visualiser"></i></a>
      </td>
      <td *ngIf="nt.url?.endsWith('.pdf')" class="d-none d-sm-table-cell text-grey-d1">
        <!-- {{nt.name}} -->
        <a href="#" (click)="showPdf(nt.url)"><i class="fa fa-file-pdf" style="color: rgb(235, 65, 65);" title="Visualiser"></i></a>
      </td>

       <!-- <td class="text-center pr-0">
        <div>
          <a href="#"  (click)="deleteNote(nt.id)"
          class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
          <i class="fa fa-trash-alt"></i>
      </a>
        </div>
      </td>-->
 
     </tr>

     <!-- detail row -->
     </tbody>
   </table>

        </div>


      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" (click)="fermer()" type="button">
          Fermer
        </button>
        <button  (click)="save()"  class="btn btn-primary" type="button" >
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>

<app-pfd-viewer #pdfViewerComponent> </app-pfd-viewer>