import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {AppModule} from '../../app.module';
import {TypetemplateService} from '../../services/typetemplate.service';
import {FrmtypetemplateComponent} from './frmtypetemplate/frmtypetemplate.component';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;


@Component({
  selector: 'app-typetemplate',
  templateUrl: './typetemplate.component.html',
  styleUrls: ['./typetemplate.component.css']
})
export class TypetemplateComponent implements OnInit {

  data: any = [];

  typeTemplate: any = null;

  @ViewChild('frmtypeTemplate') frmtypeTemplate: FrmtypetemplateComponent;

  constructor(private http: HttpClient, private typetemplateService: TypetemplateService,private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.show();
  }

  show(): void {
    this.spinner.show();
     this.typetemplateService.getData().subscribe(data => {
       this.data = data;
       this.typeTemplate = null;
       this.spinner.hide();
    });

  }



  delete(id: any): void {
    if (confirm('Voulez vraiment supprimer cet élément ? ' )) {
      this.typetemplateService.delete(id).subscribe(data => {
        this.show();
      });
    }
  }


  openForm(id: any) {
    this.frmtypeTemplate.edit(id);
  }


}
