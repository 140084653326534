<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Réservation
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Suivi
      </small>
    </h1>

    <button class="btn btn-grey px-3  text-75 radius-round border-2" routerLink="/reservations" type="button">
      <i class="fa fa-hand-point-left mr-1"></i>
      Revenir <span class="d-sm-none d-md-inline"></span> à la liste des réservations
    </button>

  </div>


  <div class="px-2">
    <div *ngIf="!!reservation" class="row mt-2 mt-md-4">

      <!-- the left side profile picture and other info -->
      <div class="col-12 col-md-4">
        <div class="card acard">
          <div class="card-body">
                    <span class="d-none position-tl mt-2 pt-3px">
                    <span class="text-white bgc-blue-d1 ml-2 radius-b-1 py-2 px-2">
                        <i class="fa fa-star"></i>
                    </span>
                    </span>


            <div class="d-flex flex-column py-3 px-lg-3 justify-content-center align-items-center">

              <div class="pos-rel">
              </div>

              <div class="text-dark-l1">
                <div class="mt-1 mb-2 text-secondary-d1 text-600 text-125">
                  Réservation
                </div>
                <div class="mt-1 mb-2 text-secondary-d1 text-600 text-125">

                </div>
                <div class="mt-1 mb-2 text-secondary-d1 text-600 text-125">

                </div>

                <div class="my-2">
                  <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                  <span class="text-600 text-90">
                                    N°:
                                </span>
                  #{{reservation.id}}
                </div>
                <div class="my-2">
                  <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                  <span class="text-600 text-90">
                                    TAD/service:
                                </span>
                  {{reservation?.tad.nom}}
                </div>

                <div class="my-2">
                  <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                  <span class="text-600 text-90">
                                    Date creation:
                                </span>
                  {{reservation.createdDate | date : 'dd/MM/yyyy'}}
                </div>

                <div class="my-2">
                  <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                  <span class="text-600 text-90">
                                    Statut:
                                </span>
                  <span class="badge bgc-orange-d1 text-white badge-pill px-25">
                                    {{reservation.statut.libelle}}
                                </span>
                </div>
                <div class="my-2">
                  <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                  <span class="text-600 text-90">
                                    Créé par:
                                </span>

                    {{(!!reservation.createdBy) ? reservation.createdBy.acteur.personne.prenom + ' ' + reservation.createdBy.acteur.personne.nom : ''}}

                </div>
                <div class="my-2">
                  <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                  <span class="text-600 text-90">
                                    Validé par:
                                </span>

                  {{(!!reservation.validatedBy) ? reservation.validatedBy.acteur.personne.prenom + ' ' + reservation.validatedBy.acteur.personne.nom : ''}}

                </div>
              </div>
              <hr class="w-90 mx-auto brc-secondary-l3">
              <div class="mt-2 w-100 text-90 text-secondary radius-1 px-25 py-3">
              </div>
              <!--div class="text-center" *ngIf="(!!this.reservation && this.reservation.statut.code !== 'TER')" >
                <button (click)="traiter()" class="btn btn-blue pos-rel px-5 px-md-4 px-lg-5" type="button">
                  <i class="far fa-handshake mr-15 text-110"></i>
                  Traiter
                </button>
              </div-->

            </div><!-- /.d-flex -->
          </div><!-- /.card-body -->
        </div><!-- /.card -->


      </div><!-- .col -->


      <!-- the right side profile tabs -->
      <div class="col-12 col-md-8 mt-3 mt-md-0">

        <div class="card dcard h-100">

          <div class="card-body p-0">

            <div class="sticky-nav">
              <div class="sticky-trigger"> </div>
              <div class="position-tr w-100 border-t-4 brc-blue-m2 radius-2 d-md-none"></div>

              <ul
                class="nav nav-tabs-scroll is-scrollable nav-tabs nav-tabs-simple p-1px pl-25 bgc-secondary-l4 border-b-1 brc-dark-l3 radius-t-1"
                id="profile-tabs" role="tablist">
                <li class="nav-item mr-2 mr-lg-3">
                  <a aria-controls="profile-tab-overview" aria-selected="true"
                     class="d-style nav-link px-2 py-35 brc-primary-tp1 active" data-toggle="tab"
                     href="#profile-tab-overview" role="tab">
                    <span class="d-n-active text-dark-l1">1. Détails</span>
                    <span class="d-active text-dark-m3">1. Details</span>
                  </a>
                </li>

                <li class="nav-item mr-2 mr-lg-3">
                  <a aria-controls="profile-tab-activity" aria-selected="false"
                     class="d-style nav-link px-2 py-35 brc-primary-tp1 pr-45" data-toggle="tab"
                     href="#profile-tab-activity" role="tab">
                    <span class="d-n-active text-dark-l1">2. Passagers</span>
                    <span class="d-active text-dark-m3">2. Passagers</span>
                  </a>
                </li>

                <li class="nav-item mr-2 mr-lg-3">
                  <a aria-controls="profile-tab-timeline" aria-selected="false"
                     class="d-style nav-link px-2 py-35 brc-primary-tp1" data-toggle="tab" href="#transporteur"
                     role="tab">
                    <span class="d-n-active text-dark-l1">3. Transporteur</span>
                    <span class="d-active text-dark-m3">3. Transporteur</span>
                  </a>
                </li>

                <li class="nav-item mr-2 mr-lg-3">
                  <a aria-controls="profile-tab-timeline" aria-selected="false"
                     class="d-style nav-link px-2 py-35 brc-primary-tp1" data-toggle="tab" href="#profile-tab-timeline"
                     role="tab">
                    <span class="d-n-active text-dark-l1">4. Notes</span>
                    <span class="d-active text-dark-m3">4. Notes</span>
                  </a>
                </li>

              </ul>
            </div><!-- /.sticky-nav-md -->


            <div class="tab-content px-0 tab-sliding flex-grow-1 border-0 ace-scrollbar">

              <!-- overview tab -->
              <div class="tab-pane show px-1 px-md-2 px-lg-4 active" id="profile-tab-overview">
                <div class="row mt-1">
                  <div class="col-12 px-4">
                    <!-- infobox -->
                    <div class="d-flex justify-content-center my-3 flex-wrap flex-equal">
                      <div class="bcard h-100 flex-grow-1 p-3 pt-1 pt-lg-2">
                        <h6 class="text-dark-m2 text-90 text-600">
                          Passagers
                        </h6>

                        <div class="text-primary-m1 text-center text-200">
                        <span class="text-info-l2">
                            {{passagers.length}}
                        </span>
                        </div>
                      </div>

                      <div class="bcard ml-3 h-100 flex-grow-1 p-3 pt-1 pt-lg-2">
                        <h6 class="text-dark-m2 text-90 text-600">
                          Distance
                        </h6>

                        <div class="text-primary-m1 text-center text-200">
                        <span class="text-grey-d2">
                            {{reservation.distance | number: '1.0-2':'fr-FR'}} KM
                        </span>
                        </div>
                      </div>


                      <div class="bcard ml-3 h-100 flex-grow-1 p-3 pt-1 pt-lg-2">
                        <h6 class="text-dark-m2 text-90 text-600">
                          Tarif
                        </h6>

                        <div class="text-primary-m1 text-center text-200">
                        <span class="text-green-d2">
                          {{getPrice(reservation.templatePrix) | number: '1.0-2':'fr-FR'}} €
                        </span>
                        </div>
                      </div>

                    </div>
                  </div>


                    <div class="col-12 px-4">

                      <div class="d-flex justify-content-center my-3 flex-wrap flex-equal">

                    <div >

                      <h4 class="text-dark-m3 text-110">
                        <i class="far fa-lightbulb text-danger-d1 text-85 w-3"></i>
                        Motif
                      </h4>


                      <div class="mt-2 ">
                              <span
                                class="d-inline-block radius-round bgc-blue-l2 text-dark-tp3 text-90 px-25 py-3px mx-2px my-2px">
                                       {{reservation.motifTransport.libelle}}
                                    </span>

                      </div>


                    </div>

                    <div  >

                      <h4 class="text-dark-m3 text-110">
                        <i class="far fa-calendar text-danger-d1 text-85 w-3"></i>
                        Date
                      </h4>


                      <div class="mt-2 ml-2">
                               <span
                                 class="d-inline-block text-85">
                                      {{jourReservation}}
                                    </span>

                      </div>


                    </div>


                    <div  >

                      <h4 class="text-dark-m3 text-110">
                        <i class="far fa-clock text-danger-d1 text-85 w-3"></i>
                        Heure <span *ngIf="!!reservation.flagHeureReservation && reservation.flagHeureReservation == 'ARRIVEE'; else elseBlock">
                              d'arrivée</span>
                              <ng-template #elseBlock>
                                de Départ</ng-template>
                      </h4>


                      <div class="mt-2 ml-5">
                               <span
                                 class="d-inline-block text-85 text-justify">
                                      {{heureReservation}}
                                    </span>

                      </div>


                    </div>

                      </div>

                    </div>




                  <div class="col-12 px-4 mt-3">

                    <h4 class="mt-2 text-dark-m3 text-130">
                      <i class="fa fa-pen-alt text-85 text-purple-d1 w-3"></i>
                      Note pour le chauffeur
                    </h4>

                    <div
                      class="d-flex flex-column flex-sm-row align-items-center align-items-sm-start mt-3 mb-2 text-95 pl-3">
                      <div class="d-style order-first order-sm-last mx-2 radius-2 overflow-hidden mb-3 mb-sm-0">
                      </div>

                      <div class="mt-2 mt-sm-0 flex-grow-1 text-dark-m2">
                        <p class="mb-1">
                          {{reservation.noteChauffeur}}
                        </p>

                      </div>
                    </div>
                  </div>


                </div>


                <div class="row mt-5">
                  <div class="col-12 px-4 mb-3">

                    <h4 class="text-dark-m3 text-110">
                      <i class="fa fa-road text-blue mr-2 w-2"></i>
                      Trajet
                    </h4>

                    <hr class="w-100 mx-auto mb-0 brc-default-l2">

                    <div class="bgc-white radius-1">
                      <table class="table table table-striped-default table-borderless">
                        <tbody>


                        <tr>
                          <td>
                            <i class="fa fa-map-marker text-blue-d1"></i>
                          </td>

                          <td class="text-95 text-600 text-secondary-d2">
                            Départ
                          </td>

                          <td class="text-dark-m3">
                            {{reservation.adresseDepart}}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <i class="fa fa-map-marker-alt text-orange-d1"></i>
                          </td>

                          <td class="text-95 text-600 text-secondary-d2">
                            Arrivée
                          </td>

                          <td class="text-dark-m3">
                            {{reservation.adresseArrivee}}
                          </td>
                        </tr>


                        </tbody>
                      </table>
                    </div>

                  </div>

                </div><!-- /.row -->

              </div><!-- /.tab-pane -->


              <!-- activity tab -->
              <div class="tab-pane px-1 px-md-2 px-lg-3" id="profile-tab-activity">
                <div>
                  <div class="d-flex m-3">
                    <h4 class="text-dark-tp4 text-130 p-0 m-0">
                    </h4>


                  </div>

                  <hr class="border-dotted mx-3">

                  <div class="px-lg-3">

                    <table
                      class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                      <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                      <tr>


                        <th>
                          N°
                        </th>

                        <th>
                          Prénom
                        </th>

                        <th class="d-none d-sm-table-cell">
                          Nom
                        </th>

                        <th class="d-none d-sm-table-cell">
                          Age
                        </th>
                        <th class="d-none d-sm-table-cell">
                          Téléphone
                        </th>

                        <th class="d-none d-sm-table-cell">
                          TAD
                        </th>

                        <th class="d-none d-sm-table-cell">
                          Lieu d'hébergement
                        </th>
                       

                        <th></th>

                      </tr>
                      </thead>

                      <tbody class="mt-1">
                      <ng-container *ngFor="let passager of passagers; let i = index;">
                        <tr class="bgc-h-yellow-l4 d-style">


                          <td>
                            <a class="text-blue-d1 text-600 text-95" href="#">{{i + 1}}</a>
                          </td>

                          <td class="text-600 text-info-d2">
                            {{passager.acteur.personne.prenom}}
                          </td>

                          <td class="d-none d-sm-table-cell text-info-d2">
                            {{passager.acteur.personne.nom}}
                          </td>

                          <td class="d-none d-sm-table-cell text-grey text-95">
                            {{tool.getAge(passager.acteur.personne.datenaissance)}}
                          </td>
                          <td class="d-none d-sm-table-cell text-grey text-95">
                            {{passager.acteur.personne.telephone2}}
                          </td>

                          <td class="d-none d-sm-table-cell text-orange-d2 text-95">
                            {{passager.acteur.structure.nom}}
                          </td>
                          
                          <td>
                            <!--   --> <input  disabled type="text" [value]="tabFoyer[passager.id]?.nom" > 
                          <!--     <span>{{tabFoyer[passager.id]?.nom}}</span> -->
                            
                          </td>


                          <td class="text-center pr-0">
                            <div>
                              <a class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                                 [attr.data-target]="'#details-passager-' + passager.id"  data-toggle="collapse" href="#" title="Show Details">
                                  <span class="d-none d-md-inline mr-1">
											Details
										</span>
                                <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                              </a>
                            </div>
                          </td>


                        </tr>

                        <tr class="border-0 detail-row bgc-white">
                          <td class="p-0 border-none brc-secondary-l2" colspan="8">
                            <div class="table-detail collapse" [id]="'details-passager-'+passager.id">
                              <div class="row">
                                <div class="col-12 row col-md-12  p-4">

                                  <div class="col-md-6">
                                    <div class="card ccard radius-t-0 h-100">
                                      <div class="position-tl w-102 border-t-3 brc-primary-tp3 ml-n1px mt-n1px"></div>
                                      <!-- the blue line on top -->

                                      <div class="card-header pb-3 brc-secondary-l3">
                                        <h5 class="card-title mb-2 mb-md-0 text-dark-m3">
                                          Départ
                                        </h5>


                                      </div>


                                      <div class="card-body pt-2 pb-1">

                                        <table class="table table table-striped-default table-borderless">
                                          <tbody>
                                          <tr>
                                            <td>
                                              <i class="fa fa-map-marker-alt text-blue"></i>
                                            </td>

                                            <td class="text-95 text-600 text-secondary-d2">
                                              Adresse
                                            </td>

                                            <td class="text-dark-m3">
                                              {{passager.adresseDepart}}
                                            </td>

                                          </tr>
                                          <tr>
                                            <td>
                                              <i class="fa fa-clock text-warning"></i>
                                            </td>
                                            <td class="text-95 text-600 text-secondary-d2">
                                              Heure de prise encharge
                                            </td>

                                            <td class="text-dark-m3">
                                              {{passager.heurePriseEncharge}}
                                            </td>

                                          </tr>
                                          <ng-container *ngIf="passager.contacts.length > 0 ">

                                            <ng-container *ngFor="let contact of passager.contacts">


                                              <ng-container *ngIf="contact.type == 'DEPART'">

                                                <tr>
                                                  <td>
                                                    <i class="far fa-user text-success"></i>
                                                  </td>

                                                  <td class="text-95 text-600 text-secondary-d2">
                                                    Contact
                                                  </td>

                                                  <td class="text-dark-m3">
                                                    {{contact.personne.prenom + ' ' + contact.personne.nom}}
                                                  </td>
                                                </tr>


                                                <tr>
                                                  <td>
                                                    <i class="fa fa-phone text-purple"></i>
                                                  </td>

                                                  <td class="text-95 text-600 text-secondary-d2">
                                                    Fixe
                                                  </td>

                                                  <td class="text-dark-m3">
                                                    {{contact.personne.telephone1}}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>
                                                    <i class="fa fa-mobile text-purple"></i>
                                                  </td>

                                                  <td class="text-95 text-600 text-secondary-d2">
                                                    Portable
                                                  </td>

                                                  <td class="text-dark-m3">
                                                    {{contact.personne.telephone2}}
                                                  </td>
                                                </tr>


                                              </ng-container>


                                            </ng-container>


                                          </ng-container>


                                          </tbody>
                                        </table>

                                      </div>
                                    </div>
                                  </div>


                                  <div class="col-md-6">
                                    <div class="card ccard radius-t-0 h-100">
                                      <div class="position-tl w-102 border-t-3 brc-warning-d1 ml-n1px mt-n1px"></div>
                                      <!-- the blue line on top -->

                                      <div class="card-header pb-3 brc-secondary-l3">
                                        <h5 class="card-title mb-2 mb-md-0 text-dark-m3">
                                          Arrivée
                                        </h5>


                                      </div>

                                      <div class="card-body pt-2 pb-1">

                                        <table class="table table table-striped-default table-borderless">
                                          <tbody>

                                          <tr>
                                            <td>
                                              <i class="fa fa-map-marker-alt text-orange"></i>
                                            </td>

                                            <td class="text-95 text-600 text-secondary-d2">
                                              Adresse
                                            </td>

                                            <td class="text-dark-m3">
                                              {{passager.adresseArrivee}}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <i class="fa fa-clock text-warning"></i>
                                            </td>
                                            <td class="text-95 text-600 text-secondary-d2">
                                              Heure de depose
                                            </td>

                                            <td class="text-dark-m3">
                                              {{passager.heureDepose}}
                                            </td>

                                          </tr>


                                          <ng-container *ngIf="passager.contacts.length > 0 ">

                                            <ng-container *ngFor="let contact of passager.contacts">


                                              <ng-container *ngIf="contact.type == 'ARRIVEE'">

                                                <tr>
                                                  <td>
                                                    <i class="far fa-user text-success"></i>
                                                  </td>

                                                  <td class="text-95 text-600 text-secondary-d2">
                                                    Contact
                                                  </td>

                                                  <td class="text-dark-m3">
                                                    {{contact.personne.prenom + ' ' + contact.personne.nom}}
                                                  </td>
                                                </tr>


                                                <tr>
                                                  <td>
                                                    <i class="fa fa-phone text-purple"></i>
                                                  </td>

                                                  <td class="text-95 text-600 text-secondary-d2">
                                                    Fixe
                                                  </td>

                                                  <td class="text-dark-m3">
                                                    {{contact.personne.telephone1}}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>
                                                    <i class="fa fa-mobile text-purple"></i>
                                                  </td>

                                                  <td class="text-95 text-600 text-secondary-d2">
                                                    Portable
                                                  </td>

                                                  <td class="text-dark-m3">
                                                    {{contact.personne.telephone2}}
                                                  </td>
                                                </tr>


                                              </ng-container>


                                            </ng-container>


                                          </ng-container>


                                          </tbody>
                                        </table>

                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                      </ng-container>

                      </tbody>
                    </table>

                  </div>
                </div>
              </div><!-- /.tab-pane -->

              <div class="tab-pane px-1 px-md-2 px-lg-3" id="transporteur">

                <div class="px-3 text-grey-m1 text-95">

                <div class=" mb-4">
                  <div class="card-body px-4 px-lg-5">

                    <div class="row mt-4">
                      <div class="col-sm-2">
                        <span class="text-dark-m3 text-140">
                          Prestataire
                      </span>
                        <br>
                        <span class="text-dark-l2 text-90">
                          {{!!reservation.templatePrix ? reservation.templatePrix.prestataire.nom :''}}
                      </span>
                      </div>
                      <div class="col-sm-4">
                        <span class="text-dark-m3 text-140">
                         Template Prix
                      </span>
                        <br>
                        <span class="text-dark-l2 text-90">
                           {{!!reservation.templatePrix ? reservation.templatePrix.libelle :''}}
                      </span>
                      </div>
                      <div class="col-sm-3">
                        <div>
                          <span class="text-dark-m3 text-140">
                            Responsable :
                        </span>
                        <br>
                          <span class="text-dark-l2 text-90">
                             {{!!reservation.templatePrix ? reservation.templatePrix.prestataire.responsable :''}}
                          </span>

                        </div>
                      </div><!-- /.col -->


                      <div class="col-sm-2 align-self-start  justify-content-end text-95">
                        <div>
                          <span class="text-dark-m3 text-140">
                            Chauffeur:
                        </span>
                        <br>
                        <span class="text-dark-l2 text-90">
                          {{ (!!reservation.chauffeur) ? (reservation.chauffeur.personne.prenom+" "+reservation.chauffeur.personne.nom):''}}
                        </span>

                          <div class="text-dark-l1">
                            <div class="my-1">
                              <i class="fa fa-phone fa-flip-horizontal text-purple"></i> <b class="text-600">{{ !!reservation.chauffeur ? reservation.chauffeur.personne.telephone1:''}} </b>
                            </div>
                            <div class="my-1">
                              <i class="fa fa-mobile fa-flip-horizontal text-purple"></i> <b class="text-600">{{ !!reservation.chauffeur ? reservation.chauffeur.personne.telephone2:''}} </b>
                            </div>
                          </div>
                        </div>
                      </div><!-- /.col -->
                    </div><!-- /.قخص -->


                    <div class="mt-4">
                      <div class="row text-600 text-95 text-secondary-d3 brc-green-l1 py-25 border-y-2">
                        <div class="col-7 col-sm-5">
                          Description
                        </div>

                        <div class="d-none d-sm-block col-4 col-sm-2" style="text-align: right;">
                          Qté
                        </div>

                        <div class="d-none d-sm-block col-sm-2" style="text-align: right;">
                          P.U
                        </div>

                        <div class="col-5 col-sm-2" style="text-align: right;">
                          Montant
                        </div>
                      </div>


                      <div class="text-95 text-dark-m3">

                        <div class="row mb-2 mb-sm-0 py-25 bgc-green-l4">

                          <div class="col-7 col-sm-5">
                            Prise en charge
                          </div>

                          <div class="d-none d-sm-block col-2  text-75" style="text-align: right;">
                            {{reservation.passagers.length}}
                          </div>

                          <div class="d-none d-sm-block col-2  text-75" style="text-align: right;">
                            {{reservation.templatePrix?.priseenncharge  | number: '1.0-2':'fr-FR'}} €
                          </div>

                          <div class="col-5 col-sm-2 text-secondary-d3  text-75 text-600" style="text-align: right;">
                            {{reservation.passagers.length * temp?.priseenncharge | number: '1.0-2':'fr-FR'}} €
                          </div>
                        </div>

                        <div class="row mb-2 mb-sm-0 py-25">

                          <div class="col-7 col-sm-5">
                            Approche
                          </div>

                          <div class="d-none d-sm-block col-2  text-75" style="text-align: right;">
                            {{distApproche  | number: '1.0-2':'fr-FR'}} KM
                          </div>

                          <div class="d-none d-sm-block col-2  text-75" style="text-align: right;">
                            {{tarifApplique  | number: '1.0-2':'fr-FR'}} €
                          </div>

                          <div class="col-5 col-sm-2 text-secondary-d3 text-600  text-75" style="text-align: right;">
                            {{distApproche * tarifApplique | number: '1.0-2':'fr-FR' }} €
                          </div>
                        </div>

                        <div class="row mb-2 mb-sm-0 py-25">
                        

                          <div class="col-7 col-sm-5">
                            Terme Kilométrique
                          </div>

                          <div class="d-none d-sm-block col-2  text-75" style="text-align: right;">
                            {{reservation.distance  | number: '1.0-2':'fr-FR'}} KM
                          </div>

                          <div class="d-none d-sm-block col-2  text-75" style="text-align: right;">
                            {{tarifApplique  | number: '1.0-2':'fr-FR'}} €
                          </div>

                          <div class="col-5 col-sm-2 text-secondary-d3 text-600  text-75" style="text-align: right;">
                            {{reservation.distance * tarifApplique | number: '1.0-2':'fr-FR' }} €
                          </div>
                        </div>


                        <div class="row mb-2 mb-sm-0 py-25">
                      

                          <div class="col-7 col-sm-5">
                            Retour à vide
                          </div>

                          <div class="d-none d-sm-block col-2  text-75" style="text-align: right;">
                            {{distRetour  | number: '1.0-2':'fr-FR'}} KM
                          </div>

                          <div class="d-none d-sm-block col-2  text-75" style="text-align: right;">
                            {{tarifApplique  | number: '1.0-2':'fr-FR'}} €
                          </div>

                          <div class="col-5 col-sm-2 text-secondary-d3 text-600  text-75" style="text-align: right;">
                            {{distRetour * tarifApplique | number: '1.0-2':'fr-FR' }} €
                          </div>
                        </div>


                      </div>

                      <div class="row border-b-2 brc-green-l1"></div>


                      <div class="row mt-4">
                        <div class="col-sm-4 mt-2 mt-lg-0">
                        </div>


                        <div class="col-sm-8 text-dark-l1 text-90 order-first order-sm-last">


                          <div class="row my-3 align-items-center bgc-green-d3 p-2 radius-1">
                            <div class="col-7 text-right text-white text-110">
                              Total HTVA
                            </div>

                            <div class="col-5">
                                <span class="text-150 text-white">
                                                                     {{total | number: '1.0-2':'fr-FR'}} €

                                    </span>
                            </div>
                          </div>
                        </div>
                      </div>


                      <!--   <div class="row mt-4">
 <hr class="brc-secondary-l3 border-t-2">
                        <div class="col-12 px-4 mt-3">

                          <h4 class="mt-2 text-dark-m3 text-130">
                            <i class="fa fa-pen-alt text-85 text-purple-d1 w-3"></i>
                            Note pour le chauffeur
                          </h4>

                          <div
                            class="d-flex flex-column flex-sm-row align-items-center align-items-sm-start mt-3 mb-2 text-95 pl-3">
                            <div class="d-style order-first order-sm-last mx-2 radius-2 overflow-hidden mb-3 mb-sm-0">
                            </div>

                            <div class="mt-2 mt-sm-0 flex-grow-1 text-dark-m2">
                              <p class="mb-1">
                                {{reservation.noteChauffeur}}
                              </p>

                            </div>
                          </div>
                        </div>


                      </div> -->

                      <div class="text-center text-secondary-d3 text-105">

                      </div>

                    </div>

                  </div>
                </div>

                </div>

              </div>


              <!-- timeline tab -->
              <div class="tab-pane px-1 px-md-2 px-lg-3" id="profile-tab-timeline">
                <table id="simple-table"
                class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
           <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
           <tr>
             <th class="text-center pr-0">
              Créée par
             </th>


             <th class="d-none d-sm-table-cell">
               Note
             </th>

             <th class="d-none d-sm-table-cell">
               Type de note
             </th>

             <th>
              Date/Heure
             </th>
             <th>
              Fichier
             </th>
           </tr>
           </thead>

                  <tbody class="mt-1">
           <tr *ngFor="let nt of notes " class="bgc-h-yellow-l4 d-style">
             <td class="text-85 text-blue-d1">
              {{nt.user.acteur.personne.prenom + ' ' + nt.user.acteur.personne.nom}}
             </td>

             <td class="d-none d-sm-table-cell text-grey-d1">
              {{nt.note}}
             </td>

             <td class="d-none d-sm-table-cell text-grey-d1">
               {{nt.typeNote?.libelle}}
             </td>

             <td class="d-none d-sm-table-cell text-grey text-95">
               {{nt.modifiedDate | date:'dd-MM-yyyy HH:mm'}}
             </td>
             <td *ngIf="nt.url?.endsWith('.xlsx')" class="d-none d-sm-table-cell text-grey-d1">
              <!-- {{nt.name}} -->
              <a href="{{nt.url}}"><i class="fa fa-file-excel" style="color: rgb(21, 146, 9);" title="Visualiser"></i></a>
            </td>
            <td *ngIf="nt.url?.endsWith('.xls')" class="d-none d-sm-table-cell text-grey-d1">
              <!-- {{nt.name}} -->
              <a href="{{nt.url}}"><i class="fa fa-file-excel" style="color: rgb(21, 146, 9);" title="Visualiser"></i></a>
            </td>
            <td *ngIf="nt.url?.endsWith('.docx')" class="d-none d-sm-table-cell text-grey-d1">
              <!-- {{nt.name}} -->
              <a href="{{nt.url}}"><i class="fa fa-file-word" style="color: rgb(25, 72, 228);" title="Visualiser"></i></a>
            </td>
            <td *ngIf="nt.url?.endsWith('.doc')" class="d-none d-sm-table-cell text-grey-d1">
              <!-- {{nt.name}} -->
              <a href="{{nt.url}}"><i class="fa fa-file-word" style="color: rgb(25, 72, 228);" title="Visualiser"></i></a>
            </td>
            <td *ngIf="nt.url?.endsWith('.pdf')" class="d-none d-sm-table-cell text-grey-d1">
              <!-- {{nt.name}} -->
              <a href="#" (click)="showPdf(nt.url)"><i class="fa fa-file-pdf" style="color: rgb(235, 65, 65);" title="Visualiser"></i></a>
            </td>
             

           </tr>

           <!-- detail row -->
           </tbody>
         </table>

              </div><!-- /.tab-pane -->



            </div><!-- /.tab-content -->

          </div>
        </div><!-- /.card -->

      </div>
    </div>


  </div>

</div>




<app-traiter #frmTaiter></app-traiter>
<app-pfd-viewer #pdfViewerComponent> </app-pfd-viewer>
