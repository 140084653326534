<div class="modal fade modal-ds " data-backdrop="static" #structureModal id="modalCollectiviteStructure" tabindex="-1"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Nouveau {{lblstructure}}
                </h5>

                <button type="button" (click)="resetForm()" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="card ccard">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label
                  text-sm-right pr-0">
                            <label class="mb-0">
                                {{collectiviteTad}} <label style="color:red">
                                    *
                                </label>
                            </label>
                        </div>

                        <div class="col-sm-9">
                            <select class="form-control struct" (change)="openSelect()" [(ngModel)]="selectedstructure"
                                name="selectedstructure" id="collectivite">
                                <option value="">&nbsp;</option>
                                <option *ngFor="let col of
                          collectivites;" value="{{col.id}}">{{col.nom}}</option>
                            </select><span style="color:rgb(168, 54, 45);font-size: small;"
                                *ngIf="errorSelectColect==true">
                                Champs obligatoire.
                            </span>
                        </div>
                    </div>
                    <ul class="nav nav-tabs nav-tabs-simple nav-tabs-scroll border-b-1 brc-dark-l3 mx-0 mx-md-0 px-3 px-md-1 pt-2px"
                        role="tablist">
                        <li class="nav-item mr-1">
                            <a class="nav-link p-3 bgc-h-primary-l4 radius-0 active" id="home17-tab-btn"
                                data-toggle="tab" href="#home17" role="tab" aria-controls="home17" aria-selected="true">
                                <i class="fa fa-list text-success mr-3px"></i> Sélectionner
                            </a>
                        </li>



                        <li class="nav-item" ngif="collectiviteTad">
                            <a class="nav-link p-3 bgc-h-primary-l4 radius-0" id="more17-tab-btn" data-toggle="tab"
                                href="#more17" role="tab" aria-controls="more17" aria-selected="false">
                                <i class="fa fa-plus text-blue mr-3px"></i> Créer un nouveau
                            </a>
                        </li>
                    </ul>

                    <div class="card-body px-0 py-2">
                        <div class="tab-content tab-sliding border-0 px-0">
                            <div class="tab-pane show text-95 px-25 active" id="home17" role="tabpanel"
                                aria-labelledby="home17-tab-btn">
                                <table
                                    class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                                    <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                                        <tr>
                                            <th class="text-center pr-0">
                                                #
                                            </th>

                                            <th>
                                                SIREN
                                            </th>

                                            <th>
                                                Nom
                                            </th>

                                            <th class="d-none d-sm-table-cell">
                                                Email
                                            </th>

                                            <th class="d-none d-sm-table-cell">
                                                Adresse
                                            </th>

                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody class="mt-1">
                                        <tr class="bgc-h-yellow-l4 d-style" *ngFor="let structure of structures;">
                                            <td class="text-center pr-0 pos-rel">
                                                <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                                    <!-- border shown on hover -->
                                                </div>
                                                <div
                                                    class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                                    <!-- border shown when row is selected -->
                                                </div>

                                                <label>
                                                    <input type="checkbox" [value]="structure.id"
                                                        (change)="oncpChange($event)" class="align-middle">
                                                </label>
                                            </td>

                                            <td>
                                                <span class="text-dark-d1 text-600 text-95">{{structure.siret}}</span>
                                            </td>
                                            <td>
                                                <span class="text-dark-d1 text-600 text-95">{{structure.nom}}</span>
                                            </td>

                                            <td class="text-600 text-orange-d2">
                                                <span class="text-dark-d1 text-600 text-95">{{structure.email}}</span>
                                            </td>
                                            <td class="text-600 text-orange-d2">
                                                <span class="text-dark-d1 text-600 text-95">{{structure.adresse}}</span>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class="tab-pane text-95 px-25" id="more17" role="tabpanel"
                                aria-labelledby="more17-tab-btn">
                                <form class="mt-lg-3">
                                    <!---  <div class="form-group row foyer" id="foyer">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Type Lieu d'hébergement <label style="color:red">
                                                    *
                                                </label>
                                            </label>
                                        </div>

                                        <div class="col-sm-7">
                                            <select class="form-control foyer" [ngModel]="typestructure"
                                                name="typestructure" id="form-field-select-1">
                                                <option>&nbsp;</option>

                                                <option *ngFor="let typefoyer of typestructures" [ngValue]="typefoyer">
                                                    {{ typefoyer }}
                                                </option>
                                            </select>
                                        </div>
                                    </div> -->
                                    <!-- <div class="row">
                                      <span *ngIf="errormessage" class="col-sm-8 col-md-6 mx-auto">
                                        <i class="fa fa-times text-center" style="color:red"> {{errormessage}}</i>
                                      </span>
                                    </div> -->

                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Siren
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input type="number" [(ngModel)]="siret" name="siret"
                                                class="form-control col-sm-8 col-md-6" >
                                               
                                        </div>
                                    </div>



                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Nom<label style="color:red">
                                                    *
                                                </label>
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input readonly type="text" [(ngModel)]="nom" (change)="valideInput()" size="4"
                                                name="nom" class="form-control col-sm-8 col-md-6">
                                            <span *ngIf="error==true" style="color: rgb(168, 54, 45);font-size: small;">
                                                Le Champs nom est obligatoire
                                            </span>
                                        </div>
                                    </div>

                                  

                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Adresse
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input readonly class="form-control" placeholder="Adresse" id="adresse"
                                                [(ngModel)]="adresse" name="adresse" />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Ville
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input readonly type="text" id="ville" [(ngModel)]="ville" name="ville"
                                                class="form-control col-sm-8 col-md-6">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Code Postal
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input readonly type="text" id="codePostal" name="codePostal"
                                                [(ngModel)]="codePostal" class="form-control col-sm-8 col-md-6">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Responsable
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input type="text" [(ngModel)]="responsable" name="responsable"
                                                class="form-control col-sm-8 col-md-6">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Email
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input (change)="valideEmail()" [ngClass]="{'error':errorEmail==true}"
                                                type="email" [(ngModel)]="email" name="email"
                                                class="form-control col-sm-8 col-md-6">
                                            <span *ngIf="errorEmail==true"
                                                style="color: rgb(168, 54, 45);font-size: small;">
                                                L'email est invalide.
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-3 col-form-label text-sm-right pr-0">
                                            <label class="mb-0">
                                                Téléphone fixe
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input type="text" [(ngModel)]="telephone" name="telephone"
                                                class="form-control col-sm-8 col-md-6">
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="fermer()">
                    Fermer
                </button>
                <button type="button" class="btn btn-primary" (click)="savePrestaFoyer()">
                    Enregistrer
                </button>

            </div>
        </div>
    </div>
</div>
