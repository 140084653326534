<div class="modal fade modal-ms " id="modalFormPassager" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >
          Ajouter passager
        </h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="reset()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">


        <form autocomplete="off">

          <div class="row">

            <div class="col-md-6">
              <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                <h3 class="text-125 pl-1 mb-3 mb-sm-0 text-secondary-d4">
                  Enfants
                </h3>

                <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                  <input [(ngModel)]="search" class="form-control w-100 pl-45 radius-1 brc-primary-m4" name="search"
                         placeholder="Rechercher ..." type="text" (keyup.enter)="getPensionnaires()">
                  <i (click)="getPensionnaires()" class="fa fa-search position-rc ml-25 text-primary-m1"></i>

                </div>


              </div>

              <table  class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                <tr>
                  <th class="text-center pr-0">

                  </th>

                  <th>
                    Prénom
                  </th>

                  <th>
                    Nom
                  </th>

                  <th>
                    Age
                  </th>

                  <th>
                    TAD
                  </th>
                </tr>
                </thead>

                <tbody class="mt-1">
                <tr *ngFor="let pensionnaire of pensionnaires| paginate : {
                  itemsPerPage: tableSize,
                  currentPage: page,
                  totalItems: count}; let i = index" [class.active]="i == currentIndex" class="bgc-h-yellow-l4 d-style">
                  <td class="text-center pr-0 pos-rel">
                    <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                      <!-- border shown on hover -->
                    </div>
                    <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                      <!-- border shown when row is selected -->
                    </div>

                    <label>
                      <input (change)="ctcChange(pensionnaire)"  [value]="pensionnaire.id" class="align-middle" name="cbxPens"
                             type="radio">
                    </label>
                  </td>

                  <td class="text-blue-d1 text-600 text-95">
                   {{pensionnaire.personne.prenom}}
                  </td>

                  <td class="text-blue-d1 text-600 text-95">
                    {{pensionnaire.personne.nom}}
                  </td>

                  <td > 
                    <span *ngIf="!!pensionnaire.personne.datenaissance">
                        {{getAge(pensionnaire.personne.datenaissance)}} ans
                    </span>  
                </td>

                  <td class="text-600 text-orange-d2">
                    {{pensionnaire.structure.nom}}
                  </td>
                </tr>
                </tbody>
              </table>

              <!-- table footer -->
              <div class="d-flex pl-3 pr-3 pt-30 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                <div class="text-nowrap align-self-center align-self-sm-start">
                    Afficher 1 - {{ tableSize }} of {{pensionnaires.length}}
                    <select (change)="tableData($event)" class="custom-select">
                    <option *ngFor="let size of tableSizesArr" [value]="size">
                        Afficher {{ size }}
                    </option>
                  </select>
                </div>
                <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                    <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next" (pageChange)="tabSize($event)">
                    </pagination-controls>
                </div>
                <div>

                </div>
            </div>

            </div>

            <div class="col-md-6">

              <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
                <h3 class="text-125 pl-1 mb-3 mb-sm-0 text-secondary-d4">
                  Destination
                </h3>

                <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">

                  <label>
                    <input [disabled]="frmReservation?.passagers.length < 1" checked="" class="input-lg bgc-blue"
                           id="ckboxDestination" type="checkbox">
                           Même adresse de départ et arrivée
                  </label>

                </div>


              </div>

              <div id="mainTrajet">  
                <ul class="list-unstyled text-dark-m3">
                  <li class="mb-3">
                    <i class="w-3 text-center fa fa-map-marker-alt text-info"></i>
                    <span id="adresse_depart_psg">...</span>
                  </li>

                  <li class="mb-3 text-110 text-color-orange">
                    <i class="w-3 text-center fa fa-map-marker text-orange"></i>
                    <span id="adresse_arrivee_psg">...</span>
                  </li>


                </ul>


              </div>
              <div>
              <div class="form-group row" id="rbAdresse">
              <div class="col-md-6">
                <input id="rbDepart" checked name="adresse" type="radio" value="0" (click)= "ckAdresseChange()">
                <label>Même adresse de départ</label>
              </div>
              <div class="col-md-6"> 
                <input id="rbDestination" name="adresse" type="radio" value="1" (click)= "ckAdresseChangeArrivee()">
                <label>Même adresse d'arrivée</label>
              </div>
              </div>
            </div>

              <div style="display: none;">
                <div class="form-group row">
                <input
                  id="origin-input-frmpsg"
                  class="controls"
                  type="text"
                  placeholder="Adresse départ" name="adresseDepartPassager" [(ngModel)]="adresseDepartPassager"
                /></div>
                <div class="form-group row">
                <input
                  id="destination-input-frmpsg"
                  class="controls"
                  type="text"
                  placeholder="Adresse arrivée" name="adresseArriveePassager" [(ngModel)]="adresseArriveePassager"
                /></div>

                <div id="mode-selector-frmpsg"  style="display: none">
                  <input
                    type="radio"
                    name="type"
                    id="changemode-walking-frmpsg"

                  />
                  <label for="changemode-walking-frmpsg">Walking</label>

                  <input type="radio" name="type" id="changemode-transit-frmpsg" />
                  <label for="changemode-transit-frmpsg">Transit</label>

                  <input type="radio" checked="checked" name="type" id="changemode-driving-frmpsg" />
                  <label for="changemode-driving-frmpsg">Driving</label>
                </div>
              </div>

              <div id="map-frmpsg" style="display: none;"></div>

              <!-- <div class="form-group row">
                <div class="col-sm-4 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Heure de prise en charge
                  </label>
                </div>

                  <div class="col-sm-6 input-group time">
                    <input [(ngModel)]="heurePriseEncharge" class="form-control" id="heurePriseEncharge" name="heurePriseEncharge"
                           type="text"/>
                    <div class="input-group-addon input-group-append">
                      <div class="input-group-text">
                        <i class="far fa-clock"></i>
                      </div>
                    </div>
                  </div>


              </div>

              <div class="form-group row">
                <div class="col-sm-4 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Heure de depose
                  </label>
                </div>
                <div class="col-sm-6 input-group time">
                  <input [(ngModel)]="heureDepose" class="form-control" id="heureDepose" name="heureDepose"
                         type="text"/>
                  <div class="input-group-addon input-group-append">
                    <div class="input-group-text">
                      <i class="far fa-clock"></i>
                    </div>
                  </div>
                </div>
              </div> -->


            </div>

          </div>




        </form>



      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="fermer()">
          Fermer
        </button>
        <button type="button" id="btnAddPassenger" (click)="savePassenger()" class="btn btn-primary">
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>

