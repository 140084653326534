import {Component, OnInit} from '@angular/core';
import {StructureService} from "../../services/structure.service";
import {SimulationService} from "../../services/simulation.service";

declare var $: any;

@Component({
  selector: 'app-details-historique',
  templateUrl: './details-historique.component.html',
  styleUrls: ['./details-historique.component.css']
})
export class DetailsHistoriqueComponent implements OnInit {

  log: any;
  tad: any;
  transporteur: any;
  details: any = [];

  wind;

  constructor(private structureService: StructureService, private simulationService: SimulationService) {
  }

  ngOnInit(): void {
    this.wind = window;
  }

  async getTad(id) {
    this.tad = await this.structureService.get(id).toPromise();
  }

  async getTransporteur(id) {
    this.transporteur = await this.structureService.get(id).toPromise();
  }


  show(log) {
    this.log = log;
    this.getTad(log.tad);
    this.getTransporteur(log.transporteur);
    this.simulationService.getDetails(this.log.id).subscribe(details => {
      this.details = details;
    });

    $('#modalDetailsSimulation').modal('show');
  }


}
