import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AppModule} from '../../app.module';
import {url} from 'inspector';
import {BROWSER_STORAGE} from '../session/storage';
import {User} from '../session/user';
import {AuthResponse} from '../session/authresponse';

@Injectable({
  providedIn: 'root'
})
export class Loc8rDataService {


  constructor(private http: HttpClient) { }

  public login(user: User): Promise<AuthResponse> {
    return this.call('auth/login', user);
  }

  public register(user: User): Promise<AuthResponse> {
    return this.call('auth/register', user);
  }

  private call(urlPath: string, user: User):
    Promise<AuthResponse> {
    const url = AppModule.API_ENDPOINT + urlPath;
    return this.http
      .post(url, user)
      .toPromise()
      .then(response => response as AuthResponse)
      .catch();
  }


}

