import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {AppModule} from '../../app.module';
import {VilleService} from "../../services/ville.service";

declare var $: any;


@Component({
  selector: 'app-ville',
  templateUrl: './ville.component.html',
  styleUrls: ['./ville.component.css']
})
export class VilleComponent implements OnInit {

  data: any = [];
  ville: any = null;

  code: any = '';
  libelle: any = '';
  description: any = '';

  constructor(private http: HttpClient, private villeService: VilleService) { }


  ngOnInit(): void {
    this.show();
  }

  show(): void {
     this.villeService.getData().subscribe(data => {
       this.data = data;
       this.ville = null;
    });

  }

  save(): void {
    const headers = { };
    const body = { code: this.code, libelle: this.libelle , actif: true  };
    console.log(this.ville);
    if (!this.ville) {
      this.villeService.save(headers, body).subscribe(data => {
        this.show();
        $('#modalForm').modal('hide');
      });
    } else {
      this.villeService.update(headers, body, this.ville.id).subscribe(data => {
        this.show();
        $('#modalForm').modal('hide');
      });
    }
  }

  delete(id: any): void {
    if (confirm('Voulez vraiment supprimer cet élément ? ' )) {
      this.villeService.delete(id).subscribe(data => {
        this.show();
      });
    }
  }


  edit(id: any): void {
    this.villeService.get(id).subscribe(data => {
      this.ville = data;
      this.code = this.ville.code;
      this.libelle = this.ville.libelle;
      $('#modalForm').modal('show');
    });

  }


}
