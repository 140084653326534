import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AppModule } from '../../app.module';
import { ActeurService } from "../../services/acteur.service";
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { FonctionService } from 'src/app/services/fonction.service';
import { PersonneService } from 'src/app/services/personne.service ';
import { StructureService } from 'src/app/services/structure.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { FrmpensionnairesComponent } from 'src/app/foyer/frmpensionnaires/frmpensionnaires.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
import { FrmcollectivitecollaborateurComponent } from 'src/app/collectivite/frmcollectivitecollaborateur/frmcollectivitecollaborateur.component';
import { FrmenfantComponent } from 'src/app/collectivite/detailstad/frmenfant/frmenfant.component';
import { FrmtransfertenfantComponent } from 'src/app/foyer/frmtransfertenfant/frmtransfertenfant.component';
import { PaginationService } from 'src/app/services/pagination.service';
import { SecurityService } from 'src/app/services/security.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare function stransAdresse(id: any);
declare var $: any;
import Swal from 'sweetalert2';
import { FoyerService } from 'src/app/services/foyer.service';
import { PassagerService } from 'src/app/services/passager.service';

@Component({
  selector: 'app-lstacteurs',
  templateUrl: './lstacteurs.component.html',
  styleUrls: ['./lstacteurs.component.css']
})
export class LstacteursComponent implements OnInit {

  datapension: any = [];
  acteur: any = null;
  prestataires: any = [];
  collaborateur: any = null;
  fonctions: any = [];

  code: any = '';
  prenom: any = null;
  nom: any = null;
  email: any = null;
  adresse: any = null;
  datenaissance: any = null;
  telephone: any = null;
  telephone2: any = null;
  telephone1: any = null;
  information: any = null;
  fonction: any = null;
  typeacteur: any = '';
  personne: any = '';
  structure: any = '';
  currentPage = 1;
  itemsPerPage = 5;
  pageSize: number;
  public tad: any;
  col: any;
  datafoyer: any = [];
  user: User = new User();
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;

  canCreate = false;
  canTransfert = false;
  canDelete = false;
  canDetails = false;
  foyers: any = [];
  idfoyer = 0;
  lstcollaborateurscollectivite = true;
  typesfoyer= ['FOYER', 'FAMILLEACCUEIL'];
  tabTad={};

  @ViewChild('frmpensionnairesComponent') frmpensionnairesComponent: FrmpensionnairesComponent;

  @ViewChild('frmcollaborateur') frmcollaborateur: FrmcollectivitecollaborateurComponent;
  @ViewChild('frmenfants') frmenfants: FrmenfantComponent;
  @ViewChild('frmtransfertenfants') frmtransfertenfants: FrmtransfertenfantComponent;

  constructor(private http: HttpClient, private acteurService: ActeurService,
     private structureService: StructureService,
    private authenticationService: AuthenticationService, private securiteService: SecurityService, 
    private paginationService: PaginationService,private spinner: NgxSpinnerService,
    private foyerService: FoyerService, private passagerService: PassagerService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.showDroits();
    this.showActeurStructure();
    this.tad = this.user.structure;
    stransAdresse('adresse');
    this.col = this.user.parent;
    this.getAge(this.datenaissance);
    this.showStructure();
  }
  showDroits() {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_FOYERPENSIONNAIRE").subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_FOYERPENSIONNAIRE").subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_EDIT_PENSIONNAIRE_FOYER").subscribe(data => {
      console.log(data)
      if (!!data) {
        this.canTransfert = true;
      } else {
        this.canTransfert = false;
      }
    });
  }
  filter(parent: any, structure: any) {
    this.showActeurStructure();
  }
  showActeurStructure(): void {
    this.spinner.show();
   if(this.user.profilCode == "PF_TAD"){
    this.acteurService.getActeurRattacheesAllRAS(TypeActeurModel.PENSIONNAIRE, this.user.parent, TypeStructureModel.FOYER,this.user.id).subscribe(data => {
      this.datapension = data;
      this.acteur = null;
      this.datapension.forEach(enfant => {
     //   alert(psg.acteur.personne.nom);
        this.passagerService.getTadPassager(enfant.personne.id).subscribe(data => {
      //    alert(data);
          this.tabTad[enfant.id] = data;
        });
      });
      this.spinner.hide();
    });}
  
    else{
        this.acteurService.getActeurRattacheesAll(TypeActeurModel.PENSIONNAIRE, this.user.structure, TypeStructureModel.FOYER).subscribe(data => {
          this.datapension = data;
          this.acteur = null;
          this.datapension.forEach(enfant => {
            //   alert(psg.acteur.personne.nom);
               this.passagerService.getTadPassager(enfant.personne.id).subscribe(data => {
             //    alert(data);
                 this.tabTad[enfant.id] = data;
               });
             });
          this.spinner.hide();
        });
      }
  }
  showStructure(): void {
   
      this.structureService.getFoyerByProfil(this.user.id).subscribe(data => {
        this.foyers = data;
       
     });
    

  }
  rechercheAvance() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    if (this.idfoyer == null )
      this.idfoyer = 0;
    this.prenom = this.prenom ? this.prenom : null;
    this.nom = this.nom ? this.nom : null;
    this.email = this.email ? this.email : null;
    this.adresse = this.adresse ? this.adresse : null;
    this.telephone = this.telephone ? this.telephone : null;
    // alert(this.user.structure)
    this.acteurService.getRechercheEnfantFoyerBis(this.idfoyer,this.prenom, this.nom, this.email, this.adresse,
      this.telephone, TypeActeurModel.PENSIONNAIRE, this.user.structure, TypeStructureModel.FOYER,this.user.id).subscribe(data => {
        this.datapension = data;
        this.datapension.forEach(enfant => {
          //   alert(psg.acteur.personne.nom);
             this.passagerService.getTadPassager(enfant.personne.id).subscribe(data => {
           //    alert(data);
               this.tabTad[enfant.id] = data;
             });
           });
        this.spinner.hide();
      });
      this.tabSize(event);

  }
  transferer(enfant: any): void {
    this.frmtransfertenfants.enfant = enfant;
    this.frmtransfertenfants.personne = enfant.personne;
    this.frmtransfertenfants.typestructure = 'FOYER';
    this.frmtransfertenfants.lblstructure = 'Lieu hebergement';
    this.frmtransfertenfants.lblnom = 'Transfert enfant';
    this.frmtransfertenfants.show(this);

  }
  openFormEnfant(typeacteur: any, typestructure: any): void {
    if (this.user.structure != 0) {
      //  alert()
      this.structureService.get(this.user.structure).subscribe(data => {
        this.frmenfants.structure = data;
      });
      this.frmenfants.selectstructure = this.user.structure
    }

    this.frmenfants.typestructure = typestructure;
    this.frmenfants.lblnom = 'Enfant';
    this.frmenfants.lblstructure = 'Foyer'
    this.frmenfants.typeacteur = typeacteur;
    this.frmenfants.show(this);


  }
  openFormActeur(typeacteur: any, typestructure: any): void {
    this.lstcollaborateurscollectivite = true;
    if (this.user.structure != 0) {
      //  alert()
      this.structureService.get(this.user.structure).subscribe(data => {
        this.frmcollaborateur.structure = data;
      });
      this.frmcollaborateur.selectstructure = this.user.structure
    }
    this.frmcollaborateur.typestructure = typestructure;
    this.frmcollaborateur.lblstructure = 'Foyer';
    this.frmcollaborateur.lblnom = 'Nouveau Enfant';
    this.frmcollaborateur.typeacteur = typeacteur;
    this.frmcollaborateur.show(this);
  }

  openFormPensionnaireFoyer(typestructure: any) {
    this.frmpensionnairesComponent.lblstructure = 'Foyer';
    this.frmpensionnairesComponent.lblnom = 'Foyer';
    this.frmpensionnairesComponent.typestructure = typestructure;
    this.frmpensionnairesComponent.show(this);
  }


  edit(id: any) {
    this.frmcollaborateur.lblstructure = 'Foyer';
    this.frmcollaborateur.lblnom = 'Modification Pensionnaire';
    this.frmcollaborateur.typeacteur = 'PENSIONNAIRE';
    this.frmcollaborateur.editPersonne(this, id);
  }

  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.acteurService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.showActeurStructure();
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car cet enfant a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.acteurService.delete(id).subscribe(data => {
    //     this.showActeurStructure();
    //   });
    // }
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

  changePagesize(num: number): void {
    this.itemsPerPage = this.pageSize + num;
  }

  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.datapension = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }

  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }


  getAge(dateNaissance) {

    var timeDiff = Math.abs(Date.now() - new Date(dateNaissance).getTime());
    var age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

    return age;
  }

  reset1(){
    this.prenom = null;
   }
   reset2(){
    this.nom = null;
   }
   reset3(){
    this.email = null;
   }
   reset4(){
    this.adresse = null;
   }
   reset5(){
    this.telephone = null;
   }

   reset6(){
    this.idfoyer = 0;
   }
}
