import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {SimulationService} from "../../services/simulation.service";
import {log} from "util";
import {DetailsHistoriqueComponent} from "../details-historique/details-historique.component";

@Component({
  selector: 'app-historique-simulation',
  templateUrl: './historique-simulation.component.html',
  styleUrls: ['./historique-simulation.component.css']
})
export class HistoriqueSimulationComponent implements OnInit {

  logs: any = [];

  @ViewChild('detailsHistoriqueComponent') detailsHistoriqueComponent: DetailsHistoriqueComponent;

  constructor(private spinner: NgxSpinnerService, private simulationService: SimulationService) {
  }

  ngOnInit(): void {
    this.simulationService.getData().subscribe(logs => {
      this.logs = logs;
    });
  }

  viewDetails(log) {
    this.detailsHistoriqueComponent.show(log);
  }

}
