<div aria-hidden="true" class="modal fade modal-ms " id="modalPassager" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Passagers
        </h5>

        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">


        <table
          class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
          <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
          <tr>


            <th>
              N°
            </th>

            <th>
              Prénom
            </th>

            <th class="d-none d-sm-table-cell">
              Nom
            </th>

            <th class="d-none d-sm-table-cell">
              Age
            </th>

            <th class="d-none d-sm-table-cell">
              Unités
            </th>

            <th class="d-none d-sm-table-cell">
              Contact Départ
            </th>

            <th class="d-none d-sm-table-cell">
              Contact Arrivée
            </th>


            <th></th>

          </tr>
          </thead>

          <tbody class="mt-1">
          <ng-container *ngFor="let passager of passagers; let i = index;">
            <tr class="bgc-h-yellow-l4 d-style">

              <td>
                <a class="text-blue-d1 text-600 text-95" href="#">{{i + 1}}</a>
              </td>

              <td class="text-600 text-info-d2">
                {{passager.acteur.personne.prenom}}
              </td>

              <td class="d-none d-sm-table-cell text-info-d2">
                {{passager.acteur.personne.nom}}
              </td>

              <td class="d-none d-sm-table-cell text-grey text-95">
                {{getAge(passager.acteur.personne.datenaissance)}}
              </td>

              <td class="d-none d-sm-table-cell text-orange-d2 text-95">
                {{passager.acteur.structure.nom}}
              </td>

              <td class="d-none d-sm-table-cell text-grey text-95">
                <span *ngIf="passager.contacts.length > 0 ">
                  <ng-container *ngFor="let contact of passager.contacts">
                    <span *ngIf="contact.type == 'DEPART'"
                          class="text-grey"> {{contact.personne.prenom}} {{contact.personne.nom}} </span>
                    </ng-container>
                </span>
              </td>

              <td class="d-none d-sm-table-cell text-grey text-95">
                 <span *ngIf="passager.contacts.length > 0 ">
                  <ng-container *ngFor="let contact of passager.contacts">
                    <span *ngIf="contact.type == 'ARRIVEE'"
                          class="text-grey"> {{contact.personne.prenom}} {{contact.personne.nom}}</span>
                    </ng-container>
                </span>
              </td>


              <td class="text-center pr-0">
                <div>
                  <a class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                     [attr.data-target]="'#details-passager-'+passager.id" data-toggle="collapse" href="#"
                     title="Details">
                                  <span class="d-none d-md-inline mr-1">
											Details
										</span>
                    <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                  </a>
                  <a *ngIf="this.canAddHeure" (click)="EditPassager(passager)" 
                         class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                         title="Ajouer heure de prise en charge et de depose de l'enfant">

                        <i class="fas fa-clock text-90"></i>
                      </a>
                </div>
              </td>


            </tr>


            <tr class="border-0 detail-row bgc-white">
              <td class="p-0 border-none brc-secondary-l2" colspan="8">
                <div [id]="'details-passager-'+passager.id" class="table-detail collapse">
                  <div class="row">
                    <div class="col-12 row col-md-12  p-4">

                      <div class="col-md-6">
                        <div class="card ccard radius-t-0 h-100">
                          <div class="position-tl w-102 border-t-3 brc-primary-tp3 ml-n1px mt-n1px"></div>
                          <!-- the blue line on top -->

                          <div class="card-header pb-3 brc-secondary-l3">
                            <h5 class="card-title mb-2 mb-md-0 text-dark-m3">
                              Départ
                            </h5>


                          </div>


                          <div class="card-body pt-2 pb-1">

                            <table class="table table table-striped-default table-borderless">
                              <tbody>
                              <tr>
                                <td>
                                  <i class="fa fa-map-marker-alt text-warning"></i>
                                </td>

                                <td class="text-95 text-600 text-secondary-d2">
                                  Adresse
                                </td>

                                <td class="text-dark-m3">
                                  {{passager.adresseDepart}}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <i class="fa fa-clock text-warning"></i>
                                </td>
                                <td class="text-95 text-600 text-secondary-d2">
                                  Heure de prise en charge
                                </td>

                                <td class="text-dark-m3"> 
                                  {{passager.heurePriseEncharge}} 
                                </td>

                              </tr>

                              <ng-container *ngIf="passager.contacts.length > 0 ">

                                <ng-container *ngFor="let contact of passager.contacts">


                                  <ng-container *ngIf="contact.type == 'DEPART'">

                                    <tr>
                                      <td>
                                        <i class="far fa-user text-success"></i>
                                      </td>

                                      <td class="text-95 text-600 text-secondary-d2">
                                        Contact
                                      </td>

                                      <td class="text-dark-m3">
                                        {{contact.personne.prenom + ' ' + contact.personne.nom}}
                                      </td>
                                    </tr>


                                    <tr>
                                      <td>
                                        <i class="fa fa-phone text-purple"></i>
                                      </td>

                                      <td class="text-95 text-600 text-secondary-d2">
                                        Téléphone 1
                                      </td>

                                      <td class="text-dark-m3">
                                        {{contact.personne.telephone1}}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <i class="fa fa-phone text-purple"></i>
                                      </td>

                                      <td class="text-95 text-600 text-secondary-d2">
                                        Téléphone 2
                                      </td>

                                      <td class="text-dark-m3">
                                        {{contact.personne.telephone2}}
                                      </td>
                                    </tr>


                                  </ng-container>


                                </ng-container>


                              </ng-container>


                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>


                      <div class="col-md-6">
                        <div class="card ccard radius-t-0 h-100">
                          <div class="position-tl w-102 border-t-3 brc-warning-d1 ml-n1px mt-n1px"></div>
                          <!-- the blue line on top -->

                          <div class="card-header pb-3 brc-secondary-l3">
                            <h5 class="card-title mb-2 mb-md-0 text-dark-m3">
                              Arrivée
                            </h5>


                          </div>

                          <div class="card-body pt-2 pb-1">

                            <table class="table table table-striped-default table-borderless">
                              <tbody>

                              <tr>
                                <td>
                                  <i class="fa fa-map-marker-alt text-info"></i>
                                </td>

                                <td class="text-95 text-600 text-secondary-d2">
                                  Adresse
                                </td>

                                <td class="text-dark-m3">
                                  {{passager.adresseArrivee}}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <i class="fa fa-clock text-warning"></i>
                                </td>
                                <td class="text-95 text-600 text-secondary-d2">
                                  Heure de depose
                                </td>

                                <td class="text-dark-m3"> 
                                  {{passager.heureDepose}} 
                                </td>

                              </tr>


                              <ng-container *ngIf="passager.contacts.length > 0 ">

                                <ng-container *ngFor="let contact of passager.contacts">


                                  <ng-container *ngIf="contact.type == 'ARRIVEE'">

                                    <tr>
                                      <td>
                                        <i class="far fa-user text-success"></i>
                                      </td>

                                      <td class="text-95 text-600 text-secondary-d2">
                                        Contact
                                      </td>

                                      <td class="text-dark-m3">
                                        {{contact.personne.prenom + ' ' + contact.personne.nom}}
                                      </td>
                                    </tr>


                                    <tr>
                                      <td>
                                        <i class="fa fa-phone text-purple"></i>
                                      </td>

                                      <td class="text-95 text-600 text-secondary-d2">
                                        Téléphone 1
                                      </td>

                                      <td class="text-dark-m3">
                                        {{contact.personne.telephone1}}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <i class="fa fa-phone text-purple"></i>
                                      </td>

                                      <td class="text-95 text-600 text-secondary-d2">
                                        Téléphone 2
                                      </td>

                                      <td class="text-dark-m3">
                                        {{contact.personne.telephone2}}
                                      </td>
                                    </tr>


                                  </ng-container>


                                </ng-container>


                              </ng-container>


                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </td>
            </tr>


          </ng-container>

          </tbody>
        </table>


      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" data-dismiss="modal" type="button">
          Fermer
        </button>

 
      </div>
    </div>
  </div>
</div>
<app-frminformationchauffeur #frminformationchauffeurComponent></app-frminformationchauffeur>
