<div aria-hidden="true" class="modal fade modal-ms " id="modalBc" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Bon de commande
        </h5>

        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div *ngIf="!!reservation" class="modal-body">


        <div class="row mt-4">
          <div class="col-12 col-lg-12">

            <div class="page-header border-0 mb-0">
              <h1 class="page-title text-dark-l3 text-115">
                Réservation
                <i class="fa fa-angle-right text-80 ml-1"></i>
                <small class="page-info text-dark-m3">
                  #{{reservation.id}}
                </small>
              </h1>

              <div class="page-tools">
                <div class="action-buttons">
                  <a (click)="print()" class="btn bg-white btn-light mx-1px text-95 shadow-sm">
                    <i class="mr-1 fa fa-print text-primary text-120 w-2"></i>
                    Imprimer
                  </a>
                  <a (click)="createPdf()" class="btn bg-white btn-light mx-1px text-95 shadow-sm mouse-pointer">
                    <i class="mr-1 far fa-file-pdf text-danger text-120 w-2"></i>
                    Exporter en PDF
                  </a>
                </div>
              </div>
            </div>


            <div class="card dcard mb-4" id="bon">
              <div class="card-body px-4 px-lg-5">

                <div class="text-center">
                  <img height="100px" src="assets/images/logostrans.png">
                  <br>

                  <span class="text-dark-m3 text-140">
                       DEM DIK
                    </span>

                </div>

                <div class="row mt-4">
                  <div class="col-sm-6">
                    <div>
                      <div class="text-600 text-110 text-primary mt-2">
                        {{reservation.templatePrix.prestataire.nom}}
                      </div>

                      <div class="text-dark-l1">
                        <div class="my-1">
                          {{reservation.templatePrix.prestataire.adresse}}
                          , {{reservation.templatePrix.prestataire.ville}}
                          , {{reservation.templatePrix.prestataire.codepostal}}
                        </div>
                        <div class="my-1">
                          SIRET : {{reservation.templatePrix.prestataire.siret}}
                        </div>
                        <div class="my-1">
                        Tépléphone : {{reservation.templatePrix.prestataire.telephone}} / {{reservation.templatePrix.prestataire.telephonePortable}}
                      </div>

                      </div>
                    </div>
                  </div><!-- /.col -->


                  <div class="col-sm-6 align-self-start d-sm-flex justify-content-end text-95">
                    <hr class="d-sm-none">
                    <div class="text-dark-l1">
                      <div class="mt-1 mb-2 text-secondary-d1 text-600 text-125">
                        {{reservation.tad.collectivite?.nom}}
                      </div>
                      <div class="my-1">
                        Téléphone : {{reservation.tad.collectivite?.telephone}} / {{reservation.tad.collectivite?.telephonePortable}}
                      </div>
                      <div class="mt-1 mb-2 text-secondary-d1 text-600 text-125">
                        {{reservation.tad.nom}}
                      </div>

                      <div class="text-dark-l1">
                        <div class="my-1">
                          {{reservation.tad.adresse}}, {{reservation.tad.ville}}, {{reservation.tad.codepostal}}
                        </div>
                        <div class="my-1">
                          SIRET : {{reservation.tad.siret}}
                        </div>
                        <div class="my-1">
                          Téléphone : {{reservation.tad.telephone}} / {{reservation.tad.telephonePortable}}
                        </div>

                      </div>
                    </div>
                  </div><!-- /.col -->
                </div><!-- /.قخص -->

                <div class="mt-4 py-25 border-y-2 border-x-2 p-2  brc-green-l1">

                  <div class="row  text-95 text-secondary-d3">
                    <div class="d-none d-sm-block col-4 text-600">
                      Nom et prénom du passager
                    </div>
                    <div class="d-none d-sm-block col-8">
                      {{reservation.passagers[0].acteur.personne.nom}} {{reservation.passagers[0].acteur.personne.prenom}}
                    </div>
                  </div>

                  <div class="row  mt-1 text-95 text-secondary-d3">
                    <div class="d-none d-sm-block col-4 text-600">
                      Age
                    </div>
                    <div class="d-none d-sm-block col-8">
                      {{tool.getAge(reservation.passagers[0].acteur.personne.datenaissance)}}
                    </div>
                  </div>

                  <div class="row mt-1  text-95 text-secondary-d3">
                    <div class="d-none d-sm-block col-4 text-600">
                      Téléphone
                    </div>
                    <div class="d-none d-sm-block col-8">
                      {{reservation.passagers[0].acteur.personne.telephone1}} /                       {{reservation.passagers[0].acteur.personne.telephone2}}

                    </div>
                  </div>

                  <div class="row mt-1  text-95 text-secondary-d3">
                    <div class="d-none d-sm-block col-4 text-600">
                      Motif
                    </div>
                    <div class="d-none d-sm-block col-8">
                      {{reservation.motifTransport.libelle}}
                    </div>
                  </div>

                  <div class="row  mt-1 text-95 text-secondary-d3">
                    <div class="d-none d-sm-block col-4 text-600">
                      Transporteur
                    </div>
                    <div class="d-none d-sm-block col-8">
                      {{reservation.templatePrix.prestataire.nom}}
                    </div>
                  </div>

                  <div class="row  mt-1 text-95 text-secondary-d3">
                    <div class="d-none d-sm-block col-4 text-600">
                      Statut
                    </div>
                    <div class="d-none d-sm-block col-8">
                      <span class="badge badge-info" >{{reservation.statut.libelle}}</span>
                    </div>
                  </div>
                  <div class="row  text-95 text-secondary-d3">
                    <div class="d-none d-sm-block col-4 text-600">
                      Créé par
                    </div>
                    <div class="d-none d-sm-block col-8">
                      {{(!!reservation.createdBy) ? reservation.createdBy.acteur.personne.prenom + ' ' + reservation.createdBy.acteur.personne.nom : ''}}
                    </div>
                  </div>
                  <div class="row  text-95 text-secondary-d3">
                    <div class="d-none d-sm-block col-4 text-600">
                      Validé par
                    </div>
                    <div class="d-none d-sm-block col-8">
                      {{(!!reservation.validatedBy) ? reservation.validatedBy.acteur.personne.prenom + ' ' + reservation.validatedBy.acteur.personne.nom : ''}}
                    </div>
                  </div>


                </div>

                <div class="mt-4">

                  <div class="py-25 border-t-2 border-x-2 p-2 text-center brc-green-l1">
                    <span class="text-600">Départ le </span> {{reservation.reservationDate}}
                  </div>
                  <div class="border-t-2 border-x-2 p-0 brc-green-l1">
                    <div class="row">
                      <div class="col-6 p-2  brc-green-l1 border-r-2 text-center">
                        <span class="text-600">Départ </span>
                      </div>

                      <div class="col-6 p-2  brc-green-l1 text-center">
                        <span class="text-600">Arrivée </span>
                      </div>
                    </div>
                  </div>

                  <div class="border-t-2 border-b-2 border-x-2 p-0 brc-green-l1">
                    <div class="row">
                      <div class="col-6 p-2  brc-green-l1 border-r-2 text-center">
                        <table class="table table  table-borderless">
                          <tbody>
                          <tr>


                            <td class="text-95 text-600 text-secondary-d2">
                              Adresse
                            </td>

                            <td class="text-dark-m3">
                              {{reservation.passagers[0].adresseDepart}}
                            </td>
                          </tr>

                          <ng-container *ngIf="reservation.passagers[0].contacts.length > 0 ">

                            <ng-container *ngFor="let contact of reservation.passagers[0].contacts">


                              <ng-container *ngIf="contact.type == 'DEPART'">

                                <tr>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Contact
                                  </td>

                                  <td class="text-dark-m3">
                                    {{contact.personne.prenom + ' ' + contact.personne.nom}}
                                  </td>
                                </tr>


                                <tr>


                                  <td class="text-95 text-600 text-secondary-d2">
                                    Fixe
                                  </td>

                                  <td class="text-dark-m3">
                                    {{contact.personne.telephone1}}
                                  </td>
                                </tr>

                                <tr>


                                  <td class="text-95 text-600 text-secondary-d2">
                                    Portable
                                  </td>

                                  <td class="text-dark-m3">
                                    {{contact.personne.telephone2}}
                                  </td>
                                </tr>


                              </ng-container>


                            </ng-container>


                          </ng-container>


                          </tbody>
                        </table>

                      </div>

                      <div class="col-6 p-2  brc-green-l1 text-center">

                        <table class="table table  table-borderless">
                          <tbody>
                          <tr>


                            <td class="text-95 text-600 text-secondary-d2">
                              Adresse
                            </td>

                            <td class="text-dark-m3">
                              {{reservation.passagers[0].adresseDepart}}
                            </td>
                          </tr>

                          <ng-container *ngIf="reservation.passagers[0].contacts.length > 0 ">

                            <ng-container *ngFor="let contact of reservation.passagers[0].contacts">


                              <ng-container *ngIf="contact.type == 'ARRIVEE'">

                                <tr>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Contact
                                  </td>

                                  <td class="text-dark-m3">
                                    {{contact.personne.prenom + ' ' + contact.personne.nom}}
                                  </td>
                                </tr>


                                <tr>


                                  <td class="text-95 text-600 text-secondary-d2">
                                    Fixe
                                  </td>

                                  <td class="text-dark-m3">
                                    {{contact.personne.telephone1}}
                                  </td>
                                </tr>

                                <tr>


                                  <td class="text-95 text-600 text-secondary-d2">
                                    Portable
                                  </td>

                                  <td class="text-dark-m3">
                                    {{contact.personne.telephone2}}
                                  </td>
                                </tr>


                              </ng-container>


                            </ng-container>


                          </ng-container>


                          </tbody>
                        </table>


                      </div>
                    </div>
                  </div>


                </div>

                <div class="mt-4 col-12">
                  <div class="row text-600 text-95 text-secondary-d3 brc-green-l1 py-25 border-y-2">
                    <div class="d-none d-sm-block col-1">
                      #
                    </div>

                    <div class="col-7 col-sm-5">
                      Description
                    </div>

                    <div class="d-none d-sm-block col-4 col-sm-2">
                      Qté
                    </div>

                    <div class="d-none d-sm-block col-sm-2">
                      P.U
                    </div>

                    <div class="col-5 col-sm-2">
                      Montant
                    </div>
                  </div>

                  <div class="text-95 text-dark-m3">
                    <div class="row mb-2 mb-sm-0 py-25">
                      <div class="d-none d-sm-block col-1">
                        1
                      </div>

                      <div class="col-7 col-sm-5">
                        {{tarif}}
                      </div>

                      <div class="d-none d-sm-block col-2">
                        {{reservation.distance}}
                      </div>

                      <div class="d-none d-sm-block col-2 text-95">
                        {{tarifApplique}} €
                      </div>

                      <div class="col-5 col-sm-2 text-secondary-d3 text-600">
                        {{price | number: '1.0-2':'fr-FR' }}
                        €
                      </div>
                    </div>

                    <div class="row mb-2 mb-sm-0 py-25 bgc-green-l4">
                      <div class="d-none d-sm-block col-1">
                        2
                      </div>

                      <div class="col-7 col-sm-5">
                        Prise en charge
                      </div>

                      <div class="d-none d-sm-block col-2">
                        {{reservation.passagers.length}}
                      </div>

                      <div class="d-none d-sm-block col-2 text-95">
                        {{reservation.templatePrix?.priseenncharge}} €
                      </div>

                      <div class="col-5 col-sm-2 text-secondary-d3 text-600">
                        {{pricePec | number: '1.0-2':'fr-FR'}}
                        €
                      </div>
                    </div>


                  </div>

                  <div class="row border-b-2 brc-green-l1"></div>


                  <div class="row mt-4">
                    <div class="col-12 col-sm-7 mt-2 mt-lg-0">
                    </div>


                    <div class="col-12 col-sm-5 text-dark-l1 text-90 order-first order-sm-last">
                      <div class="row my-2">
                        <div class="col-7 text-right">
                          Total HTVA
                        </div>

                        <div class="col-5">
                                <span class="text-125 text-secondary-d3">
                                                                   {{total | number: '1.0-2':'fr-FR'}}
                                  €
                                    </span>
                        </div>
                      </div>



                    </div>
                  </div>


                </div>

              </div>
            </div>
          </div>
        </div>


      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" data-dismiss="modal" type="button">
          Fermer
        </button>


      </div>
    </div>
  </div>
</div>
