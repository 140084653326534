import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsreservationComponent} from "../detailsreservation.component";

declare var $;

@Component({
  selector: 'app-modalreservationretails',
  templateUrl: './modalreservationretails.component.html',
  styleUrls: ['./modalreservationretails.component.css']
})
export class ModalreservationretailsComponent implements OnInit {

  @ViewChild('detailsReservation') detailsReservation: DetailsreservationComponent;

  constructor() {
  }

  ngOnInit(): void {
  }


  show(reservation) {

    this.detailsReservation.showDetails(reservation);

    $('#modalDetailsReservation').modal('show');

  }

}
