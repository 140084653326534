<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Notifications
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Notifications reçues
      </small>
    </h1>

  </div>


  <div class="px-2">


    <div class="card dcard">
      <div class="card-body px-1 px-md-3">

        <form autocomplete="off">


          <hr class="brc-black-tp10 my-0">

          <div *ngFor="let alert of alerts| paginate : {
            itemsPerPage: tableSize,
            currentPage: page,
            totalItems: count};  let i = index;" [class.active]="i == currentIndex"
               class="message-item d-flex align-items-start bgc-h-primary-l4 px-2 px-md-3 py-25 radius-2px d-style pos-rel"
               role="button">
            <label class="mb-0 message-select-btn">

            </label>


            <a class="d-md-none position-br mr-1 mb-2px message-star-btn" href="#">
            </a>


            <div class="ml-3 d-flex flex-column flex-lg-row align-items-lg-center">
              <div class="message-user mb-1  w-45 mb-lg-0 col-auto px-0 text-95  text-dark-m2">
                {{alert.template?.libelle}}
                <span class="text-400 text-sm text-grey-m3 ml-1"></span>
              </div>
              <div class="message-text ml-lg-3 ml-lg-5 pr-1 pr-lg-0 text-90  text-dark-m3 pos-rel">


                <span
                  [ngClass]="{'bgc-red-l2 text-danger-d2': alert.template?.priorite == 'Haute', 'bgc-orange-l2 text-orange-d2': alert.template?.priorite == 'Moyenne', 'bgc-info-l2 text-info-d2': alert.template?.priorite == 'Basse'}"
                  class="badge  mr-1 radius-1">{{alert.template.priorite}}</span>

                {{alert.message}}
              </div>
            </div>


            <div
              class="message-time  d-lg-flex align-items-center ml-auto pl-2 col-auto text-nowrap pr-0 pl-1 text-90  text-dark-m1">
              {{alert.alertDate | date: 'd/MM/yyyy'}} {{alert.alertTime}}
            </div>


          </div>
          <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
            <div class="text-nowrap align-self-center align-self-sm-start">
                Afficher 1 - {{ tableSize }} of {{alerts.length}}
                <select (change)="tableData($event)" class="custom-select">
                    <option *ngFor="let size of tableSizesArr" [value]="size">
                        Afficher {{ size }}
                    </option>
                </select>
            </div>
            <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                    (pageChange)="tabSize($event)">
                </pagination-controls>
            </div>
            <div>

            </div>
        </div>
          <hr class="brc-black-tp10 my-0">


          <!-- table footer -->
        </form>

      </div><!-- /.card-body -->
    </div>


  </div>

</div>

<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>
