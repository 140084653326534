import { Component, OnInit, ViewChild } from '@angular/core';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { HttpClient } from '@angular/common/http';
import { StructureService } from 'src/app/services/structure.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CollectiivteStructureService } from 'src/app/services/collectivitestructure.service ';
import { FrmcollectiviteprestataireComponent } from '../frmcollectiviteprestataire/frmcollectiviteprestataire.component';
import { User } from 'src/app/shared/session/user';
import { DetailsfoyersComponent } from 'src/app/foyer/detailsfoyers/detailsfoyers.component';
import { PaginationService } from 'src/app/services/pagination.service';
import { SecurityService } from 'src/app/services/security.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
import Swal from 'sweetalert2';
import { FrmcollectivitefoyerComponent } from '../frmcollectivitefoyer/frmcollectivitefoyer.component';

@Component({
  selector: 'app-lstfoyercollectvites',
  templateUrl: './lstfoyercollectvites.component.html',
  styleUrls: ['./lstfoyercollectvites.component.css']
})
export class LstfoyercollectvitesComponent implements OnInit {

  datafoyer: any = [];
  datafoyers: any = [];
  structure: any = null;
  user: User = new User();
  siret = null;
  nom = null;
  ville = null;
  adresse = null;
  telephone = null;
  email = null;
  responsable = null;
  codePostal: any = '';
  typestructure: any = '';
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex: any;
  collectivites: any = [];
  collectivte = null;
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canDetails = false;
  idcollectivite = 0;
  typesfoyer= ['FOYER', 'FAMILLEACCUEIL'];

  @ViewChild('detailsfoyersComponent') detailsfoyersComponent: DetailsfoyersComponent;
  //@ViewChild('frmcollectivitetprestataire') frmcollectivitetprestataire: FrmcollectiviteprestataireComponent;
  @ViewChild('frmcollectivitetfoyer') frmcollectivitetfoyer: FrmcollectivitefoyerComponent;

  constructor(private http: HttpClient, private collectivitestructure: CollectiivteStructureService,
    private structureService: StructureService, private authenticationService: AuthenticationService,
    private paginationService: PaginationService, private securiteService: SecurityService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.user = this.authenticationService.getUser();
    this.filter(null, this.user);
    this.showCollectivite();
    this.getDroit();
  }
  filter(parent, col: any): void {
    this.spinner.show();
    if (this.user != null) {
      if (this.user.structure == 0) {
        this.collectivitestructure.getStructureByTypeStructureFA(this.typesfoyer).subscribe(data => {
          this.datafoyers = data;
          this.datafoyer = null;
          this.spinner.hide();
        });
      }
      else if (this.user.profilCode == "PF_AGCOL") {
        this.structureService.getStructureRattacheeFA(this.typesfoyer, this.user.structure).subscribe(data => {
          this.datafoyers = data;
          this.datafoyer = null;
          this.spinner.hide();
        });

      } else if (this.user.profilCode == "PF_GFOYER") {
        this.structureService.getStructureRattacheeCollectivite(this.user.structure).subscribe(data => {
          this.datafoyers = data;
          this.datafoyer = null;
          this.spinner.hide();
        });

      } else if (this.user.profilCode == "PF_TAD") {
        this.structureService.getStructureRattacheeFA(this.typesfoyer, this.user.parent).subscribe(data => {
          this.datafoyers = data;
          this.spinner.hide();
        });

      } else {
        this.structureService.getStructureRattacheeFoyerPrestataireFA(this.typesfoyer, this.user.structure).subscribe(data => {
          this.datafoyers = data;
          this.spinner.hide();
        });
      }

    }


  }
  openDetails(id: any): void {
    this.detailsfoyersComponent.details(this, id);
  }

  openFormCollectStructure(typestructure: any): void {
    this.frmcollectivitetfoyer.typestructure = typestructure;
    this.frmcollectivitetfoyer.lblstructure = 'Lieu d´hébergement';
    this.frmcollectivitetfoyer.collectiviteTad = 'Structure';
    if (this.user.structure != 0) {
      this.structureService.get(this.user.structure).subscribe(data => {
        this.frmcollectivitetfoyer.collectivite = data;
      });
      this.frmcollectivitetfoyer.selectedstructure = this.user.structure;
    }
    this.frmcollectivitetfoyer.show(this);

  }
  deleteCollctiviteStructure(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.collectivitestructure.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.filter(this, this.user.structure);
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car ce lieu d\'hebergement a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vraiment supprimer cet élément ? ')) {
    //   this.collectivitestructure.delete(id).subscribe(data => {
    //     this.filter(this, this.user.structure);
    //   });
    // }
  }

  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.datafoyers = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }

  tabSize(event) {
    this.page = event;
    //this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.showData();
  }
  showCollectivite(): void {
    this.structureService.getCollectiviteByProfil(this.user.id).subscribe(data => {
      this.collectivites = data;
    });

  }
  RechercheAvanceCollFoyer() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    //  this.collectivte = this.collectivte ? this.collectivte: null;
    if (this.idcollectivite == null)
      this.idcollectivite = 0;
    this.nom = this.nom ? this.nom : null;
    this.siret = this.siret ? this.siret : null;
    this.email = this.email ? this.email : null;
    this.adresse = this.adresse ? this.adresse : null;
    this.telephone = this.telephone ? this.telephone : null;
    this.responsable = this.responsable ? this.responsable : null;
    this.collectivitestructure.getRechercheBis(this.idcollectivite,TypeStructureModel.COLLECTIVITE,
       this.nom, this.siret, this.email, this.adresse,
      this.telephone, this.responsable, TypeStructureModel.FOYER, this.user.structure).subscribe(data => {
        this.datafoyers = data;
       // this.datafoyer = null;
        this.spinner.hide();
      });
      this.tabSize(event);

  }
  				
  reset(){
    this.idcollectivite = 0;
   }
   reset1(){
    this.siret = null;
   }
   reset2(){
    this.nom = null;
   }
   reset3(){
    this.email = null;
   }
   reset4(){
    this.adresse = null;
   }
   reset5(){
    this.responsable = null;
   }

   reset6(){
    this.telephone = null;
   }
  getDroit(): void {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_COLLFOYER").subscribe(data => {
      console.log(this.datafoyers)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_COLLFOYER").subscribe(data => {
      console.log(this.datafoyers)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_DETAILS_FOYER_COLLECTIVITE").subscribe(data => {
      console.log(this.datafoyers)
      if (!!data) {
        this.canDetails = true;
      } else {
        this.canDetails = false;
      }
    });

  }
}
