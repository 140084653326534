import { Component, OnInit } from '@angular/core';
import { FrmreservationComponent } from "../../../reservation/frmreservation/frmreservation.component";
import { HttpClient } from "@angular/common/http";
import { ActeurService } from "../../../services/acteur.service";
import { User } from "../../../shared/session/user";
import { SecurityService } from "../../../services/security.service";
import { AuthenticationService } from "../../../shared/services/authentication.service";
import { UsersComponent } from "../users.component";
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { tr } from 'date-fns/locale';

declare var $: any;


@Component({
  selector: 'app-frmuser',
  templateUrl: './frmuser.component.html',
  styleUrls: ['./frmuser.component.css']
})
export class FrmuserComponent implements OnInit {

  structure: any;
  parent: UsersComponent;
  acteurs: any = [];
  acteur: any;
  user: User = new User();
  acteurSelected: any;
  profils: any = [];
  profil: any;
  comfirmPassword: any;
  codeProfil ='';
  typeacteur: TypeActeurModel;

  errorActeur = false;
  errorProfile = false;
  errorEmail = false;
  errorPassword = false;
  errorConfirmPassword = false;
  errorFrm = false;
  infoErrorEmail = ""
  infoConfirmpassword = "";

  typestructure: ['FOYER', 'FAMILLEACCUEIL', 'TAD', 'PRESTATAIRE', 'COLLECTIVITE','ADMIN'];

  constructor(private http: HttpClient, private acteurService: ActeurService, private securityService: SecurityService, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }

  show(parent, structure) {
    this.parent = parent;
    this.structure = structure;
    this.getProfils();
    this.acteurs = this.getActeurs();
    if (this.acteur != null) {
      //  alert();
      $("#acteurUser").attr("disabled", true);
    } else {
      $("#acteurUser").attr("disabled", false);
    }
  }
  reset() {
    this.acteur = null;
    this.user = new User();
    this.comfirmPassword = null;
    // this.user.login = null;
    // this.user.password = null;
    // this.user.profil = null;
    // this.user = null;
  }
  fermer() {
    this.reset();
    $('#modalFormUser').modal('hide');
  }

  getActeurs() {
    //this.acteurService.getActeursByStructure(this.structure.id).subscribe(data => {
    //  this.acteurs = data;

    //});
    if (this.acteur) {
      this.acteurService.getActeur(this.acteur).subscribe(data => {
        this.acteurs = data;
        console.log(this.acteurs);
        $('#modalFormUser').modal('show');
        // this.collaborateur = null;
      });
    } else {
      this.acteurService.getActeurNonUtilisateur(this.typeacteur, this.structure?.id).subscribe(data => {
        this.acteurs = data;
        $('#modalFormUser').modal('show');
        // this.collaborateur = null;
      });
    }

  }

  getContact() {
    //this.acteurService.getActeursByStructure(this.structure.id).subscribe(data => {
    //  this.acteurs = data;

    //});
    if (this.acteur) {
      this.acteurService.getActeur(this.acteur).subscribe(data => {
        this.acteurs = data;
        console.log(this.acteurs);
        $('#modalFormUser').modal('show');
        // this.collaborateur = null;
      });
    } else {
      this.acteurService.getActeurNonUtilisateur(TypeActeurModel.CONTACT, this.structure?.id).subscribe(data => {
        this.acteurs = data;
        $('#modalFormUser').modal('show');
        // this.collaborateur = null;
      });
    }

  }

  getChauffeur() {
    //this.acteurService.getActeursByStructure(this.structure.id).subscribe(data => {
    //  this.acteurs = data;

    //});
    if (this.acteur) {
      this.acteurService.getActeur(this.acteur).subscribe(data => {
        this.acteurs = data;
        console.log(this.acteurs);
        $('#modalFormUser').modal('show');
        // this.collaborateur = null;
      });
    } else {
      this.acteurService.getActeurNonUtilisateur(TypeActeurModel.CHAUFFEUR, this.structure?.id).subscribe(data => {
        this.acteurs = data;
        $('#modalFormUser').modal('show');
        // this.collaborateur = null;
      });
    }

  }
  getProfils() {
 //   this.securityService.getProfils().subscribe(data => {
  //    this.profils = data;
  //  });

  this.securityService.getProfilsByCode(this.codeProfil).subscribe(data => {
       this.profils = data;
      });
  }


  setEmail() {
    this.acteurService.get(this.acteur).subscribe(data => {
      this.acteurSelected = data;
      this.user.login = this.acteurSelected.personne.email;
      this.errorEmail = false;
    });
  }

  save() {
    // console.log("user = ",this.user)
    // if(!this.user?.id)
    if (!this.valideActeur() && !this.valideEmail() && !this.validePassword() && !this.valideProfil() && !this.valideConfirmPassword()) {
      this.user.acteur.id = this.acteur;
      this.authenticationService.register(this.user)
        .then(() => this.parent.getOrg())
        .catch((message) => {
         // alert('Erreur lors de l enregistrement');
        });
      this.reset();
      $('#modalFormUser').modal('hide');
    }
  }

  edit(parent, user: any): void {
    if (user != null) {
      this.user = user;
      this.comfirmPassword = user.password;
      this.acteur = user.acteur?.id;
      this.structure = user?.acteur?.structure;

    }
   // alert(this.structure);
    this.show(parent, this.structure);
    $('#modalFormUser').modal('show');
  }

  valideActeur() {
    this.errorActeur = false;
    if (!this.acteur) {
      this.errorActeur = true;
    }
    return this.errorActeur;
  }
  valideProfil() {
    this.errorProfile = false;
    if (!this.user.profil.code) {
      this.errorProfile = true;
    }
    return this.errorProfile;
  }
  validePassword() {
    this.errorPassword = false;
    if (!this.user.password) {
      this.errorPassword = true;
    }
    return this.errorPassword;
  }
  valideConfirmPassword() {
    this.errorConfirmPassword = false;
    if (!this.comfirmPassword) {
      this.errorConfirmPassword = true;
      this.infoConfirmpassword = 'Ce champs est obligatoire.';
    } else {
      if (this.comfirmPassword != this.user.password) {
        this.errorConfirmPassword = true;
        this.infoConfirmpassword = ' Attention! incohérence des password.';
      }
    }
    return this.errorConfirmPassword;
  }
  valideEmail() {
    this.errorEmail = false;
    var validRegex = "^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$";
    if (!this.user.login) {
      this.infoErrorEmail = "Le champs email est obligatoire."
      this.errorEmail = true;
    } else {
      if (!this.user.login.match(validRegex)) {

        this.infoErrorEmail = "L'email saisi est invalide.";
        this.errorEmail = true;
      }
    }
    return this.errorEmail;
  }

}
