import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceService} from './service.service';
import {AppModule} from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class SecurityService extends ServiceService {
  httpx: HttpClient;
  module = 'system/security/';
  constructor(http: HttpClient) {
    super(http, 'system/security');
    this.httpx = http;
  }

  getProfils() {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + 'profils' );
  }
  getProfilsByCode(codeProfil:any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + 'profils/'+codeProfil );
  }
  getModules() {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + 'modules' );
  }

  getModulesByProfif(codeProfil) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + 'modulesbyprofil/'+codeProfil  );
  }

  getFeatures(moduleCode) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + 'features/' + moduleCode );
  }

  getActions(modCode, pfCode) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + 'action/' + modCode  + '/' + pfCode);
  }

  getMenu(pfCode) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + 'menu/' + pfCode );
  }

  configure(headers, body) {
    return this.httpx.post(AppModule.API_ENDPOINT + this.module + '/configure/' , body, { headers });
  }

  getUsers(structId) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + '/users/' + structId );
  }

  getUsersProfil(structId,codeProfil) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + '/users/' + structId+ '/' + codeProfil );
  }
  getDroit(profil:any,codeAction:any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + 'droit/' + profil+ '/' + codeAction );
  }

  getDroit1(profil:any,codeAction:any,feature:any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + 'droit/' + profil+ '/' + codeAction +'/'+feature);
  }

  getDroit12(profil:any,codeAction:any,feature:any,module:any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + 'droit/' + profil+ '/' + codeAction +'/'+feature+'/'+module);
  }
  updateUser(headers, body, id: any) {
    return this.httpx.put(AppModule.API_ENDPOINT + this.module +'updateUser/' + id, body, { headers });
  }

  update(headers, body, id: any) {
    return this.httpx.put(AppModule.API_ENDPOINT + this.module +'edit/' + id, body, { headers });
  }


}
