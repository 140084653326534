<div aria-hidden="true" class="modal fade modal-ms " id="modalFormDuplicate" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Dupliquer réservation
        </h5>

        <button aria-label="Close" class="close" (click)="close();"  type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">


        <form autocomplete="off">

          <div class="row">

            <div class="col-md-6">

              <div class="text-dark-l1">
                <div class="mt-1 mb-2 text-secondary-d1 text-600 text-125">
                  Course de référence
                </div>


                <table class="table table table-striped-default table-borderless">
                  <tbody>
                  <tr>
                    <td>
                      <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                    </td>

                    <td class="text-95 text-600 text-secondary-d2">
                      Numéro
                    </td>

                    <td class="text-dark-m3">
                       <span>
                                     #{{reservation?.id}}
                                </span>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                    </td>

                    <td class="text-95 text-600 text-secondary-d2">
                      Départ
                    </td>

                    <td class="text-dark-m3">
                       <span>
                                     {{reservation?.reservationDate}}
                                </span>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                    </td>

                    <td class="text-95 text-600 text-secondary-d2">
                      Créée par
                    </td>

                    <td class="text-dark-m3">
                       <span>
                                     {{reservation?.createdBy?.acteur.personne.prenom}} {{reservation?.createdBy?.acteur.personne.nom}}
                                </span>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                    </td>

                    <td class="text-95 text-600 text-secondary-d2">
                      Validée par
                    </td>

                    <td class="text-dark-m3">
                       <span>
                                     {{reservation?.validatedBy?.acteur.personne.prenom}} {{reservation?.validatedBy?.acteur.personne.nom}}
                                </span>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <i class="fa fa-user text-warning"></i>
                    </td>

                    <td class="text-95 text-600 text-secondary-d2">
                      Passager
                    </td>

                    <td class="text-dark-m3">
                       <span>
                                     {{passager?.acteur.personne.prenom}} {{passager?.acteur.personne.nom}}
                                </span>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                    </td>

                    <td class="text-95 text-600 text-secondary-d2">
                      Motif
                    </td>

                    <td class="text-dark-m3">
                      <span class="badge bgc-orange-d1 text-white badge-pill px-25">
                                    {{reservation?.motifTransport?.libelle}}
                                </span>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <i class="fa fa-car text-blue-m2"></i>
                    </td>

                    <td class="text-95 text-600 text-secondary-d2">
                      Adresse départ
                    </td>

                    <td class="text-dark-m3">
                      {{passager?.adresseDepart}}
                    </td>

                  </tr>

                  <tr>
                    <td>
                      <i class="fa fa-flag text-warning"></i>
                    </td>

                    <td class="text-95 text-600 text-secondary-d2">
                      Adresse arrivée
                    </td>

                    <td class="text-dark-m3">
                      {{passager?.adresseArrivee}}
                    </td>

                  </tr>


                  </tbody>
                </table>

              </div>


            </div>

            <div class="col-md-6">


              <full-calendar #mycal [options]="calendarOptions" class="duplicate-cal" id="fc"></full-calendar>


              <button (click)="refresh()" id="btnRefresh" style="visibility: hidden"></button>

            </div>

          </div>


        </form>


      </div>

      <div class="modal-footer">
        <button (click)="close();" class="btn btn-secondary" type="button">
          Fermer
        </button>
        <button (click)="save()" class="btn btn-primary" id="btnCreateDup" type="button">
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>

