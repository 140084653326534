import {Component, OnInit, ViewChild} from '@angular/core';
import {AppModule} from "../app.module";
import {User} from "../shared/session/user";
import {Famille} from "../shared/classes/famille";
import {DossiersService} from "../shared/services/dossiers.service";
import {NgxSpinnerService} from "ngx-spinner";
import {AuthenticationService} from "../shared/services/authentication.service";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-dossier',
  templateUrl: './dossier.component.html',
  styleUrls: ['./dossier.component.css']
})
export class DossierComponent implements OnInit {

  dossier: any;
  documents = [];


  appServer = AppModule.APP_SERVER;
  apiEndPoint = AppModule.API_ENDPOINT_V2;
  user: User;
  numero: any;

  apercu: any;

  famille: Famille[] = [];
  payments;


  // @ts-ignore
  @ViewChild('viewDoc') viewDoc: ViewDocComponent;
  constructor(private route: ActivatedRoute, private dossiersService: DossiersService, private spinner: NgxSpinnerService, private authService: AuthenticationService , private saniter: DomSanitizer) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();


    this.route.params.subscribe(params => {
      console.log(params.numero);
      if (!!params && !!params.numero) {

        this.numero = params.numero;

        this.getDossier();

      }

    });
    // this.dossiers.push(this.dossier);


  }

  viewDocument(doc) {
    this.viewDoc.show(doc);
  }

  getDossier() {
    this.dossiersService.getDossier(this.numero).subscribe(dossier => {
      // @ts-ignore
      this.dossier  = dossier;

      this.getPaiements();
    });
  }

  getPaiements() {
    this.dossiersService.getPaiements(this.dossier.id).subscribe(payments => {
      this.payments = payments;
    });
  }

}
