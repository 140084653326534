import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DetailstadComponent } from 'src/app/collectivite/detailstad/detailstad.component';
import { FrmtadComponent } from 'src/app/collectivite/frmtad/frmtad.component';
import { ActeurService } from 'src/app/services/acteur.service';
import { BudgetService } from 'src/app/services/budget.service ';
import { FoyerService } from 'src/app/services/foyer.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { SecurityService } from 'src/app/services/security.service';
import { StructureService } from 'src/app/services/structure.service';
import { VilleService } from 'src/app/services/ville.service';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/session/user';
declare var $: any;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lsttads',
  templateUrl: './lsttads.component.html',
  styleUrls: ['./lsttads.component.css']
})
export class LsttadsComponent implements OnInit {
  data: any = [];
  tad: any = null;
  datapensionnaires: any = [];
  pensionnaire: any = null;
  tadSelected: any;

  collectivites: any = [];

  siret = null;
  nom = null;
  ville = null;
  adresse = null;
  telephone = null;
  email = null;
  typestructure = TypeStructureModel.TAD;
  responsable = null;
  collectivite;
  codepostal;

  user: User = new User();
  col: any;
  personne;
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizesArr = [10, 20, 30];
  currentIndex = -1;
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canDetails = false;
  test: number = 0;
  idcollectivite = 0;
  collectivitestemp: any = [];

  @ViewChild('frmtadComponent') frmtadComponent: FrmtadComponent;
  //@ViewChild('detailCollectivite') detailCollectivite: DetailscollectiviteComponent;
  @ViewChild('detailsTad') detailsTad: DetailstadComponent;
  constructor(private http: HttpClient,
    private structureService: StructureService, private villeService: VilleService
    , private foyerService: FoyerService, private acteurService: ActeurService
    , private budgetService: BudgetService, private authenticationService: AuthenticationService,
    private paginationService: PaginationService, private securiteService: SecurityService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.col = this.user.structure;
    this.personne = this.user.personne;
    this.show();
    this.getDroit();
    this.showCollectivite();
//this.showCount();

  }
  showCollectivite(): void {
    this.structureService.getCollectiviteByProfil(this.user.id).subscribe(data => {
      this.collectivitestemp = data;
    });
  }
  RechercheAvance() {
    this.spinner.show();
    $(".collapse").collapse('hide');
    if (this.idcollectivite == null)
      this.idcollectivite = 0;
    this.nom = this.nom ? this.nom : null;
    this.siret = this.siret ? this.siret : null;
    this.email = this.email ? this.email : null;
    this.adresse = this.adresse ? this.adresse : null;
    this.telephone = this.telephone ? this.telephone : null;
    this.responsable = this.responsable ? this.responsable : null;
      this.structureService.getRechercheTadBIS(this.idcollectivite,this.nom, this.siret, 
        this.email, this.adresse, this.telephone,
        this.responsable, TypeStructureModel.TAD,this.user.id).subscribe(data => {
          this.data = data;
       //   this.tad = null;
          this.spinner.hide();
        });
        this.tabSize(event);
  }
  reset1(){
    this.idcollectivite = 0;
   }
   reset2(){
    this.nom = null;
   }
   reset3(){
    this.responsable = null;
   }
  openDetailsTad(col: any) {
    this.detailsTad.codeFeatureTad = 'FEA_TAD'
    this.detailsTad.filter(this, col);
    //alert(col)
  }

  show(): void {
    this.spinner.show();
    if (this.user.structure == 0) {
      this.structureService.getStructuresByType(TypeStructureModel.TAD).subscribe(data => {
        this.data = data;
        this.data.forEach(aTad => {
          
          this.test= this.showCount(aTad.id);
         // console.log("aTad", aTad);
        });
        this.tad = null;
        this.spinner.hide();
      });
    } else {
      if (this.user.profilCode == "PF_AGCOL") {
        this.foyerService.getTadByCollectivite(this.col).subscribe(data => {
          this.data = data;
          this.tad = null;
          this.spinner.hide();
        });
      } else if (this.user.profilCode == "PF_PRESTAIRE") {
        this.structureService.getCollectivitesRattachee(TypeStructureModel.TAD, this.col).subscribe(data => {
          this.data = data;
          this.tad = null;
          this.spinner.hide();
        });
      } else if (this.user.profilCode == "PF_GFOYER") {
        this.structureService.getTadRattacheeFoyerPrestataire(TypeStructureModel.TAD, this.col).subscribe(data => {
          this.data = data;
          this.tad = null;
          this.spinner.hide();
        });
      } else {
        this.structureService.getTads(TypeStructureModel.TAD, this.personne).subscribe(data => {
          this.data = data;
          this.tad = null;
          this.spinner.hide();
        });
      }

    }


  }


  delete(id: any): void {
    Swal.fire({
      title: 'Etes vous sûr de vouloir supprimer?',
      text: 'Vous ne pourrez pas récupérer ce fichier !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: 'Non, Annuler'
    }).then((result) => {
      if (result.value) {
        this.structureService.delete(id).subscribe(data => {
          Swal.fire({ icon: 'success', text: 'Suppression réussie' });
          this.show();
          this.tabSize(event);
        },
          err => {
            Swal.fire({ icon: 'error', text: "La suppression a échoué, car ce tad a déjà été utilisé ailleurs" });
          }
        );
      }
    })
    // if (confirm('Voulez vous vraiment supprimer cet élément ? ')) {
    //   this.structureService.delete(id).subscribe(data => {
    //     this.show();
    //   });
    // }
  }

  openFormTad() {
    // if (this.user.structure != 0) {
    //   // alert()
    //   this.structureService.getCollectivite(this.user.structure).subscribe(data => {
    //     this.frmtadComponent.collectivite = data;
    //   });
    //   this.frmtadComponent.selectedcol = this.user.structure
    // }

    if (this.user.profilCode == "PF_TAD") {
      this.structureService.getCollectiviteByProfil(this.user.id).subscribe(data => {
        this.frmtadComponent.collectivite = data; 
      });
     
    }
    this.frmtadComponent.lblnomtad = 'Ajout Unités';
   
    this.frmtadComponent.show(this);
    
  }

  edit(id: any) {
    //alert()
    this.frmtadComponent.edit(this, id);
    this.frmtadComponent.lblnomtad = 'Modification Unités'
  }

  showData(): void {
    this.paginationService.fetchPosts()
      .subscribe(
        res => {
          this.data = res;
          console.log(res);
        },
        err => {
          console.log(err);
        });
  }
  tabSize(event) {
    this.page = event;
    // this.showData();
  }

  tableData(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    //  this.showData();
  }


  getRequestParams(searchTitle: string, page: number, tableSize: number): any {
    // tslint:disable-next-line:prefer-const
    let params: any = {};

    if (page) {
      params[`page`] = page - 1;
    }

    if (tableSize) {
      params[`size`] = tableSize;
    }

    return params;
  }


  getDroit(): void {
    this.securiteService.getDroit(this.user.profilCode, "ACT_ADD_TAD").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canCreate = true;
      } else {
        this.canCreate = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_EDIT_TAD").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canUpdate = true;
      } else {
        this.canUpdate = false;
      }
    });
    this.securiteService.getDroit(this.user.profilCode, "ACT_DEL_TAD").subscribe(data => {
      console.log(this.data)
      if (!!data) {
        this.canDelete = true;
      } else {
        this.canDelete = false;
      }
    });

    this.securiteService.getDroit(this.user.profilCode, "ACT_DETAILS_TAD").subscribe(data => {
    //  console.log(this.data)
      if (!!data) {
        this.canDetails = true;
      } else {
        this.canDetails = false;
      }
    });

  }

  showCount(tad:any): number {
    let nbpassager:any;
    this.acteurService.getNbActeurRattachee(TypeActeurModel.PENSIONNAIRE, tad).subscribe(data => { 
      nbpassager = data; 
      //console.log("Nb Passager =",nbpassager)  
    });
    return nbpassager;
  }

  CountPrestataire(tad:any): void {
    this.acteurService.getActeurRattachee(TypeActeurModel.PENSIONNAIRE, this.personne).subscribe(data => {
      this.datapensionnaires = data;
      this.pensionnaire = null;
    });
  }

}
