import {Component, OnInit, ViewChild} from '@angular/core';
import {Demand} from "../../shared/classes/demand";
import {AppModule} from "../../app.module";
import {User} from "../../shared/session/user";
import {DossiersService} from "../../shared/services/dossiers.service";
import {NgxSpinnerService} from "ngx-spinner";
import {AuthenticationService} from "../../shared/services/authentication.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-lstdossiers',
  templateUrl: './lstdossiers.component.html',
  styleUrls: ['./lstdossiers.component.css']
})
export class LstdossiersComponent implements OnInit {

  dossiers: Demand[] = [];
  demand: any;
  documents = [];


  appServer = AppModule.APP_SERVER;
  apiEndPoint = AppModule.API_ENDPOINT_V2;
  user: User;

  critereDaterer: any = '1';


  // @ts-ignore
  @ViewChild('viewDoc') viewDoc: ViewDocComponent;

  constructor(private dossiersService: DossiersService, private spinner: NgxSpinnerService, private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.getDossiers();
  }

  getDossiers() {
    this.spinner.show();
    this.dossiersService.getDossiersByUser(this.user.id).subscribe(dossiers => {
      // @ts-ignore
      this.dossiers = dossiers;

      this.spinner.hide();

    });
  }

  showDetails(dossier: Demand) {

    this.demand = dossier;

  }

  valid(demand: any) {
    this.spinner.show();

    this.dossiersService.validate(this.demand.id, 13).subscribe(dossier => {
      this.getDossiers();

     // this.spinner.hide();
      Swal.fire({ icon: 'success', text: 'Demande accordé' });

      this.demand = null;

    });




  }

  viewDocument(doc) {
    this.viewDoc.show(doc);
  }

  viewDetails() {
    window.location.reload();
  }

  rechercheAvance() {

    this.spinner.show();
   // window.location.reload();
  }
}
