import {Component, OnInit} from '@angular/core';
import {TemplatesComponent} from "../templates/templates.component";
import {AlerteService} from "../../services/alerts.service";
import {NgxSpinnerService} from "ngx-spinner";
import {SecurityService} from "../../services/security.service";
import Swal from "sweetalert2";

declare var $;


@Component({
  selector: 'app-frm-template-alert',
  templateUrl: './frm-template-alert.component.html',
  styleUrls: ['./frm-template-alert.component.css']
})
export class FrmTemplateAlertComponent implements OnInit {

  template: any;
  id: any;
  libelle: any;
  message: any;
  type: any;
  priorite: any;
  delai: any;
  priorites: any = ['Basse', 'Moyenne', 'Haute'];
  types: any = [];
  parent: TemplatesComponent;
  profils: any = [];
  selectedProfils: any = [];

  constructor(private alerteService: AlerteService, private spinner: NgxSpinnerService, private securityService: SecurityService) {
  }

  ngOnInit(): void {



    this.alerteService.getTypes().subscribe(types => {
      this.types = types;
    });

    this.securityService.getProfils().subscribe(profils => {
      this.profils = profils;
    });

    $('#popover-2')
      .popover({
        container: 'body',
        trigger: 'focus',
        html: true,
        width: 600,
        template: '<div class="popover brc-primary-m2 border-b-2" style="width:600px" role="tooltip"><div class="arrow arrow2 brc-primary-l2"></div><div class="arrow brc-primary-m1"></div><h3 class="popover-header bgc-primary-l2 border-0 text-110 text-dark-tp3 text-600"></h3><div class="popover-body text-grey-d3 text-105"></div></div>'
      });

  }

  save() {

    this.selectedProfils = $("#profils").val().join(',');

    if(!this.isValid())
      return;
    this.spinner.show();
    let headers = {};
    let body = {
      id: null,
      libelle: this.libelle,
      message: this.message,
      type: {id: this.type},
      priorite: this.priorite,
      delai: this.delai,
      profils: this.selectedProfils
    };

    if (!!this.id) {
      body.id = this.id;

      this.alerteService.updateTemplate(headers, body, this.id).subscribe(template => {
        this.parent.getTemplates();
        this.hide();
        this.spinner.hide();
      });

    } else {
      this.alerteService.saveTemplate(headers, body).subscribe(template => {
        this.parent.getTemplates();
        this.hide();
        this.spinner.hide();
      });
    }
  }

  show(template: any, parent: TemplatesComponent) {
    this.selectedProfils = [];
    this.spinner.show();
    this.parent = parent;
    if (!!template) {
      this.template = template;
      this.id = template.id;
      this.type = template.type.id;
      this.priorite = template.priorite;
      this.delai = template.delai;
      this.message = template.message;
      this.libelle = template.libelle;
      this.selectedProfils = template.profils?.split(',');
    }

    $('#modalFormTemplate').modal('show');
    $('.chosen-select').chosen({
      allow_single_deselect: true,
      placeholder_text_multiple: "Selectionner les profils",

    });

    // this.selectedProfils = "PF_ADMIN,PF_CHAUFFEUR,PF_CONTACT,PF_GFOYER".split(',');
    $('#profils').val(this.selectedProfils).trigger('chosen:updated');


    this.spinner.hide();
  }

  hide() {

    this.reset();

    this.securityService.getProfils().subscribe(profils => {
      this.profils = profils;
    });


    $('#modalFormTemplate').modal('hide');


  }

  reset() {
    this.id = null;
    this.type = null;
    this.priorite = null;
    this.delai = null;
    this.message = null;
    this.libelle = null;
    $('.chosen-select option').prop('selected', false).trigger('chosen:updated');
    $(".chosen-select").val(['']).trigger("chosen:updated");

  }

  isValid() {
    if(!this.libelle || !this.selectedProfils  || !this.message || !this.priorite || !this.type ) {
      Swal.fire({
        icon: 'error',
        text: 'Vous devez renseigner tous les champs'
      });
      return false
    }
    return true ;
  }
}
