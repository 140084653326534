<div class="modal fade modal-ds" data-backdrop="static" id="modalFormUser" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Nouvel utilisateur
        </h5>

        <button type="button" class="close" (click)="fermer()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="card ccard">

          <div class="card-body px-0 py-2">

            <form class="mt-lg-3" autocomplete="off">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Acteur
                    <label style="color:red">
                      *
                    </label>
                  </label>
                </div>

                <div class="col-sm-9">
                  <select class="form-control" id="acteurUser" [(ngModel)]="acteur" (change)="valideActeur()"
                    name="acteur" (ngModelChange)="setEmail()">
                    <option value="">&nbsp;</option>

                    <option *ngFor="let act of acteurs;" value="{{act.id}}">{{act.personne.prenom + ' ' +
                      act.personne.nom }}</option> 
                  </select>
                  <span *ngIf="errorActeur==true" style="color: rgb(168, 54, 45);font-size: small;">
                    Le champs acteur est obligatoire
                  </span>
                </div>
              </div>


              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Profil
                    <label style="color:red">
                      *
                    </label>
                  </label>
                </div>

                <div class="col-sm-9">
                  <select class="form-control" (change)="valideProfil()" [(ngModel)]="user.profil.code" name="profil">
                    <option value="">&nbsp;</option>

                    <option *ngFor="let pf of profils;" value="{{pf?.code}}">{{pf.name}}</option>

                  </select>
                  <span *ngIf="errorProfile==true" style="color: rgb(168, 54, 45);font-size: small;">
                    Un profil est obligatoire
                  </span>
                </div>
              </div>




              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Email
                    <label style="color:red">
                      *
                    </label>
                  </label>
                </div>

                <div class="col-sm-9">
                  <input type="text" name="login" (change)="valideEmail()" [(ngModel)]="user.login"
                    class="form-control col-sm-8 col-md-8">

                  <span *ngIf="errorEmail==true" style="color: rgb(168, 54, 45);font-size: small;">
                    {{ infoErrorEmail }}
                  </span>
                </div>
              </div>



              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Mot de passe
                    <label style="color:red">
                      *
                    </label>

                  </label>
                </div>

                <div class="col-sm-9">
                  <input type="password" (change)="validePassword()" name="password" [(ngModel)]="user.password"
                    class="form-control col-sm-8 col-md-6">
                  <span *ngIf="errorPassword==true" style="color: rgb(168, 54, 45);font-size: small;">
                    Le mot de passe est obligatoire
                  </span>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-sm-right pr-0">
                  <label class="mb-0">
                    Confirmer mot de passe
                    <label style="color:red">
                      *
                    </label>
                  </label>
                </div>

                <div class="col-sm-9">
                  <input type="password" (change)="valideConfirmPassword()" [(ngModel)]="comfirmPassword"
                    name="password2" class="form-control col-sm-8 col-md-6">
                  <span *ngIf="errorConfirmPassword==true" style="color: rgb(168, 54, 45);font-size: small;">
                    {{infoConfirmpassword}}
                  </span>
                </div>
              </div>


            </form>

          </div>
        </div>




        <label class="d-none">
          <input type="checkbox" class="input-lg bgc-blue" checked="">
          Activer ?
        </label>



      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="fermer()">
          Fermer
        </button>
        <button type="button" (click)="save()" class="btn btn-primary">
          Enregistrer
        </button>

      </div>
    </div>
  </div>
</div>