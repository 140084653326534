import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TemplatePrixService} from 'src/app/services/templatePrix.service';
declare var $: any;

@Component({
  selector: 'app-detailstemplateprix',
  templateUrl: './detailstemplateprix.component.html',
  styleUrls: ['./detailstemplateprix.component.css']
})
export class DetailstemplateprixComponent implements OnInit {


  template;


  constructor(private http: HttpClient, private templateService: TemplatePrixService) {
  }

  ngOnInit(): void {
  }

  show(template) {
    this.template = template;
    $('#modalDetailsTemplate').modal('show');
  }
}
