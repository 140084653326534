import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../app.module';
import { ActeurModel } from '../shared/models/acteur-model.model';
import { Observable } from 'rxjs';
import { TypeActeurModel } from '../shared/models/type-acteur-model.model';
import { TypeStructureModel } from '../shared/models/type-structure-model.model';
import { FoyerModel } from '../shared/models/foyer-model.model';
import { ActeurStructureModel } from '../shared/models/acteurstructure-model';
import { PersonneService } from './personne.service ';
import { PersonneModel } from '../shared/models/personne-model.model';

@Injectable({
  providedIn: 'root'
})
export class ActeurService {

  constructor(private http: HttpClient) {
  }

  getData() {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/list');
  }

  getActeursByType(typeacteur: TypeActeurModel, typestructure: TypeStructureModel): Observable<ActeurModel[]> {
    console.log(JSON.stringify(AppModule.API_ENDPOINT + 'acteur/listacteur/' + typeacteur + '/' + typestructure));
    return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/listacteur/' + typeacteur + '/' + typestructure);
  }

getActeursByType1(typeacteur: ActeurStructureModel): Observable<ActeurModel[]> {
 // console.log(JSON.stringify(AppModule.API_ENDPOINT + 'acteur/getByTypeacteurStructureTypestructure/'+typeacteur));
 return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/getByTypeacteurStructureTypestructure/' + typeacteur);
}

getActeursByTypeActeur(typeacteur: TypeActeurModel): Observable<ActeurModel[]> {
  return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/list/' + typeacteur);
}

getActeursByTypeStructure(typestructure: TypeStructureModel): Observable<ActeurModel[]> {
  return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/listbytypestructure/' + typestructure);
}

getActeursByStructure(structure: FoyerModel): Observable<ActeurModel[]> {
  return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/listbystructure/' + structure);
}


  get(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/get/' + id); 
  }

  getActeur(id: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/getActeur/' + id);
  }

  save(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'acteur/create', body, { headers });
  }
  savePesonne(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'acteur/createPersonne', body, { headers });
  }
  updatePersonne(headers, body) {
    return this.http.post<any>(AppModule.API_ENDPOINT + 'acteur/updatePersonne', body, { headers });
  }

  update(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + 'acteur/update/' + id, body, { headers });
  }

  updateActif(headers, body, id: any) {
    return this.http.put(AppModule.API_ENDPOINT + 'acteur/updateactif/' + id, body, { headers });
  }


  delete(id: any) {
    return this.http.delete(AppModule.API_ENDPOINT + 'acteur/delete/' + id);
  }

  getActeurRattachee(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/rattachees/' + type + '/' + parent);
  }

  getActeursRat(type: any,structure: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/acteurstructuresrattachees/' + type + '/' + structure);
  }


  getActeurRattacheesAll(type: any, parent: any, typestructure: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/structurerattachees/' + type + '/' + parent + '/' + typestructure);
  }

  getActeurNonRattacheesAll(parent: any, typestructure: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/acteursrattachees/' + parent + '/' + typestructure);
  }

  getActeurNonRattachees(parent: any, typestructure: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/acteursnonrattachees/' + parent + '/' + typestructure);
  }

  getActeursnonaffecter(parent: any, typestructure: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/acteursnonaffecter/' + parent + '/' + typestructure);
  }
  
  getEnfantNonRattachees(parent: any, typestructure: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/enfantsnonrattachees/' + parent + '/' + typestructure);
  }

  getEnfantNonRattacheesFoyer() {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/enfantsnonrattacheFoyers');
  }

  getRecherche(prenom: any, nom: any, email: any, adresse: any, telephone: any,typeacteur:any,structure:any,typestructure:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/rechercheacteur/' + prenom + '/' + nom + '/' + email + '/' + adresse + '/' + telephone + '/' + typeacteur+ '/' + structure+ '/' + typestructure);
  }


  getActeurRattacheeByStructAnParent(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/rattacheesAll/' + type + '/' + parent);
  }

  getActeurFoyerTad(type: any,structure: any,typeacteur:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/rattacheesfoytads/' + type + '/'+ structure+ '/'+ typeacteur);
  }


  getActeurRatacheTad(parent: any): Observable<ActeurModel[]> {
    return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/rattacheesfoyertads/' + parent);
  }

  getPensionnaire(parent: any): Observable<ActeurModel[]> {
    return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/pensionnaire/' + parent);
  }

  getPensionnaireFoyerTad(parent: any,foyer:any): Observable<ActeurModel[]> {
    return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/pensionnairefoyer/' + parent+ '/'+ foyer);
  }

  getRechercheAV(prenom: any, nom: any, email: any, adresse: any, telephone: any,typeacteur:any,structure:any,typestructure:any,idUser:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/rattacheesacteurstructure/' + prenom + '/' + nom + '/' + email + '/' + adresse + '/' + telephone + '/' + typeacteur+ '/' + structure+ '/' + typestructure+'/'+idUser);
  }

  getPensioonnairesReservation(user: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/pensionnaireforreservation/' + user);
  }

  getNbActeurRattachee(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/countrattachees/' + type + '/' + parent);
  }

  getActeurNonUtilisateur(type: any, parent: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/rattacheesNonUtilisateur/' + type + '/' + parent);
  }
  getRechercheEnfantFoyer(prenom: any, nom: any, email: any, adresse: any, telephone: any,typeacteur:any,structure:any,typestructure:any,id:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/rechercheenfantfoyer/' + prenom + '/' + nom + '/' + email + '/' + adresse + '/' + telephone + '/' + typeacteur+ '/' + structure+ '/' + typestructure+'/'+id);
  }

  getPensionnairesByUser(user: any, search: any): Observable<ActeurModel[]> {
    return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/pensionnaires/' + user + '/' + search);
  }

  getPensionnairesByTad(tad: any, search: any): Observable<ActeurModel[]> {
    return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/pensionnairesTad/' + tad + '/' + search);
  }

  getPensionnairesByTadBis(tad: any, search: any, reservation: any): Observable<ActeurModel[]> {
    return this.http.get<ActeurModel[]>(AppModule.API_ENDPOINT + 'acteur/pensionnairesTad/' + tad + '/' + search +'/' + reservation);
  }


  getActeurRattacheeByStructAnParentSearch(type: any, parent: any, search: any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/rattacheesAllSearch/' + type + '/' + parent + '/' + search);
  }

  getActeurRattacheesAllRAS(type: any, parent: any, typestructure: any,id:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/structurerattacheesras/' + type + '/' + parent + '/' + typestructure+'/'+id);
  }

  getActeurAllTAD(type: any, parent: any, typestructure: any,id:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/acteurstad/' + type + '/' + parent + '/' + typestructure+'/'+id);
  }

  getRechercheEnfantFoyerBis(idstruc:any,prenom: any, nom: any, email: any, adresse: any, telephone: any,typeacteur:any,structure:any,typestructure:any,id:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/rechercheenfantfoyerbis/'+idstruc+'/' + prenom + '/' + nom + '/' + email + '/' + adresse + '/' + telephone + '/' + typeacteur+ '/' + structure+ '/' + typestructure+'/'+id);
  }

  getRechercheBis(idpresta:any,prenom: any, nom: any, email: any, adresse: any, telephone: any,typeacteur:any,structure:any,typestructure:any) {
    return this.http.get(AppModule.API_ENDPOINT + 'acteur/rechercheacteurbis/'+idpresta+'/' + prenom + '/' + nom + '/' + email + '/' + adresse + '/' + telephone + '/' + typeacteur+ '/' + structure+ '/' + typestructure);
  }
}
