 <div class="modal fade modal-ds" id="modalFormEditPassager" tabindex="-1"  role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" style="height: 600px!important;"  role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                  Ajouter heure de prise et de depose de l'enfant
                </h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="">
                    <div class="">

                        <form class="mt-lg-3 Needs-validation1" novalidate>
                          <div class="form-group row">
                            <div class="col-sm-4 col-form-label text-sm-right pr-0">
                              <label class="mb-0">
                                Heure de prise en charge
                              </label>
                            </div>
                            <div class="col-sm-6 input-group time">
                              <input [(ngModel)]="heurePriseEncharge" class="form-control" id="heurePriseEncharge" name="heurePriseEncharge"
                                     type="text"/>
                              <div class="input-group-addon input-group-append">
                                <div class="input-group-text">
                                  <i class="far fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div> 
                            
                          <div class="form-group row">
                            <div class="col-sm-4 col-form-label text-sm-right pr-0">
                              <label class="mb-0">
                                Heure de dépose
                              </label>
                            </div>
                            <div class="col-sm-6 input-group time">
                              <input [(ngModel)]="heureDepose" class="form-control" id="heureDepose" name="heureDepose"
                                     type="text"/>
                              <div class="input-group-addon input-group-append">
                                <div class="input-group-text">
                                  <i class="far fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="fermer()">
                        Fermer
                    </button>
                    <button type="button" (click)="savePassenger()" class="btn btn-primary">
                      Enregistrer
                    </button>

            </div>
        </div>
    </div>
</div>

  
