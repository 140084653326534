<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Sécurité
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Gestions des utilisateurs
      </small>
    </h1>

  </div>


  <div class="px-2">

    <div class="row">


      <div class="col-12 col-md-6 mt-3 mt-md-0">
        <div class="card bcard h-auto border-0">
          <div class="card-header bgc-default-d3">
          
          </div>
          <div class="radius-">
            <ul class="nav nav-tabs nav-tabs-faded bgc-info-m3 pt-2 pl-2 radius-t-1" role="tablist">
              <li  class="nav-item mr-3px">
                <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0 active"
                    data-toggle="tab" (click)="changerProfil('PF_ADMIN')" href="#admin" role="tab" aria-controls="admin" aria-selected="true">
                    Administrateur
                </a>
            </li>
            <li  class="nav-item mr-3px">
              <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0 "
                  data-toggle="tab" (click)="changerProfil('PF_AGCOL')" href="#collectivitetad" role="tab" aria-controls="prestataire12" aria-selected="false">
                  Structures / Tads
              </a>
          </li>
               
                <li  class="nav-item mr-3px">
                    <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0 "
                        data-toggle="tab" (click)="changerProfil('PF_PRESTAIRE')" href="#prestataire12" role="tab" aria-controls="prestataire12" aria-selected="false">
                        Prestataire
                    </a>
                </li>
        
                <li  class="nav-item mr-3px">
                    <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                        data-toggle="tab" (click)="changerProfil('PF_GFOYER')" href="#foyer12" role="tab" aria-controls="foyer12"
                        aria-selected="false">
                        Lieu d'hébergement
                    </a>
                </li>
                <li  class="nav-item mr-3px">
                  <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                      data-toggle="tab" (click)="changerProfil('PF_CONTACT')" href="#contact12" role="tab" aria-controls="contact12"
                      aria-selected="false">
                      Contact
                  </a>
              </li>
              <li  class="nav-item mr-3px">
                <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0"
                    data-toggle="tab" (click)="changerProfil('PF_CHAUFFEUR')" href="#chauffeur12" role="tab" aria-controls="chauffeur12"
                    aria-selected="false">
                    Chauffeur
                </a>
            </li>
             
            </ul>
        
            <div class="tab-content bgc-white p-35 border-1 border-t-0 brc-default-l2 radius-b-1">
              <div  class="tab-pane fade text-95 active show" id="admin" role="tabpanel"
                    style="overflow-y: scroll; height:400px;">
                    <div class="card dcard">
                        <div class="card-body px-1 px-md-3">
        
                            <form autocomplete="off">
                              <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
                                <div class="jqtree tree-dotted" id="id-jqtree-files0"></div>
                                <input type="hidden" id="orgId" name="orgId" [(ngModel)]="orgId" (input)="getOrg()">
                            </div>
                              
                            </form>
        
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
                <div  class="tab-pane fade text-95" id="collectivitetad" role="tabpanel"
                    style="overflow-y: scroll; height:400px;">
                    <div class="card dcard">
                        <div class="card-body px-1 px-md-3">
        
                            <form autocomplete="off">
                              <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
                                <div class="jqtree tree-dotted" id="id-jqtree-files"></div>
                                <input type="hidden" id="orgId" name="orgId" [(ngModel)]="orgId" (input)="getOrg()">
                            </div>
                              
                            </form>
        
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
                <div  class="tab-pane fade text-95" id="prestataire12" role="tabpanel"
                    style="overflow-y: scroll; height:400px;">
                    <div class="card dcard">
                        <div class="card-body px-1 px-md-3">
        
                            <form autocomplete="off">
                              <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
                                <div class="jqtree tree-dotted" id="id-jqtree-files1"></div>
                                <input type="hidden" id="orgId" name="orgId" [(ngModel)]="orgId" (input)="getOrg()">
                            </div>
                                
                            </form>
        
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
        
                <div  class="tab-pane fade text-95" id="foyer12" role="tabpanel"
                    style="overflow-y: scroll; height:400px;">
                    <div class="card dcard">
                        <div class="card-body px-1 px-md-3">
        
                            <form autocomplete="off">
                              <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
                                <div class="jqtree tree-dotted" id="id-jqtree-files2"></div>
                                <input type="hidden" id="orgId" name="orgId" [(ngModel)]="orgId" (input)="getOrg()">
                            </div>
                                
                            </form>
        
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
                
                <div  class="tab-pane fade text-95" id="contact12" role="tabpanel"
                    style="overflow-y: scroll; height:400px;">
                    <div class="card dcard">
                        <div class="card-body px-1 px-md-3">
        
                            <form autocomplete="off">
                              <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
                                <div class="jqtree tree-dotted" id="id-jqtree-files3"></div>
                                <input type="hidden" id="orgId" name="orgId" [(ngModel)]="orgId" (input)="getOrg()">
                            </div>
                                
                            </form>
        
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
                
                <div  class="tab-pane fade text-95" id="chauffeur12" role="tabpanel"
                    style="overflow-y: scroll; height:400px;">
                    <div class="card dcard">
                        <div class="card-body px-1 px-md-3">
        
                            <form autocomplete="off">
                              <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
                                <div class="jqtree tree-dotted" id="id-jqtree-files4"></div>
                                <input type="hidden" id="orgId" name="orgId" [(ngModel)]="orgId" (input)="getOrg()">
                            </div>
                                
                            </form>
        
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
            </div>
        </div>
             
        </div>
      </div>

      <div class="col-md-6 mt-3 mt-md-0">

        <ng-container *ngIf="!!selectedOrg">


          <form autocomplete="off">
            <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
              <h3 class="text-125 pl-1 mb-3 mb-sm-0 text-secondary-d4">
                Utilisateurs
              </h3>

              <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
              </div>

              <div class="mb-2 mb-sm-0">
                <button type="button" (click)="createUser()"
                  class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                  <i class="fa fa-plus mr-1"></i>
                  Créer un utilisateur <span class="d-sm-none d-md-inline"></span>
                </button>
              </div>
            </div>
            <div class="card w-100 mt-1 mb-1">
              <div class="card-body p-0">
                <div class="accordion">
                  <div class="card border-0 mt-1px bgc-primary-l5">
                    <div class="card-header border-0 bgc-transparent mb-0">
                      <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                        <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed"
                          href="#rechercheAvancee" data-toggle="collapse" aria-expanded="false"
                          aria-controls="rechercheAvancee">
                          Recherche avancée

                          <span
                            class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                            <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                          </span>
                          <span
                            class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                            <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                          </span>
                        </a>
                      </h2>
                    </div>
                    <div id="rechercheAvancee" class="collapse" aria-labelledby="headingTwo4"
                      data-parent="#rechercheAvancee" style="" (keyup.enter)="rechercheAvance()">
                      <div class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                        <div class="form-group row">

                          <div class="col-md-4">

                            <div class="form-group row">
                              <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                <label class="mb-0">
                                  Prénom/Nom
                                </label>
                              </div>

                              <div class="col-sm-9 pl-2">
                                <input [(ngModel)]="prenom" name="prenom" type="text" class="form-control">
                              </div>
                            </div>

                          </div>

                          <!-- <div class="col-md-4">

                            <div class="form-group row">
                              <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                <label class="mb-0">
                                  Nom
                                </label>
                              </div>

                              <div class="col-sm-8 pl-2">
                                <input [(ngModel)]="nom" name="nom" type="text" class="form-control">
                              </div>
                            </div>

                          </div> -->

                          <div class="col-md-4">

                            <div class="form-group row">
                              <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                <label class="mb-0">
                                  Email
                                </label>
                              </div>

                              <div class="col-sm-8 pl-2">
                                <input [(ngModel)]="login" name="login" type="text" class="form-control">
                              </div>
                            </div>

                          </div>


                        </div>


                        <div class="form-group row">

                          <div class="col-md-4">

                            <div class="form-group row">
                              <div class="col-sm-5 col-form-label text-sm-right pr-2 ">
                                <label class="mb-0">
                                  Profil
                                </label>
                              </div>

                              <div class="col-sm-8 pl-2">
                                <input [(ngModel)]="profil" name="profil" type="text" class="form-control">
                              </div>
                            </div>

                          </div>

                          <div class="col-md-4">

                            <div class="form-group row">


                              <div class="mb-2 mb-sm-0">
                                <button (click)="rechercheAvance()" type="button"
                                  class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                  <i class="fa fa-search mr-1"></i>

                                </button>
                              </div>
                            </div>

                          </div>

                        </div>


                      </div>
                    </div>
                  </div>



                </div>

              </div>
            </div>

            <table
              class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
              <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                <tr>
                  <th>
                    Prénom
                  </th>
                  <th>
                    Nom
                  </th>
                  <th>
                    Profil
                  </th>
                  <th class="d-none d-sm-table-cell">
                    Etat
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody class="mt-1">
                <ng-container *ngFor="let user of users| paginate : {
                  itemsPerPage: tableSize,
                  currentPage: page,
                  totalItems: count}; let i = index" [class.active]="i == currentIndex">
                  <tr class="bgc-h-yellow-l4 d-style">

                    <td>
                      <a class="text-blue-d1 text-600 text-95">{{user.acteur.personne.prenom}}</a>
                    </td>

                    <td>
                      <a class="text-blue-d1 text-600 text-95">{{user.acteur.personne.nom}}</a>
                    </td>

                    <td class="text-600 text-orange-d2">
                      {{user.profil.name}}
                    </td>
                    <td class="d-none d-sm-table-cell">
                      <input type="checkbox" class="ace-switch" (click)="actifDesactifUser(user)"
                        [checked]="user.actif">

                    </td>
                    <td class="text-center pr-0">
                      <div>
                        <a href="#" data-toggle="collapse" [attr.data-target]="'#detail-user-'+user.id"
                          class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                          title="Show Details" aria-expanded="false">
                          <span class="d-none d-md-inline mr-1">
                            Details
                          </span>
                          <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                        </a>
                      </div>
                    </td>
                    <td>
                      <!-- action buttons -->
                      <div class="d-none d-lg-flex">
                        <a href="#" (click)="edit(user)"
                          class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                          <i class="fa fa-pencil-alt"></i>
                        </a>

                        <a href="#" (click)="delete(user.id)"
                          class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                          <i class="fa fa-trash-alt"></i>
                        </a>

                      </div>

                      <!-- show a dropdown in mobile -->
                      <div class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                        <a href="#" class="btn btn-default btn-xs py-15 radius-round dropdown-toggle"
                          data-toggle="dropdown">
                          <i class="fa fa-cog"></i>
                        </a>

                        <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                          <div class="dropdown-inner">
                            <div
                              class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">

                            </div>
                            <a href="#" (click)="edit(user.id)" class="dropdown-item">
                              <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i> Modifier
                            </a>
                            <a href="#" (click)="delete(user.id)" class="dropdown-item">
                              <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i> Supprimer
                            </a>
                            <a href="#" class="dropdown-item">
                              <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i> Autre
                            </a>
                          </div>
                        </div>
                      </div>

                    </td>
                  </tr>
                  <tr class="border-0 detail-row bgc-white">
                    <td colspan="8" class="p-0 border-none brc-secondary-l2">
                      <div class="table-detail collapse" id="{{'detail-user-'+user.id}}">
                        <div class="row">
                          <div class="col-12 col-md-10 offset-md-1 p-4">
                            <div
                              class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                              <div class="bgc-white radius-1">
                                <table class="table table table-striped-default table-borderless">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <i class="fa fa-envelope text-primary"></i>
                                      </td>

                                      <td class="text-95 text-600 text-secondary-d2">
                                        Email
                                      </td>

                                      <td class="text-dark-m3">
                                        {{(!!user.acteur.personne) ? user.acteur.personne.email:''}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <i class="fa fa-phone text-success"></i>
                                      </td>

                                      <td class="text-95 text-600 text-secondary-d2">
                                        Téléphone Fixe
                                      </td>

                                      <td class="text-dark-m3">
                                        {{(!!user.acteur.personne) ? user.acteur.personne.telephone1:''}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <i class="fa fa-mobile text-success"></i>
                                      </td>

                                      <td class="text-95 text-600 text-secondary-d2">
                                        Téléphone Portable
                                      </td>

                                      <td class="text-dark-m3">
                                        {{(!!user.acteur.personne) ? user.acteur.personne.telephone2:''}}

                                      </td>
                                    </tr>

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>

            <!-- table footer -->
            <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
              <div class="text-nowrap align-self-center align-self-sm-start">
                Afficher 1 - {{ tableSize }} of {{users.length}}
                <select (change)="tableData($event)" class="custom-select">
                  <option *ngFor="let size of tableSizesArr" [value]="size">
                    Afficher {{ size }}
                  </option>
                </select>
              </div>
              <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                  (pageChange)="tabSize($event)">
                </pagination-controls>
              </div>
              <div>

              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<app-frmuser #frmuserComponent></app-frmuser>
