<div class="page-content">
    <div class="page-header border-0">
      <h1 class="page-title text-primary-d2 text-160">
        Template de prix
        <small class="page-info text-secondary-d3">
          <i class="fa fa-angle-double-right text-80"></i> 
          Liste des templates de prix
        </small>
      </h1>
  
    </div>
  
  
    <div class="px-2">
  
  
           <div class="tab-pane fade text-95 active show" id="home2" role="tabpanel">
                <div class="card dcard">
                  <div class="card-body px-1 px-md-3">
  
                    <form autocomplete="off">
                      <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">
  
  
                        <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                          <i class="fa fa-search position-lc ml-25 text-primary-m1"></i>
                          <input type="text" class="form-control w-100 pl-45 radius-1 brc-primary-m4" placeholder="Rechercher ...">
                        </div>
  
                        <div class="mb-2 mb-sm-0">
                          <button type="button" data-toggle="modal" data-target="#modalTemplatePrix" class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                            <i class="fa fa-plus mr-1"></i>
                            Ajouter <span class="d-sm-none d-md-inline"></span>
                          </button>
                        </div>
                      </div>
  
                      <table  class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                        <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                        <tr>
                          <th class="text-center pr-0">
                            <label class="py-0">
                              <input type="checkbox" class="align-bottom mb-n1 border-2 text-dark-m3">
                            </label>
                          </th>
  
                          <th>
                            periode de validité
                          </th>
                          <th>
                            Tarif Jour
                          </th>
  
                          <th>
                            Tarif nuit
                          </th>
  
                          <th>
                            Tarif weekend
                          </th>
  
                          <th>
                            Tarif personne
                          </th>
  
                          <th class="d-none d-sm-table-cell">
                            Statut
                          </th>
  
                          <th></th>
                          <th></th>
                        </tr>
                        </thead>
  
                        <tbody class="mt-1">
                        <tr *ngFor="let template of data;" class="bgc-h-yellow-l4 d-style">
                          <td class="text-center pr-0 pos-rel">
                            <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                              <!-- border shown on hover -->
                            </div>
                            <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                              <!-- border shown when row is selected -->
                            </div>
  
                            <label>
                              <input type="checkbox" class="align-middle">
                            </label>
                          </td>
  
                          <td>
                            <span  class="text-dark-d1 text-600 text-95">{{template.datedebut}} - {{template.datefin}} </span>
                          </td>
                          <td>
                            <a href="#" class="text-blue-d1 text-600 text-95">{{template.tarifjour}} €</a>
                          </td>
  
                          <td>
                            <a href="#" class="text-blue-d1 text-600 text-95">{{template.tarifnuit}} €</a>
                          </td>
  
                          <td>
                            <a href="#" class="text-blue-d1 text-600 text-95">{{template.tarifweekend}} €</a>
                          </td>
  
                          <td>
                            <a href="#" class="text-blue-d1 text-600 text-95">{{template.priseenncharge}} €</a>
                          </td>
  
  
  
                          <td class="d-none d-sm-table-cell">
                            <input type="checkbox" class="ace-switch" [checked]="template.actif" >
                          </td>
  
  
                          <td class="text-center pr-0">
                            <div>
                              <a href="#" data-toggle="collapse" data-target="#table-detail-2" class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed" title="Show Details" aria-expanded="false">
                                    <span class="d-none d-md-inline mr-1">
                        Details
                      </span>
                                <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                              </a>
                            </div>
                          </td>
  
                          <td>
                            <!-- action buttons -->
                            <div class="d-none d-lg-flex">
                              <a href="#" (click)="edit(template.id)"  class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                                <i class="fa fa-pencil-alt"></i>
                              </a>
  
                              <a href="#" (click)="delete(template.id)" class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                                <i class="fa fa-trash-alt"></i>
                              </a>
                              <a href="#" class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-warning btn-a-lighter-warning">
                                <i class="fa fa-ellipsis-v mx-1"></i>
                              </a>
                            </div>
  
                            <!-- show a dropdown in mobile -->
                            <div class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                              <a href="#" class="btn btn-default btn-xs py-15 radius-round dropdown-toggle" data-toggle="dropdown">
                                <i class="fa fa-cog"></i>
                              </a>
  
                              <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                <div class="dropdown-inner">
                                  <div class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                                    Prestataire 1
                                  </div>
                                  <a href="#" class="dropdown-item">
                                    <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                    Edit
                                  </a>
                                  <a href="#" class="dropdown-item">
                                    <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                    Delete
                                  </a>
                                  <a href="#" class="dropdown-item">
                                    <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                    Autre
                                  </a>
                                </div>
                              </div>
                            </div>
  
                          </td>
                        </tr>
  
                        <!-- detail row -->
                        <tr class="border-0 detail-row bgc-white">
                          <td colspan="8" class="p-0 border-none brc-secondary-l2">
                            <div class="table-detail collapse" id="table-detail-2" style="">
                              <div class="row">
                                <div class="col-12 col-md-10 offset-md-1 p-4">
                                  <div class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                    <div class="bgc-white radius-1">
                                      <table class="table table table-striped-default table-borderless">
                                        <tbody><tr>
                                          <td>
                                            <i class="far fa-user text-success"></i>
                                          </td>
  
                                          <td class="text-95 text-600 text-secondary-d2">
                                            Tarif supplémentaire
                                          </td>
  
  
                                          <td class="text-dark-m3">
                                            5 €
                                          </td>
                                        </tr>
  
                                        <tr>
                                          <td>
                                            <i class="far fa-envelope text-blue"></i>
                                          </td>
  
                                          <td class="text-95 text-600 text-secondary-d2">
                                            Tarif minimum
                                          </td>
                                            100 €
                                          <td class="text-blue-d1 text-wrap">
                                            
                                          </td>
                                        </tr>
  
                                        <tr>
                                          <td>
                                            <i class="fa fa-phone text-purple"></i>
                                          </td>
  
                                          <td class="text-95 text-600 text-secondary-d2">
                                            Tarif weekend
                                          </td>
  
                                          <td class="text-dark-m3">
                                            28 €
                                          </td>
                                        </tr>
  
                                        <tr>
                                          <td>
                                            <i class="fa fa-map-marker text-orange-d1"></i>
                                          </td>
  
                                          <td class="text-95 text-600 text-secondary-d2">
                                            Adresse Point de départ
                                          </td>
  
                                          <td class="text-dark-m3">
                                            41 quai Pierre Scize, 69009 Lyon
                                          </td>
                                        </tr>
  
  
                                        </tbody></table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
  
                        </tbody>
                      </table>
  
                      <!-- table footer -->
                      <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                        <div class="text-nowrap align-self-center align-self-sm-start">
                            <span class="d-inline-block text-grey-d2">
                Afficher 1 - 10 of 152
              </span>
  
                          <select class="ml-3 ace-select no-border angle-down brc-h-blue-m3 w-auto pr-45 text-secondary-d3">
                            <option value="10">Afficher 10</option>
                            <option value="20">Afficher 20</option>
                            <option value="50">Afficher 50</option>
                          </select>
                        </div>
  
                        <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                          <a href="#" class="btn btn-lighter-default btn-bgc-white btn-a-secondary radius-l-1 px-3 border-2">
                            <i class="fa fa-caret-left mr-1"></i>
                            Prec
                          </a>
                          <a href="#" class="btn btn-lighter-default btn-bgc-white btn-a-secondary radius-r-1 px-3 border-2 ml-n2px">
                            Suiv
                            <i class="fa fa-caret-right ml-1"></i>
                          </a>
                        </div>
                      </div>
                    </form>
  
                  </div><!-- /.card-body -->
                </div>
              </div>
  
  
  
    </div>
  
  </div>
  
  
  <div class="modal fade modal-ds " id="modalTemplatePrix" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >
            Nouveau Template de Prix
          </h5>
  
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
  
        <div class="modal-body">
  
          <div class="">
            <div class="">
  
              <form class="mt-lg-3">
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Date de début <label
                      style="color:red">
                      *
                    </label>
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <input type="Date" [(ngModel)]="datedebut" name="datedebut" class="form-control col-sm-8 col-md-6">
                  </div>
                </div>
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Date de fin <label
                      style="color:red">
                      *
                    </label>
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <input type="Date" [(ngModel)]="datefin" name="datefin" class="form-control col-sm-8 col-md-6">
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Structure <label
                      style="color:red">
                      *
                    </label>
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <select class="form-control" [(ngModel)]="collectivite" name="collectivite" id="form-field-select-1">
                      <option value="">&nbsp;</option>
  
                      <option *ngFor="let collectivite of collectivites;" value="{{collectivite.id}}">{{collectivite.nom}}</option> 
                      
  
                    </select>
                    
                  </div>
  
                </div>
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Prestataire <label
                      style="color:red">
                      *
                    </label>
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <select class="form-control" [(ngModel)]="prestataire" name="prestataire" id="form-field-select-1">
                      <option value="">&nbsp;</option>
  
                      <option *ngFor="let prestataire of prestataires;" value="{{prestataire.id}}">{{prestataire.nom}}</option> 
                      
  
                    </select>
                    
                  </div>
  
                </div>
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Type de template <label
                      style="color:red">
                      *
                    </label>
                    </label>
                  </div> 
  
                  <div class="col-sm-9">
                    <select class="form-control" [(ngModel)]="typetemplate" name="typetemplate" id="form-field-select-1">
                      <option value="">&nbsp;</option>
  
                      <option *ngFor="let type of types;" value="{{type.id}}">{{type.libelle}}</option>
                      
  
                    </select>
                  </div>  
                   
                </div>
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Tarif jour
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <input type="number" [(ngModel)]="tarifjour" name="tarifjour" class="form-control col-sm-8 col-md-6">
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Tarif nuit
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <input type="number" [(ngModel)]="tarifnuit" name="tarifnuit"  class="form-control col-sm-8 col-md-6">
                  </div>
                </div>
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Tarif weekend
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <input type="number" [(ngModel)]="tarifweekend" name="tarifweekend" class="form-control col-sm-8 col-md-6">
                  </div>
                </div>
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Tarif min
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <input type="number" [(ngModel)]="tarifmin" name="tarifmin"  class="form-control col-sm-8 col-md-6">
                  </div>
                </div>
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Tarif suplementaire
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <input type="number" [(ngModel)]="priseenncharge" name="priseenncharge" class="form-control col-sm-8 col-md-6">
                  </div>
                </div>
  
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                    Delai annualation
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <input type="number" [(ngModel)]="delaiannualation" name="delaiannualation" class="form-control col-sm-8 col-md-6">
                  </div>
                </div>
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                    Delai minimum
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <input type="number" [(ngModel)]="delaiminim" name="delaiminim" class="form-control col-sm-8 col-md-6">
                  </div>
                </div>
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Adresse point de reference
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <textarea  [(ngModel)]="pointref" name="pointref" class="form-control"  placeholder="" style="overflow: hidden; overflow-wrap: break-word; resize: horizontal; height: 62px;"></textarea>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Heure debut jour
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <input type="time" [(ngModel)]="heuredebutjour" name="heuredebutjour" class="form-control col-sm-8 col-md-6">
                  </div> 
                </div>
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Heure debut nuit
                    </label>
                  </div>
  
                  <div class="col-sm-9">
                    <input type="time" [(ngModel)]="heuredebutnuit" name="heuredebutnuit" class="form-control col-sm-8 col-md-6">
                  </div>
                </div>
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Dimanche weekend
                    </label>
                  </div>
  
                  <div class="col-sm-3">
                    <input type="checkbox" [(ngModel)]="flagdimanche" name="flagdimanche" label="Dimanche weekend" [checked]="template.flagdimanche" class="form-control col-sm-8 col-md-6">
                  </div>
                  
                </div>
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Samedi weekend
                    </label>
                  </div>
  
                  <div class="col-sm-3">
                    <input type="checkbox" [(ngModel)]="flagsamedi" name="flagsamedi" label="Samedi weekend" [checked]="template.flagsamedi" class="form-control col-sm-8 col-md-6">
                  </div>
                  
                </div>
  
                 <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Flag approche
                    </label>
                  </div>
  
                  <div class="col-sm-3">
                    <input type="checkbox" [(ngModel)]="flagapproche" name="flagapproche" label="Dimanche weekend" [checked]="template.flagsamedi" class="form-control col-sm-8 col-md-6">
                  </div>
                  
                </div>
  
                <div class="form-group row">
                  <div class="col-sm-3 col-form-label text-sm-right pr-0">
                    <label class="mb-0">
                      Flag retour
                    </label>
                  </div>
  
                  <div class="col-sm-3">
                    <input type="checkbox" [(ngModel)]="flagretour" name="flagretour" label="Dimanche weekend" [checked]="template.flagretour" class="form-control col-sm-8 col-md-6">
                  </div>
                  
                </div>
  
  
              </form>
  
  
            </div><!-- /.card-body -->
          </div>
  
  
        </div>
  
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Fermer
          </button>
          <button type="button" class="btn btn-primary" (click)="save()">
            Enregistrer
          </button>
  
        </div>
      </div>
    </div>
  </div>
  
  
  
  