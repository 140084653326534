import {Component, OnInit, ViewChild} from '@angular/core';
import {DossiersService} from "../../shared/services/dossiers.service";
import {Demand} from "../../shared/classes/demand";
import Swal from "sweetalert2";
import {NgxSpinnerService} from "ngx-spinner";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {User} from "../../shared/session/user";
import {Famille} from "../../shared/classes/famille";

@Component({
  selector: 'app-lstprevals',
  templateUrl: './lstprevals.component.html',
  styleUrls: ['./lstprevals.component.css']
})
export class LstprevalsComponent implements OnInit {

  dossiers: Demand[] = [];
  demand: any;
  documents = [];
  user: User;

  famille: Famille[] = [];


  // @ts-ignore
  @ViewChild('viewDoc') viewDoc: ViewDocComponent;

  constructor(private dossiersService: DossiersService, private spinner: NgxSpinnerService, private authService: AuthenticationService) { }

  ngOnInit(): void {
     this.user = this.authService.getUser();
     this.getDossiers();
  }

  getDossiers() {
    this.spinner.show();
    this.dossiersService.getDossiersByStatutAndUser(this.user.id, 1).subscribe(dossiers => {
      // @ts-ignore
      this.dossiers = dossiers;

      this.spinner.hide();

    });
  }

  showDetails(dossier: Demand) {

    this.demand = dossier;

    this.dossiersService.getDocuments(this.demand.id).subscribe(documents => {

      // @ts-ignore
      this.documents = documents;
    });

    this.dossiersService.getMembers(this.demand.demandeur.id).subscribe(members => {
      // @ts-ignore
      this.famille = members;
    });

  }

  valid(demand: any) {

    this.spinner.show();
    this.dossiersService.validate(this.demand.id, 12).subscribe(dossier => {
      this.getDossiers();

      this.spinner.hide();

      Swal.fire({ icon: 'success', text: 'Demande pré-validé' });
      this.demand = null;

    });



  }

  viewDocument(doc) {
    this.viewDoc.show(doc);
  }
}
