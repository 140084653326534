import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StructureService } from 'src/app/services/structure.service';
import { TemplatePrixService } from 'src/app/services/templatePrix.service';
import { TypetemplateService } from 'src/app/services/typetemplate.service';

import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
import { FoyerService } from 'src/app/services/foyer.service';
declare var $: any;
declare  function  stransAdresse(id: any);
declare function createDateField();
declare function createTimeField();
declare function stransAdresseNative(id: any);
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
declare function showFieldErrorRequired(id: any);

@Component({
  selector: 'app-frmtemplateprix',
  templateUrl: './frmtemplateprix.component.html',
  styleUrls: ['./frmtemplateprix.component.css']
})
export class FrmtemplateprixComponent implements OnInit {

  data: any = [];
  selectedstructures: any = [];
  template: any = null;

  collectivites: any = [];
  types: any = [];
  prestataires: any = [];
  tads: any = [];
  libelle: any = '';
  referencemarche:any='';
  delaiannualation: any = '';
  delaiminim: any = '';
  flagapproche: any = '';
  flagretour: any = '';
  pointref: any = '';
  tarifjour: any = '';
  tarifnuit: any = '';
  jourferie: any ='';
  tarifferie : any = '';
  tarifweekend: any = '';
  tarifmin: any = '';
  flagsamedi: any = '';
  flagdimanche: any = '';
  priseenncharge: any = '';
  typetemplate: any;
  datedebut: any = '';
  datefin: any = '';
  heuredebutjour: any = '';
  heuredebutnuit: any = '';
  collectivite: any;
  prestataire: any;
  tad;
  pres: any;
  prestacol: any;
  parent;
  codePostal;
  ville;
  adresse;
  errordatedebut;
  errordatefin;
  errorcollectivite;
  errorprestataire;
  errortad;
  errortypetemplate;
  test;
  valideperiode;
  errordd: boolean;
  idtemplate = 0;
  errorheuredebutjour;
  errorheuredebutnuit;
  erroradresse;
  errorpriseenncharge;
  errorlibelle = false;
  selectedstructure;
  colSelected: any;
  tadSelected: any;
  parenttad;
  infoError = "Champs invalide";
  infoErrorm = "";
  infoErrordd = "";
  infoErrordf = "";
  lblnom;

  @ViewChild('adresseField') adresseField;

  constructor(private http: HttpClient, private templateprixService: TemplatePrixService,
              private structureService: StructureService,
              private typetemplateService: TypetemplateService, private foyerService: FoyerService,
              private spinner: NgxSpinnerService) {
  }


  ngOnInit(): void {
    this.showtype();
    this.showprestataire();
    this.showTad();
    this.showcollectivites();
   // stransAdresse('adresse');
    createDateField();
    createTimeField();

  }


  showtype(): void {
    this.typetemplateService.getData().subscribe(data => {
      this.types = data;

    });

  }

  showcollectivites(): void {
    this.structureService.getStructuresByType(TypeStructureModel.COLLECTIVITE).subscribe(data => {
      this.collectivites = data;

    });

  }

  showprestataire(): void {
    this.structureService.getStructureRattacheeBis(TypeStructureModel.PRESTATAIRE,this.tad).subscribe(data => {
      this.prestataires = data;
   } ,error => {
    this.prestataires = [];
  });

 }
 showTad(): void {
  this.foyerService.getTadByCollectivite(this.collectivite).subscribe(data => {
    this.tads = data;
 },
 error => {
   this.tads = [];
 });

}

valideInput() {
  $('#heuredebutjour')[0].dispatchEvent(new Event('input', {bubbles: true}));
  $('#heuredebutnuit')[0].dispatchEvent(new Event('input', {bubbles: true}));
  $('#datedebut')[0].dispatchEvent(new Event('input', {bubbles: true}));
  $('#datefin')[0].dispatchEvent(new Event('input', {bubbles: true}));
  this.test = true;
  if (this.libelle == null || this.libelle.trim().length == 0) {
    this.errorlibelle = true;
    this.test = false;
  } else {
    this.errorlibelle = false;
  }

  if (this.datedebut == null || this.datedebut.trim().length == 0) {
    this.errordatedebut = true;
    this.test=false;
  } else {
    this.errordatedebut = false;
  }
  if (this.datefin == null || this.datefin.trim().length == 0) {
    this.errordatefin = true;
    this.test=false;
  } else {
    this.errordatefin = false;
  }
  if(this.collectivite == null) {
    this.errorcollectivite = true;
    this.test = false;
  } else {
    this.errorcollectivite = false;
  }
  if (this.prestataire == null) {
    this.errorprestataire = true;
    this.test=false;
  } else {
    this.errorprestataire = false;
  }
  if (this.tad == null) {
    this.errortad = true;
    this.test=false;
  } else {
    this.errortad = false;
  }
  if (this.typetemplate == null) {
    this.errortypetemplate = true;
    this.test = false;
  } else {
    this.errortypetemplate = false;
  }
  if (this.heuredebutjour == null || this.heuredebutjour.trim().length == 0) {
    this.errorheuredebutjour = true;
  //  showFieldErrorRequired('heuredebutjour');
    this.test = false;
  } else {
    this.errorheuredebutjour = false;
  }

  if (this.heuredebutnuit == null || this.heuredebutnuit.trim().length == 0) {
    this.errorheuredebutnuit = true;
  //  showFieldErrorRequired('heuredebutnuit');
    this.test = false;
  } else {
    this.errorheuredebutnuit = false;
  }
  if (this.pointref == null || this.pointref.trim().length == 0) {
    this.erroradresse = true;
    this.test = false;
  } else {
    this.erroradresse = false;
  }

  return this.test;
}
pushData() {
  $('#adresse')[0].dispatchEvent(new Event('input', {bubbles: true}));
  $('#ville')[0].dispatchEvent(new Event('input', {bubbles: true}));
  $('#codePostal')[0].dispatchEvent(new Event('input', {bubbles: true}));
  $('#datedebut')[0].dispatchEvent(new Event('input', {bubbles: true}));
  $('#datefin')[0].dispatchEvent(new Event('input', {bubbles: true}));
  const headers = {};

  const body = {
    libelle: this.libelle, referencemarche:this.referencemarche,
    delaiannualation: this.delaiannualation, delaiminim: this.delaiminim,
    pointref: this.pointref, tarifjour: this.tarifjour,ville:this.ville, codePostal: this.codePostal,
    tarifnuit: this.tarifnuit, tarifweekend: this.tarifweekend,
    tarifmin: this.tarifmin, priseenncharge: this.priseenncharge,
    datedebut: this.datedebut, datefin: this.datefin, heuredebutjour: this.heuredebutjour,
    heuredebutnuit: this.heuredebutnuit, flagapproche: this.flagapproche,
    flagretour: this.flagretour, flagsamedi: this.flagsamedi,
    typetemplate: {id: this.typetemplate}, collectivite: {id: this.collectivite},
    prestataire: {id: this.prestataire},tad: {id: this.tad},
    flagdimanche: this.flagdimanche, tarifferie: this.tarifferie,actif: true
  };
  console.log(this.template);
  if (!this.template) {
    this.templateprixService.save(headers, body).subscribe(data => {
      $('#modalTemplatePrix').modal('hide');
     // this.parent.showTemplates(this.pres, this.prestacol);
      this.parent.filter(null, this.prestacol);

// this.parent.show();


    });
  } else {
    this.templateprixService.update(headers, body, this.template.id).subscribe(data => {
      $('#modalTemplatePrix').modal('hide');
     // this.parent.showTemplates(this.pres, this.prestacol);
      this.parent.filter(null,this.prestacol);

      //this.parent.show();

    });
  }
}
controleDate(datedebut,datefin) {
  var timeDiff = new Date(datefin).getTime() - new Date(datedebut).getTime();
  alert(timeDiff);
  return timeDiff;
}
 save(): void {
  if (this.valideInput()) {
    // if(this.controleDate(this.datedebut,this.datefin) > 0){ 
    if (1 > 0) {
      //  alert(this.idtemplate)
      this.templateprixService.getvalidePerodes(this.tad, this.prestataire, this.collectivite, this.datedebut, this.datefin, this.idtemplate).subscribe(data => {
        this.valideperiode = data;
        if (this.valideperiode) {
          this.pushData();
          this.resetForm();
        } else {
          Swal.fire({icon: 'error', text: "Attention! chevauchement de template de prix."});
          //alert("Attention! chevauchement de template de prix")
        }
      });

   } else if (this.controleDate(this.datedebut, this.datefin) == 0) {
      Swal.fire({ icon: 'error', text: "Attention! Date début et date fin ne peuvent pas être égales." });
      // alert("date fin ne peut etre anterieur à la date debut")
   } else {
      Swal.fire({ icon: 'error', text: "Date fin ne peut être antérieur à la date début." });
      // alert("date fin ne peut etre anterieur à la date debut")
   }
  }

 }
fermer(){
  this.resetForm();
  $('#modalTemplatePrix').modal('hide');
}
resetForm(){
  $(".struct").attr("disabled", false);
  //$("#tad").attr("disabled", false);
  this.template = null;
  this.libelle = null;
  this.tarifnuit = null;
  this.tarifferie = null;
  this.tarifjour = null;
  this.referencemarche = null;
  this.delaiannualation = null;
  this.delaiminim = null;
  this.pointref = null;
  this.tarifweekend = null;
  this.tarifmin = null;
  this.adresse = null;
  this.ville = null;
  this.codePostal = null;
  this.priseenncharge = null;
  this.datedebut = null;
  this.datefin = null;
  this.heuredebutjour = null;
  this.heuredebutnuit = null;
  this.flagapproche = null;
  this.flagretour = null;
  this.flagsamedi = null;
  this.flagdimanche = null;
  this.flagsamedi = null;
  this.typetemplate = null;
  this.prestataire = null;
    this.tad = null;
  this.collectivite = null;
  this.errorcollectivite = false;
  this.errordatedebut = false;
  this.errordatefin = false;
  this.errordd = false;
  this.errorheuredebutjour = false;
  this.errorheuredebutnuit = false;
  this.errorpriseenncharge = false;
  this.erroradresse = false;
  this.errorlibelle = false;
  this.errorprestataire = false;
  this.errortad = false;
  this.errortypetemplate = false;
}


edit(parent,id: any): void {
//alert(this.collectivite)
 this.parent = parent;
  this.templateprixService.get(id).subscribe(data => {
    this.template = data;
    if(this.template!=null){
      this.referencemarche = this.template.referencemarche;
      this.libelle = this.template.libelle;
      this.tarifnuit = this.template.tarifnuit;
      this.tarifjour = this.template.tarifjour;
      this.tarifferie = this.template.tarifferie;
      this.delaiannualation = this.template.delaiannualation;
      this.delaiminim = this.template.delaiminim;
      this.pointref = this.template.pointref;
      this.codePostal = this.template.codePostal;
      this.ville = this.template.ville;
      this.tarifweekend = this.template.tarifweekend;
      this.tarifmin = this.template.tarifmin;
      this.priseenncharge = this.template.priseenncharge;
      this.datedebut = this.template.datedebut;
      this.datefin = this.template.datefin;
      this.heuredebutjour = this.template.heuredebutjour;
      this.heuredebutnuit = this.template.heuredebutnuit;
      this.flagapproche = this.template.flagapproche;
      this.flagretour = this.template.flagretour;
      this.flagsamedi = this.template.flagsamedi;
      this.flagdimanche = this.template.flagdimanche;
      this.flagsamedi = this.template.flagsamedi;
      if(this.template.typetemplate != null)
      this.typetemplate = this.template.typetemplate.id;
      if(this.template.prestataire != null)
      this.prestataire = this.template.prestataire.id;
      if(this.template.collectivite != null)
      this.collectivite = this.template.collectivite.id;
      if(this.template.tad != null)
      this.tad = this.template.tad.id;
      this.idtemplate = this.template.id;
    }

    this.show(parent);
   // $('#modalTemplatePrix').modal('hide');
  //  $('#modalTemplatePrix').modal('show');

  //  this.showcollectivites();

  });

}

oncpChange(e) {
  if (e.target.checked) {
    this.tad.push(e.target.value);
  } else {
    let i = 0;
    this.tad.forEach((selPens: any) => {
      if (selPens == e.target.value) {
        delete this.tad[i];
        return;
      }
      i++;
    });
  }

}
show(parent) {
  this.parent = parent;
  //alert(this.prestataire)
  stransAdresseNative(this.adresseField.nativeElement);
  if (this.collectivite == null || this.collectivite == 0) {

    $("#tad").attr("disabled", true);
    $("#prestataire").attr("disabled", true);
    this.prestataire = null;

  }else{
    $(".struct").attr("disabled", true);
    $("#tad").attr("disabled", false);
    $("#prestataire").attr("disabled", false);
  }
  this.showcollectivites();
    this.showTad();
    this.showprestataire();
  if (this.tad == null || this.tad ==0) {
    //  alert();
    $("#prestataire").attr("disabled", true);
  } else {
    $("#tad").attr("disabled", true);
    $("#prestataire").attr("disabled", false);
  }
  if (this.prestataire != null) {
    //  alert();
    $("#prestataire").attr("disabled", true);
  }

  $('#modalTemplatePrix').modal('show');
}
openSelect() {
 // this.valideStrucSeleted();
  this.showTad();
   if (this.collectivite == null || this.collectivite == 0) {

     $("#tad").attr("disabled", true);
      $("#prestataire").attr("disabled", true);
      this.prestataire = null;
      this.tad = null;
    }else{
     // $(".struct").attr("disabled", true);
      $("#tad").attr("disabled", false);
    }
}

openSelectPresta() {
  // this.valideStrucSeleted();
   this.showprestataire();
   if (this.tad == null || this.tad ==0) {
    //  alert();
    $("#prestataire").attr("disabled", true);
  } else {
    $("#prestataire").attr("disabled", false);
  }
 }


}
