import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceService} from './service.service';
import {AppModule} from '../app.module';
import { FoyerModel } from '../shared/models/foyer-model.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ReservationService extends ServiceService {

  httpx: HttpClient;
  module = 'reservation/';
  eventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(http: HttpClient) {
    super(http, 'reservation');
    this.httpx = http;
   }


  updateStatut(headers, body, id: any, usr: any) {
    return this.httpx.put(AppModule.API_ENDPOINT + this.module + 'updatestatut/' + id + '/' + usr, body, { headers });
  }
  ajouterChauffeur(idreservation: any,idchauffeur: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/ajoutchauffeur/' + idreservation+ '/' + idchauffeur);
  }
  
  getByStatutTER(statut: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/reservationter/' + statut);
  }

  getByStatutStructure(statut: any, parent: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/reservationratache/' + statut + '/' + parent);
  }

  getByStructure(structure: FoyerModel) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/listreser/' + structure);
  }

  getByDate(structID, dateReservation) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/date/' + structID + '/' + dateReservation);
  }

  getByStrutures(structID) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/structure/' + structID);
  }
  getByCollectiviteProfil(structID) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/collectivite/' + structID);
  }

  getByPrestataireProfil(structID) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/prestataire/' + structID);
  }

  getByFoyerProfil(structID) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/foyer/' + structID);
  }

  getByPeriodes(structID, startDate, enDate) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/periode/' + structID + '/' + startDate + '/' + enDate);
  }

  getRecherche(tad: any, createBy: any, prestataire: any, critereDaterer, datereservation1: any, dateMax: any, statut: any, passager: any, idUser: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/recherche/' + tad + '/' + createBy + '/' + prestataire + '/' + critereDaterer + '/' + datereservation1 + '/' + dateMax + '/' + statut + '/' + passager + '/' + idUser);
  }

  duppliquer(reservation: any, createBy: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/duppliquer/' + reservation + '/' + createBy);
  }


  remove(id: any) {
    return this.httpx.put(AppModule.API_ENDPOINT + this.module + 'remove/' + id, {id}, {});
  }

  getByStructureBis(structure: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/listreserbis/' + structure);
  }
  getByStructureBis1(structure: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/listreserbis1/' + structure);
  }


  getByPrestataireProfilBis(structID: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/prestatairebis/' + structID);
  }

  getForCal(userId, dateReservation) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/cal/' + userId + '/' + dateReservation);
  }

  getSimilars(reservationId) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/similars/' + reservationId);
  }

  duplicate(reservationId, userId, body) {
    return this.httpx.post<any>(AppModule.API_ENDPOINT + 'reservation/duplicate/' + reservationId + '/' + userId, body, {});
  }

  emitNewNote() {
    this.eventEmitter.emit('newNote');
  }

  getEventEmitter() {
    return this.eventEmitter;
  }

  getFeries() {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/feries');
  }

  getDateFeries() {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/feries/dates');
  }


  getByChauffeur(user: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/listreserchauffeur/' + user);
  }

  getByContact(user: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/listresercontact/' + user);
  }

  getTerminatedByPeriod(structID, startDate, enDate) {
    return this.httpx.get(AppModule.API_ENDPOINT + 'reservation/terminated/' + structID + '/' + startDate + '/' + enDate);
  }

  delete(id: any) {
    return this.httpx.delete(AppModule.API_ENDPOINT + 'reservation/delete/' + id);
  }

  addFerie(body) {
    return this.httpx.post(AppModule.API_ENDPOINT + this.module + 'feries/add', body, {  });
  }

  getFerie(date: any) {
    return this.httpx.get(AppModule.API_ENDPOINT + this.module + 'ferie/' + date);
  }

  deleteFerie(id: any) {
    return this.httpx.delete(AppModule.API_ENDPOINT + this.module + 'ferie/delete/' + id);
  }
}

