import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActeurService } from 'src/app/services/acteur.service';
import { PersonneService } from 'src/app/services/personne.service ';
import { StructureService } from 'src/app/services/structure.service';
import { TypeActeurModel } from 'src/app/shared/models/type-acteur-model.model';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
declare var $: any;
declare  function  stransAdresse(id: any);
@Component({
  selector: 'app-frmcollaborateurs',
  templateUrl: './frmcollaborateurs.component.html',
  styleUrls: ['./frmcollaborateurs.component.css']
})
export class FrmcollaborateursComponent implements OnInit {

  data: any = [];
  prestataires: any = [];
  collaborateur: any = null;

  fonctions: any = [];

  code: any='';
  prenom: any = '';
  nom: any = '';
  email: any = '';
  adresse: any = '';
  datenaissance: any = '';
  telephone1: any = '';
  telephone2: any = '';
  information: any = '';
  fonction: any = '';
  typeacteur: any = '';
  personne: any = '';
  structure: any = '';
  collectivites: any = [];
  ville: any = '';
  parent;
  codePostal: any = '';

  constructor(private http: HttpClient, private acteurService: ActeurService, private structureService: StructureService,
    private personneService: PersonneService) { }


  ngOnInit(): void {
    this.showCollectivite();
    stransAdresse('adresse');
  }


 showCollectivite(): void {
  this.structureService.getStructuresByType(TypeStructureModel.COLLECTIVITE).subscribe(data => {
    this.collectivites = data;

 });

}
show(parent) {
  this.parent=parent;
 $('#modalCollaborateur').modal('show');
}

  savePersonne(): void {
    $('#adresse')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#ville')[0].dispatchEvent(new Event('input', { bubbles: true }));
     $('#codePostal')[0].dispatchEvent(new Event('input', { bubbles: true }));
    const headers = { };
    const body = {id:null, code: this.code, nom: this.nom, prenom: this.prenom,
      adresse: this.adresse, telephone1: this.telephone1, telephone2: this.telephone2,
     datenaissance: this.datenaissance, information: this.information, ville: this.ville
     , codePostal: this.codePostal, email: this.email, actif: true };
    console.log(this.collaborateur);
    if (!this.collaborateur) {
      this.personneService.save(headers, body).subscribe(data => {
        this.personne=data;
        this.save();
        //this.show();
       // $('#modalCollaborateur').modal('hide');
      });
    } else {
      body.id = this.collaborateur.personne.id;
      this.personneService.update(headers, body, this.collaborateur.id).subscribe(data => {
        this.save();
      //  $('#modalCollaborateur').modal('hide');
      });
    }
  }

  save(): void {
    const headers = { };
    const body = { personne: this.personne, structure: {id: this.structure}, typeacteur: "COLLABORATEUR", actif: true };
    console.log(this.collaborateur);
    if (!this.collaborateur) {
      this.acteurService.save(headers, body).subscribe(data => {
        this.personne=data;
        $('#modalCollaborateur').modal('hide');
        this.parent.show2();
      });
    } else {
      this.acteurService.update(headers, body, this.collaborateur.id).subscribe(data => {
        $('#modalCollaborateur').modal('hide');
        this.parent.show2();
      });
    }
  }

  editPersonne(parent,id: any): void {
    //alert('ok');
    this.parent = parent;
    this.acteurService.get(id).subscribe(data => {
      this.collaborateur = data;
     // this.typeacteur = this.collaborateur.typeacteur.id;
      this.structure = this.collaborateur.structure.id;
      this.nom = this.collaborateur.personne.nom;
      this.prenom = this.collaborateur.personne.prenom;
      this.adresse = this.collaborateur.personne.adresse;
      this.email = this.collaborateur.personne.email;
      this.telephone1 = this.collaborateur.personne.telephone1;
      this.telephone2 = this.collaborateur.personne.telephone2;
      this.datenaissance = this.collaborateur.personne.datenaissance;
      this.information = this.collaborateur.personne.information;
      this.ville = this.collaborateur.personne.ville;
      this.codePostal = this.collaborateur.personne.codePostal;

      $('#modalCollaborateur').modal('show');
      this.parent.show2();
    });

    

  }



}
