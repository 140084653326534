import { Component, OnInit } from '@angular/core';
import {AppModule} from "../app.module";
import {DomSanitizer} from "@angular/platform-browser";

declare var $: any;
@Component({
  selector: 'app-view-card',
  templateUrl: './view-card.component.html',
  styleUrls: ['./view-card.component.css']
})
export class ViewCardComponent implements OnInit {

  citizen: any;
  url = AppModule.APP_SERVER + 'uploads/';


  constructor(public sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
  }


  show(citizen: any) {
    this.citizen = citizen;
    $('#modalDocument').modal('show');

  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
