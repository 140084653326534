<div class="page-content">
    <div class="page-header border-0">
        <h1 class="page-title text-primary-d2 text-160">
            Souscription
            <small class="page-info text-secondary-d3">
                <i class="fa fa-angle-double-right text-80"></i>
                Liste des souscriptions
            </small>
        </h1>

    </div>


    <div class="px-2">

        <div class="mt-3 mt-lg-4">
            <div class="card bcard pt-1 pt-lg-2">
                <div class="card-header brc-primary-l3">
                    <h5 class="card-title pl-1 text-120">
                        En cours
                    </h5>

                    <div class="card-toolbar align-self-center no-border">
                        <button type="button" class="btn border-2 btn-outline-default btn-sm">
                        </button>
                    </div>

                    <div class="card-toolbar align-self-center">
                        <a href="#" data-action="toggle" class="card-toolbar-btn text-grey text-110">
                            <i class="fa fa-chevron-up"></i>
                        </a>
                    </div>
                </div>

                <div class="card w-100 mt-1 mb-1" >
                    <div class="card-body p-0">
                        <div class="accordion" >
                            <div class="card border-0 mt-1px bgc-primary-l5">
                                <div class="card-header border-0 bgc-transparent mb-0" >
                                    <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                        <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed" href="#rechercheAvancee" data-toggle="collapse" aria-expanded="false" aria-controls="rechercheAvancee">
                                            Recherche avancée

                                            <span class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                                <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                            </span>
                                            <span class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                                <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                            </span>
                                        </a>
                                    </h2>
                                </div>
                                <div aria-labelledby="headingTwo4" class="collapse" data-parent="#rechercheAvancee"
                                     id="rechercheAvancee" style="">
                                    <div class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                        <div class="form-group row">

                                            <div class="col-md-4">

                                                <div class="form-group row">
                                                    <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                        <label class="mb-0">
                                                            N°
                                                        </label>
                                                    </div>

                                                    <div class="col-sm-8 pl-2" >
                                                        <input type="text" class="form-control"   name="numero">
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-4">

                                                <div class="form-group row">
                                                    <div class="col-sm-4 col-form-label text-sm-right pr-2 " >
                                                        <label class="mb-0">
                                                            Capital min
                                                        </label>
                                                    </div>

                                                    <div class="col-sm-8 pl-2">
                                                        <input  class="form-control" name="capmin" type="text">
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-4">

                                                <div class="form-group row">
                                                    <div class="col-sm-4 col-form-label text-sm-right pr-2 " >
                                                        <label class="mb-0">
                                                            Capital max
                                                        </label>
                                                    </div>

                                                    <div class="col-sm-8 pl-2">

                                                        <input  class="form-control" name="capmax" type="text">

                                                    </div>
                                                </div>

                                            </div>


                                        </div>


                                        <div class="form-group row">

                                            <div class="col-md-4">

                                                <div class="form-group row">
                                                    <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                        <label class="mb-0">
                                                            Date souscription
                                                        </label>
                                                    </div>

                                                    <div class="col-sm-8 pl-2">
                                                        <select #searchCritereDate  (change)="searchCritereDate.blur();" [(ngModel)]="critereDaterer" name="critereDaterer" class="form-control"
                                                        >
                                                            <option value="1">Egal</option>
                                                            <option value="2">Inférieur</option>
                                                            <option value="3">Supérieur</option>
                                                            <option value="4">Compris entre</option>
                                                        </select>


                                                        <input  class="form-control" id="datecrer" name="datecrer"
                                                               type="date"/>
                                                        <button type="button" class="btn btn-sm btn-secondary"
                                                               >
                                                            Effacer
                                                        </button>


                                                    </div>


                                                </div>

                                            </div>

                                            <div class="col-md-4">

                                                <div class="form-group row">
                                                    <div class="col-sm-4 col-form-label text-sm-right pr-2 " >
                                                        <label class="mb-0">
                                                            Statut
                                                        </label>
                                                    </div>

                                                    <div class="col-sm-8 pl-2">
                                                        <select
                                                               class="form-control" id="statutselect" name="idstatut">
                                                            <option value="0">Tous</option>
                                                            <option >En attente de validation</option>
                                                            <option >En attente de paiement</option>
                                                            <option >Rejeté</option>
                                                            <option >Résilié</option>
                                                        </select>

                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-4">

                                                <div class="form-group row">
                                                    <div class="col-sm-4 col-form-label text-sm-right pr-2 " >
                                                        <label class="mb-0">
                                                            Adhérent
                                                        </label>
                                                    </div>

                                                    <div class="col-sm-8 pl-2">
                                                        <input type="text" class="form-control"  placeholder="Prénom / nom / email" name="passager">

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="form-group row">

                                            <div class="col-md-4">

                                                <div class="form-group row">
                                                    <div *ngIf="this.critereDaterer == '4'" class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                        <label class="mb-0">
                                                            Date Max
                                                        </label>
                                                    </div>

                                                    <div *ngIf="this.critereDaterer == '4'"  class="col-sm-8 pl-2">
                                                        <input  class="form-control" id="dateMax" name="dateMax"
                                                               type="date"/>
                                                        <button type="button" class="btn btn-sm btn-secondary"
                                                                >
                                                            Effacer
                                                        </button>
                                                    </div>


                                                </div>

                                            </div>
                                            <div class="col-md-2">

                                                <div class="form-group row">
                                                    <div class="col-sm-8 pl-2">
                                                        <button type="button" (click)="rechercheAvance()" class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                                            <i class="fa fa-search mr-1"></i>

                                                        </button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>


                <div class="card-body p-0 border-0">
                    <table id="simple-table" class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                        <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                        <tr>
                            <th class="text-center pr-0">
                                <label class="py-0">
                                    <input type="checkbox" class="align-bottom mb-n1 border-2 text-dark-m3">
                                </label>
                            </th>

                            <th>
                                N° DOSSIER
                            </th>

                            <th>
                                 ADHERENT
                            </th>



                            <th class="d-none d-sm-table-cell">
                                DATE SOUSCRIPTION
                            </th>

                            <th class="d-none d-sm-table-cell">
                                CAPITAL
                            </th>

                            <th class="d-none d-sm-table-cell">
                                STATUT
                            </th>

                            <th></th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody class="mt-1">
                        <tr class="bgc-h-yellow-l4 d-style" *ngFor="let dossier of dossiers">
                            <td class="text-center pr-0 pos-rel">
                                <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                    <!-- border shown on hover -->
                                </div>
                                <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                                    <!-- border shown when row is selected -->
                                </div>

                                <label>
                                    <input type="checkbox" class="align-middle">
                                </label>
                            </td>

                            <td>
                                <a href="#" class="text-blue-d1 text-600 text-95">{{dossier.id}}</a>
                            </td>

                            <td>
                                <a href="#" class="text-blue-d1 text-600 text-95">
                                    {{dossier.demandeur.prenom}} &nbsp; {{dossier.demandeur.nom}}
                                </a>
                            </td>



                            <td class="d-none d-sm-table-cell text-grey-d1">
                                {{dossier.createdDate}}
                            </td>

                            <td class="d-none d-sm-table-cell text-grey text-95">

                                {{dossier.capital}}

                            </td>

                            <td class="d-none d-sm-table-cell">
                                <span class="badge badge-sm {{dossier?.statut?.code}} text-white pb-1 px-25">{{dossier?.statut?.libelle}}</span>



                            </td>

                            <td class="text-center pr-0">
                                <div>
                                    <a routerLink="/dossier/{{dossier.id}}"  class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 " >
                                  <span class="d-none d-md-inline mr-1">
											Details
										</span>
                                        <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                                    </a>
                                </div>
                            </td>

                            <td>
                                <!-- action buttons -->

                                <!-- show a dropdown in mobile -->
                                <div class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                                    <a href="#" class="btn btn-default btn-xs py-15 radius-round dropdown-toggle" data-toggle="dropdown">
                                        <i class="fa fa-cog"></i>
                                    </a>

                                    <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                                        <div class="dropdown-inner">
                                            <div class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                                                ace.com
                                            </div>
                                            <a href="#" class="dropdown-item">
                                                <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                                Modifier
                                            </a>
                                            <a href="#" class="dropdown-item">
                                                <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                                Supprimer
                                            </a>
                                            <a href="#" class="dropdown-item">
                                                <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                                Tag
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>


                        </tbody>
                    </table>

                </div><!-- /.card-body -->
            </div><!-- /.card -->
        </div>

    </div>


</div>
