import { Component, OnInit, ViewChild } from '@angular/core';
import { FrmreservationComponent } from "../../reservation/frmreservation/frmreservation.component";
import { LstorgansisationsComponent } from "../../organisation/lstorgansisations/lstorgansisations.component";
import { StructureService } from 'src/app/services/structure.service';
import { HttpClient } from '@angular/common/http';

declare var $: any;


declare function stransAdresse(id: any);
declare function stransAdresseNative(id: any);


@Component({
  selector: 'app-frmcollectivites',
  templateUrl: './frmcollectivites.component.html',
  styleUrls: ['./frmcollectivites.component.css']
})
export class FrmcollectivitesComponent implements OnInit {

  data: any = [];
  dataprestatire: any = [];
  dataprestatires: any = [];
  datamotifs: any = [];
  datafoyers: any = [];
  collaborateurs: any = [];
  contacts: any = [];
  motifs: any = [];
  datafoyer: any = [];
  datacollectivte: any = [];
  datacol: any = [];
  dataville: any = [];
  datatype: any = [];
  structure: any = null;
  collaborateur: any = null;
  contact: any = null;
  motif: any = null;

  siret: any = '';
  nom: any = '';
  ville: any = '';
  codePostal: any = '';
  adresse: any = '';
  telephone: any = '';
  email: any = '';
  typestructure: any = '';
  responsable: any = '';
  collectivite: any = '';
  parent;
  errorNom = false;
  errorte = false;
  errorEmail = false;
  errorTel = false;
  test = true;
  infoErrorNom = "Le nom est obligatoire";
  infoErrorTel = "Le téléphone est obligatoire";
  lblnom;
  errorTelFixe = false;
  errorDate = false;
  infoErrorTelFixe = "Champs invalide";
  loadingsiren = false;
  datecreation: any;
  errormessage = '';
  error = false;

  @ViewChild('collectivteModal') collectivteModal;
  @ViewChild('adresseField') adresseField;

  constructor(private http: HttpClient, private structureService: StructureService) { }

  ngOnInit(): void {

   // stransAdresse('adresse');
  }

  show(parent) {
    this.parent = parent;
    if(this.structure != null){
      $(".siren").attr("disabled", true);
      
    }else{
      $(".siren").attr("disabled", false);
    }
    
    $('#modalCollectivite').modal('show');
  }

  pushData() {
    $('#adresse')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#ville')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#codePostal')[0].dispatchEvent(new Event('input', { bubbles: true }));
    const headers = {};
    const body = {
      siret: this.siret, nom: this.nom,datecreation:this.datecreation,
      adresse: this.adresse, telephone: this.telephone,
      responsable: this.responsable, ville: this.ville, codePostal: this.codePostal,
      email: this.email, typestructure: "COLLECTIVITE", actif: true
    };
    console.log(this.structure);
    if (!this.structure) {
      // alert();
      this.structureService.save(headers, body).subscribe(data => {
        if(!data){
          $('#modalCollectivite').modal('hide');
          this.parent.getOrganisations();
          this.parent.showCollectivite();
        } err => {
          console.log(err);
         this.errormessage = 409 === err.status ? 'La collectivité existe déjà !' : '' ;
      }
        
      });
    } else {
      this.structureService.update(headers, body, this.structure.id).subscribe(data => {
        $('#modalCollectivite').modal('hide');
        this.parent.getOrganisations();
        this.parent.showCollectivite();

      });
    }
    this.resetForm();
  }
  fermer() {
    this.resetForm();
    $('#modalCollectivite').modal('hide');
  }
  resetForm() {
    this.datecreation = null;
    this.structure = null;
    this.siret = null;
    this.nom = null;
    this.adresse = null;
    this.responsable = null;
    this.email = null;
    this.ville = null;
    this.codePostal = null;
    this.typestructure = null;
    this.telephone = null;
    this.errorNom = false;
    this.errorte = false;
    this.errorEmail = false;
    this.errorTel = false;
    this.infoErrorTel = "Le téléphone est obligatoir";
    this.errormessage = '';
  }

  edit(parent, id: any): void {
    this.parent = parent;
    this.structureService.get(id).subscribe(data => {
      this.structure = data;
      if (!!this.structure) {
        this.siret = this.structure.siret;
        this.nom = this.structure.nom;
        this.adresse = this.structure.adresse;
        this.responsable = this.structure.responsable;
        this.email = this.structure.email;
        this.ville = this.structure.ville;
        this.codePostal = this.structure.codePostal;
        this.typestructure = this.structure.typestructure;
        this.telephone = this.structure.telephone;
        this.datecreation = this.structure.dateCreation;
      }
    //  $('#modalCollectivite').modal('show');
      this.show(parent);
    });

  }

  valideEmail() {
    var validRegex = "^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$";
    if (this.email.trim().length != 0) {
      if (this.email.match(validRegex)) {
        this.errorEmail = false;
      } else {
        this.errorEmail = true;
      }
    } else {
      this.errorEmail = false;
    }
    return this.errorEmail;
  }
  valideTel() {
    var reg = /^(06|07)[0-9]{8}/gi;
    this.telephone = this.telephone.replaceAll(' ', '');
    this.telephone = this.telephone.replaceAll('-', '');
    if (this.telephone.length == 0) {
      this.errorTel = true;
      // this.infoErrorTel = 'champ obligatoir';
    } else
      if (this.telephone.length != 0) {
        if (this.telephone.length == 10) {
          if (!this.telephone.match(reg)) {
            this.errorTel = true;
            this.infoErrorTel = 'Le numero doit imperativement commencer par 06 ou 07.';
          } else {
            this.errorTel = false;
          }
        } else {
          // if(this.telephone.length < 10)
          this.infoErrorTel = 'Vous avez saisi ' + this.telephone.length + '.La longueur requise est de 10 chiffres.';
          this.errorTel = true;
          // this.infoError ="Vous avez saisie "
        }
      } else {
        this.errorTel = false;
      }
    return this.errorTel;
  }
  valideTelFixe() {
    var reg = /^(01|02|03|04|05|08|09)[0-9]{8}/gi;
    if(!!this.telephone){
      this.telephone = this.telephone.replaceAll(' ', '');
      this.telephone = this.telephone.replaceAll('-', '');

      if (this.telephone.length != 0) {
        if (this.telephone.length == 10) {
          if (!this.telephone.match(reg)) {
            this.errorTelFixe = true;
            this.infoErrorTelFixe = 'Le numero saisi doit impérativement commencer par 01 ou 02 ou 03 ou 04 ou 05 ou 08 ou 09.';
          } else {
            this.errorTelFixe = false;
          }
        } else {
          this.infoErrorTelFixe = 'Vous avez saisi ' + this.telephone.length + '.La longueur requise est de 10 chiffres.';
          this.errorTelFixe = true;
        }
      } else {
        // alert('No 10')
        this.errorTelFixe = false;
      }
    }else{
      this.errorTelFixe = false;
    }
    
    
    return this.errorTelFixe;
  }
  valideInput() {
    // if (this.siret.trim().length == 0) {
    //   this.error = true;
    //   this.test = false;
    // } else {
    //   this.error = false;
    //   this.test = true;
    // }
    if (this.valideEmail() == true) {
      this.test = false;
    }
    if (this.valideTelFixe() == true) {
      this.test = false;
    }
    return this.test;
  }

  save(): void {

    // this.errorNom = true;
    // this.errorTel = true;
    if (this.valideInput()) {
      this.pushData();
    }
  }

  searchEntrepriseFromSiren() {

    this.nom = '';
    this.adresse = '';
    this.responsable = '';
    this.email = '';
    this.ville = '';
    this.codePostal = '';
    this.telephone = '';
    this.datecreation = '';
    this.errormessage = '';
    if (this.siret.toString().length < 9) {
      return ;
    }

    this.loadingsiren = true;
    this.structureService.searchFromSiren(this.siret).subscribe(data => {
     this.loadingsiren = false;
     if (data) {
      this.siret = data?.siren;
      this.nom = data?.nom;
      this.datecreation = new Date(+ data?.dateCreation.substr(0, 4), + data?.dateCreation.substr(4, 2) - 1
     , + data?.dateCreation.substr(6, 2));
      console.log('dateCreation --> ', this.datecreation);
      this.adresse = data?.adresse;
      this.ville = data?.ville;
      this.codePostal = data?.codePostal;
     } else {
      this.errormessage = 'Siren non valide';
     }


    }, err => {
      this.loadingsiren = false;
    });
 }
}
