import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActeurService } from 'src/app/services/acteur.service';
import { PersonneService } from 'src/app/services/personne.service ';
import { StructureService } from 'src/app/services/structure.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';
declare var $: any;
declare  function  stransAdresse(id: any);
@Component({
  selector: 'app-frmcollaborateursfoyers',
  templateUrl: './frmcollaborateursfoyers.component.html',
  styleUrls: ['./frmcollaborateursfoyers.component.css']
})
export class FrmcollaborateursfoyersComponent implements OnInit {

  data: any = [];
  prestataires: any = [];
  collaborateur: any = null;

  fonctions: any = [];

  code: any='';
  prenom: any = '';
  nom: any = '';
  email: any = '';
  adresse: any = '';
  datenaissance: any = '';
  telephone1: any = '';
  telephone2: any = '';
  information: any = '';
  fonction: any = '';
  typeacteur: any = '';
  personne: any = '';
  structure: any = '';
  collectivites: any = [];
  ville: any = '';
  parent;
  codePostal: any = '';
  errorf = false;
  errorp = false;
  errorn = false;
  error = true;
  errorTel = false;

  errorEmail= false;
  typestructure:TypeStructureModel;
  lblstructure;
  lblnom;

  constructor(private http: HttpClient, private acteurService: ActeurService, 
    private structureService: StructureService,
    private personneService: PersonneService) { }


  ngOnInit(): void {
    
    
    stransAdresse('adresse'); 
  }
  show(parent) {
    this.parent=parent;
    this.showFoyer();
   $('#modalCollaborateur').modal('show');
  }
  valideEmail() {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (this.email.trim().length != 0) {
      if (this.email.match(validRegex)) {
        this.errorEmail = false;
      } else {
        this.errorEmail = true;
      }
    }
    return this.errorEmail; 
  }

  
 showFoyer(): void {
  
this.structureService.getStructuresByType(this.typestructure).subscribe(data => {
  this.collectivites = data;

});
 } 


valideTel() {
  var reg = /^(06|07)[0-9]{8}/gi;
  this.telephone2 = this.telephone2.replaceAll(' ', '');
  this.telephone2 = this.telephone2.replaceAll('-', '');
  if (this.telephone2.length != 0) {
    if (this.telephone2.length == 10) {
      if (!this.telephone2.match(reg)) {
        // alert('houpsssssssssss');
        this.errorTel = true;
      } else {
        // alert('tel okkkkkkkkkkkkkk  ' + phone)
        this.errorTel = false;
      }
    } else {
      this.errorTel = true;
    }
  } else {
    // alert('No 10')
    this.errorTel = false;
  }
  return this.errorTel;
}
valideInput() {
  this.error = true;
  var strf = document.forms['myForm'].structure.value
  // this.tad.trim().length == 0
  if (!strf.replace(/\s+/, '').length) {
   // alert('ok');
    // document.getElementById("msgt").innerHTML="Champ obligatoir";
    this.errorf = true;
    this.error = false;
  } else {
    this.errorf = false;
   // this.error = true;
  }

  if (!document.forms['myForm'].prenom.value.replace(/\s+/, '').length) {
    // document.getElementById("msgdd").innerHTML="Champ obligatoir";
    this.errorp = true;
    this.error = false;
  } else {
    this.errorp = false;
   // this.error = true;
  }

  if (!document.forms['myForm'].nom.value.replace(/\s+/, '').length) {
    //   document.getElementById("msgdf").innerHTML="Champ obligatoir";
    this.errorn = true;
    this.error = false;
  } else {
    this.errorn = false;
   // this.error = true;
  }
  if (this.valideEmail() == true) {
    this.error = false;
  }
  if (this.valideTel() == true) {
    this.error = false;
  }

  return this.error;
}
savePersonne(): void {
  if (this.valideInput()) {
    this.pushDatePersonne();
   
  }

}
pushDatePersonne() {
  $('#adresse')[0].dispatchEvent(new Event('input', { bubbles: true }));
  $('#ville')[0].dispatchEvent(new Event('input', { bubbles: true }));
   $('#codePostal')[0].dispatchEvent(new Event('input', { bubbles: true }));
  const headers = { };
  const body = {id:null, code: this.code, nom: this.nom, prenom: this.prenom,
    adresse: this.adresse, telephone1: this.telephone1, telephone2: this.telephone2,
   datenaissance: this.datenaissance, information: this.information, ville: this.ville
   , codePostal: this.codePostal, email: this.email, actif: true };
  console.log(this.collaborateur);
  if (!this.collaborateur) {
    this.personneService.save(headers, body).subscribe(data => {
      this.personne=data;
      this.save();
  
    });
  } else {
    body.id = this.collaborateur.personne.id;
    this.personneService.update(headers, body, this.collaborateur.id).subscribe(data => {
      this.save();
      
    });
  }
}

  save(): void {
   // this.parent = parent;
    const headers = { };
    const body = { personne: this.personne, structure: {id: this.structure}, typeacteur: "COLLABORATEUR", actif: true };
    console.log(this.collaborateur);
    if (!this.collaborateur) {
      this.acteurService.save(headers, body).subscribe(data => {
        this.personne=data;
        $('#modalCollaborateur').modal('hide');
        this.parent.showActeurStructure();
      });
    } else {
      this.acteurService.update(headers, body, this.collaborateur.id).subscribe(data => {
        $('#modalCollaborateur').modal('hide');
        this.parent.showActeurStructure();
      });
    }
  }

  editPersonne(parent,id: any): void {
    //alert('ok'); 
    this.parent = parent;
    this.acteurService.get(id).subscribe(data => {
      this.collaborateur = data;
     // this.typeacteur = this.collaborateur.typeacteur.id;
      this.structure = this.collaborateur.structure.id;
      this.nom = this.collaborateur.personne.nom;
      this.prenom = this.collaborateur.personne.prenom;
      this.adresse = this.collaborateur.personne.adresse;
      this.email = this.collaborateur.personne.email;
      this.telephone1 = this.collaborateur.personne.telephone1;
      this.telephone2 = this.collaborateur.personne.telephone2;
      this.datenaissance = this.collaborateur.personne.datenaissance;
      this.information = this.collaborateur.personne.information;
      this.ville = this.collaborateur.personne.ville;
      this.codePostal = this.collaborateur.personne.codePostal;
      this.personne=this.collaborateur.personne;

      $('#modalCollaborateur').modal('show');
     // this.parent.showActeurStructure();
    });

    

  }

}
