<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Template de prix
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i> 
        Liste des templates de prix
      </small>
    </h1>

  </div>


  <div class="px-2">
         <div class="tab-pane fade text-95 active show" id="home2" role="tabpanel">
              <div class="card dcard">
                <div class="card-body px-1 px-md-3">

                  <form autocomplete="off" (keyup.enter)="rechercheAvanceTemplate()">
                    <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


                      <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                        <button *ngIf="this.canCreate" type="button" (click)="openFormTemplatePrix()" class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                          <i class="fa fa-plus mr-1"></i>
                          Ajouter <span class="d-sm-none d-md-inline"></span>
                        </button></div>

                      <div class="mb-2 mb-sm-0">
                        
                      </div>
                    </div>
                    <div class="card w-100 mt-1 mb-1">
                      <div class="card-body p-0">
                          <div class="accordion">
                              <div class="card border-0 mt-1px bgc-primary-l5">
                                  <div class="card-header border-0 bgc-transparent mb-0">
                                      <h2 class="card-title bgc-transparent text-primary-d1 brc-primary-m1">
                                          <a class="d-style btn btn-white bgc-white btn-brc-tp btn-h-outline-primary btn-a-outline-primary accordion-toggle border-l-3 radius-0 collapsed" 
                                          href="#rechercheAvanceeTemplate" data-toggle="collapse" aria-expanded="false" aria-controls="rechercheAvanceeTemplate">
                    Recherche avancée

                    <span class="v-collapsed px-3px radius-round d-inline-block brc-grey-l1 border-1 mr-3 text-center position-rc">
                        <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-90"></i>
                    </span>
                    <span class="v-n-collapsed px-3px radius-round d-inline-block bgc-primary mr-3 text-center position-rc">
                        <i class="fa fa-angle-down toggle-icon w-2 mx-1px text-white text-90 pt-1px"></i>
                    </span>
                  </a>
                                      </h2>
                                  </div>
                                  <div id="rechercheAvanceeTemplate" class="collapse" aria-labelledby="headingTwo4" data-parent="#rechercheAvanceeTemplate" style="">
                                      <div class="card-body pt-1 text-dark-m1 border-l-3 brc-primary-m1 bgc-primary-l5">

                                          <div class="form-group row">
                                            <div class="col-md-4">

                                              <div class="form-group row">
                                                <div class="col-sm-4 col-form-label text-sm-right pr-2 " >
                                                  <label class="mb-0">
                                                    Structure
                                                  </label>
                                                </div>
                
                                                <div class="col-sm-8 " (keyup.enter)="rechercheAvanceTemplate()" >
                                                  <select #searchCollectivite (change)="searchCollectivite.blur(); srch.focus()" 
                                                   class="form-control coll" [(ngModel)]="idcollectivite" 
                                                   name="idcollectivite" id="idcollectivite" >
                                                    <option value="0">&nbsp;</option>
                                                      <option *ngFor="let col of collectivitestemp;" value="{{col.id}}">{{col.nom}}</option>
                                                  </select>
                                                  <button type="button" class="btn btn-sm btn-secondary"
                                                                (click)='reset1()'>
                                                                Effacer
                                                            </button>
                                                            
                                                </div>
                                              </div>
                
                                            </div>
                                            <div class="col-md-4">

                                              <div class="form-group row">
                                                <div class="col-sm-4 col-form-label text-sm-right pr-2 " >
                                                  <label class="mb-0">
                                                    TAD
                                                  </label>
                                                </div>
                
                                                <div class="col-sm-8 pl-2" (keyup.enter)="rechercheAvanceTemplate()" >
                                                  <select #searchTad (change)="searchTad.blur(); srch.focus()"  class="form-control" [(ngModel)]="idtad" name="idtad" >
                                                    <option value="0">&nbsp;</option>
                                                      <option *ngFor="let tad of idtadstemp;" value="{{tad.id}}">{{tad.nom}}</option>
                                                  </select>
                                                  <input #srch style="height: 0px; width: 0px; padding: 0px; border: none;"
                                                            type="text"/>
                                                  <button type="button" class="btn btn-sm btn-secondary"
                                                                (click)='reset2()'>
                                                                Effacer
                                                            </button>
                                                </div>
                                              </div>
                
                                            </div>
                                            <div class="col-md-4">

                                              <div class="form-group row">
                                                  <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                      <label class="mb-0">
                        Libellé
                      </label>
                                                  </div>

                                                  <div class="col-sm-8 pl-2">
                                                    
                                                      <input type="text" [(ngModel)]="libelle" name="libelle"  class="form-control">
                                                      <button type="button" class="btn btn-sm btn-secondary"
                                                      (click)='reset8()'>
                                                      Effacer
                                                  </button>
                                                    </div>
                                              </div>

                                          </div>
                                          </div>
                                            <div class="form-group row">
                                              <div class="col-md-4">

                                                  <div class="form-group row">
                                                      <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                          <label class="mb-0">
                           Date début
                          </label>
                                                      </div>

                                                      <div class="col-sm-8 pl-2">
                                                        <select #searchDateDebut (change)="searchDateDebut.blur(); srch.focus()" [(ngModel)]="critereDateDebut" name="critereDateDebut" class="form-control"
                                                        >
                                                        <option value="1">Egal</option> 
                                                        <option value="2">Inférieur</option>
                                                        <option value="3">Supérieur</option>
                                                        <option value="4">Compris entre</option>
                                                    </select>
                                                          <input type="date" [(ngModel)]="dataDebut" name="dataDebut" class="form-control">
                                                          <button type="button" class="btn btn-sm btn-secondary"
                                                          (click)='reset3()'>
                                                          Effacer
                                                      </button>
                                                        </div>
                                                  </div>
                                              </div>
                                              <div class="col-md-4">

                                                  <div class="form-group row">
                                                      <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                          <label class="mb-0">
                            Date fin
                          </label>
                                                      </div>

                                                      <div class="col-sm-8 pl-2">
                                                        <select #searchDateFin (change)="searchDateFin.blur(); srch.focus()" [(ngModel)]="critereDateFin" name="critereDateFin" class="form-control"
                                                        >
                                                        <option value="1">Egal</option>
                                                        <option value="2">Inférieur</option>
                                                        <option value="3">Supérieur</option>
                                                        <option value="4">Compris entre</option>
                                                    </select>
                                                          <input type="date" [(ngModel)]="dataFin" name="dataFin"  class="form-control">
                                                          <button type="button" class="btn btn-sm btn-secondary"
                                                          (click)='reset4()'>
                                                          Effacer
                                                      </button>
                                                        </div>
                                                  </div>

                                              </div>
                                              <div class="col-md-4">

                                                <div class="form-group row">
                                                  <div class="col-sm-4 col-form-label text-sm-right pr-2 " >
                                                    <label class="mb-0">
                                                     Prestataire
                                                    </label>
                                                  </div>
                  
                                                  <div class="col-sm-8 pl-2" (keyup.enter)="rechercheAvanceTemplate()" >
                                                    <select #searchPrestataire (change)="searchPrestataire.blur(); srch.focus()"
                                                      class="form-control" [(ngModel)]="idprestataire" name="idprestataire" >
                                                      <option value="0">&nbsp;</option>
                                                        <option *ngFor="let presta of idprestatairestemp;" value="{{presta.id}}">{{presta.nom}}</option>
                                                    </select>
                                                   
                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                                  (click)='reset7()'>
                                                                  Effacer
                                                              </button>
                                                  </div>
                                                </div>
                  
                                              </div>
                                          </div>
                                          <div class="form-group row">


                                            <div class="col-md-4">

                                                <div class="form-group row">
                                                    <div *ngIf="this.critereDateDebut == '4'" class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                        <label class="mb-0">
Date début max
</label>
                                                    </div>

                                                    <div *ngIf="this.critereDateDebut == '4'" class="col-sm-8 pl-2">
                                                        <input type="date" [(ngModel)]="dateDebutMax"  name="dateDebutMax" class="form-control">
                                                        <button type="button" class="btn btn-sm btn-secondary"
                                                        (click)='reset5()'>
                                                        Effacer
                                                    </button>
                                                      </div>
                                                </div>

                                            </div>

                                            <div class="col-md-4">

                                                <div class="form-group row">
                                                    <div *ngIf="this.critereDateFin == '4'" class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                        <label class="mb-0">
Date fin max
</label>
                                                    </div> 

                                                    <div *ngIf="this.critereDateFin == '4'" class="col-sm-8 pl-2">
                                                        <input type="date" [(ngModel)]="dateFinMax" name="dateFinMax" class="form-control">
                                                        <button type="button" class="btn btn-sm btn-secondary"
                                                        (click)='reset6()'>
                                                        Effacer
                                                    </button>
                                                      </div>
                                                </div>

                                            </div>
                                        </div>
                                          <div class="form-group row">
                                            <div class="col-md-4">

                                              <div class="form-group row">
                                                  <div class="col-sm-4 col-form-label text-sm-right pr-2 ">
                                                      <label class="mb-0">
                        Référence du marche 
                      </label>
                                                  </div>

                                                  <div class="col-sm-8 pl-2">
                                                    
                                                      <input type="text" [(ngModel)]="referencemarche" name="referencemarche"  class="form-control">
                                                      <button type="button" class="btn btn-sm btn-secondary"
                                                      (click)='reset9()'>
                                                      Effacer
                                                  </button>
                                                    </div>
                                              </div>

                                          </div>
                                            <div class="col-md-4">
      
                                              <div class="form-group row">
      
                                                  <div class="mb-2 mb-sm-0">
                                                      <button type="button" (click)="rechercheAvanceTemplate()" class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10">
                                        <i class="fa fa-search mr-1"></i>
                                        
                                      </button>
                                                  </div>
                                              </div>
      
                                          </div>
                                          </div>
                                          
                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
                    <table  class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
                      <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
                      <tr>
                        <th class="text-center pr-0">
                         
                        </th>
                        <th>
                          Libellé
                        </th>

                        <th>
                          Référence du marché 
                        </th>
                        <th>
                          Structure
                        </th>
                        <th>
                          TAD
                        </th>
                        <th>
                          Prestataire
                        </th>
                       
                        <th>
                          Période de validité
                        </th>
                        
                        <th></th>
                        <th></th>
                      </tr>
                      </thead>

                      <tbody class="mt-1">
                        <ng-container *ngFor="let template of data| paginate : {
                          itemsPerPage: tableSize,
                          currentPage: page,
                          totalItems: count}; let i = index" [class.active]="i == currentIndex" >
                      <tr class="bgc-h-yellow-l4 d-style">
                        <td class="text-center pr-0 pos-rel">
                          <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                            <!-- border shown on hover -->
                          </div>
                          <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                            <!-- border shown when row is selected -->
                          </div>
                        </td>
                        <td>
                          <a  class="text-dark-d1 text-600 text-95">{{template.libelle}} </a>
                        </td>

                        <td>
                          <a  class="text-dark-d1 text-600 text-95">{{template.referencemarche}} </a>
                        </td>

                        <td>
                          <span  class="text-dark-d1 text-600 text-95">{{(!!template.collectivite) ? template.collectivite.nom:''}} </span>
                        </td>
                        <td>
                          <a  class="text-dark-d1 text-600 text-95">{{(!!template.tad) ? template.tad.nom:''}}</a>
                        </td>
                        <td>
                          <a  class="text-dark-d1 text-600 text-95">{{(!!template.prestataire) ? template.prestataire.nom:''}}</a>
                        </td>
                       
                        <td>
                          <span  class="text-dark-d1 text-600 text-95">{{template.datedebut }}  - {{template.datefin }} </span>
                        </td>
                      
                        <td class="text-center pr-0">
                          <div>
                            <a href="#" data-toggle="collapse" [attr.data-target]="'#detail-template-'+template.id" class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed" title="Show Details" aria-expanded="false">
                                  <span class="d-none d-md-inline mr-1">
                      Details
                    </span>
                              <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                            </a>
                          </div>
                        </td>

                        <td>
                          <!-- action buttons -->
                          <div class="d-none d-lg-flex">
                            <a href="#" *ngIf="this.canEdit" (click)="edit(template.id)"  class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success">
                              <i class="fa fa-pencil-alt"></i>
                            </a>

                            <a href="#" *ngIf="this.canDelete" (click)="delete(template.id)" class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                          </div>

                          <!-- show a dropdown in mobile -->
                          <div class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                            <a href="#" class="btn btn-default btn-xs py-15 radius-round dropdown-toggle" data-toggle="dropdown">
                              <i class="fa fa-cog"></i>
                            </a>

                            <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                              <div class="dropdown-inner">
                                <div class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                                  Prestataire 1
                                </div>
                                <a href="#" class="dropdown-item">
                                  <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                                  Edit
                                </a>
                                <a href="#" class="dropdown-item">
                                  <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                                  Delete
                                </a>
                                <a href="#" class="dropdown-item">
                                  <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                                  Autre
                                </a>
                              </div>
                            </div>
                          </div>

                        </td>
                      </tr>

                      <!-- detail row -->
                      <tr class="border-0 detail-row bgc-white">
                        <td colspan="8" class="p-0 border-none brc-secondary-l2">
                          <div class="table-detail collapse" id="{{'detail-template-'+template.id}}" style="">
                            <div class="row">
                              <div class="col-12 col-md-10 offset-md-1 p-4">
                                <div class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                                  <div class="bgc-white radius-1">
                                    <table class="table table table-striped-default table-borderless">
                                      <tbody><tr>
                                        <td>
                                          <i class="fa fa-bookmark text-success"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Montant prise en charge
                                        </td>


                                        <td class="text-dark-m3">
                                          {{template.priseenncharge | number: '1.0-2':'fr-FR'}} €
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <i class="fa fa-bookmark text-blue"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Tarif minimum
                                        </td>
                                        
                                        <td class="text-blue-d1 text-wrap">
                                          {{template.tarifmin | number: '1.0-2':'fr-FR'}} €
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <i class="fa fa-bookmark text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Tarif nuit
                                        </td>

                                        <td class="text-dark-m3">
                                          {{template.tarifnuit | number: '1.0-2':'fr-FR'}} €
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <i class="fas fa-clock text-blue"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Heure debut nuit
                                        </td>
                                        
                                        <td class="text-blue-d1 text-wrap">
                                          {{template.heuredebutnuit}} 
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <i class="fa fa-bookmark text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Tarif jour
                                        </td>

                                        <td class="text-dark-m3">
                                          {{template.tarifjour | number: '1.0-2':'fr-FR'}} €
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <i class="fa fa-bookmark text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Tarif férié
                                        </td>

                                        <td class="text-dark-m3">
                                          {{template.tarifferie | number: '1.0-2':'fr-FR'}} €
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <i class="fas fa-clock text-blue"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          heure debut jour 
                                        </td>

                                        <td class="text-blue-d1 text-wrap">
                                          {{template.heuredebutjour}} 
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <i class="fa fa-bookmark text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Tarif weekend
                                        </td>

                                        <td class="text-dark-m3">
                                          {{template.tarifweekend | number: '1.0-2':'fr-FR'}} €
                                        </td>
                                      </tr>
                                      <tr *ngIf="template.flagsamedi==1">
                                        <td>
                                          <i class="fa fa-toggle-on text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Samedi weekend
                                        </td>
                                        <td class="text-dark-m3">
                                          Oui  
                                        </td>
                                      </tr>
                                      <tr *ngIf="template.flagsamedi==0">
                                        <td>
                                          <i class="fa fa-toggle-off text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Samedi weekend
                                        </td>
                                        <td class="text-dark-m3">
                                          Non   
                                        </td>
                                      </tr>
                                      <tr *ngIf="template.flagdimanche==1">
                                        <td>
                                          <i class="fa fa-toggle-on text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Dimanche weekend
                                        </td>
                                        <td class="text-dark-m3">
                                          Oui  
                                        </td>
                                      </tr>
                                      <tr *ngIf="template.flagdimanche==0">
                                        <td>
                                          <i class="fa fa-toggle-off text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Dimanche weekend
                                        </td>
                                        <td class="text-dark-m3">
                                          Non   
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <i class="fa fa-clock text-success"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Delai d'annulation
                                        </td>

                                        <td class="text-dark-m3">
                                          {{template.delaiannualation}} H
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <i class="fa fa-clock text-success"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Delai minimum
                                        </td>

                                        <td class="text-dark-m3">
                                          {{template.delaiminim}} H 
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <i class="fa fa-university text-success"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Structure
                                        </td>

                                        <td *ngIf="template.collectivite != null" class="text-dark-m3">
                                          {{template.collectivite.nom}} 
                                        </td>
                                        <td *ngIf="template.collectivite == null"class="text-dark-m3">
                                           
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <i class="fa fa-taxi text-success"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Prestataire
                                        </td>

                                        <td *ngIf="template.prestataire != null" class="text-dark-m3">
                                          {{template.prestataire.nom}} 
                                        </td>
                                        <td *ngIf="template.prestataire == null" class="text-dark-m3"> 
                                        </td>
                                      </tr>
                                                            <tr>
                                        <td>
                                          <i class="fa fa-users text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Type template de prix 
                                        </td>
                                        <td *ngIf="template.typetemplate != null" class="text-dark-m3">
                                          {{template.typetemplate.libelle}} 
                                        </td>
                                        <td *ngIf="template.typetemplate == null" class="text-dark-m3"> 
                                        </td>
                                      </tr>


                                      <tr>
                                        <td>
                                          <i class="fa fa-map-marker text-orange-d1"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Adresse de référence
                                        </td>

                                        <td class="text-dark-m3">
                                          {{template.pointref}}
                                        </td>
                                      </tr>
                                      <tr *ngIf="template.flagapproche==1">
                                        <td>
                                          <i class="fa fa-toggle-on text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Approche facturable
                                        </td>
                                        <td class="text-dark-m3">
                                          Oui  
                                        </td>
                                      </tr>
                                      <tr *ngIf="template.flagapproche==0">
                                        <td>
                                          <i class="fa fa-toggle-off text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Approche facturable
                                        </td>
                                        <td class="text-dark-m3">
                                          Non   
                                        </td>
                                      </tr>
                                      <tr *ngIf="template.flagretour==1">
                                        <td>
                                          <i class="fa fa-toggle-on text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          
                                          Retour adresse de référence facturable
                                        </td>
                                        <td class="text-dark-m3">
                                          Oui  
                                        </td>
                                      </tr>
                                      <tr *ngIf="template.flagretour==0">
                                        <td>
                                          <i class="fa fa-toggle-off text-purple"></i>
                                        </td>

                                        <td class="text-95 text-600 text-secondary-d2">
                                          Retour adresse de référence facturable
                                        </td>
                                        <td class="text-dark-m3">
                                          Non   
                                        </td>
                                      </tr>
                                      </tbody></table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                      </tbody>
                    </table>

                    <!-- table footer -->
                    <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
                      <div class="text-nowrap align-self-center align-self-sm-start">
                          Afficher 1 - {{ tableSize }} of {{data.length}}
                          <select (change)="tableData($event)" class="custom-select">
                          <option *ngFor="let size of tableSizesArr" [value]="size">
                              Afficher {{ size }}
                          </option>
                        </select>
                      </div>
                      <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                          <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next" (pageChange)="tabSize($event)">
                          </pagination-controls>
                      </div>
                      <div>

                      </div>
                  </div>
                  </form>

                </div><!-- /.card-body -->
              </div>
            </div>



  </div>

</div>
<app-frmtemplateprix #frmtemplateprix ></app-frmtemplateprix>

 
<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>




