import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StructureService } from 'src/app/services/structure.service';
import { VilleService } from 'src/app/services/ville.service';
import { FoyerService } from 'src/app/services/foyer.service';
import { BudgetService } from 'src/app/services/budget.service ';
import { ActeurService } from 'src/app/services/acteur.service';
import { TypeStructureModel } from 'src/app/shared/models/type-structure-model.model';

declare function stransAdresse(id: any);
declare var $: any;

@Component({
  selector: 'app-frmcollectivitetad',
  templateUrl: './frmcollectivitetad.component.html',
  styleUrls: ['./frmcollectivitetad.component.css']
})
export class FrmcollectivitetadComponent implements OnInit {

  data: any = [];
  tad: any = null;
  lblnom;
  collectivites: any = [];

  siret: any = '';
  nom: any = '';
  adresse: any = '';
  responsable: any = '';
  email: any = '';
  collectivite: any = '';
  codepostal: any = '';
  telephone: any = '';
  telephonePortable: any = '';
  typestructure: any = '';
  ville: any = '';
  parent;
  codePostal: any = '';
  structure: any = null;
  selectedcollectivite;
  error = false;
  errorEmail = false;
  errorTel = false;
  test = true;
  infoErrorEmail ="L\' email est obligatoire"
  infoErrorTel = "Champs invalide";
  infoErrorTelFixe = "";
  errorTelFixe =false;
  information: any ='';


  constructor(private http: HttpClient,
    private structureService: StructureService, private villeService: VilleService
    , private foyerService: FoyerService, private acteurService: ActeurService
    , private budgetService: BudgetService) { }

  ngOnInit(): void {

    stransAdresse('adresse');


  }


  show(parent) {
    this.parent = parent;
    // alert();
    $('#modalTad').modal('show');
  }

  getselected(id: any) {
    // alert("ok");
    //this.selectedcollectivite=id;
    this.collectivite = id;
  }

  pushData() {
    $('#adresse')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#ville')[0].dispatchEvent(new Event('input', { bubbles: true }));
    $('#codePostal')[0].dispatchEvent(new Event('input', { bubbles: true }));
    const headers = {};
    const body = {
      nom: this.nom,
      adresse: this.adresse, telephone: this.telephone, telephonePortable:this.telephonePortable,
      responsable: this.responsable, ville: this.ville, codePostal: this.codePostal, information: this.information,
      email: this.email, collectivite: this.collectivite, typestructure: "TAD", actif: true
    };
    console.log(this.structure);
    if (!this.structure) {

      this.structureService.saveTad(headers, body).subscribe(data => {
        //  alert(this.collectivite);
        $('#modalTad').modal('hide');
        this.parent.filter(null, this.collectivite);
        this.parent.parent.getOrganisations();

      });
    } else {
      this.structureService.update(headers, body, this.structure.id).subscribe(data => {
        $('#modalTad').modal('hide');
        this.parent.filter(null, this.collectivite);

      });
    }
  }
  save(): void {
    this.error = true;
    if (this.valideInput()) {
      this.pushData();
      this.resetForm();
    }
    // alert()
  }
  fermer(): void {
    this.resetForm();
    $('#modalTad').modal('hide');
  }
  resetForm(): void {

    this.structure = null;
    this.siret = null;
    this.nom = null;
    this.adresse = null;
    this.responsable = null;
    this.email = null;
    this.ville = null;
    this.codePostal = null;
    this.telephone = null;
    this.telephonePortable = null;
    this.error = false;
    this.errorEmail = false;
    this.errorTel = false;
    this.information = null;
    this.errorTelFixe = false;
    this.infoErrorTelFixe = null;
    $('#modalTad').modal('hide');
    // this.typestructure = null;
  }
  edit(parent, id: any): void {
    this.parent = parent;
    this.structureService.get(id).subscribe(data => {
      this.structure = data;
      // this.siret = this.structure.siret;
      this.nom = this.structure.nom;
      this.telephone = this.structure.telephone;
      this.telephonePortable = this.structure.telephonePortable;
      this.adresse = this.structure.adresse;
      this.responsable = this.structure.responsable;
      this.information = this.structure.information; 
      this.email = this.structure.email; 
      this.ville = this.structure.ville;
      this.codePostal = this.structure.codePostal;
      this.telephone = this.structure.telephone;
      this.typestructure = this.structure.typestructure; 

      $('#modalTad').modal('show');
    });

  }
  valideEmail() {
    var validRegex = "^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$";
    if (!!this.email && this.email.trim().length != 0) {
      if (this.email.match(validRegex)) {
        this.errorEmail = false;
      } else {
        this.errorEmail = true;
        this.infoErrorEmail ='L\' email est invalide.'
      }
    }else{
      this.errorEmail = true;
      this.infoErrorEmail = 'L\' email est obligatiore.';
    }

    return this.errorEmail;
  }

  valideInput() {
    if (!this.nom || this.nom.trim().length == 0) {
      this.error = true;
      this.test = false;
    } else {
      this.error = false;
      this.test = true;
    }
    if (this.valideEmail() == true) {
      this.test = false;
    }
    if (this.valideTel() == true) {
      this.test = false;
    }
    if (this.valideTelFixe() == true) {
      this.test = false;
    }
    return this.test;
  }
  valideTel() {
    var reg = /^(06|07)[0-9]{8}/gi;
    if(this.telephonePortable){
      this.telephonePortable = this.telephonePortable.replaceAll(' ', '');
    this.telephonePortable = this.telephonePortable.replaceAll('-', '');
    if (this.telephonePortable.length == 0) {
      this.errorTel = false;
      // this.infoErrorTel = 'champ obligatoir';
    } else
      if (this.telephonePortable.length != 0) {
        if (this.telephonePortable.length == 10) {
          if (!this.telephonePortable.match(reg)) {
            this.errorTel = true;
            this.infoErrorTel = 'Le numero doit imperativement commencer par 06 ou 07.';
          } else {
            this.errorTel = false;
          }
        } else {
          // if(this.telephone.length < 10)
          this.infoErrorTel = 'Vous avez saisi ' + this.telephonePortable.length + '.La longueur requise est de 10 chiffres.';
          this.errorTel = true;
          // this.infoError ="Vous avez saisie "
        }
      } else {
        this.errorTel = false;
      }
    }else{
      this.errorTel = true;
      this.infoErrorTel = 'Le numero téléphone portable est obligatiore.';
    }
    
    return this.errorTel;
  }
  valideTelFixe() {
    var reg = /^(01|02|03|04|05|08|09)[0-9]{8}/gi;
    if(this.telephone){
      this.telephone = this.telephone.replaceAll(' ', '');
      this.telephone = this.telephone.replaceAll('-', '');
      if (this.telephone.length == 0) {
        this.errorTel = false;
        // this.infoErrorTel = 'champ obligatoir';
      } else
        if (this.telephone.length != 0) {
          if (this.telephone.length == 10) {
            if (!this.telephone.match(reg)) {
              this.errorTelFixe = true;
              this.infoErrorTelFixe = 'Le numero doit imperativement commencer par 01 ou 02 ou 03 ou 04 ou 05 ou 08 ou 09.';
            } else {
              this.errorTelFixe = false;
            }
          } else {
            // if(this.telephone.length < 10)
            this.infoErrorTelFixe = 'Vous avez saisi ' + this.telephone.length + '.La longueur requise est de 10 chiffres.';
            this.errorTelFixe = true;
            // this.infoError ="Vous avez saisie "
          }
        } else {
          this.errorTelFixe = false;
        }
    }else{
      this.errorTelFixe = true;
      this.infoErrorTelFixe = 'Le numero téléphone fixe est obligatiore.';
    }
    
    return this.errorTelFixe;
  }



}
