<div class="page-content">
  <div class="page-header border-0">
    <h1 class="page-title text-primary-d2 text-160">
      Fonctions avancées
      <small class="page-info text-secondary-d3">
        <i class="fa fa-angle-double-right text-80"></i>
        Templates de simulation
      </small>
    </h1>

  </div>


  <div class="px-2">
    <div class="tab-pane fade text-95 active show" id="home2" role="tabpanel">
      <div class="card dcard">
        <div class="card-body px-1 px-md-3">

          <form autocomplete="off">
            <div class="d-flex justify-content-between flex-column flex-sm-row mb-3 px-2 px-sm-0">


              <div class="pos-rel ml-sm-auto mr-sm-2 order-last order-sm-0">
                <i class="fa fa-search position-lc ml-25 text-primary-m1"></i>
                <input class="form-control w-100 pl-45 radius-1 brc-primary-m4" placeholder="Rechercher ..."
                       type="text">
              </div>

              <div class="mb-2 mb-sm-0">
                <button (click)="openFormTemplatePrix()"
                        class="btn btn-blue px-3 d-block w-100 text-95 radius-round border-2 brc-black-tp10"
                        type="button">
                  <i class="fa fa-plus mr-1"></i>
                  Ajouter <span class="d-sm-none d-md-inline"></span>
                </button>
              </div>
            </div>

            <table
              class="mb-0 table table-borderless table-bordered-x brc-secondary-l3 text-dark-m2 radius-1 overflow-hidden">
              <thead class="text-dark-tp3 bgc-grey-l4 text-90 border-b-1 brc-transparent">
              <tr>
                <th class="text-center pr-0">
                  <label class="py-0">
                    <input class="align-bottom mb-n1 border-2 text-dark-m3" type="checkbox">
                  </label>
                </th>

                <th>
                  Libellé
                </th>

                <th>
                  Structure
                </th>
                <th>
                  Tarif Jour
                </th>

                <th>
                  Tarif nuit
                </th>

                <th>
                  Tarif weekend
                </th>

                <th>
                  Tarif personne
                </th>


                <th></th>
                <th></th>
              </tr>
              </thead>

              <tbody class="mt-1">
              <ng-container *ngFor="let template of data| paginate : {
                itemsPerPage: tableSize,
                currentPage: page,
                totalItems: count}; let i = index" [class.active]="i == currentIndex">
                <tr class="bgc-h-yellow-l4 d-style">
                  <td class="text-center pr-0 pos-rel">
                    <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                      <!-- border shown on hover -->
                    </div>
                    <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1 v-active">
                      <!-- border shown when row is selected -->
                    </div>

                    <label>
                      <input class="align-middle" type="checkbox">
                    </label>
                  </td>

                  <td>
                    <span class="text-dark-d1 text-600 text-95">{{template.libelle}}</span>
                  </td>
                  <td>
                    <span class="text-dark-d1 text-600 text-95">{{template.collectivite.nom}}</span>
                  </td>
                  <td>
                    <a class="" href="#">{{template.tarifjour}} €</a>
                  </td>

                  <td>
                    <a class="" href="#">{{template.tarifnuit}} €</a>
                  </td>

                  <td>
                    <a class="" href="#">{{template.tarifweekend}} €</a>
                  </td>

                  <td>
                    <a class="" href="#">{{template.priseenncharge}} €</a>
                  </td>


                  <td class="text-center pr-0">
                    <div>
                      <a [attr.data-target]="'#detail-template-'+template.id" aria-expanded="false"
                         class="d-style btn btn-outline-info text-90 text-600 border-0 px-2 collapsed"
                         data-toggle="collapse" href="#" title="Show Details">
                                  <span class="d-none d-md-inline mr-1">
                      Details
                    </span>
                        <i class="fa fa-angle-down toggle-icon opacity-1 text-90"></i>
                      </a>
                    </div>
                  </td>

                  <td>
                    <!-- action buttons -->
                    <div class="d-none d-lg-flex">
                      <a (click)="edit(template.id)"
                         class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-success btn-a-lighter-success"
                         href="#">
                        <i class="fa fa-pencil-alt"></i>
                      </a>

                      <a (click)="delete(template.id)"
                         class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-danger btn-a-lighter-danger"
                         href="#">
                        <i class="fa fa-trash-alt"></i>
                      </a>
                      <a
                        class="mx-2px btn radius-1 border-2 btn-xs btn-brc-tp btn-light-secondary btn-h-lighter-warning btn-a-lighter-warning"
                        href="#">
                        <i class="fa fa-ellipsis-v mx-1"></i>
                      </a>
                    </div>

                    <!-- show a dropdown in mobile -->
                    <div class="dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-lg">
                      <a class="btn btn-default btn-xs py-15 radius-round dropdown-toggle" data-toggle="dropdown"
                         href="#">
                        <i class="fa fa-cog"></i>
                      </a>

                      <div class="dropdown-menu dd-slide-up dd-slide-none-lg">
                        <div class="dropdown-inner">
                          <div
                            class="dropdown-header text-100 text-secondary-d1 border-b-1 brc-secondary-l2 text-600 mb-2">
                            Prestataire 1
                          </div>
                          <a class="dropdown-item" href="#">
                            <i class="fa fa-pencil-alt text-blue mr-1 p-2 w-4"></i>
                            Edit
                          </a>
                          <a class="dropdown-item" href="#">
                            <i class="fa fa-trash-alt text-danger-m1 mr-1 p-2 w-4"></i>
                            Delete
                          </a>
                          <a class="dropdown-item" href="#">
                            <i class="far fa-flag text-orange-d1 mr-1 p-2 w-4"></i>
                            Autre
                          </a>
                        </div>
                      </div>
                    </div>

                  </td>
                </tr>

                <!-- detail row -->
                <tr class="border-0 detail-row bgc-white">
                  <td class="p-0 border-none brc-secondary-l2" colspan="8">
                    <div class="table-detail collapse" id="{{'detail-template-'+template.id}}" style="">
                      <div class="row">
                        <div class="col-12 col-md-10 offset-md-1 p-4">
                          <div
                            class="alert bgc-secondary-l4 text-dark-m2 border-none border-l-4 brc-primary-m1 radius-0 mb-0">
                            <div class="bgc-white radius-1">
                              <table class="table table table-striped-default table-borderless">
                                <tbody>
                                <tr>
                                  <td>
                                    <i class="fa fa-bookmark text-success"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Tarif supplémentaire
                                  </td>


                                  <td class="text-dark-m3">
                                    {{template.priseenncharge}} €
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <i class="fa fa-bookmark text-blue"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Tarif minimum
                                  </td>

                                  <td class="text-blue-d1 text-wrap">
                                    {{template.tarifmin}} €
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i class="fa fa-bookmark text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Tarif nuit
                                  </td>

                                  <td class="text-dark-m3">
                                    {{template.tarifnuit}} €
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i class="fas fa-clock text-blue"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Heure debut nuit
                                  </td>

                                  <td class="text-blue-d1 text-wrap">
                                    {{template.heuredebutnuit}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i class="fa fa-bookmark text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Tarif jour
                                  </td>

                                  <td class="text-dark-m3">
                                    {{template.tarifjour}} €
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i class="fas fa-clock text-blue"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    heure debut jour
                                  </td>

                                  <td class="text-blue-d1 text-wrap">
                                    {{template.heuredebutjour}}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <i class="fa fa-bookmark text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Tarif weekend
                                  </td>

                                  <td class="text-dark-m3">
                                    {{template.tarifweekend}} €
                                  </td>
                                </tr>
                                <tr *ngIf="template.flagsamedi==1">
                                  <td>
                                    <i class="fa fa-toggle-on text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Samedi weekend
                                  </td>
                                  <td class="text-dark-m3">
                                    Oui
                                  </td>
                                </tr>
                                <tr *ngIf="template.flagsamedi==0">
                                  <td>
                                    <i class="fa fa-toggle-off text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Samedi weekend
                                  </td>
                                  <td class="text-dark-m3">
                                    Non
                                  </td>
                                </tr>
                                <tr *ngIf="template.flagdimanche==1">
                                  <td>
                                    <i class="fa fa-toggle-on text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Dimanche weekend
                                  </td>
                                  <td class="text-dark-m3">
                                    Oui
                                  </td>
                                </tr>
                                <tr *ngIf="template.flagdimanche==0">
                                  <td>
                                    <i class="fa fa-toggle-off text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Dimanche weekend
                                  </td>
                                  <td class="text-dark-m3">
                                    Non
                                  </td>
                                </tr>
                             
                                <tr>
                                  <td>
                                    <i class="fa fa-university text-success"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Structure
                                  </td>

                                  <td *ngIf="template.collectivite != null" class="text-dark-m3">
                                    {{template.collectivite.nom}}
                                  </td>
                                  <td *ngIf="template.collectivite == null" class="text-dark-m3">

                                  </td>
                                </tr>
                               
                                <tr>
                                  <td>
                                    <i class="fa fa-users text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Type template de prix
                                  </td>
                                  <td *ngIf="template.typetemplate != null" class="text-dark-m3">
                                    {{template.typetemplate.libelle}}
                                  </td>
                                  <td *ngIf="template.typetemplate == null" class="text-dark-m3">
                                  </td>
                                </tr>


                                <tr>
                                  <td>
                                    <i class="fa fa-map-marker text-orange-d1"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Adresse Point de départ
                                  </td>

                                  <td class="text-dark-m3">
                                    {{template.pointref}}
                                  </td>
                                </tr>
                                <tr *ngIf="template.flagapproche==1">
                                  <td>
                                    <i class="fa fa-toggle-on text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Aproche
                                  </td>
                                  <td class="text-dark-m3">
                                    Oui
                                  </td>
                                </tr>
                                <tr *ngIf="template.flagapproche==0">
                                  <td>
                                    <i class="fa fa-toggle-off text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Aproche
                                  </td>
                                  <td class="text-dark-m3">
                                    Non
                                  </td>
                                </tr>
                                <tr *ngIf="template.flagdimanche==1">
                                  <td>
                                    <i class="fa fa-toggle-on text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Retour
                                  </td>
                                  <td class="text-dark-m3">
                                    Oui
                                  </td>
                                </tr>
                                <tr *ngIf="template.flagretour==0">
                                  <td>
                                    <i class="fa fa-toggle-off text-purple"></i>
                                  </td>

                                  <td class="text-95 text-600 text-secondary-d2">
                                    Retour
                                  </td>
                                  <td class="text-dark-m3">
                                    Non
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>

            <!-- table footer -->
            <div class="d-flex pl-4 pr-3 pt-35 border-t-1 brc-secondary-l3 flex-column flex-sm-row mt-n1px">
              <div class="text-nowrap align-self-center align-self-sm-start">
                  Afficher 1 - {{ tableSize }} of {{data.length}}
                  <select (change)="tableData($event)" class="custom-select">
                  <option *ngFor="let size of tableSizesArr" [value]="size">
                      Afficher {{ size }}
                  </option>
                </select>
              </div>
              <div class="btn-group ml-sm-auto mt-3 mt-sm-0">
                  <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next" (pageChange)="tabSize($event)">
                  </pagination-controls>
              </div>
              <div>
  
              </div>
          </div>
          </form>

        </div><!-- /.card-body -->
      </div>
    </div>


  </div>

</div>
<app-frm-template-simulation #frmtemplateprix></app-frm-template-simulation>



<ngx-spinner size="medium" color="#4AA0DF" bdColor="rgba(255,255,255,0)" type="timer" fullScreen="false"><p style="color: #4AA0DF" > Chargement en cours... </p></ngx-spinner>



